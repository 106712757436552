import React, { useEffect, useState } from "react";
import "./memory.css";
import { SingleCard } from "./SingleCard/SingleCard";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { gameData } from "../GameData/gameData";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeStartGame } from "../../../../redux/actions";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { API } from "../../../../backend";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

const cardImages = [
  { src: "/img/cartoon1.jpg", matched: false },
  { src: "/img/cartoon2.jpg", matched: false },
  { src: "/img/cartoon3.jpg", matched: false },
  { src: "/img/cartoon4.jpg", matched: false },
  { src: "/img/cartoon5.jpg", matched: false },
  { src: "/img/cartoon6.jpg", matched: false },
];

const Memory = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [cards, setCards] = useState([]);
  const [turns, setTurns] = useState(0);
  const [cardMatched, setCardMatched] = useState(0);

  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [originalScore, setOriginalScore] = useState(0);
  const [missedScore, setMissedScore] = useState(0);
  const [gameId, setGameId] = useState("");
  const enablicJourneyScore = true;
  const { currentSchedule } = useSelector((state) => state);

  const [open, setOpen] = useState(false);

  const shuffleCards = () => {
    setOpen(false);
    const shuffledCards = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));
    setCards(shuffledCards);
    setTurns(0);
    setChoiceOne(null);
    setChoiceTwo(null);
    setCardMatched(0);
  };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCardMatched((prev) => prev + 1);
        setCards((prev) => {
          return prev.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        // console.log("those card do not match");
        setDisabled(false);
        setTimeout(() => {
          resetTurn();
        }, 1000);
      }
    }
  }, [choiceOne, choiceTwo]);
  useEffect(() => {
    shuffleCards();
  }, []);

  useEffect(() => {
    if (cardMatched === 6) {
      setOpen(true);
      let original_score = turns;
      let missed_score = Math.max(0, (original_score - 7) * 1.9);
      setOriginalScore(original_score);
      setMissedScore(missed_score);
      axios
        .get(`${API}/games/getByName/${gameData["MatchingCards"].name}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          // console.log("val", val.data.data._id);
          setGameId(val.data.data._id);
        })
        .catch((err) => {
          setOpenSnackbar(true);
          setMessage(err.message);
          setSeverity("error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardMatched]);
  useEffect(() => {
    if (cardMatched === 6 && location.pathname === "/gameplay") {
      SubmitScore(originalScore, missedScore, gameId, false);
    } else if (cardMatched === 6 && location.pathname !== "/gameplay") {
      SubmitScore(
        originalScore,
        missedScore,
        gameId,
        enablicJourneyScore,
        currentSchedule
      );
      setTimeout(() => {
        dispatch(handleChangeStartGame(false));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardMatched,
    currentSchedule,
    enablicJourneyScore,
    gameId,
    location.pathname,
    missedScore,
    originalScore,
  ]);
  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };
  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };
  const handleBack = () => {
    navigate("/games");
  };
  return (
    <div>
      {cardMatched === 6 ? (
        turns <= 10 ? (
          <>
            <ConfettiEffect />
            <WinnerMascot textBubble={`Impressive skills, ${localSigninData.data.user.first_name}! You are doing so well!`} />
          </>
        ) : (
          <>
            <GymMascot
              textBubble={`It’s okay, we can try again!`}
            />
          </>
        )
      ) : (
        <></>
      )}

      <div className="memory-parent">
        {!open ? (
          <>
            <h3>Match the cards</h3>
            <div className="card-grid">
              {cards.map((card) => (
                <SingleCard
                  card={card}
                  key={card.id}
                  handleChoice={handleChoice}
                  flipped={
                    card === choiceOne || card === choiceTwo || card.matched
                  }
                  disabled={disabled}
                />
              ))}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1>Game Over</h1>
            <div className="home-play-btn">
              <button className="game-section-return-btn" onClick={handleBack}>
                <span>Game Section</span>
              </button>
              <button onClick={shuffleCards}>
                <span>Play Again</span>
              </button>
            </div>
          </div>
        )}

        <p>Turns: {turns}</p>
      </div>
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};

export default Memory;
