import React from "react";
import { ReactComponent as Ellipse } from "../common/Games/assets/gameSection.svg";
import ProfileForm from "./ProfileForm";
import "./Profile.css";
import ProfileMobView from "../common/Games/assets/ProfileMobView";
import { API } from "../../backend";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { RingLoader } from "react-spinners";

const ProfileContentMobile = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(
    dayjs(`${dayjs().subtract(2, "year").format()}`)
  );
  const [initialValues, setInitialValues] = React.useState({
    profileAvatar: "",
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    country: "",
  });

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const getUserDetails = async () => {
    try {
      dayjs.extend(utc);
      const data = await axios.get(
        `${API}/users/getbyid/${
          localSigninData && localSigninData.data.user._id
        }`,
        {
          "Content-Type": "application/json",
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        }
      );
      // console.log(data.data.data);
      const userData = {
        profileAvatar: data.data.data.profile_emoji,
        firstName: data.data.data.first_name,
        lastName: data.data.data.last_name,
        email: data.data.data.email,
        // birthDate: data.data.data.dob.split('T')[0],
        country: data.data.data.country,
      };
      setInitialValues((prevState) => ({ ...prevState, ...userData }));
      setValue(dayjs(data.data.data.dob).local().format("MM/DD/YYYY"));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getUserDetails();
    // console.log("initial values", initialValues);
  }, []);

  return (
    <div>
      <div className="ellipseGameSection">
        <Ellipse />
      </div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="insightsSection"
          style={{
            marginTop: "80px",
            paddingLeft: "16px",
            width: loading ? "87%" : "auto",
            marginLeft: "8px",
            marginRight: "8px",
            minHeight: "fit-content",
          }}
        >
          <h1 className="insightsText">Your Profile</h1>
          {loading ? (
            <div
              style={{
                margin: "auto",
                width: "100%",
                height: "470px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RingLoader
                color="#1abcf3"
                cssOverride={override}
                loading={loading}
                size={120}
                speedMultiplier={1}
              />
            </div>
          ) : (
            <div
              className="form-container"
              style={{
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                height: "fit-content",
                background: "rgba(233,233,233,0.23)",
                padding: "15px",
                paddingTop: "30px",
                borderRadius: "10px",
              }}
            >
              {/* <div className="profileIcon">
                <ProfileMobView height={125} width={125} />
              </div> */}
              <div className="form-section">
                <ProfileForm
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  value={value}
                  setValue={setValue}
                  setLoading={setLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileContentMobile;
