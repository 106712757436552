import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { handleChangeScore, handleChangeTestOver } from "../../redux/actions";
import axios from "axios";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { API } from "../../backend";
// import { ReactComponent as Rectangle } from "./assets/rectangle_20.svg";
import backgroundImage from "./assets/unrecognizable-girl-coloring-picture.jpg";
import { ReactComponent as GameRemote } from "./assets/game_remote.svg";
import { ReactComponent as Ecllipse3 } from "./assets/ellipse3.svg";
import { journeyProgress } from "../Dashboard/EnablicJourney";
import { getCurrentJourney } from "../Dashboard/EnablicJourney";
import { ReactComponent as HomeIcon } from "../../assets/icons/dashboard.svg";
import { motion } from "framer-motion";

// import { set } from "date-fns";
import "./PsychometricTest.css";

const getRandomIndex = (num) => {
  return Math.floor(Math.random() * num);
};
const PsychometricTest = () => {
  const dispatch = useDispatch();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  // console.log("localSigninData ", localSigninData);

  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [optionsIndex, setOptionsIndex] = useState();
  const [journeyDataProgress, setJourneyDataProgress] = useState(0);
  const [currentJourneyCycle, setCurrentJourneyCycle] = useState();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleJourneyNavigate = () => {
    navigate("/dashboard/enablicJourney");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("e.target", e.target);
    // console.log("form submitted");
    try {
      setLoading(true);
      const res = await axios
        .post(
          `${API}/scorecards/create`,
          {
            test: options[optionsIndex].id,
            user: localSigninData.data.user._id,
            start_time: new Date().toISOString(),
          },
          {
            headers: {
              token: `${localSigninData && localSigninData.data.token}`,
            },
          }
        )
        .catch(() => {
          setOpenSnackbar(true);
          setMessage("Something went wrong");
          setSeverity("error");
        });
      setLoading(false);
      navigate("/questions", {
        state: { test: options[optionsIndex], scoreCardId: res.data.data._id },
        refresh: true,
      });
    } catch (err) {
      setOpenSnackbar(true);
      setMessage("Test, could not be started.");
      setSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let arr = [];
    const fetchData = () => {
      axios
        .get(`${API}/tests/getAllTests`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          val.data.data.map((id) => {
            let obj = {};
            // console.log("id ", id);
            obj["id"] = id._id;
            obj["name"] = id.name;
            obj["duration"] = id.duration;
            obj["max_score"] = id.max_score;
            arr.push(obj);
            // console.log("arr", arr);
            setOptions(arr);
            setLoading(false);
            if (loading === false) {
              dispatch(handleChangeScore(0));
              dispatch(handleChangeTestOver(false));
            }
            return arr;
          });
        });
    };
    fetchData();
    // Randomise the test options
    if (options.length > 0) {
      setOptionsIndex(getRandomIndex(options.length));
    }
  }, [loading, options.length, dispatch]); //eslint-disable-line

  useEffect(() => {
    const Jdata = journeyProgress();
    Jdata.then((data) => setJourneyDataProgress(data));
    const getCurrentJourneyDetails = getCurrentJourney();
    getCurrentJourneyDetails.then((val) => {
      setJourneyDataProgress(val.data.data.current_journey_progress);
      setCurrentJourneyCycle(val.data.data.current_journey_cycle);
    });
  }, []);

  return (
    <div>
      <div className="ellipseSettings">
        <Ecllipse3 />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
          position: "absolute",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "4vh"
        }}
      >
        <div
          style={{
            maxWidth: "1536px",
            display: "flex",
            width: "100%",
          }}
        >
          {/* <div style={{ maxHeight: "100vh" }}>
            <img src={backgroundImage} alt="background_image"/>
          </div> */}
          <Box
            sx={{
              width: "100%",
              maxHeight: "calc(95vh - 68px)",
              justifyContent: "center",
            }}
          >
            <div className="topNav">
              <div className="enablicText" style={{ marginLeft: "30px" }}>
                <GameRemote />
                Enablic
              </div>
              <div className="gameRemote">
                <GameRemote />
              </div>
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  margin: "0.5rem 1rem 0px 0px",
                }}
                onClick={() => navigate("/dashboard")}
              >
                <div className="nameTest">Back to Dashboard</div>
                <HomeIcon alt="user" height={30} width={30} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "4vh",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "75%",
                  padding: "4rem 0",
                  background: "rgba(0,0,0,0.7)",
                  borderRadius: "18px",
                }}
              >
                <motion.div
                  initial={{
                    x: "-30px",
                    opacity: "0",
                  }}
                  animate={{
                    x: "0px",
                    opacity: "1",
                    transition: {
                      duration: 0.7,
                    },
                  }}
                >
                  <div className="psychometricTestHeading">
                    Ready for a Psychometric Test
                  </div>
                  <div className="testData">
                    Psychometric tests are assessment tools used to objectively measure an individuals personality traits, aptitude,
                    intelligence, abilities and behavioral style. Psychometric
                    assessments are widelyused in career guidance and employment
                    to match a persons abilities and personality to a suitable
                    career or role.
                  </div>
                </motion.div>
                {loading ? (
                  <div>
                    <Box
                      style={{
                        minHeight: "calc(50vh - 120px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </Box>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <motion.div
                      initial={{
                        x: "-30px",
                        opacity: "0",
                      }}
                      animate={{
                        x: "0px",
                        opacity: "1",
                        transition: {
                          duration: 0.7,
                        },
                      }}
                    >
                      {/* {currentJourneyCycle === -1 ? ( */}
                      <Box mt={3}>
                        <div className="home-play-btn">
                          <button type="submit">
                            <span>Start Now</span>
                          </button>
                        </div>
                      </Box>
                      {/* ) : journeyDataProgress === 100 &&
                    currentJourneyCycle >= 0 ? (
                    <Box mt={3}>
                      <div className="home-play-btn">
                        <button type="submit">
                          <span>Start Now</span>
                        </button>
                      </div>
                    </Box>
                  ) : (
                    <div>
                      <div className="testData info-validation-container">
                        Kindly complete the allocated Enablic Journey to take
                        the Psychometric Test
                      </div>
                      <div className="home-play-btn">
                        <button onClick={handleJourneyNavigate}>
                          <span>Enablic Journey</span>
                        </button>
                      </div>
                    </div>
                  )} */}
                    </motion.div>
                  </form>
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};

export default PsychometricTest;
