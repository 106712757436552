import React from "react";
import Container from "./Container";
import "./CountryAnimalName.css";

function CountryAnimalName() {
  return (
    <div>
      <div>
        <Container style={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default CountryAnimalName;
