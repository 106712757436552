import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Google, Apple } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { signin, authenticate } from "./index";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleSignin } from "../../redux/actions";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Copyright } from "../../assets/icons/copyright.svg";
import { ReactComponent as Ecllipse1 } from "../../assets/icons/ellipse1.svg";
import { ReactComponent as Ecllipse2 } from "../../assets/icons/ellipse2.svg";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import "./Signin.css";
import RingLoader from "react-spinners/RingLoader";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const didRedirect = () => {
    if (
      JSON.parse(localStorage.getItem("jwt")) &&
      JSON.parse(localStorage.getItem("jwt")).data.token
    ) {
      navigate("/dashboard");
    }
  };
  // didRedirect();
  useEffect(() => {
    const didRedirect = () => {
      if (
        JSON.parse(localStorage.getItem("jwt")) &&
        JSON.parse(localStorage.getItem("jwt")).data.token
      ) {
        navigate("/dashboard", { replace: true });
      }
    };
    didRedirect();
  }, [navigate]);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  let [loading, setLoading] = useState(false);

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Please enter a valid Email Id")
          .required("This field is required"),
        // .username("Please enter a valid username Id")
        password: Yup.string().required("No password provided"),
        // .min(8, 'Password is too short - should be 8 chars minimum.')
        // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
      }),
      onSubmit: (values) => {
        setLoading(true);
        const data = signin({
          email: values.email,
          password: values.password,
          device_type: "web",
        });

        data
          .then((val) => {
            // console.log("val", val);
            if (!val.status) {
              setLoading(false);
              setMessage(`${val}`);
              setOpen(true);
              setSeverity("error");
            } else {
              setLoading(false);
              authenticate(
                {
                  ...val,
                },
                () => {
                  didRedirect();
                  dispatch(handleSignin(val));
                }
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            setOpen(true);
            setMessage("Signin request failed");
            setSeverity("error");
          });
      },
    });
  const handleResetPasswordRedirect = () => {
    navigate("/forgotpassword");
  };
  const handleSignUpRedirect = () => {
    navigate("/signup");
  };
  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <div className="ellipse1">
        <Ecllipse1 />
      </div>
      <div className="ellipse2">
        <Ecllipse2 />
      </div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      {loading ? (
        <>
          <div className="loaderCss">
            <RingLoader
              color="#1abcf3"
              cssOverride={override}
              loading={loading}
              size={120}
              speedMultiplier={1}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              padding: 1,
              margin: 1,
              width: "442px",
              height: "90%",
              maxWidth: "90%",
              maxHeight: "calc(95vh - 68px)",
              justifyContent: "center",
              overflowY: "auto",
              borderRadius: "15px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <CardContent>
              <Typography
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "30.5354px",
                  lineHeight: "56px",
                  fontFamily: "Circular Spotify Text",
                  fontWeight: 900,
                }}
                variant="h3"
                component="h3"
              >
                Log In
              </Typography>
              <Typography
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontFamily: "Circular Spotify Text",
                  color: "#81899A",
                  fontWeight: "light",
                  textAlign: "center",
                  maxWidth: "291px",
                  margin: "8px auto 0",
                }}
                variant="h3"
                component="h3"
              >
                Hey! Enter your Details to get login to your account
              </Typography>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "95%", maxwidth: "40ch" },
                }}
                style={{ marginTop: "30px" }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  id="Email-Id"
                  label="Email"
                  variant="outlined"
                  required
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    style={{ marginTop: "0" }}
                    error
                  >{`${errors.email}`}</FormHelperText>
                )}
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClick} edge="end">
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  defaultValue={"sandip@123"}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    style={{ marginTop: "0" }}
                    error
                  >{`${errors.password}`}</FormHelperText>
                )}

                <Link
                  underline="hover"
                  style={{ cursor: "pointer" }}
                  onClick={handleResetPasswordRedirect}
                >
                  Having trouble in signing in?
                </Link>
                <CardActions>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    style={{
                      fontFamily: "Circular Spotify Text",
                      fontWeight: 900,
                      width: "100%",
                      textTransform: "none",
                      height: "50px",
                      marginLeft: "-8px",
                      borderRadius: "8px",
                      background:
                        "radial-gradient(98.44% 1567.43% at 97.46% 50.27%, #7DA9F8 0%, #7C90F0 100%)",
                    }}
                  >
                    Log In
                  </Button>
                </CardActions>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "13.5px",
                    margin: "23px auto",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "34px",
                      height: "1px",
                      backgroundColor: "#000",
                    }}
                  ></div>
                  <Typography
                    sx={{
                      fontSize: "15.2px",
                      lineHeight: "24px",
                      fontFamily: "Circular Spotify Text",
                      fontWeight: 400,
                      color: "#3A3A39",
                    }}
                    variant="h3"
                    component="h3"
                  >
                    Or Sign in with
                  </Typography>
                  <div
                    style={{
                      width: "34px",
                      height: "1px",
                      backgroundColor: "#000",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                  className="signin-other-section"
                >
                  <Button
                    size="large"
                    disabled
                    style={{
                      textTransform: "none",
                      height: "50px",
                      borderRadius: "8px",
                      background: "#fff",
                      border: "0.754076px solid #C4C4C4",
                      color: "#545453de",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "114px",
                      fontSize: "15px",
                    }}
                  >
                    <Google /> Google
                  </Button>
                  <Button
                    size="large"
                    disabled
                    style={{
                      textTransform: "none",
                      height: "50px",
                      borderRadius: "8px",
                      background: "#fff",
                      border: "0.754076px solid #C4C4C4",
                      color: "#545453de",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "114px",
                      fontSize: "15px",
                    }}
                  >
                    <Apple /> Apple ID
                  </Button>
                  <Button
                    size="large"
                    disabled
                    style={{
                      textTransform: "none",
                      height: "50px",
                      borderRadius: "8px",
                      background: "#fff",
                      border: "0.754076px solid #C4C4C4",
                      color: "#545453de",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "114px",
                      fontSize: "15px",
                    }}
                  >
                    <Facebook /> Facebook
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Don't have an account?
                  <Link
                    underline="hover"
                    style={{ cursor: "pointer", padding: "10px" }}
                    onClick={handleSignUpRedirect}
                  >
                    Signup
                  </Link>
                </div>
              </Box>
            </CardContent>
          </Card>
          <CustomizedSnackbars
            open={open}
            message={message}
            severity={severity}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontFamily: "Circular Spotify Text",
              fontWeight: 400,
              gap: "10px",
              margin: "30px auto 10px",
            }}
          >
            <Copyright />
            <span
              style={{ paddingRight: "10px", borderRight: "2px solid #fff" }}
            >
              Copyright @Enablic
            </span>
            <span>Privacy Policy</span>
          </Box>
        </div>
      )}
    </div>
  );
}
