import React from "react";
import { useEffect, useState } from "react";
import { GooglePlay } from "../../common/GooglePlay/GooglePlay";
import { Rotate } from "../../common/Rotate/Rotate";

export const EnablicJourneyMobile = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div>
      {windowWidth > 600 && windowWidth < 950 ? <Rotate /> : <GooglePlay />}
    </div>
  );
};
