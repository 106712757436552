import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MobileViewNavBar from "../../MobileViewNavBar/MobileViewNavBar";
import { GooglePlay } from "../GooglePlay/GooglePlay";
import { gameData } from "./GameData/gameData";
import { Card } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import "./game.css";
import FirstIcon from "./assets/FirstIcon";
import SecondIcon from "./assets/SecondIcon";
import ThirdIcon from "./assets/ThirdIcon";
import { API } from "../../../backend";
import { Rotate } from "../Rotate/Rotate";
import RingLoader from "react-spinners/RingLoader";
import CustomizedSnackbars from "../Snackbar/CustomizedSnackbars";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function createData(position, name, score) {
  if (position === 1) {
    position = <FirstIcon width="40px" />;
  } else if (position === 2) {
    position = <SecondIcon width="40px" />;
  } else if (position === 3) {
    position = <ThirdIcon width="40px" />;
  }
  return { position, name, score };
}

export const GamePlay = ({ sidebarWidth }) => {
  const location = useLocation();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [highScore, setHighScore] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let [loading, setLoading] = useState(true);

  const getGroupedResults = async () => {
    let data = [];
    try {
      const responseData = await axios.get(
        `${API}/games/getByName/${gameData[`${location.state.gameid}`].name}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      responseData.data.data.leaderboard?.scores.forEach((scoreData, i) => {
        if (i < 3) {
          data.push(
            createData(
              i + 1,
              scoreData.user.first_name + " " + scoreData.user.last_name,
              scoreData.score
            )
          );
        }
      });
      const userHighScore = await axios.post(
        `${API}/users/getHighScore`,
        {
          user_id: `${localSigninData?.data?.user?._id}`,
          game_name: `${gameData[`${location.state.gameid}`].name}`,
        },
        {
          "Content-Type": "application/json",
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        }
      );
      setHighScore(userHighScore.data.data);
      // console.log("data ", data);
      setRowData(data);
      setLoading(false);
    } catch (err) {
      setOpen(true);
      setMessage(err.message);
      setSeverity("error");
    }
  };

  useEffect(() => {
    Promise.all([getGroupedResults()]).then(() => {
      // setIsLoading(false);
    });
  }, []); //eslint-disable-line

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  return (
    // console.log("location ", location.state.gameid),
    // console.log("rowData ", rowData),
    <div>
      <div className="webView">
        <div
          style={{
            marginLeft: sidebarWidth,
            width: `calc(100% - ${sidebarWidth}px)`,
            display: "flex",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#ffffff",
            }}
          >
            <div style={{ width: "100%" }}>
              {gameData[`${location.state.gameid}`].game}
            </div>
          </div>
          <div className="leaderScoreSection">
            <>
              <Card className="leaderBoardCard">
                <div className="leaderboardHeader">
                  <div className="leaderboard">Leader Board</div>
                </div>
                {loading ? (
                  <>
                    <div className="leaderBoardLoaderCss">
                      <RingLoader
                        color="#1abcf3"
                        cssOverride={override}
                        loading={loading}
                        size={45}
                        speedMultiplier={1}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          minWidth: 250,
                          background: rowData.length > 0 ? "#352839" : "",
                        }}
                        size="small"
                        aria-label="a dense table"
                      >
                        {rowData.length > 0 ? (
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Rank
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Score
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        ) : (
                          ""
                        )}
                        <TableBody>
                          {rowData.length > 0 ? (
                            rowData.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  {row.position}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "80px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {row.name}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  {row.score}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <div className="comments">
                              <div>--</div>
                            </div>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="highScore">
                      Your Highest Score: {highScore}
                    </div>
                  </>
                )}
              </Card>
            </>
          </div>
        </div>
      </div>
      <div className="mobileView">
        <MobileViewNavBar />
        {windowWidth > 600 && windowWidth < 950 ? <Rotate /> : <GooglePlay />}
      </div>
      <CustomizedSnackbars open={open} message={message} severity={severity} />
    </div>
  );
};
