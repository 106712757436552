import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { countries } from "../Signup/data/countries";
import { update } from "./index";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import { motion } from "framer-motion";

const ProfileForm = (props) => {
  const avatars = [
    {
      id: 1,
      name: "unicorn",
      url: "https://images.emojiterra.com/google/android-10/512px/1f984.png",
    },
    {
      id: 2,
      name: "astronaut",
      url: "https://images.emojiterra.com/google/android-10/512px/1f468-1f680.png",
    },
    {
      id: 3,
      name: "fox",
      url: "https://images.emojiterra.com/google/android-11/512px/1f98a.png",
    },
    {
      id: 4,
      name: "health_worker",
      url: "https://images.emojiterra.com/google/android-11/512px/1f9d1-2695.png",
    },
    {
      id: 5,
      name: "firefighter",
      url: "https://images.emojiterra.com/google/android-12l/512px/1f9d1-1f692.png",
    },
  ];
  const [initialValues] = React.useState(props.initialValues);
  const [edit, setEdit] = React.useState({
    profileAvatar: false,
    firstName: false,
    lastName: false,
    email: false,
    birthDate: false,
    country: false,
  });
  const [value, setValue] = React.useState(props.value);
  const date = `${dayjs(`${dayjs().subtract(2, "year").format()}`).format(
    "YYYY-MM-DD"
  )}`;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [avatarPicker, setAvatarPicker] = React.useState(false);
  const [previewAvatar, setPreviewAvatar] = React.useState("");

  const handleDatePickerOpen = () => {
    setDatePickerOpen(true);
    setEdit((prevState) => ({
      ...prevState,
      birthDate: true,
    }));
  };

  const handleDatePickerClose = () => {
    setEdit((prevState) => ({
      ...prevState,
      birthDate: false,
    }));
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "First Name must have alteast 3 characters")
        .required("This field is required"),
      lastName: Yup.string().min(3, "Last Name must have 3 characters"),
      email: Yup.string()
        .email("Please enter a valid Email Id")
        .required("This field is required"),
      birthDate: Yup.date()
        .typeError("please enter a valid date")
        .required()
        .min("1930-01-01", "Date is too early")
        .max(date, "Invalid Date of Birth"),
      country: Yup.string()
        .required("Please select your country")
        .min(2, "Please select your country"),
    }),
    onSubmit: (values) => {
      dayjs.extend(utc);
      // console.log("values", values);
      props.setLoading(true);
      setEdit((prevState) => ({
        ...prevState,
        profileAvatar: false,
        firstName: false,
        lastName: false,
        email: false,
        birthDate: false,
        country: false,
      }));
      // console.log("values: ", values);
      const data = update({
        profile_emoji: previewAvatar.name,
        first_name: values.firstName,
        last_name: values.lastName,
        country: values.country,
        dob: dayjs(
          dayjs(values.birthDate).format("MM/DD/YYYY"),
          "MM-DD-YYYY"
        ).toISOString(),
      });
      data.then((val) => {
        // console.log("val: ", dayjs(val.data.dob).local().format("MM/DD/YYYY"));
        if (val.error) {
          props.setLoading(false);
          setOpen(true);
          setSeverity("error");
          setMessage(`${data.error}`);
        } else {
          props.setValue(dayjs(val.data.dob).local().format("MM/DD/YYYY"));
          props.setInitialValues({
            ...props.initialValues,
            firstName: val.data.first_name,
            lastName: val.data.last_name,
            birthDate: value,
            country: val.data.country,
          });
          setOpen(true);
          setSeverity("success");
          setMessage("Profile updated successfully!");
          props.setLoading(false);
        }
      });
      props.setLoading(false);
    },
  });

  return initialValues === undefined ? (
    <></>
  ) : (
    // console.log("initial values avatar URL: ", initialValues.profileAvatar && avatars.filter((e) => e.name === initialValues.profileAvatar)[0].url),
    <>
      <Box
        component="form"
        sx={{
          "& > :not(style)": {
            m: 1,
            width: "95%",
            maxwidth: "40ch",
            height: "50%",
          },
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="profileAvatar"
          name="profileAvatar"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.profileAvatar}
        >
          <div
            className="profileIcon"
            style={{
              border: "3px solid #ffd600",
              borderRadius: "50%",
              width: "fit-content",
              padding: "1rem",
              marginBottom: "2rem",
              backgroundColor: "rgba(233,233,233,0.23)",
              position: "relative",
            }}
          >
            <img
              src={
                previewAvatar
                  ? previewAvatar.url
                  : initialValues.profileAvatar
                  ? avatars.filter(
                      (e) => e.name === initialValues.profileAvatar
                    )[0].url
                  : "https://images.emojiterra.com/openmoji/v13.1/512px/2754.png"
              }
              alt="profile_avatar"
              style={{
                width: "80px",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "2.2rem",
                height: "2.2rem",
                background: "#8F547D",
                position: "absolute",
                borderRadius: "50%",
                bottom: "-7px",
                right: 0,
              }}
            >
              <div
                style={{ cursor: "pointer", marginTop: "5px" }}
                onClick={() =>
                  edit.profileAvatar
                    ? setEdit((prevState) => ({
                        ...prevState,
                        profileAvatar: false,
                      }))
                    : (setEdit((prevState) => ({
                        ...prevState,
                        profileAvatar: true,
                      })),
                      setAvatarPicker(true))
                }
              >
                <Tooltip title={edit.profileAvatar ? "Cancel" : "Edit"} arrow>
                  {edit.profileAvatar ? (
                    <CancelIcon
                      sx={{
                        height: "22px",
                        color: "#f1f1f1",
                      }}
                    />
                  ) : (
                    <EditIcon
                      sx={{
                        height: "22px",
                        color: "#f1f1f1",
                      }}
                    />
                  )}
                </Tooltip>
              </div>
              <Modal
                open={avatarPicker}
                onClose={() => setAvatarPicker(false)}
                slots={{ backdrop: Backdrop }}
                closeAfterTransition
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={avatarPicker}>
                  <div className="avatar-modal-card">
                    <Card
                      sx={{
                        padding: "1rem",
                        overflow: "auto",
                        background: "#b1b1b1",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontWeight={700}
                        component="div"
                        paddingLeft={"1rem"}
                        sx={{ color: "#464646", display: "block" }}
                      >
                        Pick your profile avatar
                      </Typography>
                      <CardContent
                        className="avatar-emoji"
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {avatars.map((avatar) => (
                          <motion.div
                            whileHover={{
                              scale: 1.1,
                            }}
                          >
                            <Avatar
                              key={avatar.id}
                              alt={avatar.name}
                              src={avatar.url}
                              sx={{
                                width: "100px",
                                height: "100px",
                                border: "2px solid #626262",
                                padding: "0.5rem",
                                background: "#d0d0d0",
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setPreviewAvatar(avatar), setAvatarPicker(false)
                              )}
                            />
                          </motion.div>
                        ))}
                      </CardContent>
                    </Card>
                  </div>
                </Fade>
              </Modal>
            </div>
          </div>
        </div>
        <TextField
          sx={{
            "& .MuiInputLabel-root": {
              color: "#f1f1f1",
              "&.Mui-focused": {
                color: "#f1f1f1",
              },
              "&.Mui-disabled": {
                color: "#f1f1f1",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#f1f1f1",
              },
              "&:hover fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-disabled fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
              "&.Mui-disabled:hover fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
            },
            "& .MuiInputBase-input": {
              color: "#f1f1f1",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "rgba(255,255,255,0.5)",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={edit.firstName ? "Cancel" : "Edit"} arrow>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setEdit((prevState) => ({
                        ...prevState,
                        firstName: !prevState.firstName,
                      }))
                    }
                    sx={{ color: "#f1f1f1" }}
                  >
                    {edit.firstName ? <CancelIcon /> : <EditIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          id="firstName"
          label="First Name"
          fullWidth
          disabled={edit.firstName ? false : true}
          variant="outlined"
          name="firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstName}
        />
        {touched.firstName && errors.firstName && (
          <FormHelperText
            style={{ marginTop: "0" }}
            error
          >{`${errors.firstName}`}</FormHelperText>
        )}
        <TextField
          sx={{
            "& .MuiInputLabel-root": {
              color: "#f1f1f1",
              "&.Mui-focused": {
                color: "#f1f1f1",
              },
              "&.Mui-disabled": {
                color: "#f1f1f1",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#f1f1f1",
              },
              "&:hover fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-disabled fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
              "&.Mui-disabled:hover fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
            },
            "& .MuiInputBase-input": {
              color: "#f1f1f1",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "rgba(255,255,255,0.5)",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={edit.lastName ? "Cancel" : "Edit"} arrow>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setEdit((prevState) => ({
                        ...prevState,
                        lastName: !prevState.lastName,
                      }))
                    }
                    sx={{ color: "#f1f1f1" }}
                  >
                    {edit.lastName ? <CancelIcon /> : <EditIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          id="lastName"
          label="Last Name"
          fullWidth
          disabled={edit.lastName ? false : true}
          variant="outlined"
          name="lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
        />
        {touched.lastName && errors.lastName && (
          <FormHelperText
            style={{ marginTop: "0" }}
            error
          >{`${errors.lastName}`}</FormHelperText>
        )}
        <TextField
          sx={{
            "& .MuiInputLabel-root": {
              color: "#f1f1f1",
              "&.Mui-focused": {
                color: "#f1f1f1",
              },
              "&.Mui-disabled": {
                color: "#f1f1f1",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#f1f1f1",
              },
              "&:hover fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#f1f1f1",
              },
              "&.Mui-disabled fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
              "&.Mui-disabled:hover fieldset": {
                borderColor: "rgba(255,255,255,0.5)",
              },
            },
            "& .MuiInputBase-input": {
              color: "#f1f1f1",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "rgba(255,255,255,0.5)",
            },
          }}
          id="Email-Id"
          label="Email"
          variant="outlined"
          disabled={true}
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
        />
        {touched.email && errors.email && (
          <FormHelperText
            style={{ marginTop: "0" }}
            error
          >{`${errors.email}`}</FormHelperText>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <MobileDatePicker
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={edit.birthDate ? "Cancel" : "Edit"} arrow>
                      <IconButton
                        edge="end"
                        onClick={
                          edit.birthDate
                            ? handleDatePickerClose
                            : handleDatePickerOpen
                        }
                        sx={{ color: "#f1f1f1" }}
                      >
                        {edit.birthDate ? <CancelIcon /> : <EditIcon />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              disabled={edit.birthDate ? false : true}
              open={datePickerOpen}
              onClose={() => {
                setDatePickerOpen(false);
              }}
              label="Birth Date"
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={(newValue) => {
                // console.log(newValue)
                setValue(newValue);
              }}
              renderInput={(params) => {
                values.birthDate = params.inputProps.value;
                return (
                  <TextField
                    style={{ marginTop: "0px" }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#f1f1f1",
                        "&.Mui-focused": {
                          color: "#f1f1f1",
                        },
                        "&.Mui-disabled": {
                          color: "#f1f1f1",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#f1f1f1",
                        },
                        "&:hover fieldset": {
                          borderColor: "#f1f1f1",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#f1f1f1",
                        },
                        "&.Mui-disabled fieldset": {
                          borderColor: "rgba(255,255,255,0.5)",
                        },
                        "&.Mui-disabled:hover fieldset": {
                          borderColor: "rgba(255,255,255,0.5)",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#f1f1f1",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgba(255,255,255,0.5)",
                      },
                    }}
                    {...params}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={params.inputProps.value}
                  />
                );
              }}
            />
          </Stack>
          {touched.birthDate && errors.birthDate && (
            <FormHelperText
              style={{ marginTop: "0" }}
              error
            >{`${errors.birthDate}`}</FormHelperText>
          )}
        </LocalizationProvider>
        <Box sx={{ display: "inline-flex" }}>
          <Box className="autocomplete-box">
            <Autocomplete
              disabled={edit.country ? false : true}
              id="country"
              name="country"
              fullWidth
              value={values.country}
              inputValue={values.country}
              sx={{ display: "inline-flex" }}
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.label}
              onBlur={handleBlur}
              onChange={(e, value) => {
                setFieldValue("country", value !== null ? value.label : "");
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRight: "0px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          borderColor: "#f1f1f1",
                        },
                        "&:hover fieldset": {
                          borderColor: "#f1f1f1",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#f1f1f1",
                        },
                        "&.Mui-disabled fieldset": {
                          borderColor: "rgba(255,255,255,0.5)",
                        },
                        "&.Mui-disabled:hover fieldset": {
                          borderColor: "rgba(255,255,255,0.5)",
                        },
                      },

                      "& .MuiInputLabel-root": {
                        color: "#f1f1f1",
                        "&.Mui-focused": {
                          color: "#f1f1f1",
                        },
                        "&.Mui-disabled": {
                          color: "#f1f1f1",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "#f1f1f1",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgba(255,255,255,0.5)",
                      },
                    }}
                    fullWidth
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                </>
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: edit.country
                ? "1px solid #f1f1f1"
                : "1px solid rgba(255,255,255,0.5)",
              borderLeft: "0px",
              borderRadius: "4px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              paddingRight: edit.country ? "14px" : "9px",
            }}
          >
            <InputAdornment
              position="end"
              sx={{ paddingRight: edit.country ? "0px" : "6px" }}
            >
              <Tooltip title={edit.country ? "Cancel" : "Edit"} arrow>
                <IconButton
                  edge="end"
                  sx={{
                    marginRight: "-6px",
                    paddingLeft: edit.country ? "8px" : "",
                    width: "24px",
                    color: "#f1f1f1",
                  }}
                  onClick={() =>
                    setEdit((prevState) => ({
                      ...prevState,
                      country: !prevState.country,
                    }))
                  }
                >
                  {edit.country ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          </Box>
        </Box>
        {touched.country && errors.country && (
          <FormHelperText
            style={{ marginTop: "0" }}
            error
          >{`${errors.country}`}</FormHelperText>
        )}
        <div
          className="home-play-btn"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "0.2rem",
            marginTop: "0px",
          }}
        >
          <Button
            // type="submit"
            onClick={handleSubmit}
            disabled={
              edit.profileAvatar ||
              edit.firstName ||
              edit.lastName ||
              edit.email ||
              edit.birthDate ||
              edit.country
                ? false
                : true
            }
            style={{
              width: "125px",
              fontSize: "1rem",
              padding: "5px",
              marginTop: "15px",
              background:
                edit.profileAvatar ||
                edit.firstName ||
                edit.lastName ||
                edit.email ||
                edit.birthDate ||
                edit.country
                  ? ""
                  : "radial-gradient(98.44% 1567.43% at 97.46% 50.27%,#d3d3d3 0%,#e5e5e5 100%)",
              color:
                edit.profileAvatar ||
                edit.firstName ||
                edit.lastName ||
                edit.email ||
                edit.birthDate ||
                edit.country
                  ? ""
                  : "#7c90f0",
            }}
          >
            Update
          </Button>
        </div>
      </Box>
      <CustomizedSnackbars open={open} message={message} severity={severity} />
    </>
  );
};

export default ProfileForm;
