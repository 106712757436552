import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Spin as Hamburger } from "hamburger-react";
import { ReactComponent as EnablicIcon } from "../../assets/icons/enablic.svg";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

function MobileViewTopNavBar() {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"))?.data?.token;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const listItemData = [
    { label: "Home", link: "/", icon: <HomeIcon /> },
    { label: "Already A Member", link: "/signin", icon: <LoginIcon /> },
    { label: "New Here", link: "/signup", icon: <PersonAddIcon /> },
  ];
  const signedInListItemData = [
    { label: "Home", link: "/", icon: <HomeIcon /> },
  ];

  const menuVariants = {
    opened: {
      top: 71,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    closed: {
      top: "-100vh",
    },
  };

  const linkVariants = {
    opened: {
      opacity: 1,
      //   y: 50,
    },
    closed: {
      opacity: 0,
      y: 0,
    },
  };

  useEffect(() => {
    console.log(isOpen)
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <div>
      <motion.div className="header">
        <motion.div
          animate={isOpen ? "opened" : "closed"}
          whileHover={{ scale: 1.4 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Hamburger direction="left" color="#FFFFFF" />
        </motion.div>
        <motion.div whileHover={{ scale: 1.4 }}>
          <EnablicIcon />
        </motion.div>
      </motion.div>

      <motion.div
        initial={false}
        variants={menuVariants}
        animate={isOpen ? "opened" : "closed"}
        className="navbar"
      >
        {!localSigninData ? (
          <>
            {listItemData.map((data) => (
              <motion.div
                className="listStyle"
                variants={linkVariants}
                onClick={() => {
                  setIsOpen(false);
                  // console.log("on close");
                  navigate(`${data.link}`);
                }}
              >
                {data.label}
              </motion.div>
            ))}
          </>
        ) : (
          <>
            {signedInListItemData.map((data) => (
              <motion.div
                className="listStyle"
                variants={linkVariants}
                onClick={() => {
                  setIsOpen(false);
                  // console.log("on close");
                  navigate(`${data.link}`);
                }}
              >
                {data.label}
              </motion.div>
            ))}
          </>
        )}
      </motion.div>
    </div>
  );
}

export default MobileViewTopNavBar;
