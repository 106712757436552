import { Typography, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleChangeTestOver } from "../../../redux/actions";

const TIMER = {
  PAUSED: "PAUSED",
  TICKING: "TICKING",
};

export default function Counter(props) {
  const { score, testOver } = useSelector((state) => state);
  const dispatch = useDispatch();

  //   console.log("props", props);
  const [text] = useState(props.duration);
  const [current, setCurrent] = useState(null);
  const [timerState, setTimerState] = useState(TIMER.PAUSED);

  function pause() {
    setTimerState(TIMER.PAUSED);
  }

  useEffect(() => {
    let intervalId;

    function reset() {
      pause();
      setCurrent(null);
    }

    function pauseTimer() {
      clearInterval(intervalId);
    }

    function tick() {
      setCurrent((current) => {
        if (current === 1) {
          reset();
        }

        return current - 1;
      });
    }
    function isTicking() {
      return timerState === TIMER.TICKING;
    }
    switch (timerState) {
      case TIMER.PAUSED:
        break;
      case TIMER.TICKING:
        intervalId = setInterval(tick, 1000);
        break;
      default:
        break;
    }

    function handleStart() {
      if (isTicking()) {
        return;
      }

      if (!current) {
        setCurrent(Number(text));
      }

      setTimerState(TIMER.TICKING);
    }
    if (props.start && current !== 1) {
      handleStart();
      //   console.log("current", current);
    } else {
      pauseTimer();
      if (typeof props.updateScore === "function") {
        props.updateScore();
      }
      dispatch(handleChangeTestOver(true));
      //   console.log("score", score);
      // console.log("testOver", testOver);
    }
    return pauseTimer;
  }, [timerState, props.start, current, text, dispatch, score, testOver]); //eslint-disable-line

  return (
    <div>
      <Box>
        <Typography variant="h6" color="#E3242B">
          {current}
        </Typography>
      </Box>
    </div>
  );
}
