import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Star from "../../../../assets/icons/Star";

import "./ProgressBarCircular.css";

import { useSelector } from "react-redux";

import { journeyProgress, getCurrentJourney } from "../../EnablicJourney";

export default function ProgressBarCircular() {
  const { changeStartGame } = useSelector((state) => state);

  const [percentage, setPecentage] = useState(0);
  const [noOfCycles, setNoOfCycles] = useState(0);

  // fetching total game data
  useEffect(() => {
    (async () => {
      const gamePercentRes = await journeyProgress();
      setPecentage(gamePercentRes);

      const NoOfGamesRes = await getCurrentJourney();

      //setting journey cycles
      setNoOfCycles(NoOfGamesRes.data.data.current_journey_cycle);
    })();
  }, [changeStartGame]);

  return (
    // console.log("changeStartGame progress", changeStartGame),
    (
      <div className="progress-container-with-image">
        <div className="circular-progress-container">
          <CircularProgressbar value={percentage} text={`${66}%`} />
          <div className="inner-circle">
            <div className="inner-circle-top-child">
              Level
              <div className="level-star-container">
                <Star width={32} />
                <span>{noOfCycles}</span>
              </div>
            </div>
            <div className="inner-circle-line" />
            <div className="inner-circle-bottom-child">{`${percentage}%`}</div>
          </div>
        </div>
        <div className="brain-image-container">
          <img src="/assets/scrore_brain.png" alt="" width={100} />
        </div>
      </div>
    )
  );
}
