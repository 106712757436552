export const pathUrl="https://medias.enablic.co.uk/video/mp4/13/";

export const solutions = [
  {video: {url: `58589.mp4`},word: "Bright",},
  {video: {url: `93088.mp4`},word: "Glow",},
  {video: {url: `72920.mp4`},word: "Fog light",},
  {video: {url: `245041.mp4`},word: "Visible light",},
  {video: {url: `48559.mp4`},word: "Light",},
  {video: {url: `116481.mp4`},word: "Ray",},
  {video: {url: `186031.mp4`},word: "Neon lamp",},
  {video: {url: `97017.mp4`},word: "Beam",},
  {video: {url: `76939.mp4`},word: "Headlight switch",},
  {video: {url: `62874.mp4`},word: "Light",},
  {video: {url: `125920.mp4`},word: "Chandelier",},
  {video: {url: `65949.mp4`},word: "Bulb",},
  {video: {url: `62862.mp4`},word: "Lamp holder",},
  {video: {url: `93029.mp4`},word: "Flashlight",},
  {video: {url: `125754.mp4`},word: "Candle",},
  {video: {url: `93961.mp4`},word: "Lighthouse",},
  {video: {url: `245938.mp4`},word: "Natural light",},
  {video: {url: `117022.mp4`},word: "Steady lights",},
  {video: {url: `58465.mp4`},word: "Lamp",},
  {video: {url: `186015.mp4`},word: "Luminosity",},
  {video: {url: `244365.mp4`},word: "Dimmer",},
  {video: {url: `90100.mp4`},word: "Torch",},
  {video: {url: `62682.mp4`},word: "Candle snuffer",},
  {video: {url: `91519.mp4`},word: "Match",},
  {video: {url: `116988.mp4`},word: "Spotlight",},
  {video: {url: `93964.mp4`},word: "Louder",},
  {video: {url: `49000.mp4`},word: "Noisy",},
  {video: {url: `89853.mp4`},word: "Make a noise",},
  {video: {url: `94095.mp4`},word: "Decibel",},
  {video: {url: `245228.mp4`},word: "Sound pressure",},
  {video: {url: `123254.mp4`},word: "Bang",},
  {video: {url: `72318.mp4`},word: "Alarm",},
  {video: {url: `246040.mp4`},word: "Loudness",},
  {video: {url: `244140.mp4`},word: "Buzz",},
  {video: {url: `187531.mp4`},word: "Hertz",},
  {video: {url: `69416.mp4`},word: "Microphone",},
  {video: {url: `93338.mp4`},word: "Earmold",},
  {video: {url: `58468.mp4`},word: "Noise",},
  {video: {url: `189675.mp4`},word: "Megaphone",},
  {video: {url: `93341.mp4`},word: "Earplugs",},
  {video: {url: `63624.mp4`},word: "Off",},
  {video: {url: `89875.mp4`},word: "On",},
  {video: {url: `94396.mp4`},word: "Nuclear",},
  {video: {url: `69333.mp4`},word: "Electromagnetic",},
  {video: {url: `63608.mp4`},word: "Electrical",},
  {video: {url: `158015.mp4`},word: "Rechargeable",},
  {video: {url: `69453.mp4`},word: "Three phase",},
  {video: {url: `63607.mp4`},word: "Electric",},
  {video: {url: `244328.mp4`},word: "Cut-off",},
  {video: {url: `69452.mp4`},word: "Switch on",},
  {video: {url: `65959.mp4`},word: "Disconnect",},
  {video: {url: `63602.mp4`},word: "Connect",},
  {video: {url: `69457.mp4`},word: "Turn on",},
  {video: {url: `69451.mp4`},word: "Switch off",},
  {video: {url: `69456.mp4`},word: "Turn off",},
  {video: {url: `154653.mp4`},word: "Fuse box",},
  {video: {url: `186352.mp4`},word: "Contactor",},
  {video: {url: `65955.mp4`},word: "Cross connection",},
  {video: {url: `186357.mp4`},word: "Current",},
  {video: {url: `245233.mp4`},word: "Solar cell",},
  {video: {url: `245232.mp4`},word: "Solar heating",},
  {video: {url: `72320.mp4`},word: "Alternator",},
  {video: {url: `94389.mp4`},word: "Cell",},
  {video: {url: `187528.mp4`},word: "Heavy current",},
  {video: {url: `69343.mp4`},word: "Idle power",},
  {video: {url: `94393.mp4`},word: "Generator",},
  {video: {url: `187527.mp4`},word: "Heating element",},
  {video: {url: `69447.mp4`},word: "Solar power station",},
  {video: {url: `69448.mp4`},word: "Specific resistivity",},
  {video: {url: `94398.mp4`},word: "Star connection",},
  {video: {url: `69443.mp4`},word: "Socket",},
  {video: {url: `69445.mp4`},word: "Solar energy",},
  {video: {url: `69446.mp4`},word: "Solar panel",},
  {video: {url: `69430.mp4`},word: "Positive charge",},
  {video: {url: `244409.mp4`},word: "Electrics",},
  {video: {url: `157955.mp4`},word: "Power supply",},
  {video: {url: `116383.mp4`},word: "Power generator",},
  {video: {url: `116385.mp4`},word: "Power surge strip",},
  {video: {url: `244455.mp4`},word: "Energy",},
  {video: {url: `244454.mp4`},word: "Energy balance",},
  {video: {url: `63612.mp4`},word: "Energy",},
  {video: {url: `65942.mp4`},word: "Ampere",},
  {video: {url: `65943.mp4`},word: "Amperehour",},
  {video: {url: `186120.mp4`},word: "Thermistor",},
  {video: {url: `186249.mp4`},word: "Amplification",},
  {video: {url: `186251.mp4`},word: "Amplitude",},
  {video: {url: `186253.mp4`},word: "Anode",},
  {video: {url: `69450.mp4`},word: "Switch",},
  {video: {url: `65946.mp4`},word: "Apparent power",},
  {video: {url: `69418.mp4`},word: "Negative charge",},
  {video: {url: `94391.mp4`},word: "Circuit",},
  {video: {url: `96352.mp4`},word: "Extension cord",},
  {video: {url: `187555.mp4`},word: "Insulation resistance",},
  {video: {url: `187559.mp4`},word: "Integrated circuit",},
  {video: {url: `125656.mp4`},word: "Cable duct",},
  {video: {url: `187554.mp4`},word: "Insulating tape",},
  {video: {url: `69412.mp4`},word: "Isolator",},
  {video: {url: `187565.mp4`},word: "Kilowat",},
  {video: {url: `69410.mp4`},word: "Internal resistance",},
  {video: {url: `187562.mp4`},word: "Ion",},
  {video: {url: `69455.mp4`},word: "Transformer",},
  {video: {url: `186143.mp4`},word: "Transistor",},
  {video: {url: `186313.mp4`},word: "Capacitor",},
  {video: {url: `65952.mp4`},word: "Capacitive sensors",},
  {video: {url: `65951.mp4`},word: "Capacitance",},
  {video: {url: `58444.mp4`},word: "Electronics",},
  {video: {url: `69461.mp4`},word: "Wave",},
  {video: {url: `69465.mp4`},word: "Wiring",},
  {video: {url: `245855.mp4`},word: "Parallel connection",},
  {video: {url: `65944.mp4`},word: "Amperemeter",},
  {video: {url: `186229.mp4`},word: "Voltampere",},
  {video: {url: `186226.mp4`},word: "Voltage regulator",},
  {video: {url: `186230.mp4`},word: "Voltmeter",},
  {video: {url: `154577.mp4`},word: "Energy level",},
  {video: {url: `65947.mp4`},word: "Battery cell",},
  {video: {url: `186227.mp4`},word: "Voltage divider",},
  {video: {url: `186228.mp4`},word: "Voltage",},
  {video: {url: `186214.mp4`},word: "Watt",},
  {video: {url: `69341.mp4`},word: "Fuse",},
  {video: {url: `186239.mp4`},word: "Alternating current",},
  {video: {url: `187541.mp4`},word: "Induction",},
  {video: {url: `187540.mp4`},word: "Inductance",},
  {video: {url: `187544.mp4`},word: "Inductor",},
  {video: {url: `187542.mp4`},word: "Inductive sensor",},
  {video: {url: `157869.mp4`},word: "Nuclear energy",},
  {video: {url: `157870.mp4`},word: "Nuclear power",},
  {video: {url: `186335.mp4`},word: "Commutator",},
  {video: {url: `94397.mp4`},word: "Nuclear power station",},
  {video: {url: `63610.mp4`},word: "Electrician",},
  {video: {url: `186041.mp4`},word: "Ohm",},
  {video: {url: `186043.mp4`},word: "Ohmmeter",},
  {video: {url: `186045.mp4`},word: "Oscilloscope",},
  {video: {url: `65941.mp4`},word: "Admittance",},
  {video: {url: `118434.mp4`},word: "Ammeter",},
  {video: {url: `72536.mp4`},word: "Chassis",},
  {video: {url: `187192.mp4`},word: "Geothermal energy",},
  {video: {url: `65962.mp4`},word: "Electric cable",},
  {video: {url: `65963.mp4`},word: "Electric charge",},
  {video: {url: `77030.mp4`},word: "Overload",},
  {video: {url: `187453.mp4`},word: "Dynamometer wattmeter",},
  {video: {url: `63606.mp4`},word: "Earth",},
  {video: {url: `65961.mp4`},word: "Efficiency",},
  {video: {url: `69449.mp4`},word: "Steady current",},
  {video: {url: `186019.mp4`},word: "Main contactor",},
  {video: {url: `186012.mp4`},word: "Low current",},
  {video: {url: `186225.mp4`},word: "Volt",},
  {video: {url: `65968.mp4`},word: "Electric motor",},
  {video: {url: `73341.mp4`},word: "Non-renewable energy resources",},
  {video: {url: `69432.mp4`},word: "Potential",},
  {video: {url: `69413.mp4`},word: "Loop",},
  {video: {url: `69429.mp4`},word: "Plug",},
  {video: {url: `69433.mp4`},word: "Power factor",},
  {video: {url: `63625.mp4`},word: "Power",},
  {video: {url: `63611.mp4`},word: "Electricity",},
  {video: {url: `186331.mp4`},word: "Circuit breaker",},
  {video: {url: `65957.mp4`},word: "Digital meter",},
  {video: {url: `186360.mp4`},word: "Dielectric",},
  {video: {url: `63604.mp4`},word: "Delay",},
  {video: {url: `65958.mp4`},word: "Discharge",},
  {video: {url: `191285.mp4`},word: "Direct current",},
  {video: {url: `186364.mp4`},word: "Diode",},
  {video: {url: `92877.mp4`},word: "Microwave",},
  {video: {url: `69335.mp4`},word: "Electromagnetic force",},
  {video: {url: `69336.mp4`},word: "Electromagnetic wave",},
  {video: {url: `69334.mp4`},word: "Electromagnetic field",},
  {video: {url: `187495.mp4`},word: "Electrolyte",},
  {video: {url: `69332.mp4`},word: "Electromagnet",},
  {video: {url: `69435.mp4`},word: "Relay",},
  {video: {url: `187493.mp4`},word: "Electrolysis",},
  {video: {url: `186077.mp4`},word: "Rectifier circuit",},
  {video: {url: `69434.mp4`},word: "Regulation",},
  {video: {url: `116457.mp4`},word: "Pylons",},
  {video: {url: `123602.mp4`},word: "Battery",},
  {video: {url: `65964.mp4`},word: "Electric circuit",},
  {video: {url: `65967.mp4`},word: "Electric machine",},
  {video: {url: `65966.mp4`},word: "Electric field",},
  {video: {url: `187488.mp4`},word: "Electrode",},
  {video: {url: `63609.mp4`},word: "Electrical power",},
  {video: {url: `65969.mp4`},word: "Electrical conductivity",},
  {video: {url: `69440.mp4`},word: "Resistor",},
  {video: {url: `69439.mp4`},word: "Resistance",},
  {video: {url: `186083.mp4`},word: "Rheostat",},
  {video: {url: `186087.mp4`},word: "Serial connection",},
  {video: {url: `186086.mp4`},word: "Semiconductor",},
  {video: {url: `186089.mp4`},word: "Short circuit",},
  {video: {url: `186088.mp4`},word: "Series",},
  {video: {url: `186320.mp4`},word: "Cathode",},
  {video: {url: `72382.mp4`},word: "Battery charger",},
  {video: {url: `118383.mp4`},word: "Air pressure",},
  {video: {url: `118381.mp4`},word: "Air jet",},
  {video: {url: `153052.mp4`},word: "Air-conditioning plant",},
  {video: {url: `153050.mp4`},word: "Air quality",},
  {video: {url: `51071.mp4`},word: "Wind",},
  {video: {url: `153051.mp4`},word: "Air temperature",},
  {video: {url: `69342.mp4`},word: "Hydroelectric power station",},
  {video: {url: `118379.mp4`},word: "Air grille",},
  {video: {url: `72785.mp4`},word: "Damper",},
  {video: {url: `92590.mp4`},word: "Air conditioning",},
  {video: {url: `69462.mp4`},word: "Wind energy",},
  {video: {url: `69463.mp4`},word: "Wind power station",},
  {video: {url: `186220.mp4`},word: "Wind turbine",},
  {video: {url: `89613.mp4`},word: "Windmill",},
  {video: {url: `89614.mp4`},word: "Windpower",},
  {video: {url: `118386.mp4`},word: "Air motor",},
  {video: {url: `69460.mp4`},word: "Water wheel",},
  {video: {url: `72314.mp4`},word: "Air conditioner",},
  {video: {url: `72313.mp4`},word: "Air suspension",},
  {video: {url: `72315.mp4`},word: "Air-mass sensor",},
  {video: {url: `72389.mp4`},word: "Blower",},
  {video: {url: `93957.mp4`},word: "Dying",},
  {video: {url: `96101.mp4`},word: "Conscious",},
  {video: {url: `58409.mp4`},word: "Healthy",},
  {video: {url: `118400.mp4`},word: "Alive",},
  {video: {url: `89888.mp4`},word: "Overweight",},
  {video: {url: `96992.mp4`},word: "Vital",},
  {video: {url: `245152.mp4`},word: "Tactile",},
  {video: {url: `124284.mp4`},word: "Benign",},
  {video: {url: `51443.mp4`},word: "Chubby",},
  {video: {url: `118431.mp4`},word: "Ambulatory",},
  {video: {url: `125536.mp4`},word: "Breathe",},
  {video: {url: `185014.mp4`},word: "Consult",},
  {video: {url: `48576.mp4`},word: "Live",},
  {video: {url: `51944.mp4`},word: "Help",},
  {video: {url: `62500.mp4`},word: "Assist",},
  {video: {url: `96611.mp4`},word: "Infect",},
  {video: {url: `117013.mp4`},word: "Starve",},
  {video: {url: `116214.mp4`},word: "Paralyze",},
  {video: {url: `117109.mp4`},word: "Survive",},
  {video: {url: `93318.mp4`},word: "Donate blood",},
  {video: {url: `91769.mp4`},word: "Get better",},
  {video: {url: `91727.mp4`},word: "Confidentiality",},
  {video: {url: `118378.mp4`},word: "Air filter",},
  {video: {url: `185012.mp4`},word: "Consciousness",},
  {video: {url: `244873.mp4`},word: "Meditation",},
  {video: {url: `116376.mp4`},word: "Posture",},
  {video: {url: `245739.mp4`},word: "Public health",},
  {video: {url: `58261.mp4`},word: "Basic needs",},
  {video: {url: `185081.mp4`},word: "Home help services",},
  {video: {url: `58309.mp4`},word: "Health",},
  {video: {url: `188343.mp4`},word: "Assistance allowance",},
  {video: {url: `244601.mp4`},word: "Good health",},
  {video: {url: `91327.mp4`},word: "Fine motor skills",},
  {video: {url: `244147.mp4`},word: "Cause of death",},
  {video: {url: `92967.mp4`},word: "Nutrition",},
  {video: {url: `154680.mp4`},word: "Health hazard",},
  {video: {url: `244881.mp4`},word: "Life",},
  {video: {url: `185108.mp4`},word: "Long-term care",},
  {video: {url: `189058.mp4`},word: "Corpse",},
  {video: {url: `92857.mp4`},word: "Medical insurance",},
  {video: {url: `245397.mp4`},word: "Resistance to infection",},
  {video: {url: `116961.mp4`},word: "Specimen sample",},
  {video: {url: `118377.mp4`},word: "Air dehumidifier",},
  {video: {url: `93194.mp4`},word: "Assistance",},
  {video: {url: `116960.mp4`},word: "Specimen cup",},
  {video: {url: `185299.mp4`},word: "Target group",},
  {video: {url: `73343.mp4`},word: "Nutrient",},
  {video: {url: `244628.mp4`},word: "Healthy habit",},
  {video: {url: `51267.mp4`},word: "Cadaver",},
  {video: {url: `92855.mp4`},word: "Medical chart",},
  {video: {url: `92858.mp4`},word: "Medical records",},
  {video: {url: `246094.mp4`},word: "Lack of sleep",},
  {video: {url: `58426.mp4`},word: "Death",},
  {video: {url: `58310.mp4`},word: "Health insurance",},
  {video: {url: `189268.mp4`},word: "Health status",},
  {video: {url: `51257.mp4`},word: "Cabin fever",},
  {video: {url: `244632.mp4`},word: "Health certificate",},
  {video: {url: `74878.mp4`},word: "Assistant",},
  {video: {url: `96362.mp4`},word: "Fatality",},
  {video: {url: `51573.mp4`},word: "Emergency",},
  {video: {url: `93956.mp4`},word: "Dwarf",},
  {video: {url: `245148.mp4`},word: "Taichi",},
  {video: {url: `189392.mp4`},word: "Malpractice",},
  {video: {url: `51789.mp4`},word: "Child health",},
  {video: {url: `153315.mp4`},word: "Fitness",},
  {video: {url: `93127.mp4`},word: "Heartbeat",},
  {video: {url: `244629.mp4`},word: "Healthy diet",},
  {video: {url: `189412.mp4`},word: "Mortality",},
  {video: {url: `96941.mp4`},word: "Medical care",},
  {video: {url: `75042.mp4`},word: "Non-smokers",},
  {video: {url: `58522.mp4`},word: "Health background",},
  {video: {url: `116292.mp4`},word: "Physical fitness",},
  {video: {url: `117064.mp4`},word: "Strength",},
  {video: {url: `124953.mp4`},word: "Blood group",},
  {video: {url: `116440.mp4`},word: "Pulse",},
  {video: {url: `92856.mp4`},word: "Medical checkup",},
  {video: {url: `91754.mp4`},word: "Emergency help line",},
  {video: {url: `244602.mp4`},word: "Health care services",},
  {video: {url: `62603.mp4`},word: "Blood pressure",},
  {video: {url: `62602.mp4`},word: "Blood donor",},
  {video: {url: `124952.mp4`},word: "Blood bank",},
  {video: {url: `62600.mp4`},word: "Blood",},
  {video: {url: `94175.mp4`},word: "User involvement",},
  {video: {url: `244627.mp4`},word: "Healthy life expectancy",},
  {video: {url: `244630.mp4`},word: "Health record",},
  {video: {url: `94174.mp4`},word: "User council",},
  {video: {url: `93106.mp4`},word: "Growth",},
  {video: {url: `185315.mp4`},word: "World health organization",},
  {video: {url: `50824.mp4`},word: "Stinking",},
  {video: {url: `58456.mp4`},word: "Dirty",},
  {video: {url: `155630.mp4`},word: "Sterile",},
  {video: {url: `117038.mp4`},word: "Stink",},
  {video: {url: `50941.mp4`},word: "Wash ,to",},
  {video: {url: `125580.mp4`},word: "Brush teeth",},
  {video: {url: `59638.mp4`},word: "Wash one\u00e2\u00b4s hands",},
  {video: {url: `244946.mp4`},word: "Cleanliness",},
  {video: {url: `87730.mp4`},word: "Breath freshener",},
  {video: {url: `93297.mp4`},word: "Diaper",},
  {video: {url: `93291.mp4`},word: "Deodorant",},
  {video: {url: `89870.mp4`},word: "Odor",},
  {video: {url: `189099.mp4`},word: "Disinfection",},
  {video: {url: `189354.mp4`},word: "Hygiene",},
  {video: {url: `62530.mp4`},word: "Back scrubber",},
  {video: {url: `126005.mp4`},word: "Chlorine",},
  {video: {url: `51850.mp4`},word: "Men's bathroom",},
  {video: {url: `65912.mp4`},word: "Shampoo",},
  {video: {url: `50815.mp4`},word: "Soap",},
  {video: {url: `91762.mp4`},word: "Feces",},
  {video: {url: `124291.mp4`},word: "Bet",},
  {video: {url: `110175.mp4`},word: "Level",},
  {video: {url: `51612.mp4`},word: "Play catch",},
  {video: {url: `51950.mp4`},word: "Lottery",},
  {video: {url: `96924.mp4`},word: "Pinball",},
  {video: {url: `62689.mp4`},word: "Card shark",},
  {video: {url: `96173.mp4`},word: "Dartboard",},
  {video: {url: `96174.mp4`},word: "Darts player",},
  {video: {url: `153344.mp4`},word: "Hearts",},
  {video: {url: `91853.mp4`},word: "Paint-ball",},
  {video: {url: `116590.mp4`},word: "Rock paper scissors game",},
  {video: {url: `51569.mp4`},word: "Circle game",},
  {video: {url: `51593.mp4`},word: "Darts",},
  {video: {url: `116456.mp4`},word: "Puzzle piece",},
  {video: {url: `50794.mp4`},word: "Chess",},
  {video: {url: `51606.mp4`},word: "Peek-a-boo-game",},
  {video: {url: `65869.mp4`},word: "Mind game",},
  {video: {url: `186006.mp4`},word: "First person shooter",},
  {video: {url: `65868.mp4`},word: "Marbles",},
  {video: {url: `96149.mp4`},word: "Crossword puzzle",},
  {video: {url: `51412.mp4`},word: "Checkers",},
  {video: {url: `124315.mp4`},word: "Bingo game",},
  {video: {url: `93200.mp4`},word: "Beach ball",},
  {video: {url: `125803.mp4`},word: "Carnival game",},
  {video: {url: `62704.mp4`},word: "Charades",},
  {video: {url: `51551.mp4`},word: "Computer game",},
  {video: {url: `51819.mp4`},word: "Jump-rope",},
  {video: {url: `65867.mp4`},word: "Finger game",},
  {video: {url: `116915.mp4`},word: "Slot machine",},
  {video: {url: `51818.mp4`},word: "Jigsaw puzzle",},
  {video: {url: `118584.mp4`},word: "Wii",},
  {video: {url: `125789.mp4`},word: "Card game",},
  {video: {url: `51904.mp4`},word: "Checkers",},
  {video: {url: `96305.mp4`},word: "Electronic game",},
  {video: {url: `51733.mp4`},word: "Video game",},
  {video: {url: `92620.mp4`},word: "Casino",},
  {video: {url: `51620.mp4`},word: "Puzzle",},
  {video: {url: `116874.mp4`},word: "Sims",},
  {video: {url: `90137.mp4`},word: "Tug of war",},
  {video: {url: `118592.mp4`},word: "World of warcraft",},
  {video: {url: `93227.mp4`},word: "Chess player",},
  {video: {url: `62588.mp4`},word: "Billiards",},
  {video: {url: `51386.mp4`},word: "Cards",},
  {video: {url: `62897.mp4`},word: "Quiz",},
  {video: {url: `116555.mp4`},word: "Riddle",},
  {video: {url: `51384.mp4`},word: "Card table",},
  {video: {url: `124703.mp4`},word: "Blackjack",},
  {video: {url: `58504.mp4`},word: "Game",},
  {video: {url: `153669.mp4`},word: "Playstation 3",},
  {video: {url: `51552.mp4`},word: "Construction game",},
  {video: {url: `63565.mp4`},word: "Portugal",},
  {video: {url: `77337.mp4`},word: "Starter",},
  {video: {url: `72918.mp4`},word: "Exhaust valve",},
  {video: {url: `72917.mp4`},word: "Exhaust system",},
  {video: {url: `72893.mp4`},word: "Electronic fuel injection",},
  {video: {url: `72916.mp4`},word: "Exhaust pipe",},
  {video: {url: `72914.mp4`},word: "Hood",},
  {video: {url: `73034.mp4`},word: "Injection nozzle",},
  {video: {url: `72922.mp4`},word: "Four-stroke engine",},
  {video: {url: `96274.mp4`},word: "Draftsman",},
  {video: {url: `77335.mp4`},word: "Spark plug",},
  {video: {url: `154682.mp4`},word: "Heat engine",},
  {video: {url: `72541.mp4`},word: "Compressor",},
  {video: {url: `72763.mp4`},word: "Crankshaft",},
  {video: {url: `186375.mp4`},word: "Distributor",},
  {video: {url: `72483.mp4`},word: "Camshaft",},
  {video: {url: `186147.mp4`},word: "Turbine",},
  {video: {url: `58446.mp4`},word: "Fuel",},
  {video: {url: `65965.mp4`},word: "Electric engine",},
  {video: {url: `72787.mp4`},word: "Diesel",},
  {video: {url: `72913.mp4`},word: "Engine",},
  {video: {url: `72929.mp4`},word: "Gasket",},
  {video: {url: `72782.mp4`},word: "Cylinder",},
  {video: {url: `72928.mp4`},word: "Gasoline",},
  {video: {url: `73031.mp4`},word: "Ignition",},
  {video: {url: `73030.mp4`},word: "Distributor cap",},
  {video: {url: `186096.mp4`},word: "Slip ring motor",},
  {video: {url: `186093.mp4`},word: "Shunt motor",},
  {video: {url: `72485.mp4`},word: "Carburator",},
  {video: {url: `118386.mp4`},word: "Air motor",},
  {video: {url: `69441.mp4`},word: "Rotor",},
  {video: {url: `246104.mp4`},word: "Jet engine",},
  {video: {url: `94392.mp4`},word: "Coil",},
  {video: {url: `186266.mp4`},word: "Asynchronous motor",},
  {video: {url: `69454.mp4`},word: "Timing relay",},
  {video: {url: `77311.mp4`},word: "Piston",},
  {video: {url: `72313.mp4`},word: "Air suspension",},
  {video: {url: `77307.mp4`},word: "Gas",},
  {video: {url: `50918.mp4`},word: "Tram",},
  {video: {url: `96289.mp4`},word: "Dump truck",},
  {video: {url: `97096.mp4`},word: "Sledge",},
  {video: {url: `73039.mp4`},word: "Kick starter",},
  {video: {url: `73038.mp4`},word: "Jeep",},
  {video: {url: `50901.mp4`},word: "Taxi",},
  {video: {url: `96151.mp4`},word: "Cruise ship",},
  {video: {url: `116904.mp4`},word: "Sled",},
  {video: {url: `116909.mp4`},word: "Sleigh",},
  {video: {url: `93222.mp4`},word: "Cart",},
  {video: {url: `96420.mp4`},word: "Go-cart",},
  {video: {url: `48603.mp4`},word: "Motorcycle",},
  {video: {url: `76794.mp4`},word: "Bus",},
  {video: {url: `90132.mp4`},word: "Trolley car",},
  {video: {url: `76797.mp4`},word: "Commercial vehicle",},
  {video: {url: `76941.mp4`},word: "Limousine",},
  {video: {url: `76950.mp4`},word: "Mini cooper",},
  {video: {url: `125650.mp4`},word: "Cab",},
  {video: {url: `72317.mp4`},word: "Airplane",},
  {video: {url: `125780.mp4`},word: "Car",},
  {video: {url: `58448.mp4`},word: "Lorry",},
  {video: {url: `50917.mp4`},word: "Train",},
  {video: {url: `97079.mp4`},word: "Aircraft",},
  {video: {url: `117075.mp4`},word: "Submarine",},
  {video: {url: `58454.mp4`},word: "Truck",},
  {video: {url: `76795.mp4`},word: "Bus",},
  {video: {url: `116593.mp4`},word: "Rocket ride",},
  {video: {url: `116935.mp4`},word: "Snowmobile",},
  {video: {url: `49198.mp4`},word: "Plane",},
  {video: {url: `92666.mp4`},word: "Ferry",},
  {video: {url: `97024.mp4`},word: "Caterpillar",},
  {video: {url: `245797.mp4`},word: "Police car",},
  {video: {url: `91780.mp4`},word: "Helicopter",},
  {video: {url: `72745.mp4`},word: "Convertible",},
  {video: {url: `92741.mp4`},word: "Ship",},
  {video: {url: `122855.mp4`},word: "Automobile",},
  {video: {url: `58449.mp4`},word: "Lorry-driver",},
  {video: {url: `76952.mp4`},word: "Shuttle",},
  {video: {url: `51632.mp4`},word: "Scooter",},
  {video: {url: `76996.mp4`},word: "Motorcycle",},
  {video: {url: `90188.mp4`},word: "Wagon",},
  {video: {url: `58438.mp4`},word: "Bicycle",},
  {video: {url: `91487.mp4`},word: "Eel",},
  {video: {url: `59422.mp4`},word: "Lobster",},
  {video: {url: `155567.mp4`},word: "Sea trout",},
  {video: {url: `154622.mp4`},word: "Flounder",},
  {video: {url: `74950.mp4`},word: "Crab",},
  {video: {url: `89780.mp4`},word: "Anchovy",},
  {video: {url: `91470.mp4`},word: "Crayfish",},
  {video: {url: `59598.mp4`},word: "Squid",},
  {video: {url: `91567.mp4`},word: "Pike",},
  {video: {url: `245946.mp4`},word: "Mussel",},
  {video: {url: `74930.mp4`},word: "Clam",},
  {video: {url: `155659.mp4`},word: "Swordfish",},
  {video: {url: `59574.mp4`},word: "Seafood",},
  {video: {url: `91466.mp4`},word: "Cod",},
  {video: {url: `91514.mp4`},word: "Mackerel",},
  {video: {url: `91576.mp4`},word: "Prawn",},
  {video: {url: `91620.mp4`},word: "Shellfish",},
  {video: {url: `153214.mp4`},word: "Brown trout",},
  {video: {url: `91621.mp4`},word: "Shrimp",},
  {video: {url: `91615.mp4`},word: "Sardine",},
  {video: {url: `59566.mp4`},word: "Salmon",},
  {video: {url: `74988.mp4`},word: "Fish",},
  {video: {url: `91538.mp4`},word: "Oyster",},
  {video: {url: `154391.mp4`},word: "Catfish",},
  {video: {url: `91528.mp4`},word: "Octopus",},
  {video: {url: `157999.mp4`},word: "Rainbow trout",},
  {video: {url: `59628.mp4`},word: "Trout",},
  {video: {url: `91661.mp4`},word: "Tuna",},
  {video: {url: `91564.mp4`},word: "Perch",},
  {video: {url: `191209.mp4`},word: "Norway pout",},
  {video: {url: `91575.mp4`},word: "Poultry",},
  {video: {url: `59571.mp4`},word: "Sausage",},
  {video: {url: `59543.mp4`},word: "Pork chops",},
  {video: {url: `74983.mp4`},word: "Elk",},
  {video: {url: `245217.mp4`},word: "Spicy sausage",},
  {video: {url: `91490.mp4`},word: "Falun sausage",},
  {video: {url: `74979.mp4`},word: "Duck",},
  {video: {url: `110221.mp4`},word: "Mince",},
  {video: {url: `74892.mp4`},word: "Breast",},
  {video: {url: `91476.mp4`},word: "Cutlet",},
  {video: {url: `74967.mp4`},word: "Deer",},
  {video: {url: `74918.mp4`},word: "Chicken breast",},
  {video: {url: `74917.mp4`},word: "Chicken",},
  {video: {url: `74883.mp4`},word: "Beef",},
  {video: {url: `59634.mp4`},word: "Veal",},
  {video: {url: `59561.mp4`},word: "Reindeer meat",},
  {video: {url: `75009.mp4`},word: "Goose",},
  {video: {url: `96383.mp4`},word: "Flesh",},
  {video: {url: `91488.mp4`},word: "Escalope",},
  {video: {url: `75023.mp4`},word: "Loin of pork",},
  {video: {url: `91493.mp4`},word: "Fillet of pork",},
  {video: {url: `123145.mp4`},word: "Bacon",},
  {video: {url: `69480.mp4`},word: "Cock",},
  {video: {url: `59536.mp4`},word: "Pheasant",},
  {video: {url: `245372.mp4`},word: "Roasted pork loin",},
  {video: {url: `245373.mp4`},word: "Roast chicken",},
  {video: {url: `75040.mp4`},word: "Mutton",},
  {video: {url: `245253.mp4`},word: "Smoked ham",},
  {video: {url: `91569.mp4`},word: "Pork",},
  {video: {url: `75012.mp4`},word: "Ham",},
  {video: {url: `59556.mp4`},word: "Rabbit",},
  {video: {url: `59629.mp4`},word: "Turkey",},
  {video: {url: `75029.mp4`},word: "Meat",},
  {video: {url: `91572.mp4`},word: "Pot roast",},
  {video: {url: `59544.mp4`},word: "Pork with clams",},
  {video: {url: `59573.mp4`},word: "Scrambled eggs",},
  {video: {url: `58868.mp4`},word: "Boiled egg",},
  {video: {url: `92926.mp4`},word: "Nachos",},
  {video: {url: `246003.mp4`},word: "Meat rissole",},
  {video: {url: `245290.mp4`},word: "Shrimp rissole",},
  {video: {url: `245333.mp4`},word: "Seafood rice",},
  {video: {url: `91624.mp4`},word: "Skewer",},
  {video: {url: `59263.mp4`},word: "Lamb stew",},
  {video: {url: `91552.mp4`},word: "Pate",},
  {video: {url: `59541.mp4`},word: "Poached eggs",},
  {video: {url: `59539.mp4`},word: "Pizza",},
  {video: {url: `91644.mp4`},word: "Steak",},
  {video: {url: `59568.mp4`},word: "Sandwich",},
  {video: {url: `59537.mp4`},word: "Pie",},
  {video: {url: `59206.mp4`},word: "Hot dog",},
  {video: {url: `74884.mp4`},word: "Beef stew",},
  {video: {url: `75003.mp4`},word: "Fruit salad",},
  {video: {url: `244157.mp4`},word: "Carrot soup",},
  {video: {url: `91649.mp4`},word: "Supper",},
  {video: {url: `74999.mp4`},word: "Fried eggs",},
  {video: {url: `91531.mp4`},word: "Omelette",},
  {video: {url: `244167.mp4`},word: "Chicken rissole",},
  {video: {url: `89792.mp4`},word: "Black pudding",},
  {video: {url: `245200.mp4`},word: "Steak sandwich",},
  {video: {url: `245081.mp4`},word: "Tuna rissole",},
  {video: {url: `59586.mp4`},word: "Soup",},
  {video: {url: `245046.mp4`},word: "Vegetable soup",},
  {video: {url: `59585.mp4`},word: "Soft egg",},
  {video: {url: `246092.mp4`},word: "Lamb rice",},
  {video: {url: `59565.mp4`},word: "Salad",},
  {video: {url: `91570.mp4`},word: "Porridge",},
  {video: {url: `122589.mp4`},word: "Aspic",},
  {video: {url: `93060.mp4`},word: "French fry",},
  {video: {url: `89877.mp4`},word: "Onion rings",},
  {video: {url: `90186.mp4`},word: "Waffle",},
  {video: {url: `125603.mp4`},word: "Bull's eye",},
  {video: {url: `245161.mp4`},word: "Sushi",},
  {video: {url: `244490.mp4`},word: "Fish soup",},
  {video: {url: `91497.mp4`},word: "Fruit dish",},
  {video: {url: `89974.mp4`},word: "Taco",},
  {video: {url: `89788.mp4`},word: "Baked potato",},
  {video: {url: `91542.mp4`},word: "Pancake",},
  {video: {url: `75013.mp4`},word: "Hamburger",},
  {video: {url: `244119.mp4`},word: "Breaded meat",},
  {video: {url: `245862.mp4`},word: "Paella",},
  {video: {url: `59165.mp4`},word: "Hardboiled egg",},
  {video: {url: `91598.mp4`},word: "Pur\u00e9",},
  {video: {url: `245211.mp4`},word: "Spring roll",},
  {video: {url: `74919.mp4`},word: "Chicken soup with pasta",},
  {video: {url: `91645.mp4`},word: "Stew",},
  {video: {url: `59602.mp4`},word: "Sweet",},
  {video: {url: `59606.mp4`},word: "Sweets",},
  {video: {url: `125968.mp4`},word: "Chewing gum",},
  {video: {url: `58852.mp4`},word: "Apple pie",},
  {video: {url: `96125.mp4`},word: "Cotton candy",},
  {video: {url: `91472.mp4`},word: "Croissant",},
  {video: {url: `74968.mp4`},word: "Dessert",},
  {video: {url: `93268.mp4`},word: "Cupcake",},
  {video: {url: `244158.mp4`},word: "Carrot cake",},
  {video: {url: `245917.mp4`},word: "Nougat candy",},
  {video: {url: `153782.mp4`},word: "Whipped cream",},
  {video: {url: `245884.mp4`},word: "Orange cake",},
  {video: {url: `245174.mp4`},word: "Sugared almond",},
  {video: {url: `91521.mp4`},word: "Milkshake",},
  {video: {url: `74899.mp4`},word: "Cake",},
  {video: {url: `244211.mp4`},word: "Churro",},
  {video: {url: `91596.mp4`},word: "Pudding",},
  {video: {url: `89801.mp4`},word: "Butter cream",},
  {video: {url: `244994.mp4`},word: "White chocolate",},
  {video: {url: `91659.mp4`},word: "Toffee",},
  {video: {url: `246099.mp4`},word: "King cake",},
  {video: {url: `74927.mp4`},word: "Chocolate mousse",},
  {video: {url: `91460.mp4`},word: "Chocolate ball",},
  {video: {url: `59209.mp4`},word: "Ice cream",},
  {video: {url: `92896.mp4`},word: "Molasses",},
  {video: {url: `75018.mp4`},word: "Jelly",},
  {video: {url: `91495.mp4`},word: "Flan",},
  {video: {url: `91455.mp4`},word: "Caramel",},
  {video: {url: `75038.mp4`},word: "Mousse",},
  {video: {url: `91642.mp4`},word: "Sponge",},
  {video: {url: `92844.mp4`},word: "Marshmallow",},
  {video: {url: `91474.mp4`},word: "Custard",},
  {video: {url: `93109.mp4`},word: "Gum",},
  {video: {url: `117089.mp4`},word: "Sundae",},
  {video: {url: `59579.mp4`},word: "Slice of a cake",},
  {video: {url: `51294.mp4`},word: "Candy",},
  {video: {url: `74946.mp4`},word: "Cookie",},
  {video: {url: `89988.mp4`},word: "Tart",},
  {video: {url: `91459.mp4`},word: "Chocolate",},
  {video: {url: `89735.mp4`},word: "Pulp",},
  {video: {url: `124285.mp4`},word: "Berry",},
  {video: {url: `74874.mp4`},word: "Apricot",},
  {video: {url: `188194.mp4`},word: "Pericarp",},
  {video: {url: `244374.mp4`},word: "Dried fig",},
  {video: {url: `59326.mp4`},word: "Lemon",},
  {video: {url: `74913.mp4`},word: "Cherry",},
  {video: {url: `59257.mp4`},word: "Kiwi fruit",},
  {video: {url: `154411.mp4`},word: "Cloudberry",},
  {video: {url: `244199.mp4`},word: "Clementine",},
  {video: {url: `89617.mp4`},word: "Blackcurrant",},
  {video: {url: `91556.mp4`},word: "Peach",},
  {video: {url: `93195.mp4`},word: "Avocado",},
  {video: {url: `58850.mp4`},word: "Apple",},
  {video: {url: `187340.mp4`},word: "Redcurrant",},
  {video: {url: `73399.mp4`},word: "Wild strawberry",},
  {video: {url: `63634.mp4`},word: "Blueberry",},
  {video: {url: `63633.mp4`},word: "Blackberry",},
  {video: {url: `59162.mp4`},word: "Grapefruit",},
  {video: {url: `91501.mp4`},word: "Gooseberries",},
  {video: {url: `91534.mp4`},word: "Orange",},
  {video: {url: `91465.mp4`},word: "Coconut",},
  {video: {url: `59163.mp4`},word: "Grapes",},
  {video: {url: `91577.mp4`},word: "Prune",},
  {video: {url: `58860.mp4`},word: "Banana",},
  {video: {url: `91492.mp4`},word: "Fig",},
  {video: {url: `244331.mp4`},word: "Custard apple",},
  {video: {url: `59554.mp4`},word: "Pumpkin",},
  {video: {url: `91516.mp4`},word: "Mango",},
  {video: {url: `244400.mp4`},word: "Dry fruit",},
  {video: {url: `59600.mp4`},word: "Strawberries",},
  {video: {url: `75001.mp4`},word: "Fruit",},
  {video: {url: `246021.mp4`},word: "Mandarin",},
  {video: {url: `89624.mp4`},word: "Cranberry",},
  {video: {url: `91544.mp4`},word: "Papaya",},
  {video: {url: `91558.mp4`},word: "Pear",},
  {video: {url: `91560.mp4`},word: "Peel",},
  {video: {url: `90204.mp4`},word: "Watermelon",},
  {video: {url: `59557.mp4`},word: "Raisin",},
  {video: {url: `91568.mp4`},word: "Plum",},
  {video: {url: `93223.mp4`},word: "Cantaloupe",},
  {video: {url: `245825.mp4`},word: "Persimmon",},
  {video: {url: `245845.mp4`},word: "Passion fruit",},
  {video: {url: `75031.mp4`},word: "Melon",},
  {video: {url: `245792.mp4`},word: "Pomegranate",},
  {video: {url: `59538.mp4`},word: "Pineapple",},
  {video: {url: `89799.mp4`},word: "Brussels sprout",},
  {video: {url: `245182.mp4`},word: "String bean",},
  {video: {url: `89785.mp4`},word: "Asparagus",},
  {video: {url: `74894.mp4`},word: "Broccoli",},
  {video: {url: `122571.mp4`},word: "Artichoke",},
  {video: {url: `244104.mp4`},word: "Black bean",},
  {video: {url: `59546.mp4`},word: "Potato",},
  {video: {url: `244967.mp4`},word: "Yellow chili pepper",},
  {video: {url: `245694.mp4`},word: "Red bean",},
  {video: {url: `116502.mp4`},word: "Red pepper",},
  {video: {url: `245693.mp4`},word: "Red cabbage",},
  {video: {url: `245692.mp4`},word: "Red chili pepper",},
  {video: {url: `91511.mp4`},word: "Leek",},
  {video: {url: `50747.mp4`},word: "Bean",},
  {video: {url: `59604.mp4`},word: "Sweet corn",},
  {video: {url: `51392.mp4`},word: "Carrot",},
  {video: {url: `125993.mp4`},word: "Chili",},
  {video: {url: `93101.mp4`},word: "Green pepper",},
  {video: {url: `69483.mp4`},word: "Corn",},
  {video: {url: `59635.mp4`},word: "Vegetable",},
  {video: {url: `59343.mp4`},word: "Lettuce",},
  {video: {url: `91529.mp4`},word: "Olive",},
  {video: {url: `91532.mp4`},word: "Onion",},
  {video: {url: `244166.mp4`},word: "Chickpea",},
  {video: {url: `91513.mp4`},word: "Lentil",},
  {video: {url: `74897.mp4`},word: "Cabbage",},
  {video: {url: `244173.mp4`},word: "Chard",},
  {video: {url: `59160.mp4`},word: "Eggplant",},
  {video: {url: `244995.mp4`},word: "White cabbage",},
  {video: {url: `59624.mp4`},word: "Tomato",},
  {video: {url: `244996.mp4`},word: "White bean",},
  {video: {url: `244638.mp4`},word: "Hot pepper",},
  {video: {url: `97019.mp4`},word: "Beet",},
  {video: {url: `91457.mp4`},word: "Cauliflower",},
  {video: {url: `74905.mp4`},word: "Celery",},
  {video: {url: `75039.mp4`},word: "Mushroom",},
  {video: {url: `59190.mp4`},word: "Head",},
  {video: {url: `74957.mp4`},word: "Cucumber",},
  {video: {url: `91639.mp4`},word: "Spinach",},
  {video: {url: `91559.mp4`},word: "Peas",},
  {video: {url: `91662.mp4`},word: "Turnip",},
  {video: {url: `59595.mp4`},word: "Spicy",},
  {video: {url: `58865.mp4`},word: "Black pepper",},
  {video: {url: `91654.mp4`},word: "Thyme",},
  {video: {url: `244967.mp4`},word: "Yellow chili pepper",},
  {video: {url: `245692.mp4`},word: "Red chili pepper",},
  {video: {url: `91547.mp4`},word: "Parsley",},
  {video: {url: `125993.mp4`},word: "Chili",},
  {video: {url: `59204.mp4`},word: "Horseradish",},
  {video: {url: `91536.mp4`},word: "Oregano",},
  {video: {url: `244498.mp4`},word: "Fine salt",},
  {video: {url: `89790.mp4`},word: "Basil",},
  {video: {url: `91491.mp4`},word: "Fennel",},
  {video: {url: `91480.mp4`},word: "Dill",},
  {video: {url: `244638.mp4`},word: "Hot pepper",},
  {video: {url: `153264.mp4`},word: "Curry powder",},
  {video: {url: `91526.mp4`},word: "Nutmeg",},
  {video: {url: `91545.mp4`},word: "Paprika",},
  {video: {url: `91473.mp4`},word: "Cummin",},
  {video: {url: `91507.mp4`},word: "Herb",},
  {video: {url: `59594.mp4`},word: "Spice",},
  {video: {url: `91562.mp4`},word: "Pepper",},
  {video: {url: `153662.mp4`},word: "Peppercorn",},
  {video: {url: `91500.mp4`},word: "Ginger",},
  {video: {url: `123605.mp4`},word: "Bay leaf",},
  {video: {url: `245883.mp4`},word: "Orange chili pepper",},
  {video: {url: `91468.mp4`},word: "Coriander",},
  {video: {url: `74929.mp4`},word: "Cinnamon",},
  {video: {url: `91498.mp4`},word: "Garlic",},
  {video: {url: `117023.mp4`},word: "Steamroll",},
  {video: {url: `96284.mp4`},word: "Drill press",},
  {video: {url: `89622.mp4`},word: "Chain saw",},
  {video: {url: `110168.mp4`},word: "Jigsaw",},
  {video: {url: `244060.mp4`},word: "Automatic machine",},
  {video: {url: `118171.mp4`},word: "Absorption",},
  {video: {url: `76999.mp4`},word: "Multi-disk clutch",},
  {video: {url: `124275.mp4`},word: "Belt",},
  {video: {url: `96052.mp4`},word: "Cement mixer",},
  {video: {url: `69460.mp4`},word: "Water wheel",},
  {video: {url: `186115.mp4`},word: "Steam generator",},
  {video: {url: `116925.mp4`},word: "Snow blower",},
  {video: {url: `93051.mp4`},word: "Forklift",},
  {video: {url: `125963.mp4`},word: "Cherry picker",},
  {video: {url: `89641.mp4`},word: "Mill",},
  {video: {url: `154765.mp4`},word: "Machine tool",},
  {video: {url: `154766.mp4`},word: "Machinery",},
  {video: {url: `154668.mp4`},word: "Grinder",},
  {video: {url: `62698.mp4`},word: "Cement truck",},
  {video: {url: `125604.mp4`},word: "Bulldozer",},
  {video: {url: `96390.mp4`},word: "Forklift operator",},
  {video: {url: `125995.mp4`},word: "Chiller",},
  {video: {url: `94120.mp4`},word: "Free of charge",},
  {video: {url: `94222.mp4`},word: "Educational targets",},
  {video: {url: `94312.mp4`},word: "Principal administrative officer",},
  {video: {url: `94355.mp4`},word: "Student loan",},
  {video: {url: `94302.mp4`},word: "Post secondary student aid",},
  {video: {url: `245342.mp4`},word: "School action plan",},
  {video: {url: `94271.mp4`},word: "Local education committee",},
  {video: {url: `94313.mp4`},word: "Principal responsibility",},
  {video: {url: `58495.mp4`},word: "Counsel",},
  {video: {url: `154675.mp4`},word: "Head of school",},
  {video: {url: `94209.mp4`},word: "Compulsory school age",},
  {video: {url: `155643.mp4`},word: "Student union",},
  {video: {url: `245328.mp4`},word: "Secretary's office",},
  {video: {url: `94332.mp4`},word: "School administration",},
  {video: {url: `154421.mp4`},word: "Complaints procedure",},
  {video: {url: `94337.mp4`},word: "School management training",},
  {video: {url: `94110.mp4`},word: "Education act",},
  {video: {url: `94334.mp4`},word: "School head",},
  {video: {url: `245043.mp4`},word: "Vice chancellor",},
  {video: {url: `94354.mp4`},word: "Student grant",},
  {video: {url: `94096.mp4`},word: "Decision-maker",},
  {video: {url: `91771.mp4`},word: "Grant",},
  {video: {url: `94273.mp4`},word: "Minimum guaranteed instruction time",},
  {video: {url: `94295.mp4`},word: "Parents\u2019 association",},
  {video: {url: `153029.mp4`},word: "Academic misconduct",},
  {video: {url: `116403.mp4`},word: "Principal's office",},
  {video: {url: `96177.mp4`},word: "Dean's office",},
  {video: {url: `244604.mp4`},word: "Headmaster's office",},
  {video: {url: `94122.mp4`},word: "Free tuition",},
  {video: {url: `49210.mp4`},word: "President",},
  {video: {url: `94335.mp4`},word: "School head training",},
  {video: {url: `118341.mp4`},word: "Administration",},
  {video: {url: `94185.mp4`},word: "Action program",},
  {video: {url: `94382.mp4`},word: "Upper secondary student aid",},
  {video: {url: `94099.mp4`},word: "Deputy head (gb)",},
  {video: {url: `89574.mp4`},word: "Administrator",},
  {video: {url: `94158.mp4`},word: "Pupil participation",},
  {video: {url: `94086.mp4`},word: "Chair of the pupil council",},
  {video: {url: `94162.mp4`},word: "Qualified (for studies)",},
  {video: {url: `94068.mp4`},word: "Admitted",},
  {video: {url: `122523.mp4`},word: "Approved",},
  {video: {url: `245179.mp4`},word: "Submit",},
  {video: {url: `116510.mp4`},word: "Register",},
  {video: {url: `94072.mp4`},word: "Apply for",},
  {video: {url: `94161.mp4`},word: "Qualification requirements",},
  {video: {url: `94123.mp4`},word: "Full cost coverage",},
  {video: {url: `94119.mp4`},word: "Formal entry requirements",},
  {video: {url: `109896.mp4`},word: "General eligibility",},
  {video: {url: `93185.mp4`},word: "Admission",},
  {video: {url: `94229.mp4`},word: "General entry requirements",},
  {video: {url: `94121.mp4`},word: "Free option",},
  {video: {url: `94071.mp4`},word: "Application fee",},
  {video: {url: `94111.mp4`},word: "Eligible applicant",},
  {video: {url: `244042.mp4`},word: "Application date",},
  {video: {url: `116511.mp4`},word: "Registration",},
  {video: {url: `153707.mp4`},word: "Scholarship",},
  {video: {url: `94112.mp4`},word: "Entry requirements",},
  {video: {url: `94140.mp4`},word: "Letter of acceptance",},
  {video: {url: `94067.mp4`},word: "Admission on special grounds",},
  {video: {url: `94297.mp4`},word: "Pass threshold",},
  {video: {url: `94126.mp4`},word: "General admission requirements",},
  {video: {url: `50831.mp4`},word: "Child care provider",},
  {video: {url: `94139.mp4`},word: "Leisure-time pedagogue",},
  {video: {url: `94341.mp4`},word: "School-age child care",},
  {video: {url: `51804.mp4`},word: "Day-care center",},
  {video: {url: `91326.mp4`},word: "Day nursery",},
  {video: {url: `91332.mp4`},word: "Nanny",},
  {video: {url: `125982.mp4`},word: "Childcare",},
  {video: {url: `96648.mp4`},word: "Kindergarten",},
  {video: {url: `94305.mp4`},word: "Pre-school class",},
  {video: {url: `94304.mp4`},word: "Pre-school activities",},
  {video: {url: `94306.mp4`},word: "Pre-school education",},
  {video: {url: `94138.mp4`},word: "Leisure-time centre",},
  {video: {url: `94182.mp4`},word: "Youth recreation leader",},
  {video: {url: `94164.mp4`},word: "Recreation assistant",},
  {video: {url: `94308.mp4`},word: "Pre-school teacher training",},
  {video: {url: `94307.mp4`},word: "Pre-school teacher",},
  {video: {url: `157963.mp4`},word: "Preschool books",},
  {video: {url: `51577.mp4`},word: "Free play",},
  {video: {url: `94165.mp4`},word: "Recreation premises",},
  {video: {url: `154725.mp4`},word: "Kindergarten teacher",},
  {video: {url: `51614.mp4`},word: "Playroom",},
  {video: {url: `51584.mp4`},word: "Nursery school",},
  {video: {url: `51781.mp4`},word: "Nursery",},
  {video: {url: `109674.mp4`},word: "Child minder",},
  {video: {url: `118590.mp4`},word: "Wood screw",},
  {video: {url: `73036.mp4`},word: "Jack",},
  {video: {url: `191332.mp4`},word: "Pneumatic ratchet",},
  {video: {url: `96522.mp4`},word: "Hatchet",},
  {video: {url: `51806.mp4`},word: "Equipment",},
  {video: {url: `155557.mp4`},word: "Safety glasses",},
  {video: {url: `96636.mp4`},word: "Jackhammer",},
  {video: {url: `90145.mp4`},word: "Tweezers",},
  {video: {url: `191319.mp4`},word: "Planer",},
  {video: {url: `116792.mp4`},word: "Sawhorse",},
  {video: {url: `116455.mp4`},word: "Putty knife",},
  {video: {url: `93089.mp4`},word: "Glue gun",},
  {video: {url: `97026.mp4`},word: "Caulking gun",},
  {video: {url: `96656.mp4`},word: "Nozzle",},
  {video: {url: `72901.mp4`},word: "Caliper",},
  {video: {url: `118507.mp4`},word: "Angle-iron",},
  {video: {url: `89665.mp4`},word: "Wheelbarrow",},
  {video: {url: `109879.mp4`},word: "Eye screw",},
  {video: {url: `124987.mp4`},word: "Bolt clipper",},
  {video: {url: `69458.mp4`},word: "Valve",},
  {video: {url: `96382.mp4`},word: "Flatter",},
  {video: {url: `96213.mp4`},word: "Device",},
  {video: {url: `116803.mp4`},word: "Scraper",},
  {video: {url: `96284.mp4`},word: "Drill press",},
  {video: {url: `96283.mp4`},word: "Drill bit",},
  {video: {url: `185943.mp4`},word: "Allen screw",},
  {video: {url: `90098.mp4`},word: "Tool belt",},
  {video: {url: `76796.mp4`},word: "Dipstick",},
  {video: {url: `96593.mp4`},word: "Ice scraper",},
  {video: {url: `124986.mp4`},word: "Bolt",},
  {video: {url: `184750.mp4`},word: "Measuring instrument",},
  {video: {url: `246014.mp4`},word: "Masking tape",},
  {video: {url: `89615.mp4`},word: "Axe",},
  {video: {url: `73371.mp4`},word: "Shovel",},
  {video: {url: `116929.mp4`},word: "Snow shovel",},
  {video: {url: `90069.mp4`},word: "Thumbtack",},
  {video: {url: `51364.mp4`},word: "Bellows",},
  {video: {url: `191298.mp4`},word: "Pickaxe",},
  {video: {url: `116337.mp4`},word: "Pocketknife",},
  {video: {url: `89970.mp4`},word: "Table saw",},
  {video: {url: `92809.mp4`},word: "Wrench",},
  {video: {url: `124706.mp4`},word: "Blade",},
  {video: {url: `109883.mp4`},word: "Flathead screw",},
  {video: {url: `191287.mp4`},word: "Phillips screw",},
  {video: {url: `109876.mp4`},word: "Electric screwdriver",},
  {video: {url: `191288.mp4`},word: "Phillips screwdriver",},
  {video: {url: `93274.mp4`},word: "Deadbolt",},
  {video: {url: `116189.mp4`},word: "Packing for college",},
  {video: {url: `243982.mp4`},word: "Academic",},
  {video: {url: `94125.mp4`},word: "Further qualified",},
  {video: {url: `155563.mp4`},word: "Scholarly",},
  {video: {url: `94011.mp4`},word: "Research",},
  {video: {url: `58510.mp4`},word: "Graduate",},
  {video: {url: `96587.mp4`},word: "Hypothesis",},
  {video: {url: `244502.mp4`},word: "Fieldwork",},
  {video: {url: `243979.mp4`},word: "Academic paper",},
  {video: {url: `94141.mp4`},word: "Liberal adult education",},
  {video: {url: `94301.mp4`},word: "Pilot study",},
  {video: {url: `90020.mp4`},word: "Theory",},
  {video: {url: `94238.mp4`},word: "Higher education studies",},
  {video: {url: `157873.mp4`},word: "Observational techniques",},
  {video: {url: `153043.mp4`},word: "Adult education",},
  {video: {url: `92835.mp4`},word: "Major",},
  {video: {url: `154600.mp4`},word: "Field work",},
  {video: {url: `51607.mp4`},word: "Peer",},
  {video: {url: `116529.mp4`},word: "Research paper",},
  {video: {url: `92984.mp4`},word: "Faculty",},
  {video: {url: `94186.mp4`},word: "Adult education at compulsory school level",},
  {video: {url: `94195.mp4`},word: "Basic adult education",},
  {video: {url: `157902.mp4`},word: "Paragraph style",},
  {video: {url: `154661.mp4`},word: "Graduate",},
  {video: {url: `94189.mp4`},word: "Advanced vocational training",},
  {video: {url: `94092.mp4`},word: "Continuing education course",},
  {video: {url: `96258.mp4`},word: "Doctorate",},
  {video: {url: `244010.mp4`},word: "Acknowledgements",},
  {video: {url: `155577.mp4`},word: "Seminar",},
  {video: {url: `91423.mp4`},word: "Further education",},
  {video: {url: `94254.mp4`},word: "Initial vocational training",},
  {video: {url: `58487.mp4`},word: "College student",},
  {video: {url: `94012.mp4`},word: "Research",},
  {video: {url: `58486.mp4`},word: "College",},
  {video: {url: `157950.mp4`},word: "Postgraduate",},
  {video: {url: `94347.mp4`},word: "Special adult student aid",},
  {video: {url: `153579.mp4`},word: "Higher education",},
  {video: {url: `94221.mp4`},word: "Education for adults with learning disabilities",},
  {video: {url: `116416.mp4`},word: "Professor",},
  {video: {url: `245407.mp4`},word: "Research method",},
  {video: {url: `94187.mp4`},word: "Adult education at upper-secondary level",},
  {video: {url: `65750.mp4`},word: "Monograph",},
  {video: {url: `94069.mp4`},word: "Adult education initiative",},
  {video: {url: `94289.mp4`},word: "Orientation",},
  {video: {url: `153044.mp4`},word: "Adult secondary education",},
  {video: {url: `89918.mp4`},word: "Any questions?",},
  {video: {url: `90047.mp4`},word: "Close your book.",},
  {video: {url: `94103.mp4`},word: "Didactic",},
  {video: {url: `96622.mp4`},word: "Instruct",},
  {video: {url: `51954.mp4`},word: "Make understand",},
  {video: {url: `92972.mp4`},word: "Explain",},
  {video: {url: `118410.mp4`},word: "Allocate",},
  {video: {url: `96299.mp4`},word: "Educate",},
  {video: {url: `50896.mp4`},word: "Teach, to",},
  {video: {url: `154738.mp4`},word: "Lecture",},
  {video: {url: `94211.mp4`},word: "Cooperative teaching",},
  {video: {url: `94217.mp4`},word: "Developmental curriculum",},
  {video: {url: `48578.mp4`},word: "Lessons",},
  {video: {url: `94291.mp4`},word: "Parent-teacher association",},
  {video: {url: `94242.mp4`},word: "In-service training day",},
  {video: {url: `94262.mp4`},word: "Lecturer",},
  {video: {url: `94237.mp4`},word: "Heterogeneous grouping",},
  {video: {url: `58566.mp4`},word: "Tutorial",},
  {video: {url: `58565.mp4`},word: "Tutor",},
  {video: {url: `94370.mp4`},word: "Teaching qualification",},
  {video: {url: `65890.mp4`},word: "Master",},
  {video: {url: `245991.mp4`},word: "Mentor",},
  {video: {url: `155626.mp4`},word: "Staff room",},
  {video: {url: `89894.mp4`},word: "Pedagogy",},
  {video: {url: `154734.mp4`},word: "Learning and teaching",},
  {video: {url: `91453.mp4`},word: "Lecture",},
  {video: {url: `58551.mp4`},word: "Teacher's day",},
  {video: {url: `94365.mp4`},word: "Syllabus",},
  {video: {url: `94274.mp4`},word: "Minimum number of teaching hours",},
  {video: {url: `153295.mp4`},word: "Educationalist",},
  {video: {url: `94292.mp4`},word: "Parent-teacher meeting",},
  {video: {url: `94369.mp4`},word: "Teaching methods",},
  {video: {url: `93177.mp4`},word: "Instructor",},
  {video: {url: `155577.mp4`},word: "Seminar",},
  {video: {url: `65866.mp4`},word: "Educational method",},
  {video: {url: `154739.mp4`},word: "Lecture programme",},
  {video: {url: `154404.mp4`},word: "Class teacher",},
  {video: {url: `94367.mp4`},word: "Teaching materials",},
  {video: {url: `94216.mp4`},word: "Curriculum",},
  {video: {url: `189535.mp4`},word: "Social pedagogue",},
  {video: {url: `94299.mp4`},word: "Physical education teacher",},
  {video: {url: `94381.mp4`},word: "Upper secondary school teacher",},
  {video: {url: `94366.mp4`},word: "Teacher education",},
  {video: {url: `155694.mp4`},word: "Tutor group",},
  {video: {url: `50897.mp4`},word: "Teacher",},
  {video: {url: `94243.mp4`},word: "In-service training for teachers",},
  {video: {url: `69345.mp4`},word: "Instruction",},
  {video: {url: `94258.mp4`},word: "Joint lessons",},
  {video: {url: `94241.mp4`},word: "In-service training",},
  {video: {url: `155663.mp4`},word: "Teaching language",},
  {video: {url: `92874.mp4`},word: "Metal detector",},
  {video: {url: `116947.mp4`},word: "Solder gun",},
  {video: {url: `62547.mp4`},word: "Barbed wire",},
  {video: {url: `72904.mp4`},word: "Rust proofing",},
  {video: {url: `124281.mp4`},word: "Bending",},
  {video: {url: `245987.mp4`},word: "Metal forming",},
  {video: {url: `117024.mp4`},word: "Steelworker",},
  {video: {url: `245989.mp4`},word: "Metal casting",},
  {video: {url: `124710.mp4`},word: "Blanking",},
  {video: {url: `93361.mp4`},word: "Do you speak german?",},
  {video: {url: `93360.mp4`},word: "Do you speak french?",},
  {video: {url: `93359.mp4`},word: "Do you speak english?",},
  {video: {url: `243969.mp4`},word: "Spanish",},
  {video: {url: `92598.mp4`},word: "Arabic",},
  {video: {url: `122533.mp4`},word: "Aramaic",},
  {video: {url: `51351.mp4`},word: "Braille",},
  {video: {url: `92778.mp4`},word: "Irish",},
  {video: {url: `155601.mp4`},word: "Slab",},
  {video: {url: `153187.mp4`},word: "Bond",},
  {video: {url: `246091.mp4`},word: "Laminate",},
  {video: {url: `245047.mp4`},word: "Varnish",},
  {video: {url: `89528.mp4`},word: "Binder",},
  {video: {url: `125894.mp4`},word: "Cement",},
  {video: {url: `118384.mp4`},word: "Airbrick",},
  {video: {url: `244272.mp4`},word: "Construction materials",},
  {video: {url: `96122.mp4`},word: "Cork",},
  {video: {url: `244134.mp4`},word: "Cardboard",},
  {video: {url: `155552.mp4`},word: "Roof tiles",},
  {video: {url: `96051.mp4`},word: "Cement block",},
  {video: {url: `125596.mp4`},word: "Build materials",},
  {video: {url: `124971.mp4`},word: "Board",},
  {video: {url: `62892.mp4`},word: "Wallpaper",},
  {video: {url: `125543.mp4`},word: "Brick",},
  {video: {url: `123632.mp4`},word: "Beech",},
  {video: {url: `154621.mp4`},word: "Floor tiles",},
  {video: {url: `126001.mp4`},word: "Chipboard",},
  {video: {url: `96386.mp4`},word: "Floor tile",},
  {video: {url: `116349.mp4`},word: "Pole",},
  {video: {url: `89985.mp4`},word: "Tar",},
  {video: {url: `157834.mp4`},word: "Material",},
  {video: {url: `93994.mp4`},word: "Post",},
  {video: {url: `116600.mp4`},word: "Rome",},
  {video: {url: `51863.mp4`},word: "Italy",},
  {video: {url: `93673.mp4`},word: "It is 4:45.",},
  {video: {url: `93672.mp4`},word: "It is 10 till 9:00.",},
  {video: {url: `93674.mp4`},word: "It is 6:15.",},
  {video: {url: `48507.mp4`},word: "At eight o\u00b4clock",},
  {video: {url: `65896.mp4`},word: "O\u00b4clock",},
  {video: {url: `117094.mp4`},word: "Sunset",},
  {video: {url: `58571.mp4`},word: "Two o'clock",},
  {video: {url: `93028.mp4`},word: "Five o'clock",},
  {video: {url: `92957.mp4`},word: "Nine o'clock",},
  {video: {url: `49153.mp4`},word: "Midnight",},
  {video: {url: `93055.mp4`},word: "Four o'clock",},
  {video: {url: `116772.mp4`},word: "Rush hour",},
  {video: {url: `97013.mp4`},word: "At quarter to eight",},
  {video: {url: `116885.mp4`},word: "Six o'clock",},
  {video: {url: `58568.mp4`},word: "Twelve o'clock",},
  {video: {url: `97012.mp4`},word: "At quarter past eight",},
  {video: {url: `48602.mp4`},word: "Morning",},
  {video: {url: `97014.mp4`},word: "At twenty to eight",},
  {video: {url: `48821.mp4`},word: "Night",},
  {video: {url: `49154.mp4`},word: "Noon",},
  {video: {url: `117093.mp4`},word: "Sunrise",},
  {video: {url: `116334.mp4`},word: "Pm",},
  {video: {url: `63542.mp4`},word: "Hungary",},
  {video: {url: `118395.mp4`},word: "Albania",},
  {video: {url: `65910.mp4`},word: "Serbija",},
  {video: {url: `65919.mp4`},word: "Slovakia",},
  {video: {url: `93965.mp4`},word: "Luxembourg",},
  {video: {url: `51862.mp4`},word: "Ireland",},
  {video: {url: `125012.mp4`},word: "Bosnia",},
  {video: {url: `65927.mp4`},word: "Switzerland",},
  {video: {url: `92648.mp4`},word: "Cyprus",},
  {video: {url: `63550.mp4`},word: "Norway",},
  {video: {url: `50757.mp4`},word: "Belgium",},
  {video: {url: `118140.mp4`},word: "A lot",},
  {video: {url: `76696.mp4`},word: "Nothing",},
  {video: {url: `118138.mp4`},word: "A little",},
  {video: {url: `244013.mp4`},word: "Almost all",},
  {video: {url: `50922.mp4`},word: "Very",},
  {video: {url: `93968.mp4`},word: "More",},
  {video: {url: `93184.mp4`},word: "Additional",},
  {video: {url: `96341.mp4`},word: "Excessive",},
  {video: {url: `244467.mp4`},word: "Extensive",},
  {video: {url: `93969.mp4`},word: "Most",},
  {video: {url: `58460.mp4`},word: "Full",},
  {video: {url: `96613.mp4`},word: "Infinite",},
  {video: {url: `96181.mp4`},word: "Definite",},
  {video: {url: `116835.mp4`},word: "Several",},
  {video: {url: `58447.mp4`},word: "Light",},
  {video: {url: `90130.mp4`},word: "Triple",},
  {video: {url: `92917.mp4`},word: "Multiple",},
  {video: {url: `63616.mp4`},word: "Individual",},
  {video: {url: `92915.mp4`},word: "Much",},
  {video: {url: `244503.mp4`},word: "Few",},
  {video: {url: `244016.mp4`},word: "All",},
  {video: {url: `93008.mp4`},word: "Few",},
  {video: {url: `96339.mp4`},word: "Exceed",},
  {video: {url: `65956.mp4`},word: "Decrease",},
  {video: {url: `62746.mp4`},word: "Reduce, to",},
  {video: {url: `96327.mp4`},word: "Escalate",},
  {video: {url: `157837.mp4`},word: "Measure",},
  {video: {url: `96351.mp4`},word: "Extend",},
  {video: {url: `63615.mp4`},word: "Increase",},
  {video: {url: `73040.mp4`},word: "Kilometer",},
  {video: {url: `245117.mp4`},word: "Thousandth",},
  {video: {url: `75022.mp4`},word: "Liter (litre)",},
  {video: {url: `96340.mp4`},word: "Excess",},
  {video: {url: `245131.mp4`},word: "Tenth",},
  {video: {url: `245920.mp4`},word: "None",},
  {video: {url: `96917.mp4`},word: "Ounce",},
  {video: {url: `245744.mp4`},word: "Proximity",},
  {video: {url: `116198.mp4`},word: "Pair",},
  {video: {url: `91483.mp4`},word: "Dozen",},
  {video: {url: `153746.mp4`},word: "Teaspoon",},
  {video: {url: `184882.mp4`},word: "Measurement",},
  {video: {url: `189662.mp4`},word: "Measuring device",},
  {video: {url: `116380.mp4`},word: "Pound",},
  {video: {url: `116331.mp4`},word: "Plenty",},
  {video: {url: `125904.mp4`},word: "Centimeter",},
  {video: {url: `245223.mp4`},word: "Span",},
  {video: {url: `185353.mp4`},word: "Millimeter",},
  {video: {url: `91449.mp4`},word: "Lack",},
  {video: {url: `51769.mp4`},word: "Minmum",},
  {video: {url: `244304.mp4`},word: "Cubic centimeter",},
  {video: {url: `116309.mp4`},word: "Pint",},
  {video: {url: `51289.mp4`},word: "Amount",},
  {video: {url: `59215.mp4`},word: "Kilogram",},
  {video: {url: `93162.mp4`},word: "Inch",},
  {video: {url: `96938.mp4`},word: "Maximum",},
  {video: {url: `59555.mp4`},word: "Quantity",},
  {video: {url: `50049.mp4`},word: "Africa",},
  {video: {url: `243949.mp4`},word: "Libya",},
  {video: {url: `243948.mp4`},word: "Lebanon",},
  {video: {url: `92680.mp4`},word: "Ghana",},
  {video: {url: `243971.mp4`},word: "South africa",},
  {video: {url: `92660.mp4`},word: "Egypt",},
  {video: {url: `116847.mp4`},word: "Shift gears",},
  {video: {url: `77322.mp4`},word: "Rear view mirror",},
  {video: {url: `77324.mp4`},word: "Rear window",},
  {video: {url: `76800.mp4`},word: "Kingpin",},
  {video: {url: `77323.mp4`},word: "Rear wheel drive",},
  {video: {url: `77321.mp4`},word: "Rear ligtht",},
  {video: {url: `62607.mp4`},word: "Blowout",},
  {video: {url: `122555.mp4`},word: "Armrest",},
  {video: {url: `117022.mp4`},word: "Steady lights",},
  {video: {url: `72319.mp4`},word: "Alloy wheel",},
  {video: {url: `118549.mp4`},word: "Antifreeze",},
  {video: {url: `72328.mp4`},word: "Axle",},
  {video: {url: `72325.mp4`},word: "Automatic transmission",},
  {video: {url: `125783.mp4`},word: "Car hood",},
  {video: {url: `72930.mp4`},word: "Stick shift",},
  {video: {url: `118176.mp4`},word: "Acceleration",},
  {video: {url: `116465.mp4`},word: "Rail",},
  {video: {url: `116466.mp4`},word: "Railroad crossing",},
  {video: {url: `116467.mp4`},word: "Railroad signal",},
  {video: {url: `77313.mp4`},word: "Power steering",},
  {video: {url: `77314.mp4`},word: "Power windows",},
  {video: {url: `72890.mp4`},word: "Car service",},
  {video: {url: `72487.mp4`},word: "Center stand",},
  {video: {url: `77328.mp4`},word: "Power locks",},
  {video: {url: `72537.mp4`},word: "Clutch",},
  {video: {url: `96140.mp4`},word: "Crash test dummy",},
  {video: {url: `96495.mp4`},word: "Hangar",},
  {video: {url: `72481.mp4`},word: "Brake hose",},
  {video: {url: `72376.mp4`},word: "Rotor",},
  {video: {url: `72898.mp4`},word: "Brake drum",},
  {video: {url: `72897.mp4`},word: "Brake",},
  {video: {url: `72482.mp4`},word: "Bumper",},
  {video: {url: `72900.mp4`},word: "Brake pedal",},
  {video: {url: `72899.mp4`},word: "Brake pads",},
  {video: {url: `245901.mp4`},word: "Oil",},
  {video: {url: `76804.mp4`},word: "Jack",},
  {video: {url: `76802.mp4`},word: "Leaf spring",},
  {video: {url: `65948.mp4`},word: "Break contact",},
  {video: {url: `58443.mp4`},word: "Driver",},
  {video: {url: `72911.mp4`},word: "Drum brakes",},
  {video: {url: `72327.mp4`},word: "Automatic gearbox",},
  {video: {url: `72316.mp4`},word: "Airbag",},
  {video: {url: `90086.mp4`},word: "Tire chains",},
  {video: {url: `72768.mp4`},word: "Helmet",},
  {video: {url: `122852.mp4`},word: "Auto mechanic",},
  {video: {url: `90139.mp4`},word: "Tune-up",},
  {video: {url: `72536.mp4`},word: "Chassis",},
  {video: {url: `72883.mp4`},word: "Door panel",},
  {video: {url: `76798.mp4`},word: "Drive axle",},
  {video: {url: `72916.mp4`},word: "Exhaust pipe",},
  {video: {url: `72907.mp4`},word: "Door lock",},
  {video: {url: `76695.mp4`},word: "Drive chain",},
  {video: {url: `72909.mp4`},word: "Drive shaft",},
  {video: {url: `72921.mp4`},word: "Four wheel drive",},
  {video: {url: `72539.mp4`},word: "Air brakes",},
  {video: {url: `96559.mp4`},word: "Hitch",},
  {video: {url: `58450.mp4`},word: "Oil change",},
  {video: {url: `77029.mp4`},word: "Oil filter",},
  {video: {url: `77007.mp4`},word: "License plate",},
  {video: {url: `77008.mp4`},word: "Odometer",},
  {video: {url: `58446.mp4`},word: "Fuel",},
  {video: {url: `72923.mp4`},word: "Front axle",},
  {video: {url: `72924.mp4`},word: "Front-wheel drive",},
  {video: {url: `72786.mp4`},word: "Dashboard",},
  {video: {url: `72925.mp4`},word: "Gas pedal",},
  {video: {url: `72928.mp4`},word: "Gasoline",},
  {video: {url: `245986.mp4`},word: "Metallic paints",},
  {video: {url: `122853.mp4`},word: "Auto painter",},
  {video: {url: `77310.mp4`},word: "Pilot",},
  {video: {url: `77308.mp4`},word: "Gas station",},
  {video: {url: `77307.mp4`},word: "Gas",},
  {video: {url: `72310.mp4`},word: "Accelerator",},
  {video: {url: `77032.mp4`},word: "Pedal",},
  {video: {url: `77339.mp4`},word: "Steering wheel",},
  {video: {url: `77336.mp4`},word: "Speedometer",},
  {video: {url: `72932.mp4`},word: "Emergency brake",},
  {video: {url: `72934.mp4`},word: "Hatchback",},
  {video: {url: `77343.mp4`},word: "Sun roof",},
  {video: {url: `72931.mp4`},word: "Gearbox",},
  {video: {url: `77320.mp4`},word: "Rear fog light",},
  {video: {url: `116849.mp4`},word: "Ship captain",},
  {video: {url: `93167.mp4`},word: "Infant car seat",},
  {video: {url: `125781.mp4`},word: "Car headlights",},
  {video: {url: `77318.mp4`},word: "Radiator grill",},
  {video: {url: `73032.mp4`},word: "Signals",},
  {video: {url: `73033.mp4`},word: "Turn signal",},
  {video: {url: `77331.mp4`},word: "Car door light",},
  {video: {url: `73029.mp4`},word: "Hubcap",},
  {video: {url: `77334.mp4`},word: "Spare parts",},
  {video: {url: `77342.mp4`},word: "Studded tire",},
  {video: {url: `92605.mp4`},word: "Bay",},
  {video: {url: `72895.mp4`},word: "Hood",},
  {video: {url: `72896.mp4`},word: "Trunk",},
  {video: {url: `72329.mp4`},word: "Axle shaft",},
  {video: {url: `72336.mp4`},word: "Backrest",},
  {video: {url: `243955.mp4`},word: "New zealand",},
  {video: {url: `122846.mp4`},word: "Australia",},
  {video: {url: `153639.mp4`},word: "Oceania",},
  {video: {url: `96609.mp4`},word: "India",},
  {video: {url: `116224.mp4`},word: "Paris",},
  {video: {url: `93358.mp4`},word: "Do you like to watch tv?",},
  {video: {url: `154530.mp4`},word: "Cut!",},
  {video: {url: `96259.mp4`},word: "Documentary",},
  {video: {url: `154599.mp4`},word: "Fictional",},
  {video: {url: `244488.mp4`},word: "Fade out",},
  {video: {url: `51734.mp4`},word: "Watch tv",},
  {video: {url: `153039.mp4`},word: "Act",},
  {video: {url: `154390.mp4`},word: "Casting director",},
  {video: {url: `153312.mp4`},word: "Film",},
  {video: {url: `245310.mp4`},word: "Set design",},
  {video: {url: `154557.mp4`},word: "Documentary",},
  {video: {url: `245335.mp4`},word: "Scriptwriter",},
  {video: {url: `154602.mp4`},word: "Film review",},
  {video: {url: `51790.mp4`},word: "Children\u2019s tv program",},
  {video: {url: `96244.mp4`},word: "Disney",},
  {video: {url: `48607.mp4`},word: "Movie",},
  {video: {url: `90040.mp4`},word: "Three-d movie",},
  {video: {url: `91325.mp4`},word: "Children's programme",},
  {video: {url: `155584.mp4`},word: "Setting",},
  {video: {url: `50947.mp4`},word: "Tv",},
  {video: {url: `244450.mp4`},word: "Episode",},
  {video: {url: `154512.mp4`},word: "Costumes",},
  {video: {url: `155583.mp4`},word: "Set up",},
  {video: {url: `153730.mp4`},word: "Standard definition",},
  {video: {url: `155566.mp4`},word: "Screenwriter",},
  {video: {url: `157962.mp4`},word: "Pre-production",},
  {video: {url: `49215.mp4`},word: "Program",},
  {video: {url: `154616.mp4`},word: "Flashback",},
  {video: {url: `154601.mp4`},word: "Film props",},
  {video: {url: `153737.mp4`},word: "Subtitle",},
  {video: {url: `118514.mp4`},word: "Animation",},
  {video: {url: `125657.mp4`},word: "Cable tv",},
  {video: {url: `158030.mp4`},word: "Release print",},
  {video: {url: `155612.mp4`},word: "Sound effects",},
  {video: {url: `118328.mp4`},word: "Actor",},
  {video: {url: `51298.mp4`},word: "Actress",},
  {video: {url: `153254.mp4`},word: "Color tv",},
  {video: {url: `90039.mp4`},word: "Three-d glasses",},
  {video: {url: `154759.mp4`},word: "Location",},
  {video: {url: `153715.mp4`},word: "Short film",},
  {video: {url: `124962.mp4`},word: "Blu-ray disc",},
  {video: {url: `155613.mp4`},word: "Sound track",},
  {video: {url: `154758.mp4`},word: "Live action",},
  {video: {url: `189656.mp4`},word: "Matinee",},
  {video: {url: `154417.mp4`},word: "Comedy",},
  {video: {url: `155719.mp4`},word: "Widescreen",},
  {video: {url: `243932.mp4`},word: "Cnn",},
  {video: {url: `90174.mp4`},word: "Videographer",},
  {video: {url: `155595.mp4`},word: "Sidekick",},
  {video: {url: `155551.mp4`},word: "Role",},
  {video: {url: `154770.mp4`},word: "Main character",},
  {video: {url: `157938.mp4`},word: "Plot",},
  {video: {url: `157952.mp4`},word: "Post-production",},
  {video: {url: `245090.mp4`},word: "Transcript",},
  {video: {url: `96952.mp4`},word: "Moviepremiere",},
  {video: {url: `245038.mp4`},word: "Voice-over",},
  {video: {url: `110442.mp4`},word: "Movie star",},
  {video: {url: `110441.mp4`},word: "Movie poster",},
  {video: {url: `153240.mp4`},word: "Camera crew",},
  {video: {url: `58388.mp4`},word: "Television",},
  {video: {url: `155636.mp4`},word: "Storyboard",},
  {video: {url: `65945.mp4`},word: "Antenna",},
  {video: {url: `244138.mp4`},word: "Call sheet",},
  {video: {url: `244377.mp4`},word: "Dramatic",},
  {video: {url: `122511.mp4`},word: "Applaud",},
  {video: {url: `116270.mp4`},word: "Perform",},
  {video: {url: `116513.mp4`},word: "Rehearse",},
  {video: {url: `153039.mp4`},word: "Act",},
  {video: {url: `93915.mp4`},word: "Dance",},
  {video: {url: `117003.mp4`},word: "Stage",},
  {video: {url: `93321.mp4`},word: "Drag queen",},
  {video: {url: `92711.mp4`},word: "Opera",},
  {video: {url: `62880.mp4`},word: "Performance",},
  {video: {url: `244376.mp4`},word: "Dramatisation",},
  {video: {url: `245310.mp4`},word: "Set design",},
  {video: {url: `91367.mp4`},word: "Artiste",},
  {video: {url: `244217.mp4`},word: "Comedy of character",},
  {video: {url: `51557.mp4`},word: "Dancing",},
  {video: {url: `116919.mp4`},word: "Snake charmer",},
  {video: {url: `122513.mp4`},word: "Applause",},
  {video: {url: `62541.mp4`},word: "Ballet",},
  {video: {url: `89554.mp4`},word: "Scenery",},
  {video: {url: `116862.mp4`},word: "Showgirl",},
  {video: {url: `92883.mp4`},word: "Mime",},
  {video: {url: `51637.mp4`},word: "Show",},
  {video: {url: `245966.mp4`},word: "Monologue",},
  {video: {url: `118328.mp4`},word: "Actor",},
  {video: {url: `93948.mp4`},word: "Diva",},
  {video: {url: `245210.mp4`},word: "Stage music",},
  {video: {url: `245343.mp4`},word: "Scene",},
  {video: {url: `116328.mp4`},word: "Play",},
  {video: {url: `157915.mp4`},word: "Performance art",},
  {video: {url: `245109.mp4`},word: "Tone",},
  {video: {url: `109528.mp4`},word: "Ballet shoes",},
  {video: {url: `90084.mp4`},word: "Tiptoe",},
  {video: {url: `153087.mp4`},word: "Audition",},
  {video: {url: `50891.mp4`},word: "Theater",},
  {video: {url: `51298.mp4`},word: "Actress",},
  {video: {url: `155551.mp4`},word: "Role",},
  {video: {url: `62872.mp4`},word: "Improvisation",},
  {video: {url: `153328.mp4`},word: "Golden section",},
  {video: {url: `246036.mp4`},word: "Lyric",},
  {video: {url: `153096.mp4`},word: "Autographed by the author",},
  {video: {url: `157856.mp4`},word: "Narrative",},
  {video: {url: `157894.mp4`},word: "Out of print",},
  {video: {url: `154599.mp4`},word: "Fictional",},
  {video: {url: `58604.mp4`},word: "Describe",},
  {video: {url: `116320.mp4`},word: "Plagiarize",},
  {video: {url: `94003.mp4`},word: "Publish",},
  {video: {url: `246108.mp4`},word: "Issue",},
  {video: {url: `157854.mp4`},word: "Narrate",},
  {video: {url: `245772.mp4`},word: "Printing house",},
  {video: {url: `51131.mp4`},word: "Booklet",},
  {video: {url: `124302.mp4`},word: "Bibliology",},
  {video: {url: `157864.mp4`},word: "Non-fiction",},
  {video: {url: `153085.mp4`},word: "Audio book",},
  {video: {url: `116341.mp4`},word: "Poem",},
  {video: {url: `116342.mp4`},word: "Poet",},
  {video: {url: `245383.mp4`},word: "Rhyme scheme",},
  {video: {url: `157861.mp4`},word: "New edition",},
  {video: {url: `244450.mp4`},word: "Episode",},
  {video: {url: `58564.mp4`},word: "Topic",},
  {video: {url: `89532.mp4`},word: "Majuscule",},
  {video: {url: `65747.mp4`},word: "Letterpress",},
  {video: {url: `93930.mp4`},word: "Description",},
  {video: {url: `154770.mp4`},word: "Main character",},
  {video: {url: `246078.mp4`},word: "Legend",},
  {video: {url: `154575.mp4`},word: "Encyclopaedia",},
  {video: {url: `245747.mp4`},word: "Proof correction mark",},
  {video: {url: `157984.mp4`},word: "Proofreader",},
  {video: {url: `116192.mp4`},word: "Page",},
  {video: {url: `154672.mp4`},word: "Happy ending",},
  {video: {url: `155682.mp4`},word: "Thesaurus",},
  {video: {url: `154586.mp4`},word: "E-publisher",},
  {video: {url: `125770.mp4`},word: "Capital",},
  {video: {url: `157938.mp4`},word: "Plot",},
  {video: {url: `245103.mp4`},word: "Trade book",},
  {video: {url: `155617.mp4`},word: "Specialist's book",},
  {video: {url: `51438.mp4`},word: "Chapter",},
  {video: {url: `116839.mp4`},word: "Shakespeare",},
  {video: {url: `65750.mp4`},word: "Monograph",},
  {video: {url: `246031.mp4`},word: "Main idea",},
  {video: {url: `245898.mp4`},word: "Old face",},
  {video: {url: `245197.mp4`},word: "Stereotype",},
  {video: {url: `245854.mp4`},word: "Paraphrase",},
  {video: {url: `244613.mp4`},word: "Haiku",},
  {video: {url: `244376.mp4`},word: "Dramatisation",},
  {video: {url: `157926.mp4`},word: "Picture research",},
  {video: {url: `157925.mp4`},word: "Picture book",},
  {video: {url: `245799.mp4`},word: "Point of view",},
  {video: {url: `153042.mp4`},word: "Addendum",},
  {video: {url: `154519.mp4`},word: "Cover price",},
  {video: {url: `155593.mp4`},word: "Short story",},
  {video: {url: `153207.mp4`},word: "Bookshop",},
  {video: {url: `153206.mp4`},word: "Bookseller's discount",},
  {video: {url: `153205.mp4`},word: "Bookseller",},
  {video: {url: `153208.mp4`},word: "Bookworm",},
  {video: {url: `154718.mp4`},word: "Joint author",},
  {video: {url: `154619.mp4`},word: "Flipbook",},
  {video: {url: `62868.mp4`},word: "Handmade paper",},
  {video: {url: `154568.mp4`},word: "E-book",},
  {video: {url: `245943.mp4`},word: "Narrative poem",},
  {video: {url: `154762.mp4`},word: "Lower-case letter",},
  {video: {url: `154756.mp4`},word: "Literature criticism",},
  {video: {url: `154755.mp4`},word: "Literature",},
  {video: {url: `154610.mp4`},word: "First edition",},
  {video: {url: `155558.mp4`},word: "Sample chapter",},
  {video: {url: `155560.mp4`},word: "Sample pages",},
  {video: {url: `157963.mp4`},word: "Preschool books",},
  {video: {url: `245800.mp4`},word: "Pocket edition",},
  {video: {url: `155678.mp4`},word: "Textbook",},
  {video: {url: `110194.mp4`},word: "Margin",},
  {video: {url: `96935.mp4`},word: "Manuscript",},
  {video: {url: `244125.mp4`},word: "Book video",},
  {video: {url: `153202.mp4`},word: "Book store",},
  {video: {url: `153203.mp4`},word: "Book token",},
  {video: {url: `153204.mp4`},word: "Book trade",},
  {video: {url: `244126.mp4`},word: "Book trailer",},
  {video: {url: `153199.mp4`},word: "Book review",},
  {video: {url: `153200.mp4`},word: "Book selection",},
  {video: {url: `153201.mp4`},word: "Book signing",},
  {video: {url: `157888.mp4`},word: "Original edition",},
  {video: {url: `157988.mp4`},word: "Publication date",},
  {video: {url: `155562.mp4`},word: "Scheduled publication",},
  {video: {url: `153191.mp4`},word: "Book jacket",},
  {video: {url: `153190.mp4`},word: "Book fair",},
  {video: {url: `153192.mp4`},word: "Book manufacturing",},
  {video: {url: `153194.mp4`},word: "Book producer",},
  {video: {url: `153193.mp4`},word: "Book printing",},
  {video: {url: `153195.mp4`},word: "Book production",},
  {video: {url: `153198.mp4`},word: "Book publishing",},
  {video: {url: `153197.mp4`},word: "Book publisher",},
  {video: {url: `158003.mp4`},word: "Rare book",},
  {video: {url: `65900.mp4`},word: "Poetry",},
  {video: {url: `157992.mp4`},word: "Publishing company",},
  {video: {url: `124996.mp4`},word: "Bookbinding",},
  {video: {url: `244451.mp4`},word: "Epic poem",},
  {video: {url: `89943.mp4`},word: "Scroll",},
  {video: {url: `93322.mp4`},word: "Drama",},
  {video: {url: `244046.mp4`},word: "Ancient literature",},
  {video: {url: `244452.mp4`},word: "Epic",},
  {video: {url: `117097.mp4`},word: "Superman",},
  {video: {url: `50854.mp4`},word: "Tale",},
  {video: {url: `116434.mp4`},word: "Publisher",},
  {video: {url: `62589.mp4`},word: "Biography",},
  {video: {url: `245995.mp4`},word: "Memoirs",},
  {video: {url: `51563.mp4`},word: "Book",},
  {video: {url: `244473.mp4`},word: "Exclamative sentence",},
  {video: {url: `89514.mp4`},word: "Fable",},
  {video: {url: `153117.mp4`},word: "Binding",},
  {video: {url: `154415.mp4`},word: "Coffee-table book",},
  {video: {url: `154412.mp4`},word: "Co-author",},
  {video: {url: `154418.mp4`},word: "Comment",},
  {video: {url: `153188.mp4`},word: "Book club",},
  {video: {url: `153189.mp4`},word: "Book editor",},
  {video: {url: `244645.mp4`},word: "Historical literature",},
  {video: {url: `244443.mp4`},word: "Eulogy",},
  {video: {url: `92842.mp4`},word: "Manual",},
  {video: {url: `157901.mp4`},word: "Paperback",},
  {video: {url: `244583.mp4`},word: "Ghostwriter",},
  {video: {url: `244204.mp4`},word: "Classic literature",},
  {video: {url: `191478.mp4`},word: "Prose",},
  {video: {url: `191476.mp4`},word: "Prologue",},
  {video: {url: `116806.mp4`},word: "Script",},
  {video: {url: `153058.mp4`},word: "Appendix",},
  {video: {url: `154413.mp4`},word: "Co-edition",},
  {video: {url: `122849.mp4`},word: "Author",},
  {video: {url: `51813.mp4`},word: "Fairy tale",},
  {video: {url: `154402.mp4`},word: "Children's book",},
  {video: {url: `154567.mp4`},word: "Easy-to-read book",},
  {video: {url: `244050.mp4`},word: "Backlist",},
  {video: {url: `245729.mp4`},word: "Publisher's representative",},
  {video: {url: `245730.mp4`},word: "Publisher's reader",},
  {video: {url: `154690.mp4`},word: "House style",},
  {video: {url: `245743.mp4`},word: "Pub date",},
  {video: {url: `155679.mp4`},word: "Textbook publisher",},
  {video: {url: `51567.mp4`},word: "Children's literature",},
  {video: {url: `48556.mp4`},word: "Letter",},
  {video: {url: `90115.mp4`},word: "Tragedy",},
  {video: {url: `155538.mp4`},word: "Review copy",},
  {video: {url: `155732.mp4`},word: "Young literature",},
  {video: {url: `153025.mp4`},word: "Abc book",},
  {video: {url: `155731.mp4`},word: "Young adult's books",},
  {video: {url: `245158.mp4`},word: "Suspense",},
  {video: {url: `158024.mp4`},word: "Reference book",},
  {video: {url: `96368.mp4`},word: "Fiction",},
  {video: {url: `246035.mp4`},word: "Lyric poem",},
  {video: {url: `245230.mp4`},word: "Sonnet",},
  {video: {url: `92812.mp4`},word: "Writer",},
  {video: {url: `153094.mp4`},word: "Autobiography",},
  {video: {url: `155661.mp4`},word: "Talking book",},
  {video: {url: `153088.mp4`},word: "Author's contract",},
  {video: {url: `153089.mp4`},word: "Author's copy",},
  {video: {url: `153090.mp4`},word: "Author's correction",},
  {video: {url: `153091.mp4`},word: "Author's discount",},
  {video: {url: `153092.mp4`},word: "Author's promotion tour",},
  {video: {url: `153093.mp4`},word: "Author's right",},
  {video: {url: `245956.mp4`},word: "Motto",},
  {video: {url: `157868.mp4`},word: "Novelist",},
  {video: {url: `157866.mp4`},word: "Novel writer",},
  {video: {url: `157867.mp4`},word: "Novel",},
  {video: {url: `245121.mp4`},word: "Theme",},
  {video: {url: `157991.mp4`},word: "Publishing",},
  {video: {url: `94372.mp4`},word: "Text book",},
  {video: {url: `157993.mp4`},word: "Publishing contract",},
  {video: {url: `157994.mp4`},word: "Publishing right",},
  {video: {url: `155595.mp4`},word: "Sidekick",},
  {video: {url: `154742.mp4`},word: "Letter head",},
  {video: {url: `157855.mp4`},word: "Narrative",},
  {video: {url: `51784.mp4`},word: "Rhyme",},
  {video: {url: `62582.mp4`},word: "Bibliography",},
  {video: {url: `124300.mp4`},word: "Bible",},
  {video: {url: `244124.mp4`},word: "Braid",},
  {video: {url: `155632.mp4`},word: "Stitch",},
  {video: {url: `244311.mp4`},word: "Crochet",},
  {video: {url: `91475.mp4`},word: "Cut",},
  {video: {url: `89744.mp4`},word: "Sew",},
  {video: {url: `116305.mp4`},word: "Pin",},
  {video: {url: `96311.mp4`},word: "Embroider",},
  {video: {url: `245263.mp4`},word: "Silk painting",},
  {video: {url: `244478.mp4`},word: "Felt",},
  {video: {url: `188447.mp4`},word: "Suiting",},
  {video: {url: `187835.mp4`},word: "Fleece",},
  {video: {url: `188037.mp4`},word: "Hemming machine",},
  {video: {url: `188493.mp4`},word: "Worsted",},
  {video: {url: `244310.mp4`},word: "Crochet hook",},
  {video: {url: `244308.mp4`},word: "Crochet thread",},
  {video: {url: `244481.mp4`},word: "Fashion design",},
  {video: {url: `89752.mp4`},word: "Safety-pin",},
  {video: {url: `96360.mp4`},word: "Fashion designer",},
  {video: {url: `188417.mp4`},word: "Silk",},
  {video: {url: `96277.mp4`},word: "Dress designer",},
  {video: {url: `155681.mp4`},word: "The web",},
  {video: {url: `89762.mp4`},word: "Tailor",},
  {video: {url: `89505.mp4`},word: "Cloth",},
  {video: {url: `116307.mp4`},word: "Pincushion",},
  {video: {url: `246096.mp4`},word: "Knitting needles",},
  {video: {url: `116321.mp4`},word: "Plaid",},
  {video: {url: `187684.mp4`},word: "Couturier",},
  {video: {url: `244069.mp4`},word: "Batik",},
  {video: {url: `116774.mp4`},word: "Sack",},
  {video: {url: `116809.mp4`},word: "Seamstress",},
  {video: {url: `51846.mp4`},word: "Cotton",},
  {video: {url: `89779.mp4`},word: "Yarn",},
  {video: {url: `92979.mp4`},word: "Fabric",},
  {video: {url: `116836.mp4`},word: "Sewing machine",},
  {video: {url: `116834.mp4`},word: "Sewer",},
  {video: {url: `89729.mp4`},word: "Pin",},
  {video: {url: `116241.mp4`},word: "Patch",},
  {video: {url: `154526.mp4`},word: "Crochet",},
  {video: {url: `245080.mp4`},word: "Twisted yarn",},
  {video: {url: `245842.mp4`},word: "Patchwork",},
  {video: {url: `244309.mp4`},word: "Crochet lace",},
  {video: {url: `87744.mp4`},word: "Cotton wool",},
  {video: {url: `246095.mp4`},word: "Lace",},
  {video: {url: `188451.mp4`},word: "Synthetic fibre",},
  {video: {url: `89692.mp4`},word: "Dummy",},
  {video: {url: `89745.mp4`},word: "Sewing",},
  {video: {url: `244610.mp4`},word: "Hand sewing",},
  {video: {url: `246033.mp4`},word: "Machine sewing",},
  {video: {url: `89771.mp4`},word: "Velcro fastener",},
  {video: {url: `244220.mp4`},word: "Colour tabs",},
  {video: {url: `245307.mp4`},word: "Sewing sketch",},
  {video: {url: `87766.mp4`},word: "Fashion",},
  {video: {url: `155680.mp4`},word: "Textile",},
  {video: {url: `65938.mp4`},word: "Zip",},
  {video: {url: `96121.mp4`},word: "Cord",},
  {video: {url: `154684.mp4`},word: "Hem",},
  {video: {url: `94057.mp4`},word: "Wool",},
  {video: {url: `110190.mp4`},word: "Mannequin",},
  {video: {url: `87752.mp4`},word: "Dressmaking",},
  {video: {url: `187692.mp4`},word: "Crocheting",},
  {video: {url: `89772.mp4`},word: "Velvet",},
  {video: {url: `188082.mp4`},word: "Linen",},
  {video: {url: `187823.mp4`},word: "Fibre",},
  {video: {url: `187687.mp4`},word: "Crease",},
  {video: {url: `117039.mp4`},word: "Stitches",},
  {video: {url: `187910.mp4`},word: "Goffer",},
  {video: {url: `117070.mp4`},word: "Stripe",},
  {video: {url: `65886.mp4`},word: "Leather",},
  {video: {url: `92942.mp4`},word: "Needle",},
  {video: {url: `87889.mp4`},word: "Fur",},
  {video: {url: `58248.mp4`},word: "Abstract",},
  {video: {url: `154406.mp4`},word: "Classical",},
  {video: {url: `89533.mp4`},word: "Modern",},
  {video: {url: `155690.mp4`},word: "Traditional",},
  {video: {url: `89520.mp4`},word: "Gothic",},
  {video: {url: `245166.mp4`},word: "Surreal",},
  {video: {url: `158031.mp4`},word: "Religious art",},
  {video: {url: `89551.mp4`},word: "Romanticism",},
  {video: {url: `62883.mp4`},word: "Pop art",},
  {video: {url: `158134.mp4`},word: "Renaissance",},
  {video: {url: `184915.mp4`},word: "Style",},
  {video: {url: `89535.mp4`},word: "Modern art",},
  {video: {url: `158126.mp4`},word: "Minimalism",},
  {video: {url: `158132.mp4`},word: "Pointillism",},
  {video: {url: `157996.mp4`},word: "Punk",},
  {video: {url: `154424.mp4`},word: "Conceptual art",},
  {video: {url: `58600.mp4`},word: "Cubism",},
  {video: {url: `89563.mp4`},word: "Surrealism",},
  {video: {url: `122801.mp4`},word: "Asymmetry",},
  {video: {url: `58254.mp4`},word: "Artistic style",},
  {video: {url: `158127.mp4`},word: "Modernism",},
  {video: {url: `62869.mp4`},word: "Historical art",},
  {video: {url: `154417.mp4`},word: "Comedy",},
  {video: {url: `153028.mp4`},word: "Abstract art",},
  {video: {url: `153069.mp4`},word: "Art deco",},
  {video: {url: `69328.mp4`},word: "Fine art",},
  {video: {url: `245339.mp4`},word: "Science fiction",},
  {video: {url: `158051.mp4`},word: "Expressionism",},
  {video: {url: `245156.mp4`},word: "Symbolism",},
  {video: {url: `157953.mp4`},word: "Post-war photography",},
  {video: {url: `157943.mp4`},word: "Pop design",},
  {video: {url: `154660.mp4`},word: "Goth",},
  {video: {url: `154569.mp4`},word: "Eco design",},
  {video: {url: `157871.mp4`},word: "Nude",},
  {video: {url: `158053.mp4`},word: "Classicism",},
  {video: {url: `157857.mp4`},word: "Narrative art",},
  {video: {url: `157863.mp4`},word: "New york school",},
  {video: {url: `154713.mp4`},word: "International style",},
  {video: {url: `65744.mp4`},word: "Industrial design",},
  {video: {url: `89534.mp4`},word: "Modern age",},
  {video: {url: `89536.mp4`},word: "Naturalism",},
  {video: {url: `89516.mp4`},word: "Futurism",},
  {video: {url: `89517.mp4`},word: "Genre",},
  {video: {url: `154558.mp4`},word: "Do-it-yourself",},
  {video: {url: `154522.mp4`},word: "Creative",},
  {video: {url: `58603.mp4`},word: "Decorative",},
  {video: {url: `89565.mp4`},word: "Three-dimensional",},
  {video: {url: `58611.mp4`},word: "Expressive",},
  {video: {url: `91444.mp4`},word: "Imaginative",},
  {video: {url: `154531.mp4`},word: "Cut-and-paste",},
  {video: {url: `96346.mp4`},word: "Exhibit",},
  {video: {url: `244346.mp4`},word: "Deconstruct",},
  {video: {url: `89556.mp4`},word: "Seal",},
  {video: {url: `74965.mp4`},word: "Decorate",},
  {video: {url: `245955.mp4`},word: "Mould",},
  {video: {url: `89541.mp4`},word: "Prime",},
  {video: {url: `93173.mp4`},word: "Inspire",},
  {video: {url: `96316.mp4`},word: "Entertain",},
  {video: {url: `153187.mp4`},word: "Bond",},
  {video: {url: `96214.mp4`},word: "Devise",},
  {video: {url: `91366.mp4`},word: "Arrange",},
  {video: {url: `51933.mp4`},word: "Imagine, to",},
  {video: {url: `157958.mp4`},word: "Practise",},
  {video: {url: `94107.mp4`},word: "Draft",},
  {video: {url: `96601.mp4`},word: "Imitate",},
  {video: {url: `58498.mp4`},word: "Create",},
  {video: {url: `244574.mp4`},word: "Glue",},
  {video: {url: `89513.mp4`},word: "Engrave",},
  {video: {url: `245257.mp4`},word: "Site specific",},
  {video: {url: `244176.mp4`},word: "Character",},
  {video: {url: `245776.mp4`},word: "Presentation",},
  {video: {url: `153072.mp4`},word: "Artwork",},
  {video: {url: `58593.mp4`},word: "Collection",},
  {video: {url: `62891.mp4`},word: "Technique",},
  {video: {url: `62894.mp4`},word: "Symbol",},
  {video: {url: `51583.mp4`},word: "Imitation",},
  {video: {url: `62890.mp4`},word: "Subject",},
  {video: {url: `94100.mp4`},word: "Design for all",},
  {video: {url: `65748.mp4`},word: "Miniature",},
  {video: {url: `244938.mp4`},word: "Creation",},
  {video: {url: `96245.mp4`},word: "Display",},
  {video: {url: `244936.mp4`},word: "Creator",},
  {video: {url: `125812.mp4`},word: "Carpentry",},
  {video: {url: `157947.mp4`},word: "Portfolio",},
  {video: {url: `65771.mp4`},word: "Texture",},
  {video: {url: `153119.mp4`},word: "Blowing",},
  {video: {url: `65772.mp4`},word: "Title",},
  {video: {url: `65749.mp4`},word: "Model",},
  {video: {url: `62873.mp4`},word: "Inspiration",},
  {video: {url: `62871.mp4`},word: "Imagination",},
  {video: {url: `157889.mp4`},word: "Originality",},
  {video: {url: `157887.mp4`},word: "Origami",},
  {video: {url: `245871.mp4`},word: "Ornamentation",},
  {video: {url: `89511.mp4`},word: "Designer",},
  {video: {url: `65745.mp4`},word: "Interpretation",},
  {video: {url: `62886.mp4`},word: "Sign",},
  {video: {url: `62885.mp4`},word: "Shape",},
  {video: {url: `96493.mp4`},word: "Handicraft",},
  {video: {url: `65768.mp4`},word: "Stand",},
  {video: {url: `51822.mp4`},word: "Entertainment",},
  {video: {url: `96139.mp4`},word: "Craft",},
  {video: {url: `245799.mp4`},word: "Point of view",},
  {video: {url: `116417.mp4`},word: "Profile",},
  {video: {url: `89528.mp4`},word: "Binder",},
  {video: {url: `154712.mp4`},word: "Interior design",},
  {video: {url: `154548.mp4`},word: "Design strategies",},
  {video: {url: `154547.mp4`},word: "Design solution",},
  {video: {url: `244338.mp4`},word: "Design procedure",},
  {video: {url: `58251.mp4`},word: "Art exhibition",},
  {video: {url: `58250.mp4`},word: "Arrangement",},
  {video: {url: `58253.mp4`},word: "Artistic method",},
  {video: {url: `58252.mp4`},word: "Artist",},
  {video: {url: `50759.mp4`},word: "Review",},
  {video: {url: `154652.mp4`},word: "Funding",},
  {video: {url: `89543.mp4`},word: "Proportion",},
  {video: {url: `65751.mp4`},word: "Object",},
  {video: {url: `154705.mp4`},word: "In-house designer",},
  {video: {url: `157834.mp4`},word: "Material",},
  {video: {url: `157975.mp4`},word: "Private view",},
  {video: {url: `157973.mp4`},word: "Private collection",},
  {video: {url: `51319.mp4`},word: "Autograph",},
  {video: {url: `89564.mp4`},word: "Symmetry",},
  {video: {url: `155708.mp4`},word: "Visual language",},
  {video: {url: `89518.mp4`},word: "Gallery",},
  {video: {url: `245687.mp4`},word: "Reduction",},
  {video: {url: `244655.mp4`},word: "Idea",},
  {video: {url: `154701.mp4`},word: "Impact",},
  {video: {url: `51554.mp4`},word: "Creativity",},
  {video: {url: `74966.mp4`},word: "Decoration",},
  {video: {url: `154425.mp4`},word: "Conclusion",},
  {video: {url: `62866.mp4`},word: "Form",},
  {video: {url: `155711.mp4`},word: "War artist",},
  {video: {url: `155569.mp4`},word: "Self-expression",},
  {video: {url: `65757.mp4`},word: "Pattern",},
  {video: {url: `65752.mp4`},word: "Ornament",},
  {video: {url: `89609.mp4`},word: "Workroom",},
  {video: {url: `93160.mp4`},word: "Idol",},
  {video: {url: `96088.mp4`},word: "Concept",},
  {video: {url: `155554.mp4`},word: "Royalty rate",},
  {video: {url: `155553.mp4`},word: "Royalty",},
  {video: {url: `154607.mp4`},word: "Finishing touches",},
  {video: {url: `58610.mp4`},word: "Exhibition",},
  {video: {url: `122838.mp4`},word: "Audience",},
  {video: {url: `154667.mp4`},word: "Grid",},
  {video: {url: `58605.mp4`},word: "Design",},
  {video: {url: `155539.mp4`},word: "Reviewer",},
  {video: {url: `246080.mp4`},word: "Leather design",},
  {video: {url: `89512.mp4`},word: "Detail",},
  {video: {url: `244033.mp4`},word: "Artisan",},
  {video: {url: `153071.mp4`},word: "Arts administration",},
  {video: {url: `65778.mp4`},word: "Visualisation",},
  {video: {url: `65777.mp4`},word: "Visual perception",},
  {video: {url: `244036.mp4`},word: "Artform",},
  {video: {url: `244339.mp4`},word: "Design methods",},
  {video: {url: `158047.mp4`},word: "Restoration",},
  {video: {url: `89959.mp4`},word: "Studio",},
  {video: {url: `154659.mp4`},word: "Golden age of illustration",},
  {video: {url: `97081.mp4`},word: "Berth",},
  {video: {url: `58597.mp4`},word: "Contrast",},
  {video: {url: `122567.mp4`},word: "Art",},
  {video: {url: `122568.mp4`},word: "Art critic",},
  {video: {url: `157855.mp4`},word: "Narrative",},
  {video: {url: `244150.mp4`},word: "Cast",},
  {video: {url: `59582.mp4`},word: "Smoked",},
  {video: {url: `91665.mp4`},word: "Well done",},
  {video: {url: `91537.mp4`},word: "Oven baked",},
  {video: {url: `59200.mp4`},word: "Home-made",},
  {video: {url: `91506.mp4`},word: "Grilled",},
  {video: {url: `74989.mp4`},word: "Flamb\u00e9",},
  {video: {url: `75030.mp4`},word: "Medium",},
  {video: {url: `117006.mp4`},word: "Stale",},
  {video: {url: `92862.mp4`},word: "Medium-rare",},
  {video: {url: `74953.mp4`},word: "Crumble",},
  {video: {url: `58867.mp4`},word: "Boil",},
  {video: {url: `74887.mp4`},word: "Boil down",},
  {video: {url: `89796.mp4`},word: "Broil",},
  {video: {url: `59575.mp4`},word: "Season, to",},
  {video: {url: `89798.mp4`},word: "Brown, to",},
  {video: {url: `153733.mp4`},word: "Strain, to",},
  {video: {url: `74871.mp4`},word: "Add salt to",},
  {video: {url: `91477.mp4`},word: "Deep fry",},
  {video: {url: `75019.mp4`},word: "Knead",},
  {video: {url: `91653.mp4`},word: "Thicken",},
  {video: {url: `153732.mp4`},word: "Stew, to",},
  {video: {url: `91646.mp4`},word: "Stir",},
  {video: {url: `123618.mp4`},word: "Beat",},
  {video: {url: `59560.mp4`},word: "Refrigerate",},
  {video: {url: `116802.mp4`},word: "Scramble",},
  {video: {url: `91502.mp4`},word: "Grate",},
  {video: {url: `91623.mp4`},word: "Simmer",},
  {video: {url: `125297.mp4`},word: "Braise",},
  {video: {url: `58856.mp4`},word: "Bake a cake",},
  {video: {url: `89787.mp4`},word: "Bake",},
  {video: {url: `96287.mp4`},word: "Drizzle",},
  {video: {url: `91565.mp4`},word: "Pickle",},
  {video: {url: `91479.mp4`},word: "Dice",},
  {video: {url: `123372.mp4`},word: "Baste",},
  {video: {url: `91518.mp4`},word: "Marinate",},
  {video: {url: `74928.mp4`},word: "Chop",},
  {video: {url: `74942.mp4`},word: "Cook",},
  {video: {url: `93169.mp4`},word: "Ingredient",},
  {video: {url: `74875.mp4`},word: "Apron",},
  {video: {url: `59222.mp4`},word: "Kitchen",},
  {video: {url: `91482.mp4`},word: "Dough",},
  {video: {url: `59558.mp4`},word: "Recipe",},
  {video: {url: `91489.mp4`},word: "Expiry date",},
  {video: {url: `92772.mp4`},word: "Ice cube tray",},
  {video: {url: `73025.mp4`},word: "Heating",},
  {video: {url: `74987.mp4`},word: "Fire",},
  {video: {url: `116491.mp4`},word: "Recipe card",},
  {video: {url: `116490.mp4`},word: "Recipe book",},
  {video: {url: `96948.mp4`},word: "Mixture",},
  {video: {url: `91543.mp4`},word: "Pantry",},
  {video: {url: `75017.mp4`},word: "Jar",},
  {video: {url: `116785.mp4`},word: "Salt box",},
  {video: {url: `74940.mp4`},word: "Combination",},
  {video: {url: `96152.mp4`},word: "Crust",},
  {video: {url: `123601.mp4`},word: "Batter",},
  {video: {url: `96403.mp4`},word: "Garbage disposal",},
  {video: {url: `59152.mp4`},word: "Cookery",},
  {video: {url: `123596.mp4`},word: "Batch",},
  {video: {url: `116975.mp4`},word: "Spice rack",},
  {video: {url: `90090.mp4`},word: "Toaster oven",},
  {video: {url: `92877.mp4`},word: "Microwave",},
  {video: {url: `74982.mp4`},word: "Electric mixer",},
  {video: {url: `93042.mp4`},word: "Food processor",},
  {video: {url: `91648.mp4`},word: "Stove",},
  {video: {url: `93226.mp4`},word: "Chauffer",},
  {video: {url: `124721.mp4`},word: "Blender",},
  {video: {url: `124982.mp4`},word: "Boiler",},
  {video: {url: `75036.mp4`},word: "Mixer",},
  {video: {url: `74996.mp4`},word: "Freezer",},
  {video: {url: `90187.mp4`},word: "Waffle maker",},
  {video: {url: `91505.mp4`},word: "Grill",},
  {video: {url: `125618.mp4`},word: "Burner",},
  {video: {url: `91658.mp4`},word: "Toaster",},
  {video: {url: `59552.mp4`},word: "Pressure cooker",},
  {video: {url: `96591.mp4`},word: "Ice cream maker",},
  {video: {url: `74998.mp4`},word: "Fridge",},
  {video: {url: `96061.mp4`},word: "Coffee pot",},
  {video: {url: `109877.mp4`},word: "Electric stove",},
  {video: {url: `123299.mp4`},word: "Barbecue grill",},
  {video: {url: `89887.mp4`},word: "Oven",},
  {video: {url: `125523.mp4`},word: "Bread machine",},
  {video: {url: `96985.mp4`},word: "Vegetable steamer",},
  {video: {url: `74881.mp4`},word: "Barbecue",},
  {video: {url: `92851.mp4`},word: "Meat grinder",},
  {video: {url: `75007.mp4`},word: "Gas cooker",},
  {video: {url: `59547.mp4`},word: "Potato peeler",},
  {video: {url: `89718.mp4`},word: "Measuring cup",},
  {video: {url: `244296.mp4`},word: "Cookware",},
  {video: {url: `59159.mp4`},word: "Eggbeater",},
  {video: {url: `74888.mp4`},word: "Bowl",},
  {video: {url: `116377.mp4`},word: "Potato masher",},
  {video: {url: `116378.mp4`},word: "Potholder",},
  {video: {url: `62630.mp4`},word: "Bottle opener",},
  {video: {url: `74964.mp4`},word: "Cutting board",},
  {video: {url: `75020.mp4`},word: "Ladle",},
  {video: {url: `91551.mp4`},word: "Pastry cutter",},
  {video: {url: `58859.mp4`},word: "Baking tin",},
  {video: {url: `59328.mp4`},word: "Lemon squeezer",},
  {video: {url: `74938.mp4`},word: "Coffee spoon",},
  {video: {url: `91656.mp4`},word: "Tin opener",},
  {video: {url: `109887.mp4`},word: "Food scissors",},
  {video: {url: `109767.mp4`},word: "Draining spoon",},
  {video: {url: `91463.mp4`},word: "Cocktail shaker",},
  {video: {url: `124706.mp4`},word: "Blade",},
  {video: {url: `59588.mp4`},word: "Soup ladle",},
  {video: {url: `59570.mp4`},word: "Saucepan",},
  {video: {url: `189727.mp4`},word: "Mortar and pestle",},
  {video: {url: `153746.mp4`},word: "Teaspoon",},
  {video: {url: `125299.mp4`},word: "Braising pan",},
  {video: {url: `96231.mp4`},word: "Dipper",},
  {video: {url: `153785.mp4`},word: "Whisk",},
  {video: {url: `59613.mp4`},word: "Thermometer",},
  {video: {url: `59646.mp4`},word: "Wooden spoon",},
  {video: {url: `153734.mp4`},word: "Strainer",},
  {video: {url: `116597.mp4`},word: "Rolling pin",},
  {video: {url: `59609.mp4`},word: "Tablespoon",},
  {video: {url: `62708.mp4`},word: "Cheese grater",},
  {video: {url: `91541.mp4`},word: "Pan",},
  {video: {url: `91539.mp4`},word: "Oyster-opener",},
  {video: {url: `116316.mp4`},word: "Pizza cutter",},
  {video: {url: `59596.mp4`},word: "Spoon",},
  {video: {url: `153663.mp4`},word: "Peppermill",},
  {video: {url: `91638.mp4`},word: "Spatula",},
  {video: {url: `62537.mp4`},word: "Baking pan",},
  {video: {url: `59153.mp4`},word: "Corkscrew",},
  {video: {url: `125954.mp4`},word: "Cheese slicer",},
  {video: {url: `189662.mp4`},word: "Measuring device",},
  {video: {url: `91467.mp4`},word: "Cookware set",},
  {video: {url: `75005.mp4`},word: "Frying pan",},
  {video: {url: `75006.mp4`},word: "Funnel",},
  {video: {url: `125643.mp4`},word: "Butcher board",},
  {video: {url: `51293.mp4`},word: "Can opener",},
  {video: {url: `74939.mp4`},word: "Colander",},
  {video: {url: `89928.mp4`},word: "Are you hungry?",},
  {video: {url: `93286.mp4`},word: "Delicious",},
  {video: {url: `153747.mp4`},word: "Tender",},
  {video: {url: `245143.mp4`},word: "Tasteless",},
  {video: {url: `245347.mp4`},word: "Salty",},
  {video: {url: `93937.mp4`},word: "Dip",},
  {video: {url: `59616.mp4`},word: "To be thirsty",},
  {video: {url: `74980.mp4`},word: "Eat, to",},
  {video: {url: `59615.mp4`},word: "To be hungry",},
  {video: {url: `59549.mp4`},word: "Pour, to",},
  {video: {url: `59617.mp4`},word: "To have breakfast",},
  {video: {url: `74916.mp4`},word: "Chew",},
  {video: {url: `59303.mp4`},word: "Lay the table",},
  {video: {url: `96421.mp4`},word: "Gobble",},
  {video: {url: `153289.mp4`},word: "Drink",},
  {video: {url: `91663.mp4`},word: "Uncork",},
  {video: {url: `96230.mp4`},word: "Dine",},
  {video: {url: `125619.mp4`},word: "Burp",},
  {video: {url: `91533.mp4`},word: "Open a bottle",},
  {video: {url: `59619.mp4`},word: "To have lunch",},
  {video: {url: `59620.mp4`},word: "To have supper",},
  {video: {url: `110519.mp4`},word: "Munch",},
  {video: {url: `59618.mp4`},word: "To have dinner",},
  {video: {url: `93324.mp4`},word: "Drink a toast",},
  {video: {url: `116879.mp4`},word: "Sip",},
  {video: {url: `117114.mp4`},word: "Swallow",},
  {video: {url: `96456.mp4`},word: "Gulp",},
  {video: {url: `124694.mp4`},word: "Bite",},
  {video: {url: `244918.mp4`},word: "Feast",},
  {video: {url: `90201.mp4`},word: "Water fountain",},
  {video: {url: `91478.mp4`},word: "Dessert knife",},
  {video: {url: `91633.mp4`},word: "Slice",},
  {video: {url: `244952.mp4`},word: "Bottle",},
  {video: {url: `89782.mp4`},word: "Aroma",},
  {video: {url: `75026.mp4`},word: "Main dish",},
  {video: {url: `90124.mp4`},word: "Tray",},
  {video: {url: `74994.mp4`},word: "Fork",},
  {video: {url: `244468.mp4`},word: "Expired food",},
  {video: {url: `245709.mp4`},word: "Raw food",},
  {video: {url: `51611.mp4`},word: "Picnic",},
  {video: {url: `91652.mp4`},word: "Taste",},
  {video: {url: `59156.mp4`},word: "Cutlery",},
  {video: {url: `89781.mp4`},word: "Appetizer",},
  {video: {url: `59540.mp4`},word: "Plate",},
  {video: {url: `111047.mp4`},word: "Nibble",},
  {video: {url: `116606.mp4`},word: "Round-bottomed flask",},
  {video: {url: `59589.mp4`},word: "Soup spoon",},
  {video: {url: `59587.mp4`},word: "Soup dish",},
  {video: {url: `59608.mp4`},word: "Tablecloth",},
  {video: {url: `59612.mp4`},word: "Teapot",},
  {video: {url: `51872.mp4`},word: "Lunch",},
  {video: {url: `50884.mp4`},word: "Thirst",},
  {video: {url: `244371.mp4`},word: "Drinking water",},
  {video: {url: `51873.mp4`},word: "Dinner",},
  {video: {url: `59644.mp4`},word: "Wine rack",},
  {video: {url: `116327.mp4`},word: "Platter",},
  {video: {url: `74958.mp4`},word: "Cup",},
  {video: {url: `96122.mp4`},word: "Cork",},
  {video: {url: `74873.mp4`},word: "Appetite",},
  {video: {url: `75041.mp4`},word: "Napkin",},
  {video: {url: `91742.mp4`},word: "Dieting",},
  {video: {url: `51325.mp4`},word: "Banquet",},
  {video: {url: `116296.mp4`},word: "Picnic basket",},
  {video: {url: `116295.mp4`},word: "Picnic area",},
  {video: {url: `91496.mp4`},word: "Flask",},
  {video: {url: `74969.mp4`},word: "Dessert spoon",},
  {video: {url: `125848.mp4`},word: "Caterer",},
  {video: {url: `74993.mp4`},word: "Food allergy",},
  {video: {url: `116297.mp4`},word: "Picnic table",},
  {video: {url: `116781.mp4`},word: "Salad bowl",},
  {video: {url: `74971.mp4`},word: "Dish",},
  {video: {url: `75008.mp4`},word: "Glass",},
  {video: {url: `92850.mp4`},word: "Meal",},
  {video: {url: `111069.mp4`},word: "Nip",},
  {video: {url: `125531.mp4`},word: "Breakfast",},
  {video: {url: `62581.mp4`},word: "Bib",},
  {video: {url: `92916.mp4`},word: "Mug",},
  {video: {url: `74970.mp4`},word: "Diet",},
  {video: {url: `243950.mp4`},word: "London",},
  {video: {url: `50780.mp4`},word: "Scotland",},
  {video: {url: `63570.mp4`},word: "Russia",},
  {video: {url: `110160.mp4`},word: "Initiate",},
  {video: {url: `153738.mp4`},word: "Swedish institute",},
  {video: {url: `118374.mp4`},word: "Aid and charity provider",},
  {video: {url: `189480.mp4`},word: "Sheltered workshop",},
  {video: {url: `51588.mp4`},word: "Informal group",},
  {video: {url: `118358.mp4`},word: "Advisory bureau",},
  {video: {url: `89588.mp4`},word: "Federation of trade unions",},
  {video: {url: `96621.mp4`},word: "Institute",},
  {video: {url: `91322.mp4`},word: "Child-welfare centre",},
  {video: {url: `96956.mp4`},word: "Academy",},
  {video: {url: `51576.mp4`},word: "Formal group",},
  {video: {url: `189420.mp4`},word: "Nongovernmental organization",},
  {video: {url: `246117.mp4`},word: "Institution",},
  {video: {url: `118373.mp4`},word: "Aid and charity foundation",},
  {video: {url: `91684.mp4`},word: "Agency",},
  {video: {url: `245857.mp4`},word: "Paper mill",},
  {video: {url: `245377.mp4`},word: "Rights and permissions department",},
  {video: {url: `189421.mp4`},word: "Non-profit-making organization",},
  {video: {url: `96974.mp4`},word: "Union",},
  {video: {url: `185315.mp4`},word: "World health organization",},
  {video: {url: `91706.mp4`},word: "Basic state pension",},
  {video: {url: `245071.mp4`},word: "Unemployment assistance",},
  {video: {url: `188343.mp4`},word: "Assistance allowance",},
  {video: {url: `244196.mp4`},word: "Child benefit",},
  {video: {url: `189424.mp4`},word: "Old-age benefit",},
  {video: {url: `189530.mp4`},word: "Social welfare service",},
  {video: {url: `58370.mp4`},word: "Social benefit",},
  {video: {url: `94231.mp4`},word: "Government grant",},
  {video: {url: `185273.mp4`},word: "Sickness benefit",},
  {video: {url: `245390.mp4`},word: "Retirement conditions",},
  {video: {url: `94136.mp4`},word: "Job support systems",},
  {video: {url: `185111.mp4`},word: "Maternity benefit",},
  {video: {url: `185296.mp4`},word: "Supported living",},
  {video: {url: `58269.mp4`},word: "Cash benefit",},
  {video: {url: `58422.mp4`},word: "Disability benefit",},
  {video: {url: `58372.mp4`},word: "Social insurance",},
  {video: {url: `245391.mp4`},word: "Retirement allowance",},
  {video: {url: `185220.mp4`},word: "Pension",},
  {video: {url: `94354.mp4`},word: "Student grant",},
  {video: {url: `244432.mp4`},word: "Employment benefit",},
  {video: {url: `94105.mp4`},word: "Direct government funding",},
  {video: {url: `91771.mp4`},word: "Grant",},
  {video: {url: `245009.mp4`},word: "Welfare society",},
  {video: {url: `245240.mp4`},word: "Social security benefit",},
  {video: {url: `244361.mp4`},word: "Disable benefit",},
  {video: {url: `185283.mp4`},word: "Social security",},
  {video: {url: `189201.mp4`},word: "Family allowance",},
  {video: {url: `157906.mp4`},word: "Paternity leave",},
  {video: {url: `94347.mp4`},word: "Special adult student aid",},
  {video: {url: `124283.mp4`},word: "Benefit",},
  {video: {url: `244350.mp4`},word: "Death grant",},
  {video: {url: `189529.mp4`},word: "Social welfare",},
  {video: {url: `91790.mp4`},word: "Incapacity for work",},
  {video: {url: `94302.mp4`},word: "Post secondary student aid",},
  {video: {url: `125978.mp4`},word: "Child allowance",},
  {video: {url: `154604.mp4`},word: "Financial aid",},
  {video: {url: `189431.mp4`},word: "Paid parental leave",},
  {video: {url: `185308.mp4`},word: "Unemployment benefit",},
  {video: {url: `91810.mp4`},word: "Maternity leave",},
  {video: {url: `117119.mp4`},word: "Swedish inheritance fund",},
  {video: {url: `189493.mp4`},word: "Welfare",},
  {video: {url: `94382.mp4`},word: "Upper secondary student aid",},
  {video: {url: `244344.mp4`},word: "Dental care benefit",},
  {video: {url: `153761.mp4`},word: "Unemployment benefit fund",},
  {video: {url: `245003.mp4`},word: "Well-paid",},
  {video: {url: `94149.mp4`},word: "Part-time",},
  {video: {url: `58290.mp4`},word: "Earning",},
  {video: {url: `93340.mp4`},word: "Earn",},
  {video: {url: `51712.mp4`},word: "Income",},
  {video: {url: `91807.mp4`},word: "Low-paid employment",},
  {video: {url: `91704.mp4`},word: "Average wage",},
  {video: {url: `89578.mp4`},word: "Bonus",},
  {video: {url: `51500.mp4`},word: "Wage",},
  {video: {url: `245827.mp4`},word: "Performance-related pay",},
  {video: {url: `244127.mp4`},word: "Bonus payment",},
  {video: {url: `92665.mp4`},word: "Extra pay",},
  {video: {url: `244590.mp4`},word: "Gender pay gap",},
  {video: {url: `245838.mp4`},word: "Pay scale",},
  {video: {url: `92685.mp4`},word: "Gratuity",},
  {video: {url: `91812.mp4`},word: "Minimum wage",},
  {video: {url: `51683.mp4`},word: "Black money",},
  {video: {url: `51899.mp4`},word: "Gross",},
  {video: {url: `50774.mp4`},word: "Salary",},
  {video: {url: `244432.mp4`},word: "Employment benefit",},
  {video: {url: `245025.mp4`},word: "Wage policy",},
  {video: {url: `91785.mp4`},word: "Homeless",},
  {video: {url: `51200.mp4`},word: "Bury",},
  {video: {url: `51625.mp4`},word: "Rescue",},
  {video: {url: `116375.mp4`},word: "Postal worker",},
  {video: {url: `96375.mp4`},word: "Fire brigade",},
  {video: {url: `116374.mp4`},word: "Post office",},
  {video: {url: `185081.mp4`},word: "Home help services",},
  {video: {url: `96927.mp4`},word: "Postman",},
  {video: {url: `96649.mp4`},word: "Letter box",},
  {video: {url: `94361.mp4`},word: "Support services",},
  {video: {url: `116351.mp4`},word: "Police officer",},
  {video: {url: `91737.mp4`},word: "Day centre",},
  {video: {url: `189454.mp4`},word: "Public works",},
  {video: {url: `245736.mp4`},word: "Public library",},
  {video: {url: `96377.mp4`},word: "Fire station",},
  {video: {url: `93023.mp4`},word: "Firefighter",},
  {video: {url: `91725.mp4`},word: "Community care service",},
  {video: {url: `91726.mp4`},word: "Community residential facility",},
  {video: {url: `153235.mp4`},word: "Burial sites",},
  {video: {url: `245008.mp4`},word: "Welfare worker",},
  {video: {url: `158019.mp4`},word: "Recorded delivery",},
  {video: {url: `48545.mp4`},word: "Ladies toilet",},
  {video: {url: `244602.mp4`},word: "Health care services",},
  {video: {url: `50787.mp4`},word: "Services",},
  {video: {url: `58312.mp4`},word: "Health protection",},
  {video: {url: `245009.mp4`},word: "Welfare society",},
  {video: {url: `245876.mp4`},word: "Organisation of healthcare",},
  {video: {url: `116352.mp4`},word: "Police station",},
  {video: {url: `189479.mp4`},word: "Shelter",},
  {video: {url: `185084.mp4`},word: "Homeless person",},
  {video: {url: `58374.mp4`},word: "Social protection",},
  {video: {url: `48557.mp4`},word: "Library",},
  {video: {url: `116353.mp4`},word: "Policeman",},
  {video: {url: `94336.mp4`},word: "School health service",},
  {video: {url: `58382.mp4`},word: "Visiting hours",},
  {video: {url: `189467.mp4`},word: "Salvage and rescue service",},
  {video: {url: `96376.mp4`},word: "Fire engine",},
  {video: {url: `245797.mp4`},word: "Police car",},
  {video: {url: `245742.mp4`},word: "Public building",},
  {video: {url: `49201.mp4`},word: "Police",},
  {video: {url: `58376.mp4`},word: "Social worker",},
  {video: {url: `93019.mp4`},word: "Fire department",},
  {video: {url: `96442.mp4`},word: "Guard",},
  {video: {url: `90214.mp4`},word: "Weather reporter",},
  {video: {url: `94190.mp4`},word: "Adviser",},
  {video: {url: `110164.mp4`},word: "Inventory clerk",},
  {video: {url: `92776.mp4`},word: "Interior decorator",},
  {video: {url: `59637.mp4`},word: "Waitress",},
  {video: {url: `96390.mp4`},word: "Forklift operator",},
  {video: {url: `90143.mp4`},word: "Tv repairman",},
  {video: {url: `93950.mp4`},word: "Doorkeeper",},
  {video: {url: `90168.mp4`},word: "Vendor",},
  {video: {url: `96984.mp4`},word: "Veterinarian",},
  {video: {url: `51357.mp4`},word: "Beekeeper",},
  {video: {url: `244188.mp4`},word: "Child psychologist",},
  {video: {url: `244189.mp4`},word: "Child protection worker",},
  {video: {url: `73314.mp4`},word: "Gardener",},
  {video: {url: `117024.mp4`},word: "Steelworker",},
  {video: {url: `51383.mp4`},word: "Car salesman",},
  {video: {url: `111038.mp4`},word: "Newscaster",},
  {video: {url: `92953.mp4`},word: "Newspaper salesman",},
  {video: {url: `245022.mp4`},word: "Warden",},
  {video: {url: `117060.mp4`},word: "Street cleaner",},
  {video: {url: `58554.mp4`},word: "Technician",},
  {video: {url: `92831.mp4`},word: "Mailman",},
  {video: {url: `244939.mp4`},word: "Counselor",},
  {video: {url: `92779.mp4`},word: "Magician",},
  {video: {url: `92780.mp4`},word: "Maid",},
  {video: {url: `74878.mp4`},word: "Assistant",},
  {video: {url: `122540.mp4`},word: "Architect",},
  {video: {url: `62561.mp4`},word: "Beautician",},
  {video: {url: `154746.mp4`},word: "Librarian",},
  {video: {url: `51129.mp4`},word: "Bodyguard",},
  {video: {url: `124269.mp4`},word: "Bell hop",},
  {video: {url: `93149.mp4`},word: "Housekeeper",},
  {video: {url: `116317.mp4`},word: "Pizza delivery person",},
  {video: {url: `49201.mp4`},word: "Police",},
  {video: {url: `116784.mp4`},word: "Salesclerk",},
  {video: {url: `154408.mp4`},word: "Cleaner",},
  {video: {url: `116780.mp4`},word: "Sailor",},
  {video: {url: `157900.mp4`},word: "Paparazzi",},
  {video: {url: `89825.mp4`},word: "Journalist",},
  {video: {url: `153241.mp4`},word: "Camera operator",},
  {video: {url: `50831.mp4`},word: "Child care provider",},
  {video: {url: `244162.mp4`},word: "Career planning",},
  {video: {url: `154605.mp4`},word: "Financial manager",},
  {video: {url: `96107.mp4`},word: "Consultant",},
  {video: {url: `116318.mp4`},word: "Pizza maker",},
  {video: {url: `62653.mp4`},word: "Bricklayer",},
  {video: {url: `116413.mp4`},word: "Produce vendor",},
  {video: {url: `116485.mp4`},word: "Real estate salesperson",},
  {video: {url: `116484.mp4`},word: "Real estate agent",},
  {video: {url: `96353.mp4`},word: "Exterminator",},
  {video: {url: `58376.mp4`},word: "Social worker",},
  {video: {url: `93313.mp4`},word: "Dog groomer",},
  {video: {url: `93314.mp4`},word: "Dog trainer",},
  {video: {url: `96623.mp4`},word: "Insurance salesman",},
  {video: {url: `90134.mp4`},word: "Truck driver",},
  {video: {url: `245750.mp4`},word: "Professional standard",},
  {video: {url: `153205.mp4`},word: "Bookseller",},
  {video: {url: `51753.mp4`},word: "Secretary",},
  {video: {url: `109517.mp4`},word: "Advocate",},
  {video: {url: `189633.mp4`},word: "Maitre d",},
  {video: {url: `92840.mp4`},word: "Manager",},
  {video: {url: `92983.mp4`},word: "Factory worker",},
  {video: {url: `116809.mp4`},word: "Seamstress",},
  {video: {url: `92880.mp4`},word: "Milkman",},
  {video: {url: `116814.mp4`},word: "Security guard",},
  {video: {url: `94182.mp4`},word: "Youth recreation leader",},
  {video: {url: `51339.mp4`},word: "Bartender",},
  {video: {url: `59636.mp4`},word: "Waiter",},
  {video: {url: `92923.mp4`},word: "Musician",},
  {video: {url: `96317.mp4`},word: "Entomologist",},
  {video: {url: `245098.mp4`},word: "Training consultant",},
  {video: {url: `244534.mp4`},word: "Fruit seller",},
  {video: {url: `94343.mp4`},word: "Sign language interpreter",},
  {video: {url: `116489.mp4`},word: "Receptionist",},
  {video: {url: `96130.mp4`},word: "Courier",},
  {video: {url: `245976.mp4`},word: "Miner",},
  {video: {url: `96267.mp4`},word: "Doorman",},
  {video: {url: `96936.mp4`},word: "Marines",},
  {video: {url: `116294.mp4`},word: "Piano mover",},
  {video: {url: `117108.mp4`},word: "Surveyor",},
  {video: {url: `191297.mp4`},word: "Piccolo",},
  {video: {url: `122853.mp4`},word: "Auto painter",},
  {video: {url: `122852.mp4`},word: "Auto mechanic",},
  {video: {url: `116869.mp4`},word: "Sign maker",},
  {video: {url: `63610.mp4`},word: "Electrician",},
  {video: {url: `116870.mp4`},word: "Sign painter",},
  {video: {url: `58449.mp4`},word: "Lorry-driver",},
  {video: {url: `92848.mp4`},word: "Mayor",},
  {video: {url: `116206.mp4`},word: "Paperboy",},
  {video: {url: `92852.mp4`},word: "Mechanic",},
  {video: {url: `116211.mp4`},word: "Paralegal",},
  {video: {url: `96412.mp4`},word: "Geographer",},
  {video: {url: `116375.mp4`},word: "Postal worker",},
  {video: {url: `87768.mp4`},word: "Fashion model",},
  {video: {url: `153194.mp4`},word: "Book producer",},
  {video: {url: `125811.mp4`},word: "Carpenter",},
  {video: {url: `153197.mp4`},word: "Book publisher",},
  {video: {url: `116406.mp4`},word: "Private eye",},
  {video: {url: `96298.mp4`},word: "Editor",},
  {video: {url: `90123.mp4`},word: "Travel guide",},
  {video: {url: `91320.mp4`},word: "Carrier",},
  {video: {url: `96274.mp4`},word: "Draftsman",},
  {video: {url: `123260.mp4`},word: "Bank teller",},
  {video: {url: `117096.mp4`},word: "Superintendent",},
  {video: {url: `93023.mp4`},word: "Firefighter",},
  {video: {url: `96380.mp4`},word: "Fisherman",},
  {video: {url: `96379.mp4`},word: "Fish farmer",},
  {video: {url: `116288.mp4`},word: "Pharmacist",},
  {video: {url: `90198.mp4`},word: "Watch salesperson",},
  {video: {url: `110197.mp4`},word: "Masseuse",},
  {video: {url: `245008.mp4`},word: "Welfare worker",},
  {video: {url: `110199.mp4`},word: "Matador",},
  {video: {url: `191296.mp4`},word: "Piano tuner",},
  {video: {url: `153189.mp4`},word: "Book editor",},
  {video: {url: `93099.mp4`},word: "Graphic designer",},
  {video: {url: `62503.mp4`},word: "Astronomer",},
  {video: {url: `62502.mp4`},word: "Astronaut",},
  {video: {url: `92696.mp4`},word: "Intepreter",},
  {video: {url: `116353.mp4`},word: "Policeman",},
  {video: {url: `123262.mp4`},word: "Banker",},
  {video: {url: `51287.mp4`},word: "Analyst",},
  {video: {url: `155591.mp4`},word: "Shop assistant",},
  {video: {url: `116351.mp4`},word: "Police officer",},
  {video: {url: `116227.mp4`},word: "Parking attendant",},
  {video: {url: `116226.mp4`},word: "Park ranger",},
  {video: {url: `244820.mp4`},word: "Servant",},
  {video: {url: `125623.mp4`},word: "Bus driver",},
  {video: {url: `125661.mp4`},word: "Cafeteria worker",},
  {video: {url: `77310.mp4`},word: "Pilot",},
  {video: {url: `94360.mp4`},word: "Supervisor",},
  {video: {url: `63628.mp4`},word: "Programmer",},
  {video: {url: `96261.mp4`},word: "Dog catcher",},
  {video: {url: `62508.mp4`},word: "Auctioneer",},
  {video: {url: `62507.mp4`},word: "Attorney",},
  {video: {url: `154400.mp4`},word: "Chief editor",},
  {video: {url: `51869.mp4`},word: "Interpreter",},
  {video: {url: `116585.mp4`},word: "Road worker",},
  {video: {url: `246022.mp4`},word: "Managing editor",},
  {video: {url: `96978.mp4`},word: "Usher",},
  {video: {url: `96637.mp4`},word: "Janitor",},
  {video: {url: `116849.mp4`},word: "Ship captain",},
  {video: {url: `157990.mp4`},word: "Publicist",},
  {video: {url: `90112.mp4`},word: "Traffic controller",},
  {video: {url: `155539.mp4`},word: "Reviewer",},
  {video: {url: `116601.mp4`},word: "Roofer",},
  {video: {url: `157853.mp4`},word: "Museum guide",},
  {video: {url: `89998.mp4`},word: "Telephone operator",},
  {video: {url: `62273.mp4`},word: "Animal trainer",},
  {video: {url: `124998.mp4`},word: "Bookkeeper",},
  {video: {url: `93119.mp4`},word: "Handyman",},
  {video: {url: `62527.mp4`},word: "Babysitter",},
  {video: {url: `110181.mp4`},word: "Machinery operator",},
  {video: {url: `51291.mp4`},word: "Cameraman",},
  {video: {url: `244529.mp4`},word: "Garbage collector",},
  {video: {url: `245133.mp4`},word: "Television presenter",},
  {video: {url: `59564.mp4`},word: "Restaurant director",},
  {video: {url: `116237.mp4`},word: "Party planner",},
  {video: {url: `153070.mp4`},word: "Art director",},
  {video: {url: `187180.mp4`},word: "Gamekeeper",},
  {video: {url: `111150.mp4`},word: "Office staff",},
  {video: {url: `96945.mp4`},word: "Meteorologist",},
  {video: {url: `92771.mp4`},word: "Ice cream vendor",},
  {video: {url: `93103.mp4`},word: "Groom",},
  {video: {url: `94286.mp4`},word: "Notetaker",},
  {video: {url: `116859.mp4`},word: "Short order cook",},
  {video: {url: `92782.mp4`},word: "Mail carrier",},
  {video: {url: `96186.mp4`},word: "Delivery person",},
  {video: {url: `74911.mp4`},word: "Chef",},
  {video: {url: `116855.mp4`},word: "Shoemaker",},
  {video: {url: `125848.mp4`},word: "Caterer",},
  {video: {url: `116857.mp4`},word: "Shopkeeper",},
  {video: {url: `90174.mp4`},word: "Videographer",},
  {video: {url: `116526.mp4`},word: "Reporter",},
  {video: {url: `116798.mp4`},word: "Scentist",},
  {video: {url: `125958.mp4`},word: "Chemist",},
  {video: {url: `155688.mp4`},word: "Town planner",},
  {video: {url: `116523.mp4`},word: "Repairman",},
  {video: {url: `89993.mp4`},word: "Taxi driver",},
  {video: {url: `62536.mp4`},word: "Baker",},
  {video: {url: `244299.mp4`},word: "Cook",},
  {video: {url: `96951.mp4`},word: "Mover",},
  {video: {url: `96056.mp4`},word: "Chimney sweep",},
  {video: {url: `244793.mp4`},word: "Tax collector",},
  {video: {url: `89991.mp4`},word: "Tattoo artist",},
  {video: {url: `116248.mp4`},word: "Pawnbroker",},
  {video: {url: `51658.mp4`},word: "Administrative",},
  {video: {url: `154435.mp4`},word: "Corporate",},
  {video: {url: `96628.mp4`},word: "Intermediate",},
  {video: {url: `51310.mp4`},word: "Authorize",},
  {video: {url: `51844.mp4`},word: "Collaborate",},
  {video: {url: `89573.mp4`},word: "Administer",},
  {video: {url: `51700.mp4`},word: "Close down",},
  {video: {url: `91333.mp4`},word: "Organize",},
  {video: {url: `51805.mp4`},word: "Delegate",},
  {video: {url: `51897.mp4`},word: "Negotiate",},
  {video: {url: `48574.mp4`},word: "Manage",},
  {video: {url: `155641.mp4`},word: "Structure",},
  {video: {url: `118519.mp4`},word: "Annul",},
  {video: {url: `92971.mp4`},word: "Expand",},
  {video: {url: `58492.mp4`},word: "Convince",},
  {video: {url: `116547.mp4`},word: "Revise",},
  {video: {url: `51743.mp4`},word: "Position",},
  {video: {url: `91416.mp4`},word: "Found",},
  {video: {url: `245758.mp4`},word: "Proceedings",},
  {video: {url: `51752.mp4`},word: "Board",},
  {video: {url: `89603.mp4`},word: "Strategic management",},
  {video: {url: `51723.mp4`},word: "Merger",},
  {video: {url: `51674.mp4`},word: "Department",},
  {video: {url: `51758.mp4`},word: "Statistics",},
  {video: {url: `124972.mp4`},word: "Board",},
  {video: {url: `51706.mp4`},word: "Franchise",},
  {video: {url: `90171.mp4`},word: "Vice president",},
  {video: {url: `157974.mp4`},word: "Private sector",},
  {video: {url: `87732.mp4`},word: "Business",},
  {video: {url: `245751.mp4`},word: "Professional secrecy",},
  {video: {url: `94275.mp4`},word: "Minimum requirement",},
  {video: {url: `96116.mp4`},word: "Convention",},
  {video: {url: `94000.mp4`},word: "Proposal",},
  {video: {url: `246064.mp4`},word: "Limited company",},
  {video: {url: `51724.mp4`},word: "Minutes",},
  {video: {url: `93053.mp4`},word: "Foundation",},
  {video: {url: `118369.mp4`},word: "Agreement",},
  {video: {url: `51701.mp4`},word: "Commitment",},
  {video: {url: `93276.mp4`},word: "Deal",},
  {video: {url: `153712.mp4`},word: "Sector",},
  {video: {url: `94175.mp4`},word: "User involvement",},
  {video: {url: `89595.mp4`},word: "Post",},
  {video: {url: `89590.mp4`},word: "Firm",},
  {video: {url: `116411.mp4`},word: "Processing",},
  {video: {url: `51745.mp4`},word: "Project management",},
  {video: {url: `51753.mp4`},word: "Secretary",},
  {video: {url: `51757.mp4`},word: "Share",},
  {video: {url: `154436.mp4`},word: "Corporate video",},
  {video: {url: `154722.mp4`},word: "Key account",},
  {video: {url: `96184.mp4`},word: "Delegation",},
  {video: {url: `154638.mp4`},word: "Formal letter",},
  {video: {url: `51935.mp4`},word: "Inauguration",},
  {video: {url: `154705.mp4`},word: "In-house designer",},
  {video: {url: `94282.mp4`},word: "Networking",},
  {video: {url: `118520.mp4`},word: "Annulment",},
  {video: {url: `51829.mp4`},word: "Personnel",},
  {video: {url: `153676.mp4`},word: "Precontract",},
  {video: {url: `51731.mp4`},word: "Pact",},
  {video: {url: `153646.mp4`},word: "Organization chart",},
  {video: {url: `51663.mp4`},word: "Annual report",},
  {video: {url: `51727.mp4`},word: "Logistics",},
  {video: {url: `51732.mp4`},word: "Parthner",},
  {video: {url: `51661.mp4`},word: "Agent",},
  {video: {url: `118341.mp4`},word: "Administration",},
  {video: {url: `244389.mp4`},word: "Discussion group",},
  {video: {url: `89574.mp4`},word: "Administrator",},
  {video: {url: `246063.mp4`},word: "Limited liability company",},
  {video: {url: `244233.mp4`},word: "Company culture",},
  {video: {url: `244951.mp4`},word: "Branch",},
  {video: {url: `58357.mp4`},word: "Report",},
  {video: {url: `89582.mp4`},word: "Corporation",},
  {video: {url: `51703.mp4`},word: "Company",},
  {video: {url: `125632.mp4`},word: "Business performance",},
  {video: {url: `153713.mp4`},word: "Shareholder",},
  {video: {url: `51691.mp4`},word: "Business document",},
  {video: {url: `96993.mp4`},word: "Agenda",},
  {video: {url: `94281.mp4`},word: "Negotiation",},
  {video: {url: `94115.mp4`},word: "Financing",},
  {video: {url: `89580.mp4`},word: "Business trip",},
  {video: {url: `153121.mp4`},word: "Board of directors",},
  {video: {url: `244256.mp4`},word: "Competitive advantage",},
  {video: {url: `63544.mp4`},word: "Leadership",},
  {video: {url: `154655.mp4`},word: "General agreement",},
  {video: {url: `116423.mp4`},word: "Prospector",},
  {video: {url: `125634.mp4`},word: "Businessman",},
  {video: {url: `125635.mp4`},word: "Businesswoman",},
  {video: {url: `65870.mp4`},word: "Stage of development",},
  {video: {url: `96347.mp4`},word: "Expansion",},
  {video: {url: `154401.mp4`},word: "Chief operating officer",},
  {video: {url: `154394.mp4`},word: "Chairman",},
  {video: {url: `89957.mp4`},word: "Strategy",},
  {video: {url: `154676.mp4`},word: "Head office",},
  {video: {url: `246023.mp4`},word: "Managing director",},
  {video: {url: `90152.mp4`},word: "Upper management",},
  {video: {url: `155549.mp4`},word: "Risk management",},
  {video: {url: `96610.mp4`},word: "Industry",},
  {video: {url: `51681.mp4`},word: "Bargaining",},
  {video: {url: `122597.mp4`},word: "Assent",},
  {video: {url: `92634.mp4`},word: "Consultation",},
  {video: {url: `244314.mp4`},word: "Crisis management",},
  {video: {url: `153236.mp4`},word: "Business card",},
  {video: {url: `153237.mp4`},word: "Business concept",},
  {video: {url: `48551.mp4`},word: "Leader",},
  {video: {url: `51696.mp4`},word: "Business plan",},
  {video: {url: `63627.mp4`},word: "Process",},
  {video: {url: `51695.mp4`},word: "Financial report",},
  {video: {url: `94296.mp4`},word: "Participation in decision-making",},
  {video: {url: `51702.mp4`},word: "Committee",},
  {video: {url: `51890.mp4`},word: "Stock",},
  {video: {url: `153321.mp4`},word: "For sale",},
  {video: {url: `93046.mp4`},word: "For free",},
  {video: {url: `244216.mp4`},word: "Commercial",},
  {video: {url: `153112.mp4`},word: "Beneficial",},
  {video: {url: `92707.mp4`},word: "Non-refundable",},
  {video: {url: `157895.mp4`},word: "Out of stock",},
  {video: {url: `157874.mp4`},word: "Off season",},
  {video: {url: `94120.mp4`},word: "Free of charge",},
  {video: {url: `116946.mp4`},word: "Sold",},
  {video: {url: `51306.mp4`},word: "Attract",},
  {video: {url: `158013.mp4`},word: "Recall",},
  {video: {url: `116414.mp4`},word: "Produce",},
  {video: {url: `117099.mp4`},word: "Supply",},
  {video: {url: `91819.mp4`},word: "Promote",},
  {video: {url: `125647.mp4`},word: "Buy, to",},
  {video: {url: `96108.mp4`},word: "Consume",},
  {video: {url: `244837.mp4`},word: "Redeem",},
  {video: {url: `155633.mp4`},word: "Stock up",},
  {video: {url: `245417.mp4`},word: "Reissue",},
  {video: {url: `125932.mp4`},word: "Charge",},
  {video: {url: `158018.mp4`},word: "Recommend price",},
  {video: {url: `117076.mp4`},word: "Subscribe",},
  {video: {url: `49179.mp4`},word: "Pay, to",},
  {video: {url: `246084.mp4`},word: "Launch",},
  {video: {url: `116259.mp4`},word: "Peddle",},
  {video: {url: `58432.mp4`},word: "Sell",},
  {video: {url: `157971.mp4`},word: "Pricing",},
  {video: {url: `157969.mp4`},word: "Price reduction",},
  {video: {url: `118331.mp4`},word: "Actual value",},
  {video: {url: `51766.mp4`},word: "Quotas",},
  {video: {url: `245932.mp4`},word: "Net price",},
  {video: {url: `154554.mp4`},word: "Display stand",},
  {video: {url: `244363.mp4`},word: "Direct selling",},
  {video: {url: `244364.mp4`},word: "Direct mail",},
  {video: {url: `90167.mp4`},word: "Vending machine",},
  {video: {url: `90168.mp4`},word: "Vendor",},
  {video: {url: `155634.mp4`},word: "Store",},
  {video: {url: `154776.mp4`},word: "Manufacturing industry",},
  {video: {url: `59553.mp4`},word: "Price",},
  {video: {url: `157980.mp4`},word: "Production department",},
  {video: {url: `245754.mp4`},word: "Production manager",},
  {video: {url: `245753.mp4`},word: "Production schedule",},
  {video: {url: `91721.mp4`},word: "Client",},
  {video: {url: `59576.mp4`},word: "Self-service",},
  {video: {url: `51682.mp4`},word: "Bid",},
  {video: {url: `154538.mp4`},word: "Dealer",},
  {video: {url: `51750.mp4`},word: "Promotion",},
  {video: {url: `92709.mp4`},word: "Offering",},
  {video: {url: `90071.mp4`},word: "Ticket purchase",},
  {video: {url: `90072.mp4`},word: "Ticket window",},
  {video: {url: `116782.mp4`},word: "Sale",},
  {video: {url: `116784.mp4`},word: "Salesclerk",},
  {video: {url: `94036.mp4`},word: "Storage room",},
  {video: {url: `51692.mp4`},word: "Fee",},
  {video: {url: `155616.mp4`},word: "Special position",},
  {video: {url: `116415.mp4`},word: "Production sheet",},
  {video: {url: `116413.mp4`},word: "Produce vendor",},
  {video: {url: `155590.mp4`},word: "Shipping cost",},
  {video: {url: `154777.mp4`},word: "Manufacturing process",},
  {video: {url: `154778.mp4`},word: "Manufacturing system",},
  {video: {url: `154774.mp4`},word: "Manufacturing cost",},
  {video: {url: `154775.mp4`},word: "Manufacturing data",},
  {video: {url: `154779.mp4`},word: "Manufacturing task",},
  {video: {url: `154519.mp4`},word: "Cover price",},
  {video: {url: `58407.mp4`},word: "Payment",},
  {video: {url: `69482.mp4`},word: "Consumer",},
  {video: {url: `153207.mp4`},word: "Bookshop",},
  {video: {url: `245091.mp4`},word: "Transaction",},
  {video: {url: `155718.mp4`},word: "Wholesale trade",},
  {video: {url: `245102.mp4`},word: "Trade fair",},
  {video: {url: `155689.mp4`},word: "Trade discount",},
  {video: {url: `153211.mp4`},word: "Break even point",},
  {video: {url: `154436.mp4`},word: "Corporate video",},
  {video: {url: `155574.mp4`},word: "Selling point",},
  {video: {url: `246010.mp4`},word: "Mass production",},
  {video: {url: `154611.mp4`},word: "First-time buyer",},
  {video: {url: `153736.mp4`},word: "Subscription",},
  {video: {url: `92647.mp4`},word: "Cycle store",},
  {video: {url: `154728.mp4`},word: "Label",},
  {video: {url: `154635.mp4`},word: "Foreign sales",},
  {video: {url: `154769.mp4`},word: "Mail advertising",},
  {video: {url: `155533.mp4`},word: "Retail",},
  {video: {url: `245350.mp4`},word: "Sales revenue",},
  {video: {url: `245773.mp4`},word: "Price cut",},
  {video: {url: `155559.mp4`},word: "Sample material",},
  {video: {url: `155573.mp4`},word: "Selling",},
  {video: {url: `157966.mp4`},word: "Price increase",},
  {video: {url: `51759.mp4`},word: "Total",},
  {video: {url: `51762.mp4`},word: "Vat (value-added tax)",},
  {video: {url: `96934.mp4`},word: "Manufacture",},
  {video: {url: `153202.mp4`},word: "Book store",},
  {video: {url: `153204.mp4`},word: "Book trade",},
  {video: {url: `51669.mp4`},word: "Customer base",},
  {video: {url: `74962.mp4`},word: "Customer",},
  {video: {url: `157886.mp4`},word: "Order form",},
  {video: {url: `154754.mp4`},word: "List price",},
  {video: {url: `73301.mp4`},word: "Farmer's market",},
  {video: {url: `157897.mp4`},word: "Overstock",},
  {video: {url: `96073.mp4`},word: "Commerce",},
  {video: {url: `58494.mp4`},word: "Cost",},
  {video: {url: `51870.mp4`},word: "Discount",},
  {video: {url: `51763.mp4`},word: "Promotional materials",},
  {video: {url: `51834.mp4`},word: "Sales target",},
  {video: {url: `245351.mp4`},word: "Sales rep",},
  {video: {url: `245352.mp4`},word: "Sales promotion",},
  {video: {url: `93137.mp4`},word: "Hire",},
  {video: {url: `50805.mp4`},word: "Shop",},
  {video: {url: `157997.mp4`},word: "Quality control",},
  {video: {url: `188144.mp4`},word: "Mail order store",},
  {video: {url: `157841.mp4`},word: "Merchandising",},
  {video: {url: `154783.mp4`},word: "Market strategy",},
  {video: {url: `154786.mp4`},word: "Marketing plan",},
  {video: {url: `154784.mp4`},word: "Market survey",},
  {video: {url: `244596.mp4`},word: "Gross profit",},
  {video: {url: `92704.mp4`},word: "Market",},
  {video: {url: `69423.mp4`},word: "Peak value",},
  {video: {url: `155712.mp4`},word: "Warehouse",},
  {video: {url: `92674.mp4`},word: "Foreign trade zone",},
  {video: {url: `51708.mp4`},word: "Goods",},
  {video: {url: `155589.mp4`},word: "Ship date",},
  {video: {url: `122836.mp4`},word: "Auction",},
  {video: {url: `245755.mp4`},word: "Production controller",},
  {video: {url: `153113.mp4`},word: "Bidding",},
  {video: {url: `89741.mp4`},word: "Sample",},
  {video: {url: `154773.mp4`},word: "Manufacturing",},
  {video: {url: `154576.mp4`},word: "End user",},
  {video: {url: `154782.mp4`},word: "Market investigation",},
  {video: {url: `155535.mp4`},word: "Revenue",},
  {video: {url: `154752.mp4`},word: "Limited edition",},
  {video: {url: `89597.mp4`},word: "Productivity",},
  {video: {url: `51729.mp4`},word: "Order",},
  {video: {url: `89604.mp4`},word: "Supplier",},
  {video: {url: `158014.mp4`},word: "Receipt",},
  {video: {url: `158029.mp4`},word: "Release date",},
  {video: {url: `89602.mp4`},word: "Salesperson",},
  {video: {url: `155662.mp4`},word: "Target audience",},
  {video: {url: `51912.mp4`},word: "Exchange office",},
  {video: {url: `153206.mp4`},word: "Bookseller's discount",},
  {video: {url: `244405.mp4`},word: "Drop in sales",},
  {video: {url: `244526.mp4`},word: "Fishing area",},
  {video: {url: `89592.mp4`},word: "Market share",},
  {video: {url: `116857.mp4`},word: "Shopkeeper",},
  {video: {url: `51717.mp4`},word: "Marketing",},
  {video: {url: `155534.mp4`},word: "Retail price",},
  {video: {url: `51711.mp4`},word: "Import",},
  {video: {url: `51690.mp4`},word: "Export",},
  {video: {url: `155685.mp4`},word: "Total percentage",},
  {video: {url: `158035.mp4`},word: "Renewal",},
  {video: {url: `157908.mp4`},word: "Peak sales",},
  {video: {url: `51867.mp4`},word: "Jobless",},
  {video: {url: `91830.mp4`},word: "Unemployed",},
  {video: {url: `93942.mp4`},word: "Dismissed",},
  {video: {url: `58305.mp4`},word: "Find a job",},
  {video: {url: `116533.mp4`},word: "Resign",},
  {video: {url: `96243.mp4`},word: "Dismiss",},
  {video: {url: `51361.mp4`},word: "Employ",},
  {video: {url: `244428.mp4`},word: "Employment office",},
  {video: {url: `153265.mp4`},word: "Cv",},
  {video: {url: `245072.mp4`},word: "Unemployment",},
  {video: {url: `58259.mp4`},word: "Ability to work",},
  {video: {url: `154717.mp4`},word: "Job application",},
  {video: {url: `158020.mp4`},word: "Recruitment",},
  {video: {url: `94069.mp4`},word: "Adult education initiative",},
  {video: {url: `58318.mp4`},word: "Interview",},
  {video: {url: `245051.mp4`},word: "Vacancy",},
  {video: {url: `94270.mp4`},word: "List of qualifications",},
  {video: {url: `245169.mp4`},word: "Supported employment",},
  {video: {url: `96307.mp4`},word: "Eligibility",},
  {video: {url: `244042.mp4`},word: "Application date",},
  {video: {url: `116540.mp4`},word: "Resume",},
  {video: {url: `189480.mp4`},word: "Sheltered workshop",},
  {video: {url: `94085.mp4`},word: "Certificate of service",},
  {video: {url: `153761.mp4`},word: "Unemployment benefit fund",},
  {video: {url: `185308.mp4`},word: "Unemployment benefit",},
  {video: {url: `58319.mp4`},word: "Job centre",},
  {video: {url: `94154.mp4`},word: "Professional experience",},
  {video: {url: `244992.mp4`},word: "White-collar",},
  {video: {url: `96080.mp4`},word: "Competent",},
  {video: {url: `157982.mp4`},word: "Professional",},
  {video: {url: `155568.mp4`},word: "Self-employed",},
  {video: {url: `51499.mp4`},word: "Vocational",},
  {video: {url: `51902.mp4`},word: "Employed",},
  {video: {url: `65874.mp4`},word: "Disobedient",},
  {video: {url: `51155.mp4`},word: "Hard-working",},
  {video: {url: `94176.mp4`},word: "Vocationally oriented",},
  {video: {url: `50930.mp4`},word: "Volunteer",},
  {video: {url: `51361.mp4`},word: "Employ",},
  {video: {url: `96189.mp4`},word: "Demote",},
  {video: {url: `93026.mp4`},word: "Fit",},
  {video: {url: `49009.mp4`},word: "Obey",},
  {video: {url: `116533.mp4`},word: "Resign",},
  {video: {url: `96198.mp4`},word: "Designate",},
  {video: {url: `116525.mp4`},word: "Report",},
  {video: {url: `125716.mp4`},word: "Calling in sick",},
  {video: {url: `93284.mp4`},word: "Degrade",},
  {video: {url: `51645.mp4`},word: "Supervise",},
  {video: {url: `96526.mp4`},word: "Haul",},
  {video: {url: `96119.mp4`},word: "Cooperate",},
  {video: {url: `94058.mp4`},word: "Work",},
  {video: {url: `116542.mp4`},word: "Retire",},
  {video: {url: `155674.mp4`},word: "Terminate",},
  {video: {url: `96078.mp4`},word: "Commute",},
  {video: {url: `96967.mp4`},word: "Toil",},
  {video: {url: `245061.mp4`},word: "Unpaid work",},
  {video: {url: `245062.mp4`},word: "Unpaid labour",},
  {video: {url: `154537.mp4`},word: "Deadline",},
  {video: {url: `94375.mp4`},word: "Trial period",},
  {video: {url: `245325.mp4`},word: "Self-employment",},
  {video: {url: `91358.mp4`},word: "Ambition",},
  {video: {url: `244778.mp4`},word: "Universalism",},
  {video: {url: `245761.mp4`},word: "Probationer",},
  {video: {url: `185005.mp4`},word: "Competence",},
  {video: {url: `51644.mp4`},word: "Success",},
  {video: {url: `94384.mp4`},word: "Vocational training",},
  {video: {url: `96145.mp4`},word: "Crew",},
  {video: {url: `96065.mp4`},word: "Colleague",},
  {video: {url: `189473.mp4`},word: "Self-employed person",},
  {video: {url: `189435.mp4`},word: "Pension age",},
  {video: {url: `116543.mp4`},word: "Retirement",},
  {video: {url: `245770.mp4`},word: "Private practice",},
  {video: {url: `63553.mp4`},word: "Office",},
  {video: {url: `245026.mp4`},word: "Wage earner",},
  {video: {url: `49155.mp4`},word: "Obligation",},
  {video: {url: `94083.mp4`},word: "Central administration",},
  {video: {url: `51387.mp4`},word: "Career",},
  {video: {url: `244783.mp4`},word: "Trial",},
  {video: {url: `93303.mp4`},word: "Directions",},
  {video: {url: `245355.mp4`},word: "Safety measure",},
  {video: {url: `244161.mp4`},word: "Career woman",},
  {video: {url: `244163.mp4`},word: "Career opportunity",},
  {video: {url: `116896.mp4`},word: "Skill",},
  {video: {url: `154646.mp4`},word: "Freelance",},
  {video: {url: `94179.mp4`},word: "Work plan",},
  {video: {url: `118164.mp4`},word: "Ability",},
  {video: {url: `157981.mp4`},word: "Professional",},
  {video: {url: `94085.mp4`},word: "Certificate of service",},
  {video: {url: `154523.mp4`},word: "Credibility",},
  {video: {url: `154679.mp4`},word: "Health and safety",},
  {video: {url: `94154.mp4`},word: "Professional experience",},
  {video: {url: `245099.mp4`},word: "Training centre",},
  {video: {url: `244524.mp4`},word: "Flexible working hours",},
  {video: {url: `244523.mp4`},word: "Flextime",},
  {video: {url: `51420.mp4`},word: "Certificate",},
  {video: {url: `94092.mp4`},word: "Continuing education course",},
  {video: {url: `189379.mp4`},word: "Labor exchange",},
  {video: {url: `189380.mp4`},word: "Labor market",},
  {video: {url: `94155.mp4`},word: "Proficiency",},
  {video: {url: `244431.mp4`},word: "Employment contract",},
  {video: {url: `96074.mp4`},word: "Commission",},
  {video: {url: `157959.mp4`},word: "Practitioner",},
  {video: {url: `51829.mp4`},word: "Personnel",},
  {video: {url: `116408.mp4`},word: "Pro",},
  {video: {url: `58359.mp4`},word: "Responsibility",},
  {video: {url: `93075.mp4`},word: "Gang",},
  {video: {url: `96232.mp4`},word: "Directive",},
  {video: {url: `96307.mp4`},word: "Eligibility",},
  {video: {url: `91786.mp4`},word: "Hours of work",},
  {video: {url: `185218.mp4`},word: "Occupation",},
  {video: {url: `91789.mp4`},word: "Illegal work",},
  {video: {url: `94359.mp4`},word: "Supervised placement",},
  {video: {url: `94185.mp4`},word: "Action program",},
  {video: {url: `155578.mp4`},word: "Senior staff",},
  {video: {url: `93241.mp4`},word: "Command",},
  {video: {url: `155626.mp4`},word: "Staff room",},
  {video: {url: `116497.mp4`},word: "Recruiter",},
  {video: {url: `116496.mp4`},word: "Recruit",},
  {video: {url: `51362.mp4`},word: "Employee",},
  {video: {url: `65939.mp4`},word: "Achievement",},
  {video: {url: `90081.mp4`},word: "Time sheet",},
  {video: {url: `189040.mp4`},word: "Collective bargaining",},
  {video: {url: `245031.mp4`},word: "Volunteer",},
  {video: {url: `185220.mp4`},word: "Pension",},
  {video: {url: `89871.mp4`},word: "Office manager",},
  {video: {url: `116217.mp4`},word: "Paraprofessional",},
  {video: {url: `92802.mp4`},word: "Worker",},
  {video: {url: `155570.mp4`},word: "Self-initiated project",},
  {video: {url: `155727.mp4`},word: "Work schendule",},
  {video: {url: `244975.mp4`},word: "Working experience",},
  {video: {url: `94202.mp4`},word: "Certification",},
  {video: {url: `153675.mp4`},word: "Practical training",},
  {video: {url: `244980.mp4`},word: "Work stress",},
  {video: {url: `244977.mp4`},word: "Workaholism",},
  {video: {url: `244978.mp4`},word: "Workaholic",},
  {video: {url: `94254.mp4`},word: "Initial vocational training",},
  {video: {url: `89594.mp4`},word: "Place of work",},
  {video: {url: `92746.mp4`},word: "Specialty",},
  {video: {url: `153744.mp4`},word: "Team associate",},
  {video: {url: `91807.mp4`},word: "Low-paid employment",},
  {video: {url: `189431.mp4`},word: "Paid parental leave",},
  {video: {url: `125013.mp4`},word: "Boss",},
  {video: {url: `94178.mp4`},word: "Work placement training",},
  {video: {url: `94353.mp4`},word: "Staff",},
  {video: {url: `94360.mp4`},word: "Supervisor",},
  {video: {url: `245688.mp4`},word: "Reduced capacity of work",},
  {video: {url: `154401.mp4`},word: "Chief operating officer",},
  {video: {url: `63540.mp4`},word: "Holiday",},
  {video: {url: `94180.mp4`},word: "Working life",},
  {video: {url: `87670.mp4`},word: "Technical staff",},
  {video: {url: `89958.mp4`},word: "Strike",},
  {video: {url: `154670.mp4`},word: "Group work",},
  {video: {url: `158012.mp4`},word: "Real-world environment",},
  {video: {url: `94184.mp4`},word: "Acknowledgement",},
  {video: {url: `51649.mp4`},word: "Teamwork",},
  {video: {url: `185303.mp4`},word: "Team role",},
  {video: {url: `185295.mp4`},word: "Supervision",},
  {video: {url: `244773.mp4`},word: "Work",},
  {video: {url: `92636.mp4`},word: "Cooperation",},
  {video: {url: `91810.mp4`},word: "Maternity leave",},
  {video: {url: `89903.mp4`},word: "Project",},
  {video: {url: `245386.mp4`},word: "Revision",},
  {video: {url: `94270.mp4`},word: "List of qualifications",},
  {video: {url: `244194.mp4`},word: "Child labour",},
  {video: {url: `155691.mp4`},word: "Transferable skills",},
  {video: {url: `91755.mp4`},word: "Employment",},
  {video: {url: `125527.mp4`},word: "Break",},
  {video: {url: `65899.mp4`},word: "Pensionist",},
  {video: {url: `185314.mp4`},word: "Work therapy",},
  {video: {url: `185313.mp4`},word: "Vocational rehabilitation",},
  {video: {url: `245330.mp4`},word: "Seasonal worker",},
  {video: {url: `89584.mp4`},word: "Draft project",},
  {video: {url: `89989.mp4`},word: "Task",},
  {video: {url: `63563.mp4`},word: "Poland",},
  {video: {url: `62795.mp4`},word: "Romania",},
  {video: {url: `118323.mp4`},word: "Active",},
  {video: {url: `122815.mp4`},word: "Athletic",},
  {video: {url: `87650.mp4`},word: "Step",},
  {video: {url: `87643.mp4`},word: "Sprint",},
  {video: {url: `51820.mp4`},word: "Jumping",},
  {video: {url: `50935.mp4`},word: "Walk, to",},
  {video: {url: `50771.mp4`},word: "Run, to",},
  {video: {url: `96629.mp4`},word: "Interval",},
  {video: {url: `97054.mp4`},word: "Jogging",},
  {video: {url: `69323.mp4`},word: "Action",},
  {video: {url: `91856.mp4`},word: "Push-up",},
  {video: {url: `117097.mp4`},word: "Superman",},
  {video: {url: `93145.mp4`},word: "Hop",},
  {video: {url: `87684.mp4`},word: "Training",},
  {video: {url: `244620.mp4`},word: "Hiking",},
  {video: {url: `117064.mp4`},word: "Strength",},
  {video: {url: `117117.mp4`},word: "Sweat",},
  {video: {url: `93187.mp4`},word: "Aerobics",},
  {video: {url: `51630.mp4`},word: "Running",},
  {video: {url: `92970.mp4`},word: "Exercise",},
  {video: {url: `87652.mp4`},word: "Stretching",},
  {video: {url: `51819.mp4`},word: "Jump-rope",},
  {video: {url: `153315.mp4`},word: "Fitness",},
  {video: {url: `96175.mp4`},word: "Dash",},
  {video: {url: `62901.mp4`},word: "Walking",},
  {video: {url: `116292.mp4`},word: "Physical fitness",},
  {video: {url: `116440.mp4`},word: "Pulse",},
  {video: {url: `116439.mp4`},word: "Pulse check",},
  {video: {url: `117048.mp4`},word: "Stopwatch",},
  {video: {url: `91866.mp4`},word: "Yoga",},
  {video: {url: `116499.mp4`},word: "Rectally",},
  {video: {url: `116424.mp4`},word: "Prosthetics",},
  {video: {url: `91736.mp4`},word: "Cure",},
  {video: {url: `155632.mp4`},word: "Stitch",},
  {video: {url: `96617.mp4`},word: "Inject",},
  {video: {url: `93070.mp4`},word: "Gain weight",},
  {video: {url: `96217.mp4`},word: "Diagnose",},
  {video: {url: `91809.mp4`},word: "Make an appointment",},
  {video: {url: `116393.mp4`},word: "Prescribe",},
  {video: {url: `91695.mp4`},word: "Amputate",},
  {video: {url: `96586.mp4`},word: "Hypnotize",},
  {video: {url: `116512.mp4`},word: "Rehabilitate",},
  {video: {url: `51807.mp4`},word: "Evaluate",},
  {video: {url: `96929.mp4`},word: "Quarantine",},
  {video: {url: `96531.mp4`},word: "Heal",},
  {video: {url: `96193.mp4`},word: "Deplete",},
  {video: {url: `91750.mp4`},word: "Early intervention",},
  {video: {url: `244947.mp4`},word: "Circumcision",},
  {video: {url: `91715.mp4`},word: "Certificate of vaccination",},
  {video: {url: `91800.mp4`},word: "Investigation",},
  {video: {url: `189193.mp4`},word: "Euthanasia",},
  {video: {url: `91784.mp4`},word: "Home nursing",},
  {video: {url: `96584.mp4`},word: "Hypnosis",},
  {video: {url: `91718.mp4`},word: "Chemotherapy",},
  {video: {url: `62510.mp4`},word: "Autopsy",},
  {video: {url: `89879.mp4`},word: "Operating room",},
  {video: {url: `58264.mp4`},word: "Care",},
  {video: {url: `58325.mp4`},word: "Prevention",},
  {video: {url: `58380.mp4`},word: "Surgery",},
  {video: {url: `91717.mp4`},word: "Checkup",},
  {video: {url: `189099.mp4`},word: "Disinfection",},
  {video: {url: `116498.mp4`},word: "Rectal exam",},
  {video: {url: `185263.mp4`},word: "Self-care",},
  {video: {url: `245220.mp4`},word: "Speech and language therapy",},
  {video: {url: `185100.mp4`},word: "Injection",},
  {video: {url: `58302.mp4`},word: "Evaluation",},
  {video: {url: `97084.mp4`},word: "Diagnosis",},
  {video: {url: `116291.mp4`},word: "Physical examination",},
  {video: {url: `117039.mp4`},word: "Stitches",},
  {video: {url: `96211.mp4`},word: "Detoxication",},
  {video: {url: `51786.mp4`},word: "Massage",},
  {video: {url: `244644.mp4`},word: "Home nursing care",},
  {video: {url: `244368.mp4`},word: "Diet therapy",},
  {video: {url: `245818.mp4`},word: "Pet-assisted therapy",},
  {video: {url: `94102.mp4`},word: "Diagnostic test",},
  {video: {url: `185088.mp4`},word: "Hospitalization",},
  {video: {url: `189386.mp4`},word: "Liniment",},
  {video: {url: `116462.mp4`},word: "Radiotherapy",},
  {video: {url: `246001.mp4`},word: "Medical rehabilitation",},
  {video: {url: `189373.mp4`},word: "Irradiation",},
  {video: {url: `58312.mp4`},word: "Health protection",},
  {video: {url: `245881.mp4`},word: "Organ transplantation",},
  {video: {url: `244336.mp4`},word: "Detoxification",},
  {video: {url: `91742.mp4`},word: "Dieting",},
  {video: {url: `110155.mp4`},word: "Immunization shot",},
  {video: {url: `189281.mp4`},word: "Heimlich maneuver",},
  {video: {url: `189429.mp4`},word: "Out-patient care",},
  {video: {url: `246116.mp4`},word: "Instructions to get healthier",},
  {video: {url: `246002.mp4`},word: "Medical consultation",},
  {video: {url: `91709.mp4`},word: "Blood test",},
  {video: {url: `189497.mp4`},word: "Urinalysis",},
  {video: {url: `245812.mp4`},word: "Physiotherapy",},
  {video: {url: `116293.mp4`},word: "Physical therapy",},
  {video: {url: `91746.mp4`},word: "Doctor's round",},
  {video: {url: `189444.mp4`},word: "Plaster cast",},
  {video: {url: `96257.mp4`},word: "Doctor house call",},
  {video: {url: `97092.mp4`},word: "Nursing",},
  {video: {url: `74970.mp4`},word: "Diet",},
  {video: {url: `189441.mp4`},word: "Personal assistance",},
  {video: {url: `91678.mp4`},word: "Acupuncture",},
  {video: {url: `51815.mp4`},word: "First aid",},
  {video: {url: `93255.mp4`},word: "Cosmetic surgery",},
  {video: {url: `189657.mp4`},word: "Maxillofacial surgery",},
  {video: {url: `185313.mp4`},word: "Vocational rehabilitation",},
  {video: {url: `185306.mp4`},word: "Treatment",},
  {video: {url: `94220.mp4`},word: "Early detection",},
  {video: {url: `116461.mp4`},word: "Radiology",},
  {video: {url: `73382.mp4`},word: "Sprout",},
  {video: {url: `186314.mp4`},word: "Capsule",},
  {video: {url: `188482.mp4`},word: "Vascular bundle",},
  {video: {url: `244470.mp4`},word: "Exotic plant",},
  {video: {url: `187679.mp4`},word: "Cotyledon",},
  {video: {url: `246068.mp4`},word: "Light reaction",},
  {video: {url: `188469.mp4`},word: "Trefoil",},
  {video: {url: `186329.mp4`},word: "Chlorophyll",},
  {video: {url: `188227.mp4`},word: "Pollination",},
  {video: {url: `96255.mp4`},word: "Dock",},
  {video: {url: `73345.mp4`},word: "Offshoot",},
  {video: {url: `124956.mp4`},word: "Bloom",},
  {video: {url: `187854.mp4`},word: "Footstalk",},
  {video: {url: `116324.mp4`},word: "Plant cell",},
  {video: {url: `89664.mp4`},word: "Weed",},
  {video: {url: `89620.mp4`},word: "Bud",},
  {video: {url: `187313.mp4`},word: "Photosynthesis",},
  {video: {url: `188057.mp4`},word: "Lamina",},
  {video: {url: `73353.mp4`},word: "Poisonous mushroom",},
  {video: {url: `187343.mp4`},word: "Resin",},
  {video: {url: `187159.mp4`},word: "Foliage",},
  {video: {url: `69475.mp4`},word: "Bush",},
  {video: {url: `187580.mp4`},word: "Shrubbery",},
  {video: {url: `125599.mp4`},word: "Bulb",},
  {video: {url: `125658.mp4`},word: "Cactus",},
  {video: {url: `189568.mp4`},word: "Conservatory",},
  {video: {url: `244166.mp4`},word: "Chickpea",},
  {video: {url: `89732.mp4`},word: "Pollen",},
  {video: {url: `96972.mp4`},word: "Twig",},
  {video: {url: `89730.mp4`},word: "Plant",},
  {video: {url: `89742.mp4`},word: "Seed",},
  {video: {url: `89644.mp4`},word: "Oats",},
  {video: {url: `188241.mp4`},word: "Rhizome",},
  {video: {url: `73346.mp4`},word: "Outdoor plant",},
  {video: {url: `188424.mp4`},word: "Stalk",},
  {video: {url: `51579.mp4`},word: "Grass",},
  {video: {url: `117026.mp4`},word: "Stem",},
  {video: {url: `73337.mp4`},word: "Mistletoe",},
  {video: {url: `89642.mp4`},word: "Moss",},
  {video: {url: `73369.mp4`},word: "Root",},
  {video: {url: `187618.mp4`},word: "Tuber",},
  {video: {url: `90025.mp4`},word: "Thorn",},
  {video: {url: `246000.mp4`},word: "Medicinal plants",},
  {video: {url: `96988.mp4`},word: "Vine",},
  {video: {url: `75039.mp4`},word: "Mushroom",},
  {video: {url: `73328.mp4`},word: "Indoor plant",},
  {video: {url: `187283.mp4`},word: "Oilseed rape",},
  {video: {url: `187628.mp4`},word: "Vegetation",},
  {video: {url: `118399.mp4`},word: "Algae",},
  {video: {url: `125014.mp4`},word: "Botany",},
  {video: {url: `89768.mp4`},word: "Tub",},
  {video: {url: `153607.mp4`},word: "Leaf",},
  {video: {url: `186090.mp4`},word: "Shrub",},
  {video: {url: `189037.mp4`},word: "Clinical thermometer",},
  {video: {url: `93262.mp4`},word: "Cpr",},
  {video: {url: `96292.mp4`},word: "Ear thermometer",},
  {video: {url: `91705.mp4`},word: "Bandage",},
  {video: {url: `117066.mp4`},word: "Stretcher",},
  {video: {url: `92854.mp4`},word: "Medical bag",},
  {video: {url: `96314.mp4`},word: "Emergency vehicle",},
  {video: {url: `189468.mp4`},word: "Scalpel",},
  {video: {url: `59613.mp4`},word: "Thermometer",},
  {video: {url: `91328.mp4`},word: "First aid kit",},
  {video: {url: `116536.mp4`},word: "Respirator",},
  {video: {url: `93126.mp4`},word: "Heart monitor",},
  {video: {url: `244066.mp4`},word: "Bedpan",},
  {video: {url: `244065.mp4`},word: "Bedrail",},
  {video: {url: `62567.mp4`},word: "Bed pan",},
  {video: {url: `96578.mp4`},word: "Hot water bottle",},
  {video: {url: `90087.mp4`},word: "Tissue",},
  {video: {url: `96256.mp4`},word: "Doctor bag",},
  {video: {url: `117105.mp4`},word: "Surgical table",},
  {video: {url: `91832.mp4`},word: "Wheelchair",},
  {video: {url: `92775.mp4`},word: "Mri",},
  {video: {url: `62605.mp4`},word: "Blood pressure meter",},
  {video: {url: `62604.mp4`},word: "Blood pressure cuff",},
  {video: {url: `125791.mp4`},word: "Cardiac",},
  {video: {url: `189416.mp4`},word: "Neurology",},
  {video: {url: `126003.mp4`},word: "Chiropractic",},
  {video: {url: `96197.mp4`},word: "Dermatology",},
  {video: {url: `189249.mp4`},word: "Gynaecology",},
  {video: {url: `189434.mp4`},word: "Pathology",},
  {video: {url: `92947.mp4`},word: "Neurosurgery",},
  {video: {url: `116261.mp4`},word: "Pediatric emergency",},
  {video: {url: `116262.mp4`},word: "Pediatrics",},
  {video: {url: `191339.mp4`},word: "Podiatry",},
  {video: {url: `96905.mp4`},word: "Optometry",},
  {video: {url: `116987.mp4`},word: "Sports medicine",},
  {video: {url: `125793.mp4`},word: "Cardiology",},
  {video: {url: `96254.mp4`},word: "Dizzy",},
  {video: {url: `125537.mp4`},word: "Breathless",},
  {video: {url: `116954.mp4`},word: "Sore",},
  {video: {url: `117125.mp4`},word: "Swollen",},
  {video: {url: `91787.mp4`},word: "Hungover",},
  {video: {url: `51903.mp4`},word: "Dumb",},
  {video: {url: `51843.mp4`},word: "Bedridden",},
  {video: {url: `62726.mp4`},word: "Choke",},
  {video: {url: `93081.mp4`},word: "Get worse",},
  {video: {url: `96605.mp4`},word: "Impair",},
  {video: {url: `92986.mp4`},word: "Faint",},
  {video: {url: `91708.mp4`},word: "Bleed",},
  {video: {url: `50932.mp4`},word: "Vomit",},
  {video: {url: `93256.mp4`},word: "Cough",},
  {video: {url: `94048.mp4`},word: "Tremble",},
  {video: {url: `93237.mp4`},word: "Collapse",},
  {video: {url: `91752.mp4`},word: "Elevated temperature",},
  {video: {url: `189513.mp4`},word: "Syndrome",},
  {video: {url: `90153.mp4`},word: "Upset stomach",},
  {video: {url: `62729.mp4`},word: "Choking",},
  {video: {url: `91735.mp4`},word: "Cramp",},
  {video: {url: `93337.mp4`},word: "Earache",},
  {video: {url: `91745.mp4`},word: "Dizziness",},
  {video: {url: `91779.mp4`},word: "Heartburn",},
  {video: {url: `91329.mp4`},word: "Impairment",},
  {video: {url: `62529.mp4`},word: "Back pain",},
  {video: {url: `124719.mp4`},word: "Bleeding",},
  {video: {url: `91774.mp4`},word: "Hangover",},
  {video: {url: `91707.mp4`},word: "Blackout",},
  {video: {url: `91831.mp4`},word: "Virus",},
  {video: {url: `89813.mp4`},word: "Itch",},
  {video: {url: `92962.mp4`},word: "Nosebleed",},
  {video: {url: `92937.mp4`},word: "Nausea",},
  {video: {url: `91741.mp4`},word: "Diarrhea",},
  {video: {url: `185298.mp4`},word: "Symptom",},
  {video: {url: `96551.mp4`},word: "Hiccup",},
  {video: {url: `116617.mp4`},word: "Runny nose",},
  {video: {url: `118168.mp4`},word: "Abscess",},
  {video: {url: `93106.mp4`},word: "Growth",},
  {video: {url: `117088.mp4`},word: "Sunburn",},
  {video: {url: `189501.mp4`},word: "Tremor",},
  {video: {url: `244401.mp4`},word: "Drug withdrawal",},
  {video: {url: `50825.mp4`},word: "Stomach ache",},
  {video: {url: `116953.mp4`},word: "Sore throat",},
  {video: {url: `118360.mp4`},word: "Afforest",},
  {video: {url: `73382.mp4`},word: "Sprout",},
  {video: {url: `246077.mp4`},word: "Lemon tree",},
  {video: {url: `89724.mp4`},word: "Palm tree",},
  {video: {url: `244916.mp4`},word: "Fig tree",},
  {video: {url: `187170.mp4`},word: "Forester",},
  {video: {url: `58459.mp4`},word: "Forest",},
  {video: {url: `189569.mp4`},word: "Coniferous forest",},
  {video: {url: `89645.mp4`},word: "Orange tree",},
  {video: {url: `244014.mp4`},word: "Almond tree",},
  {video: {url: `189570.mp4`},word: "Conifer",},
  {video: {url: `63632.mp4`},word: "Apple tree",},
  {video: {url: `187609.mp4`},word: "Taiga",},
  {video: {url: `189575.mp4`},word: "Clearing",},
  {video: {url: `69486.mp4`},word: "Crown of a tree",},
  {video: {url: `73362.mp4`},word: "Reforestation",},
  {video: {url: `153077.mp4`},word: "Aspen",},
  {video: {url: `154630.mp4`},word: "Foliage tree",},
  {video: {url: `73391.mp4`},word: "Tree",},
  {video: {url: `187172.mp4`},word: "Forestry",},
  {video: {url: `187826.mp4`},word: "Fir tree",},
  {video: {url: `124322.mp4`},word: "Birch",},
  {video: {url: `89661.mp4`},word: "Pine cone",},
  {video: {url: `89632.mp4`},word: "Fern",},
  {video: {url: `245836.mp4`},word: "Pear tree",},
  {video: {url: `187167.mp4`},word: "Forest nursery",},
  {video: {url: `189553.mp4`},word: "Deciduous forest",},
  {video: {url: `189551.mp4`},word: "Dense forest",},
  {video: {url: `73334.mp4`},word: "Maple",},
  {video: {url: `69474.mp4`},word: "Branch",},
  {video: {url: `189554.mp4`},word: "Deciduous tree",},
  {video: {url: `245147.mp4`},word: "Tangerine tree",},
  {video: {url: `154674.mp4`},word: "Hazel",},
  {video: {url: `153073.mp4`},word: "Ash",},
  {video: {url: `123632.mp4`},word: "Beech",},
  {video: {url: `188468.mp4`},word: "Treetop",},
  {video: {url: `187056.mp4`},word: "Evergreen tree",},
  {video: {url: `245895.mp4`},word: "Olive tree",},
  {video: {url: `89616.mp4`},word: "Bark",},
  {video: {url: `189565.mp4`},word: "Coppicing",},
  {video: {url: `89654.mp4`},word: "Tropical rainforest",},
  {video: {url: `246041.mp4`},word: "Logging",},
  {video: {url: `89666.mp4`},word: "Willow",},
  {video: {url: `89648.mp4`},word: "Pine tree",},
  {video: {url: `187210.mp4`},word: "Growth ring",},
  {video: {url: `89655.mp4`},word: "Trunk",},
  {video: {url: `96440.mp4`},word: "Grove",},
  {video: {url: `157940.mp4`},word: "Plum-tree",},
  {video: {url: `157910.mp4`},word: "Pear-tree",},
  {video: {url: `154649.mp4`},word: "Fruit tree",},
  {video: {url: `245837.mp4`},word: "Peach tree",},
  {video: {url: `245801.mp4`},word: "Plum tree",},
  {video: {url: `69467.mp4`},word: "Acorn",},
  {video: {url: `94396.mp4`},word: "Nuclear",},
  {video: {url: `116460.mp4`},word: "Radioactive",},
  {video: {url: `245120.mp4`},word: "Thermal",},
  {video: {url: `93990.mp4`},word: "Physical",},
  {video: {url: `92985.mp4`},word: "Fahrenheit",},
  {video: {url: `157936.mp4`},word: "Plastic",},
  {video: {url: `96303.mp4`},word: "Elastic",},
  {video: {url: `96354.mp4`},word: "Extinct",},
  {video: {url: `59551.mp4`},word: "Press, to",},
  {video: {url: `72882.mp4`},word: "Dismantle",},
  {video: {url: `90170.mp4`},word: "Vibrate",},
  {video: {url: `96248.mp4`},word: "Dissolve",},
  {video: {url: `75032.mp4`},word: "Melt",},
  {video: {url: `92971.mp4`},word: "Expand",},
  {video: {url: `58609.mp4`},word: "Evaporate",},
  {video: {url: `154647.mp4`},word: "Freeze",},
  {video: {url: `96325.mp4`},word: "Essence",},
  {video: {url: `244095.mp4`},word: "Boiling point",},
  {video: {url: `243987.mp4`},word: "Absorbed dose",},
  {video: {url: `245826.mp4`},word: "Period",},
  {video: {url: `58458.mp4`},word: "Force",},
  {video: {url: `245184.mp4`},word: "Stress",},
  {video: {url: `154588.mp4`},word: "Evaporation",},
  {video: {url: `69417.mp4`},word: "Momentary value",},
  {video: {url: `245297.mp4`},word: "Shock wave",},
  {video: {url: `189720.mp4`},word: "Momentum",},
  {video: {url: `245713.mp4`},word: "Radio waves",},
  {video: {url: `245216.mp4`},word: "Spin",},
  {video: {url: `153247.mp4`},word: "Celsius scale",},
  {video: {url: `155696.mp4`},word: "Twisting",},
  {video: {url: `94397.mp4`},word: "Nuclear power station",},
  {video: {url: `154683.mp4`},word: "Heat transfer",},
  {video: {url: `65940.mp4`},word: "Active resistance",},
  {video: {url: `124699.mp4`},word: "Black body",},
  {video: {url: `244058.mp4`},word: "Average speed",},
  {video: {url: `92847.mp4`},word: "Mass",},
  {video: {url: `69422.mp4`},word: "Passive sensor",},
  {video: {url: `186076.mp4`},word: "Radiation",},
  {video: {url: `118383.mp4`},word: "Air pressure",},
  {video: {url: `187511.mp4`},word: "Electron",},
  {video: {url: `245204.mp4`},word: "Statics",},
  {video: {url: `117077.mp4`},word: "Substance",},
  {video: {url: `94394.mp4`},word: "Heat",},
  {video: {url: `186145.mp4`},word: "Triode",},
  {video: {url: `244623.mp4`},word: "Heat capacity",},
  {video: {url: `154556.mp4`},word: "Dissolution",},
  {video: {url: `245011.mp4`},word: "Weight",},
  {video: {url: `189373.mp4`},word: "Irradiation",},
  {video: {url: `96367.mp4`},word: "Fiber",},
  {video: {url: `93991.mp4`},word: "Physics",},
  {video: {url: `74987.mp4`},word: "Fire",},
  {video: {url: `245814.mp4`},word: "Physical property",},
  {video: {url: `244178.mp4`},word: "Centre of mass",},
  {video: {url: `245208.mp4`},word: "Standard model",},
  {video: {url: `69414.mp4`},word: "Magnetic field",},
  {video: {url: `155622.mp4`},word: "Sphere",},
  {video: {url: `92897.mp4`},word: "Molecule",},
  {video: {url: `245997.mp4`},word: "Melting",},
  {video: {url: `246011.mp4`},word: "Mass number",},
  {video: {url: `244179.mp4`},word: "Centre of gravity",},
  {video: {url: `155715.mp4`},word: "Wavelength",},
  {video: {url: `244378.mp4`},word: "Drag",},
  {video: {url: `69459.mp4`},word: "Vibration",},
  {video: {url: `118170.mp4`},word: "Absolute zero temperature",},
  {video: {url: `245013.mp4`},word: "Weak interaction",},
  {video: {url: `96906.mp4`},word: "Orbit",},
  {video: {url: `116233.mp4`},word: "Particle",},
  {video: {url: `154648.mp4`},word: "Freezing point",},
  {video: {url: `244455.mp4`},word: "Energy",},
  {video: {url: `244063.mp4`},word: "Atomic physics",},
  {video: {url: `158026.mp4`},word: "Reflection",},
  {video: {url: `245689.mp4`},word: "Redshift",},
  {video: {url: `186123.mp4`},word: "Thermonuclear reactor",},
  {video: {url: `245183.mp4`},word: "Stress analysis",},
  {video: {url: `188339.mp4`},word: "Aseptic",},
  {video: {url: `93151.mp4`},word: "Human",},
  {video: {url: `245152.mp4`},word: "Tactile",},
  {video: {url: `155630.mp4`},word: "Sterile",},
  {video: {url: `49181.mp4`},word: "Pee",},
  {video: {url: `91761.mp4`},word: "Fart",},
  {video: {url: `96247.mp4`},word: "Dissect",},
  {video: {url: `125619.mp4`},word: "Burp",},
  {video: {url: `116602.mp4`},word: "Rot",},
  {video: {url: `154631.mp4`},word: "Food web",},
  {video: {url: `125929.mp4`},word: "Characteristic",},
  {video: {url: `125891.mp4`},word: "Cell wall",},
  {video: {url: `125889.mp4`},word: "Cell division",},
  {video: {url: `189588.mp4`},word: "Biome",},
  {video: {url: `123147.mp4`},word: "Bacterial cell",},
  {video: {url: `73297.mp4`},word: "Extinction",},
  {video: {url: `123134.mp4`},word: "Bacillus",},
  {video: {url: `244321.mp4`},word: "Dark reactions",},
  {video: {url: `92967.mp4`},word: "Nutrition",},
  {video: {url: `91762.mp4`},word: "Feces",},
  {video: {url: `184821.mp4`},word: "Population",},
  {video: {url: `89634.mp4`},word: "Germ",},
  {video: {url: `187300.mp4`},word: "Parasite",},
  {video: {url: `187607.mp4`},word: "Symbiosis",},
  {video: {url: `73347.mp4`},word: "Pest",},
  {video: {url: `244049.mp4`},word: "Bacterial toxin",},
  {video: {url: `62600.mp4`},word: "Blood",},
  {video: {url: `124319.mp4`},word: "Biology",},
  {video: {url: `117117.mp4`},word: "Sweat",},
  {video: {url: `73343.mp4`},word: "Nutrient",},
  {video: {url: `96383.mp4`},word: "Flesh",},
  {video: {url: `94017.mp4`},word: "Scent",},
  {video: {url: `246070.mp4`},word: "Life stage",},
  {video: {url: `245835.mp4`},word: "Pearl",},
  {video: {url: `49207.mp4`},word: "Poo",},
  {video: {url: `246062.mp4`},word: "Limiting factor",},
  {video: {url: `244085.mp4`},word: "Biological clock",},
  {video: {url: `189294.mp4`},word: "Hormone",},
  {video: {url: `153118.mp4`},word: "Biosphere",},
  {video: {url: `189589.mp4`},word: "Biodiversity",},
  {video: {url: `89631.mp4`},word: "Ecology",},
  {video: {url: `96910.mp4`},word: "Origin",},
  {video: {url: `93127.mp4`},word: "Heartbeat",},
  {video: {url: `245999.mp4`},word: "Melanin",},
  {video: {url: `96337.mp4`},word: "Evolution",},
  {video: {url: `187905.mp4`},word: "Gemmule",},
  {video: {url: `124320.mp4`},word: "Biom",},
  {video: {url: `157859.mp4`},word: "Natural resource",},
  {video: {url: `91683.mp4`},word: "Adrenalin",},
  {video: {url: `73318.mp4`},word: "Greenhouse effect",},
  {video: {url: `89659.mp4`},word: "Metabolism",},
  {video: {url: `187589.mp4`},word: "Species",},
  {video: {url: `157858.mp4`},word: "Natural gas",},
  {video: {url: `117033.mp4`},word: "Sterilization",},
  {video: {url: `62187.mp4`},word: "Amoeba",},
  {video: {url: `116827.mp4`},word: "Sense",},
  {video: {url: `118359.mp4`},word: "Aerobic respiration",},
  {video: {url: `73309.mp4`},word: "Food chain",},
  {video: {url: `245982.mp4`},word: "Microscopic",},
  {video: {url: `91803.mp4`},word: "Laboratory",},
  {video: {url: `91757.mp4`},word: "Experiment",},
  {video: {url: `96405.mp4`},word: "Gas mask",},
  {video: {url: `155557.mp4`},word: "Safety glasses",},
  {video: {url: `154731.mp4`},word: "Laboratory report",},
  {video: {url: `155717.mp4`},word: "Wet laboratory",},
  {video: {url: `154729.mp4`},word: "Laboratory experiment",},
  {video: {url: `154727.mp4`},word: "Lab",},
  {video: {url: `116286.mp4`},word: "Petri dish",},
  {video: {url: `123612.mp4`},word: "Beaker",},
  {video: {url: `116961.mp4`},word: "Specimen sample",},
  {video: {url: `92876.mp4`},word: "Microscope",},
  {video: {url: `116960.mp4`},word: "Specimen cup",},
  {video: {url: `116829.mp4`},word: "Serum lab",},
  {video: {url: `189104.mp4`},word: "Dropping funnel",},
  {video: {url: `50779.mp4`},word: "Science",},
  {video: {url: `89851.mp4`},word: "Magnifying glass",},
  {video: {url: `155676.mp4`},word: "Test-tube",},
  {video: {url: `154726.mp4`},word: "Lab coat",},
  {video: {url: `244612.mp4`},word: "Hand lens",},
  {video: {url: `90010.mp4`},word: "Test tube",},
  {video: {url: `90135.mp4`},word: "Tube",},
  {video: {url: `154730.mp4`},word: "Laboratory notebook",},
  {video: {url: `154626.mp4`},word: "Fluid",},
  {video: {url: `89900.mp4`},word: "Poisonous",},
  {video: {url: `96349.mp4`},word: "Explosives",},
  {video: {url: `118401.mp4`},word: "Alkaline",},
  {video: {url: `245880.mp4`},word: "Organic",},
  {video: {url: `63623.mp4`},word: "Neutral",},
  {video: {url: `89800.mp4`},word: "Burn",},
  {video: {url: `154624.mp4`},word: "Flow",},
  {video: {url: `69337.mp4`},word: "Explode",},
  {video: {url: `89558.mp4`},word: "Solvent",},
  {video: {url: `72915.mp4`},word: "Ethanol",},
  {video: {url: `185956.mp4`},word: "Anti-codon",},
  {video: {url: `185953.mp4`},word: "Alpha-helix",},
  {video: {url: `58249.mp4`},word: "Additive",},
  {video: {url: `245196.mp4`},word: "Steroid",},
  {video: {url: `109903.mp4`},word: "Half-life",},
  {video: {url: `59350.mp4`},word: "Liquid",},
  {video: {url: `244135.mp4`},word: "Carbohydrate",},
  {video: {url: `157937.mp4`},word: "Plastic",},
  {video: {url: `244168.mp4`},word: "Chemical symbol",},
  {video: {url: `244169.mp4`},word: "Chemical product",},
  {video: {url: `118390.mp4`},word: "Air",},
  {video: {url: `186035.mp4`},word: "Neutron",},
  {video: {url: `245879.mp4`},word: "Organic chemistry",},
  {video: {url: `245708.mp4`},word: "Reactivity",},
  {video: {url: `158005.mp4`},word: "Reaction",},
  {video: {url: `187495.mp4`},word: "Electrolyte",},
  {video: {url: `245829.mp4`},word: "Perfect fluid",},
  {video: {url: `153050.mp4`},word: "Air quality",},
  {video: {url: `187493.mp4`},word: "Electrolysis",},
  {video: {url: `245815.mp4`},word: "Physical chemistry",},
  {video: {url: `72538.mp4`},word: "Combustion",},
  {video: {url: `186074.mp4`},word: "Proton",},
  {video: {url: `125959.mp4`},word: "Chemistry",},
  {video: {url: `244129.mp4`},word: "Bond",},
  {video: {url: `153186.mp4`},word: "Bond energy",},
  {video: {url: `154387.mp4`},word: "Carbon dioxide",},
  {video: {url: `154386.mp4`},word: "Carbon bond",},
  {video: {url: `96404.mp4`},word: "Gas flame",},
  {video: {url: `157849.mp4`},word: "Mole",},
  {video: {url: `154397.mp4`},word: "Chemical formula",},
  {video: {url: `154395.mp4`},word: "Chemical bond",},
  {video: {url: `153037.mp4`},word: "Acid-base reaction",},
  {video: {url: `157885.mp4`},word: "Orbital energy",},
  {video: {url: `91407.mp4`},word: "Firework",},
  {video: {url: `93155.mp4`},word: "Hybrid",},
  {video: {url: `188451.mp4`},word: "Synthetic fibre",},
  {video: {url: `244062.mp4`},word: "Atomic weight",},
  {video: {url: `157916.mp4`},word: "Periodic table",},
  {video: {url: `244171.mp4`},word: "Chemical change",},
  {video: {url: `245877.mp4`},word: "Organic functional group",},
  {video: {url: `87885.mp4`},word: "Fluid",},
  {video: {url: `157921.mp4`},word: "Ph",},
  {video: {url: `157920.mp4`},word: "Ph scale",},
  {video: {url: `244504.mp4`},word: "Fermentation",},
  {video: {url: `74990.mp4`},word: "Flame",},
  {video: {url: `157848.mp4`},word: "Mole calculation",},
  {video: {url: `244480.mp4`},word: "Fatty acid",},
  {video: {url: `118433.mp4`},word: "Amino acid",},
  {video: {url: `65953.mp4`},word: "Chain reaction",},
  {video: {url: `116346.mp4`},word: "Poison",},
  {video: {url: `245782.mp4`},word: "Postive charge",},
  {video: {url: `154609.mp4`},word: "Firing",},
  {video: {url: `94387.mp4`},word: "Atom",},
  {video: {url: `154399.mp4`},word: "Chemical reaction",},
  {video: {url: `91393.mp4`},word: "Explosion",},
  {video: {url: `154398.mp4`},word: "Chemical groups",},
  {video: {url: `154396.mp4`},word: "Chemical equation",},
  {video: {url: `153081.mp4`},word: "Atomic number",},
  {video: {url: `245878.mp4`},word: "Organic compound",},
  {video: {url: `153082.mp4`},word: "Atomic structure",},
  {video: {url: `153080.mp4`},word: "Atomic mass",},
  {video: {url: `91519.mp4`},word: "Match",},
  {video: {url: `125958.mp4`},word: "Chemist",},
  {video: {url: `244170.mp4`},word: "Chemical energy",},
  {video: {url: `89509.mp4`},word: "Colourful",},
  {video: {url: `62736.mp4`},word: "Clear",},
  {video: {url: `58589.mp4`},word: "Bright",},
  {video: {url: `62867.mp4`},word: "Hand-coloured",},
  {video: {url: `154645.mp4`},word: "Freehand",},
  {video: {url: `62595.mp4`},word: "Blank",},
  {video: {url: `89555.mp4`},word: "Scribble",},
  {video: {url: `155587.mp4`},word: "Shade",},
  {video: {url: `62889.mp4`},word: "Sketch",},
  {video: {url: `65741.mp4`},word: "Hatch",},
  {video: {url: `63556.mp4`},word: "Paint, to",},
  {video: {url: `65743.mp4`},word: "Illustrate",},
  {video: {url: `89558.mp4`},word: "Solvent",},
  {video: {url: `155631.mp4`},word: "Still life",},
  {video: {url: `62888.mp4`},word: "Sketch",},
  {video: {url: `116196.mp4`},word: "Paint thinner",},
  {video: {url: `155600.mp4`},word: "Sketchbook",},
  {video: {url: `122531.mp4`},word: "Aquatint",},
  {video: {url: `245986.mp4`},word: "Metallic paints",},
  {video: {url: `89499.mp4`},word: "Airbrush",},
  {video: {url: `153038.mp4`},word: "Acrylic",},
  {video: {url: `125917.mp4`},word: "Chalk",},
  {video: {url: `245798.mp4`},word: "Point paper",},
  {video: {url: `58595.mp4`},word: "Colour contrast",},
  {video: {url: `58599.mp4`},word: "Crayon",},
  {video: {url: `157852.mp4`},word: "Mural",},
  {video: {url: `245900.mp4`},word: "Oil pastels",},
  {video: {url: `89508.mp4`},word: "Colour wheel",},
  {video: {url: `89507.mp4`},word: "Colour shade",},
  {video: {url: `89562.mp4`},word: "Stencilling",},
  {video: {url: `244174.mp4`},word: "Charcoal drawing",},
  {video: {url: `125831.mp4`},word: "Cartoon",},
  {video: {url: `89538.mp4`},word: "Palette",},
  {video: {url: `89531.mp4`},word: "Landscape",},
  {video: {url: `65742.mp4`},word: "Hatching",},
  {video: {url: `62893.mp4`},word: "Watercolour",},
  {video: {url: `63557.mp4`},word: "Painter",},
  {video: {url: `69329.mp4`},word: "Flat brush",},
  {video: {url: `125578.mp4`},word: "Brush",},
  {video: {url: `89540.mp4`},word: "Primary",},
  {video: {url: `65759.mp4`},word: "Preparatory drawing",},
  {video: {url: `65763.mp4`},word: "Roundbrush",},
  {video: {url: `94033.mp4`},word: "Spot",},
  {video: {url: `65761.mp4`},word: "Red chalk",},
  {video: {url: `89515.mp4`},word: "Freehand drawing",},
  {video: {url: `65766.mp4`},word: "Secondary",},
  {video: {url: `65754.mp4`},word: "Paintbrush",},
  {video: {url: `65755.mp4`},word: "Painting",},
  {video: {url: `62878.mp4`},word: "Paint",},
  {video: {url: `157835.mp4`},word: "Matte",},
  {video: {url: `157972.mp4`},word: "Primer",},
  {video: {url: `65753.mp4`},word: "Outline",},
  {video: {url: `116194.mp4`},word: "Paint can",},
  {video: {url: `89521.mp4`},word: "Graffiti",},
  {video: {url: `244218.mp4`},word: "Colouring",},
  {video: {url: `58591.mp4`},word: "Charcoal",},
  {video: {url: `245362.mp4`},word: "Round brush",},
  {video: {url: `244219.mp4`},word: "Coloured pencil",},
  {video: {url: `125764.mp4`},word: "Canvas",},
  {video: {url: `58607.mp4`},word: "Drawing",},
  {video: {url: `155714.mp4`},word: "Water-colour",},
  {video: {url: `58608.mp4`},word: "Easel",},
  {video: {url: `246014.mp4`},word: "Masking tape",},
  {video: {url: `89526.mp4`},word: "Impasto technique",},
  {video: {url: `62877.mp4`},word: "Oil paint",},
  {video: {url: `62876.mp4`},word: "Oil on canvas",},
  {video: {url: `116992.mp4`},word: "Spray can",},
  {video: {url: `116993.mp4`},word: "Spray gun",},
  {video: {url: `116197.mp4`},word: "Paint tray",},
  {video: {url: `116195.mp4`},word: "Paint roller",},
  {video: {url: `244949.mp4`},word: "Christology",},
  {video: {url: `244937.mp4`},word: "Creationism",},
  {video: {url: `191102.mp4`},word: "Parochial school",},
  {video: {url: `118505.mp4`},word: "Angelology",},
  {video: {url: `244895.mp4`},word: "Homiletics",},
  {video: {url: `116797.mp4`},word: "Scenic",},
  {video: {url: `155697.mp4`},word: "Two-dimensional",},
  {video: {url: `245334.mp4`},word: "Sculptural",},
  {video: {url: `89519.mp4`},word: "Glaze",},
  {video: {url: `157948.mp4`},word: "Pose",},
  {video: {url: `116807.mp4`},word: "Sculpt",},
  {video: {url: `244081.mp4`},word: "Balance",},
  {video: {url: `245047.mp4`},word: "Varnish",},
  {video: {url: `89539.mp4`},word: "Portrait",},
  {video: {url: `89557.mp4`},word: "Silhouette",},
  {video: {url: `155572.mp4`},word: "Self-portrait",},
  {video: {url: `62881.mp4`},word: "Perspective",},
  {video: {url: `158052.mp4`},word: "Chiaroscuro",},
  {video: {url: `124681.mp4`},word: "Bird\u00b4s eye view",},
  {video: {url: `244424.mp4`},word: "Enamel",},
  {video: {url: `51550.mp4`},word: "Clay",},
  {video: {url: `245803.mp4`},word: "Plastic art",},
  {video: {url: `154628.mp4`},word: "Foam board",},
  {video: {url: `154665.mp4`},word: "Greenware",},
  {video: {url: `154518.mp4`},word: "Cover art",},
  {video: {url: `96310.mp4`},word: "Emblem",},
  {video: {url: `89504.mp4`},word: "Clear lacquer",},
  {video: {url: `244600.mp4`},word: "Graphic art",},
  {video: {url: `89506.mp4`},word: "Collage",},
  {video: {url: `51838.mp4`},word: "Modeling clay",},
  {video: {url: `89524.mp4`},word: "Gravure printing",},
  {video: {url: `155703.mp4`},word: "Video art",},
  {video: {url: `89530.mp4`},word: "Layout",},
  {video: {url: `65779.mp4`},word: "Worm\u00b4s eye view",},
  {video: {url: `62870.mp4`},word: "Image",},
  {video: {url: `69330.mp4`},word: "Foreground",},
  {video: {url: `153070.mp4`},word: "Art director",},
  {video: {url: `62531.mp4`},word: "Background",},
  {video: {url: `65776.mp4`},word: "Vanishing point",},
  {video: {url: `65775.mp4`},word: "Vanishing line",},
  {video: {url: `245959.mp4`},word: "Mosaic",},
  {video: {url: `65764.mp4`},word: "Sculptor",},
  {video: {url: `65765.mp4`},word: "Sculpture",},
  {video: {url: `154780.mp4`},word: "Maquette",},
  {video: {url: `89542.mp4`},word: "Projection",},
  {video: {url: `89537.mp4`},word: "Optical illusion",},
  {video: {url: `158045.mp4`},word: "Residency",},
  {video: {url: `93099.mp4`},word: "Graphic designer",},
  {video: {url: `155710.mp4`},word: "Wall hanging",},
  {video: {url: `62892.mp4`},word: "Wallpaper",},
  {video: {url: `63560.mp4`},word: "Picture",},
  {video: {url: `154664.mp4`},word: "Graphics",},
  {video: {url: `244580.mp4`},word: "Glazing",},
  {video: {url: `244985.mp4`},word: "Woodworking",},
  {video: {url: `154781.mp4`},word: "Mark",},
  {video: {url: `89510.mp4`},word: "Composition",},
  {video: {url: `158044.mp4`},word: "Reshaping",},
  {video: {url: `153120.mp4`},word: "Blur",},
  {video: {url: `125907.mp4`},word: "Ceramics",},
  {video: {url: `154606.mp4`},word: "Fine art photographer",},
  {video: {url: `58590.mp4`},word: "Bust",},
  {video: {url: `154551.mp4`},word: "Digital art",},
  {video: {url: `154609.mp4`},word: "Firing",},
  {video: {url: `157899.mp4`},word: "Panoramic",},
  {video: {url: `89522.mp4`},word: "Graphic",},
  {video: {url: `89523.mp4`},word: "Graphic design",},
  {video: {url: `109486.mp4`},word: "3d",},
  {video: {url: `155707.mp4`},word: "Visual arts",},
  {video: {url: `158140.mp4`},word: "Statue",},
  {video: {url: `92616.mp4`},word: "Camping ground",},
  {video: {url: `72484.mp4`},word: "Van",},
  {video: {url: `92619.mp4`},word: "Car-camping",},
  {video: {url: `51650.mp4`},word: "Tent",},
  {video: {url: `76970.mp4`},word: "Rv",},
  {video: {url: `51640.mp4`},word: "Sleeping bag",},
  {video: {url: `51565.mp4`},word: "Camping",},
  {video: {url: `93794.mp4`},word: "Slow down and make a right turn",},
  {video: {url: `117045.mp4`},word: "Stop here on red",},
  {video: {url: `47503.mp4`},word: "Crash, to",},
  {video: {url: `93139.mp4`},word: "Hitchhiker",},
  {video: {url: `93325.mp4`},word: "Drive car",},
  {video: {url: `116531.mp4`},word: "Reserve parking",},
  {video: {url: `96361.mp4`},word: "Fasten",},
  {video: {url: `116895.mp4`},word: "Skid",},
  {video: {url: `116903.mp4`},word: "Slam on brakes",},
  {video: {url: `116503.mp4`},word: "Reduce speed",},
  {video: {url: `58442.mp4`},word: "Drive",},
  {video: {url: `72384.mp4`},word: "Buckle up",},
  {video: {url: `96210.mp4`},word: "Detour",},
  {video: {url: `49172.mp4`},word: "Park, to",},
  {video: {url: `116847.mp4`},word: "Shift gears",},
  {video: {url: `77327.mp4`},word: "Toll",},
  {video: {url: `97094.mp4`},word: "Parking-site",},
  {video: {url: `116570.mp4`},word: "Road bends",},
  {video: {url: `116571.mp4`},word: "Road block",},
  {video: {url: `96172.mp4`},word: "Dangerous bend",},
  {video: {url: `50827.mp4`},word: "Street",},
  {video: {url: `116963.mp4`},word: "Speed checked",},
  {video: {url: `117063.mp4`},word: "Street sign",},
  {video: {url: `65898.mp4`},word: "Pedestrian",},
  {video: {url: `117060.mp4`},word: "Street cleaner",},
  {video: {url: `117061.mp4`},word: "Street closed",},
  {video: {url: `117062.mp4`},word: "Street ends",},
  {video: {url: `96066.mp4`},word: "Collision",},
  {video: {url: `116537.mp4`},word: "Rest area",},
  {video: {url: `116538.mp4`},word: "Rest stop",},
  {video: {url: `93273.mp4`},word: "Dead end",},
  {video: {url: `116926.mp4`},word: "Snow chains",},
  {video: {url: `155550.mp4`},word: "Roadwork",},
  {video: {url: `90067.mp4`},word: "Through traffic",},
  {video: {url: `72486.mp4`},word: "Carwash",},
  {video: {url: `116319.mp4`},word: "Place",},
  {video: {url: `96643.mp4`},word: "Junction",},
  {video: {url: `123127.mp4`},word: "Baby seat",},
  {video: {url: `62722.mp4`},word: "Children car seat",},
  {video: {url: `65746.mp4`},word: "Intersection",},
  {video: {url: `90114.mp4`},word: "Traffic lights",},
  {video: {url: `96350.mp4`},word: "Expressway",},
  {video: {url: `90134.mp4`},word: "Truck driver",},
  {video: {url: `51388.mp4`},word: "Carpool",},
  {video: {url: `72897.mp4`},word: "Brake",},
  {video: {url: `92763.mp4`},word: "Unlimited mileage",},
  {video: {url: `116962.mp4`},word: "Speed bump",},
  {video: {url: `116964.mp4`},word: "Speed limit",},
  {video: {url: `62597.mp4`},word: "Blind spot",},
  {video: {url: `116967.mp4`},word: "Speed limit-30 mph",},
  {video: {url: `116966.mp4`},word: "Speed limit-20 mph",},
  {video: {url: `72910.mp4`},word: "Drivers education",},
  {video: {url: `72781.mp4`},word: "Intersection",},
  {video: {url: `72780.mp4`},word: "Crosswalk",},
  {video: {url: `51316.mp4`},word: "Auto rental",},
  {video: {url: `96922.mp4`},word: "Overpass",},
  {video: {url: `117046.mp4`},word: "Stop line",},
  {video: {url: `117047.mp4`},word: "Stop sign",},
  {video: {url: `117044.mp4`},word: "Stop ahead",},
  {video: {url: `116560.mp4`},word: "Right lane must turn",},
  {video: {url: `116561.mp4`},word: "Right turn",},
  {video: {url: `116970.mp4`},word: "Speed zone-end",},
  {video: {url: `116969.mp4`},word: "Speed zone ahead",},
  {video: {url: `116968.mp4`},word: "Speed limit-50 mph",},
  {video: {url: `116965.mp4`},word: "Speed limit minimum",},
  {video: {url: `76998.mp4`},word: "Highway",},
  {video: {url: `116558.mp4`},word: "Right lane - exit only",},
  {video: {url: `116559.mp4`},word: "Right lane closed",},
  {video: {url: `116557.mp4`},word: "Right bend",},
  {video: {url: `76694.mp4`},word: "Direction",},
  {video: {url: `116607.mp4`},word: "Roundabout",},
  {video: {url: `116504.mp4`},word: "Reduce speed ahead",},
  {video: {url: `97005.mp4`},word: "Asphalt",},
  {video: {url: `90106.mp4`},word: "Tow",},
  {video: {url: `116574.mp4`},word: "Road curves left",},
  {video: {url: `77033.mp4`},word: "Pavement",},
  {video: {url: `116212.mp4`},word: "Parallel parking",},
  {video: {url: `77031.mp4`},word: "Parking",},
  {video: {url: `77005.mp4`},word: "Navigation",},
  {video: {url: `116945.mp4`},word: "Soft road shoulder",},
  {video: {url: `90095.mp4`},word: "Toll road",},
  {video: {url: `72926.mp4`},word: "Gas station",},
  {video: {url: `94031.mp4`},word: "Speeding offence",},
  {video: {url: `96286.mp4`},word: "Driveway",},
  {video: {url: `116227.mp4`},word: "Parking attendant",},
  {video: {url: `116225.mp4`},word: "Park and rail",},
  {video: {url: `116231.mp4`},word: "Parking restriction",},
  {video: {url: `116230.mp4`},word: "Parking meter",},
  {video: {url: `116229.mp4`},word: "Parking lights",},
  {video: {url: `116228.mp4`},word: "Parking hours",},
  {video: {url: `77308.mp4`},word: "Gas station",},
  {video: {url: `77034.mp4`},word: "Pedestrian zone",},
  {video: {url: `245957.mp4`},word: "Motorcycling",},
  {video: {url: `77341.mp4`},word: "Stoplight",},
  {video: {url: `116584.mp4`},word: "Road work",},
  {video: {url: `116585.mp4`},word: "Road worker",},
  {video: {url: `116583.mp4`},word: "Road splits",},
  {video: {url: `116872.mp4`},word: "Signal ahead",},
  {video: {url: `116882.mp4`},word: "Site",},
  {video: {url: `93118.mp4`},word: "Handicapped parking",},
  {video: {url: `90111.mp4`},word: "Traffic cone",},
  {video: {url: `90112.mp4`},word: "Traffic controller",},
  {video: {url: `116452.mp4`},word: "Push for walk signal",},
  {video: {url: `96161.mp4`},word: "Curb",},
  {video: {url: `125781.mp4`},word: "Car headlights",},
  {video: {url: `116239.mp4`},word: "Passing zone",},
  {video: {url: `73027.mp4`},word: "Highway",},
  {video: {url: `77333.mp4`},word: "Spare tire",},
  {video: {url: `50916.mp4`},word: "Traffic",},
  {video: {url: `89876.mp4`},word: "One-way street",},
  {video: {url: `116582.mp4`},word: "Road sign",},
  {video: {url: `116581.mp4`},word: "Road shoulder work ahead",},
  {video: {url: `116580.mp4`},word: "Road shoulder work",},
  {video: {url: `116578.mp4`},word: "Road narrows",},
  {video: {url: `116577.mp4`},word: "Road enters from right",},
  {video: {url: `116576.mp4`},word: "Road ends",},
  {video: {url: `116575.mp4`},word: "Road curves right",},
  {video: {url: `90113.mp4`},word: "Traffic jam",},
  {video: {url: `116573.mp4`},word: "Road curves",},
  {video: {url: `116572.mp4`},word: "Road closed",},
  {video: {url: `96365.mp4`},word: "Fender bender",},
  {video: {url: `116242.mp4`},word: "Path",},
  {video: {url: `155630.mp4`},word: "Sterile",},
  {video: {url: `89643.mp4`},word: "Muddy",},
  {video: {url: `59632.mp4`},word: "Untidy",},
  {video: {url: `93308.mp4`},word: "Dishes",},
  {video: {url: `91617.mp4`},word: "Scrub",},
  {video: {url: `244945.mp4`},word: "Cleanse",},
  {video: {url: `91637.mp4`},word: "Soak",},
  {video: {url: `96246.mp4`},word: "Dispose",},
  {video: {url: `74977.mp4`},word: "Dry up",},
  {video: {url: `59622.mp4`},word: "To sweep the floor",},
  {video: {url: `93955.mp4`},word: "Dust",},
  {video: {url: `90196.mp4`},word: "Wash the dishes",},
  {video: {url: `50941.mp4`},word: "Wash ,to",},
  {video: {url: `246028.mp4`},word: "Make the bed",},
  {video: {url: `125583.mp4`},word: "Bucket",},
  {video: {url: `118378.mp4`},word: "Air filter",},
  {video: {url: `93330.mp4`},word: "Dry cleaner",},
  {video: {url: `74973.mp4`},word: "Dishwasher",},
  {video: {url: `187652.mp4`},word: "Clothes press",},
  {video: {url: `90092.mp4`},word: "Toilet scrub brush",},
  {video: {url: `125579.mp4`},word: "Brush on the stain",},
  {video: {url: `91651.mp4`},word: "Tap",},
  {video: {url: `59640.mp4`},word: "Washing machine",},
  {video: {url: `62741.mp4`},word: "Clothespin",},
  {video: {url: `62740.mp4`},word: "Clothesline",},
  {video: {url: `96206.mp4`},word: "Detergent",},
  {video: {url: `125566.mp4`},word: "Broom closet",},
  {video: {url: `93941.mp4`},word: "Dishwashing",},
  {video: {url: `51187.mp4`},word: "Bubble",},
  {video: {url: `89675.mp4`},word: "Bleach",},
  {video: {url: `89955.mp4`},word: "Stain",},
  {video: {url: `89810.mp4`},word: "Iron",},
  {video: {url: `244946.mp4`},word: "Cleanliness",},
  {video: {url: `189440.mp4`},word: "Peroxide",},
  {video: {url: `126005.mp4`},word: "Chlorine",},
  {video: {url: `75037.mp4`},word: "Mop",},
  {video: {url: `116937.mp4`},word: "Soap bar",},
  {video: {url: `116205.mp4`},word: "Paper towel",},
  {video: {url: `50760.mp4`},word: "Bin",},
  {video: {url: `62798.mp4`},word: "Rubbishbin",},
  {video: {url: `89679.mp4`},word: "Clothes line",},
  {video: {url: `87757.mp4`},word: "Drier",},
  {video: {url: `96239.mp4`},word: "Dish rack",},
  {video: {url: `96240.mp4`},word: "Dish soap",},
  {video: {url: `96241.mp4`},word: "Dishwasher soap",},
  {video: {url: `116994.mp4`},word: "Spring cleaning",},
  {video: {url: `96403.mp4`},word: "Garbage disposal",},
  {video: {url: `59576.mp4`},word: "Self-service",},
  {video: {url: `154408.mp4`},word: "Cleaner",},
  {video: {url: `51182.mp4`},word: "Broom",},
  {video: {url: `96634.mp4`},word: "Ironing board",},
  {video: {url: `74974.mp4`},word: "Dishwashing detergent",},
  {video: {url: `91481.mp4`},word: "Dishcloth",},
  {video: {url: `96291.mp4`},word: "Dustpan",},
  {video: {url: `116878.mp4`},word: "Sink",},
  {video: {url: `89640.mp4`},word: "Lawn mower",},
  {video: {url: `90120.mp4`},word: "Trash",},
  {video: {url: `59626.mp4`},word: "Towel",},
  {video: {url: `58578.mp4`},word: "Washing",},
  {video: {url: `93071.mp4`},word: "Garbage",},
  {video: {url: `116991.mp4`},word: "Spray bottle",},
  {video: {url: `96491.mp4`},word: "Hand washing",},
  {video: {url: `51073.mp4`},word: "Vacuum cleaner",},
  {video: {url: `96489.mp4`},word: "Hamper",},
  {video: {url: `96954.mp4`},word: "Mutual",},
  {video: {url: `91347.mp4`},word: "Acquainted",},
  {video: {url: `65915.mp4`},word: "Single",},
  {video: {url: `93277.mp4`},word: "Dear",},
  {video: {url: `51621.mp4`},word: "Raise",},
  {video: {url: `62857.mp4`},word: "Cut off",},
  {video: {url: `125530.mp4`},word: "Break up",},
  {video: {url: `91760.mp4`},word: "Family status",},
  {video: {url: `93983.mp4`},word: "Parents",},
  {video: {url: `50861.mp4`},word: "Son",},
  {video: {url: `96546.mp4`},word: "Heirloom",},
  {video: {url: `96997.mp4`},word: "Ancestry",},
  {video: {url: `185277.mp4`},word: "Single parent",},
  {video: {url: `185275.mp4`},word: "Single-parent family",},
  {video: {url: `91405.mp4`},word: "Fianc\u00e9e",},
  {video: {url: `91404.mp4`},word: "Fianc\u00e9",},
  {video: {url: `48820.mp4`},word: "Niece",},
  {video: {url: `244914.mp4`},word: "Firstborn",},
  {video: {url: `51607.mp4`},word: "Peer",},
  {video: {url: `91426.mp4`},word: "Generation",},
  {video: {url: `49273.mp4`},word: "Relationship",},
  {video: {url: `62528.mp4`},word: "Bachelor",},
  {video: {url: `90131.mp4`},word: "Triplets",},
  {video: {url: `62271.mp4`},word: "Ancestor",},
  {video: {url: `153266.mp4`},word: "Daddy",},
  {video: {url: `116220.mp4`},word: "Parent",},
  {video: {url: `48811.mp4`},word: "Nephew",},
  {video: {url: `47501.mp4`},word: "Cousin",},
  {video: {url: `122845.mp4`},word: "Aunt",},
  {video: {url: `50951.mp4`},word: "Uncle",},
  {video: {url: `50860.mp4`},word: "Son-in-law",},
  {video: {url: `51923.mp4`},word: "Granddaughter",},
  {video: {url: `51366.mp4`},word: "Best friend",},
  {video: {url: `125251.mp4`},word: "Boyfriend",},
  {video: {url: `92632.mp4`},word: "Companion",},
  {video: {url: `50873.mp4`},word: "Sister-in-law",},
  {video: {url: `125569.mp4`},word: "Brother-in-law",},
  {video: {url: `96166.mp4`},word: "Custody",},
  {video: {url: `93269.mp4`},word: "Dad",},
  {video: {url: `92994.mp4`},word: "Family photo",},
  {video: {url: `51852.mp4`},word: "Husband",},
  {video: {url: `47848.mp4`},word: "Grandmother",},
  {video: {url: `92898.mp4`},word: "Mom",},
  {video: {url: `125568.mp4`},word: "Brother",},
  {video: {url: `189426.mp4`},word: "Orphan",},
  {video: {url: `58513.mp4`},word: "Grandparents",},
  {video: {url: `244902.mp4`},word: "Godparent",},
  {video: {url: `91402.mp4`},word: "Fatherhood",},
  {video: {url: `89860.mp4`},word: "Neighbor",},
  {video: {url: `91682.mp4`},word: "Adoption",},
  {video: {url: `91681.mp4`},word: "Adopted child",},
  {video: {url: `91441.mp4`},word: "Housewife",},
  {video: {url: `50870.mp4`},word: "Sister",},
  {video: {url: `48601.mp4`},word: "Mother",},
  {video: {url: `94037.mp4`},word: "Stranger",},
  {video: {url: `48568.mp4`},word: "Lover",},
  {video: {url: `96128.mp4`},word: "Couple",},
  {video: {url: `75010.mp4`},word: "Guest",},
  {video: {url: `51925.mp4`},word: "Grandson",},
  {video: {url: `153634.mp4`},word: "Mummy",},
  {video: {url: `50948.mp4`},word: "Twins",},
  {video: {url: `117121.mp4`},word: "Sweetheart",},
  {video: {url: `62751.mp4`},word: "Relative",},
  {video: {url: `51069.mp4`},word: "Wife",},
  {video: {url: `118344.mp4`},word: "Adopter",},
  {video: {url: `89610.mp4`},word: "Adoptive parents",},
  {video: {url: `155588.mp4`},word: "Shelve",},
  {video: {url: `62554.mp4`},word: "Beach furniture",},
  {video: {url: `73023.mp4`},word: "Head rest",},
  {video: {url: `125818.mp4`},word: "Carpet",},
  {video: {url: `118551.mp4`},word: "Antiquity",},
  {video: {url: `74909.mp4`},word: "Chair",},
  {video: {url: `122553.mp4`},word: "Armchair",},
  {video: {url: `96138.mp4`},word: "Cradle",},
  {video: {url: `96266.mp4`},word: "Doorknob",},
  {video: {url: `62737.mp4`},word: "Closet",},
  {video: {url: `89948.mp4`},word: "Sheet",},
  {video: {url: `96273.mp4`},word: "Drafting table",},
  {video: {url: `51802.mp4`},word: "Cozy corner",},
  {video: {url: `124997.mp4`},word: "Bookcase",},
  {video: {url: `62560.mp4`},word: "Bean bag",},
  {video: {url: `116303.mp4`},word: "Pillowcase",},
  {video: {url: `125001.mp4`},word: "Bookshelf",},
  {video: {url: `62796.mp4`},word: "Room",},
  {video: {url: `91647.mp4`},word: "Stool",},
  {video: {url: `125653.mp4`},word: "Cabinet",},
  {video: {url: `116944.mp4`},word: "Sofa bed",},
  {video: {url: `125965.mp4`},word: "Chest of drawers",},
  {video: {url: `125920.mp4`},word: "Chandelier",},
  {video: {url: `50898.mp4`},word: "Curtain",},
  {video: {url: `50797.mp4`},word: "Clothes rack",},
  {video: {url: `153234.mp4`},word: "Built-in wardrobe",},
  {video: {url: `51196.mp4`},word: "Bunk bed",},
  {video: {url: `48822.mp4`},word: "Night table",},
  {video: {url: `59607.mp4`},word: "Table",},
  {video: {url: `49191.mp4`},word: "Pillow",},
  {video: {url: `77329.mp4`},word: "Seat",},
  {video: {url: `96278.mp4`},word: "Dresser",},
  {video: {url: `91622.mp4`},word: "Sideboard",},
  {video: {url: `58465.mp4`},word: "Lamp",},
  {video: {url: `124726.mp4`},word: "Blind",},
  {video: {url: `96146.mp4`},word: "Crib",},
  {video: {url: `93293.mp4`},word: "Desk",},
  {video: {url: `93098.mp4`},word: "Grandfather clock",},
  {video: {url: `116492.mp4`},word: "Recliner",},
  {video: {url: `65960.mp4`},word: "Doorbell",},
  {video: {url: `92829.mp4`},word: "Bench",},
  {video: {url: `87718.mp4`},word: "Artificial flowers",},
  {video: {url: `92774.mp4`},word: "Inside",},
  {video: {url: `96268.mp4`},word: "Doormat",},
  {video: {url: `116190.mp4`},word: "Pad",},
  {video: {url: `117027.mp4`},word: "Step stool",},
  {video: {url: `93301.mp4`},word: "Dining table",},
  {video: {url: `93041.mp4`},word: "Folding table",},
  {video: {url: `93235.mp4`},word: "Coffe table",},
  {video: {url: `93133.mp4`},word: "Highchair",},
  {video: {url: `92813.mp4`},word: "Writing table",},
  {video: {url: `125883.mp4`},word: "Ceiling fan",},
  {video: {url: `96504.mp4`},word: "Hat rack",},
  {video: {url: `123626.mp4`},word: "Bed",},
  {video: {url: `90200.mp4`},word: "Water bed",},
  {video: {url: `116243.mp4`},word: "Patio furniture",},
  {video: {url: `96265.mp4`},word: "Door knocker",},
  {video: {url: `117074.mp4`},word: "Stuff",},
  {video: {url: `72336.mp4`},word: "Backrest",},
  {video: {url: `48590.mp4`},word: "Mirror",},
  {video: {url: `93066.mp4`},word: "Furniture",},
  {video: {url: `51809.mp4`},word: "Wc",},
  {video: {url: `50910.mp4`},word: "Toilet",},
  {video: {url: `116878.mp4`},word: "Sink",},
  {video: {url: `90093.mp4`},word: "Toilet seat",},
  {video: {url: `90092.mp4`},word: "Toilet scrub brush",},
  {video: {url: `116860.mp4`},word: "Showerhead",},
  {video: {url: `92788.mp4`},word: "Whirlpool bathtub",},
  {video: {url: `62552.mp4`},word: "Bathtub",},
  {video: {url: `116938.mp4`},word: "Soap dispenser",},
  {video: {url: `116937.mp4`},word: "Soap bar",},
  {video: {url: `96363.mp4`},word: "Faucet",},
  {video: {url: `123597.mp4`},word: "Bath",},
  {video: {url: `50815.mp4`},word: "Soap",},
  {video: {url: `50909.mp4`},word: "Toilet paper",},
  {video: {url: `93706.mp4`},word: "Joy to the world",},
  {video: {url: `246036.mp4`},word: "Lyric",},
  {video: {url: `62887.mp4`},word: "Singing",},
  {video: {url: `244203.mp4`},word: "Classical music",},
  {video: {url: `93329.mp4`},word: "Drum",},
  {video: {url: `155613.mp4`},word: "Sound track",},
  {video: {url: `244396.mp4`},word: "Dynamics",},
  {video: {url: `96233.mp4`},word: "Disc jockey",},
  {video: {url: `244397.mp4`},word: "Duo",},
  {video: {url: `92711.mp4`},word: "Opera",},
  {video: {url: `189690.mp4`},word: "Metronome",},
  {video: {url: `245947.mp4`},word: "Musical mode",},
  {video: {url: `245948.mp4`},word: "Music director",},
  {video: {url: `244070.mp4`},word: "Bass",},
  {video: {url: `109669.mp4`},word: "Cd",},
  {video: {url: `245300.mp4`},word: "Sharp",},
  {video: {url: `245749.mp4`},word: "Program music",},
  {video: {url: `245918.mp4`},word: "Note",},
  {video: {url: `51568.mp4`},word: "Children's song",},
  {video: {url: `153105.mp4`},word: "Bandsaw",},
  {video: {url: `62544.mp4`},word: "Band",},
  {video: {url: `62691.mp4`},word: "Carol",},
  {video: {url: `51431.mp4`},word: "Chime",},
  {video: {url: `62627.mp4`},word: "Boombox",},
  {video: {url: `246017.mp4`},word: "March",},
  {video: {url: `92633.mp4`},word: "Concert",},
  {video: {url: `96498.mp4`},word: "Harmonica",},
  {video: {url: `245231.mp4`},word: "Song cycle",},
  {video: {url: `90176.mp4`},word: "Violin",},
  {video: {url: `48609.mp4`},word: "Music",},
  {video: {url: `96057.mp4`},word: "Chorus",},
  {video: {url: `124268.mp4`},word: "Bell",},
  {video: {url: `245969.mp4`},word: "Modern music",},
  {video: {url: `92681.mp4`},word: "Gig",},
  {video: {url: `92922.mp4`},word: "Music stand",},
  {video: {url: `92923.mp4`},word: "Musician",},
  {video: {url: `93038.mp4`},word: "Flute",},
  {video: {url: `92921.mp4`},word: "Music box",},
  {video: {url: `89897.mp4`},word: "Piano",},
  {video: {url: `246034.mp4`},word: "Lyrics",},
  {video: {url: `96094.mp4`},word: "Conductor",},
  {video: {url: `91330.mp4`},word: "Instrument",},
  {video: {url: `245132.mp4`},word: "Tempo",},
  {video: {url: `96499.mp4`},word: "Harp",},
  {video: {url: `96908.mp4`},word: "Orchestra",},
  {video: {url: `245085.mp4`},word: "Trio",},
  {video: {url: `191296.mp4`},word: "Piano tuner",},
  {video: {url: `244943.mp4`},word: "Community singing",},
  {video: {url: `62725.mp4`},word: "Choir",},
  {video: {url: `51767.mp4`},word: "Song",},
  {video: {url: `246115.mp4`},word: "Instrumental concert",},
  {video: {url: `90181.mp4`},word: "Voice",},
  {video: {url: `246106.mp4`},word: "Jazz",},
  {video: {url: `116591.mp4`},word: "Rock star",},
  {video: {url: `96058.mp4`},word: "Clarinet",},
  {video: {url: `96943.mp4`},word: "Melody",},
  {video: {url: `116877.mp4`},word: "Singer",},
  {video: {url: `93734.mp4`},word: "Mother's day",},
  {video: {url: `93809.mp4`},word: "The 4th of july is a holiday.",},
  {video: {url: `93845.mp4`},word: "Trick or treat?",},
  {video: {url: `93707.mp4`},word: "Joyous christmas",},
  {video: {url: `26499.mp4`},word: "Merry christmas",},
  {video: {url: `91447.mp4`},word: "Invited",},
  {video: {url: `93903.mp4`},word: "Celebrated",},
  {video: {url: `51794.mp4`},word: "Celebrate",},
  {video: {url: `51860.mp4`},word: "Invite",},
  {video: {url: `93247.mp4`},word: "Congratulate",},
  {video: {url: `243953.mp4`},word: "New year",},
  {video: {url: `244918.mp4`},word: "Feast",},
  {video: {url: `125886.mp4`},word: "Celebration",},
  {video: {url: `50776.mp4`},word: "Santa claus",},
  {video: {url: `116888.mp4`},word: "Sixties party",},
  {video: {url: `124993.mp4`},word: "Bonfire",},
  {video: {url: `91324.mp4`},word: "Children's party",},
  {video: {url: `93011.mp4`},word: "Fifth anniversary",},
  {video: {url: `154666.mp4`},word: "Greeting card",},
  {video: {url: `62543.mp4`},word: "Balloon",},
  {video: {url: `92667.mp4`},word: "Festival",},
  {video: {url: `116394.mp4`},word: "Present",},
  {video: {url: `116810.mp4`},word: "Seating chart",},
  {video: {url: `93107.mp4`},word: "Guest list",},
  {video: {url: `58861.mp4`},word: "Birthday party",},
  {video: {url: `116236.mp4`},word: "Party hat",},
  {video: {url: `116237.mp4`},word: "Party planner",},
  {video: {url: `124685.mp4`},word: "Birthday",},
  {video: {url: `116234.mp4`},word: "Party favors",},
  {video: {url: `93012.mp4`},word: "Fiftieth anniversary",},
  {video: {url: `91407.mp4`},word: "Firework",},
  {video: {url: `93113.mp4`},word: "Halloween",},
  {video: {url: `96969.mp4`},word: "Tribute",},
  {video: {url: `58551.mp4`},word: "Teacher's day",},
  {video: {url: `244790.mp4`},word: "Thanksgiving",},
  {video: {url: `116363.mp4`},word: "Pool party",},
  {video: {url: `157945.mp4`},word: "Pop-up card",},
  {video: {url: `243954.mp4`},word: "New year's eve",},
  {video: {url: `117106.mp4`},word: "Surprise party",},
  {video: {url: `89808.mp4`},word: "Invitation",},
  {video: {url: `154714.mp4`},word: "Invitation card",},
  {video: {url: `244958.mp4`},word: "Advent calendar",},
  {video: {url: `116207.mp4`},word: "Parade",},
  {video: {url: `116235.mp4`},word: "Party flyer",},
  {video: {url: `51382.mp4`},word: "Birthday boy",},
  {video: {url: `51380.mp4`},word: "Birthday girl",},
  {video: {url: `51385.mp4`},word: "Birthday cake",},
  {video: {url: `89929.mp4`},word: "Are you married?",},
  {video: {url: `48580.mp4`},word: "Married",},
  {video: {url: `153683.mp4`},word: "Propose",},
  {video: {url: `118519.mp4`},word: "Annul",},
  {video: {url: `124293.mp4`},word: "Betrothed",},
  {video: {url: `96315.mp4`},word: "Engagement",},
  {video: {url: `50949.mp4`},word: "Wedding",},
  {video: {url: `125548.mp4`},word: "Bridegroom",},
  {video: {url: `62592.mp4`},word: "Birde and groom",},
  {video: {url: `125547.mp4`},word: "Bride",},
  {video: {url: `62654.mp4`},word: "Bridal gown",},
  {video: {url: `116563.mp4`},word: "Ring bearer",},
  {video: {url: `125549.mp4`},word: "Bridesmaids",},
  {video: {url: `93143.mp4`},word: "Honeymoon",},
  {video: {url: `62528.mp4`},word: "Bachelor",},
  {video: {url: `92952.mp4`},word: "Newlywed",},
  {video: {url: `51365.mp4`},word: "Best man",},
  {video: {url: `51445.mp4`},word: "Church wedding",},
  {video: {url: `244948.mp4`},word: "Chuppah",},
  {video: {url: `93103.mp4`},word: "Groom",},
  {video: {url: `118515.mp4`},word: "Anniversary",},
  {video: {url: `94000.mp4`},word: "Proposal",},
  {video: {url: `92781.mp4`},word: "Maid of honor",},
  {video: {url: `51852.mp4`},word: "Husband",},
  {video: {url: `51069.mp4`},word: "Wife",},
  {video: {url: `118520.mp4`},word: "Annulment",},
  {video: {url: `58289.mp4`},word: "Divorce",},
  {video: {url: `116873.mp4`},word: "Silver anniversary",},
  {video: {url: `189394.mp4`},word: "Marriage guyance bureau",},
  {video: {url: `92843.mp4`},word: "Marriage",},
  {video: {url: `93104.mp4`},word: "Groomsmen",},
  {video: {url: `244775.mp4`},word: "Vow",},
  {video: {url: `246122.mp4`},word: "Injured",},
  {video: {url: `89847.mp4`},word: "Lame",},
  {video: {url: `93170.mp4`},word: "Injure",},
  {video: {url: `93962.mp4`},word: "Limp",},
  {video: {url: `93954.mp4`},word: "Drown",},
  {video: {url: `116990.mp4`},word: "Sprain",},
  {video: {url: `92676.mp4`},word: "Fracture",},
  {video: {url: `245229.mp4`},word: "Sore",},
  {video: {url: `96618.mp4`},word: "Injured",},
  {video: {url: `51938.mp4`},word: "Injury",},
  {video: {url: `51198.mp4`},word: "Burn",},
  {video: {url: `93017.mp4`},word: "Finger injury",},
  {video: {url: `125577.mp4`},word: "Bruise",},
  {video: {url: `96497.mp4`},word: "Harm",},
  {video: {url: `96092.mp4`},word: "Concussion",},
  {video: {url: `65778.mp4`},word: "Visualisation",},
  {video: {url: `244200.mp4`},word: "Claustrophobia",},
  {video: {url: `92866.mp4`},word: "Memory",},
  {video: {url: `63554.mp4`},word: "Oral",},
  {video: {url: `125580.mp4`},word: "Brush teeth",},
  {video: {url: `116437.mp4`},word: "Pulling a tooth",},
  {video: {url: `93289.mp4`},word: "Dentist",},
  {video: {url: `93288.mp4`},word: "Dental floss",},
  {video: {url: `97027.mp4`},word: "Cavity",},
  {video: {url: `91763.mp4`},word: "Filling",},
  {video: {url: `189504.mp4`},word: "Tooth decay",},
  {video: {url: `116392.mp4`},word: "Premolar",},
  {video: {url: `91739.mp4`},word: "Dentistry",},
  {video: {url: `96191.mp4`},word: "Dentures",},
  {video: {url: `125270.mp4`},word: "Braces",},
  {video: {url: `59625.mp4`},word: "Toothpick",},
  {video: {url: `110439.mp4`},word: "Mouthwash",},
  {video: {url: `51274.mp4`},word: "Calculus",},
  {video: {url: `50912.mp4`},word: "Toothbrush",},
  {video: {url: `244344.mp4`},word: "Dental care benefit",},
  {video: {url: `50913.mp4`},word: "Toothpaste",},
  {video: {url: `110233.mp4`},word: "Molar",},
  {video: {url: `90099.mp4`},word: "Toothbrushing",},
  {video: {url: `154558.mp4`},word: "Do-it-yourself",},
  {video: {url: `154651.mp4`},word: "Functional",},
  {video: {url: `93331.mp4`},word: "Duplicate",},
  {video: {url: `122854.mp4`},word: "Automatic",},
  {video: {url: `58553.mp4`},word: "Technical",},
  {video: {url: `157838.mp4`},word: "Mechanical",},
  {video: {url: `244035.mp4`},word: "Artificial",},
  {video: {url: `89883.mp4`},word: "Out of order",},
  {video: {url: `125006.mp4`},word: "Bore",},
  {video: {url: `49278.mp4`},word: "Repair",},
  {video: {url: `245955.mp4`},word: "Mould",},
  {video: {url: `51368.mp4`},word: "Bend",},
  {video: {url: `154521.mp4`},word: "Crack",},
  {video: {url: `244574.mp4`},word: "Glue",},
  {video: {url: `155722.mp4`},word: "Wind",},
  {video: {url: `116981.mp4`},word: "Splice",},
  {video: {url: `58537.mp4`},word: "Invent",},
  {video: {url: `69344.mp4`},word: "Install",},
  {video: {url: `75028.mp4`},word: "Mash",},
  {video: {url: `93022.mp4`},word: "Fire extinguisher",},
  {video: {url: `93020.mp4`},word: "Fire detector",},
  {video: {url: `91616.mp4`},word: "Scales",},
  {video: {url: `93018.mp4`},word: "Fire alarm",},
  {video: {url: `116478.mp4`},word: "Range",},
  {video: {url: `65955.mp4`},word: "Cross connection",},
  {video: {url: `188172.mp4`},word: "Neutraliser",},
  {video: {url: `154583.mp4`},word: "Environmental impact assessment",},
  {video: {url: `69415.mp4`},word: "Measuring range",},
  {video: {url: `125767.mp4`},word: "Cap",},
  {video: {url: `91767.mp4`},word: "Function",},
  {video: {url: `77317.mp4`},word: "Radiator",},
  {video: {url: `155667.mp4`},word: "Technological solution",},
  {video: {url: `157956.mp4`},word: "Power unit",},
  {video: {url: `191069.mp4`},word: "Pendulum",},
  {video: {url: `65744.mp4`},word: "Industrial design",},
  {video: {url: `69338.mp4`},word: "Field rotation speed",},
  {video: {url: `94399.mp4`},word: "Thermostat",},
  {video: {url: `65945.mp4`},word: "Antenna",},
  {video: {url: `96328.mp4`},word: "Escalator",},
  {video: {url: `93241.mp4`},word: "Command",},
  {video: {url: `96136.mp4`},word: "Crack",},
  {video: {url: `96401.mp4`},word: "Furnace",},
  {video: {url: `154778.mp4`},word: "Manufacturing system",},
  {video: {url: `96141.mp4`},word: "Crate",},
  {video: {url: `154779.mp4`},word: "Manufacturing task",},
  {video: {url: `155666.mp4`},word: "Technological problem",},
  {video: {url: `245136.mp4`},word: "Technical issue",},
  {video: {url: `96573.mp4`},word: "Hoop",},
  {video: {url: `191063.mp4`},word: "Periscope",},
  {video: {url: `191323.mp4`},word: "Plastic explosives",},
  {video: {url: `246010.mp4`},word: "Mass production",},
  {video: {url: `186150.mp4`},word: "Up counter",},
  {video: {url: `58387.mp4`},word: "Technology",},
  {video: {url: `65950.mp4`},word: "Button",},
  {video: {url: `244453.mp4`},word: "Engineering analysis",},
  {video: {url: `245074.mp4`},word: "Underground mining",},
  {video: {url: `69464.mp4`},word: "Wire",},
  {video: {url: `125766.mp4`},word: "Cap",},
  {video: {url: `92842.mp4`},word: "Manual",},
  {video: {url: `96921.mp4`},word: "Overheating",},
  {video: {url: `72905.mp4`},word: "Coolant",},
  {video: {url: `72558.mp4`},word: "Connecting rod",},
  {video: {url: `244988.mp4`},word: "Winding",},
  {video: {url: `76946.mp4`},word: "Lubrication",},
  {video: {url: `69345.mp4`},word: "Instruction",},
  {video: {url: `72919.mp4`},word: "Filter",},
  {video: {url: `153193.mp4`},word: "Book printing",},
  {video: {url: `153195.mp4`},word: "Book production",},
  {video: {url: `76695.mp4`},word: "Drive chain",},
  {video: {url: `125654.mp4`},word: "Cable",},
  {video: {url: `58466.mp4`},word: "Lock",},
  {video: {url: `96272.mp4`},word: "Drafting",},
  {video: {url: `155665.mp4`},word: "Technical report writing",},
  {video: {url: `118325.mp4`},word: "Active sensor",},
  {video: {url: `69422.mp4`},word: "Passive sensor",},
  {video: {url: `153060.mp4`},word: "Applied technology",},
  {video: {url: `155729.mp4`},word: "Workshop",},
  {video: {url: `187450.mp4`},word: "Down counter",},
  {video: {url: `74889.mp4`},word: "Box",},
  {video: {url: `72892.mp4`},word: "Differential",},
  {video: {url: `89609.mp4`},word: "Workroom",},
  {video: {url: `63619.mp4`},word: "Measuring error",},
  {video: {url: `63618.mp4`},word: "Measurement bridges",},
  {video: {url: `244607.mp4`},word: "Hardening",},
  {video: {url: `154578.mp4`},word: "Engineering systems",},
  {video: {url: `96121.mp4`},word: "Cord",},
  {video: {url: `154580.mp4`},word: "Environmental engineering",},
  {video: {url: `154581.mp4`},word: "Environmental engineering assessment",},
  {video: {url: `94047.mp4`},word: "Time for consideration",},
  {video: {url: `72309.mp4`},word: "Abs",},
  {video: {url: `50770.mp4`},word: "Robot",},
  {video: {url: `189690.mp4`},word: "Metronome",},
  {video: {url: `153018.mp4`},word: "3d object",},
  {video: {url: `96083.mp4`},word: "Compound",},
  {video: {url: `118335.mp4`},word: "Adaptor",},
  {video: {url: `69437.mp4`},word: "Remote control",},
  {video: {url: `246050.mp4`},word: "Load",},
  {video: {url: `188337.mp4`},word: "Aerosol",},
  {video: {url: `74900.mp4`},word: "Capacity",},
  {video: {url: `244985.mp4`},word: "Woodworking",},
  {video: {url: `93334.mp4`},word: "Dynamite",},
  {video: {url: `58417.mp4`},word: "Elevator",},
  {video: {url: `72345.mp4`},word: "Ball bearing",},
  {video: {url: `153705.mp4`},word: "Satellite",},
  {video: {url: `245225.mp4`},word: "Space",},
  {video: {url: `48581.mp4`},word: "Mars",},
  {video: {url: `245296.mp4`},word: "Shooting star",},
  {video: {url: `58550.mp4`},word: "Star",},
  {video: {url: `244029.mp4`},word: "Asteroid",},
  {video: {url: `243952.mp4`},word: "Mercury",},
  {video: {url: `92902.mp4`},word: "Moonlight",},
  {video: {url: `116325.mp4`},word: "Planet",},
  {video: {url: `48600.mp4`},word: "Moon",},
  {video: {url: `51085.mp4`},word: "World",},
  {video: {url: `89999.mp4`},word: "Telescope",},
  {video: {url: `244279.mp4`},word: "Crescent moon",},
  {video: {url: `243974.mp4`},word: "Pluto",},
  {video: {url: `62503.mp4`},word: "Astronomer",},
  {video: {url: `62504.mp4`},word: "Astronomy",},
  {video: {url: `62502.mp4`},word: "Astronaut",},
  {video: {url: `96906.mp4`},word: "Orbit",},
  {video: {url: `155608.mp4`},word: "Solar system",},
  {video: {url: `50839.mp4`},word: "Sun",},
  {video: {url: `93068.mp4`},word: "Galaxy",},
  {video: {url: `245964.mp4`},word: "Moon phase",},
  {video: {url: `153705.mp4`},word: "Satellite",},
  {video: {url: `245903.mp4`},word: "Odd",},
  {video: {url: `58557.mp4`},word: "Third",},
  {video: {url: `50783.mp4`},word: "Second",},
  {video: {url: `109494.mp4`},word: "40",},
  {video: {url: `109493.mp4`},word: "30",},
  {video: {url: `109485.mp4`},word: "29",},
  {video: {url: `109484.mp4`},word: "28",},
  {video: {url: `109483.mp4`},word: "27",},
  {video: {url: `109482.mp4`},word: "26",},
  {video: {url: `51469.mp4`},word: "25",},
  {video: {url: `109480.mp4`},word: "24",},
  {video: {url: `109479.mp4`},word: "23",},
  {video: {url: `109478.mp4`},word: "22",},
  {video: {url: `243922.mp4`},word: "90 000",},
  {video: {url: `243923.mp4`},word: "900",},
  {video: {url: `243920.mp4`},word: "800 000",},
  {video: {url: `243921.mp4`},word: "8000",},
  {video: {url: `243924.mp4`},word: "900 000",},
  {video: {url: `243925.mp4`},word: "9000",},
  {video: {url: `109500.mp4`},word: "67",},
  {video: {url: `109501.mp4`},word: "68",},
  {video: {url: `109497.mp4`},word: "63",},
  {video: {url: `109498.mp4`},word: "64",},
  {video: {url: `109499.mp4`},word: "65",},
  {video: {url: `51487.mp4`},word: "66",},
  {video: {url: `51478.mp4`},word: "50",},
  {video: {url: `51481.mp4`},word: "60",},
  {video: {url: `109495.mp4`},word: "61",},
  {video: {url: `109496.mp4`},word: "62",},
  {video: {url: `243917.mp4`},word: "7000",},
  {video: {url: `243916.mp4`},word: "700 000",},
  {video: {url: `243915.mp4`},word: "700",},
  {video: {url: `243914.mp4`},word: "70 000",},
  {video: {url: `243910.mp4`},word: "6000",},
  {video: {url: `243911.mp4`},word: "600\u00a0000",},
  {video: {url: `243909.mp4`},word: "600",},
  {video: {url: `243912.mp4`},word: "60\u00a0000",},
  {video: {url: `243919.mp4`},word: "800",},
  {video: {url: `243918.mp4`},word: "80 000",},
  {video: {url: `243897.mp4`},word: "300",},
  {video: {url: `243899.mp4`},word: "300\u00a0000",},
  {video: {url: `243898.mp4`},word: "3000",},
  {video: {url: `243905.mp4`},word: "40\u00a0000",},
  {video: {url: `243901.mp4`},word: "400",},
  {video: {url: `243904.mp4`},word: "400\u00a0000",},
  {video: {url: `243903.mp4`},word: "4000",},
  {video: {url: `243908.mp4`},word: "50\u00a0000",},
  {video: {url: `243907.mp4`},word: "500\u00a0000",},
  {video: {url: `243906.mp4`},word: "5000",},
  {video: {url: `243964.mp4`},word: "A billion",},
  {video: {url: `50883.mp4`},word: "Thousand",},
  {video: {url: `63541.mp4`},word: "Hundred",},
  {video: {url: `243896.mp4`},word: "20\u00a0000",},
  {video: {url: `243895.mp4`},word: "200\u00a0000",},
  {video: {url: `243893.mp4`},word: "200",},
  {video: {url: `243890.mp4`},word: "1 million",},
  {video: {url: `243889.mp4`},word: "1 billion",},
  {video: {url: `243892.mp4`},word: "10\u00a0000",},
  {video: {url: `243891.mp4`},word: "10",},
  {video: {url: `243900.mp4`},word: "30\u00a0000",},
  {video: {url: `243894.mp4`},word: "2000",},
  {video: {url: `51474.mp4`},word: "3",},
  {video: {url: `51463.mp4`},word: "2",},
  {video: {url: `51477.mp4`},word: "5",},
  {video: {url: `51475.mp4`},word: "4",},
  {video: {url: `51490.mp4`},word: "7",},
  {video: {url: `51480.mp4`},word: "6",},
  {video: {url: `51494.mp4`},word: "9",},
  {video: {url: `51493.mp4`},word: "8",},
  {video: {url: `109470.mp4`},word: "11",},
  {video: {url: `51497.mp4`},word: "Ten",},
  {video: {url: `243888.mp4`},word: "1",},
  {video: {url: `109469.mp4`},word: "18",},
  {video: {url: `109467.mp4`},word: "16",},
  {video: {url: `109468.mp4`},word: "17",},
  {video: {url: `109473.mp4`},word: "14",},
  {video: {url: `109474.mp4`},word: "15",},
  {video: {url: `109471.mp4`},word: "12",},
  {video: {url: `109472.mp4`},word: "13",},
  {video: {url: `109476.mp4`},word: "20",},
  {video: {url: `51465.mp4`},word: "21",},
  {video: {url: `51496.mp4`},word: "One",},
  {video: {url: `116887.mp4`},word: "Sixteen",},
  {video: {url: `51479.mp4`},word: "500",},
  {video: {url: `109460.mp4`},word: "1000000",},
  {video: {url: `109459.mp4`},word: "1000",},
  {video: {url: `51492.mp4`},word: "70",},
  {video: {url: `109502.mp4`},word: "69",},
  {video: {url: `51495.mp4`},word: "90",},
  {video: {url: `109504.mp4`},word: "80",},
  {video: {url: `58570.mp4`},word: "Two",},
  {video: {url: `58559.mp4`},word: "Thirty",},
  {video: {url: `90003.mp4`},word: "Ten thousand",},
  {video: {url: `116832.mp4`},word: "Seventeen",},
  {video: {url: `116833.mp4`},word: "Seventy",},
  {video: {url: `58560.mp4`},word: "Three",},
  {video: {url: `58567.mp4`},word: "Twelve",},
  {video: {url: `58558.mp4`},word: "Thirteen",},
  {video: {url: `96169.mp4`},word: "Daffodil",},
  {video: {url: `124270.mp4`},word: "Bell-flower",},
  {video: {url: `188212.mp4`},word: "Pistil",},
  {video: {url: `186323.mp4`},word: "Chamomile",},
  {video: {url: `188427.mp4`},word: "Stamen",},
  {video: {url: `87886.mp4`},word: "Fresh flower",},
  {video: {url: `87754.mp4`},word: "Dried flower",},
  {video: {url: `187674.mp4`},word: "Corolla",},
  {video: {url: `184891.mp4`},word: "Rose",},
  {video: {url: `154750.mp4`},word: "Lily of the valley",},
  {video: {url: `87883.mp4`},word: "Flowerstand",},
  {video: {url: `89728.mp4`},word: "Petal",},
  {video: {url: `188154.mp4`},word: "Marguerite",},
  {video: {url: `89711.mp4`},word: "Lavender",},
  {video: {url: `89646.mp4`},word: "Orchid",},
  {video: {url: `89769.mp4`},word: "Tulip",},
  {video: {url: `244291.mp4`},word: "Cornflower",},
  {video: {url: `191159.mp4`},word: "Pansy",},
  {video: {url: `89621.mp4`},word: "Carnation",},
  {video: {url: `188080.mp4`},word: "Lily",},
  {video: {url: `87770.mp4`},word: "Floristry",},
  {video: {url: `116996.mp4`},word: "Spring flowers",},
  {video: {url: `96170.mp4`},word: "Dandelion",},
  {video: {url: `65879.mp4`},word: "Flowerpot",},
  {video: {url: `188184.mp4`},word: "Oxlip",},
  {video: {url: `186305.mp4`},word: "Burgeon",},
  {video: {url: `89688.mp4`},word: "Daisy",},
  {video: {url: `188420.mp4`},word: "Snowdrop",},
  {video: {url: `89678.mp4`},word: "Bouquet",},
  {video: {url: `87882.mp4`},word: "Flowerbox",},
  {video: {url: `87881.mp4`},word: "Flower shop",},
  {video: {url: `187840.mp4`},word: "Flowerbed",},
  {video: {url: `89770.mp4`},word: "Vase",},
  {video: {url: `188046.mp4`},word: "Inflorescence",},
  {video: {url: `89687.mp4`},word: "Cut flowers",},
  {video: {url: `186310.mp4`},word: "Calyx",},
  {video: {url: `125024.mp4`},word: "Bouquet of flowers",},
  {video: {url: `154749.mp4`},word: "Lilac",},
  {video: {url: `157944.mp4`},word: "Poppy",},
  {video: {url: `154636.mp4`},word: "Forget-me-not",},
  {video: {url: `73388.mp4`},word: "Sunflower",},
  {video: {url: `93035.mp4`},word: "Florist",},
  {video: {url: `153238.mp4`},word: "Calculate!",},
  {video: {url: `58401.mp4`},word: "Unlimited",},
  {video: {url: `153020.mp4`},word: "90 degrees",},
  {video: {url: `246066.mp4`},word: "Likely",},
  {video: {url: `153022.mp4`},word: "360 degrees",},
  {video: {url: `93951.mp4`},word: "Double",},
  {video: {url: `116333.mp4`},word: "Plus",},
  {video: {url: `92886.mp4`},word: "Minus",},
  {video: {url: `153261.mp4`},word: "Count",},
  {video: {url: `96252.mp4`},word: "Divide",},
  {video: {url: `154633.mp4`},word: "Forecast",},
  {video: {url: `244001.mp4`},word: "Add up",},
  {video: {url: `92918.mp4`},word: "Multiply",},
  {video: {url: `157837.mp4`},word: "Measure",},
  {video: {url: `117079.mp4`},word: "Subtract",},
  {video: {url: `125664.mp4`},word: "Calculate",},
  {video: {url: `157939.mp4`},word: "Plot",},
  {video: {url: `154541.mp4`},word: "Deduct",},
  {video: {url: `49007.mp4`},word: "Number",},
  {video: {url: `245189.mp4`},word: "Straight angle",},
  {video: {url: `153703.mp4`},word: "Roman numerals",},
  {video: {url: `153065.mp4`},word: "Arabic numerals",},
  {video: {url: `96220.mp4`},word: "Diameter",},
  {video: {url: `96321.mp4`},word: "Equation",},
  {video: {url: `155702.mp4`},word: "Variables",},
  {video: {url: `245933.mp4`},word: "Negative number",},
  {video: {url: `154587.mp4`},word: "Estimate",},
  {video: {url: `97002.mp4`},word: "Arithmetic",},
  {video: {url: `154545.mp4`},word: "Degree",},
  {video: {url: `243930.mp4`},word: "Addition",},
  {video: {url: `93279.mp4`},word: "Decimal",},
  {video: {url: `244622.mp4`},word: "Hexagon",},
  {video: {url: `153062.mp4`},word: "Approximation",},
  {video: {url: `244441.mp4`},word: "Even number",},
  {video: {url: `245203.mp4`},word: "Statistical analysis",},
  {video: {url: `244991.mp4`},word: "Whole number",},
  {video: {url: `157846.mp4`},word: "Minus",},
  {video: {url: `153021.mp4`},word: "90\u00b0",},
  {video: {url: `244076.mp4`},word: "Bar chart",},
  {video: {url: `92650.mp4`},word: "Deduction",},
  {video: {url: `245949.mp4`},word: "Multiple",},
  {video: {url: `244074.mp4`},word: "Baseline",},
  {video: {url: `155576.mp4`},word: "Semicircle",},
  {video: {url: `245768.mp4`},word: "Probability",},
  {video: {url: `245368.mp4`},word: "Root",},
  {video: {url: `63551.mp4`},word: "Numeral",},
  {video: {url: `117080.mp4`},word: "Subtraction",},
  {video: {url: `245756.mp4`},word: "Product",},
  {video: {url: `93079.mp4`},word: "Geometry",},
  {video: {url: `245937.mp4`},word: "Natural numbers",},
  {video: {url: `50830.mp4`},word: "Cardinal number",},
  {video: {url: `245973.mp4`},word: "Mixed numbers",},
  {video: {url: `245904.mp4`},word: "Octagon",},
  {video: {url: `94173.mp4`},word: "Unrestricted quota",},
  {video: {url: `48582.mp4`},word: "Mathematics",},
  {video: {url: `96396.mp4`},word: "Fraction",},
  {video: {url: `96253.mp4`},word: "Division",},
  {video: {url: `245202.mp4`},word: "Statistical evidence",},
  {video: {url: `89553.mp4`},word: "Scale",},
  {video: {url: `96228.mp4`},word: "Digit",},
  {video: {url: `69424.mp4`},word: "Phase",},
  {video: {url: `96953.mp4`},word: "Multiplication",},
  {video: {url: `96430.mp4`},word: "Graph",},
  {video: {url: `153645.mp4`},word: "Ordinal number",},
  {video: {url: `157927.mp4`},word: "Pie chart",},
  {video: {url: `96219.mp4`},word: "Diagram",},
  {video: {url: `245414.mp4`},word: "Remainder",},
  {video: {url: `245811.mp4`},word: "Pi number",},
  {video: {url: `244448.mp4`},word: "Equivalent",},
  {video: {url: `245778.mp4`},word: "Power",},
  {video: {url: `245286.mp4`},word: "Side",},
  {video: {url: `153101.mp4`},word: "Axis",},
  {video: {url: `69425.mp4`},word: "Phase shift",},
  {video: {url: `155546.mp4`},word: "Right angle",},
  {video: {url: `154623.mp4`},word: "Flow chart",},
  {video: {url: `157941.mp4`},word: "Plus",},
  {video: {url: `245311.mp4`},word: "Set",},
  {video: {url: `125665.mp4`},word: "Calculator",},
  {video: {url: `96392.mp4`},word: "Formula",},
  {video: {url: `244000.mp4`},word: "Addend",},
  {video: {url: `244006.mp4`},word: "Acute",},
  {video: {url: `155685.mp4`},word: "Total percentage",},
  {video: {url: `91808.mp4`},word: "Majority",},
  {video: {url: `58496.mp4`},word: "Counter",},
  {video: {url: `125935.mp4`},word: "Chart",},
  {video: {url: `63567.mp4`},word: "Problem",},
  {video: {url: `245785.mp4`},word: "Positive",},
  {video: {url: `117085.mp4`},word: "Sum",},
  {video: {url: `245771.mp4`},word: "Prism",},
  {video: {url: `246007.mp4`},word: "Mathematical software",},
  {video: {url: `153023.mp4`},word: "360\u00b0",},
  {video: {url: `51741.mp4`},word: "Percentage",},
  {video: {url: `91818.mp4`},word: "Problem solving",},
  {video: {url: `51758.mp4`},word: "Statistics",},
  {video: {url: `91421.mp4`},word: "From home",},
  {video: {url: `96571.mp4`},word: "Homesick",},
  {video: {url: `62512.mp4`},word: "Away",},
  {video: {url: `63596.mp4`},word: "To travel",},
  {video: {url: `93139.mp4`},word: "Hitchhiker",},
  {video: {url: `92678.mp4`},word: "Get away",},
  {video: {url: `58583.mp4`},word: "World travel",},
  {video: {url: `51167.mp4`},word: "Leave",},
  {video: {url: `96192.mp4`},word: "Depart",},
  {video: {url: `96210.mp4`},word: "Detour",},
  {video: {url: `51501.mp4`},word: "Visit",},
  {video: {url: `92588.mp4`},word: "Adventure",},
  {video: {url: `92754.mp4`},word: "Tour",},
  {video: {url: `245105.mp4`},word: "Tourism region",},
  {video: {url: `92600.mp4`},word: "Backpacker",},
  {video: {url: `118175.mp4`},word: "Abta",},
  {video: {url: `92717.mp4`},word: "Postcard",},
  {video: {url: `76694.mp4`},word: "Direction",},
  {video: {url: `93143.mp4`},word: "Honeymoon",},
  {video: {url: `62555.mp4`},word: "Beach towel",},
  {video: {url: `92661.mp4`},word: "Embassy",},
  {video: {url: `50864.mp4`},word: "Travel",},
  {video: {url: `97101.mp4`},word: "Sunshade",},
  {video: {url: `92602.mp4`},word: "Bathing place",},
  {video: {url: `92603.mp4`},word: "Bathing pool",},
  {video: {url: `90175.mp4`},word: "View",},
  {video: {url: `92745.mp4`},word: "Souvenir",},
  {video: {url: `92653.mp4`},word: "Destination",},
  {video: {url: `58400.mp4`},word: "World tour",},
  {video: {url: `51651.mp4`},word: "Trail",},
  {video: {url: `58408.mp4`},word: "Journey",},
  {video: {url: `63540.mp4`},word: "Holiday",},
  {video: {url: `58404.mp4`},word: "Station",},
  {video: {url: `90184.mp4`},word: "Voyage",},
  {video: {url: `92757.mp4`},word: "Tourist",},
  {video: {url: `109901.mp4`},word: "Good night",},
  {video: {url: `109900.mp4`},word: "Good morning",},
  {video: {url: `73476.mp4`},word: "I love you",},
  {video: {url: `109919.mp4`},word: "Hello",},
  {video: {url: `51106.mp4`},word: "Yes",},
  {video: {url: `118365.mp4`},word: "Again",},
  {video: {url: `91401.mp4`},word: "Fast",},
  {video: {url: `92963.mp4`},word: "Not",},
  {video: {url: `48823.mp4`},word: "No",},
  {video: {url: `89882.mp4`},word: "Out",},
  {video: {url: `17655.mp4`},word: "Hi!",},
  {video: {url: `125649.mp4`},word: "Bye bye",},
  {video: {url: `58480.mp4`},word: "Sorry",},
  {video: {url: `93968.mp4`},word: "More",},
  {video: {url: `51156.mp4`},word: "Dark",},
  {video: {url: `58460.mp4`},word: "Full",},
  {video: {url: `58544.mp4`},word: "Open",},
  {video: {url: `118508.mp4`},word: "Angry",},
  {video: {url: `123620.mp4`},word: "Beautiful",},
  {video: {url: `58435.mp4`},word: "Dry",},
  {video: {url: `58529.mp4`},word: "Hungry",},
  {video: {url: `48592.mp4`},word: "Mischievous",},
  {video: {url: `58462.mp4`},word: "Hot",},
  {video: {url: `50950.mp4`},word: "Ugly",},
  {video: {url: `118361.mp4`},word: "Afraid",},
  {video: {url: `50878.mp4`},word: "Tired",},
  {video: {url: `93902.mp4`},word: "Careful",},
  {video: {url: `124964.mp4`},word: "Blue",},
  {video: {url: `124308.mp4`},word: "Big",},
  {video: {url: `125561.mp4`},word: "Broken",},
  {video: {url: `58499.mp4`},word: "Cute",},
  {video: {url: `49264.mp4`},word: "Quiet",},
  {video: {url: `92668.mp4`},word: "Fine",},
  {video: {url: `116954.mp4`},word: "Sore",},
  {video: {url: `65914.mp4`},word: "Short",},
  {video: {url: `111066.mp4`},word: "Nice",},
  {video: {url: `153314.mp4`},word: "Finished",},
  {video: {url: `51148.mp4`},word: "I",},
  {video: {url: `89864.mp4`},word: "Nobody",},
  {video: {url: `62693.mp4`},word: "Carry",},
  {video: {url: `49181.mp4`},word: "Pee",},
  {video: {url: `125921.mp4`},word: "Change",},
  {video: {url: `58476.mp4`},word: "Thank",},
  {video: {url: `50941.mp4`},word: "Wash ,to",},
  {video: {url: `50933.mp4`},word: "Wait",},
  {video: {url: `51820.mp4`},word: "Jumping",},
  {video: {url: `50812.mp4`},word: "Sleep",},
  {video: {url: `63577.mp4`},word: "Smile",},
  {video: {url: `93915.mp4`},word: "Dance",},
  {video: {url: `49199.mp4`},word: "Play",},
  {video: {url: `51555.mp4`},word: "Cry",},
  {video: {url: `74980.mp4`},word: "Eat, to",},
  {video: {url: `51779.mp4`},word: "Crawl",},
  {video: {url: `154561.mp4`},word: "Don\u2019t know",},
  {video: {url: `51192.mp4`},word: "Build",},
  {video: {url: `51081.mp4`},word: "Woman",},
  {video: {url: `59641.mp4`},word: "Water",},
  {video: {url: `125573.mp4`},word: "Brown",},
  {video: {url: `51108.mp4`},word: "Yesterday",},
  {video: {url: `51077.mp4`},word: "Witch",},
  {video: {url: `48600.mp4`},word: "Moon",},
  {video: {url: `58850.mp4`},word: "Apple",},
  {video: {url: `47501.mp4`},word: "Cousin",},
  {video: {url: `73391.mp4`},word: "Tree",},
  {video: {url: `50910.mp4`},word: "Toilet",},
  {video: {url: `50906.mp4`},word: "White",},
  {video: {url: `50858.mp4`},word: "Shark",},
  {video: {url: `125602.mp4`},word: "Bull",},
  {video: {url: `65883.mp4`},word: "Kangaroo",},
  {video: {url: `73310.mp4`},word: "Fox",},
  {video: {url: `125952.mp4`},word: "Cheese",},
  {video: {url: `59163.mp4`},word: "Grapes",},
  {video: {url: `246079.mp4`},word: "Leg",},
  {video: {url: `93297.mp4`},word: "Diaper",},
  {video: {url: `49184.mp4`},word: "Penguin",},
  {video: {url: `75041.mp4`},word: "Napkin",},
  {video: {url: `123615.mp4`},word: "Bear",},
  {video: {url: `48561.mp4`},word: "Lion",},
  {video: {url: `74880.mp4`},word: "Bag",},
  {video: {url: `59554.mp4`},word: "Pumpkin",},
  {video: {url: `59209.mp4`},word: "Ice cream",},
  {video: {url: `155657.mp4`},word: "Swan",},
  {video: {url: `91558.mp4`},word: "Pear",},
  {video: {url: `91562.mp4`},word: "Pepper",},
  {video: {url: `75008.mp4`},word: "Glass",},
  {video: {url: `125250.mp4`},word: "Boy",},
  {video: {url: `123626.mp4`},word: "Bed",},
  {video: {url: `51378.mp4`},word: "Computer",},
  {video: {url: `74946.mp4`},word: "Cookie",},
  {video: {url: `62522.mp4`},word: "Baby rattle",},
  {video: {url: `58448.mp4`},word: "Lorry",},
  {video: {url: `4103.mp4`},word: "Girl",},
  {video: {url: `125977.mp4`},word: "Child",},
  {video: {url: `58463.mp4`},word: "House",},
  {video: {url: `59546.mp4`},word: "Potato",},
  {video: {url: `125583.mp4`},word: "Bucket",},
  {video: {url: `73379.mp4`},word: "Spider",},
  {video: {url: `50856.mp4`},word: "Crocodile",},
  {video: {url: `51105.mp4`},word: "Yellow",},
  {video: {url: `73307.mp4`},word: "Fly",},
  {video: {url: `122551.mp4`},word: "Arm",},
  {video: {url: `49224.mp4`},word: "Purple",},
  {video: {url: `49192.mp4`},word: "Pink",},
  {video: {url: `125754.mp4`},word: "Candle",},
  {video: {url: `50791.mp4`},word: "Shirt",},
  {video: {url: `91691.mp4`},word: "Ambulance",},
  {video: {url: `74950.mp4`},word: "Crab",},
  {video: {url: `51392.mp4`},word: "Carrot",},
  {video: {url: `91470.mp4`},word: "Crayfish",},
  {video: {url: `63636.mp4`},word: "Desert",},
  {video: {url: `74917.mp4`},word: "Chicken",},
  {video: {url: `243937.mp4`},word: "Ipad",},
  {video: {url: `50867.mp4`},word: "Today",},
  {video: {url: `49191.mp4`},word: "Pillow",},
  {video: {url: `51399.mp4`},word: "Blanket",},
  {video: {url: `51133.mp4`},word: "Telephone",},
  {video: {url: `65950.mp4`},word: "Button",},
  {video: {url: `75001.mp4`},word: "Fruit",},
  {video: {url: `153652.mp4`},word: "Panda",},
  {video: {url: `75034.mp4`},word: "Milk",},
  {video: {url: `153724.mp4`},word: "Snow",},
  {video: {url: `93269.mp4`},word: "Dad",},
  {video: {url: `50838.mp4`},word: "Summer",},
  {video: {url: `50820.mp4`},word: "Spring",},
  {video: {url: `123631.mp4`},word: "Bee",},
  {video: {url: `91570.mp4`},word: "Porridge",},
  {video: {url: `59567.mp4`},word: "Salt",},
  {video: {url: `76795.mp4`},word: "Bus",},
  {video: {url: `245885.mp4`},word: "Orange",},
  {video: {url: `92898.mp4`},word: "Mom",},
  {video: {url: `116401.mp4`},word: "Prince",},
  {video: {url: `116402.mp4`},word: "Princess",},
  {video: {url: `58465.mp4`},word: "Lamp",},
  {video: {url: `91723.mp4`},word: "Cold",},
  {video: {url: `93302.mp4`},word: "Dinosaur",},
  {video: {url: `50870.mp4`},word: "Sister",},
  {video: {url: `74988.mp4`},word: "Fish",},
  {video: {url: `74958.mp4`},word: "Cup",},
  {video: {url: `74957.mp4`},word: "Cucumber",},
  {video: {url: `74991.mp4`},word: "Flour",},
  {video: {url: `74994.mp4`},word: "Fork",},
  {video: {url: `74907.mp4`},word: "Cereal",},
  {video: {url: `74909.mp4`},word: "Chair",},
  {video: {url: `47848.mp4`},word: "Grandmother",},
  {video: {url: `51951.mp4`},word: "Love",},
  {video: {url: `153634.mp4`},word: "Mummy",},
  {video: {url: `125531.mp4`},word: "Breakfast",},
  {video: {url: `75029.mp4`},word: "Meat",},
  {video: {url: `51342.mp4`},word: "Basement",},
  {video: {url: `91764.mp4`},word: "Foot",},
  {video: {url: `91621.mp4`},word: "Shrimp",},
  {video: {url: `59540.mp4`},word: "Plate",},
  {video: {url: `244952.mp4`},word: "Bottle",},
  {video: {url: `51076.mp4`},word: "Winter",},
  {video: {url: `125720.mp4`},word: "Camel",},
  {video: {url: `49271.mp4`},word: "Red",},
  {video: {url: `123139.mp4`},word: "Backpack",},
  {video: {url: `58455.mp4`},word: "Dirt",},
  {video: {url: `48821.mp4`},word: "Night",},
  {video: {url: `153266.mp4`},word: "Daddy",},
  {video: {url: `51097.mp4`},word: "Whale",},
  {video: {url: `62543.mp4`},word: "Balloon",},
  {video: {url: `123213.mp4`},word: "Ball",},
  {video: {url: `59222.mp4`},word: "Kitchen",},
  {video: {url: `69483.mp4`},word: "Corn",},
  {video: {url: `59326.mp4`},word: "Lemon",},
  {video: {url: `50763.mp4`},word: "Boots",},
  {video: {url: `89995.mp4`},word: "Teddy bear",},
  {video: {url: `50804.mp4`},word: "Shoes",},
  {video: {url: `123630.mp4`},word: "Bedroom",},
  {video: {url: `91534.mp4`},word: "Orange",},
  {video: {url: `91532.mp4`},word: "Onion",},
  {video: {url: `74889.mp4`},word: "Box",},
  {video: {url: `74888.mp4`},word: "Bowl",},
  {video: {url: `59607.mp4`},word: "Table",},
  {video: {url: `125842.mp4`},word: "Cat",},
  {video: {url: `65882.mp4`},word: "Hen",},
  {video: {url: `74891.mp4`},word: "Bread",},
  {video: {url: `125644.mp4`},word: "Butterfly",},
  {video: {url: `49170.mp4`},word: "Owl",},
  {video: {url: `59624.mp4`},word: "Tomato",},
  {video: {url: `125568.mp4`},word: "Brother",},
  {video: {url: `58526.mp4`},word: "Home",},
  {video: {url: `51570.mp4`},word: "Doll",},
  {video: {url: `59213.mp4`},word: "Juice",},
  {video: {url: `75043.mp4`},word: "Nut",},
  {video: {url: `75039.mp4`},word: "Mushroom",},
  {video: {url: `51760.mp4`},word: "Toy",},
  {video: {url: `153673.mp4`},word: "Potty",},
  {video: {url: `59596.mp4`},word: "Spoon",},
  {video: {url: `58457.mp4`},word: "Door",},
  {video: {url: `51080.mp4`},word: "Wolf",},
  {video: {url: `65936.mp4`},word: "Zebra",},
  {video: {url: `91528.mp4`},word: "Octopus",},
  {video: {url: `90204.mp4`},word: "Watermelon",},
  {video: {url: `93016.mp4`},word: "Finger",},
  {video: {url: `72317.mp4`},word: "Airplane",},
  {video: {url: `125780.mp4`},word: "Car",},
  {video: {url: `58860.mp4`},word: "Banana",},
  {video: {url: `245331.mp4`},word: "Seal",},
  {video: {url: `51647.mp4`},word: "Stuffed animal",},
  {video: {url: `246098.mp4`},word: "Knee",},
  {video: {url: `50917.mp4`},word: "Train",},
  {video: {url: `153296.mp4`},word: "Egg",},
  {video: {url: `74983.mp4`},word: "Elk",},
  {video: {url: `51134.mp4`},word: "Dolphin",},
  {video: {url: `59601.mp4`},word: "Sugar",},
  {video: {url: `59600.mp4`},word: "Strawberries",},
  {video: {url: `48602.mp4`},word: "Morning",},
  {video: {url: `89947.mp4`},word: "Sheep",},
  {video: {url: `155627.mp4`},word: "Staircase",},
  {video: {url: `91549.mp4`},word: "Pasta",},
  {video: {url: `50947.mp4`},word: "Tv",},
  {video: {url: `74979.mp4`},word: "Duck",},
  {video: {url: `58539.mp4`},word: "Island",},
  {video: {url: `51197.mp4`},word: "Bunny",},
  {video: {url: `122845.mp4`},word: "Aunt",},
  {video: {url: `50951.mp4`},word: "Uncle",},
  {video: {url: `50911.mp4`},word: "Tomorrow",},
  {video: {url: `65888.mp4`},word: "Lizard",},
  {video: {url: `48597.mp4`},word: "Monkey",},
  {video: {url: `48605.mp4`},word: "Mouse",},
  {video: {url: `74899.mp4`},word: "Cake",},
  {video: {url: `49207.mp4`},word: "Poo",},
  {video: {url: `74896.mp4`},word: "Butter",},
  {video: {url: `245945.mp4`},word: "Nail",},
  {video: {url: `50894.mp4`},word: "Giraffe",},
  {video: {url: `49266.mp4`},word: "Rain",},
  {video: {url: `122858.mp4`},word: "Autumn",},
  {video: {url: `58559.mp4`},word: "Thirty",},
  {video: {url: `74913.mp4`},word: "Cherry",},
  {video: {url: `51564.mp4`},word: "Camera",},
  {video: {url: `51563.mp4`},word: "Book",},
  {video: {url: `124685.mp4`},word: "Birthday",},
  {video: {url: `63528.mp4`},word: "Adult",},
  {video: {url: `47841.mp4`},word: "Good",},
  {video: {url: `49261.mp4`},word: "Queen",},
  {video: {url: `48542.mp4`},word: "King",},
  {video: {url: `50839.mp4`},word: "Sun",},
  {video: {url: `59190.mp4`},word: "Head",},
  {video: {url: `91542.mp4`},word: "Pancake",},
  {video: {url: `51873.mp4`},word: "Dinner",},
  {video: {url: `50813.mp4`},word: "Snake",},
  {video: {url: `65922.mp4`},word: "Squirrel",},
  {video: {url: `94027.mp4`},word: "Snail",},
  {video: {url: `49190.mp4`},word: "Pig",},
  {video: {url: `244299.mp4`},word: "Cook",},
  {video: {url: `125662.mp4`},word: "Cage",},
  {video: {url: `50931.mp4`},word: "Turtle",},
  {video: {url: `91556.mp4`},word: "Peach",},
  {video: {url: `51294.mp4`},word: "Candy",},
  {video: {url: `92762.mp4`},word: "Umbrella",},
  {video: {url: `62581.mp4`},word: "Bib",},
  {video: {url: `58438.mp4`},word: "Bicycle",},
  {video: {url: `51948.mp4`},word: "Laugh",},
  {video: {url: `153772.mp4`},word: "Watch",},
  {video: {url: `91459.mp4`},word: "Chocolate",},
  {video: {url: `91487.mp4`},word: "Eel",},
  {video: {url: `244499.mp4`},word: "Fin",},
  {video: {url: `59422.mp4`},word: "Lobster",},
  {video: {url: `155567.mp4`},word: "Sea trout",},
  {video: {url: `154622.mp4`},word: "Flounder",},
  {video: {url: `116569.mp4`},word: "Roach",},
  {video: {url: `93092.mp4`},word: "Goldfish",},
  {video: {url: `59598.mp4`},word: "Squid",},
  {video: {url: `91567.mp4`},word: "Pike",},
  {video: {url: `116808.mp4`},word: "Seahorse",},
  {video: {url: `50858.mp4`},word: "Shark",},
  {video: {url: `153064.mp4`},word: "Aquatic animal",},
  {video: {url: `155659.mp4`},word: "Swordfish",},
  {video: {url: `191209.mp4`},word: "Norway pout",},
  {video: {url: `91514.mp4`},word: "Mackerel",},
  {video: {url: `91576.mp4`},word: "Prawn",},
  {video: {url: `91620.mp4`},word: "Shellfish",},
  {video: {url: `153214.mp4`},word: "Brown trout",},
  {video: {url: `91621.mp4`},word: "Shrimp",},
  {video: {url: `59566.mp4`},word: "Salmon",},
  {video: {url: `96415.mp4`},word: "Gill",},
  {video: {url: `74988.mp4`},word: "Fish",},
  {video: {url: `154391.mp4`},word: "Catfish",},
  {video: {url: `157999.mp4`},word: "Rainbow trout",},
  {video: {url: `59628.mp4`},word: "Trout",},
  {video: {url: `91661.mp4`},word: "Tuna",},
  {video: {url: `157930.mp4`},word: "Piranha",},
  {video: {url: `91564.mp4`},word: "Perch",},
  {video: {url: `117011.mp4`},word: "Starfish",},
  {video: {url: `92622.mp4`},word: "Cathedral",},
  {video: {url: `96987.mp4`},word: "Village",},
  {video: {url: `49173.mp4`},word: "Park, a",},
  {video: {url: `92712.mp4`},word: "Outdoor museum",},
  {video: {url: `92691.mp4`},word: "Historical monument",},
  {video: {url: `123606.mp4`},word: "Bazaar",},
  {video: {url: `245936.mp4`},word: "Natural park",},
  {video: {url: `154689.mp4`},word: "Hot spring",},
  {video: {url: `92606.mp4`},word: "Beach",},
  {video: {url: `97098.mp4`},word: "Sphinx",},
  {video: {url: `92682.mp4`},word: "Glacier",},
  {video: {url: `92621.mp4`},word: "Castle",},
  {video: {url: `92596.mp4`},word: "Alps",},
  {video: {url: `92655.mp4`},word: "Disneyland",},
  {video: {url: `92743.mp4`},word: "Sightseeing",},
  {video: {url: `92765.mp4`},word: "Wineyard",},
  {video: {url: `92706.mp4`},word: "National park",},
  {video: {url: `92720.mp4`},word: "Pyramid",},
  {video: {url: `48608.mp4`},word: "Museum",},
  {video: {url: `243956.mp4`},word: "Niagara falls",},
  {video: {url: `92704.mp4`},word: "Market",},
  {video: {url: `58521.mp4`},word: "He",},
  {video: {url: `93130.mp4`},word: "Her",},
  {video: {url: `58546.mp4`},word: "Our",},
  {video: {url: `51095.mp4`},word: "What",},
  {video: {url: `91385.mp4`},word: "Everything",},
  {video: {url: `110165.mp4`},word: "It",},
  {video: {url: `89864.mp4`},word: "Nobody",},
  {video: {url: `50905.mp4`},word: "Which",},
  {video: {url: `90013.mp4`},word: "That",},
  {video: {url: `90017.mp4`},word: "Their",},
  {video: {url: `90018.mp4`},word: "Them",},
  {video: {url: `90156.mp4`},word: "Us",},
  {video: {url: `93138.mp4`},word: "His",},
  {video: {url: `93136.mp4`},word: "Him",},
  {video: {url: `63579.mp4`},word: "Something",},
  {video: {url: `51148.mp4`},word: "I",},
  {video: {url: `51110.mp4`},word: "You",},
  {video: {url: `48587.mp4`},word: "Me",},
  {video: {url: `96334.mp4`},word: "Every",},
  {video: {url: `96335.mp4`},word: "Everybody",},
  {video: {url: `89946.mp4`},word: "She",},
  {video: {url: `244016.mp4`},word: "All",},
  {video: {url: `244788.mp4`},word: "Thee",},
  {video: {url: `90022.mp4`},word: "They",},
  {video: {url: `90208.mp4`},word: "We",},
  {video: {url: `116950.mp4`},word: "Some",},
  {video: {url: `51811.mp4`},word: "Who",},
  {video: {url: `110509.mp4`},word: "Mr",},
  {video: {url: `92884.mp4`},word: "Mine",},
  {video: {url: `116880.mp4`},word: "Sir",},
  {video: {url: `92914.mp4`},word: "Mrs",},
  {video: {url: `116821.mp4`},word: "Self",},
  {video: {url: `89920.mp4`},word: "Are you a christian?",},
  {video: {url: `93400.mp4`},word: "Have you been baptized?",},
  {video: {url: `93707.mp4`},word: "Joyous christmas",},
  {video: {url: `93410.mp4`},word: "He is risen.",},
  {video: {url: `89921.mp4`},word: "Are you a roman catholic or a protestant?",},
  {video: {url: `243931.mp4`},word: "Bc",},
  {video: {url: `153041.mp4`},word: "Ad",},
  {video: {url: `118432.mp4`},word: "Amen",},
  {video: {url: `125009.mp4`},word: "Born again",},
  {video: {url: `244876.mp4`},word: "Lutheran",},
  {video: {url: `125849.mp4`},word: "Catholic",},
  {video: {url: `191455.mp4`},word: "Presbyterian",},
  {video: {url: `96150.mp4`},word: "Crucify",},
  {video: {url: `51517.mp4`},word: "Baptize",},
  {video: {url: `122535.mp4`},word: "Archangel",},
  {video: {url: `243933.mp4`},word: "Catholic",},
  {video: {url: `244957.mp4`},word: "Christian",},
  {video: {url: `110214.mp4`},word: "Methodist",},
  {video: {url: `122536.mp4`},word: "Archbishop",},
  {video: {url: `245125.mp4`},word: "The three wise men",},
  {video: {url: `118157.mp4`},word: "Abbey",},
  {video: {url: `244935.mp4`},word: "Crucifixion",},
  {video: {url: `244798.mp4`},word: "Sunday school",},
  {video: {url: `244912.mp4`},word: "Foot washing",},
  {video: {url: `51442.mp4`},word: "Christ",},
  {video: {url: `62577.mp4`},word: "Bethlehem",},
  {video: {url: `96947.mp4`},word: "Minister",},
  {video: {url: `244877.mp4`},word: "Luther",},
  {video: {url: `118393.mp4`},word: "Aisle",},
  {video: {url: `92904.mp4`},word: "Mormon",},
  {video: {url: `244950.mp4`},word: "Canon law",},
  {video: {url: `122580.mp4`},word: "Ash wednesday",},
  {video: {url: `244861.mp4`},word: "Old testament",},
  {video: {url: `244863.mp4`},word: "New testament",},
  {video: {url: `118351.mp4`},word: "Advent",},
  {video: {url: `124300.mp4`},word: "Bible",},
  {video: {url: `51518.mp4`},word: "Baptist",},
  {video: {url: `123268.mp4`},word: "Baptism",},
  {video: {url: `90172.mp4`},word: "Victim",},
  {video: {url: `244958.mp4`},word: "Advent calendar",},
  {video: {url: `244776.mp4`},word: "Virgin birth",},
  {video: {url: `96425.mp4`},word: "Gospel",},
  {video: {url: `51398.mp4`},word: "Bishop",},
  {video: {url: `244856.mp4`},word: "Pope",},
  {video: {url: `244792.mp4`},word: "Ten commandements",},
  {video: {url: `96554.mp4`},word: "Hinduism",},
  {video: {url: `96553.mp4`},word: "Hindu",},
  {video: {url: `92861.mp4`},word: "Meditate",},
  {video: {url: `125585.mp4`},word: "Buddha",},
  {video: {url: `125586.mp4`},word: "Buddhism",},
  {video: {url: `244873.mp4`},word: "Meditation",},
  {video: {url: `93708.mp4`},word: "Judaism is an old religion.",},
  {video: {url: `93703.mp4`},word: "Jewish people go to the temple on the sabbath.",},
  {video: {url: `118432.mp4`},word: "Amen",},
  {video: {url: `96639.mp4`},word: "Jewish",},
  {video: {url: `244947.mp4`},word: "Circumcision",},
  {video: {url: `244948.mp4`},word: "Chuppah",},
  {video: {url: `123292.mp4`},word: "Bar mitzvah",},
  {video: {url: `116773.mp4`},word: "Sabbath",},
  {video: {url: `123595.mp4`},word: "Bat mitzvah",},
  {video: {url: `244861.mp4`},word: "Old testament",},
  {video: {url: `118597.mp4`},word: "Yarmulke",},
  {video: {url: `93121.mp4`},word: "Hanukkah",},
  {video: {url: `116240.mp4`},word: "Passover",},
  {video: {url: `51442.mp4`},word: "Christ",},
  {video: {url: `244785.mp4`},word: "Torah",},
  {video: {url: `118596.mp4`},word: "Yhwh-personal god",},
  {video: {url: `89811.mp4`},word: "Israel",},
  {video: {url: `109510.mp4`},word: "Abraham",},
  {video: {url: `155556.mp4`},word: "Rural",},
  {video: {url: `155700.mp4`},word: "Urban",},
  {video: {url: `244579.mp4`},word: "Global",},
  {video: {url: `244034.mp4`},word: "Artificial landscape change",},
  {video: {url: `96412.mp4`},word: "Geographer",},
  {video: {url: `154388.mp4`},word: "Cardinal direction",},
  {video: {url: `47500.mp4`},word: "Country",},
  {video: {url: `154771.mp4`},word: "Mainland",},
  {video: {url: `48534.mp4`},word: "Isle",},
  {video: {url: `245939.mp4`},word: "Natural landscape change",},
  {video: {url: `155668.mp4`},word: "Temperate zone",},
  {video: {url: `245226.mp4`},word: "Southern hemisphere",},
  {video: {url: `246089.mp4`},word: "Landscape",},
  {video: {url: `245127.mp4`},word: "The arctic circle",},
  {video: {url: `93947.mp4`},word: "District",},
  {video: {url: `244989.mp4`},word: "Wild environment",},
  {video: {url: `92785.mp4`},word: "West",},
  {video: {url: `245856.mp4`},word: "Parallel",},
  {video: {url: `96322.mp4`},word: "Equator",},
  {video: {url: `244615.mp4`},word: "Gulf",},
  {video: {url: `58539.mp4`},word: "Island",},
  {video: {url: `244231.mp4`},word: "Compass",},
  {video: {url: `154688.mp4`},word: "Hot spot",},
  {video: {url: `92635.mp4`},word: "Continent",},
  {video: {url: `185697.mp4`},word: "Perimeter",},
  {video: {url: `245912.mp4`},word: "Oasis",},
  {video: {url: `245919.mp4`},word: "Northern hemisphere",},
  {video: {url: `51085.mp4`},word: "World",},
  {video: {url: `51314.mp4`},word: "Avenue",},
  {video: {url: `154515.mp4`},word: "Countryside",},
  {video: {url: `97082.mp4`},word: "Border",},
  {video: {url: `153209.mp4`},word: "Boundary",},
  {video: {url: `63581.mp4`},word: "South",},
  {video: {url: `122544.mp4`},word: "Area",},
  {video: {url: `246016.mp4`},word: "Marine environment",},
  {video: {url: `244226.mp4`},word: "Coast landscape",},
  {video: {url: `125772.mp4`},word: "Capital",},
  {video: {url: `110997.mp4`},word: "Nation",},
  {video: {url: `244972.mp4`},word: "World map",},
  {video: {url: `244971.mp4`},word: "World ocean",},
  {video: {url: `89861.mp4`},word: "Neighborhood",},
  {video: {url: `245795.mp4`},word: "Political map",},
  {video: {url: `244584.mp4`},word: "Ghetto",},
  {video: {url: `92752.mp4`},word: "Time zone",},
  {video: {url: `245018.mp4`},word: "Water level",},
  {video: {url: `116509.mp4`},word: "Region",},
  {video: {url: `62505.mp4`},word: "Atlantic ocean",},
  {video: {url: `122818.mp4`},word: "Atlas",},
  {video: {url: `116771.mp4`},word: "Rural area",},
  {video: {url: `49001.mp4`},word: "North",},
  {video: {url: `92719.mp4`},word: "Protected landscape area",},
  {video: {url: `90154.mp4`},word: "Urban area",},
  {video: {url: `93944.mp4`},word: "Distance",},
  {video: {url: `124734.mp4`},word: "Block",},
  {video: {url: `246039.mp4`},word: "Low-pressure area",},
  {video: {url: `243941.mp4`},word: "International date line",},
  {video: {url: `50915.mp4`},word: "Town",},
  {video: {url: `245905.mp4`},word: "Oceanic climate",},
  {video: {url: `244659.mp4`},word: "Ice shelf",},
  {video: {url: `244658.mp4`},word: "Iceberg",},
  {video: {url: `245014.mp4`},word: "Wave",},
  {video: {url: `51918.mp4`},word: "Geography",},
  {video: {url: `50049.mp4`},word: "Africa",},
  {video: {url: `62186.mp4`},word: "America",},
  {video: {url: `122846.mp4`},word: "Australia",},
  {video: {url: `50819.mp4`},word: "South america",},
  {video: {url: `49002.mp4`},word: "North america",},
  {video: {url: `153639.mp4`},word: "Oceania",},
  {video: {url: `122581.mp4`},word: "Asia",},
  {video: {url: `118544.mp4`},word: "Antarctic",},
  {video: {url: `243934.mp4`},word: "Central america",},
  {video: {url: `243972.mp4`},word: "Scandinavia",},
  {video: {url: `122543.mp4`},word: "Arctic",},
  {video: {url: `90061.mp4`},word: "Do you have a car?",},
  {video: {url: `89848.mp4`},word: "Land",},
  {video: {url: `62284.mp4`},word: "Arrive",},
  {video: {url: `245353.mp4`},word: "Sail",},
  {video: {url: `92613.mp4`},word: "By rail",},
  {video: {url: `96078.mp4`},word: "Commute",},
  {video: {url: `50821.mp4`},word: "Bus stop",},
  {video: {url: `58440.mp4`},word: "Bus station",},
  {video: {url: `50901.mp4`},word: "Taxi",},
  {video: {url: `58406.mp4`},word: "Rail travel",},
  {video: {url: `51875.mp4`},word: "Bus tour",},
  {video: {url: `58428.mp4`},word: "Car hire",},
  {video: {url: `58437.mp4`},word: "Arrival",},
  {video: {url: `244234.mp4`},word: "Commuter rail",},
  {video: {url: `92614.mp4`},word: "Cabin",},
  {video: {url: `155693.mp4`},word: "Transportation",},
  {video: {url: `92599.mp4`},word: "Arrival of train",},
  {video: {url: `244639.mp4`},word: "Hot air ballon",},
  {video: {url: `51259.mp4`},word: "Cable car",},
  {video: {url: `90179.mp4`},word: "Visitor parking",},
  {video: {url: `245015.mp4`},word: "Water trasportation",},
  {video: {url: `65898.mp4`},word: "Pedestrian",},
  {video: {url: `125650.mp4`},word: "Cab",},
  {video: {url: `77005.mp4`},word: "Navigation",},
  {video: {url: `125780.mp4`},word: "Car",},
  {video: {url: `92742.mp4`},word: "Short curt",},
  {video: {url: `245731.mp4`},word: "Public transport",},
  {video: {url: `116465.mp4`},word: "Rail",},
  {video: {url: `116466.mp4`},word: "Railroad crossing",},
  {video: {url: `116467.mp4`},word: "Railroad signal",},
  {video: {url: `58453.mp4`},word: "Train station",},
  {video: {url: `76795.mp4`},word: "Bus",},
  {video: {url: `97085.mp4`},word: "Ferry travel",},
  {video: {url: `92644.mp4`},word: "Cruise",},
  {video: {url: `92666.mp4`},word: "Ferry",},
  {video: {url: `65905.mp4`},word: "Railway station",},
  {video: {url: `244621.mp4`},word: "High-speed rail",},
  {video: {url: `92714.mp4`},word: "Passenger",},
  {video: {url: `92741.mp4`},word: "Ship",},
  {video: {url: `245088.mp4`},word: "Transfer station",},
  {video: {url: `58404.mp4`},word: "Station",},
  {video: {url: `89993.mp4`},word: "Taxi driver",},
  {video: {url: `89994.mp4`},word: "Taxi stand",},
  {video: {url: `125623.mp4`},word: "Bus driver",},
  {video: {url: `125625.mp4`},word: "Bus service",},
  {video: {url: `93215.mp4`},word: "Bus terminal",},
  {video: {url: `93436.mp4`},word: "How much is it per night?",},
  {video: {url: `73421.mp4`},word: "Can you recommend reasonably priced hotel?",},
  {video: {url: `58405.mp4`},word: "Room only",},
  {video: {url: `89966.mp4`},word: "Breakfast is not served after 11 am",},
  {video: {url: `244425.mp4`},word: "En suite",},
  {video: {url: `92739.mp4`},word: "Self-catering",},
  {video: {url: `96980.mp4`},word: "Vacant",},
  {video: {url: `65924.mp4`},word: "Stay overnight",},
  {video: {url: `116532.mp4`},word: "Reside",},
  {video: {url: `244012.mp4`},word: "Accommodate",},
  {video: {url: `92749.mp4`},word: "Suite",},
  {video: {url: `50047.mp4`},word: "Accommodation",},
  {video: {url: `58419.mp4`},word: "Dormitory",},
  {video: {url: `92627.mp4`},word: "Children's club",},
  {video: {url: `51737.mp4`},word: "Youth hostel",},
  {video: {url: `92686.mp4`},word: "Guest house",},
  {video: {url: `153580.mp4`},word: "Hospitality",},
  {video: {url: `154514.mp4`},word: "Country house",},
  {video: {url: `123113.mp4`},word: "B and b",},
  {video: {url: `92693.mp4`},word: "Hostel",},
  {video: {url: `153111.mp4`},word: "Bedroom suite",},
  {video: {url: `124269.mp4`},word: "Bell hop",},
  {video: {url: `92907.mp4`},word: "Motel",},
  {video: {url: `93319.mp4`},word: "Dorm",},
  {video: {url: `94199.mp4`},word: "Board and lodging",},
  {video: {url: `97089.mp4`},word: "Holiday resort",},
  {video: {url: `92695.mp4`},word: "Inn",},
  {video: {url: `92614.mp4`},word: "Cabin",},
  {video: {url: `125748.mp4`},word: "Cancel",},
  {video: {url: `59562.mp4`},word: "Reserve",},
  {video: {url: `92611.mp4`},word: "Budget fare",},
  {video: {url: `90121.mp4`},word: "Travel brochure",},
  {video: {url: `92760.mp4`},word: "Travel agency",},
  {video: {url: `92595.mp4`},word: "All-in package",},
  {video: {url: `92995.mp4`},word: "Family vacation",},
  {video: {url: `157898.mp4`},word: "Package",},
  {video: {url: `92617.mp4`},word: "Cancellation fee",},
  {video: {url: `92755.mp4`},word: "Tour operator",},
  {video: {url: `123139.mp4`},word: "Backpack",},
  {video: {url: `92690.mp4`},word: "High season",},
  {video: {url: `92600.mp4`},word: "Backpacker",},
  {video: {url: `92692.mp4`},word: "Holiday centre",},
  {video: {url: `92703.mp4`},word: "Luggage",},
  {video: {url: `97076.mp4`},word: "Activity holiday",},
  {video: {url: `92702.mp4`},word: "Low season",},
  {video: {url: `92718.mp4`},word: "Pre-bookable seat",},
  {video: {url: `92672.mp4`},word: "Fly/drive holiday",},
  {video: {url: `97086.mp4`},word: "Guide book",},
  {video: {url: `92748.mp4`},word: "Suitcase",},
  {video: {url: `157853.mp4`},word: "Museum guide",},
  {video: {url: `92601.mp4`},word: "Baggage",},
  {video: {url: `97087.mp4`},word: "Hand luggage",},
  {video: {url: `58403.mp4`},word: "Travel booking",},
  {video: {url: `92610.mp4`},word: "Booking",},
  {video: {url: `97103.mp4`},word: "Traveller cheque",},
  {video: {url: `90122.mp4`},word: "Travel documents",},
  {video: {url: `92736.mp4`},word: "Seat reservation ticket",},
  {video: {url: `92713.mp4`},word: "Package holiday",},
  {video: {url: `92733.mp4`},word: "Safari",},
  {video: {url: `74880.mp4`},word: "Bag",},
  {video: {url: `157909.mp4`},word: "Peak season",},
  {video: {url: `97088.mp4`},word: "Holiday representative",},
  {video: {url: `58429.mp4`},word: "Cancellation",},
  {video: {url: `92594.mp4`},word: "All inclusive holiday",},
  {video: {url: `97090.mp4`},word: "Hotel classification",},
  {video: {url: `89581.mp4`},word: "Cancellation insurance",},
  {video: {url: `92756.mp4`},word: "Touring centre",},
  {video: {url: `92705.mp4`},word: "Mountain-tour",},
  {video: {url: `90123.mp4`},word: "Travel guide",},
  {video: {url: `92759.mp4`},word: "Tourist office",},
  {video: {url: `51603.mp4`},word: "Outing",},
  {video: {url: `90071.mp4`},word: "Ticket purchase",},
  {video: {url: `90072.mp4`},word: "Ticket window",},
  {video: {url: `62556.mp4`},word: "Beach vacation",},
  {video: {url: `50881.mp4`},word: "Ticket",},
  {video: {url: `92715.mp4`},word: "Passport",},
  {video: {url: `89891.mp4`},word: "Pack",},
  {video: {url: `89848.mp4`},word: "Land",},
  {video: {url: `91412.mp4`},word: "Fly",},
  {video: {url: `92888.mp4`},word: "Missed flight",},
  {video: {url: `92735.mp4`},word: "Scheduled flight",},
  {video: {url: `92701.mp4`},word: "Long haul flight",},
  {video: {url: `92703.mp4`},word: "Luggage",},
  {video: {url: `92738.mp4`},word: "Security procedures",},
  {video: {url: `92718.mp4`},word: "Pre-bookable seat",},
  {video: {url: `92608.mp4`},word: "Boarding pass",},
  {video: {url: `49198.mp4`},word: "Plane",},
  {video: {url: `97087.mp4`},word: "Hand luggage",},
  {video: {url: `58413.mp4`},word: "Flight",},
  {video: {url: `93031.mp4`},word: "Flight attendant",},
  {video: {url: `97079.mp4`},word: "Aircraft",},
  {video: {url: `92874.mp4`},word: "Metal detector",},
  {video: {url: `97078.mp4`},word: "Air travel",},
  {video: {url: `58418.mp4`},word: "Economy class",},
  {video: {url: `118376.mp4`},word: "Air cabin crew",},
  {video: {url: `92626.mp4`},word: "Check-in",},
  {video: {url: `92593.mp4`},word: "Airline information screen",},
  {video: {url: `92657.mp4`},word: "Duty free",},
  {video: {url: `125936.mp4`},word: "Charter flight",},
  {video: {url: `92716.mp4`},word: "Passport control",},
  {video: {url: `92591.mp4`},word: "Air stewardess",},
  {video: {url: `92736.mp4`},word: "Seat reservation ticket",},
  {video: {url: `92652.mp4`},word: "Departure gate",},
  {video: {url: `92737.mp4`},word: "Security check",},
  {video: {url: `92592.mp4`},word: "Air ticket",},
  {video: {url: `191259.mp4`},word: "Moving walkway",},
  {video: {url: `62535.mp4`},word: "Baggage claim",},
  {video: {url: `72317.mp4`},word: "Airplane",},
  {video: {url: `58399.mp4`},word: "Airport",},
  {video: {url: `50881.mp4`},word: "Ticket",},
  {video: {url: `92612.mp4`},word: "Business class",},
  {video: {url: `125555.mp4`},word: "Broad-shouldered",},
  {video: {url: `124274.mp4`},word: "Beloved",},
  {video: {url: `110159.mp4`},word: "Initial",},
  {video: {url: `154549.mp4`},word: "Desirable",},
  {video: {url: `118330.mp4`},word: "Actual",},
  {video: {url: `157954.mp4`},word: "Potential",},
  {video: {url: `92786.mp4`},word: "Wet",},
  {video: {url: `63569.mp4`},word: "Real",},
  {video: {url: `62748.mp4`},word: "Redundant",},
  {video: {url: `49263.mp4`},word: "Quick",},
  {video: {url: `124290.mp4`},word: "Best",},
  {video: {url: `117017.mp4`},word: "Stationary",},
  {video: {url: `58433.mp4`},word: "Natural",},
  {video: {url: `116391.mp4`},word: "Premier",},
  {video: {url: `97008.mp4`},word: "Assorted",},
  {video: {url: `155700.mp4`},word: "Urban",},
  {video: {url: `89559.mp4`},word: "Static",},
  {video: {url: `91372.mp4`},word: "Backward",},
  {video: {url: `74984.mp4`},word: "Empty",},
  {video: {url: `185223.mp4`},word: "Personal",},
  {video: {url: `92954.mp4`},word: "Next",},
  {video: {url: `91355.mp4`},word: "Akin",},
  {video: {url: `51842.mp4`},word: "Anonymous",},
  {video: {url: `49168.mp4`},word: "Original",},
  {video: {url: `94052.mp4`},word: "Valid",},
  {video: {url: `118332.mp4`},word: "Acute",},
  {video: {url: `96115.mp4`},word: "Convenient",},
  {video: {url: `97109.mp4`},word: "Magnificent",},
  {video: {url: `93926.mp4`},word: "Delicate",},
  {video: {url: `51536.mp4`},word: "Brilliant",},
  {video: {url: `244387.mp4`},word: "Disgusting",},
  {video: {url: `122524.mp4`},word: "Approximate",},
  {video: {url: `97015.mp4`},word: "Authentic",},
  {video: {url: `244466.mp4`},word: "External",},
  {video: {url: `49264.mp4`},word: "Quiet",},
  {video: {url: `153310.mp4`},word: "Faultless",},
  {video: {url: `244323.mp4`},word: "Damaged",},
  {video: {url: `117025.mp4`},word: "Steep",},
  {video: {url: `118179.mp4`},word: "Acceptable",},
  {video: {url: `116388.mp4`},word: "Precise",},
  {video: {url: `87742.mp4`},word: "Coarse",},
  {video: {url: `51498.mp4`},word: "Fantastic",},
  {video: {url: `154550.mp4`},word: "Detached",},
  {video: {url: `63566.mp4`},word: "Private",},
  {video: {url: `125553.mp4`},word: "Brittle",},
  {video: {url: `96612.mp4`},word: "Inferior",},
  {video: {url: `244035.mp4`},word: "Artificial",},
  {video: {url: `89529.mp4`},word: "Intensive",},
  {video: {url: `58544.mp4`},word: "Open",},
  {video: {url: `91342.mp4`},word: "Abominable",},
  {video: {url: `65894.mp4`},word: "Objective",},
  {video: {url: `118319.mp4`},word: "Acquired",},
  {video: {url: `50811.mp4`},word: "Simple",},
  {video: {url: `96202.mp4`},word: "Destructive",},
  {video: {url: `51824.mp4`},word: "Certain",},
  {video: {url: `244616.mp4`},word: "Guilty",},
  {video: {url: `246109.mp4`},word: "Irreversible",},
  {video: {url: `58474.mp4`},word: "Soft",},
  {video: {url: `96627.mp4`},word: "Intense",},
  {video: {url: `153722.mp4`},word: "Slippery",},
  {video: {url: `91432.mp4`},word: "Great",},
  {video: {url: `154618.mp4`},word: "Flat",},
  {video: {url: `96324.mp4`},word: "Erect",},
  {video: {url: `92903.mp4`},word: "Moral",},
  {video: {url: `244307.mp4`},word: "Crucial",},
  {video: {url: `90007.mp4`},word: "Terrific",},
  {video: {url: `116409.mp4`},word: "Probable",},
  {video: {url: `244290.mp4`},word: "Corrected",},
  {video: {url: `92792.mp4`},word: "Wild",},
  {video: {url: `96264.mp4`},word: "Done",},
  {video: {url: `123109.mp4`},word: "Awful",},
  {video: {url: `118417.mp4`},word: "Alone",},
  {video: {url: `116323.mp4`},word: "Plain",},
  {video: {url: `51845.mp4`},word: "Compact",},
  {video: {url: `154720.mp4`},word: "Juicy",},
  {video: {url: `92764.mp4`},word: "Unusual",},
  {video: {url: `92663.mp4`},word: "Exotic",},
  {video: {url: `51102.mp4`},word: "Wrong",},
  {video: {url: `123106.mp4`},word: "Aware",},
  {video: {url: `96308.mp4`},word: "Eligible",},
  {video: {url: `93924.mp4`},word: "Definitive",},
  {video: {url: `116959.mp4`},word: "Specific",},
  {video: {url: `51336.mp4`},word: "Bare",},
  {video: {url: `91343.mp4`},word: "Absolute",},
  {video: {url: `96652.mp4`},word: "Limited",},
  {video: {url: `153323.mp4`},word: "Fresh",},
  {video: {url: `51075.mp4`},word: "Unexpected",},
  {video: {url: `96385.mp4`},word: "Floating",},
  {video: {url: `51936.mp4`},word: "Incredible",},
  {video: {url: `155536.mp4`},word: "Reversible",},
  {video: {url: `155582.mp4`},word: "Sequential",},
  {video: {url: `93063.mp4`},word: "Fun",},
  {video: {url: `93062.mp4`},word: "Frozen",},
  {video: {url: `49268.mp4`},word: "Ready",},
  {video: {url: `96227.mp4`},word: "Diffuse",},
  {video: {url: `116368.mp4`},word: "Popular",},
  {video: {url: `96378.mp4`},word: "Firm-solid",},
  {video: {url: `125554.mp4`},word: "Broad",},
  {video: {url: `51317.mp4`},word: "Available",},
  {video: {url: `51502.mp4`},word: "True",},
  {video: {url: `96502.mp4`},word: "Harsh",},
  {video: {url: `96566.mp4`},word: "Hollow",},
  {video: {url: `51906.mp4`},word: "Safe",},
  {video: {url: `92668.mp4`},word: "Fine",},
  {video: {url: `96918.mp4`},word: "Overalls",},
  {video: {url: `92993.mp4`},word: "Familiar",},
  {video: {url: `96973.mp4`},word: "Uniform",},
  {video: {url: `157976.mp4`},word: "Problematic",},
  {video: {url: `116949.mp4`},word: "Sole",},
  {video: {url: `92941.mp4`},word: "Necessary",},
  {video: {url: `153314.mp4`},word: "Finished",},
  {video: {url: `116213.mp4`},word: "Parallel",},
  {video: {url: `116387.mp4`},word: "Precious",},
  {video: {url: `244253.mp4`},word: "Comprehensive",},
  {video: {url: `116371.mp4`},word: "Portable",},
  {video: {url: `157875.mp4`},word: "Offensive",},
  {video: {url: `91415.mp4`},word: "Formal",},
  {video: {url: `93253.mp4`},word: "Correct",},
  {video: {url: `96345.mp4`},word: "Exclusive",},
  {video: {url: `94177.mp4`},word: "Voluntary",},
  {video: {url: `96954.mp4`},word: "Mutual",},
  {video: {url: `96602.mp4`},word: "Immaculate",},
  {video: {url: `96968.mp4`},word: "Tremendous",},
  {video: {url: `244575.mp4`},word: "Glossy",},
  {video: {url: `154698.mp4`},word: "Iconic",},
  {video: {url: `117122.mp4`},word: "Swell",},
  {video: {url: `154423.mp4`},word: "Conceptual",},
  {video: {url: `244840.mp4`},word: "Pure",},
  {video: {url: `89869.mp4`},word: "Obvious",},
  {video: {url: `116426.mp4`},word: "Psychedelic",},
  {video: {url: `116425.mp4`},word: "Proximal",},
  {video: {url: `90206.mp4`},word: "Waterproof",},
  {video: {url: `125561.mp4`},word: "Broken",},
  {video: {url: `58584.mp4`},word: "Worse",},
  {video: {url: `58585.mp4`},word: "Worst",},
  {video: {url: `74985.mp4`},word: "Entire, whole",},
  {video: {url: `50788.mp4`},word: "Secret",},
  {video: {url: `116891.mp4`},word: "Sizzling hot",},
  {video: {url: `246059.mp4`},word: "Liquid",},
  {video: {url: `245334.mp4`},word: "Sculptural",},
  {video: {url: `89807.mp4`},word: "Invisible",},
  {video: {url: `63598.mp4`},word: "Underground",},
  {video: {url: `96429.mp4`},word: "Grand",},
  {video: {url: `154715.mp4`},word: "Irregular",},
  {video: {url: `58469.mp4`},word: "Normal",},
  {video: {url: `58461.mp4`},word: "Hard",},
  {video: {url: `96062.mp4`},word: "Cohesive",},
  {video: {url: `92990.mp4`},word: "Fake",},
  {video: {url: `125022.mp4`},word: "Bound",},
  {video: {url: `153322.mp4`},word: "Freestanding",},
  {video: {url: `90006.mp4`},word: "Terrible",},
  {video: {url: `93132.mp4`},word: "High",},
  {video: {url: `96394.mp4`},word: "Fortunate",},
  {video: {url: `50786.mp4`},word: "Serious",},
  {video: {url: `245962.mp4`},word: "More than",},
  {video: {url: `63605.mp4`},word: "Direct",},
  {video: {url: `91634.mp4`},word: "Smooth",},
  {video: {url: `96946.mp4`},word: "Mild",},
  {video: {url: `93158.mp4`},word: "Ideal",},
  {video: {url: `96332.mp4`},word: "Even",},
  {video: {url: `155625.mp4`},word: "Stable",},
  {video: {url: `96979.mp4`},word: "Usual",},
  {video: {url: `89963.mp4`},word: "Sure",},
  {video: {url: `94028.mp4`},word: "Solved",},
  {video: {url: `89587.mp4`},word: "Express",},
  {video: {url: `89953.mp4`},word: "Special",},
  {video: {url: `96081.mp4`},word: "Complex",},
  {video: {url: `93281.mp4`},word: "Deep",},
  {video: {url: `49159.mp4`},word: "Occupied",},
  {video: {url: `93906.mp4`},word: "Complete",},
  {video: {url: `93945.mp4`},word: "Distant",},
  {video: {url: `245975.mp4`},word: "Minimalist",},
  {video: {url: `50775.mp4`},word: "Same",},
  {video: {url: `74976.mp4`},word: "Drunk",},
  {video: {url: `116516.mp4`},word: "Relative",},
  {video: {url: `87721.mp4`},word: "Becoming",},
  {video: {url: `96309.mp4`},word: "Else",},
  {video: {url: `244772.mp4`},word: "Worthy",},
  {video: {url: `51949.mp4`},word: "Left-handed",},
  {video: {url: `58462.mp4`},word: "Hot",},
  {video: {url: `117078.mp4`},word: "Subtle",},
  {video: {url: `118174.mp4`},word: "Absurd",},
  {video: {url: `50762.mp4`},word: "Ridiculous",},
  {video: {url: `51367.mp4`},word: "Better",},
  {video: {url: `65774.mp4`},word: "Vague",},
  {video: {url: `153324.mp4`},word: "Ghastly",},
  {video: {url: `118523.mp4`},word: "Another",},
  {video: {url: `51138.mp4`},word: "Possible",},
  {video: {url: `51855.mp4`},word: "Impossible",},
  {video: {url: `155547.mp4`},word: "Rigid",},
  {video: {url: `118385.mp4`},word: "Airtight",},
  {video: {url: `153026.mp4`},word: "Abnormal",},
  {video: {url: `153035.mp4`},word: "Accurate",},
  {video: {url: `96336.mp4`},word: "Evident",},
  {video: {url: `154591.mp4`},word: "Experiential",},
  {video: {url: `93951.mp4`},word: "Double",},
  {video: {url: `89747.mp4`},word: "Shiny",},
  {video: {url: `58543.mp4`},word: "Only",},
  {video: {url: `155649.mp4`},word: "Sufficient",},
  {video: {url: `92832.mp4`},word: "Main",},
  {video: {url: `91397.mp4`},word: "Extreme",},
  {video: {url: `188142.mp4`},word: "Luscious",},
  {video: {url: `91350.mp4`},word: "Advanced",},
  {video: {url: `89509.mp4`},word: "Colourful",},
  {video: {url: `96397.mp4`},word: "Fragile",},
  {video: {url: `87769.mp4`},word: "Flexible",},
  {video: {url: `58531.mp4`},word: "Important",},
  {video: {url: `246066.mp4`},word: "Likely",},
  {video: {url: `158036.mp4`},word: "Renewed",},
  {video: {url: `158033.mp4`},word: "Remade",},
  {video: {url: `51956.mp4`},word: "Messy",},
  {video: {url: `116522.mp4`},word: "Remote",},
  {video: {url: `122521.mp4`},word: "Appropriate",},
  {video: {url: `123148.mp4`},word: "Bad",},
  {video: {url: `92790.mp4`},word: "Whole",},
  {video: {url: `89986.mp4`},word: "Tardy",},
  {video: {url: `117006.mp4`},word: "Stale",},
  {video: {url: `157957.mp4`},word: "Practical",},
  {video: {url: `50810.mp4`},word: "Similar",},
  {video: {url: `50801.mp4`},word: "Common",},
  {video: {url: `93364.mp4`},word: "Do you take credit cards?",},
  {video: {url: `93366.mp4`},word: "Do you want a diet or regular drink?",},
  {video: {url: `90024.mp4`},word: "Can we reserve a table?",},
  {video: {url: `93373.mp4`},word: "Do you want your eggs scrambled dry?",},
  {video: {url: `93374.mp4`},word: "Do you want your eggs scrambled wet?",},
  {video: {url: `93370.mp4`},word: "Do you want mustard, mayo, or both?",},
  {video: {url: `93371.mp4`},word: "Do you want sweet or unsweet tea?",},
  {video: {url: `73449.mp4`},word: "How much does it cost?",},
  {video: {url: `90063.mp4`},word: "Do you have a map of the city?",},
  {video: {url: `93345.mp4`},word: "Do you have any brochures?",},
  {video: {url: `93353.mp4`},word: "Do you have your ticket?",},
  {video: {url: `89933.mp4`},word: "Are your bags packed?",},
  {video: {url: `90028.mp4`},word: "Can you drive?",},
  {video: {url: `109553.mp4`},word: "Can you show me on a map?",},
  {video: {url: `90033.mp4`},word: "Can you wait there?",},
  {video: {url: `93887.mp4`},word: "What would you like to order?",},
  {video: {url: `73432.mp4`},word: "Do you have a boy friend?",},
  {video: {url: `73437.mp4`},word: "Do you want a beer?",},
  {video: {url: `93888.mp4`},word: "What would you recommend?",},
  {video: {url: `93357.mp4`},word: "Do you know how to use a stick-shift?",},
  {video: {url: `93368.mp4`},word: "Do you want me to interpret the sermon?",},
  {video: {url: `93870.mp4`},word: "What is the soup of the day?",},
  {video: {url: `93862.mp4`},word: "What can i get for you?",},
  {video: {url: `91251.mp4`},word: "Who is that man?",},
  {video: {url: `118581.mp4`},word: "Why do you say that?",},
  {video: {url: `91224.mp4`},word: "Where do you come from?",},
  {video: {url: `118580.mp4`},word: "Who?",},
  {video: {url: `93899.mp4`},word: "Where are your boyfriend?",},
  {video: {url: `91225.mp4`},word: "Where do you live?",},
  {video: {url: `93893.mp4`},word: "When does it open?",},
  {video: {url: `73452.mp4`},word: "How would you like it?",},
  {video: {url: `93858.mp4`},word: "What are the benefits?",},
  {video: {url: `93880.mp4`},word: "What kind of work do you do?",},
  {video: {url: `91226.mp4`},word: "Where do you work?",},
  {video: {url: `93359.mp4`},word: "Do you speak english?",},
  {video: {url: `93396.mp4`},word: "Has the snow melted?",},
  {video: {url: `93404.mp4`},word: "Have you ever been in an earthquake?",},
  {video: {url: `93436.mp4`},word: "How much is it per night?",},
  {video: {url: `93429.mp4`},word: "How long are you staying?",},
  {video: {url: `93665.mp4`},word: "Is somebody meeting you?",},
  {video: {url: `93361.mp4`},word: "Do you speak german?",},
  {video: {url: `93360.mp4`},word: "Do you speak french?",},
  {video: {url: `93363.mp4`},word: "Do you speak spanish?",},
  {video: {url: `93362.mp4`},word: "Do you speak italian?",},
  {video: {url: `93402.mp4`},word: "Have you checked your luggage?",},
  {video: {url: `93401.mp4`},word: "Have you bought your ticket?",},
  {video: {url: `93427.mp4`},word: "How far is it?",},
  {video: {url: `93405.mp4`},word: "Have you ever been to japan?",},
  {video: {url: `73523.mp4`},word: "Why not?",},
  {video: {url: `89975.mp4`},word: "Can i take your order?",},
  {video: {url: `89919.mp4`},word: "Anything for dessert?",},
  {video: {url: `91265.mp4`},word: "Would you like to add cheese?",},
  {video: {url: `91245.mp4`},word: "Where were you born?",},
  {video: {url: `91239.mp4`},word: "Where is the restroom?",},
  {video: {url: `91237.mp4`},word: "Where is the post office?",},
  {video: {url: `118579.mp4`},word: "Where is my palm pilot?",},
  {video: {url: `90058.mp4`},word: "Do you belong to the pta?",},
  {video: {url: `93730.mp4`},word: "May i see your ticket please?",},
  {video: {url: `91232.mp4`},word: "Where is the administration building?",},
  {video: {url: `90054.mp4`},word: "Did you receive a notification to appear in court?",},
  {video: {url: `93864.mp4`},word: "What courses are you taking this semester?",},
  {video: {url: `51825.mp4`},word: "Can i park here?",},
  {video: {url: `89935.mp4`},word: "Are your parents deaf?",},
  {video: {url: `73450.mp4`},word: "How much is it?",},
  {video: {url: `91267.mp4`},word: "Would you like to upsize?",},
  {video: {url: `91263.mp4`},word: "Would you like fries with that order?",},
  {video: {url: `91264.mp4`},word: "Would you like ketchup?",},
  {video: {url: `153583.mp4`},word: "How?",},
  {video: {url: `153582.mp4`},word: "How many?",},
  {video: {url: `93430.mp4`},word: "How many children are coming?",},
  {video: {url: `73447.mp4`},word: "How did you become deaf?",},
  {video: {url: `118574.mp4`},word: "What are you wearing tonight?",},
  {video: {url: `91230.mp4`},word: "Where is mcdonalds?",},
  {video: {url: `93724.mp4`},word: "May i have you business card?",},
  {video: {url: `93897.mp4`},word: "Where are the dressing rooms?",},
  {video: {url: `91233.mp4`},word: "Where is the cash register?",},
  {video: {url: `93620.mp4`},word: "I voted, did you?",},
  {video: {url: `93861.mp4`},word: "What are your symptoms?",},
  {video: {url: `91256.mp4`},word: "Will you be dining in today?",},
  {video: {url: `93720.mp4`},word: "May i borrow your car?",},
  {video: {url: `91255.mp4`},word: "Will that be take-out or dine-in?",},
  {video: {url: `93898.mp4`},word: "Where are you going?",},
  {video: {url: `91231.mp4`},word: "Where is my calculator?",},
  {video: {url: `93884.mp4`},word: "What time does the train leave?",},
  {video: {url: `93873.mp4`},word: "What is your adress?",},
  {video: {url: `93875.mp4`},word: "What is your name?",},
  {video: {url: `109929.mp4`},word: "How much did you pay?",},
  {video: {url: `91254.mp4`},word: "Who won the election?",},
  {video: {url: `91252.mp4`},word: "Who is the new president?",},
  {video: {url: `90065.mp4`},word: "Do you have a pet?",},
  {video: {url: `90056.mp4`},word: "Do you allow animals?",},
  {video: {url: `93432.mp4`},word: "How much does he owe?",},
  {video: {url: `73435.mp4`},word: "Do you have more of this?",},
  {video: {url: `93365.mp4`},word: "Do you use a hearing aid?",},
  {video: {url: `93354.mp4`},word: "Do you hear music?",},
  {video: {url: `90031.mp4`},word: "Can you read lips?",},
  {video: {url: `93439.mp4`},word: "How old were you when you became deaf?",},
  {video: {url: `93425.mp4`},word: "How did you lose your hearing?",},
  {video: {url: `93424.mp4`},word: "How did you get interested in signing?",},
  {video: {url: `93437.mp4`},word: "How much?",},
  {video: {url: `93343.mp4`},word: "Do you have a savings plan?",},
  {video: {url: `90059.mp4`},word: "Do you feel all right?",},
  {video: {url: `93349.mp4`},word: "Do you have hospitalization insurance?",},
  {video: {url: `93344.mp4`},word: "Do you have any aspirin?",},
  {video: {url: `118573.mp4`},word: "What are you going to order?",},
  {video: {url: `93860.mp4`},word: "What are today's specials?",},
  {video: {url: `90050.mp4`},word: "Did you ask him?",},
  {video: {url: `90061.mp4`},word: "Do you have a car?",},
  {video: {url: `93346.mp4`},word: "Do you have any children?",},
  {video: {url: `93358.mp4`},word: "Do you like to watch tv?",},
  {video: {url: `89929.mp4`},word: "Are you married?",},
  {video: {url: `93868.mp4`},word: "What is the church address?",},
  {video: {url: `93869.mp4`},word: "What is the church phone number?",},
  {video: {url: `153779.mp4`},word: "What kind of service do you have?",},
  {video: {url: `93886.mp4`},word: "What times are the services?",},
  {video: {url: `91227.mp4`},word: "Where does sunday school meet?",},
  {video: {url: `90026.mp4`},word: "Can you change a five?",},
  {video: {url: `115235.mp4`},word: "Do you want a soft drink?",},
  {video: {url: `93369.mp4`},word: "Do you want milk and sugar?",},
  {video: {url: `91221.mp4`},word: "Where can i rent a car?",},
  {video: {url: `91222.mp4`},word: "Where do i buy tickets?",},
  {video: {url: `73519.mp4`},word: "Where is the toilet",},
  {video: {url: `93894.mp4`},word: "When is checkout?",},
  {video: {url: `93882.mp4`},word: "What time does the bus arrive?",},
  {video: {url: `93883.mp4`},word: "What time does the plane takeoff?",},
  {video: {url: `73515.mp4`},word: "When do you open?",},
  {video: {url: `91290.mp4`},word: "Can i use the toilet?",},
  {video: {url: `91235.mp4`},word: "Where is the museum?",},
  {video: {url: `91241.mp4`},word: "Where is the subway?",},
  {video: {url: `93355.mp4`},word: "Do you know a good barber shop?",},
  {video: {url: `91223.mp4`},word: "Where do i vote?",},
  {video: {url: `91248.mp4`},word: "Which city is the capital?",},
  {video: {url: `93865.mp4`},word: "What do you think of the president?",},
  {video: {url: `90066.mp4`},word: "Do you have a quarter?",},
  {video: {url: `89923.mp4`},word: "Are you deaf?",},
  {video: {url: `90051.mp4`},word: "Did you bring the dessert?",},
  {video: {url: `109693.mp4`},word: "Did you eat?",},
  {video: {url: `90055.mp4`},word: "Did you see the woman?",},
  {video: {url: `93729.mp4`},word: "May i introduce my wife?",},
  {video: {url: `93342.mp4`},word: "Do you have a raincoat?",},
  {video: {url: `109751.mp4`},word: "Do you want cream and sugar?",},
  {video: {url: `91243.mp4`},word: "Where is the tourist information office?",},
  {video: {url: `91242.mp4`},word: "Where is the teater?",},
  {video: {url: `118586.mp4`},word: "Will you check the oil?",},
  {video: {url: `91246.mp4`},word: "Which airline are you taking?",},
  {video: {url: `91261.mp4`},word: "Would you call me at 6:00 a.m.?",},
  {video: {url: `91260.mp4`},word: "Would you call me a cab, please?",},
  {video: {url: `50903.mp4`},word: "What time",},
  {video: {url: `91262.mp4`},word: "Would you like a magazine or newspaper?",},
  {video: {url: `90057.mp4`},word: "Do you bank online?",},
  {video: {url: `89931.mp4`},word: "Are you still using dial-up?",},
  {video: {url: `89920.mp4`},word: "Are you a christian?",},
  {video: {url: `91266.mp4`},word: "Would you like to dance?",},
  {video: {url: `93433.mp4`},word: "How much does it cost to get in?",},
  {video: {url: `93434.mp4`},word: "How much does the book cost?",},
  {video: {url: `91219.mp4`},word: "Where can i find a veterinarian?",},
  {video: {url: `93876.mp4`},word: "What is your pet's name?",},
  {video: {url: `91236.mp4`},word: "Where is the pet store?",},
  {video: {url: `91234.mp4`},word: "Where is the local spca?",},
  {video: {url: `93728.mp4`},word: "May i introduce my son?",},
  {video: {url: `91244.mp4`},word: "Where is the zoo?",},
  {video: {url: `93727.mp4`},word: "May i introduce my friend?",},
  {video: {url: `93731.mp4`},word: "May i introduce my husband?",},
  {video: {url: `73426.mp4`},word: "Could you take my luggage up to my room/to the taxi?",},
  {video: {url: `73520.mp4`},word: "Where's the nearest lift?",},
  {video: {url: `93350.mp4`},word: "Do you have interpreted services?",},
  {video: {url: `90062.mp4`},word: "Do you have a dime?",},
  {video: {url: `73484.mp4`},word: "Is it true?",},
  {video: {url: `73481.mp4`},word: "I'm hard of hearing. could you speak up a bit?",},
  {video: {url: `73428.mp4`},word: "Could you write that down for me?",},
  {video: {url: `73516.mp4`},word: "When does school start?",},
  {video: {url: `93431.mp4`},word: "How many children do you have?",},
  {video: {url: `153581.mp4`},word: "How do you sign?",},
  {video: {url: `93403.mp4`},word: "Have you eaten?",},
  {video: {url: `73510.mp4`},word: "What\u00b4s your name?",},
  {video: {url: `93392.mp4`},word: "Has anyone seen my pager?",},
  {video: {url: `93372.mp4`},word: "Do you want to go the movies?",},
  {video: {url: `73448.mp4`},word: "How do i get there?",},
  {video: {url: `93669.mp4`},word: "Is there a children's menu?",},
  {video: {url: `93663.mp4`},word: "Is breakfast included?",},
  {video: {url: `91269.mp4`},word: "Would you write it please?",},
  {video: {url: `73511.mp4`},word: "What\u00b4s your sign?",},
  {video: {url: `91268.mp4`},word: "Would you take our picture?",},
  {video: {url: `118582.mp4`},word: "Why do you want to learn signing?",},
  {video: {url: `90064.mp4`},word: "Do you have a nickel?",},
  {video: {url: `63600.mp4`},word: "Where to",},
  {video: {url: `153784.mp4`},word: "Why?",},
  {video: {url: `91229.mp4`},word: "Where does this trail lead?",},
  {video: {url: `93670.mp4`},word: "Is there a youth group?",},
  {video: {url: `93866.mp4`},word: "What does it eat?",},
  {video: {url: `90060.mp4`},word: "Do you have a tty?",},
  {video: {url: `109739.mp4`},word: "Do you know how to make a photo cd?",},
  {video: {url: `93394.mp4`},word: "Has he had his shots?",},
  {video: {url: `93395.mp4`},word: "Has she been spayed?",},
  {video: {url: `91253.mp4`},word: "Who took my...?",},
  {video: {url: `93722.mp4`},word: "May i go with you?",},
  {video: {url: `93438.mp4`},word: "How often should i feed it?",},
  {video: {url: `93871.mp4`},word: "What is the temperature?",},
  {video: {url: `93723.mp4`},word: "May i have a menu, please?",},
  {video: {url: `73521.mp4`},word: "Why did you do this?",},
  {video: {url: `93859.mp4`},word: "What are these charges for?",},
  {video: {url: `153341.mp4`},word: "Have you been to the gynaecologist?",},
  {video: {url: `73525.mp4`},word: "You are pregnant with who?",},
  {video: {url: `93664.mp4`},word: "Is it broken?",},
  {video: {url: `153778.mp4`},word: "What do you think?",},
  {video: {url: `91284.mp4`},word: "Your socks do not match?",},
  {video: {url: `109703.mp4`},word: "Do you go to school?",},
  {video: {url: `90052.mp4`},word: "Did you pass or fail?",},
  {video: {url: `93347.mp4`},word: "Do you have any dirty clothes?",},
  {video: {url: `90032.mp4`},word: "Can you sew on a button for me?",},
  {video: {url: `90029.mp4`},word: "Can you get this stain out?",},
  {video: {url: `93863.mp4`},word: "What color do you plan to paint the walls?",},
  {video: {url: `93867.mp4`},word: "What does that mean?",},
  {video: {url: `93400.mp4`},word: "Have you been baptized?",},
  {video: {url: `73524.mp4`},word: "Would you like a glass of wine?",},
  {video: {url: `93879.mp4`},word: "What kind of tree is this?",},
  {video: {url: `153275.mp4`},word: "Did you understand?",},
  {video: {url: `153780.mp4`},word: "When?",},
  {video: {url: `73423.mp4`},word: "Could you draw me a little map?",},
  {video: {url: `93393.mp4`},word: "Has he been neutered?",},
  {video: {url: `153781.mp4`},word: "Where?",},
  {video: {url: `91259.mp4`},word: "Would that be large, medium or small?",},
  {video: {url: `93375.mp4`},word: "Does it bite?",},
  {video: {url: `93668.mp4`},word: "Is the tip included?",},
  {video: {url: `50904.mp4`},word: "Where are you from?",},
  {video: {url: `93666.mp4`},word: "Is that enough for you?",},
  {video: {url: `93435.mp4`},word: "How much does the position pay?",},
  {video: {url: `89973.mp4`},word: "Can i help you?",},
  {video: {url: `93721.mp4`},word: "May i borrow your hairdryer?",},
  {video: {url: `91247.mp4`},word: "Which church do you belong to?",},
  {video: {url: `93881.mp4`},word: "What salad dressing would you like?",},
  {video: {url: `93352.mp4`},word: "Do you have plans for labor day?",},
  {video: {url: `91217.mp4`},word: "Where can i find a doctor?",},
  {video: {url: `93872.mp4`},word: "What is this prescription for?",},
  {video: {url: `91228.mp4`},word: "Where does it hurt?",},
  {video: {url: `91218.mp4`},word: "Where can i find a hospital?",},
  {video: {url: `118594.mp4`},word: "Would you care for a refill?",},
  {video: {url: `93857.mp4`},word: "Were any bones broken?",},
  {video: {url: `93351.mp4`},word: "Do you have it in black?",},
  {video: {url: `89918.mp4`},word: "Any questions?",},
  {video: {url: `93356.mp4`},word: "Do you know a good beauty shop?",},
  {video: {url: `73485.mp4`},word: "Is this the road to the town?",},
  {video: {url: `89928.mp4`},word: "Are you hungry?",},
  {video: {url: `91240.mp4`},word: "Where is the server?",},
  {video: {url: `89922.mp4`},word: "Are you a member of the church?",},
  {video: {url: `89921.mp4`},word: "Are you a roman catholic or a protestant?",},
  {video: {url: `91249.mp4`},word: "Who are you?",},
  {video: {url: `73422.mp4`},word: "Could you book me in?",},
  {video: {url: `73436.mp4`},word: "Do you know where i can find a room here?",},
  {video: {url: `73421.mp4`},word: "Can you recommend reasonably priced hotel?",},
  {video: {url: `91257.mp4`},word: "With or without onions?",},
  {video: {url: `73434.mp4`},word: "Do you have a room for me?",},
  {video: {url: `73483.mp4`},word: "Is it far from here?",},
  {video: {url: `73513.mp4`},word: "What\u2019s the matter with you?",},
  {video: {url: `93348.mp4`},word: "Do you have any openings?",},
  {video: {url: `73517.mp4`},word: "When will we meet?",},
  {video: {url: `91287.mp4`},word: "Are you studying or are you working?",},
  {video: {url: `93726.mp4`},word: "May i introduce my daughter?",},
  {video: {url: `93667.mp4`},word: "Is the dishwasher full?",},
  {video: {url: `93892.mp4`},word: "When does it close?",},
  {video: {url: `93885.mp4`},word: "What time is it?",},
  {video: {url: `93874.mp4`},word: "What is your cellphone number?",},
  {video: {url: `109914.mp4`},word: "Have you ever had a tooth pulled?",},
  {video: {url: `93890.mp4`},word: "What\u00b4s your address?",},
  {video: {url: `89927.mp4`},word: "Are you going to the town council meeting?",},
  {video: {url: `91258.mp4`},word: "With or without pickles?",},
  {video: {url: `93877.mp4`},word: "What is your phone number?",},
  {video: {url: `93878.mp4`},word: "What kind of job do you do?",},
  {video: {url: `93889.mp4`},word: "What?",},
  {video: {url: `91250.mp4`},word: "Who does your taxes?",},
  {video: {url: `91220.mp4`},word: "Where can i find an atm?",},
  {video: {url: `94228.mp4`},word: "Fingerspelling",},
  {video: {url: `93966.mp4`},word: "Manual alphabet",},
  {video: {url: `244362.mp4`},word: "Directional sign",},
  {video: {url: `50926.mp4`},word: "Sign language",},
  {video: {url: `244379.mp4`},word: "Downward",},
  {video: {url: `244380.mp4`},word: "Down",},
  {video: {url: `244255.mp4`},word: "Compound sign",},
  {video: {url: `244269.mp4`},word: "Contact",},
  {video: {url: `244202.mp4`},word: "Classifier",},
  {video: {url: `94343.mp4`},word: "Sign language interpreter",},
  {video: {url: `244071.mp4`},word: "Basic signs",},
  {video: {url: `243942.mp4`},word: "International sign",},
  {video: {url: `244113.mp4`},word: "Brow raise",},
  {video: {url: `244352.mp4`},word: "Deaf humour",},
  {video: {url: `94345.mp4`},word: "Sign-to-voice interpreting",},
  {video: {url: `94344.mp4`},word: "Sign language training for parents",},
  {video: {url: `244073.mp4`},word: "Basic handshapes",},
  {video: {url: `244053.mp4`},word: "Back of a hand",},
  {video: {url: `116332.mp4`},word: "Plural",},
  {video: {url: `65901.mp4`},word: "Preposition",},
  {video: {url: `90023.mp4`},word: "This",},
  {video: {url: `154540.mp4`},word: "Decline",},
  {video: {url: `93909.mp4`},word: "Conjunction",},
  {video: {url: `93193.mp4`},word: "Aspect",},
  {video: {url: `245916.mp4`},word: "Noun phrase",},
  {video: {url: `155671.mp4`},word: "Tense",},
  {video: {url: `155660.mp4`},word: "Syntax",},
  {video: {url: `65902.mp4`},word: "Proper noun",},
  {video: {url: `116390.mp4`},word: "Prefix",},
  {video: {url: `153682.mp4`},word: "Pronoun",},
  {video: {url: `49004.mp4`},word: "Noun",},
  {video: {url: `245045.mp4`},word: "Verbal tense",},
  {video: {url: `117126.mp4`},word: "Synonym",},
  {video: {url: `245823.mp4`},word: "Person",},
  {video: {url: `245322.mp4`},word: "Semantic field",},
  {video: {url: `117083.mp4`},word: "Suffix",},
  {video: {url: `245844.mp4`},word: "Passive voice",},
  {video: {url: `93993.mp4`},word: "Possessive pronoun",},
  {video: {url: `50924.mp4`},word: "Verb",},
  {video: {url: `244984.mp4`},word: "Word family",},
  {video: {url: `65873.mp4`},word: "Common noun",},
  {video: {url: `94205.mp4`},word: "Comparative",},
  {video: {url: `153664.mp4`},word: "Personal pronoun",},
  {video: {url: `47846.mp4`},word: "Grammar",},
  {video: {url: `245319.mp4`},word: "Sentence connectors",},
  {video: {url: `189612.mp4`},word: "Inflection",},
  {video: {url: `118352.mp4`},word: "Adverb",},
  {video: {url: `118339.mp4`},word: "Adjective",},
  {video: {url: `191450.mp4`},word: "Predicate",},
  {video: {url: `157903.mp4`},word: "Part of sentence",},
  {video: {url: `155597.mp4`},word: "Simple sentence",},
  {video: {url: `50784.mp4`},word: "Sentence",},
  {video: {url: `155725.mp4`},word: "Word order",},
  {video: {url: `90048.mp4`},word: "Come visit me sometime",},
  {video: {url: `93708.mp4`},word: "Judaism is an old religion.",},
  {video: {url: `93703.mp4`},word: "Jewish people go to the temple on the sabbath.",},
  {video: {url: `93524.mp4`},word: "I go to church every sunday.",},
  {video: {url: `93473.mp4`},word: "I am just visiting.",},
  {video: {url: `93650.mp4`},word: "I would like to be part of the music ministry",},
  {video: {url: `109918.mp4`},word: "He used to be a preacher",},
  {video: {url: `93412.mp4`},word: "He is an atheist.",},
  {video: {url: `116791.mp4`},word: "Saved by the bell",},
  {video: {url: `93737.mp4`},word: "My bird is ill.",},
  {video: {url: `93808.mp4`},word: "That's a pretty animal.",},
  {video: {url: `93593.mp4`},word: "I need a dentist.",},
  {video: {url: `93565.mp4`},word: "I have to take pills.",},
  {video: {url: `93551.mp4`},word: "I have caught a cold.",},
  {video: {url: `93548.mp4`},word: "I have a toothache.",},
  {video: {url: `93547.mp4`},word: "I have a stomachache.",},
  {video: {url: `93546.mp4`},word: "I have a sore throat.",},
  {video: {url: `93560.mp4`},word: "I have to buy some medicine.",},
  {video: {url: `93558.mp4`},word: "I have run out of medicine.",},
  {video: {url: `93552.mp4`},word: "I have lost my glasses.",},
  {video: {url: `109935.mp4`},word: "I have lost a filling.",},
  {video: {url: `93411.mp4`},word: "He is a senator.",},
  {video: {url: `90049.mp4`},word: "Congress is responsible for passing laws.",},
  {video: {url: `89965.mp4`},word: "Boy scouts is a good organization.",},
  {video: {url: `158141.mp4`},word: "He is an social security.",},
  {video: {url: `93447.mp4`},word: "I am a democrat.",},
  {video: {url: `93891.mp4`},word: "When i took my coat to the cleaners it shrunk.",},
  {video: {url: `93807.mp4`},word: "That shirt and tie don't match.",},
  {video: {url: `93827.mp4`},word: "The shirt fits well.",},
  {video: {url: `93790.mp4`},word: "Shirt and shoes are required",},
  {video: {url: `93805.mp4`},word: "That dress is an odd color.",},
  {video: {url: `93756.mp4`},word: "My pants are ripped.",},
  {video: {url: `93759.mp4`},word: "My size is small.",},
  {video: {url: `93526.mp4`},word: "I got a 'a' on my paper.",},
  {video: {url: `93578.mp4`},word: "I like to complete my work early.",},
  {video: {url: `73438.mp4`},word: "Follow me",},
  {video: {url: `153698.mp4`},word: "Remind yourself!",},
  {video: {url: `153699.mp4`},word: "Repeat!",},
  {video: {url: `153690.mp4`},word: "Read!",},
  {video: {url: `73455.mp4`},word: "I am not deaf",},
  {video: {url: `73478.mp4`},word: "I want this",},
  {video: {url: `73463.mp4`},word: "I do not like this",},
  {video: {url: `73505.mp4`},word: "This is not important",},
  {video: {url: `73506.mp4`},word: "This is very important",},
  {video: {url: `73456.mp4`},word: "I am not hearing",},
  {video: {url: `73454.mp4`},word: "I am afraid",},
  {video: {url: `93498.mp4`},word: "I am taking asl classes.",},
  {video: {url: `93499.mp4`},word: "I am taking see classes.",},
  {video: {url: `93687.mp4`},word: "It will freeze tonight.",},
  {video: {url: `93747.mp4`},word: "My head aches.",},
  {video: {url: `93748.mp4`},word: "My husband had an operation.",},
  {video: {url: `93573.mp4`},word: "I like winter best.",},
  {video: {url: `93579.mp4`},word: "I like to sit on the beach.",},
  {video: {url: `93572.mp4`},word: "I like summer best.",},
  {video: {url: `93571.mp4`},word: "I like spring best.",},
  {video: {url: `93681.mp4`},word: "It is snowing.",},
  {video: {url: `93680.mp4`},word: "It is raining.",},
  {video: {url: `93676.mp4`},word: "It is beautiful today.",},
  {video: {url: `93609.mp4`},word: "I planted the seeds last fall.",},
  {video: {url: `93684.mp4`},word: "It rained yesterday.",},
  {video: {url: `93683.mp4`},word: "It is windy today.",},
  {video: {url: `93755.mp4`},word: "My nose is runny.",},
  {video: {url: `93760.mp4`},word: "My stomach is upset.",},
  {video: {url: `93616.mp4`},word: "I studied all night.",},
  {video: {url: `93580.mp4`},word: "I like to study.",},
  {video: {url: `93629.mp4`},word: "I want to visit the deaf club.",},
  {video: {url: `93614.mp4`},word: "I speak a little bit.",},
  {video: {url: `93633.mp4`},word: "I went to a school for hearing children.",},
  {video: {url: `93631.mp4`},word: "I was born deaf.",},
  {video: {url: `93758.mp4`},word: "My sign language has already improved.",},
  {video: {url: `93718.mp4`},word: "Many deaf students enter hearing colleges.",},
  {video: {url: `93791.mp4`},word: "Sign slowly, please",},
  {video: {url: `93635.mp4`},word: "I will be back soon.",},
  {video: {url: `93836.mp4`},word: "There is no sign for that you have to fingerspell it.",},
  {video: {url: `93792.mp4`},word: "Signed music is so beautiful",},
  {video: {url: `93492.mp4`},word: "I am majoring in special education.",},
  {video: {url: `93493.mp4`},word: "I am majoring in theater.",},
  {video: {url: `93675.mp4`},word: "It is ok.",},
  {video: {url: `93763.mp4`},word: "My wife is dead.",},
  {video: {url: `93485.mp4`},word: "I am majoring in history.",},
  {video: {url: `93486.mp4`},word: "I am majoring in home economics",},
  {video: {url: `93487.mp4`},word: "I am majoring in math.",},
  {video: {url: `93488.mp4`},word: "I am majoring in music.",},
  {video: {url: `109931.mp4`},word: "I am majoring in philosophy.",},
  {video: {url: `93489.mp4`},word: "I am majoring in physical therapy.",},
  {video: {url: `93490.mp4`},word: "I am majoring in psychology.",},
  {video: {url: `93491.mp4`},word: "I am majoring in science.",},
  {video: {url: `93534.mp4`},word: "I graduated last year.",},
  {video: {url: `93527.mp4`},word: "I got a 'b' on my paper.",},
  {video: {url: `93626.mp4`},word: "I want to brush my teeth.",},
  {video: {url: `93529.mp4`},word: "I got a 'd' on my paper.",},
  {video: {url: `93528.mp4`},word: "I got a 'c' on my paper.",},
  {video: {url: `153797.mp4`},word: "Write!",},
  {video: {url: `117130.mp4`},word: "The reds and blues of the sunset are beautiful",},
  {video: {url: `93688.mp4`},word: "It's a boy!",},
  {video: {url: `93689.mp4`},word: "It's a girl!",},
  {video: {url: `93509.mp4`},word: "I do not have a floppy drive.",},
  {video: {url: `93532.mp4`},word: "I got an email from my mother today.",},
  {video: {url: `93842.mp4`},word: "Tiger's are dangerous animals.",},
  {video: {url: `93538.mp4`},word: "I have a dsl connection.",},
  {video: {url: `93445.mp4`},word: "I am a senior.",},
  {video: {url: `93465.mp4`},word: "I am doing an employment search.",},
  {video: {url: `93496.mp4`},word: "I am studying to be a network engineer.",},
  {video: {url: `93413.mp4`},word: "He looks mean.",},
  {video: {url: `93418.mp4`},word: "His fur is so soft.",},
  {video: {url: `93607.mp4`},word: "I painted my house gray.",},
  {video: {url: `93543.mp4`},word: "I have a new photo editing program.",},
  {video: {url: `93576.mp4`},word: "I like online shopping.",},
  {video: {url: `93541.mp4`},word: "I have a dog.",},
  {video: {url: `93804.mp4`},word: "Thank you for dining with us.",},
  {video: {url: `93785.mp4`},word: "Salt and pepper are on the table.",},
  {video: {url: `118402.mp4`},word: "All done",},
  {video: {url: `118403.mp4`},word: "All gone",},
  {video: {url: `93819.mp4`},word: "The food is delicious.",},
  {video: {url: `93815.mp4`},word: "The coke is in the refrigerator.",},
  {video: {url: `117128.mp4`},word: "The cake is in the oven",},
  {video: {url: `93813.mp4`},word: "The bacon is overdone.",},
  {video: {url: `93826.mp4`},word: "The service is lousy.",},
  {video: {url: `93671.mp4`},word: "It hurts here.",},
  {video: {url: `93594.mp4`},word: "I need a doctor.",},
  {video: {url: `93632.mp4`},word: "I was in bed for two weeks.",},
  {video: {url: `93603.mp4`},word: "I need to send an email",},
  {video: {url: `93599.mp4`},word: "I need to brush my teeth",},
  {video: {url: `93691.mp4`},word: "It's time to take your temperature.",},
  {video: {url: `93742.mp4`},word: "My father passed away last month.",},
  {video: {url: `93582.mp4`},word: "I lost my book",},
  {video: {url: `93592.mp4`},word: "I need a cold drink",},
  {video: {url: `93585.mp4`},word: "I lost my umbrella",},
  {video: {url: `93701.mp4`},word: "I\u00b4m tired",},
  {video: {url: `93699.mp4`},word: "I\u00b4m sad",},
  {video: {url: `93544.mp4`},word: "I have a question",},
  {video: {url: `93557.mp4`},word: "I have not shaved yet.",},
  {video: {url: `93823.mp4`},word: "The plane is ready for boarding now.",},
  {video: {url: `48507.mp4`},word: "At eight o\u00b4clock",},
  {video: {url: `153255.mp4`},word: "Come here!",},
  {video: {url: `153248.mp4`},word: "Clean the board!",},
  {video: {url: `153670.mp4`},word: "Please give it to me.",},
  {video: {url: `73482.mp4`},word: "I'm looking for a room",},
  {video: {url: `91301.mp4`},word: "Have nothing to do",},
  {video: {url: `153263.mp4`},word: "Cross out!",},
  {video: {url: `73494.mp4`},word: "Keep smiling",},
  {video: {url: `73429.mp4`},word: "David is a nice guy",},
  {video: {url: `73474.mp4`},word: "I like meeting new people",},
  {video: {url: `73509.mp4`},word: "We\u00b4ll talk by mobile",},
  {video: {url: `73508.mp4`},word: "We\u00b4ll meet at the cinema",},
  {video: {url: `73470.mp4`},word: "I haven\u2019t seen you for ages!",},
  {video: {url: `73498.mp4`},word: "Nothing happens",},
  {video: {url: `153685.mp4`},word: "Put it away!",},
  {video: {url: `90036.mp4`},word: "Check, please.",},
  {video: {url: `93514.mp4`},word: "I enjoy sitting in the sun.",},
  {video: {url: `93470.mp4`},word: "I am hard of hearing.",},
  {video: {url: `93407.mp4`},word: "He eats too much.",},
  {video: {url: `93457.mp4`},word: "I am afraid of tornadoes.",},
  {video: {url: `93570.mp4`},word: "I like autumn best.",},
  {video: {url: `89915.mp4`},word: "A bear lives in this cave.",},
  {video: {url: `93378.mp4`},word: "Falling on ice.",},
  {video: {url: `93455.mp4`},word: "I am afraid of hurricanes.",},
  {video: {url: `93456.mp4`},word: "I am afraid of thunderstorms.",},
  {video: {url: `93787.mp4`},word: "She has an easy job",},
  {video: {url: `93798.mp4`},word: "Stop here",},
  {video: {url: `93717.mp4`},word: "Make a left turn and stop.",},
  {video: {url: `91274.mp4`},word: "You have not turned in your paper to me yet.",},
  {video: {url: `93895.mp4`},word: "When you have been absent you must bring an excuse.",},
  {video: {url: `93853.mp4`},word: "We have a test tomorrow.",},
  {video: {url: `93829.mp4`},word: "The teacher asked me a lot of questions.",},
  {video: {url: `93801.mp4`},word: "Susan is not respectful to the teacher",},
  {video: {url: `93644.mp4`},word: "I would like a double room.",},
  {video: {url: `93646.mp4`},word: "I would like a receipt.",},
  {video: {url: `93647.mp4`},word: "I would like a room for one night.",},
  {video: {url: `93648.mp4`},word: "I would like a single room.",},
  {video: {url: `93652.mp4`},word: "I would like to make a reservation.",},
  {video: {url: `91285.mp4`},word: "Your writing is terrible.",},
  {video: {url: `93678.mp4`},word: "It is illegal to park here overnight.",},
  {video: {url: `117131.mp4`},word: "Peace and goodwill",},
  {video: {url: `73490.mp4`},word: "I\u00b4d like to dye my hair",},
  {video: {url: `153590.mp4`},word: "I'd like some highlights put in.",},
  {video: {url: `93706.mp4`},word: "Joy to the world",},
  {video: {url: `93410.mp4`},word: "He is risen.",},
  {video: {url: `93421.mp4`},word: "Home for the holidays.",},
  {video: {url: `73489.mp4`},word: "I\u00b4d like a haircut",},
  {video: {url: `154392.mp4`},word: "Cause and effect",},
  {video: {url: `154385.mp4`},word: "Capture the moment",},
  {video: {url: `89941.mp4`},word: "Beware of dog.",},
  {video: {url: `89917.mp4`},word: "Adopt a pet",},
  {video: {url: `153777.mp4`},word: "Well done!",},
  {video: {url: `90035.mp4`},word: "Chasing its tail.",},
  {video: {url: `89961.mp4`},word: "Birds can be messy.",},
  {video: {url: `153760.mp4`},word: "Underline!",},
  {video: {url: `153771.mp4`},word: "Wait!",},
  {video: {url: `153750.mp4`},word: "Think!",},
  {video: {url: `153755.mp4`},word: "Translate!",},
  {video: {url: `89968.mp4`},word: "Brush your hair",},
  {video: {url: `109679.mp4`},word: "Close the door",},
  {video: {url: `90045.mp4`},word: "Close the window",},
  {video: {url: `73530.mp4`},word: "You have a big alcohol problem",},
  {video: {url: `73488.mp4`},word: "It is too advanced for me",},
  {video: {url: `73465.mp4`},word: "I do not understand",},
  {video: {url: `73467.mp4`},word: "I have fever",},
  {video: {url: `91302.mp4`},word: "I have pain in my stomach",},
  {video: {url: `51512.mp4`},word: '"leave me alone."',},
  {video: {url: `91272.mp4`},word: "You can see many stars from here.",},
  {video: {url: `91414.mp4`},word: "For adults only",},
  {video: {url: `73453.mp4`},word: "I am a virgin",},
  {video: {url: `73504.mp4`},word: "This is fantastic!",},
  {video: {url: `73500.mp4`},word: "Please tell me",},
  {video: {url: `73496.mp4`},word: "Let\u00b4s go to the beach",},
  {video: {url: `91271.mp4`},word: "Yesterday, i practiced my signs.",},
  {video: {url: `93415.mp4`},word: "He won a silver medal.",},
  {video: {url: `93501.mp4`},word: "I bought a new gold shirt.",},
  {video: {url: `91281.mp4`},word: "You sign fast.",},
  {video: {url: `93385.mp4`},word: "Hand me the purple highlighter.",},
  {video: {url: `93581.mp4`},word: "I like to use colored pencils.",},
  {video: {url: `93586.mp4`},word: "I love all the colors of the rainbow.",},
  {video: {url: `93484.mp4`},word: "I am majoring in health.",},
  {video: {url: `93483.mp4`},word: "I am majoring in english.",},
  {video: {url: `93713.mp4`},word: "Let's take a break.",},
  {video: {url: `93477.mp4`},word: "I am majoring in art.",},
  {video: {url: `93712.mp4`},word: "Let's go to the zoo.",},
  {video: {url: `93690.mp4`},word: "It's party time!",},
  {video: {url: `93482.mp4`},word: "I am majoring in education.",},
  {video: {url: `93481.mp4`},word: "I am majoring in counseling.",},
  {video: {url: `93480.mp4`},word: "I am majoring in computers.",},
  {video: {url: `93479.mp4`},word: "I am majoring in chemistry.",},
  {video: {url: `93825.mp4`},word: "The seats are not reserved.",},
  {video: {url: `93440.mp4`},word: "I already said that.",},
  {video: {url: `93618.mp4`},word: "I use outlook for email.",},
  {video: {url: `158142.mp4`},word: "I use an optical mouse.",},
  {video: {url: `93615.mp4`},word: "I started a computer class today.",},
  {video: {url: `93610.mp4`},word: "I receive too much spam.",},
  {video: {url: `93591.mp4`},word: "I need a better firewall.",},
  {video: {url: `93588.mp4`},word: "I love my new sidekick.",},
  {video: {url: `153697.mp4`},word: "Remember!",},
  {video: {url: `93794.mp4`},word: "Slow down and make a right turn",},
  {video: {url: `93744.mp4`},word: "My favorite search engine is google.",},
  {video: {url: `93682.mp4`},word: "It is under five dollars.",},
  {video: {url: `93780.mp4`},word: "Please fasten your seatbelt.",},
  {video: {url: `93639.mp4`},word: "I will have iced tea.",},
  {video: {url: `93649.mp4`},word: "I would like dessert.",},
  {video: {url: `93653.mp4`},word: "I would like to order steak.",},
  {video: {url: `93624.mp4`},word: "I want a medium milk.",},
  {video: {url: `93625.mp4`},word: "I want a small milk.",},
  {video: {url: `93637.mp4`},word: "I will have coffee after i eat.",},
  {video: {url: `93638.mp4`},word: "I will have hot tea.",},
  {video: {url: `110530.mp4`},word: "My father passed away last year.",},
  {video: {url: `89936.mp4`},word: "Be my valentine.",},
  {video: {url: `153643.mp4`},word: "Open the window!",},
  {video: {url: `93386.mp4`},word: "Happy birthday america",},
  {video: {url: `93634.mp4`},word: "I will be back",},
  {video: {url: `93381.mp4`},word: "Gallaudet university was the first university for deaf people",},
  {video: {url: `93841.mp4`},word: "They have to draw some blood.",},
  {video: {url: `93764.mp4`},word: "My wife is in the hospital.",},
  {video: {url: `93640.mp4`},word: "I will see you next monday.",},
  {video: {url: `155669.mp4`},word: "Temporarily unavailable",},
  {video: {url: `93518.mp4`},word: "I feel dizzy.",},
  {video: {url: `93519.mp4`},word: "I feel fine.",},
  {video: {url: `153288.mp4`},word: "Draw!",},
  {video: {url: `93478.mp4`},word: "I am majoring in business.",},
  {video: {url: `153326.mp4`},word: "Go away!",},
  {video: {url: `73446.mp4`},word: "He is an idiot",},
  {video: {url: `73472.mp4`},word: "I like him",},
  {video: {url: `153334.mp4`},word: "Guess!",},
  {video: {url: `153586.mp4`},word: "I do not like you",},
  {video: {url: `153350.mp4`},word: "Here you are.",},
  {video: {url: `73502.mp4`},word: "There has been an accident",},
  {video: {url: `93472.mp4`},word: "I am in graduate school now.",},
  {video: {url: `73460.mp4`},word: "I am not so good in your sign language",},
  {video: {url: `73499.mp4`},word: "Please help me",},
  {video: {url: `93454.mp4`},word: "I am a student.",},
  {video: {url: `153608.mp4`},word: "Learn!",},
  {video: {url: `243935.mp4`},word: "I can't see.",},
  {video: {url: `243936.mp4`},word: "I don't like it at all.",},
  {video: {url: `93715.mp4`},word: "Let's you and i go to a restaurant.",},
  {video: {url: `89966.mp4`},word: "Breakfast is not served after 11 am",},
  {video: {url: `89916.mp4`},word: "A table for two, please",},
  {video: {url: `91282.mp4`},word: "Your father is nice looking.",},
  {video: {url: `91277.mp4`},word: "You look like your mother.",},
  {video: {url: `93655.mp4`},word: "I would not want her job.",},
  {video: {url: `93651.mp4`},word: "I would like to interview for this job.",},
  {video: {url: `93761.mp4`},word: "My uncle is a farmer.",},
  {video: {url: `93741.mp4`},word: "My cousin is a pilot.",},
  {video: {url: `93476.mp4`},word: "I am looking for a new job.",},
  {video: {url: `93459.mp4`},word: "I am an artist.",},
  {video: {url: `93575.mp4`},word: "I like my work.",},
  {video: {url: `93511.mp4`},word: "I don't earn enough money.",},
  {video: {url: `93495.mp4`},word: "I am single.",},
  {video: {url: `93500.mp4`},word: "I appreciate your help.",},
  {video: {url: `93469.mp4`},word: "I am happy to meet you.",},
  {video: {url: `93442.mp4`},word: "I already took a shower.",},
  {video: {url: `93464.mp4`},word: "I am divorced.",},
  {video: {url: `93441.mp4`},word: "I already took a bath.",},
  {video: {url: `153617.mp4`},word: "Make a sentence!",},
  {video: {url: `73477.mp4`},word: "I really miss you",},
  {video: {url: `73476.mp4`},word: "I love you",},
  {video: {url: `93705.mp4`},word: "John is capable of much better work.",},
  {video: {url: `93719.mp4`},word: "Math is a difficult subject for me.",},
  {video: {url: `93702.mp4`},word: "Jane needs to listen to directions.",},
  {video: {url: `93704.mp4`},word: "Joe is late to class too often.",},
  {video: {url: `93766.mp4`},word: "No talking during the test.",},
  {video: {url: `93770.mp4`},word: "Open your book.",},
  {video: {url: `93733.mp4`},word: "Mike is a hard-working student.",},
  {video: {url: `93746.mp4`},word: "My grades are improving steadily.",},
  {video: {url: `93380.mp4`},word: "Gallaudet university is in washington, d.c.",},
  {video: {url: `93796.mp4`},word: "Someday i'm going to africa",},
  {video: {url: `93779.mp4`},word: "Please don't erase the board.",},
  {video: {url: `93799.mp4`},word: "Stop writing",},
  {video: {url: `93818.mp4`},word: "The flight has been delayed an hour.",},
  {video: {url: `93817.mp4`},word: "The flight has been cancelled.",},
  {video: {url: `93816.mp4`},word: "The elevator is stuck.",},
  {video: {url: `93811.mp4`},word: "The airports is closed due to fog.",},
  {video: {url: `93809.mp4`},word: "The 4th of july is a holiday.",},
  {video: {url: `93803.mp4`},word: "Thank your grandparents",},
  {video: {url: `93856.mp4`},word: "We're going to spend memorial day at the beach.",},
  {video: {url: `89971.mp4`},word: "Call the ambulance.",},
  {video: {url: `93508.mp4`},word: "I do not feel well.",},
  {video: {url: `158144.mp4`},word: "Our church is having a revival.",},
  {video: {url: `93773.mp4`},word: "Our music is modern.",},
  {video: {url: `93774.mp4`},word: "Our music is traditional.",},
  {video: {url: `93775.mp4`},word: "Our pastor is a good preacher.",},
  {video: {url: `93789.mp4`},word: "She is a missionary",},
  {video: {url: `93577.mp4`},word: "I like sandwiches and hamburgers.",},
  {video: {url: `153749.mp4`},word: "That\u2019s enough!",},
  {video: {url: `153742.mp4`},word: "Talk!",},
  {video: {url: `93810.mp4`},word: "The ymca is just around the corner.",},
  {video: {url: `93854.mp4`},word: "We must pay taxes to support the government.",},
  {video: {url: `153741.mp4`},word: "Take it!",},
  {video: {url: `93735.mp4`},word: "Mr.jones is running for mayor.",},
  {video: {url: `116845.mp4`},word: "She is a congresswoman",},
  {video: {url: `93814.mp4`},word: "The city council will vote this week.",},
  {video: {url: `93822.mp4`},word: "The legislature is responsible for passing laws.",},
  {video: {url: `91278.mp4`},word: "You lost a lot of blood.",},
  {video: {url: `93379.mp4`},word: "Forgive me",},
  {video: {url: `91303.mp4`},word: "It won't",},
  {video: {url: `93824.mp4`},word: "The police station is two blocks away.",},
  {video: {url: `153740.mp4`},word: "Take it away!",},
  {video: {url: `153718.mp4`},word: "Sit down!",},
  {video: {url: `153729.mp4`},word: "Stand up!",},
  {video: {url: `91270.mp4`},word: "Yesterday evening at sunset the clouds were beautiful.",},
  {video: {url: `93834.mp4`},word: "There is a full moon tonight.",},
  {video: {url: `93832.mp4`},word: "The waterfall is beautiful.",},
  {video: {url: `93838.mp4`},word: "There was thunder and lightning last night.",},
  {video: {url: `93837.mp4`},word: "There was a flood last year.",},
  {video: {url: `93851.mp4`},word: "We got lost in the desert.",},
  {video: {url: `93847.mp4`},word: "Warming by the fireplace.",},
  {video: {url: `73462.mp4`},word: "I do not believe you",},
  {video: {url: `73443.mp4`},word: "Give me a new chance",},
  {video: {url: `73528.mp4`},word: "You can go in my car",},
  {video: {url: `93628.mp4`},word: "I want to subscribe to a newspaper.",},
  {video: {url: `93444.mp4`},word: "I am a junior.",},
  {video: {url: `93656.mp4`},word: "I wrote her a letter last week.",},
  {video: {url: `93414.mp4`},word: "He talks too much in class.",},
  {video: {url: `93597.mp4`},word: "I need stamps.",},
  {video: {url: `89964.mp4`},word: "Bob works well in groups.",},
  {video: {url: `90047.mp4`},word: "Close your book.",},
  {video: {url: `93587.mp4`},word: "I love my flat screen monitor.",},
  {video: {url: `93745.mp4`},word: "My garden is so green.",},
  {video: {url: `93743.mp4`},word: "My favorite color is...",},
  {video: {url: `93738.mp4`},word: "My car is bright red.",},
  {video: {url: `93446.mp4`},word: "I am a sophomore.",},
  {video: {url: `93619.mp4`},word: "I visited my aunt two months ago.",},
  {video: {url: `93613.mp4`},word: "I see her every saturday",},
  {video: {url: `93533.mp4`},word: "I graduate in two years.",},
  {video: {url: `93502.mp4`},word: "I bought a new house two years ago.",},
  {video: {url: `93608.mp4`},word: "I pay every three months.",},
  {video: {url: `93549.mp4`},word: "I have an appointment at 2:30",},
  {video: {url: `109916.mp4`},word: "He is 87 years old",},
  {video: {url: `93408.mp4`},word: "He goes to the movies every tuseday.",},
  {video: {url: `91296.mp4`},word: "Forget it",},
  {video: {url: `93453.mp4`},word: "I am a secretary.",},
  {video: {url: `93458.mp4`},word: "I am an actor.",},
  {video: {url: `93449.mp4`},word: "I am a firefighter",},
  {video: {url: `93450.mp4`},word: "I am a housewife.",},
  {video: {url: `93451.mp4`},word: "I am a lawyer.",},
  {video: {url: `93462.mp4`},word: "I am an police officer.",},
  {video: {url: `93416.mp4`},word: "He works hard at his job.",},
  {video: {url: `93420.mp4`},word: "His son wants to be an astronaut.",},
  {video: {url: `93448.mp4`},word: "I am a doctor.",},
  {video: {url: `91280.mp4`},word: "You need to have an x-ray.",},
  {video: {url: `153589.mp4`},word: "I understand.",},
  {video: {url: `93753.mp4`},word: "My monitor is dead.",},
  {video: {url: `93765.mp4`},word: "My word processor is word.",},
  {video: {url: `93752.mp4`},word: "My internet connection is slow.",},
  {video: {url: `93806.mp4`},word: "That is a pretty mouse pad.",},
  {video: {url: `93311.mp4`},word: "Do not drink and drive",},
  {video: {url: `91275.mp4`},word: "You have to go the library to do some research.",},
  {video: {url: `93540.mp4`},word: "I have a cough.",},
  {video: {url: `93685.mp4`},word: "It is over five dollars.",},
  {video: {url: `93657.mp4`},word: "I'm broke.",},
  {video: {url: `93606.mp4`},word: "I paid less than you.",},
  {video: {url: `93601.mp4`},word: "I need to go to the bank.",},
  {video: {url: `93598.mp4`},word: "I need to balance my checkbook.",},
  {video: {url: `93554.mp4`},word: "I have no money.",},
  {video: {url: `93463.mp4`},word: "I am applying for a credit card",},
  {video: {url: `93516.mp4`},word: "I feel all right.",},
  {video: {url: `93517.mp4`},word: "I feel better now.",},
  {video: {url: `93754.mp4`},word: "My name is..",},
  {video: {url: `93750.mp4`},word: "My internet connection is down",},
  {video: {url: `93520.mp4`},word: "I feel lousy.",},
  {video: {url: `93521.mp4`},word: "I feel sick.",},
  {video: {url: `93535.mp4`},word: "I had a physical last week.",},
  {video: {url: `93539.mp4`},word: "I have a cold.",},
  {video: {url: `93612.mp4`},word: "I second the motion.",},
  {video: {url: `158143.mp4`},word: "I was on the picket line all morning.",},
  {video: {url: `93460.mp4`},word: "I am an election judge.",},
  {video: {url: `93452.mp4`},word: "I am a republican.",},
  {video: {url: `93536.mp4`},word: "I had to pay a parking fine.",},
  {video: {url: `93461.mp4`},word: "I am an independent.",},
  {video: {url: `153074.mp4`},word: "Ask me!",},
  {video: {url: `93660.mp4`},word: "If you go to court you must have a good lawyer",},
  {video: {url: `110153.mp4`},word: "If you break the law you might go to jail.",},
  {video: {url: `110149.mp4`},word: "I would like to seen some rings.",},
  {video: {url: `93645.mp4`},word: "I would like a haircut.",},
  {video: {url: `93677.mp4`},word: "It is fine like that.",},
  {video: {url: `93654.mp4`},word: "I would like to try it on.",},
  {video: {url: `93605.mp4`},word: "I need to wash out my shirt.",},
  {video: {url: `93602.mp4`},word: "I need to iron this shirt.",},
  {video: {url: `93641.mp4`},word: "I will take that tie.",},
  {video: {url: `93627.mp4`},word: "I want to buy a sweater.",},
  {video: {url: `93781.mp4`},word: "Please repeat.",},
  {video: {url: `93739.mp4`},word: "My change is incorrect.",},
  {video: {url: `93709.mp4`},word: "Leave it a little long.",},
  {video: {url: `93443.mp4`},word: "I am a freshman.",},
  {video: {url: `91276.mp4`},word: "You have to have a shot.",},
  {video: {url: `91279.mp4`},word: "You must not interrupt.",},
  {video: {url: `91273.mp4`},word: "You have lost a lot of weight.",},
  {video: {url: `153273.mp4`},word: "Depart from this life",},
  {video: {url: `118599.mp4`},word: "You look nice",},
  {video: {url: `93710.mp4`},word: "Let freedom ring",},
  {video: {url: `93716.mp4`},word: "Mail early",},
  {video: {url: `153642.mp4`},word: "Open the door!",},
  {video: {url: `73527.mp4`},word: "You are too young",},
  {video: {url: `73461.mp4`},word: "I am too old",},
  {video: {url: `73507.mp4`},word: "We are the best",},
  {video: {url: `73414.mp4`},word: "Call me later.",},
  {video: {url: `73532.mp4`},word: "You look good!",},
  {video: {url: `94022.mp4`},word: "Silly young thing",},
  {video: {url: `93595.mp4`},word: "I need a shave.",},
  {video: {url: `93600.mp4`},word: "I need to do some laundry.",},
  {video: {url: `93795.mp4`},word: "Smoking is not allowed",},
  {video: {url: `93812.mp4`},word: "The baby is cute.",},
  {video: {url: `93846.mp4`},word: "Very interesting.",},
  {video: {url: `93848.mp4`},word: "Wash your face.",},
  {video: {url: `93849.mp4`},word: "Wash your hands.",},
  {video: {url: `93852.mp4`},word: "We had a family reunion last summer.",},
  {video: {url: `93686.mp4`},word: "It was cold this morning.",},
  {video: {url: `93740.mp4`},word: "My computer crashed.",},
  {video: {url: `93732.mp4`},word: "Maybe it will snow tomorrow.",},
  {video: {url: `93783.mp4`},word: "Raining cats and dogs",},
  {video: {url: `93797.mp4`},word: "Splashing in a puddle",},
  {video: {url: `93800.mp4`},word: "Storm at sea",},
  {video: {url: `93820.mp4`},word: "The forecast is for sunny skies.",},
  {video: {url: `93749.mp4`},word: "My husband is dead.",},
  {video: {url: `93828.mp4`},word: "The sun is hot.",},
  {video: {url: `93523.mp4`},word: "I feel wonderful.",},
  {video: {url: `93556.mp4`},word: "I have not seen you in a long time.",},
  {video: {url: `93550.mp4`},word: "I have been waiting 20 minutes.",},
  {video: {url: `93563.mp4`},word: "I have to go home.",},
  {video: {url: `93736.mp4`},word: "My aunt lives in town.",},
  {video: {url: `93583.mp4`},word: "I lost my comb.",},
  {video: {url: `93569.mp4`},word: "I lent my typewriter.",},
  {video: {url: `110525.mp4`},word: "My brother is younger than i.",},
  {video: {url: `93621.mp4`},word: "I want a large coke.",},
  {video: {url: `93617.mp4`},word: "I think the bill is incorrect.",},
  {video: {url: `93622.mp4`},word: "I want a large pepsi.",},
  {video: {url: `93503.mp4`},word: "I can fingerspell, but i can't read it well.",},
  {video: {url: `93505.mp4`},word: "I cannot fingerspell well.",},
  {video: {url: `93515.mp4`},word: "I enjoy watching tv with captions.",},
  {video: {url: `93568.mp4`},word: "I hear music through its vibrations.",},
  {video: {url: `93474.mp4`},word: "I am learning sign language",},
  {video: {url: `93497.mp4`},word: "I am studying to be an interpreter.",},
  {video: {url: `93835.mp4`},word: "There is a two hour layover.",},
  {video: {url: `93855.mp4`},word: "We will land in ten minutes.",},
  {video: {url: `93589.mp4`},word: "I love to pray in sign.",},
  {video: {url: `93611.mp4`},word: "I saw a captioned film last  night.",},
  {video: {url: `116844.mp4`},word: "She and i discussed it",},
  {video: {url: `93782.mp4`},word: "Please wait.",},
  {video: {url: `93530.mp4`},word: "I got a 'f' on my paper.",},
  {video: {url: `93772.mp4`},word: "Our family is small.",},
  {video: {url: `93778.mp4`},word: "Please close the curtains.",},
  {video: {url: `93777.mp4`},word: "Perhaps.",},
  {video: {url: `93768.mp4`},word: "Open the door.",},
  {video: {url: `93525.mp4`},word: "I go to college.",},
  {video: {url: `93771.mp4`},word: "Our family is large.",},
  {video: {url: `93769.mp4`},word: "Open the window.",},
  {video: {url: `93537.mp4`},word: "I have 3 weeks for vacation.",},
  {video: {url: `109934.mp4`},word: "I can't eat the whole thing.",},
  {video: {url: `93409.mp4`},word: "He has two graduate degrees.",},
  {video: {url: `93643.mp4`},word: "I work six days a week.",},
  {video: {url: `93636.mp4`},word: "I will have a half cup of coffee.",},
  {video: {url: `93566.mp4`},word: "I have two weekends off every month.",},
  {video: {url: `93559.mp4`},word: "I have seen this movie three times.",},
  {video: {url: `93658.mp4`},word: "I've been on my diet for 12 weeks.",},
  {video: {url: `93522.mp4`},word: "I feel tired.",},
  {video: {url: `73493.mp4`},word: "Joan doesn\u00b4t understand french sign language",},
  {video: {url: `93419.mp4`},word: "His niece will help you.",},
  {video: {url: `93417.mp4`},word: "Her daughter works here.",},
  {video: {url: `93406.mp4`},word: "He broke the vcr",},
  {video: {url: `93399.mp4`},word: "Have a seat, please.",},
  {video: {url: `93698.mp4`},word: "I\u00b4m married",},
  {video: {url: `93697.mp4`},word: "I\u00b4m happy",},
  {video: {url: `73491.mp4`},word: "I\u00b4d like you to meet my friend",},
  {video: {url: `73533.mp4`},word: "You\u00b4re right",},
  {video: {url: `93584.mp4`},word: "I lost my pencil.",},
  {video: {url: `93821.mp4`},word: "The humidity is high today.",},
  {video: {url: `93793.mp4`},word: "Sixty thousand people live in my city.",},
  {video: {url: `93831.mp4`},word: "The temperature is below zero.",},
  {video: {url: `153213.mp4`},word: "Bring it here!",},
  {video: {url: `73469.mp4`},word: "I haven\u2019t seen him for ages",},
  {video: {url: `73497.mp4`},word: "My friends are partying",},
  {video: {url: `73503.mp4`},word: "This guy is full of energy!",},
  {video: {url: `73473.mp4`},word: "I like jos\u00e9",},
  {video: {url: `73475.mp4`},word: "I love travelling around",},
  {video: {url: `153075.mp4`},word: "Ask other students!",},
  {video: {url: `153057.mp4`},word: "Answer!",},
  {video: {url: `73492.mp4`},word: "I\u00b4m going shopping",},
  {video: {url: `93471.mp4`},word: "I am hungry.",},
  {video: {url: `93630.mp4`},word: "I was born in 1960.",},
  {video: {url: `93555.mp4`},word: "I have not eaten yet.",},
  {video: {url: `93507.mp4`},word: "I did not order that.",},
  {video: {url: `93802.mp4`},word: "Taking time out",},
  {video: {url: `93574.mp4`},word: "I like chocolate.",},
  {video: {url: `93844.mp4`},word: "Time is up.",},
  {video: {url: `93843.mp4`},word: "Time flies.",},
  {video: {url: `89939.mp4`},word: "Begin writing.",},
  {video: {url: `93623.mp4`},word: "I want a large milk.",},
  {video: {url: `109915.mp4`},word: "He always dresses nicely",},
  {video: {url: `93475.mp4`},word: "I am looking for a laundromat.",},
  {video: {url: `93504.mp4`},word: "I cannot fasten my belt.",},
  {video: {url: `93506.mp4`},word: "I cannot tie a bowtie.",},
  {video: {url: `93564.mp4`},word: "I have to go shopping.",},
  {video: {url: `93850.mp4`},word: "Water freezes at 32 degrees.",},
  {video: {url: `93840.mp4`},word: "They have 11 children.",},
  {video: {url: `93839.mp4`},word: "There were 150 people at the wedding.",},
  {video: {url: `93833.mp4`},word: "There are only 2 million people in our country.",},
  {video: {url: `93830.mp4`},word: "The temperature is 76 degrees.",},
  {video: {url: `117129.mp4`},word: "The garbage truck comes once a week",},
  {video: {url: `153587.mp4`},word: "I had a hard drive failure.",},
  {video: {url: `93762.mp4`},word: "My vacation is in three months.",},
  {video: {url: `93757.mp4`},word: "My phone number is\u2026",},
  {video: {url: `93596.mp4`},word: "I need a taxi.",},
  {video: {url: `93642.mp4`},word: "I will take you to the airport.",},
  {video: {url: `93553.mp4`},word: "I have lost my passport.",},
  {video: {url: `93561.mp4`},word: "I have to change planes in chicago.",},
  {video: {url: `93513.mp4`},word: "I enjoy riding a train.",},
  {video: {url: `93531.mp4`},word: "I got a flat tire yesterday.",},
  {video: {url: `93494.mp4`},word: "I am out of gas.",},
  {video: {url: `93510.mp4`},word: "I do not have a license.",},
  {video: {url: `93466.mp4`},word: "I am flying to new york tonight.",},
  {video: {url: `93468.mp4`},word: "I am going to the hotel to take a bath.",},
  {video: {url: `116913.mp4`},word: "Slippery when wet",},
  {video: {url: `6153.mp4`},word: "How are you",},
  {video: {url: `51508.mp4`},word: "How do you feel?",},
  {video: {url: `109901.mp4`},word: "Good night",},
  {video: {url: `93398.mp4`},word: "Have a nice day!",},
  {video: {url: `153330.mp4`},word: "Good-bye!",},
  {video: {url: `51575.mp4`},word: "Farewell",},
  {video: {url: `51854.mp4`},word: "I am sorry",},
  {video: {url: `26513.mp4`},word: "Happy new year",},
  {video: {url: `26499.mp4`},word: "Merry christmas",},
  {video: {url: `63537.mp4`},word: "Good bye",},
  {video: {url: `48819.mp4`},word: "Nice to meet you",},
  {video: {url: `93387.mp4`},word: "Happy halloween",},
  {video: {url: `93388.mp4`},word: "Happy hannukah.",},
  {video: {url: `109900.mp4`},word: "Good morning",},
  {video: {url: `93390.mp4`},word: "Happy thanksgiving",},
  {video: {url: `93693.mp4`},word: "I\u00b4ll see you later",},
  {video: {url: `93695.mp4`},word: "I\u00b4m fine",},
  {video: {url: `93391.mp4`},word: "Happy valentines day",},
  {video: {url: `93397.mp4`},word: "Have a nice thanksgiving.",},
  {video: {url: `93428.mp4`},word: "How have you been?",},
  {video: {url: `93389.mp4`},word: "Happy mothers day",},
  {video: {url: `73451.mp4`},word: "How old are you?",},
  {video: {url: `93423.mp4`},word: "How can i help you?",},
  {video: {url: `93725.mp4`},word: "May i help you?",},
  {video: {url: `153748.mp4`},word: "Thank you.",},
  {video: {url: `51106.mp4`},word: "Yes",},
  {video: {url: `93776.mp4`},word: "Pardon me.",},
  {video: {url: `93467.mp4`},word: "I am glad to see you.",},
  {video: {url: `73414.mp4`},word: "Call me later.",},
  {video: {url: `93767.mp4`},word: "No, thank you.",},
  {video: {url: `51113.mp4`},word: "You are welcome",},
  {video: {url: `73501.mp4`},word: "See you later",},
  {video: {url: `93786.mp4`},word: "Seasons greetings",},
  {video: {url: `73445.mp4`},word: "Have a nice weekend",},
  {video: {url: `109919.mp4`},word: "Hello",},
  {video: {url: `93376.mp4`},word: "Excuse me",},
  {video: {url: `62851.mp4`},word: "See you",},
  {video: {url: `109898.mp4`},word: "Good afternoon",},
  {video: {url: `49200.mp4`},word: "Please",},
  {video: {url: `17655.mp4`},word: "Hi!",},
  {video: {url: `125649.mp4`},word: "Bye bye",},
  {video: {url: `50956.mp4`},word: "Welcome",},
  {video: {url: `58518.mp4`},word: "Happy birthday",},
  {video: {url: `62620.mp4`},word: "Bon voyage",},
  {video: {url: `90012.mp4`},word: "Thank goodness",},
  {video: {url: `153331.mp4`},word: "Goodbye",},
  {video: {url: `58480.mp4`},word: "Sorry",},
  {video: {url: `12806.mp4`},word: "Thank you!",},
  {video: {url: `51580.mp4`},word: "Have fun",},
  {video: {url: `58476.mp4`},word: "Thank",},
  {video: {url: `89880.mp4`},word: "Orally",},
  {video: {url: `63554.mp4`},word: "Oral",},
  {video: {url: `51903.mp4`},word: "Dumb",},
  {video: {url: `116778.mp4`},word: "Said",},
  {video: {url: `122583.mp4`},word: "Ask",},
  {video: {url: `96343.mp4`},word: "Exclaim",},
  {video: {url: `50807.mp4`},word: "Shut up",},
  {video: {url: `51561.mp4`},word: "Babble",},
  {video: {url: `125670.mp4`},word: "Call",},
  {video: {url: `93940.mp4`},word: "Discuss",},
  {video: {url: `49279.mp4`},word: "Reply",},
  {video: {url: `153696.mp4`},word: "Remark",},
  {video: {url: `123604.mp4`},word: "Bawl",},
  {video: {url: `65931.mp4`},word: "Vocalize",},
  {video: {url: `58555.mp4`},word: "Tell",},
  {video: {url: `125938.mp4`},word: "Chat",},
  {video: {url: `63583.mp4`},word: "Speak",},
  {video: {url: `63571.mp4`},word: "Say",},
  {video: {url: `50902.mp4`},word: "Talk",},
  {video: {url: `96582.mp4`},word: "Hush",},
  {video: {url: `92919.mp4`},word: "Mumble",},
  {video: {url: `96117.mp4`},word: "Converse",},
  {video: {url: `50806.mp4`},word: "Shout",},
  {video: {url: `48558.mp4`},word: "Lie",},
  {video: {url: `116422.mp4`},word: "Pronounce",},
  {video: {url: `118516.mp4`},word: "Announce",},
  {video: {url: `63585.mp4`},word: "Speech therapist",},
  {video: {url: `245222.mp4`},word: "Speaker",},
  {video: {url: `191244.mp4`},word: "Mutter",},
  {video: {url: `94016.mp4`},word: "Salute",},
  {video: {url: `155709.mp4`},word: "Vowel",},
  {video: {url: `153695.mp4`},word: "Remark",},
  {video: {url: `97028.mp4`},word: "Announcement",},
  {video: {url: `246100.mp4`},word: "Key note",},
  {video: {url: `157922.mp4`},word: "Phonology",},
  {video: {url: `58379.mp4`},word: "Speech therapy",},
  {video: {url: `51449.mp4`},word: "Chatter",},
  {video: {url: `96958.mp4`},word: "Saying",},
  {video: {url: `155543.mp4`},word: "Rhetoric",},
  {video: {url: `92870.mp4`},word: "Mention",},
  {video: {url: `94352.mp4`},word: "Speech impairment",},
  {video: {url: `96565.mp4`},word: "Holler",},
  {video: {url: `243976.mp4`},word: "Accent",},
  {video: {url: `244389.mp4`},word: "Discussion group",},
  {video: {url: `155621.mp4`},word: "Speechreading",},
  {video: {url: `58287.mp4`},word: "Discussion",},
  {video: {url: `93933.mp4`},word: "Dialect",},
  {video: {url: `93934.mp4`},word: "Dialogue",},
  {video: {url: `244805.mp4`},word: "Speech",},
  {video: {url: `154732.mp4`},word: "Large print",},
  {video: {url: `58604.mp4`},word: "Describe",},
  {video: {url: `49279.mp4`},word: "Reply",},
  {video: {url: `155542.mp4`},word: "Rewrite",},
  {video: {url: `93976.mp4`},word: "Note down",},
  {video: {url: `94032.mp4`},word: "Spell",},
  {video: {url: `93901.mp4`},word: "Browse",},
  {video: {url: `90141.mp4`},word: "Turn the page",},
  {video: {url: `51100.mp4`},word: "Write",},
  {video: {url: `51955.mp4`},word: "Marker",},
  {video: {url: `63568.mp4`},word: "Reading",},
  {video: {url: `63601.mp4`},word: "Writing",},
  {video: {url: `154697.mp4`},word: "Hyphen",},
  {video: {url: `245854.mp4`},word: "Paraphrase",},
  {video: {url: `158009.mp4`},word: "Reading habit",},
  {video: {url: `158008.mp4`},word: "Reading circle",},
  {video: {url: `158007.mp4`},word: "Reader",},
  {video: {url: `243928.mp4`},word: "Abc story",},
  {video: {url: `245707.mp4`},word: "Readership",},
  {video: {url: `93935.mp4`},word: "Diary",},
  {video: {url: `96070.mp4`},word: "Comma",},
  {video: {url: `244473.mp4`},word: "Exclamative sentence",},
  {video: {url: `153239.mp4`},word: "Calligraphy",},
  {video: {url: `51275.mp4`},word: "Apostrophe",},
  {video: {url: `93975.mp4`},word: "Note",},
  {video: {url: `153025.mp4`},word: "Abc book",},
  {video: {url: `158006.mp4`},word: "Readability",},
  {video: {url: `125715.mp4`},word: "Calligraphy pen",},
  {video: {url: `244439.mp4`},word: "Exclamation mark",},
  {video: {url: `93930.mp4`},word: "Description",},
  {video: {url: `116222.mp4`},word: "Parenthesis",},
  {video: {url: `154762.mp4`},word: "Lower-case letter",},
  {video: {url: `244968.mp4`},word: "Written expression",},
  {video: {url: `244538.mp4`},word: "Freestyle letter",},
  {video: {url: `154638.mp4`},word: "Formal letter",},
  {video: {url: `246075.mp4`},word: "Letters",},
  {video: {url: `244982.mp4`},word: "Wording",},
  {video: {url: `157995.mp4`},word: "Punctuation mark",},
  {video: {url: `50809.mp4`},word: "Signature",},
  {video: {url: `155580.mp4`},word: "Separate writing",},
  {video: {url: `157998.mp4`},word: "Quotation marks",},
  {video: {url: `153687.mp4`},word: "Quotation",},
  {video: {url: `153592.mp4`},word: "Ink",},
  {video: {url: `96269.mp4`},word: "Dot",},
  {video: {url: `93977.mp4`},word: "Notice",},
  {video: {url: `155555.mp4`},word: "Run-on",},
  {video: {url: `244137.mp4`},word: "Calligraphy nib",},
  {video: {url: `116443.mp4`},word: "Punctuation",},
  {video: {url: `50784.mp4`},word: "Sentence",},
  {video: {url: `50892.mp4`},word: "Text",},
  {video: {url: `245055.mp4`},word: "Upper case",},
  {video: {url: `243959.mp4`},word: "Pakistan",},
  {video: {url: `243951.mp4`},word: "Malaysia",},
  {video: {url: `62577.mp4`},word: "Bethlehem",},
  {video: {url: `243947.mp4`},word: "Jordan",},
  {video: {url: `125999.mp4`},word: "China",},
  {video: {url: `243958.mp4`},word: "North korea",},
  {video: {url: `243940.mp4`},word: "Indonesia",},
  {video: {url: `243967.mp4`},word: "Thailand",},
  {video: {url: `243943.mp4`},word: "Iran",},
  {video: {url: `243970.mp4`},word: "South korea",},
  {video: {url: `243944.mp4`},word: "Iraq",},
  {video: {url: `122581.mp4`},word: "Asia",},
  {video: {url: `97077.mp4`},word: "Afghanistan",},
  {video: {url: `89811.mp4`},word: "Israel",},
  {video: {url: `243960.mp4`},word: "Philippines",},
  {video: {url: `243973.mp4`},word: "Saudi arabia",},
  {video: {url: `93151.mp4`},word: "Human",},
  {video: {url: `154692.mp4`},word: "Human activity",},
  {video: {url: `58373.mp4`},word: "Social problem",},
  {video: {url: `246070.mp4`},word: "Life stage",},
  {video: {url: `91788.mp4`},word: "Identification",},
  {video: {url: `91450.mp4`},word: "Lady",},
  {video: {url: `244880.mp4`},word: "Lord",},
  {video: {url: `89599.mp4`},word: "Protocol",},
  {video: {url: `58378.mp4`},word: "Socialization",},
  {video: {url: `89905.mp4`},word: "Public",},
  {video: {url: `58375.mp4`},word: "Social work",},
  {video: {url: `154693.mp4`},word: "Human comfort",},
  {video: {url: `154695.mp4`},word: "Humankind",},
  {video: {url: `245247.mp4`},word: "Social class",},
  {video: {url: `51581.mp4`},word: "Identity",},
  {video: {url: `91826.mp4`},word: "Society",},
  {video: {url: `89525.mp4`},word: "Idealism",},
  {video: {url: `245242.mp4`},word: "Social participation",},
  {video: {url: `93159.mp4`},word: "Identity card",},
  {video: {url: `94346.mp4`},word: "Social background",},
  {video: {url: `189456.mp4`},word: "Race hate",},
  {video: {url: `49187.mp4`},word: "Person",},
  {video: {url: `189539.mp4`},word: "Social exclusion",},
  {video: {url: `62275.mp4`},word: "Anthropology",},
  {video: {url: `91806.mp4`},word: "Living environment",},
  {video: {url: `49185.mp4`},word: "People",},
  {video: {url: `51643.mp4`},word: "Social behavior",},
  {video: {url: `96655.mp4`},word: "Nobility",},
  {video: {url: `62732.mp4`},word: "Class",},
  {video: {url: `93264.mp4`},word: "Cross-eyed",},
  {video: {url: `155706.mp4`},word: "Visual",},
  {video: {url: `124725.mp4`},word: "Blind",},
  {video: {url: `90177.mp4`},word: "Visible",},
  {video: {url: `110176.mp4`},word: "Light-sensitive",},
  {video: {url: `94035.mp4`},word: "Stare",},
  {video: {url: `93087.mp4`},word: "Glance",},
  {video: {url: `63548.mp4`},word: "Look, to",},
  {video: {url: `123649.mp4`},word: "Behold",},
  {video: {url: `48565.mp4`},word: "Look at",},
  {video: {url: `153609.mp4`},word: "Leer",},
  {video: {url: `96407.mp4`},word: "Gaze",},
  {video: {url: `185311.mp4`},word: "Visual impairment",},
  {video: {url: `90175.mp4`},word: "View",},
  {video: {url: `96356.mp4`},word: "Eyewash",},
  {video: {url: `125844.mp4`},word: "Cataract",},
  {video: {url: `92977.mp4`},word: "Eyeglasses",},
  {video: {url: `96416.mp4`},word: "Glimpse",},
  {video: {url: `93252.mp4`},word: "Contact lens",},
  {video: {url: `92975.mp4`},word: "Eye exam",},
  {video: {url: `90178.mp4`},word: "Vision",},
  {video: {url: `92974.mp4`},word: "Eye dropper",},
  {video: {url: `116815.mp4`},word: "Seeing-eye dog",},
  {video: {url: `96905.mp4`},word: "Optometry",},
  {video: {url: `244816.mp4`},word: "Sight",},
  {video: {url: `96899.mp4`},word: "Optician",},
  {video: {url: `65887.mp4`},word: "Lithuania",},
  {video: {url: `65934.mp4`},word: "Xenophobic",},
  {video: {url: `58411.mp4`},word: "Foreign",},
  {video: {url: `96625.mp4`},word: "Integrate",},
  {video: {url: `116369.mp4`},word: "Populate",},
  {video: {url: `58316.mp4`},word: "Immigrant",},
  {video: {url: `94276.mp4`},word: "Minority language",},
  {video: {url: `244444.mp4`},word: "Ethnic minority",},
  {video: {url: `185093.mp4`},word: "Imigration",},
  {video: {url: `118184.mp4`},word: "Acclimatization",},
  {video: {url: `244341.mp4`},word: "Deportation",},
  {video: {url: `118358.mp4`},word: "Advisory bureau",},
  {video: {url: `244334.mp4`},word: "Culture shock",},
  {video: {url: `189458.mp4`},word: "Refugee",},
  {video: {url: `185102.mp4`},word: "Integration",},
  {video: {url: `58371.mp4`},word: "Social group",},
  {video: {url: `91789.mp4`},word: "Illegal work",},
  {video: {url: `244018.mp4`},word: "Alien",},
  {video: {url: `94037.mp4`},word: "Stranger",},
  {video: {url: `51921.mp4`},word: "Language barrier",},
  {video: {url: `185282.mp4`},word: "Social integration",},
  {video: {url: `51621.mp4`},word: "Raise",},
  {video: {url: `91766.mp4`},word: "Foster care",},
  {video: {url: `96166.mp4`},word: "Custody",},
  {video: {url: `91322.mp4`},word: "Child-welfare centre",},
  {video: {url: `91681.mp4`},word: "Adopted child",},
  {video: {url: `91682.mp4`},word: "Adoption",},
  {video: {url: `125981.mp4`},word: "Child welfare",},
  {video: {url: `91321.mp4`},word: "Child prostitution",},
  {video: {url: `51789.mp4`},word: "Child health",},
  {video: {url: `51602.mp4`},word: "Orphanage",},
  {video: {url: `125980.mp4`},word: "Child in care",},
  {video: {url: `91716.mp4`},word: "Charity",},
  {video: {url: `125989.mp4`},word: "Children's home",},
  {video: {url: `189408.mp4`},word: "Minor",},
  {video: {url: `91748.mp4`},word: "Dysfunctional family",},
  {video: {url: `244196.mp4`},word: "Child benefit",},
  {video: {url: `189427.mp4`},word: "Orphan\u2018s pension",},
  {video: {url: `118344.mp4`},word: "Adopter",},
  {video: {url: `91772.mp4`},word: "Guardian",},
  {video: {url: `94251.mp4`},word: "Individually family support plan",},
  {video: {url: `189426.mp4`},word: "Orphan",},
  {video: {url: `91815.mp4`},word: "Neglect",},
  {video: {url: `189535.mp4`},word: "Social pedagogue",},
  {video: {url: `244015.mp4`},word: "All rights reserved",},
  {video: {url: `96259.mp4`},word: "Documentary",},
  {video: {url: `155540.mp4`},word: "Revised",},
  {video: {url: `154772.mp4`},word: "Mainstream",},
  {video: {url: `244267.mp4`},word: "Contextual",},
  {video: {url: `153212.mp4`},word: "Brief",},
  {video: {url: `116320.mp4`},word: "Plagiarize",},
  {video: {url: `158039.mp4`},word: "Reprint",},
  {video: {url: `155699.mp4`},word: "Updating",},
  {video: {url: `51507.mp4`},word: "Interest",},
  {video: {url: `118354.mp4`},word: "Advertise",},
  {video: {url: `51857.mp4`},word: "Inform, to",},
  {video: {url: `124302.mp4`},word: "Bibliology",},
  {video: {url: `154534.mp4`},word: "Data analysis",},
  {video: {url: `154535.mp4`},word: "Data collection",},
  {video: {url: `91714.mp4`},word: "Case study",},
  {video: {url: `157864.mp4`},word: "Non-fiction",},
  {video: {url: `153268.mp4`},word: "Data",},
  {video: {url: `153633.mp4`},word: "Multimedia",},
  {video: {url: `157861.mp4`},word: "New edition",},
  {video: {url: `153196.mp4`},word: "Book publications right",},
  {video: {url: `155598.mp4`},word: "Single-page spread",},
  {video: {url: `154719.mp4`},word: "Journalism",},
  {video: {url: `185599.mp4`},word: "Pamphlet",},
  {video: {url: `111038.mp4`},word: "Newscaster",},
  {video: {url: `154575.mp4`},word: "Encyclopaedia",},
  {video: {url: `244471.mp4`},word: "Exclusive right",},
  {video: {url: `96113.mp4`},word: "Controversy",},
  {video: {url: `155701.mp4`},word: "Valid data",},
  {video: {url: `154746.mp4`},word: "Librarian",},
  {video: {url: `154747.mp4`},word: "Library loan",},
  {video: {url: `153047.mp4`},word: "Advertising rates",},
  {video: {url: `154748.mp4`},word: "Library science",},
  {video: {url: `155620.mp4`},word: "Specimen",},
  {video: {url: `153636.mp4`},word: "Newsletter",},
  {video: {url: `89825.mp4`},word: "Journalist",},
  {video: {url: `93234.mp4`},word: "Clue",},
  {video: {url: `153115.mp4`},word: "Billboard",},
  {video: {url: `153680.mp4`},word: "Producer",},
  {video: {url: `155604.mp4`},word: "Small ads",},
  {video: {url: `48557.mp4`},word: "Library",},
  {video: {url: `153046.mp4`},word: "Advertising manager",},
  {video: {url: `245153.mp4`},word: "Tabloid",},
  {video: {url: `244005.mp4`},word: "Ad",},
  {video: {url: `89863.mp4`},word: "News",},
  {video: {url: `155692.mp4`},word: "Translation rights",},
  {video: {url: `153210.mp4`},word: "Brand",},
  {video: {url: `246013.mp4`},word: "Mass culture",},
  {video: {url: `116956.mp4`},word: "Source",},
  {video: {url: `48815.mp4`},word: "Newspaper",},
  {video: {url: `48570.mp4`},word: "Magazine",},
  {video: {url: `154634.mp4`},word: "Foreign rights",},
  {video: {url: `157965.mp4`},word: "Press release",},
  {video: {url: `154431.mp4`},word: "Contributor",},
  {video: {url: `58318.mp4`},word: "Interview",},
  {video: {url: `157964.mp4`},word: "Press kit",},
  {video: {url: `155562.mp4`},word: "Scheduled publication",},
  {video: {url: `125605.mp4`},word: "Bulletin board",},
  {video: {url: `246012.mp4`},word: "Mass media",},
  {video: {url: `244382.mp4`},word: "Documentation",},
  {video: {url: `154430.mp4`},word: "Context",},
  {video: {url: `153280.mp4`},word: "Digital library",},
  {video: {url: `246058.mp4`},word: "List broker",},
  {video: {url: `246057.mp4`},word: "List building",},
  {video: {url: `158023.mp4`},word: "Reference",},
  {video: {url: `89498.mp4`},word: "Advertising",},
  {video: {url: `96298.mp4`},word: "Editor",},
  {video: {url: `154594.mp4`},word: "E-zine",},
  {video: {url: `93242.mp4`},word: "Commercial",},
  {video: {url: `153677.mp4`},word: "Press",},
  {video: {url: `116433.mp4`},word: "Publicity",},
  {video: {url: `93312.mp4`},word: "Document",},
  {video: {url: `154400.mp4`},word: "Chief editor",},
  {video: {url: `96935.mp4`},word: "Manuscript",},
  {video: {url: `244408.mp4`},word: "Electronic media",},
  {video: {url: `185984.mp4`},word: "Creative commons",},
  {video: {url: `154678.mp4`},word: "Headline",},
  {video: {url: `154677.mp4`},word: "Heading",},
  {video: {url: `244266.mp4`},word: "Contextual information",},
  {video: {url: `155652.mp4`},word: "Supplements",},
  {video: {url: `51937.mp4`},word: "Information",},
  {video: {url: `116526.mp4`},word: "Reporter",},
  {video: {url: `65878.mp4`},word: "File",},
  {video: {url: `157931.mp4`},word: "Plagiarism",},
  {video: {url: `94041.mp4`},word: "Survey",},
  {video: {url: `244416.mp4`},word: "Editorial director",},
  {video: {url: `153066.mp4`},word: "Archive",},
  {video: {url: `154407.mp4`},word: "Classified advertising",},
  {video: {url: `153106.mp4`},word: "Banned books",},
  {video: {url: `111160.mp4`},word: "Old medias",},
  {video: {url: `157862.mp4`},word: "New media",},
  {video: {url: `246022.mp4`},word: "Managing editor",},
  {video: {url: `244417.mp4`},word: "Editorial",},
  {video: {url: `244418.mp4`},word: "Edition",},
  {video: {url: `157987.mp4`},word: "Publication",},
  {video: {url: `245736.mp4`},word: "Public library",},
  {video: {url: `157990.mp4`},word: "Publicist",},
  {video: {url: `118396.mp4`},word: "Album",},
  {video: {url: `69437.mp4`},word: "Remote control",},
  {video: {url: `154735.mp4`},word: "Learning centre",},
  {video: {url: `153624.mp4`},word: "Media",},
  {video: {url: `158025.mp4`},word: "Reference library",},
  {video: {url: `245133.mp4`},word: "Television presenter",},
  {video: {url: `153258.mp4`},word: "Copyright",},
  {video: {url: `154757.mp4`},word: "Literature search",},
  {video: {url: `157924.mp4`},word: "Photojournalism",},
  {video: {url: `125558.mp4`},word: "Brochure",},
  {video: {url: `91399.mp4`},word: "Fact",},
  {video: {url: `246008.mp4`},word: "Master copy",},
  {video: {url: `157842.mp4`},word: "Merchandising rights",},
  {video: {url: `122572.mp4`},word: "Article",},
  {video: {url: `93004.mp4`},word: "Feedback",},
  {video: {url: `50766.mp4`},word: "Butcher",},
  {video: {url: `125842.mp4`},word: "Cat",},
  {video: {url: `73375.mp4`},word: "Slaughter",},
  {video: {url: `97106.mp4`},word: "Wine-vault",},
  {video: {url: `89795.mp4`},word: "Brewery",},
  {video: {url: `92766.mp4`},word: "Wineroom",},
  {video: {url: `245757.mp4`},word: "Processed food",},
  {video: {url: `91655.mp4`},word: "Tin",},
  {video: {url: `244537.mp4`},word: "Fresh food",},
  {video: {url: `93165.mp4`},word: "Indian",},
  {video: {url: `62499.mp4`},word: "Asian",},
  {video: {url: `47584.mp4`},word: "English",},
  {video: {url: `244830.mp4`},word: "Roman",},
  {video: {url: `116355.mp4`},word: "Polish",},
  {video: {url: `96556.mp4`},word: "Hispanic",},
  {video: {url: `51350.mp4`},word: "British",},
  {video: {url: `92697.mp4`},word: "International",},
  {video: {url: `58411.mp4`},word: "Foreign",},
  {video: {url: `97083.mp4`},word: "Danish",},
  {video: {url: `92934.mp4`},word: "Native",},
  {video: {url: `65930.mp4`},word: "Universal",},
  {video: {url: `96615.mp4`},word: "Inhabit",},
  {video: {url: `96167.mp4`},word: "Custom",},
  {video: {url: `93112.mp4`},word: "Gypsy",},
  {video: {url: `92677.mp4`},word: "Frenchman",},
  {video: {url: `244899.mp4`},word: "Greek",},
  {video: {url: `94219.mp4`},word: "Diversity",},
  {video: {url: `96553.mp4`},word: "Hindu",},
  {video: {url: `125976.mp4`},word: "Chief-boss",},
  {video: {url: `245924.mp4`},word: "Nomad",},
  {video: {url: `90110.mp4`},word: "Tradition",},
  {video: {url: `58505.mp4`},word: "German",},
  {video: {url: `51448.mp4`},word: "Citizen",},
  {video: {url: `244353.mp4`},word: "Deaf community",},
  {video: {url: `117118.mp4`},word: "Swede",},
  {video: {url: `110997.mp4`},word: "Nation",},
  {video: {url: `243939.mp4`},word: "Indian",},
  {video: {url: `245942.mp4`},word: "National costume",},
  {video: {url: `58275.mp4`},word: "Community",},
  {video: {url: `90128.mp4`},word: "Tribe",},
  {video: {url: `93275.mp4`},word: "Deaf culture",},
  {video: {url: `96067.mp4`},word: "Colony",},
  {video: {url: `93285.mp4`},word: "Delaware",},
  {video: {url: `245714.mp4`},word: "Race",},
  {video: {url: `51878.mp4`},word: "Culture",},
  {video: {url: `62275.mp4`},word: "Anthropology",},
  {video: {url: `245958.mp4`},word: "Mother country",},
  {video: {url: `185120.mp4`},word: "Nationality",},
  {video: {url: `116801.mp4`},word: "Scot",},
  {video: {url: `116244.mp4`},word: "Patriot",},
  {video: {url: `244521.mp4`},word: "Folk costume",},
  {video: {url: `93190.mp4`},word: "Antique",},
  {video: {url: `244830.mp4`},word: "Roman",},
  {video: {url: `62272.mp4`},word: "Ancient",},
  {video: {url: `154406.mp4`},word: "Classical",},
  {video: {url: `96338.mp4`},word: "Excavate",},
  {video: {url: `96150.mp4`},word: "Crucify",},
  {video: {url: `245817.mp4`},word: "Pharaoh",},
  {video: {url: `244812.mp4`},word: "Slavery",},
  {video: {url: `244829.mp4`},word: "Roman emperor",},
  {video: {url: `244813.mp4`},word: "Slave",},
  {video: {url: `245126.mp4`},word: "The cold war",},
  {video: {url: `245834.mp4`},word: "Peasant",},
  {video: {url: `243938.mp4`},word: "Ice age",},
  {video: {url: `244046.mp4`},word: "Ancient literature",},
  {video: {url: `93266.mp4`},word: "Crown",},
  {video: {url: `154685.mp4`},word: "Heritage",},
  {video: {url: `65935.mp4`},word: "Yugoslavia",},
  {video: {url: `244935.mp4`},word: "Crucifixion",},
  {video: {url: `62875.mp4`},word: "Middle ages",},
  {video: {url: `62869.mp4`},word: "Historical art",},
  {video: {url: `246097.mp4`},word: "Knight",},
  {video: {url: `243968.mp4`},word: "Stone age",},
  {video: {url: `245777.mp4`},word: "Prehistory",},
  {video: {url: `244446.mp4`},word: "Estate owner",},
  {video: {url: `243945.mp4`},word: "Iron age",},
  {video: {url: `89899.mp4`},word: "Pirate",},
  {video: {url: `118551.mp4`},word: "Antiquity",},
  {video: {url: `62278.mp4`},word: "Archeology",},
  {video: {url: `51354.mp4`},word: "History",},
  {video: {url: `244910.mp4`},word: "Fortress",},
  {video: {url: `154659.mp4`},word: "Golden age of illustration",},
  {video: {url: `96964.mp4`},word: "Throne",},
  {video: {url: `92691.mp4`},word: "Historical monument",},
  {video: {url: `90015.mp4`},word: "The second world war",},
  {video: {url: `93302.mp4`},word: "Dinosaur",},
  {video: {url: `89534.mp4`},word: "Modern age",},
  {video: {url: `92960.mp4`},word: "Noble",},
  {video: {url: `92807.mp4`},word: "Wreath",},
  {video: {url: `244885.mp4`},word: "Kingdom",},
  {video: {url: `48588.mp4`},word: "Military",},
  {video: {url: `51450.mp4`},word: "Civil",},
  {video: {url: `93923.mp4`},word: "Defend",},
  {video: {url: `117107.mp4`},word: "Surround",},
  {video: {url: `91337.mp4`},word: "Defeat",},
  {video: {url: `96100.mp4`},word: "Conquer",},
  {video: {url: `58506.mp4`},word: "Give up",},
  {video: {url: `89804.mp4`},word: "Invade",},
  {video: {url: `116544.mp4`},word: "Retreat",},
  {video: {url: `244797.mp4`},word: "Surrender",},
  {video: {url: `116856.mp4`},word: "Shoot",},
  {video: {url: `93110.mp4`},word: "Gun",},
  {video: {url: `49180.mp4`},word: "Peace",},
  {video: {url: `244501.mp4`},word: "Fight",},
  {video: {url: `245084.mp4`},word: "Truce",},
  {video: {url: `62553.mp4`},word: "Battle",},
  {video: {url: `50937.mp4`},word: "War",},
  {video: {url: `96522.mp4`},word: "Hatchet",},
  {video: {url: `51687.mp4`},word: "Enemy",},
  {video: {url: `122556.mp4`},word: "Army",},
  {video: {url: `244835.mp4`},word: "Retreat",},
  {video: {url: `116497.mp4`},word: "Recruiter",},
  {video: {url: `116496.mp4`},word: "Recruit",},
  {video: {url: `91360.mp4`},word: "Ammunition",},
  {video: {url: `96994.mp4`},word: "Alliance",},
  {video: {url: `87695.mp4`},word: "Victory",},
  {video: {url: `90133.mp4`},word: "Troop",},
  {video: {url: `116219.mp4`},word: "Paratrooper",},
  {video: {url: `117075.mp4`},word: "Submarine",},
  {video: {url: `125774.mp4`},word: "Captain",},
  {video: {url: `116592.mp4`},word: "Rocket",},
  {video: {url: `118353.mp4`},word: "Adversary",},
  {video: {url: `92938.mp4`},word: "Navy",},
  {video: {url: `244818.mp4`},word: "Shield",},
  {video: {url: `87747.mp4`},word: "Dress parade",},
  {video: {url: `122538.mp4`},word: "Archer",},
  {video: {url: `93282.mp4`},word: "Defense",},
  {video: {url: `124988.mp4`},word: "Bomb",},
  {video: {url: `90015.mp4`},word: "The second world war",},
  {video: {url: `89980.mp4`},word: "Tank",},
  {video: {url: `93191.mp4`},word: "Arrow",},
  {video: {url: `92675.mp4`},word: "Fort",},
  {video: {url: `96568.mp4`},word: "Holster",},
  {video: {url: `97003.mp4`},word: "Armor",},
  {video: {url: `90119.mp4`},word: "Trap",},
  {video: {url: `90211.mp4`},word: "Weapon",},
  {video: {url: `87626.mp4`},word: "Shooting",},
  {video: {url: `244796.mp4`},word: "Sword",},
  {video: {url: `116948.mp4`},word: "Soldier",},
  {video: {url: `93220.mp4`},word: "Cannon",},
  {video: {url: `244806.mp4`},word: "Spear",},
  {video: {url: `62638.mp4`},word: "Bow",},
  {video: {url: `157876.mp4`},word: "On sale",},
  {video: {url: `89572.mp4`},word: "Second-hand",},
  {video: {url: `92707.mp4`},word: "Non-refundable",},
  {video: {url: `125941.mp4`},word: "Cheap",},
  {video: {url: `116946.mp4`},word: "Sold",},
  {video: {url: `125647.mp4`},word: "Buy, to",},
  {video: {url: `89767.mp4`},word: "Try on",},
  {video: {url: `51764.mp4`},word: "Purchase",},
  {video: {url: `116974.mp4`},word: "Spend",},
  {video: {url: `125932.mp4`},word: "Charge",},
  {video: {url: `244383.mp4`},word: "Do the shopping",},
  {video: {url: `157970.mp4`},word: "Price sticker",},
  {video: {url: `154409.mp4`},word: "Clearance",},
  {video: {url: `153108.mp4`},word: "Bargain",},
  {video: {url: `75021.mp4`},word: "List",},
  {video: {url: `155592.mp4`},word: "Shopping cart",},
  {video: {url: `155591.mp4`},word: "Shop assistant",},
  {video: {url: `74962.mp4`},word: "Customer",},
  {video: {url: `50840.mp4`},word: "Supermarket",},
  {video: {url: `244363.mp4`},word: "Direct selling",},
  {video: {url: `153107.mp4`},word: "Bar code",},
  {video: {url: `157967.mp4`},word: "Price label",},
  {video: {url: `245352.mp4`},word: "Sales promotion",},
  {video: {url: `90198.mp4`},word: "Watch salesperson",},
  {video: {url: `158014.mp4`},word: "Receipt",},
  {video: {url: `244651.mp4`},word: "Impulse purchase",},
  {video: {url: `50797.mp4`},word: "Clothes rack",},
  {video: {url: `50805.mp4`},word: "Shop",},
  {video: {url: `93084.mp4`},word: "Gift certificate",},
  {video: {url: `244489.mp4`},word: "Fish store",},
  {video: {url: `89602.mp4`},word: "Salesperson",},
  {video: {url: `188144.mp4`},word: "Mail order store",},
  {video: {url: `59578.mp4`},word: "Shopping bag",},
  {video: {url: `87733.mp4`},word: "Catalogue",},
  {video: {url: `184889.mp4`},word: "Pushcart",},
  {video: {url: `51870.mp4`},word: "Discount",},
  {video: {url: `155533.mp4`},word: "Retail",},
  {video: {url: `116398.mp4`},word: "Price tag",},
  {video: {url: `87749.mp4`},word: "Dressing-room",},
  {video: {url: `157878.mp4`},word: "Online bookshop",},
  {video: {url: `116857.mp4`},word: "Shopkeeper",},
  {video: {url: `116858.mp4`},word: "Shopping",},
  {video: {url: `116782.mp4`},word: "Sale",},
  {video: {url: `90173.mp4`},word: "Video store",},
  {video: {url: `155534.mp4`},word: "Retail price",},
  {video: {url: `74902.mp4`},word: "Cash-desk",},
  {video: {url: `74903.mp4`},word: "Cashier",},
  {video: {url: `116784.mp4`},word: "Salesclerk",},
  {video: {url: `155712.mp4`},word: "Warehouse",},
  {video: {url: `116783.mp4`},word: "Sales counter",},
  {video: {url: `155615.mp4`},word: "Special offer",},
  {video: {url: `74882.mp4`},word: "Basket",},
  {video: {url: `157882.mp4`},word: "Online shopping",},
  {video: {url: `244229.mp4`},word: "Clothes shop",},
  {video: {url: `245295.mp4`},word: "Shopping centre",},
  {video: {url: `245186.mp4`},word: "Street market",},
  {video: {url: `157968.mp4`},word: "Price list",},
  {video: {url: `89978.mp4`},word: "Tame",},
  {video: {url: `90185.mp4`},word: "Waddle",},
  {video: {url: `96615.mp4`},word: "Inhabit",},
  {video: {url: `93105.mp4`},word: "Growl",},
  {video: {url: `116587.mp4`},word: "Roar",},
  {video: {url: `188379.mp4`},word: "Carcinology",},
  {video: {url: `96262.mp4`},word: "Dog dish",},
  {video: {url: `97018.mp4`},word: "Beast",},
  {video: {url: `187264.mp4`},word: "Migration",},
  {video: {url: `62734.mp4`},word: "Claw",},
  {video: {url: `93316.mp4`},word: "Doghouse",},
  {video: {url: `93315.mp4`},word: "Dog water bowl",},
  {video: {url: `155720.mp4`},word: "Wild animal",},
  {video: {url: `62273.mp4`},word: "Animal trainer",},
  {video: {url: `96984.mp4`},word: "Veterinarian",},
  {video: {url: `69484.mp4`},word: "Critically endangered species",},
  {video: {url: `116283.mp4`},word: "Pet toy",},
  {video: {url: `116284.mp4`},word: "Pet water bowl",},
  {video: {url: `118511.mp4`},word: "Animal",},
  {video: {url: `244457.mp4`},word: "Endangered animal",},
  {video: {url: `187141.mp4`},word: "Flock",},
  {video: {url: `96999.mp4`},word: "Antler",},
  {video: {url: `51922.mp4`},word: "Instinct",},
  {video: {url: `96144.mp4`},word: "Creep",},
  {video: {url: `191376.mp4`},word: "Pooper scooper",},
  {video: {url: `187333.mp4`},word: "Predator",},
  {video: {url: `93302.mp4`},word: "Dinosaur",},
  {video: {url: `89638.mp4`},word: "Herd",},
  {video: {url: `125804.mp4`},word: "Carnivore",},
  {video: {url: `89976.mp4`},word: "Tail",},
  {video: {url: `116282.mp4`},word: "Pet food dish",},
  {video: {url: `116281.mp4`},word: "Pet food",},
  {video: {url: `116278.mp4`},word: "Pet bed",},
  {video: {url: `116285.mp4`},word: "Pet",},
  {video: {url: `92997.mp4`},word: "Fang",},
  {video: {url: `116279.mp4`},word: "Pet care",},
  {video: {url: `96580.mp4`},word: "Howl",},
  {video: {url: `90008.mp4`},word: "Territory",},
  {video: {url: `94027.mp4`},word: "Snail",},
  {video: {url: `118510.mp4`},word: "Animal kingdom",},
  {video: {url: `125662.mp4`},word: "Cage",},
  {video: {url: `116989.mp4`},word: "Spots on animals",},
  {video: {url: `116246.mp4`},word: "Paw",},
  {video: {url: `116280.mp4`},word: "Pet carrier",},
  {video: {url: `116249.mp4`},word: "Pawprint",},
  {video: {url: `118509.mp4`},word: "Animal behaviour",},
  {video: {url: `59548.mp4`},word: "Pour off, to",},
  {video: {url: `117059.mp4`},word: "Streamers",},
  {video: {url: `157989.mp4`},word: "Publication year",},
  {video: {url: `155727.mp4`},word: "Work schendule",},
  {video: {url: `51086.mp4`},word: "Worm",},
  {video: {url: `73379.mp4`},word: "Spider",},
  {video: {url: `50856.mp4`},word: "Crocodile",},
  {video: {url: `73307.mp4`},word: "Fly",},
  {video: {url: `125592.mp4`},word: "Bug",},
  {video: {url: `187245.mp4`},word: "Insecticide",},
  {video: {url: `96060.mp4`},word: "Cocoon",},
  {video: {url: `96276.mp4`},word: "Dragonfly",},
  {video: {url: `97020.mp4`},word: "Beetle",},
  {video: {url: `93203.mp4`},word: "Beehive",},
  {video: {url: `51357.mp4`},word: "Beekeeper",},
  {video: {url: `50597.mp4`},word: "Ant",},
  {video: {url: `155603.mp4`},word: "Slow-worm",},
  {video: {url: `154566.mp4`},word: "Earthworm",},
  {video: {url: `65888.mp4`},word: "Lizard",},
  {video: {url: `93030.mp4`},word: "Fleas",},
  {video: {url: `89653.mp4`},word: "Tick",},
  {video: {url: `116800.mp4`},word: "Scorpion",},
  {video: {url: `96408.mp4`},word: "Gecko",},
  {video: {url: `92905.mp4`},word: "Mosquito",},
  {video: {url: `73329.mp4`},word: "Insect",},
  {video: {url: `122587.mp4`},word: "Asp",},
  {video: {url: `125644.mp4`},word: "Butterfly",},
  {video: {url: `96317.mp4`},word: "Entomologist",},
  {video: {url: `245410.mp4`},word: "Reptile",},
  {video: {url: `187321.mp4`},word: "Plague",},
  {video: {url: `51191.mp4`},word: "Bug spray",},
  {video: {url: `244149.mp4`},word: "Caterpillar",},
  {video: {url: `69473.mp4`},word: "Beekeeping",},
  {video: {url: `118409.mp4`},word: "Alligator",},
  {video: {url: `90089.mp4`},word: "Toad",},
  {video: {url: `90126.mp4`},word: "Tree frog",},
  {video: {url: `90102.mp4`},word: "Tortoise",},
  {video: {url: `50813.mp4`},word: "Snake",},
  {video: {url: `188140.mp4`},word: "Louse",},
  {video: {url: `50931.mp4`},word: "Turtle",},
  {video: {url: `96963.mp4`},word: "Tarantula",},
  {video: {url: `90197.mp4`},word: "Wasp",},
  {video: {url: `73395.mp4`},word: "Viper",},
  {video: {url: `123631.mp4`},word: "Bee",},
  {video: {url: `116976.mp4`},word: "Spider web",},
  {video: {url: `116257.mp4`},word: "Peck",},
  {video: {url: `93002.mp4`},word: "Feather",},
  {video: {url: `62559.mp4`},word: "Beak",},
  {video: {url: `124323.mp4`},word: "Bird",},
  {video: {url: `93984.mp4`},word: "Parrot",},
  {video: {url: `244987.mp4`},word: "Wing",},
  {video: {url: `96529.mp4`},word: "Hawk",},
  {video: {url: `245332.mp4`},word: "Seagull",},
  {video: {url: `69480.mp4`},word: "Cock",},
  {video: {url: `154614.mp4`},word: "Flamingo",},
  {video: {url: `74979.mp4`},word: "Duck",},
  {video: {url: `244614.mp4`},word: "Gull",},
  {video: {url: `74917.mp4`},word: "Chicken",},
  {video: {url: `62591.mp4`},word: "Birdcage",},
  {video: {url: `124679.mp4`},word: "Birdhouse",},
  {video: {url: `51907.mp4`},word: "Eagle",},
  {video: {url: `62590.mp4`},word: "Birdbath",},
  {video: {url: `49184.mp4`},word: "Penguin",},
  {video: {url: `154527.mp4`},word: "Cuckoo",},
  {video: {url: `65882.mp4`},word: "Hen",},
  {video: {url: `92946.mp4`},word: "Nest",},
  {video: {url: `49170.mp4`},word: "Owl",},
  {video: {url: `96388.mp4`},word: "Falcon",},
  {video: {url: `89898.mp4`},word: "Pigeon",},
  {video: {url: `96270.mp4`},word: "Dove",},
  {video: {url: `73401.mp4`},word: "Woodpecker",},
  {video: {url: `59536.mp4`},word: "Pheasant",},
  {video: {url: `157907.mp4`},word: "Peacock",},
  {video: {url: `157913.mp4`},word: "Pelican",},
  {video: {url: `50929.mp4`},word: "Turkey",},
  {video: {url: `155657.mp4`},word: "Swan",},
  {video: {url: `116210.mp4`},word: "Parakeet",},
  {video: {url: `65895.mp4`},word: "Ostrich",},
  {video: {url: `125970.mp4`},word: "Chick",},
  {video: {url: `116589.mp4`},word: "Robin",},
  {video: {url: `116898.mp4`},word: "Skunk",},
  {video: {url: `154763.mp4`},word: "Lynx",},
  {video: {url: `245715.mp4`},word: "Raccoon dog",},
  {video: {url: `116202.mp4`},word: "Panther",},
  {video: {url: `154627.mp4`},word: "Flying squirrel",},
  {video: {url: `65907.mp4`},word: "Rhinoceros",},
  {video: {url: `125602.mp4`},word: "Bull",},
  {video: {url: `65880.mp4`},word: "Hamster",},
  {video: {url: `65883.mp4`},word: "Kangaroo",},
  {video: {url: `123615.mp4`},word: "Bear",},
  {video: {url: `125216.mp4`},word: "Boxer",},
  {video: {url: `93201.mp4`},word: "Beaver",},
  {video: {url: `246024.mp4`},word: "Mammoth",},
  {video: {url: `96068.mp4`},word: "Colt",},
  {video: {url: `93147.mp4`},word: "Horseshoe",},
  {video: {url: `90193.mp4`},word: "Walrus",},
  {video: {url: `245331.mp4`},word: "Seal",},
  {video: {url: `157929.mp4`},word: "Piglet",},
  {video: {url: `158002.mp4`},word: "Ram",},
  {video: {url: `92839.mp4`},word: "Mammal",},
  {video: {url: `110189.mp4`},word: "Mane",},
  {video: {url: `59634.mp4`},word: "Veal",},
  {video: {url: `116815.mp4`},word: "Seeing-eye dog",},
  {video: {url: `153652.mp4`},word: "Panda",},
  {video: {url: `245726.mp4`},word: "Puma",},
  {video: {url: `153056.mp4`},word: "American bison",},
  {video: {url: `110511.mp4`},word: "Mule",},
  {video: {url: `91374.mp4`},word: "Badger",},
  {video: {url: `191380.mp4`},word: "Porpoise",},
  {video: {url: `116370.mp4`},word: "Porcupine",},
  {video: {url: `89618.mp4`},word: "Boar",},
  {video: {url: `118545.mp4`},word: "Antelope",},
  {video: {url: `96560.mp4`},word: "Hog",},
  {video: {url: `51345.mp4`},word: "Bat",},
  {video: {url: `116373.mp4`},word: "Possum",},
  {video: {url: `125720.mp4`},word: "Camel",},
  {video: {url: `96126.mp4`},word: "Cougar",},
  {video: {url: `51188.mp4`},word: "Buffalo",},
  {video: {url: `93095.mp4`},word: "Gorilla",},
  {video: {url: `51097.mp4`},word: "Whale",},
  {video: {url: `125616.mp4`},word: "Bunny rabbit",},
  {video: {url: `48561.mp4`},word: "Lion",},
  {video: {url: `51134.mp4`},word: "Dolphin",},
  {video: {url: `125842.mp4`},word: "Cat",},
  {video: {url: `154598.mp4`},word: "Ferret",},
  {video: {url: `157892.mp4`},word: "Otter",},
  {video: {url: `48597.mp4`},word: "Monkey",},
  {video: {url: `155721.mp4`},word: "Wild boar",},
  {video: {url: `245371.mp4`},word: "Roe",},
  {video: {url: `116595.mp4`},word: "Rodent",},
  {video: {url: `126002.mp4`},word: "Chipmunk",},
  {video: {url: `51080.mp4`},word: "Wolf",},
  {video: {url: `65936.mp4`},word: "Zebra",},
  {video: {url: `116348.mp4`},word: "Polar bear",},
  {video: {url: `154696.mp4`},word: "Hyena",},
  {video: {url: `93217.mp4`},word: "Calf",},
  {video: {url: `153068.mp4`},word: "Arctic fox",},
  {video: {url: `74983.mp4`},word: "Elk",},
  {video: {url: `89947.mp4`},word: "Sheep",},
  {video: {url: `116362.mp4`},word: "Pony",},
  {video: {url: `116458.mp4`},word: "Raccoon",},
  {video: {url: `51197.mp4`},word: "Bunny",},
  {video: {url: `73310.mp4`},word: "Fox",},
  {video: {url: `49272.mp4`},word: "Reindeer",},
  {video: {url: `51946.mp4`},word: "Koala",},
  {video: {url: `245012.mp4`},word: "Weasel",},
  {video: {url: `244882.mp4`},word: "Leopard",},
  {video: {url: `153116.mp4`},word: "Billy goat",},
  {video: {url: `191326.mp4`},word: "Platypus",},
  {video: {url: `51947.mp4`},word: "Lamb",},
  {video: {url: `69476.mp4`},word: "Cattle",},
  {video: {url: `116448.mp4`},word: "Puppy",},
  {video: {url: `96155.mp4`},word: "Cub",},
  {video: {url: `65922.mp4`},word: "Squirrel",},
  {video: {url: `49190.mp4`},word: "Pig",},
  {video: {url: `49267.mp4`},word: "Rat",},
  {video: {url: `48605.mp4`},word: "Mouse",},
  {video: {url: `96055.mp4`},word: "Cheetah",},
  {video: {url: `50894.mp4`},word: "Giraffe",},
  {video: {url: `96424.mp4`},word: "Gopher",},
  {video: {url: `73319.mp4`},word: "Hare",},
  {video: {url: `184813.mp4`},word: "Mutation",},
  {video: {url: `109880.mp4`},word: "F1 generation",},
  {video: {url: `154654.mp4`},word: "Gene",},
  {video: {url: `125929.mp4`},word: "Characteristic",},
  {video: {url: `109700.mp4`},word: "Dna extraction",},
  {video: {url: `109699.mp4`},word: "Dna sample",},
  {video: {url: `109881.mp4`},word: "F2 generation",},
  {video: {url: `116568.mp4`},word: "Rna",},
  {video: {url: `73530.mp4`},word: "You have a big alcohol problem",},
  {video: {url: `94059.mp4`},word: "Worn out",},
  {video: {url: `96590.mp4`},word: "Hysterical",},
  {video: {url: `51883.mp4`},word: "Depressed",},
  {video: {url: `93913.mp4`},word: "Crazy",},
  {video: {url: `90212.mp4`},word: "Weary",},
  {video: {url: `244811.mp4`},word: "Sober",},
  {video: {url: `74976.mp4`},word: "Drunk",},
  {video: {url: `65934.mp4`},word: "Xenophobic",},
  {video: {url: `185114.mp4`},word: "Mentally handicapped",},
  {video: {url: `92869.mp4`},word: "Mental",},
  {video: {url: `116216.mp4`},word: "Paranoid",},
  {video: {url: `116426.mp4`},word: "Psychedelic",},
  {video: {url: `93183.mp4`},word: "Addicted",},
  {video: {url: `48812.mp4`},word: "Nervous",},
  {video: {url: `89868.mp4`},word: "Obsessed",},
  {video: {url: `123142.mp4`},word: "Backslide",},
  {video: {url: `63578.mp4`},word: "Smoke",},
  {video: {url: `48517.mp4`},word: "Forget",},
  {video: {url: `96586.mp4`},word: "Hypnotize",},
  {video: {url: `96484.mp4`},word: "Hallucinate",},
  {video: {url: `96195.mp4`},word: "Depress",},
  {video: {url: `244924.mp4`},word: "Drunkenness",},
  {video: {url: `93327.mp4`},word: "Drug prevention",},
  {video: {url: `93328.mp4`},word: "Drug user",},
  {video: {url: `90091.mp4`},word: "Tobacco",},
  {video: {url: `94256.mp4`},word: "Intellectual disability",},
  {video: {url: `96584.mp4`},word: "Hypnosis",},
  {video: {url: `59583.mp4`},word: "Smokers",},
  {video: {url: `91747.mp4`},word: "Drug addiction",},
  {video: {url: `91699.mp4`},word: "Anxiety",},
  {video: {url: `189403.mp4`},word: "Mental retardation",},
  {video: {url: `109678.mp4`},word: "Cigarr smoking",},
  {video: {url: `109677.mp4`},word: "Cigarette smoking",},
  {video: {url: `185115.mp4`},word: "Mental health",},
  {video: {url: `185113.mp4`},word: "Mental disorder",},
  {video: {url: `116312.mp4`},word: "Pipe smoking",},
  {video: {url: `91824.mp4`},word: "Schizophrenia",},
  {video: {url: `116430.mp4`},word: "Psychology experiment",},
  {video: {url: `116429.mp4`},word: "Psychologist",},
  {video: {url: `58425.mp4`},word: "Depression",},
  {video: {url: `91686.mp4`},word: "Alcoholic",},
  {video: {url: `116428.mp4`},word: "Psychiatry",},
  {video: {url: `116427.mp4`},word: "Psychiatrist",},
  {video: {url: `51817.mp4`},word: "Hyperactivity",},
  {video: {url: `155594.mp4`},word: "Short-term memory",},
  {video: {url: `91829.mp4`},word: "Stress",},
  {video: {url: `244020.mp4`},word: "Alcohol dependence",},
  {video: {url: `110222.mp4`},word: "Mind",},
  {video: {url: `244799.mp4`},word: "Suicide",},
  {video: {url: `58260.mp4`},word: "Addiction",},
  {video: {url: `91774.mp4`},word: "Hangover",},
  {video: {url: `51446.mp4`},word: "Cigarette",},
  {video: {url: `244810.mp4`},word: "Sobriety",},
  {video: {url: `96588.mp4`},word: "Hysteric",},
  {video: {url: `244223.mp4`},word: "Cold turkey",},
  {video: {url: `91773.mp4`},word: "Hallucination",},
  {video: {url: `91733.mp4`},word: "Counselling session",},
  {video: {url: `244336.mp4`},word: "Detoxification",},
  {video: {url: `189442.mp4`},word: "Phobia",},
  {video: {url: `245241.mp4`},word: "Social phobia",},
  {video: {url: `92799.mp4`},word: "Wit",},
  {video: {url: `91701.mp4`},word: "Asylum",},
  {video: {url: `93904.mp4`},word: "Cigar",},
  {video: {url: `122507.mp4`},word: "Apathy",},
  {video: {url: `91687.mp4`},word: "Alcoholism",},
  {video: {url: `189417.mp4`},word: "Neurosis",},
  {video: {url: `90157.mp4`},word: "Users",},
  {video: {url: `91750.mp4`},word: "Early intervention",},
  {video: {url: `51617.mp4`},word: "Psychology",},
  {video: {url: `185116.mp4`},word: "Mental illness",},
  {video: {url: `245965.mp4`},word: "Mood disorder",},
  {video: {url: `245416.mp4`},word: "Relapse",},
  {video: {url: `91693.mp4`},word: "Amnesia",},
  {video: {url: `244398.mp4`},word: "Dual personality",},
  {video: {url: `49157.mp4`},word: "Obsession",},
  {video: {url: `244343.mp4`},word: "Dependent user",},
  {video: {url: `62534.mp4`},word: "Bad habits",},
  {video: {url: `185305.mp4`},word: "Therapy",},
  {video: {url: `185269.mp4`},word: "Self-help group",},
  {video: {url: `245178.mp4`},word: "Substance abuse",},
  {video: {url: `244086.mp4`},word: "Binge eating",},
  {video: {url: `51928.mp4`},word: "Iceland",},
  {video: {url: `48537.mp4`},word: "Japan",},
  {video: {url: `89633.mp4`},word: "Fertilize",},
  {video: {url: `90077.mp4`},word: "Till",},
  {video: {url: `245978.mp4`},word: "Milk",},
  {video: {url: `125538.mp4`},word: "Breed",},
  {video: {url: `73349.mp4`},word: "Pick",},
  {video: {url: `116477.mp4`},word: "Ranch",},
  {video: {url: `187581.mp4`},word: "Sickle",},
  {video: {url: `187135.mp4`},word: "Field",},
  {video: {url: `73350.mp4`},word: "Ph soil",},
  {video: {url: `69478.mp4`},word: "Cattle breed",},
  {video: {url: `63638.mp4`},word: "Farm",},
  {video: {url: `73370.mp4`},word: "Sheep dip",},
  {video: {url: `189561.mp4`},word: "Crop dusting",},
  {video: {url: `189558.mp4`},word: "Culling",},
  {video: {url: `187274.mp4`},word: "Mulch",},
  {video: {url: `73333.mp4`},word: "Manure",},
  {video: {url: `187219.mp4`},word: "Harvester",},
  {video: {url: `187271.mp4`},word: "Mineral fertilizer",},
  {video: {url: `89660.mp4`},word: "Organic fertilizer",},
  {video: {url: `63640.mp4`},word: "Farmer",},
  {video: {url: `96068.mp4`},word: "Colt",},
  {video: {url: `69479.mp4`},word: "Chicken breeding",},
  {video: {url: `89638.mp4`},word: "Herd",},
  {video: {url: `63641.mp4`},word: "Farmhouse",},
  {video: {url: `73305.mp4`},word: "Feed",},
  {video: {url: `73375.mp4`},word: "Slaughter",},
  {video: {url: `73320.mp4`},word: "Harvest",},
  {video: {url: `73336.mp4`},word: "Milking machine",},
  {video: {url: `189544.mp4`},word: "Dungheap",},
  {video: {url: `89627.mp4`},word: "Crop storage",},
  {video: {url: `153048.mp4`},word: "Agricultural building",},
  {video: {url: `187205.mp4`},word: "Grazing land",},
  {video: {url: `73384.mp4`},word: "Stable",},
  {video: {url: `73386.mp4`},word: "Straw",},
  {video: {url: `69471.mp4`},word: "Barn",},
  {video: {url: `187583.mp4`},word: "Silo",},
  {video: {url: `90188.mp4`},word: "Wagon",},
  {video: {url: `187105.mp4`},word: "Fallow",},
  {video: {url: `244281.mp4`},word: "Cowbell",},
  {video: {url: `73303.mp4`},word: "Farmyard",},
  {video: {url: `93260.mp4`},word: "Cowboy",},
  {video: {url: `187586.mp4`},word: "Soil permeability",},
  {video: {url: `187216.mp4`},word: "Harrow",},
  {video: {url: `187325.mp4`},word: "Plough",},
  {video: {url: `188267.mp4`},word: "Semination",},
  {video: {url: `244148.mp4`},word: "Cattle-breeding",},
  {video: {url: `188411.mp4`},word: "Sheepshearer",},
  {video: {url: `123322.mp4`},word: "Barley",},
  {video: {url: `73301.mp4`},word: "Farmer's market",},
  {video: {url: `63639.mp4`},word: "Farm-hand",},
  {video: {url: `51403.mp4`},word: "Cattle crossing",},
  {video: {url: `187821.mp4`},word: "Fertilizer",},
  {video: {url: `110438.mp4`},word: "Mousetrap",},
  {video: {url: `187237.mp4`},word: "Humus",},
  {video: {url: `73323.mp4`},word: "Haystack",},
  {video: {url: `73352.mp4`},word: "Pitchfork",},
  {video: {url: `73322.mp4`},word: "Hay bale",},
  {video: {url: `187315.mp4`},word: "Pigsty",},
  {video: {url: `73321.mp4`},word: "Hay",},
  {video: {url: `187202.mp4`},word: "Granary",},
  {video: {url: `187178.mp4`},word: "Furrow",},
  {video: {url: `125957.mp4`},word: "Chemical treatment of crops",},
  {video: {url: `187156.mp4`},word: "Fodder",},
  {video: {url: `187303.mp4`},word: "Pasture",},
  {video: {url: `69468.mp4`},word: "Agriculture",},
  {video: {url: `187165.mp4`},word: "Forage",},
  {video: {url: `89626.mp4`},word: "Crop rotation",},
  {video: {url: `187582.mp4`},word: "Silage",},
  {video: {url: `187299.mp4`},word: "Paddock",},
  {video: {url: `63642.mp4`},word: "Farming",},
  {video: {url: `245977.mp4`},word: "Millet",},
  {video: {url: `245157.mp4`},word: "Sustainable agriculture",},
  {video: {url: `244819.mp4`},word: "Shepherd",},
  {video: {url: `125539.mp4`},word: "Breeding",},
  {video: {url: `245298.mp4`},word: "Shepherd boy",},
  {video: {url: `69476.mp4`},word: "Cattle",},
  {video: {url: `123212.mp4`},word: "Baler",},
  {video: {url: `187324.mp4`},word: "Plantation",},
  {video: {url: `89639.mp4`},word: "Horticulture",},
  {video: {url: `73302.mp4`},word: "Farmland",},
  {video: {url: `189556.mp4`},word: "Cultivation",},
  {video: {url: `89625.mp4`},word: "Crop",},
  {video: {url: `118512.mp4`},word: "Animal husbandry",},
  {video: {url: `73331.mp4`},word: "Irrigation",},
  {video: {url: `187256.mp4`},word: "Livestock",},
  {video: {url: `89647.mp4`},word: "Organic farming",},
  {video: {url: `189557.mp4`},word: "Cultivator",},
  {video: {url: `74980.mp4`},word: "Eat, to",},
  {video: {url: `154600.mp4`},word: "Field work",},
  {video: {url: `153260.mp4`},word: "Counseling",},
  {video: {url: `245942.mp4`},word: "National costume",},
  {video: {url: `48559.mp4`},word: "Light",},
  {video: {url: `18519.mp4`},word: "Usa",},
  {video: {url: `62874.mp4`},word: "Light",},
  {video: {url: `155618.mp4`},word: "Specification",},
  {video: {url: `96633.mp4`},word: "Intrigue",},
  {video: {url: `155678.mp4`},word: "Textbook",},
  {video: {url: `91501.mp4`},word: "Gooseberries",},
  {video: {url: `245801.mp4`},word: "Plum tree",},
  {video: {url: `51646.mp4`},word: "Story",},
  {video: {url: `92696.mp4`},word: "Intepreter",},
  {video: {url: `73338.mp4`},word: "Moist",},
  {video: {url: `58435.mp4`},word: "Dry",},
  {video: {url: `96285.mp4`},word: "Drip",},
  {video: {url: `117116.mp4`},word: "Swamp",},
  {video: {url: `116980.mp4`},word: "Splash",},
  {video: {url: `157942.mp4`},word: "Pond",},
  {video: {url: `122528.mp4`},word: "Aqua lung",},
  {video: {url: `153185.mp4`},word: "Body of water",},
  {video: {url: `154689.mp4`},word: "Hot spring",},
  {video: {url: `59641.mp4`},word: "Water",},
  {video: {url: `73293.mp4`},word: "Drainage",},
  {video: {url: `155585.mp4`},word: "Sewage",},
  {video: {url: `73397.mp4`},word: "Water cycle",},
  {video: {url: `92649.mp4`},word: "Dam",},
  {video: {url: `90199.mp4`},word: "Water barrel",},
  {video: {url: `89629.mp4`},word: "Ditch",},
  {video: {url: `97093.mp4`},word: "Ocean",},
  {video: {url: `116435.mp4`},word: "Puddle",},
  {video: {url: `125924.mp4`},word: "Channel",},
  {video: {url: `154733.mp4`},word: "Leak",},
  {video: {url: `90075.mp4`},word: "Tide",},
  {video: {url: `245146.mp4`},word: "Tap water",},
  {video: {url: `245020.mp4`},word: "Waste water",},
  {video: {url: `73330.mp4`},word: "Inundation",},
  {video: {url: `117057.mp4`},word: "Stream",},
  {video: {url: `244971.mp4`},word: "World ocean",},
  {video: {url: `244536.mp4`},word: "Fresh water",},
  {video: {url: `116468.mp4`},word: "Rain puddle",},
  {video: {url: `122529.mp4`},word: "Aquarium",},
  {video: {url: `96143.mp4`},word: "Creek",},
  {video: {url: `92643.mp4`},word: "Crossing",},
  {video: {url: `89719.mp4`},word: "Moisture",},
  {video: {url: `96120.mp4`},word: "Coral",},
  {video: {url: `244909.mp4`},word: "Fountain",},
  {video: {url: `50781.mp4`},word: "Sea",},
  {video: {url: `96387.mp4`},word: "Flow",},
  {video: {url: `96576.mp4`},word: "Hose",},
  {video: {url: `188045.mp4`},word: "Hosepipe",},
  {video: {url: `92700.mp4`},word: "Lake",},
  {video: {url: `50769.mp4`},word: "River",},
  {video: {url: `186372.mp4`},word: "Distribution box",},
  {video: {url: `245348.mp4`},word: "Saltwater",},
  {video: {url: `245349.mp4`},word: "Salt lake",},
  {video: {url: `154625.mp4`},word: "Fluid system",},
  {video: {url: `189552.mp4`},word: "Deluge",},
  {video: {url: `125746.mp4`},word: "Canal",},
  {video: {url: `154620.mp4`},word: "Flooded",},
  {video: {url: `117116.mp4`},word: "Swamp",},
  {video: {url: `58294.mp4`},word: "Environment",},
  {video: {url: `187305.mp4`},word: "Peat",},
  {video: {url: `118390.mp4`},word: "Air",},
  {video: {url: `244335.mp4`},word: "Cultural services",},
  {video: {url: `92698.mp4`},word: "Jungle",},
  {video: {url: `93129.mp4`},word: "Heaven",},
  {video: {url: `92706.mp4`},word: "National park",},
  {video: {url: `73335.mp4`},word: "Meadow",},
  {video: {url: `89652.mp4`},word: "Swamp",},
  {video: {url: `187619.mp4`},word: "Tundra",},
  {video: {url: `116226.mp4`},word: "Park ranger",},
  {video: {url: `63636.mp4`},word: "Desert",},
  {video: {url: `96574.mp4`},word: "Horizon",},
  {video: {url: `73386.mp4`},word: "Straw",},
  {video: {url: `48613.mp4`},word: "Nature",},
  {video: {url: `96950.mp4`},word: "Mound",},
  {video: {url: `92682.mp4`},word: "Glacier",},
  {video: {url: `184769.mp4`},word: "Conservation",},
  {video: {url: `154585.mp4`},word: "Environmental science",},
  {video: {url: `94272.mp4`},word: "Local environment",},
  {video: {url: `93157.mp4`},word: "Icicle",},
  {video: {url: `116914.mp4`},word: "Slope",},
  {video: {url: `244909.mp4`},word: "Fountain",},
  {video: {url: `73296.mp4`},word: "Ecosystem",},
  {video: {url: `89663.mp4`},word: "Turf",},
  {video: {url: `93033.mp4`},word: "Flood",},
  {video: {url: `187054.mp4`},word: "Erosion",},
  {video: {url: `116787.mp4`},word: "Sand block",},
  {video: {url: `73340.mp4`},word: "Nature reserve",},
  {video: {url: `244774.mp4`},word: "Wilderness",},
  {video: {url: `187574.mp4`},word: "Savanna",},
  {video: {url: `73400.mp4`},word: "Wildlife",},
  {video: {url: `89633.mp4`},word: "Fertilize",},
  {video: {url: `73365.mp4`},word: "Replant",},
  {video: {url: `188222.mp4`},word: "Plant",},
  {video: {url: `116475.mp4`},word: "Rake",},
  {video: {url: `90077.mp4`},word: "Till",},
  {video: {url: `51913.mp4`},word: "Garden",},
  {video: {url: `157935.mp4`},word: "Planting of greenery",},
  {video: {url: `73398.mp4`},word: "Watering",},
  {video: {url: `73368.mp4`},word: "Roofgarden",},
  {video: {url: `89755.mp4`},word: "Spray of water",},
  {video: {url: `187230.mp4`},word: "Herbicide",},
  {video: {url: `73350.mp4`},word: "Ph soil",},
  {video: {url: `73312.mp4`},word: "Garden centre",},
  {video: {url: `116326.mp4`},word: "Plant entrance",},
  {video: {url: `73346.mp4`},word: "Outdoor plant",},
  {video: {url: `187226.mp4`},word: "Hedge trimmer",},
  {video: {url: `187227.mp4`},word: "Hedge",},
  {video: {url: `189548.mp4`},word: "Dibble",},
  {video: {url: `187335.mp4`},word: "Pruning shear",},
  {video: {url: `73377.mp4`},word: "Small hoe",},
  {video: {url: `73317.mp4`},word: "Greenhouse",},
  {video: {url: `73314.mp4`},word: "Gardener",},
  {video: {url: `89665.mp4`},word: "Wheelbarrow",},
  {video: {url: `187274.mp4`},word: "Mulch",},
  {video: {url: `93072.mp4`},word: "Garden fork",},
  {video: {url: `93073.mp4`},word: "Garden spade",},
  {video: {url: `187821.mp4`},word: "Fertilizer",},
  {video: {url: `187576.mp4`},word: "Seedbed",},
  {video: {url: `50943.mp4`},word: "Watering can",},
  {video: {url: `187228.mp4`},word: "Hedgerow",},
  {video: {url: `73311.mp4`},word: "Front garden",},
  {video: {url: `96576.mp4`},word: "Hose",},
  {video: {url: `89742.mp4`},word: "Seed",},
  {video: {url: `187271.mp4`},word: "Mineral fertilizer",},
  {video: {url: `87882.mp4`},word: "Flowerbox",},
  {video: {url: `187840.mp4`},word: "Flowerbed",},
  {video: {url: `244327.mp4`},word: "Cutting",},
  {video: {url: `187323.mp4`},word: "Plant stalk",},
  {video: {url: `96907.mp4`},word: "Orchard",},
  {video: {url: `73371.mp4`},word: "Shovel",},
  {video: {url: `73298.mp4`},word: "Fan rake",},
  {video: {url: `187575.mp4`},word: "Seed drill",},
  {video: {url: `73315.mp4`},word: "Gardening",},
  {video: {url: `187586.mp4`},word: "Soil permeability",},
  {video: {url: `73313.mp4`},word: "Garden hose",},
  {video: {url: `188045.mp4`},word: "Hosepipe",},
  {video: {url: `187334.mp4`},word: "Pruning",},
  {video: {url: `87739.mp4`},word: "Clipper",},
  {video: {url: `73381.mp4`},word: "Sprinkler",},
  {video: {url: `89639.mp4`},word: "Horticulture",},
  {video: {url: `73359.mp4`},word: "Rake",},
  {video: {url: `91827.mp4`},word: "Spray",},
  {video: {url: `69472.mp4`},word: "Bed of flowers",},
  {video: {url: `116991.mp4`},word: "Spray bottle",},
  {video: {url: `89657.mp4`},word: "Garden shed",},
  {video: {url: `116795.mp4`},word: "Scarecrow",},
  {video: {url: `187185.mp4`},word: "Garden nursery",},
  {video: {url: `65773.mp4`},word: "Uncoloured",},
  {video: {url: `51156.mp4`},word: "Dark",},
  {video: {url: `93298.mp4`},word: "Dim",},
  {video: {url: `124964.mp4`},word: "Blue",},
  {video: {url: `62736.mp4`},word: "Clear",},
  {video: {url: `62595.mp4`},word: "Blank",},
  {video: {url: `65756.mp4`},word: "Pale",},
  {video: {url: `124740.mp4`},word: "Blond",},
  {video: {url: `89540.mp4`},word: "Primary",},
  {video: {url: `51105.mp4`},word: "Yellow",},
  {video: {url: `245885.mp4`},word: "Orange",},
  {video: {url: `50906.mp4`},word: "White",},
  {video: {url: `65766.mp4`},word: "Secondary",},
  {video: {url: `89507.mp4`},word: "Colour shade",},
  {video: {url: `89765.mp4`},word: "Tint",},
  {video: {url: `49271.mp4`},word: "Red",},
  {video: {url: `62570.mp4`},word: "Beige",},
  {video: {url: `125573.mp4`},word: "Brown",},
  {video: {url: `49224.mp4`},word: "Purple",},
  {video: {url: `49192.mp4`},word: "Pink",},
  {video: {url: `188466.mp4`},word: "Tone",},
  {video: {url: `96991.mp4`},word: "Violet",},
  {video: {url: `244933.mp4`},word: "Darkness",},
  {video: {url: `245843.mp4`},word: "Pastels",},
  {video: {url: `93923.mp4`},word: "Defend",},
  {video: {url: `116313.mp4`},word: "Pitch",},
  {video: {url: `51791.mp4`},word: "Block",},
  {video: {url: `97035.mp4`},word: "Drible",},
  {video: {url: `97062.mp4`},word: "Pass",},
  {video: {url: `87630.mp4`},word: "Shoulder pass",},
  {video: {url: `87668.mp4`},word: "Tackle",},
  {video: {url: `153271.mp4`},word: "Defensive covering",},
  {video: {url: `125922.mp4`},word: "Change direction",},
  {video: {url: `87628.mp4`},word: "Shot",},
  {video: {url: `58389.mp4`},word: "Defence",},
  {video: {url: `51538.mp4`},word: "Combined marking",},
  {video: {url: `97052.mp4`},word: "Interception of the ball",},
  {video: {url: `91317.mp4`},word: "Back row",},
  {video: {url: `153600.mp4`},word: "Jump serve",},
  {video: {url: `153640.mp4`},word: "Offensive block",},
  {video: {url: `97032.mp4`},word: "Double block",},
  {video: {url: `93282.mp4`},word: "Defense",},
  {video: {url: `87633.mp4`},word: "Single block",},
  {video: {url: `91842.mp4`},word: "Front row",},
  {video: {url: `87625.mp4`},word: "Set play",},
  {video: {url: `153796.mp4`},word: "Wrist pass",},
  {video: {url: `153684.mp4`},word: "Protection dribble",},
  {video: {url: `87629.mp4`},word: "Shot in support",},
  {video: {url: `124727.mp4`},word: "Blind pass",},
  {video: {url: `245880.mp4`},word: "Organic",},
  {video: {url: `155656.mp4`},word: "Sustainable",},
  {video: {url: `58294.mp4`},word: "Environment",},
  {video: {url: `116501.mp4`},word: "Recycling bin",},
  {video: {url: `154579.mp4`},word: "Environmental design",},
  {video: {url: `154582.mp4`},word: "Environmental impact",},
  {video: {url: `154583.mp4`},word: "Environmental impact assessment",},
  {video: {url: `154584.mp4`},word: "Environmental management",},
  {video: {url: `51388.mp4`},word: "Carpool",},
  {video: {url: `245232.mp4`},word: "Solar heating",},
  {video: {url: `189572.mp4`},word: "Compost heap",},
  {video: {url: `89623.mp4`},word: "Composting",},
  {video: {url: `73364.mp4`},word: "Renewable energy resource",},
  {video: {url: `73361.mp4`},word: "Recycling",},
  {video: {url: `187192.mp4`},word: "Geothermal energy",},
  {video: {url: `124321.mp4`},word: "Biomass",},
  {video: {url: `184769.mp4`},word: "Conservation",},
  {video: {url: `69447.mp4`},word: "Solar power station",},
  {video: {url: `69445.mp4`},word: "Solar energy",},
  {video: {url: `69446.mp4`},word: "Solar panel",},
  {video: {url: `94223.mp4`},word: "Environmental protection",},
  {video: {url: `245157.mp4`},word: "Sustainable agriculture",},
  {video: {url: `245233.mp4`},word: "Solar cell",},
  {video: {url: `89660.mp4`},word: "Organic fertilizer",},
  {video: {url: `157859.mp4`},word: "Natural resource",},
  {video: {url: `154569.mp4`},word: "Eco design",},
  {video: {url: `158034.mp4`},word: "Renewable energy",},
  {video: {url: `154580.mp4`},word: "Environmental engineering",},
  {video: {url: `154581.mp4`},word: "Environmental engineering assessment",},
  {video: {url: `157858.mp4`},word: "Natural gas",},
  {video: {url: `89647.mp4`},word: "Organic farming",},
  {video: {url: `63643.mp4`},word: "Rocky",},
  {video: {url: `154662.mp4`},word: "Granite",},
  {video: {url: `158125.mp4`},word: "Marble",},
  {video: {url: `244305.mp4`},word: "Crystal structure",},
  {video: {url: `96152.mp4`},word: "Crust",},
  {video: {url: `51550.mp4`},word: "Clay",},
  {video: {url: `245033.mp4`},word: "Volcanic cone",},
  {video: {url: `245172.mp4`},word: "Summit",},
  {video: {url: `245032.mp4`},word: "Volcanic rock",},
  {video: {url: `245034.mp4`},word: "Volcanic bomb",},
  {video: {url: `245035.mp4`},word: "Volcanic ash",},
  {video: {url: `116322.mp4`},word: "Plain",},
  {video: {url: `187249.mp4`},word: "Landscape relief",},
  {video: {url: `73393.mp4`},word: "Valley",},
  {video: {url: `116254.mp4`},word: "Peak",},
  {video: {url: `191324.mp4`},word: "Plateau",},
  {video: {url: `93078.mp4`},word: "Geology",},
  {video: {url: `97021.mp4`},word: "Canyon",},
  {video: {url: `116256.mp4`},word: "Pebble",},
  {video: {url: `48604.mp4`},word: "Mountain",},
  {video: {url: `246083.mp4`},word: "Lava",},
  {video: {url: `245074.mp4`},word: "Underground mining",},
  {video: {url: `89636.mp4`},word: "Hammock",},
  {video: {url: `245870.mp4`},word: "Outer core",},
  {video: {url: `73367.mp4`},word: "Rock",},
  {video: {url: `96134.mp4`},word: "Cove",},
  {video: {url: `116423.mp4`},word: "Prospector",},
  {video: {url: `154751.mp4`},word: "Limestone",},
  {video: {url: `93135.mp4`},word: "Hill",},
  {video: {url: `96153.mp4`},word: "Crystal",},
  {video: {url: `187054.mp4`},word: "Erosion",},
  {video: {url: `116787.mp4`},word: "Sand block",},
  {video: {url: `246120.mp4`},word: "Inner core",},
  {video: {url: `93076.mp4`},word: "Gem",},
  {video: {url: `97105.mp4`},word: "Volcano",},
  {video: {url: `92596.mp4`},word: "Alps",},
  {video: {url: `50792.mp4`},word: "Cave",},
  {video: {url: `243931.mp4`},word: "Bc",},
  {video: {url: `49006.mp4`},word: "Now",},
  {video: {url: `91352.mp4`},word: "After",},
  {video: {url: `50817.mp4`},word: "Soon",},
  {video: {url: `50890.mp4`},word: "Then",},
  {video: {url: `50754.mp4`},word: "Before",},
  {video: {url: `58569.mp4`},word: "Twice",},
  {video: {url: `89956.mp4`},word: "Still",},
  {video: {url: `63580.mp4`},word: "Sometimes",},
  {video: {url: `51112.mp4`},word: "At once",},
  {video: {url: `96333.mp4`},word: "Ever",},
  {video: {url: `118426.mp4`},word: "Always",},
  {video: {url: `91401.mp4`},word: "Fast",},
  {video: {url: `58580.mp4`},word: "Weekly",},
  {video: {url: `91353.mp4`},word: "Afterwards",},
  {video: {url: `50866.mp4`},word: "Seldom",},
  {video: {url: `65906.mp4`},word: "Rarely",},
  {video: {url: `50589.mp4`},word: "Already",},
  {video: {url: `116876.mp4`},word: "Since",},
  {video: {url: `51909.mp4`},word: "Early",},
  {video: {url: `153041.mp4`},word: "Ad",},
  {video: {url: `58586.mp4`},word: "Yet",},
  {video: {url: `49161.mp4`},word: "Often",},
  {video: {url: `48813.mp4`},word: "Never",},
  {video: {url: `48549.mp4`},word: "Later",},
  {video: {url: `122806.mp4`},word: "At last",},
  {video: {url: `96603.mp4`},word: "Immediate",},
  {video: {url: `90155.mp4`},word: "Urgent",},
  {video: {url: `89906.mp4`},word: "Punctual",},
  {video: {url: `244275.mp4`},word: "Constant",},
  {video: {url: `96391.mp4`},word: "Former",},
  {video: {url: `157917.mp4`},word: "Permanent",},
  {video: {url: `58556.mp4`},word: "Temporary",},
  {video: {url: `118518.mp4`},word: "Annual",},
  {video: {url: `244922.mp4`},word: "Everlasting",},
  {video: {url: `93925.mp4`},word: "Delayed",},
  {video: {url: `89902.mp4`},word: "Present",},
  {video: {url: `51170.mp4`},word: "Brief",},
  {video: {url: `116488.mp4`},word: "Recent",},
  {video: {url: `116400.mp4`},word: "Prime",},
  {video: {url: `48468.mp4`},word: "Continuous",},
  {video: {url: `116404.mp4`},word: "Prior",},
  {video: {url: `93998.mp4`},word: "Previous",},
  {video: {url: `94038.mp4`},word: "Sudden",},
  {video: {url: `116875.mp4`},word: "Simultaneous",},
  {video: {url: `48814.mp4`},word: "New",},
  {video: {url: `89533.mp4`},word: "Modern",},
  {video: {url: `89986.mp4`},word: "Tardy",},
  {video: {url: `48548.mp4`},word: "Late",},
  {video: {url: `93332.mp4`},word: "During",},
  {video: {url: `96063.mp4`},word: "Coincide",},
  {video: {url: `89901.mp4`},word: "Postpone",},
  {video: {url: `118340.mp4`},word: "Adjourn",},
  {video: {url: `50933.mp4`},word: "Wait",},
  {video: {url: `116412.mp4`},word: "Procrastinate",},
  {video: {url: `50876.mp4`},word: "To begin",},
  {video: {url: `117007.mp4`},word: "Stall",},
  {video: {url: `96072.mp4`},word: "Commence",},
  {video: {url: `91340.mp4`},word: "Half-hour",},
  {video: {url: `96629.mp4`},word: "Interval",},
  {video: {url: `96331.mp4`},word: "Eve",},
  {video: {url: `50879.mp4`},word: "Time",},
  {video: {url: `91425.mp4`},word: "Future",},
  {video: {url: `123643.mp4`},word: "Beginning",},
  {video: {url: `93919.mp4`},word: "Daytime",},
  {video: {url: `110432.mp4`},word: "Moment",},
  {video: {url: `96290.mp4`},word: "Dusk",},
  {video: {url: `116483.mp4`},word: "Reaction time",},
  {video: {url: `51556.mp4`},word: "Daily routine",},
  {video: {url: `92787.mp4`},word: "While",},
  {video: {url: `51803.mp4`},word: "Cycle",},
  {video: {url: `91443.mp4`},word: "Hurry",},
  {video: {url: `48599.mp4`},word: "Month",},
  {video: {url: `125906.mp4`},word: "Century",},
  {video: {url: `116273.mp4`},word: "Period",},
  {video: {url: `96157.mp4`},word: "Cuckoo clock",},
  {video: {url: `116545.mp4`},word: "Retrospect",},
  {video: {url: `50954.mp4`},word: "Week",},
  {video: {url: `93174.mp4`},word: "Instant",},
  {video: {url: `157905.mp4`},word: "Past",},
  {video: {url: `89942.mp4`},word: "Rush",},
  {video: {url: `93918.mp4`},word: "Day and night",},
  {video: {url: `90109.mp4`},word: "Tower clock",},
  {video: {url: `96176.mp4`},word: "Daylight savings time",},
  {video: {url: `63620.mp4`},word: "Measuring time",},
  {video: {url: `155683.mp4`},word: "Time-table",},
  {video: {url: `91356.mp4`},word: "Almanac",},
  {video: {url: `89669.mp4`},word: "Appointment",},
  {video: {url: `117048.mp4`},word: "Stopwatch",},
  {video: {url: `48589.mp4`},word: "Minute",},
  {video: {url: `50955.mp4`},word: "Weekend",},
  {video: {url: `91383.mp4`},word: "Eternity",},
  {video: {url: `125666.mp4`},word: "Calender",},
  {video: {url: `92753.mp4`},word: "Timetable",},
  {video: {url: `69340.mp4`},word: "Frequency",},
  {video: {url: `93922.mp4`},word: "Decayed",},
  {video: {url: `116358.mp4`},word: "Pollute",},
  {video: {url: `58502.mp4`},word: "Earthquake",},
  {video: {url: `73389.mp4`},word: "Tornado",},
  {video: {url: `118382.mp4`},word: "Air pollution",},
  {video: {url: `154582.mp4`},word: "Environmental impact",},
  {video: {url: `153036.mp4`},word: "Acid rain",},
  {video: {url: `97097.mp4`},word: "Snowslide",},
  {video: {url: `90074.mp4`},word: "Tidal wave",},
  {video: {url: `96928.mp4`},word: "Quake",},
  {video: {url: `49203.mp4`},word: "Pollution",},
  {video: {url: `189574.mp4`},word: "Climate",},
  {video: {url: `155605.mp4`},word: "Smog",},
  {video: {url: `93154.mp4`},word: "Hurricane",},
  {video: {url: `187294.mp4`},word: "Ozone hole",},
  {video: {url: `116918.mp4`},word: "Smog alert",},
  {video: {url: `73318.mp4`},word: "Greenhouse effect",},
  {video: {url: `63637.mp4`},word: "Environmental disaster",},
  {video: {url: `62511.mp4`},word: "Avalanche",},
  {video: {url: `73316.mp4`},word: "Global warming",},
  {video: {url: `65925.mp4`},word: "Storm",},
  {video: {url: `154410.mp4`},word: "Climate change",},
  {video: {url: `245419.mp4`},word: "Regional study",},
  {video: {url: `245793.mp4`},word: "Pollution load",},
  {video: {url: `123334.mp4`},word: "Base metal",},
  {video: {url: `125520.mp4`},word: "Brass",},
  {video: {url: `118412.mp4`},word: "Alloy",},
  {video: {url: `93091.mp4`},word: "Gold",},
  {video: {url: `93296.mp4`},word: "Diamond",},
  {video: {url: `94388.mp4`},word: "Carbon",},
  {video: {url: `93213.mp4`},word: "Bronze",},
  {video: {url: `118425.mp4`},word: "Aluminium",},
  {video: {url: `69421.mp4`},word: "Oxygen",},
  {video: {url: `244871.mp4`},word: "Metal",},
  {video: {url: `58472.mp4`},word: "Silver",},
  {video: {url: `157845.mp4`},word: "Mineral",},
  {video: {url: `48523.mp4`},word: "Ill",},
  {video: {url: `245130.mp4`},word: "Terminally ill",},
  {video: {url: `94300.mp4`},word: "Physically disabled",},
  {video: {url: `50808.mp4`},word: "Sick",},
  {video: {url: `50952.mp4`},word: "Unconscious",},
  {video: {url: `244360.mp4`},word: "Disabled",},
  {video: {url: `91740.mp4`},word: "Diabetic",},
  {video: {url: `91816.mp4`},word: "Paralysed",},
  {video: {url: `48471.mp4`},word: "Contagious",},
  {video: {url: `58517.mp4`},word: "Handicapped",},
  {video: {url: `96103.mp4`},word: "Constipate",},
  {video: {url: `245821.mp4`},word: "Person with reduced mobility",},
  {video: {url: `125291.mp4`},word: "Brain-damage",},
  {video: {url: `244083.mp4`},word: "Bipolar disorder",},
  {video: {url: `91783.mp4`},word: "High blood pressure",},
  {video: {url: `189375.mp4`},word: "Jaundice",},
  {video: {url: `91703.mp4`},word: "Autism",},
  {video: {url: `96104.mp4`},word: "Constipation",},
  {video: {url: `189366.mp4`},word: "Influenza",},
  {video: {url: `125751.mp4`},word: "Cancer",},
  {video: {url: `116335.mp4`},word: "Pneumonia",},
  {video: {url: `91700.mp4`},word: "Asthma",},
  {video: {url: `125601.mp4`},word: "Bulimia",},
  {video: {url: `185226.mp4`},word: "Physical disability",},
  {video: {url: `116905.mp4`},word: "Sleepwalking",},
  {video: {url: `91723.mp4`},word: "Cold",},
  {video: {url: `188340.mp4`},word: "Asphyxia",},
  {video: {url: `91776.mp4`},word: "Headcold",},
  {video: {url: `244447.mp4`},word: "Erectile dysfunction",},
  {video: {url: `94283.mp4`},word: "Neuropsychiatric disorder",},
  {video: {url: `94260.mp4`},word: "Language impairment",},
  {video: {url: `51798.mp4`},word: "Behavioral disorder",},
  {video: {url: `58286.mp4`},word: "Disabled person",},
  {video: {url: `245950.mp4`},word: "Movement disorder",},
  {video: {url: `58423.mp4`},word: "Disability",},
  {video: {url: `117042.mp4`},word: "Stomach flu",},
  {video: {url: `93140.mp4`},word: "Hiv",},
  {video: {url: `91689.mp4`},word: "Allergy sufferer",},
  {video: {url: `245308.mp4`},word: "Severe learning difficulty",},
  {video: {url: `91690.mp4`},word: "Alzheimer\u00b4s disease",},
  {video: {url: `118375.mp4`},word: "Aids",},
  {video: {url: `244222.mp4`},word: "Color blindness",},
  {video: {url: `189048.mp4`},word: "Condition",},
  {video: {url: `94342.mp4`},word: "Severe and profound learning disability",},
  {video: {url: `116479.mp4`},word: "Rash",},
  {video: {url: `93336.mp4`},word: "Ear infection",},
  {video: {url: `245416.mp4`},word: "Relapse",},
  {video: {url: `188332.mp4`},word: "Aphasia",},
  {video: {url: `94143.mp4`},word: "Multiple disabilities",},
  {video: {url: `51841.mp4`},word: "Anorexia",},
  {video: {url: `189094.mp4`},word: "Diphtheria",},
  {video: {url: `244624.mp4`},word: "Hearing loss",},
  {video: {url: `189371.mp4`},word: "Invalidity",},
  {video: {url: `189187.mp4`},word: "Epidemic",},
  {video: {url: `116308.mp4`},word: "Pinkeye",},
  {video: {url: `96318.mp4`},word: "Epilepsy",},
  {video: {url: `91688.mp4`},word: "Allergy",},
  {video: {url: `91778.mp4`},word: "Heart attack",},
  {video: {url: `244817.mp4`},word: "Sickness",},
  {video: {url: `58315.mp4`},word: "Illness",},
  {video: {url: `91720.mp4`},word: "Chronic illness",},
  {video: {url: `244080.mp4`},word: "Balance disorder",},
  {video: {url: `93212.mp4`},word: "Bronchitis",},
  {video: {url: `91696.mp4`},word: "Anaemia",},
  {video: {url: `65865.mp4`},word: "Disorder",},
  {video: {url: `91781.mp4`},word: "Hepatitis",},
  {video: {url: `94076.mp4`},word: "Autistic spectrum disorder/syndrome",},
  {video: {url: `91792.mp4`},word: "Infection",},
  {video: {url: `96147.mp4`},word: "Cripple",},
  {video: {url: `189503.mp4`},word: "Toxaemia",},
  {video: {url: `125844.mp4`},word: "Cataract",},
  {video: {url: `245796.mp4`},word: "Polio",},
  {video: {url: `94084.mp4`},word: "Cerebral palsy",},
  {video: {url: `245972.mp4`},word: "Mobility impairment",},
  {video: {url: `91782.mp4`},word: "Herpes",},
  {video: {url: `91744.mp4`},word: "Disease",},
  {video: {url: `90136.mp4`},word: "Tuberculos",},
  {video: {url: `116611.mp4`},word: "Rubella",},
  {video: {url: `97004.mp4`},word: "Arthritis",},
  {video: {url: `189433.mp4`},word: "Paralysis",},
  {video: {url: `116386.mp4`},word: "Pox",},
  {video: {url: `90138.mp4`},word: "Tumor",},
  {video: {url: `117065.mp4`},word: "Strep throat",},
  {video: {url: `96939.mp4`},word: "Measles",},
  {video: {url: `116867.mp4`},word: "Sids",},
  {video: {url: `158145.mp4`},word: "Diabetes",},
  {video: {url: `244268.mp4`},word: "Contagious disease",},
  {video: {url: `91669.mp4`},word: "Adhd",},
  {video: {url: `244422.mp4`},word: "Eating disorder",},
  {video: {url: `93036.mp4`},word: "Flu",},
  {video: {url: `74993.mp4`},word: "Food allergy",},
  {video: {url: `125974.mp4`},word: "Chicken pox",},
  {video: {url: `245930.mp4`},word: "New build",},
  {video: {url: `155625.mp4`},word: "Stable",},
  {video: {url: `245887.mp4`},word: "Operational",},
  {video: {url: `155637.mp4`},word: "Structural",},
  {video: {url: `50746.mp4`},word: "Barrier-free",},
  {video: {url: `96338.mp4`},word: "Excavate",},
  {video: {url: `93295.mp4`},word: "Develop",},
  {video: {url: `96282.mp4`},word: "Drill",},
  {video: {url: `73292.mp4`},word: "Dig",},
  {video: {url: `97025.mp4`},word: "Caulk",},
  {video: {url: `116982.mp4`},word: "Splinter",},
  {video: {url: `116805.mp4`},word: "Screw",},
  {video: {url: `116514.mp4`},word: "Reinforce",},
  {video: {url: `116250.mp4`},word: "Pave",},
  {video: {url: `93116.mp4`},word: "Hammer",},
  {video: {url: `51192.mp4`},word: "Build",},
  {video: {url: `154428.mp4`},word: "Construction progress",},
  {video: {url: `62608.mp4`},word: "Blueprint",},
  {video: {url: `155640.mp4`},word: "Structure",},
  {video: {url: `154546.mp4`},word: "Demolition",},
  {video: {url: `155670.mp4`},word: "Temporary work",},
  {video: {url: `245259.mp4`},word: "Site preparation",},
  {video: {url: `189450.mp4`},word: "Preventive inspection",},
  {video: {url: `96106.mp4`},word: "Construction site",},
  {video: {url: `155599.mp4`},word: "Site manager",},
  {video: {url: `62653.mp4`},word: "Bricklayer",},
  {video: {url: `125544.mp4`},word: "Brick wall",},
  {video: {url: `116601.mp4`},word: "Roofer",},
  {video: {url: `245807.mp4`},word: "Planning application",},
  {video: {url: `154427.mp4`},word: "Construction planning",},
  {video: {url: `244271.mp4`},word: "Construction organisation",},
  {video: {url: `244273.mp4`},word: "Construction management",},
  {video: {url: `51173.mp4`},word: "Bridge",},
  {video: {url: `245805.mp4`},word: "Planning theory",},
  {video: {url: `154429.mp4`},word: "Construction technique",},
  {video: {url: `245258.mp4`},word: "Site set-up",},
  {video: {url: `154513.mp4`},word: "Cottage",},
  {video: {url: `93992.mp4`},word: "Platform",},
  {video: {url: `244145.mp4`},word: "Building technology",},
  {video: {url: `157932.mp4`},word: "Plan",},
  {video: {url: `153233.mp4`},word: "Building site",},
  {video: {url: `153232.mp4`},word: "Building plot",},
  {video: {url: `244075.mp4`},word: "Base rock",},
  {video: {url: `244260.mp4`},word: "Contracting organisation",},
  {video: {url: `97016.mp4`},word: "Barrier",},
  {video: {url: `62548.mp4`},word: "Base",},
  {video: {url: `245806.mp4`},word: "Planning approval",},
  {video: {url: `189651.mp4`},word: "Masonry",},
  {video: {url: `158047.mp4`},word: "Restoration",},
  {video: {url: `188421.mp4`},word: "Spillway",},
  {video: {url: `96145.mp4`},word: "Crew",},
  {video: {url: `125812.mp4`},word: "Carpentry",},
  {video: {url: `154669.mp4`},word: "Ground investigation",},
  {video: {url: `124974.mp4`},word: "Boarded wall",},
  {video: {url: `51837.mp4`},word: "Development",},
  {video: {url: `244270.mp4`},word: "Construction technology",},
  {video: {url: `155653.mp4`},word: "Supporting wall",},
  {video: {url: `155624.mp4`},word: "Stability",},
  {video: {url: `93122.mp4`},word: "Hard hat",},
  {video: {url: `244409.mp4`},word: "Electrics",},
  {video: {url: `58596.mp4`},word: "Construction",},
  {video: {url: `245804.mp4`},word: "Plastering",},
  {video: {url: `154650.mp4`},word: "Full survey",},
  {video: {url: `155639.mp4`},word: "Structural failure",},
  {video: {url: `155638.mp4`},word: "Structural engineer",},
  {video: {url: `116384.mp4`},word: "Power sanding",},
  {video: {url: `153216.mp4`},word: "Building adaptation",},
  {video: {url: `153230.mp4`},word: "Building contract",},
  {video: {url: `96179.mp4`},word: "Deck",},
  {video: {url: `189454.mp4`},word: "Public works",},
  {video: {url: `125595.mp4`},word: "Building",},
  {video: {url: `93783.mp4`},word: "Raining cats and dogs",},
  {video: {url: `74947.mp4`},word: "Cool",},
  {video: {url: `122548.mp4`},word: "Arid",},
  {video: {url: `58435.mp4`},word: "Dry",},
  {video: {url: `58528.mp4`},word: "Humid",},
  {video: {url: `93062.mp4`},word: "Frozen",},
  {video: {url: `93233.mp4`},word: "Cloudy",},
  {video: {url: `92794.mp4`},word: "Windy",},
  {video: {url: `92985.mp4`},word: "Fahrenheit",},
  {video: {url: `50938.mp4`},word: "Warm",},
  {video: {url: `116861.mp4`},word: "Showers",},
  {video: {url: `50814.mp4`},word: "Snow, to",},
  {video: {url: `116469.mp4`},word: "Rain shelter",},
  {video: {url: `73356.mp4`},word: "Rainfall",},
  {video: {url: `116471.mp4`},word: "Raindrop",},
  {video: {url: `153247.mp4`},word: "Celsius scale",},
  {video: {url: `94399.mp4`},word: "Thermostat",},
  {video: {url: `155668.mp4`},word: "Temperate zone",},
  {video: {url: `73389.mp4`},word: "Tornado",},
  {video: {url: `116472.mp4`},word: "Rainy day",},
  {video: {url: `89951.mp4`},word: "Solar eclipse",},
  {video: {url: `62651.mp4`},word: "Breeze",},
  {video: {url: `96530.mp4`},word: "Haze",},
  {video: {url: `116906.mp4`},word: "Sleet",},
  {video: {url: `89628.mp4`},word: "Depression",},
  {video: {url: `89630.mp4`},word: "Drought",},
  {video: {url: `96322.mp4`},word: "Equator",},
  {video: {url: `87746.mp4`},word: "Damp",},
  {video: {url: `96949.mp4`},word: "Monsoon",},
  {video: {url: `96216.mp4`},word: "Dew",},
  {video: {url: `187293.mp4`},word: "Ozone",},
  {video: {url: `116473.mp4`},word: "Rainy weather",},
  {video: {url: `153036.mp4`},word: "Acid rain",},
  {video: {url: `184823.mp4`},word: "Precipitation",},
  {video: {url: `125994.mp4`},word: "Chill",},
  {video: {url: `51356.mp4`},word: "Cloud",},
  {video: {url: `245998.mp4`},word: "Melt water",},
  {video: {url: `49266.mp4`},word: "Rain",},
  {video: {url: `90213.mp4`},word: "Weather report",},
  {video: {url: `90214.mp4`},word: "Weather reporter",},
  {video: {url: `153051.mp4`},word: "Air temperature",},
  {video: {url: `94394.mp4`},word: "Heat",},
  {video: {url: `96475.mp4`},word: "Hail",},
  {video: {url: `50895.mp4`},word: "Temperature",},
  {video: {url: `94025.mp4`},word: "Sky",},
  {video: {url: `189574.mp4`},word: "Climate",},
  {video: {url: `153724.mp4`},word: "Snow",},
  {video: {url: `117094.mp4`},word: "Sunset",},
  {video: {url: `96945.mp4`},word: "Meteorologist",},
  {video: {url: `117095.mp4`},word: "Sunshine",},
  {video: {url: `93154.mp4`},word: "Hurricane",},
  {video: {url: `59613.mp4`},word: "Thermometer",},
  {video: {url: `90070.mp4`},word: "Thunder",},
  {video: {url: `92762.mp4`},word: "Umbrella",},
  {video: {url: `116936.mp4`},word: "Snowstorm",},
  {video: {url: `65920.mp4`},word: "Snow cap",},
  {video: {url: `245863.mp4`},word: "Ozone layer",},
  {video: {url: `116932.mp4`},word: "Snowfall",},
  {video: {url: `153308.mp4`},word: "Fahrenheit scale",},
  {video: {url: `116928.mp4`},word: "Snow drift",},
  {video: {url: `116927.mp4`},word: "Snow cloud",},
  {video: {url: `187294.mp4`},word: "Ozone hole",},
  {video: {url: `96965.mp4`},word: "Thunderstorm",},
  {video: {url: `65925.mp4`},word: "Storm",},
  {video: {url: `73354.mp4`},word: "Rainbow",},
  {video: {url: `117091.mp4`},word: "Sunny day",},
  {video: {url: `93039.mp4`},word: "Fog",},
  {video: {url: `124731.mp4`},word: "Blizzard",},
  {video: {url: `51071.mp4`},word: "Wind",},
  {video: {url: `5040.mp4`},word: "Weather",},
  {video: {url: `244663.mp4`},word: "Humidity",},
  {video: {url: `96486.mp4`},word: "Halo",},
  {video: {url: `117093.mp4`},word: "Sunrise",},
  {video: {url: `96168.mp4`},word: "Cyclone",},
  {video: {url: `50843.mp4`},word: "Sweden",},
  {video: {url: `93914.mp4`},word: "Cursed",},
  {video: {url: `96527.mp4`},word: "Haunt",},
  {video: {url: `96188.mp4`},word: "Demon",},
  {video: {url: `89859.mp4`},word: "Myth",},
  {video: {url: `92925.mp4`},word: "Mystery",},
  {video: {url: `93131.mp4`},word: "Hero",},
  {video: {url: `96422.mp4`},word: "Goblin",},
  {video: {url: `96164.mp4`},word: "Curse",},
  {video: {url: `96160.mp4`},word: "Cupid",},
  {video: {url: `92987.mp4`},word: "Fairy",},
  {video: {url: `92673.mp4`},word: "Folklore",},
  {video: {url: `93082.mp4`},word: "Giant",},
  {video: {url: `48598.mp4`},word: "Monster",},
  {video: {url: `92999.mp4`},word: "Fate",},
  {video: {url: `244923.mp4`},word: "Eternal life",},
  {video: {url: `92798.mp4`},word: "Wish",},
  {video: {url: `51077.mp4`},word: "Witch",},
  {video: {url: `117098.mp4`},word: "Superstition",},
  {video: {url: `116315.mp4`},word: "Pixie",},
  {video: {url: `48571.mp4`},word: "Magic",},
  {video: {url: `96154.mp4`},word: "Crystal ball",},
  {video: {url: `191152.mp4`},word: "Paradise",},
  {video: {url: `116971.mp4`},word: "Spell",},
  {video: {url: `244842.mp4`},word: "Prophecy",},
  {video: {url: `111007.mp4`},word: "Nectar",},
  {video: {url: `243963.mp4`},word: "Abdominal",},
  {video: {url: `92933.mp4`},word: "Nasal",},
  {video: {url: `244367.mp4`},word: "Digestive organ",},
  {video: {url: `93125.mp4`},word: "Heart",},
  {video: {url: `246065.mp4`},word: "Limb",},
  {video: {url: `244463.mp4`},word: "Eyelid",},
  {video: {url: `96630.mp4`},word: "Intestines",},
  {video: {url: `245723.mp4`},word: "Pupil",},
  {video: {url: `89858.mp4`},word: "Muscle",},
  {video: {url: `245859.mp4`},word: "Pancreas",},
  {video: {url: `122551.mp4`},word: "Arm",},
  {video: {url: `94024.mp4`},word: "Skull",},
  {video: {url: `96544.mp4`},word: "Heel",},
  {video: {url: `245293.mp4`},word: "Shoulder",},
  {video: {url: `90097.mp4`},word: "Tongue",},
  {video: {url: `245215.mp4`},word: "Spinal column",},
  {video: {url: `125572.mp4`},word: "Brow",},
  {video: {url: `244395.mp4`},word: "Ear canal",},
  {video: {url: `245256.mp4`},word: "Small intestine",},
  {video: {url: `244121.mp4`},word: "Brain hemisphere",},
  {video: {url: `245396.mp4`},word: "Respiratory system",},
  {video: {url: `244054.mp4`},word: "Back",},
  {video: {url: `48606.mp4`},word: "Mouth",},
  {video: {url: `125998.mp4`},word: "Chin",},
  {video: {url: `50793.mp4`},word: "Cheek",},
  {video: {url: `244088.mp4`},word: "Biceps",},
  {video: {url: `244225.mp4`},word: "Cochlea",},
  {video: {url: `96996.mp4`},word: "Anatomy",},
  {video: {url: `90043.mp4`},word: "Throat",},
  {video: {url: `51140.mp4`},word: "Nose",},
  {video: {url: `244394.mp4`},word: "Eardrum",},
  {video: {url: `245027.mp4`},word: "Vulva",},
  {video: {url: `91814.mp4`},word: "Neck",},
  {video: {url: `90189.mp4`},word: "Waist",},
  {video: {url: `246086.mp4`},word: "Large intestine",},
  {video: {url: `92811.mp4`},word: "Wrist",},
  {video: {url: `245083.mp4`},word: "Trunk",},
  {video: {url: `62185.mp4`},word: "Abdomen",},
  {video: {url: `244180.mp4`},word: "Central nervous system",},
  {video: {url: `244141.mp4`},word: "Buttocks",},
  {video: {url: `244139.mp4`},word: "Calf muscle",},
  {video: {url: `245945.mp4`},word: "Nail",},
  {video: {url: `246051.mp4`},word: "Liver",},
  {video: {url: `244248.mp4`},word: "Cone",},
  {video: {url: `244496.mp4`},word: "Fingertip",},
  {video: {url: `246060.mp4`},word: "Lip",},
  {video: {url: `124992.mp4`},word: "Bone",},
  {video: {url: `91679.mp4`},word: "Adam's apple",},
  {video: {url: `91398.mp4`},word: "Face",},
  {video: {url: `244505.mp4`},word: "Femur",},
  {video: {url: `245144.mp4`},word: "Taste bud",},
  {video: {url: `191203.mp4`},word: "Nostril",},
  {video: {url: `51128.mp4`},word: "Body",},
  {video: {url: `244366.mp4`},word: "Digestive system",},
  {video: {url: `245119.mp4`},word: "Thigh",},
  {video: {url: `90068.mp4`},word: "Thumb",},
  {video: {url: `93204.mp4`},word: "Belly",},
  {video: {url: `93189.mp4`},word: "Ankle",},
  {video: {url: `90166.mp4`},word: "Vein",},
  {video: {url: `116893.mp4`},word: "Skeleton",},
  {video: {url: `122570.mp4`},word: "Artery",},
  {video: {url: `125964.mp4`},word: "Chest",},
  {video: {url: `93016.mp4`},word: "Finger",},
  {video: {url: `92945.mp4`},word: "Nerve",},
  {video: {url: `65875.mp4`},word: "Eyelash",},
  {video: {url: `116977.mp4`},word: "Spine",},
  {video: {url: `125289.mp4`},word: "Brain",},
  {video: {url: `91764.mp4`},word: "Foot",},
  {video: {url: `246098.mp4`},word: "Knee",},
  {video: {url: `93662.mp4`},word: "Insert floppy disk",},
  {video: {url: `155561.mp4`},word: "Scan",},
  {video: {url: `118335.mp4`},word: "Adaptor",},
  {video: {url: `125622.mp4`},word: "Bus",},
  {video: {url: `153270.mp4`},word: "Datashow",},
  {video: {url: `63626.mp4`},word: "Printer",},
  {video: {url: `50946.mp4`},word: "Webcamera",},
  {video: {url: `153626.mp4`},word: "Memory card",},
  {video: {url: `153340.mp4`},word: "Hardware",},
  {video: {url: `125862.mp4`},word: "Cd-rom",},
  {video: {url: `153631.mp4`},word: "Mouse",},
  {video: {url: `153602.mp4`},word: "Keyboard",},
  {video: {url: `153706.mp4`},word: "Scanner",},
  {video: {url: `153768.mp4`},word: "Usb",},
  {video: {url: `154711.mp4`},word: "Interactive",},
  {video: {url: `125004.mp4`},word: "Boot",},
  {video: {url: `153615.mp4`},word: "Logout",},
  {video: {url: `155699.mp4`},word: "Updating",},
  {video: {url: `153272.mp4`},word: "Delete",},
  {video: {url: `158027.mp4`},word: "Refresh",},
  {video: {url: `154536.mp4`},word: "Data entry",},
  {video: {url: `48577.mp4`},word: "Laptop",},
  {video: {url: `155702.mp4`},word: "Variables",},
  {video: {url: `245337.mp4`},word: "Screen shot",},
  {video: {url: `153274.mp4`},word: "Desktop computer",},
  {video: {url: `153767.mp4`},word: "Usb port",},
  {video: {url: `153613.mp4`},word: "Login",},
  {video: {url: `153269.mp4`},word: "Database",},
  {video: {url: `69419.mp4`},word: "Network",},
  {video: {url: `125591.mp4`},word: "Bug",},
  {video: {url: `186049.mp4`},word: "Output",},
  {video: {url: `153311.mp4`},word: "File format",},
  {video: {url: `124693.mp4`},word: "Bit",},
  {video: {url: `243937.mp4`},word: "Ipad",},
  {video: {url: `153763.mp4`},word: "Update",},
  {video: {url: `63629.mp4`},word: "Start delay",},
  {video: {url: `96085.mp4`},word: "Computer screen",},
  {video: {url: `51378.mp4`},word: "Computer",},
  {video: {url: `123144.mp4`},word: "Backup",},
  {video: {url: `65767.mp4`},word: "Sequence",},
  {video: {url: `153325.mp4`},word: "Gigabyte",},
  {video: {url: `153267.mp4`},word: "Data compression",},
  {video: {url: `153656.mp4`},word: "Password",},
  {video: {url: `153280.mp4`},word: "Digital library",},
  {video: {url: `153256.mp4`},word: "Computer programming",},
  {video: {url: `109882.mp4`},word: "File sharing",},
  {video: {url: `245967.mp4`},word: "Monitor",},
  {video: {url: `125624.mp4`},word: "Bus network",},
  {video: {url: `153102.mp4`},word: "Backslash",},
  {video: {url: `123349.mp4`},word: "Basic",},
  {video: {url: `153338.mp4`},word: "Hard disk",},
  {video: {url: `94253.mp4`},word: "Information and communication technology (ict/it)",},
  {video: {url: `187549.mp4`},word: "Input module",},
  {video: {url: `187548.mp4`},word: "Input",},
  {video: {url: `157840.mp4`},word: "Memory device",},
  {video: {url: `122535.mp4`},word: "Archangel",},
  {video: {url: `96607.mp4`},word: "Incense",},
  {video: {url: `244823.mp4`},word: "Seal",},
  {video: {url: `123360.mp4`},word: "Basin",},
  {video: {url: `96995.mp4`},word: "Amulet",},
  {video: {url: `122550.mp4`},word: "Ark",},
  {video: {url: `62558.mp4`},word: "Bead",},
  {video: {url: `96969.mp4`},word: "Tribute",},
  {video: {url: `118504.mp4`},word: "Angel",},
  {video: {url: `92807.mp4`},word: "Wreath",},
  {video: {url: `244832.mp4`},word: "Robe",},
  {video: {url: `158031.mp4`},word: "Religious art",},
  {video: {url: `244777.mp4`},word: "Veil",},
  {video: {url: `109511.mp4`},word: "Adam",},
  {video: {url: `244954.mp4`},word: "Eve",},
  {video: {url: `244875.mp4`},word: "Mary",},
  {video: {url: `244877.mp4`},word: "Luther",},
  {video: {url: `244870.mp4`},word: "Ministry",},
  {video: {url: `122536.mp4`},word: "Archbishop",},
  {video: {url: `244851.mp4`},word: "Preacher",},
  {video: {url: `244846.mp4`},word: "Priest",},
  {video: {url: `244956.mp4`},word: "Elijah",},
  {video: {url: `244955.mp4`},word: "Emmanuel",},
  {video: {url: `51398.mp4`},word: "Bishop",},
  {video: {url: `109684.mp4`},word: "David",},
  {video: {url: `244809.mp4`},word: "Son of god",},
  {video: {url: `244856.mp4`},word: "Pope",},
  {video: {url: `244839.mp4`},word: "Rabbi",},
  {video: {url: `244868.mp4`},word: "Missionary",},
  {video: {url: `92966.mp4`},word: "Nun",},
  {video: {url: `109510.mp4`},word: "Abraham",},
  {video: {url: `244865.mp4`},word: "Monk",},
  {video: {url: `51371.mp4`},word: "Biking",},
  {video: {url: `91834.mp4`},word: "Cheer on",},
  {video: {url: `49199.mp4`},word: "Play",},
  {video: {url: `157958.mp4`},word: "Practise",},
  {video: {url: `87681.mp4`},word: "Trainer",},
  {video: {url: `58398.mp4`},word: "Sportsman",},
  {video: {url: `94163.mp4`},word: "Recreation activities",},
  {video: {url: `91436.mp4`},word: "Hobby",},
  {video: {url: `154741.mp4`},word: "Leisure",},
  {video: {url: `96420.mp4`},word: "Go-cart",},
  {video: {url: `90202.mp4`},word: "Water skier",},
  {video: {url: `92607.mp4`},word: "Bicycle path",},
  {video: {url: `122814.mp4`},word: "Athlete",},
  {video: {url: `125002.mp4`},word: "Boomerang",},
  {video: {url: `87684.mp4`},word: "Training",},
  {video: {url: `63586.mp4`},word: "Sport",},
  {video: {url: `153691.mp4`},word: "Recreational activity",},
  {video: {url: `51633.mp4`},word: "Scout",},
  {video: {url: `94137.mp4`},word: "Leisure-time",},
  {video: {url: `125774.mp4`},word: "Captain",},
  {video: {url: `125951.mp4`},word: "Cheerleader",},
  {video: {url: `92647.mp4`},word: "Cycle store",},
  {video: {url: `87635.mp4`},word: "Skipper",},
  {video: {url: `90129.mp4`},word: "Trick",},
  {video: {url: `73327.mp4`},word: "Hunting dog",},
  {video: {url: `245697.mp4`},word: "Recreational water",},
  {video: {url: `51919.mp4`},word: "Athletic center",},
  {video: {url: `93134.mp4`},word: "Hike",},
  {video: {url: `73357.mp4`},word: "Raised hide",},
  {video: {url: `116447.mp4`},word: "Puppeteer",},
  {video: {url: `51653.mp4`},word: "Tricycle",},
  {video: {url: `124309.mp4`},word: "Bike",},
  {video: {url: `73326.mp4`},word: "Hunter",},
  {video: {url: `62586.mp4`},word: "Bicyclist",},
  {video: {url: `51622.mp4`},word: "Recreation",},
  {video: {url: `62585.mp4`},word: "Bicycle trail",},
  {video: {url: `62583.mp4`},word: "Bicycle crossing",},
  {video: {url: `89987.mp4`},word: "Target",},
  {video: {url: `58438.mp4`},word: "Bicycle",},
  {video: {url: `116987.mp4`},word: "Sports medicine",},
  {video: {url: `117008.mp4`},word: "Stamp collector",},
  {video: {url: `62584.mp4`},word: "Bicycle lane",},
  {video: {url: `93412.mp4`},word: "He is an atheist.",},
  {video: {url: `92770.mp4`},word: "God-fearing",},
  {video: {url: `125009.mp4`},word: "Born again",},
  {video: {url: `93141.mp4`},word: "Holy",},
  {video: {url: `122813.mp4`},word: "Atheist",},
  {video: {url: `116775.mp4`},word: "Sacred",},
  {video: {url: `93064.mp4`},word: "Funeral",},
  {video: {url: `96358.mp4`},word: "Faithful",},
  {video: {url: `51120.mp4`},word: "Bless",},
  {video: {url: `124266.mp4`},word: "Believe, to",},
  {video: {url: `244945.mp4`},word: "Cleanse",},
  {video: {url: `125212.mp4`},word: "Bow down",},
  {video: {url: `244854.mp4`},word: "Pray",},
  {video: {url: `122821.mp4`},word: "Atone",},
  {video: {url: `244852.mp4`},word: "Preach",},
  {video: {url: `96180.mp4`},word: "Dedicate",},
  {video: {url: `90011.mp4`},word: "Testify",},
  {video: {url: `125925.mp4`},word: "Chant",},
  {video: {url: `93050.mp4`},word: "Forgive",},
  {video: {url: `51200.mp4`},word: "Bury",},
  {video: {url: `93290.mp4`},word: "Deny",},
  {video: {url: `93245.mp4`},word: "Confess",},
  {video: {url: `96185.mp4`},word: "Deliver",},
  {video: {url: `96190.mp4`},word: "Denounce",},
  {video: {url: `244827.mp4`},word: "Sacrifice",},
  {video: {url: `244920.mp4`},word: "Fast",},
  {video: {url: `244903.mp4`},word: "Glorify",},
  {video: {url: `118172.mp4`},word: "Abstain",},
  {video: {url: `96096.mp4`},word: "Confession",},
  {video: {url: `91756.mp4`},word: "Empowerment",},
  {video: {url: `118416.mp4`},word: "Alms",},
  {video: {url: `124723.mp4`},word: "Blessing",},
  {video: {url: `125888.mp4`},word: "Celibacy",},
  {video: {url: `244940.mp4`},word: "Conversion",},
  {video: {url: `244926.mp4`},word: "Disbelief",},
  {video: {url: `244942.mp4`},word: "Confusion",},
  {video: {url: `244859.mp4`},word: "Pardon",},
  {video: {url: `92988.mp4`},word: "Faith",},
  {video: {url: `189425.mp4`},word: "Ordination",},
  {video: {url: `62571.mp4`},word: "Belief",},
  {video: {url: `92803.mp4`},word: "Worship",},
  {video: {url: `245935.mp4`},word: "Nature worship",},
  {video: {url: `244906.mp4`},word: "Funeral service",},
  {video: {url: `244904.mp4`},word: "Glorification",},
  {video: {url: `51419.mp4`},word: "Ceremony",},
  {video: {url: `96118.mp4`},word: "Convert",},
  {video: {url: `244879.mp4`},word: "Loyalty",},
  {video: {url: `122796.mp4`},word: "Assurance",},
  {video: {url: `96944.mp4`},word: "Mercy",},
  {video: {url: `244936.mp4`},word: "Creator",},
  {video: {url: `96076.mp4`},word: "Communion",},
  {video: {url: `244869.mp4`},word: "Miracle",},
  {video: {url: `244853.mp4`},word: "Prayer",},
  {video: {url: `244911.mp4`},word: "Forgiveness",},
  {video: {url: `244929.mp4`},word: "Dedication",},
  {video: {url: `96547.mp4`},word: "Hell",},
  {video: {url: `244804.mp4`},word: "Spirit",},
  {video: {url: `244927.mp4`},word: "Devotion",},
  {video: {url: `91384.mp4`},word: "Ethics",},
  {video: {url: `244807.mp4`},word: "Soul",},
  {video: {url: `116773.mp4`},word: "Sabbath",},
  {video: {url: `93129.mp4`},word: "Heaven",},
  {video: {url: `153318.mp4`},word: "Floor exercise mat",},
  {video: {url: `122528.mp4`},word: "Aqua lung",},
  {video: {url: `87649.mp4`},word: "Starting block",},
  {video: {url: `51806.mp4`},word: "Equipment",},
  {video: {url: `93093.mp4`},word: "Golf bag",},
  {video: {url: `87700.mp4`},word: "Volleyball set",},
  {video: {url: `116985.mp4`},word: "Sport jersey shirt",},
  {video: {url: `96923.mp4`},word: "Paddle",},
  {video: {url: `97071.mp4`},word: "Surfboard",},
  {video: {url: `123213.mp4`},word: "Ball",},
  {video: {url: `96381.mp4`},word: "Fishing pole",},
  {video: {url: `65916.mp4`},word: "Skate",},
  {video: {url: `92744.mp4`},word: "Ski lift",},
  {video: {url: `87641.mp4`},word: "Sports equipment",},
  {video: {url: `97048.mp4`},word: "Hiking boots",},
  {video: {url: `51340.mp4`},word: "Baseball jersey",},
  {video: {url: `50927.mp4`},word: "Sneakers",},
  {video: {url: `90192.mp4`},word: "Walking stick",},
  {video: {url: `153293.mp4`},word: "Dynamic rope",},
  {video: {url: `51523.mp4`},word: "Baton",},
  {video: {url: `92671.mp4`},word: "Fishing rod",},
  {video: {url: `118380.mp4`},word: "Air hose",},
  {video: {url: `153625.mp4`},word: "Medicine ball",},
  {video: {url: `87640.mp4`},word: "Sport matress",},
  {video: {url: `91312.mp4`},word: "Altimeter",},
  {video: {url: `116505.mp4`},word: "Reel",},
  {video: {url: `116365.mp4`},word: "Pool table",},
  {video: {url: `153658.mp4`},word: "Pedometer",},
  {video: {url: `87692.mp4`},word: "Universal handle",},
  {video: {url: `93191.mp4`},word: "Arrow",},
  {video: {url: `91864.mp4`},word: "Whistle",},
  {video: {url: `90005.mp4`},word: "Tennis ball",},
  {video: {url: `153630.mp4`},word: "Mountain bike",},
  {video: {url: `87697.mp4`},word: "Volleyball net",},
  {video: {url: `153714.mp4`},word: "Shin guard",},
  {video: {url: `118572.mp4`},word: "Water ski",},
  {video: {url: `116930.mp4`},word: "Snow ski",},
  {video: {url: `51638.mp4`},word: "Skateboard",},
  {video: {url: `51627.mp4`},word: "Roller skate",},
  {video: {url: `62551.mp4`},word: "Bat",},
  {video: {url: `87675.mp4`},word: "Tool bag",},
  {video: {url: `118415.mp4`},word: "Almighty",},
  {video: {url: `51096.mp4`},word: "Ascend",},
  {video: {url: `244917.mp4`},word: "Fellowship",},
  {video: {url: `93090.mp4`},word: "God",},
  {video: {url: `124267.mp4`},word: "Believer",},
  {video: {url: `244912.mp4`},word: "Foot washing",},
  {video: {url: `244898.mp4`},word: "Heavenly father",},
  {video: {url: `244905.mp4`},word: "Garden of eden",},
  {video: {url: `244896.mp4`},word: "Holy land",},
  {video: {url: `96071.mp4`},word: "Commandment",},
  {video: {url: `244934.mp4`},word: "Cult",},
  {video: {url: `244932.mp4`},word: "Deaf church",},
  {video: {url: `92622.mp4`},word: "Cathedral",},
  {video: {url: `90001.mp4`},word: "Temple",},
  {video: {url: `118421.mp4`},word: "Altar",},
  {video: {url: `244834.mp4`},word: "Revelation",},
  {video: {url: `90096.mp4`},word: "Tomb",},
  {video: {url: `244953.mp4`},word: "Judges",},
  {video: {url: `49276.mp4`},word: "Religion",},
  {video: {url: `244914.mp4`},word: "Firstborn",},
  {video: {url: `96433.mp4`},word: "Grave",},
  {video: {url: `96964.mp4`},word: "Throne",},
  {video: {url: `51415.mp4`},word: "Cemetery",},
  {video: {url: `51437.mp4`},word: "Chapel",},
  {video: {url: `244784.mp4`},word: "Tree of knowledge of good and evil",},
  {video: {url: `118601.mp4`},word: "Zamzam",},
  {video: {url: `118188.mp4`},word: "Accursed",},
  {video: {url: `244900.mp4`},word: "Greedy",},
  {video: {url: `93929.mp4`},word: "Deprive",},
  {video: {url: `118347.mp4`},word: "Adultery",},
  {video: {url: `244815.mp4`},word: "Sin",},
  {video: {url: `93100.mp4`},word: "Greed",},
  {video: {url: `244814.mp4`},word: "Sinner",},
  {video: {url: `96547.mp4`},word: "Hell",},
  {video: {url: `244862.mp4`},word: "Offense",},
  {video: {url: `96212.mp4`},word: "Deviate",},
  {video: {url: `244931.mp4`},word: "Deceit",},
  {video: {url: `244897.mp4`},word: "Heresy",},
  {video: {url: `244889.mp4`},word: "Jealousy",},
  {video: {url: `244781.mp4`},word: "Unbelief",},
  {video: {url: `97060.mp4`},word: "Offside",},
  {video: {url: `91452.mp4`},word: "Last",},
  {video: {url: `58506.mp4`},word: "Give up",},
  {video: {url: `51070.mp4`},word: "Win, to",},
  {video: {url: `125942.mp4`},word: "Cheat",},
  {video: {url: `58395.mp4`},word: "Point",},
  {video: {url: `91292.mp4`},word: "Drop out",},
  {video: {url: `91337.mp4`},word: "Defeat",},
  {video: {url: `96079.mp4`},word: "Compete",},
  {video: {url: `93943.mp4`},word: "Disqualify",},
  {video: {url: `154420.mp4`},word: "Competitor",},
  {video: {url: `153304.mp4`},word: "European football championship",},
  {video: {url: `96111.mp4`},word: "Contest",},
  {video: {url: `153693.mp4`},word: "Regular player",},
  {video: {url: `97034.mp4`},word: "Draw",},
  {video: {url: `91408.mp4`},word: "First",},
  {video: {url: `91843.mp4`},word: "Goal",},
  {video: {url: `153335.mp4`},word: "Half time interval",},
  {video: {url: `153605.mp4`},word: "Laws of the game",},
  {video: {url: `244646.mp4`},word: "Individual sport",},
  {video: {url: `153694.mp4`},word: "Relay race",},
  {video: {url: `153688.mp4`},word: "Race",},
  {video: {url: `87706.mp4`},word: "Winner",},
  {video: {url: `153654.mp4`},word: "Paralympics games",},
  {video: {url: `116566.mp4`},word: "Rival",},
  {video: {url: `91850.mp4`},word: "Olympic games",},
  {video: {url: `51592.mp4`},word: "Deaflympics games",},
  {video: {url: `92846.mp4`},word: "Mascot",},
  {video: {url: `87680.mp4`},word: "Tournament",},
  {video: {url: `91844.mp4`},word: "Gold medal",},
  {video: {url: `87695.mp4`},word: "Victory",},
  {video: {url: `123105.mp4`},word: "Award",},
  {video: {url: `49214.mp4`},word: "Prize",},
  {video: {url: `116552.mp4`},word: "Ribbon for second place",},
  {video: {url: `116551.mp4`},word: "Ribbon for honorable mentions",},
  {video: {url: `185303.mp4`},word: "Team role",},
  {video: {url: `58392.mp4`},word: "Home game",},
  {video: {url: `92853.mp4`},word: "Medal",},
  {video: {url: `91838.mp4`},word: "Deaflympics",},
  {video: {url: `116550.mp4`},word: "Ribbon for first place",},
  {video: {url: `51533.mp4`},word: "Championship",},
  {video: {url: `51532.mp4`},word: "Champion",},
  {video: {url: `58394.mp4`},word: "National team",},
  {video: {url: `153621.mp4`},word: "Match",},
  {video: {url: `97073.mp4`},word: "Trophy",},
  {video: {url: `153309.mp4`},word: "False start",},
  {video: {url: `91851.mp4`},word: "Opponent",},
  {video: {url: `125563.mp4`},word: "Bronze medal",},
  {video: {url: `51649.mp4`},word: "Teamwork",},
  {video: {url: `93015.mp4`},word: "Final",},
  {video: {url: `90080.mp4`},word: "Time out",},
  {video: {url: `153650.mp4`},word: "Own goal",},
  {video: {url: `153745.mp4`},word: "Team",},
  {video: {url: `58281.mp4`},word: "Competition",},
  {video: {url: `51599.mp4`},word: "Competitive sports",},
  {video: {url: `87708.mp4`},word: "World cup",},
  {video: {url: `153307.mp4`},word: "Extra-time",},
  {video: {url: `91835.mp4`},word: "Coach",},
  {video: {url: `122601.mp4`},word: "Assistant coach",},
  {video: {url: `87623.mp4`},word: "Scoreboard",},
  {video: {url: `123108.mp4`},word: "Away game",},
  {video: {url: `87622.mp4`},word: "Score",},
  {video: {url: `62898.mp4`},word: "Result",},
  {video: {url: `153701.mp4`},word: "Reward",},
  {video: {url: `91841.mp4`},word: "Foul",},
  {video: {url: `116553.mp4`},word: "Ribbon for third place",},
  {video: {url: `90079.mp4`},word: "Time keeper sports",},
  {video: {url: `87647.mp4`},word: "Start referee",},
  {video: {url: `87632.mp4`},word: "Silver medal",},
  {video: {url: `58863.mp4`},word: "Bitter",},
  {video: {url: `94040.mp4`},word: "Surprised",},
  {video: {url: `93065.mp4`},word: "Furious",},
  {video: {url: `91310.mp4`},word: "Totally exhausted",},
  {video: {url: `91357.mp4`},word: "Amazed",},
  {video: {url: `91339.mp4`},word: "Hair-raising",},
  {video: {url: `48564.mp4`},word: "Lonely",},
  {video: {url: `51150.mp4`},word: "Jealous",},
  {video: {url: `118508.mp4`},word: "Angry",},
  {video: {url: `51879.mp4`},word: "Curious",},
  {video: {url: `94019.mp4`},word: "Shocked",},
  {video: {url: `94030.mp4`},word: "Sorry",},
  {video: {url: `93294.mp4`},word: "Desperate",},
  {video: {url: `122505.mp4`},word: "Anxious",},
  {video: {url: `94059.mp4`},word: "Worn out",},
  {video: {url: `125007.mp4`},word: "Bored",},
  {video: {url: `63535.mp4`},word: "Exciting",},
  {video: {url: `90116.mp4`},word: "Tragic",},
  {video: {url: `62706.mp4`},word: "Cheerful",},
  {video: {url: `58529.mp4`},word: "Hungry",},
  {video: {url: `91380.mp4`},word: "Enthusiastic",},
  {video: {url: `50777.mp4`},word: "Satisfied",},
  {video: {url: `63536.mp4`},word: "Exhausting",},
  {video: {url: `93907.mp4`},word: "Concerned",},
  {video: {url: `118361.mp4`},word: "Afraid",},
  {video: {url: `89868.mp4`},word: "Obsessed",},
  {video: {url: `51892.mp4`},word: "Disappointed",},
  {video: {url: `50878.mp4`},word: "Tired",},
  {video: {url: `92872.mp4`},word: "Merry",},
  {video: {url: `93908.mp4`},word: "Confused",},
  {video: {url: `96389.mp4`},word: "Fond",},
  {video: {url: `51883.mp4`},word: "Depressed",},
  {video: {url: `91419.mp4`},word: "Frightened",},
  {video: {url: `244791.mp4`},word: "Thankful",},
  {video: {url: `50594.mp4`},word: "Amorous",},
  {video: {url: `51286.mp4`},word: "Calm",},
  {video: {url: `91440.mp4`},word: "Hopeless",},
  {video: {url: `116539.mp4`},word: "Restless",},
  {video: {url: `62498.mp4`},word: "Ashamed",},
  {video: {url: `63531.mp4`},word: "Enamored",},
  {video: {url: `93086.mp4`},word: "Glad",},
  {video: {url: `96966.mp4`},word: "Timid",},
  {video: {url: `96571.mp4`},word: "Homesick",},
  {video: {url: `116894.mp4`},word: "Skeptic",},
  {video: {url: `90209.mp4`},word: "Weak",},
  {video: {url: `89950.mp4`},word: "So-so",},
  {video: {url: `48812.mp4`},word: "Nervous",},
  {video: {url: `63572.mp4`},word: "Scared",},
  {video: {url: `50772.mp4`},word: "Sad",},
  {video: {url: `125718.mp4`},word: "Calmed",},
  {video: {url: `63573.mp4`},word: "Scary",},
  {video: {url: `244928.mp4`},word: "Devoted",},
  {video: {url: `244808.mp4`},word: "Sorrowful",},
  {video: {url: `93932.mp4`},word: "Determined",},
  {video: {url: `59616.mp4`},word: "To be thirsty",},
  {video: {url: `96524.mp4`},word: "Hate",},
  {video: {url: `93005.mp4`},word: "Feel",},
  {video: {url: `96342.mp4`},word: "Excite",},
  {video: {url: `59615.mp4`},word: "To be hungry",},
  {video: {url: `96238.mp4`},word: "Disgust",},
  {video: {url: `51927.mp4`},word: "Hurt",},
  {video: {url: `96436.mp4`},word: "Grieve",},
  {video: {url: `94048.mp4`},word: "Tremble",},
  {video: {url: `96199.mp4`},word: "Despise",},
  {video: {url: `96635.mp4`},word: "Irritate",},
  {video: {url: `90042.mp4`},word: "Thrill",},
  {video: {url: `93173.mp4`},word: "Inspire",},
  {video: {url: `48503.mp4`},word: "Get angry, to",},
  {video: {url: `51782.mp4`},word: "Motivate",},
  {video: {url: `116530.mp4`},word: "Resent",},
  {video: {url: `51513.mp4`},word: "Bewilder",},
  {video: {url: `244801.mp4`},word: "Suffer",},
  {video: {url: `49274.mp4`},word: "Relax",},
  {video: {url: `49209.mp4`},word: "Prefer, to",},
  {video: {url: `90094.mp4`},word: "Tolerate",},
  {video: {url: `93963.mp4`},word: "Loathe",},
  {video: {url: `92908.mp4`},word: "Mourn",},
  {video: {url: `91418.mp4`},word: "Fret",},
  {video: {url: `93292.mp4`},word: "Desire",},
  {video: {url: `51655.mp4`},word: "Trust",},
  {video: {url: `91439.mp4`},word: "Hope",},
  {video: {url: `48560.mp4`},word: "Like",},
  {video: {url: `89967.mp4`},word: "Suspect",},
  {video: {url: `93214.mp4`},word: "Burst into tears",},
  {video: {url: `96209.mp4`},word: "Detest",},
  {video: {url: `50936.mp4`},word: "Want",},
  {video: {url: `93085.mp4`},word: "Giggle",},
  {video: {url: `62276.mp4`},word: "Anticipate",},
  {video: {url: `51137.mp4`},word: "Upset",},
  {video: {url: `89889.mp4`},word: "Overwhelm",},
  {video: {url: `62274.mp4`},word: "Annoy",},
  {video: {url: `90085.mp4`},word: "Tire",},
  {video: {url: `93917.mp4`},word: "Dare",},
  {video: {url: `91349.mp4`},word: "Admire",},
  {video: {url: `94060.mp4`},word: "Worry",},
  {video: {url: `117012.mp4`},word: "Startle",},
  {video: {url: `47585.mp4`},word: "Enjoy, to",},
  {video: {url: `125800.mp4`},word: "Cares",},
  {video: {url: `96402.mp4`},word: "Fury",},
  {video: {url: `122832.mp4`},word: "Attitude",},
  {video: {url: `155571.mp4`},word: "Self-motivation",},
  {video: {url: `63555.mp4`},word: "Pain",},
  {video: {url: `58304.mp4`},word: "Feeling",},
  {video: {url: `65897.mp4`},word: "Passion",},
  {video: {url: `51926.mp4`},word: "Empathy",},
  {video: {url: `244847.mp4`},word: "Pride",},
  {video: {url: `244926.mp4`},word: "Disbelief",},
  {video: {url: `51795.mp4`},word: "Aggression",},
  {video: {url: `51814.mp4`},word: "Fear",},
  {video: {url: `244826.mp4`},word: "Satisfaction",},
  {video: {url: `51951.mp4`},word: "Love",},
  {video: {url: `58365.mp4`},word: "Self-analysis",},
  {video: {url: `185264.mp4`},word: "Self-consciousness",},
  {video: {url: `62513.mp4`},word: "Awe",},
  {video: {url: `244803.mp4`},word: "Stubbornness",},
  {video: {url: `51087.mp4`},word: "Worry",},
  {video: {url: `51601.mp4`},word: "Motivation",},
  {video: {url: `51283.mp4`},word: "Anger",},
  {video: {url: `188325.mp4`},word: "Anguish",},
  {video: {url: `96236.mp4`},word: "Disdain",},
  {video: {url: `157850.mp4`},word: "Mood",},
  {video: {url: `116201.mp4`},word: "Panic",},
  {video: {url: `93102.mp4`},word: "Grief",},
  {video: {url: `51924.mp4`},word: "Impulse",},
  {video: {url: `244771.mp4`},word: "Wrath",},
  {video: {url: `93108.mp4`},word: "Guilt",},
  {video: {url: `65891.mp4`},word: "Misery",},
  {video: {url: `244411.mp4`},word: "Egoism",},
  {video: {url: `51635.mp4`},word: "Self-image",},
  {video: {url: `63534.mp4`},word: "Excitement",},
  {video: {url: `51634.mp4`},word: "Self-confidence",},
  {video: {url: `189507.mp4`},word: "Tension",},
  {video: {url: `93000.mp4`},word: "Fatigue",},
  {video: {url: `244800.mp4`},word: "Suffering",},
  {video: {url: `58306.mp4`},word: "Frustration",},
  {video: {url: `50789.mp4`},word: "Shame",},
  {video: {url: `244857.mp4`},word: "Pity",},
  {video: {url: `50884.mp4`},word: "Thirst",},
  {video: {url: `116464.mp4`},word: "Rage",},
  {video: {url: `244930.mp4`},word: "Decisiveness",},
  {video: {url: `93309.mp4`},word: "Disrespect",},
  {video: {url: `62873.mp4`},word: "Inspiration",},
  {video: {url: `96432.mp4`},word: "Gratitude",},
  {video: {url: `96928.mp4`},word: "Quake",},
  {video: {url: `244894.mp4`},word: "Hope",},
  {video: {url: `122507.mp4`},word: "Apathy",},
  {video: {url: `244886.mp4`},word: "Kindness",},
  {video: {url: `96296.mp4`},word: "Ease",},
  {video: {url: `96090.mp4`},word: "Concern",},
  {video: {url: `244883.mp4`},word: "Laziness",},
  {video: {url: `49280.mp4`},word: "Respect",},
  {video: {url: `90009.mp4`},word: "Terror",},
  {video: {url: `96977.mp4`},word: "Urge",},
  {video: {url: `49177.mp4`},word: "Patience",},
  {video: {url: `116789.mp4`},word: "Sarcasm",},
  {video: {url: `58293.mp4`},word: "Emotion",},
  {video: {url: `93146.mp4`},word: "Horror",},
  {video: {url: `189447.mp4`},word: "Prejudice",},
  {video: {url: `92654.mp4`},word: "Discomfort",},
  {video: {url: `93061.mp4`},word: "Fright",},
  {video: {url: `50803.mp4`},word: "Shock",},
  {video: {url: `244388.mp4`},word: "Disgust",},
  {video: {url: `51624.mp4`},word: "Relaxation",},
  {video: {url: `51853.mp4`},word: "Hunger",},
  {video: {url: `244795.mp4`},word: "Sympathy",},
  {video: {url: `116995.mp4`},word: "Spring fever",},
  {video: {url: `63562.mp4`},word: "Pleasure",},
  {video: {url: `244889.mp4`},word: "Jealousy",},
  {video: {url: `91396.mp4`},word: "Expression",},
  {video: {url: `50842.mp4`},word: "Surprise",},
  {video: {url: `51509.mp4`},word: "Hug",},
  {video: {url: `154403.mp4`},word: "Circular",},
  {video: {url: `89885.mp4`},word: "Oval",},
  {video: {url: `116605.mp4`},word: "Round - shape",},
  {video: {url: `58524.mp4`},word: "Heavy",},
  {video: {url: `89763.mp4`},word: "Thick",},
  {video: {url: `96604.mp4`},word: "Immense",},
  {video: {url: `92791.mp4`},word: "Wide",},
  {video: {url: `89750.mp4`},word: "Shorter",},
  {video: {url: `96165.mp4`},word: "Curve",},
  {video: {url: `51778.mp4`},word: "Loose",},
  {video: {url: `90076.mp4`},word: "Tight",},
  {video: {url: `96414.mp4`},word: "Gigantic",},
  {video: {url: `92932.mp4`},word: "Narrow",},
  {video: {url: `116910.mp4`},word: "Slim",},
  {video: {url: `124308.mp4`},word: "Big",},
  {video: {url: `89714.mp4`},word: "Long",},
  {video: {url: `90083.mp4`},word: "Tiny",},
  {video: {url: `116897.mp4`},word: "Skinny",},
  {video: {url: `93150.mp4`},word: "Huge",},
  {video: {url: `65914.mp4`},word: "Short",},
  {video: {url: `50853.mp4`},word: "Tall",},
  {video: {url: `50888.mp4`},word: "Thin",},
  {video: {url: `96229.mp4`},word: "Diminish",},
  {video: {url: `96095.mp4`},word: "Cone",},
  {video: {url: `96156.mp4`},word: "Cube",},
  {video: {url: `153611.mp4`},word: "Line",},
  {video: {url: `96418.mp4`},word: "Globe",},
  {video: {url: `65758.mp4`},word: "Piece",},
  {video: {url: `244332.mp4`},word: "Curve",},
  {video: {url: `93231.mp4`},word: "Circle",},
  {video: {url: `244622.mp4`},word: "Hexagon",},
  {video: {url: `116500.mp4`},word: "Rectangle",},
  {video: {url: `116998.mp4`},word: "Square",},
  {video: {url: `245904.mp4`},word: "Octagon",},
  {video: {url: `63546.mp4`},word: "Little",},
  {video: {url: `90127.mp4`},word: "Triangle",},
  {video: {url: `96097.mp4`},word: "Confident",},
  {video: {url: `50849.mp4`},word: "Coward",},
  {video: {url: `157960.mp4`},word: "Pragmatic",},
  {video: {url: `89906.mp4`},word: "Punctual",},
  {video: {url: `96171.mp4`},word: "Dandy",},
  {video: {url: `89904.mp4`},word: "Proud",},
  {video: {url: `96926.mp4`},word: "Posh",},
  {video: {url: `87761.mp4`},word: "Dull",},
  {video: {url: `244381.mp4`},word: "Dominant",},
  {video: {url: `123620.mp4`},word: "Beautiful",},
  {video: {url: `47463.mp4`},word: "Clumsy",},
  {video: {url: `91359.mp4`},word: "Ambitious",},
  {video: {url: `49275.mp4`},word: "Reliable",},
  {video: {url: `49178.mp4`},word: "Patient",},
  {video: {url: `51151.mp4`},word: "Intelligent",},
  {video: {url: `244900.mp4`},word: "Greedy",},
  {video: {url: `117053.mp4`},word: "Strange",},
  {video: {url: `116258.mp4`},word: "Peculiar",},
  {video: {url: `153727.mp4`},word: "Spoiled",},
  {video: {url: `91698.mp4`},word: "Antisocial",},
  {video: {url: `93902.mp4`},word: "Careful",},
  {video: {url: `96302.mp4`},word: "Egoistic",},
  {video: {url: `93912.mp4`},word: "Cowardly",},
  {video: {url: `244901.mp4`},word: "Gracious",},
  {video: {url: `96358.mp4`},word: "Faithful",},
  {video: {url: `50908.mp4`},word: "Vain",},
  {video: {url: `91354.mp4`},word: "Aggressive",},
  {video: {url: `92980.mp4`},word: "Fabulous",},
  {video: {url: `58581.mp4`},word: "Weird",},
  {video: {url: `90105.mp4`},word: "Tough",},
  {video: {url: `49186.mp4`},word: "Perfect",},
  {video: {url: `116840.mp4`},word: "Shallow",},
  {video: {url: `125933.mp4`},word: "Charismatic",},
  {video: {url: `96975.mp4`},word: "Unique",},
  {video: {url: `153623.mp4`},word: "Mean",},
  {video: {url: `62285.mp4`},word: "Arrogant",},
  {video: {url: `49175.mp4`},word: "Polite",},
  {video: {url: `246119.mp4`},word: "Innovative",},
  {video: {url: `92939.mp4`},word: "Neat",},
  {video: {url: `92936.mp4`},word: "Naughty",},
  {video: {url: `110992.mp4`},word: "Nasty",},
  {video: {url: `96413.mp4`},word: "Gifted",},
  {video: {url: `93936.mp4`},word: "Diligent",},
  {video: {url: `96590.mp4`},word: "Hysterical",},
  {video: {url: `94045.mp4`},word: "Thoughtful",},
  {video: {url: `51358.mp4`},word: "Generous",},
  {video: {url: `155579.mp4`},word: "Sensual",},
  {video: {url: `51510.mp4`},word: "Hypocritical",},
  {video: {url: `65874.mp4`},word: "Disobedient",},
  {video: {url: `51578.mp4`},word: "Friendly",},
  {video: {url: `246006.mp4`},word: "Mature",},
  {video: {url: `92797.mp4`},word: "Wise",},
  {video: {url: `96966.mp4`},word: "Timid",},
  {video: {url: `50862.mp4`},word: "Strong",},
  {video: {url: `96410.mp4`},word: "Gentle",},
  {video: {url: `123345.mp4`},word: "Bashful",},
  {video: {url: `92943.mp4`},word: "Negative",},
  {video: {url: `96411.mp4`},word: "Genuine",},
  {video: {url: `58256.mp4`},word: "Cocky",},
  {video: {url: `116372.mp4`},word: "Positive",},
  {video: {url: `92930.mp4`},word: "Naive",},
  {video: {url: `116604.mp4`},word: "Rough",},
  {video: {url: `63588.mp4`},word: "Stubborn",},
  {video: {url: `51088.mp4`},word: "Obedient",},
  {video: {url: `51879.mp4`},word: "Curious",},
  {video: {url: `125983.mp4`},word: "Childlike",},
  {video: {url: `116941.mp4`},word: "Social",},
  {video: {url: `91380.mp4`},word: "Enthusiastic",},
  {video: {url: `94021.mp4`},word: "Silly",},
  {video: {url: `91673.mp4`},word: "Absent-minded",},
  {video: {url: `93152.mp4`},word: "Humble",},
  {video: {url: `96293.mp4`},word: "Earnest",},
  {video: {url: `51139.mp4`},word: "Innocent",},
  {video: {url: `58499.mp4`},word: "Cute",},
  {video: {url: `154524.mp4`},word: "Critical",},
  {video: {url: `58611.mp4`},word: "Expressive",},
  {video: {url: `123150.mp4`},word: "Bad tempered",},
  {video: {url: `94049.mp4`},word: "Troublesome",},
  {video: {url: `96960.mp4`},word: "Stingy",},
  {video: {url: `48550.mp4`},word: "Lazy",},
  {video: {url: `125522.mp4`},word: "Brave",},
  {video: {url: `89978.mp4`},word: "Tame",},
  {video: {url: `89954.mp4`},word: "Spectacular",},
  {video: {url: `116951.mp4`},word: "Sophisticate",},
  {video: {url: `58431.mp4`},word: "Sensitive",},
  {video: {url: `63543.mp4`},word: "Kind",},
  {video: {url: `62617.mp4`},word: "Bold",},
  {video: {url: `96369.mp4`},word: "Fierce",},
  {video: {url: `48592.mp4`},word: "Mischievous",},
  {video: {url: `96300.mp4`},word: "Educated",},
  {video: {url: `97107.mp4`},word: "Idle",},
  {video: {url: `154708.mp4`},word: "Intellectual",},
  {video: {url: `91433.mp4`},word: "Handy",},
  {video: {url: `116245.mp4`},word: "Patriotic",},
  {video: {url: `90041.mp4`},word: "Thrifty",},
  {video: {url: `50836.mp4`},word: "Stupid",},
  {video: {url: `49158.mp4`},word: "Obstinate",},
  {video: {url: `49281.mp4`},word: "Responsible",},
  {video: {url: `93267.mp4`},word: "Cruel",},
  {video: {url: `50857.mp4`},word: "Shy",},
  {video: {url: `117067.mp4`},word: "Strict",},
  {video: {url: `125008.mp4`},word: "Boring",},
  {video: {url: `123110.mp4`},word: "Awkward",},
  {video: {url: `111066.mp4`},word: "Nice",},
  {video: {url: `65877.mp4`},word: "Fashionable",},
  {video: {url: `51074.mp4`},word: "Unpleasant",},
  {video: {url: `116822.mp4`},word: "Selfish",},
  {video: {url: `51882.mp4`},word: "Decent",},
  {video: {url: `49166.mp4`},word: "Ordinary",},
  {video: {url: `96439.mp4`},word: "Grouch",},
  {video: {url: `116917.mp4`},word: "Smart",},
  {video: {url: `125606.mp4`},word: "Bully",},
  {video: {url: `125929.mp4`},word: "Characteristic",},
  {video: {url: `110227.mp4`},word: "Miser",},
  {video: {url: `124985.mp4`},word: "Boldness",},
  {video: {url: `51511.mp4`},word: "Hypocrite",},
  {video: {url: `93959.mp4`},word: "Egoist",},
  {video: {url: `51609.mp4`},word: "Personality",},
  {video: {url: `65770.mp4`},word: "Talent",},
  {video: {url: `154706.mp4`},word: "Initiative",},
  {video: {url: `93100.mp4`},word: "Greed",},
  {video: {url: `51849.mp4`},word: "Gentleman",},
  {video: {url: `96588.mp4`},word: "Hysteric",},
  {video: {url: `125639.mp4`},word: "Busybody",},
  {video: {url: `93259.mp4`},word: "Courage",},
  {video: {url: `116599.mp4`},word: "Romantic",},
  {video: {url: `187736.mp4`},word: "Dandy",},
  {video: {url: `154787.mp4`},word: "Masculinity",},
  {video: {url: `245820.mp4`},word: "Personal traits",},
  {video: {url: `244822.mp4`},word: "Selfishness",},
  {video: {url: `125934.mp4`},word: "Charm",},
  {video: {url: `116244.mp4`},word: "Patriot",},
  {video: {url: `92944.mp4`},word: "Nerd",},
  {video: {url: `118566.mp4`},word: "Walk to school",},
  {video: {url: `94200.mp4`},word: "Bully",},
  {video: {url: `91292.mp4`},word: "Drop out",},
  {video: {url: `155679.mp4`},word: "Textbook publisher",},
  {video: {url: `93021.mp4`},word: "Fire drill",},
  {video: {url: `153260.mp4`},word: "Counseling",},
  {video: {url: `94235.mp4`},word: "Guidance team",},
  {video: {url: `63540.mp4`},word: "Holiday",},
  {video: {url: `245255.mp4`},word: "Smart board",},
  {video: {url: `62509.mp4`},word: "Auditorium",},
  {video: {url: `49222.mp4`},word: "Pupil",},
  {video: {url: `153243.mp4`},word: "Campus",},
  {video: {url: `58419.mp4`},word: "Dormitory",},
  {video: {url: `154572.mp4`},word: "Educational course book",},
  {video: {url: `154571.mp4`},word: "Educational books",},
  {video: {url: `155565.mp4`},word: "School trip",},
  {video: {url: `94358.mp4`},word: "Study visit",},
  {video: {url: `94201.mp4`},word: "Bullying",},
  {video: {url: `125917.mp4`},word: "Chalk",},
  {video: {url: `51787.mp4`},word: "All-day trip",},
  {video: {url: `96205.mp4`},word: "Detention",},
  {video: {url: `153244.mp4`},word: "Canteen",},
  {video: {url: `157918.mp4`},word: "Person on duty",},
  {video: {url: `94203.mp4`},word: "Class hours",},
  {video: {url: `153708.mp4`},word: "School environment",},
  {video: {url: `158010.mp4`},word: "Reading week",},
  {video: {url: `58527.mp4`},word: "Homework",},
  {video: {url: `94339.mp4`},word: "School transport",},
  {video: {url: `93009.mp4`},word: "Field trip",},
  {video: {url: `94183.mp4`},word: "Academic year",},
  {video: {url: `244205.mp4`},word: "Class assembly",},
  {video: {url: `125918.mp4`},word: "Chalkboard",},
  {video: {url: `58511.mp4`},word: "Graduation",},
  {video: {url: `58512.mp4`},word: "Graduation party",},
  {video: {url: `92639.mp4`},word: "Counselling",},
  {video: {url: `155647.mp4`},word: "Study trip",},
  {video: {url: `94310.mp4`},word: "Preparation for school",},
  {video: {url: `94293.mp4`},word: "Parental cooperation",},
  {video: {url: `74898.mp4`},word: "Cafeteria",},
  {video: {url: `94298.mp4`},word: "Peer groups",},
  {video: {url: `93111.mp4`},word: "Gym",},
  {video: {url: `94333.mp4`},word: "School attendance",},
  {video: {url: `94294.mp4`},word: "Parental influence",},
  {video: {url: `154681.mp4`},word: "Health room",},
  {video: {url: `245340.mp4`},word: "School readiness",},
  {video: {url: `244118.mp4`},word: "Breakfast club",},
  {video: {url: `94336.mp4`},word: "School health service",},
  {video: {url: `87892.mp4`},word: "Graduation style",},
  {video: {url: `154760.mp4`},word: "Log book",},
  {video: {url: `155672.mp4`},word: "Term",},
  {video: {url: `125606.mp4`},word: "Bully",},
  {video: {url: `51793.mp4`},word: "After school care club",},
  {video: {url: `94372.mp4`},word: "Text book",},
  {video: {url: `155678.mp4`},word: "Textbook",},
  {video: {url: `93319.mp4`},word: "Dorm",},
  {video: {url: `94108.mp4`},word: "Drop-out",},
  {video: {url: `155564.mp4`},word: "School holiday",},
  {video: {url: `154573.mp4`},word: "Educational publishing",},
  {video: {url: `116826.mp4`},word: "Senior student",},
  {video: {url: `109874.mp4`},word: "Drug free school zone",},
  {video: {url: `116421.mp4`},word: "Prom",},
  {video: {url: `116264.mp4`},word: "Peer pressure",},
  {video: {url: `96920.mp4`},word: "Overhead projector",},
  {video: {url: `62732.mp4`},word: "Class",},
  {video: {url: `125527.mp4`},word: "Break",},
  {video: {url: `154389.mp4`},word: "Careers service",},
  {video: {url: `50829.mp4`},word: "Student",},
  {video: {url: `94338.mp4`},word: "School meal",},
  {video: {url: `116952.mp4`},word: "Sophomore",},
  {video: {url: `117072.mp4`},word: "Study area",},
  {video: {url: `244542.mp4`},word: "Free school meal",},
  {video: {url: `116823.mp4`},word: "Semester",},
  {video: {url: `117073.mp4`},word: "Study hall",},
  {video: {url: `94157.mp4`},word: "Pupil council",},
  {video: {url: `62733.mp4`},word: "Class ring",},
  {video: {url: `94340.mp4`},word: "School year",},
  {video: {url: `94356.mp4`},word: "Study and vocational guidance",},
  {video: {url: `155644.mp4`},word: "Student-centred",},
  {video: {url: `94249.mp4`},word: "Individualise",},
  {video: {url: `93911.mp4`},word: "Continue education",},
  {video: {url: `50778.mp4`},word: "School",},
  {video: {url: `94314.mp4`},word: "Prior education",},
  {video: {url: `94226.mp4`},word: "Extended programme",},
  {video: {url: `94090.mp4`},word: "Completed education",},
  {video: {url: `94287.mp4`},word: "Online education",},
  {video: {url: `94118.mp4`},word: "Formal education",},
  {video: {url: `94188.mp4`},word: "Advanced depth specialised",},
  {video: {url: `244288.mp4`},word: "Correspondence study",},
  {video: {url: `94206.mp4`},word: "Completed compulsory education",},
  {video: {url: `94366.mp4`},word: "Teacher education",},
  {video: {url: `58402.mp4`},word: "University",},
  {video: {url: `89612.mp4`},word: "Boarding house",},
  {video: {url: `94198.mp4`},word: "Beginning of compulsory education",},
  {video: {url: `94208.mp4`},word: "Compulsory school",},
  {video: {url: `94210.mp4`},word: "Compulsory school level",},
  {video: {url: `94063.mp4`},word: "A school for all",},
  {video: {url: `116432.mp4`},word: "Public school",},
  {video: {url: `94305.mp4`},word: "Pre-school class",},
  {video: {url: `118563.mp4`},word: "Universal pre-school",},
  {video: {url: `94064.mp4`},word: "A school with a special profile",},
  {video: {url: `94306.mp4`},word: "Pre-school education",},
  {video: {url: `94148.mp4`},word: "Part time attendance",},
  {video: {url: `94145.mp4`},word: "Non-compulsory school",},
  {video: {url: `94134.mp4`},word: "Independent school",},
  {video: {url: `51571.mp4`},word: "Education",},
  {video: {url: `94308.mp4`},word: "Pre-school teacher training",},
  {video: {url: `94311.mp4`},word: "Preparatory training for school",},
  {video: {url: `94376.mp4`},word: "Type of school",},
  {video: {url: `94156.mp4`},word: "Public education, state education",},
  {video: {url: `244798.mp4`},word: "Sunday school",},
  {video: {url: `94153.mp4`},word: "Preparatory training",},
  {video: {url: `94089.mp4`},word: "Complete course programme",},
  {video: {url: `94135.mp4`},word: "Individually adapted education",},
  {video: {url: `244097.mp4`},word: "Boarding school",},
  {video: {url: `94207.mp4`},word: "Completed upper secondary education",},
  {video: {url: `94384.mp4`},word: "Vocational training",},
  {video: {url: `94378.mp4`},word: "Upper secondary level",},
  {video: {url: `94247.mp4`},word: "Individual program",},
  {video: {url: `94351.mp4`},word: "Specially designed programme",},
  {video: {url: `94349.mp4`},word: "Special needs education",},
  {video: {url: `94250.mp4`},word: "Individualised tuition",},
  {video: {url: `94344.mp4`},word: "Sign language training for parents",},
  {video: {url: `94309.mp4`},word: "Pre-vocational training",},
  {video: {url: `94383.mp4`},word: "Upper-secondary school program",},
  {video: {url: `94379.mp4`},word: "Upper secondary school",},
  {video: {url: `154656.mp4`},word: "General education",},
  {video: {url: `157881.mp4`},word: "Online learning",},
  {video: {url: `245769.mp4`},word: "Private school",},
  {video: {url: `58525.mp4`},word: "High school",},
  {video: {url: `94159.mp4`},word: "Pupil\u00b4s study plan",},
  {video: {url: `94077.mp4`},word: "Basic education",},
  {video: {url: `109889.mp4`},word: "Foundation year programme (univ)",},
  {video: {url: `94377.mp4`},word: "Upper secondary education for pupils with learning disabilities",},
  {video: {url: `94106.mp4`},word: "Distance education",},
  {video: {url: `94170.mp4`},word: "Special school",},
  {video: {url: `244386.mp4`},word: "Distance learning",},
  {video: {url: `94151.mp4`},word: "Post-secondary education",},
  {video: {url: `94146.mp4`},word: "Not graded",},
  {video: {url: `93037.mp4`},word: "Flunk",},
  {video: {url: `94128.mp4`},word: "Grade",},
  {video: {url: `94097.mp4`},word: "Degree certificates",},
  {video: {url: `94129.mp4`},word: "Grades",},
  {video: {url: `94131.mp4`},word: "Grading scale",},
  {video: {url: `94130.mp4`},word: "Grading criteria",},
  {video: {url: `94079.mp4`},word: "Basis for assessment",},
  {video: {url: `94132.mp4`},word: "Grading system",},
  {video: {url: `58302.mp4`},word: "Evaluation",},
  {video: {url: `154537.mp4`},word: "Deadline",},
  {video: {url: `94233.mp4`},word: "Grade average",},
  {video: {url: `94288.mp4`},word: "Oral examination",},
  {video: {url: `244642.mp4`},word: "Honours degree",},
  {video: {url: `94280.mp4`},word: "National tests",},
  {video: {url: `155650.mp4`},word: "Summative assessment",},
  {video: {url: `244423.mp4`},word: "End test",},
  {video: {url: `154405.mp4`},word: "Class test",},
  {video: {url: `153045.mp4`},word: "Advanced level",},
  {video: {url: `244508.mp4`},word: "Foundation degree",},
  {video: {url: `154718.mp4`},word: "Joint author",},
  {video: {url: `51586.mp4`},word: "Individual work",},
  {video: {url: `154704.mp4`},word: "In-course assessment",},
  {video: {url: `154703.mp4`},word: "In-class test",},
  {video: {url: `94316.mp4`},word: "Project work",},
  {video: {url: `116972.mp4`},word: "Spelling award",},
  {video: {url: `243981.mp4`},word: "Academic degree",},
  {video: {url: `94133.mp4`},word: "Independent project",},
  {video: {url: `157911.mp4`},word: "Peer assessment",},
  {video: {url: `51420.mp4`},word: "Certificate",},
  {video: {url: `94290.mp4`},word: "Overall grade",},
  {video: {url: `155673.mp4`},word: "Term paper",},
  {video: {url: `155654.mp4`},word: "Surprise test",},
  {video: {url: `154744.mp4`},word: "Level test",},
  {video: {url: `89903.mp4`},word: "Project",},
  {video: {url: `94266.mp4`},word: "Level descriptors",},
  {video: {url: `155642.mp4`},word: "Student report",},
  {video: {url: `109527.mp4`},word: "Assessor",},
  {video: {url: `94386.mp4`},word: "Written assessment",},
  {video: {url: `94380.mp4`},word: "Upper secondary school credits",},
  {video: {url: `157951.mp4`},word: "Postgraduate degree",},
  {video: {url: `153086.mp4`},word: "Audio-visual presentation",},
  {video: {url: `157851.mp4`},word: "Multiple choice questions",},
  {video: {url: `157884.mp4`},word: "Oral presentation",},
  {video: {url: `94166.mp4`},word: "School-leaving certificate",},
  {video: {url: `155651.mp4`},word: "Summative feedback",},
  {video: {url: `62897.mp4`},word: "Quiz",},
  {video: {url: `154555.mp4`},word: "Dissertation",},
  {video: {url: `154525.mp4`},word: "Critical review",},
  {video: {url: `153078.mp4`},word: "Assessment criteria",},
  {video: {url: `153798.mp4`},word: "Written test",},
  {video: {url: `94093.mp4`},word: "Continuous assessment",},
  {video: {url: `116973.mp4`},word: "Spelling bee",},
  {video: {url: `157883.mp4`},word: "Open book examination",},
  {video: {url: `116524.mp4`},word: "Report card",},
  {video: {url: `94074.mp4`},word: "Assessment",},
  {video: {url: `153058.mp4`},word: "Appendix",},
  {video: {url: `153301.mp4`},word: "English as a foreign language",},
  {video: {url: `94257.mp4`},word: "Interdisciplinary",},
  {video: {url: `153059.mp4`},word: "Applied science",},
  {video: {url: `94117.mp4`},word: "Foreign language",},
  {video: {url: `94147.mp4`},word: "Optional course",},
  {video: {url: `94178.mp4`},word: "Work placement training",},
  {video: {url: `94278.mp4`},word: "Mother tongue tuition",},
  {video: {url: `244509.mp4`},word: "Foundation course",},
  {video: {url: `94267.mp4`},word: "Life skills",},
  {video: {url: `51610.mp4`},word: "Physical education",},
  {video: {url: `154517.mp4`},word: "Course structure",},
  {video: {url: `153060.mp4`},word: "Applied technology",},
  {video: {url: `94212.mp4`},word: "Core subject",},
  {video: {url: `50837.mp4`},word: "Subject",},
  {video: {url: `94152.mp4`},word: "Practical and artistic subjects",},
  {video: {url: `94214.mp4`},word: "Course code",},
  {video: {url: `94073.mp4`},word: "Artistic activities",},
  {video: {url: `94160.mp4`},word: "Pupil\u2019s choice",},
  {video: {url: `155544.mp4`},word: "Rhythmics",},
  {video: {url: `155723.mp4`},word: "Woodshop",},
  {video: {url: `244207.mp4`},word: "Civics",},
  {video: {url: `94127.mp4`},word: "General course",},
  {video: {url: `123355.mp4`},word: "Basic course",},
  {video: {url: `154422.mp4`},word: "Computer class",},
  {video: {url: `94124.mp4`},word: "Full programme",},
  {video: {url: `50779.mp4`},word: "Science",},
  {video: {url: `94215.mp4`},word: "Course for beginners",},
  {video: {url: `157860.mp4`},word: "Natural science",},
  {video: {url: `58497.mp4`},word: "Course",},
  {video: {url: `154694.mp4`},word: "Human studies",},
  {video: {url: `89896.mp4`},word: "Philosophy",},
  {video: {url: `58542.mp4`},word: "Linguistics",},
  {video: {url: `244283.mp4`},word: "Coursework",},
  {video: {url: `154748.mp4`},word: "Library science",},
  {video: {url: `94082.mp4`},word: "Block of subjects",},
  {video: {url: `244249.mp4`},word: "Computer science",},
  {video: {url: `94385.mp4`},word: "Work experience programme",},
  {video: {url: `96557.mp4`},word: "Hit the books",},
  {video: {url: `244983.mp4`},word: "Word-based learning",},
  {video: {url: `244780.mp4`},word: "Understood",},
  {video: {url: `58530.mp4`},word: "Ignorant",},
  {video: {url: `51931.mp4`},word: "Illiterate",},
  {video: {url: `92784.mp4`},word: "Well-informed",},
  {video: {url: `48552.mp4`},word: "Learn",},
  {video: {url: `50869.mp4`},word: "To understand",},
  {video: {url: `94011.mp4`},word: "Research",},
  {video: {url: `48544.mp4`},word: "Know",},
  {video: {url: `51083.mp4`},word: "Wonder",},
  {video: {url: `92865.mp4`},word: "Memorize",},
  {video: {url: `50835.mp4`},word: "Study",},
  {video: {url: `122594.mp4`},word: "Assignment",},
  {video: {url: `154723.mp4`},word: "Key concept",},
  {video: {url: `94357.mp4`},word: "Study problems",},
  {video: {url: `154707.mp4`},word: "Intellectual",},
  {video: {url: `154567.mp4`},word: "Easy-to-read book",},
  {video: {url: `158009.mp4`},word: "Reading habit",},
  {video: {url: `58566.mp4`},word: "Tutorial",},
  {video: {url: `243977.mp4`},word: "Academic progress",},
  {video: {url: `96597.mp4`},word: "Ignorance",},
  {video: {url: `243980.mp4`},word: "Academic difficulty",},
  {video: {url: `243978.mp4`},word: "Academic performance",},
  {video: {url: `245748.mp4`},word: "Project based learning",},
  {video: {url: `94191.mp4`},word: "Aim",},
  {video: {url: `94268.mp4`},word: "Lifelong learning",},
  {video: {url: `155645.mp4`},word: "Study skills",},
  {video: {url: `154709.mp4`},word: "Intelligence",},
  {video: {url: `155730.mp4`},word: "Written communication",},
  {video: {url: `153049.mp4`},word: "Aims",},
  {video: {url: `155594.mp4`},word: "Short-term memory",},
  {video: {url: `154735.mp4`},word: "Learning centre",},
  {video: {url: `154734.mp4`},word: "Learning and teaching",},
  {video: {url: `94181.mp4`},word: "Youth recreation leader",},
  {video: {url: `94091.mp4`},word: "Computer assisted learning",},
  {video: {url: `62900.mp4`},word: "Symbol game",},
  {video: {url: `154543.mp4`},word: "Deep learning",},
  {video: {url: `94051.mp4`},word: "Understanding",},
  {video: {url: `94070.mp4`},word: "Alternative learning strategies",},
  {video: {url: `153298.mp4`},word: "E-learning",},
  {video: {url: `94196.mp4`},word: "Basic knowledge",},
  {video: {url: `154736.mp4`},word: "Learning log",},
  {video: {url: `94012.mp4`},word: "Research",},
  {video: {url: `94197.mp4`},word: "Basic level",},
  {video: {url: `154592.mp4`},word: "Experiential learning",},
  {video: {url: `94248.mp4`},word: "Individual transition plan",},
  {video: {url: `51785.mp4`},word: "Activity",},
  {video: {url: `51837.mp4`},word: "Development",},
  {video: {url: `94246.mp4`},word: "Individual development",},
  {video: {url: `155646.mp4`},word: "Study support",},
  {video: {url: `245167.mp4`},word: "Surface learning",},
  {video: {url: `51572.mp4`},word: "Educational game",},
  {video: {url: `155728.mp4`},word: "Workbook",},
  {video: {url: `246081.mp4`},word: "Learning outcome",},
  {video: {url: `154737.mp4`},word: "Learning style",},
  {video: {url: `157912.mp4`},word: "Peer group learning",},
  {video: {url: `125293.mp4`},word: "Brainstorm",},
  {video: {url: `153109.mp4`},word: "Basic skills",},
  {video: {url: `94078.mp4`},word: "Basic skill",},
  {video: {url: `244884.mp4`},word: "Knowledge",},
  {video: {url: `94255.mp4`},word: "Intellectual development",},
  {video: {url: `94218.mp4`},word: "Disadvantaged",},
  {video: {url: `94066.mp4`},word: "Adjusted",},
  {video: {url: `94144.mp4`},word: "Needs-oriented",},
  {video: {url: `51931.mp4`},word: "Illiterate",},
  {video: {url: `91749.mp4`},word: "Dyslexia",},
  {video: {url: `245219.mp4`},word: "Speech therapy room",},
  {video: {url: `244415.mp4`},word: "Education of the deaf",},
  {video: {url: `244414.mp4`},word: "Educational interpreter",},
  {video: {url: `94357.mp4`},word: "Study problems",},
  {video: {url: `94362.mp4`},word: "Support teacher",},
  {video: {url: `243980.mp4`},word: "Academic difficulty",},
  {video: {url: `94283.mp4`},word: "Neuropsychiatric disorder",},
  {video: {url: `94342.mp4`},word: "Severe and profound learning disability",},
  {video: {url: `94169.mp4`},word: "Special educational needs",},
  {video: {url: `94247.mp4`},word: "Individual program",},
  {video: {url: `94080.mp4`},word: "Behaviour problems",},
  {video: {url: `155661.mp4`},word: "Talking book",},
  {video: {url: `94065.mp4`},word: "Adapted course of studies",},
  {video: {url: `94261.mp4`},word: "Learning difficulties",},
  {video: {url: `94348.mp4`},word: "Special arrangements for pupils with physical disabilities",},
  {video: {url: `94170.mp4`},word: "Special school",},
  {video: {url: `94168.mp4`},word: "Special educational needs teacher",},
  {video: {url: `94349.mp4`},word: "Special needs education",},
  {video: {url: `94250.mp4`},word: "Individualised tuition",},
  {video: {url: `94094.mp4`},word: "Curricula for pupils with a hearing impairment or deafness",},
  {video: {url: `94350.mp4`},word: "Special support",},
  {video: {url: `94221.mp4`},word: "Education for adults with learning disabilities",},
  {video: {url: `94368.mp4`},word: "Teacher in education for pupils with learning disabilities",},
  {video: {url: `94377.mp4`},word: "Upper secondary education for pupils with learning disabilities",},
  {video: {url: `94067.mp4`},word: "Admission on special grounds",},
  {video: {url: `94109.mp4`},word: "Dyslectic",},
  {video: {url: `245930.mp4`},word: "New build",},
  {video: {url: `244425.mp4`},word: "En suite",},
  {video: {url: `158046.mp4`},word: "Residential",},
  {video: {url: `155614.mp4`},word: "Spatial",},
  {video: {url: `58463.mp4`},word: "House",},
  {video: {url: `69325.mp4`},word: "Architecture",},
  {video: {url: `89500.mp4`},word: "Architectural style",},
  {video: {url: `155640.mp4`},word: "Structure",},
  {video: {url: `62506.mp4`},word: "Attic",},
  {video: {url: `92670.mp4`},word: "Fireplace",},
  {video: {url: `123630.mp4`},word: "Bedroom",},
  {video: {url: `62509.mp4`},word: "Auditorium",},
  {video: {url: `245810.mp4`},word: "Pillar",},
  {video: {url: `116902.mp4`},word: "Skyscraper",},
  {video: {url: `92630.mp4`},word: "Cloakroom",},
  {video: {url: `96179.mp4`},word: "Deck",},
  {video: {url: `89852.mp4`},word: "Main entrance",},
  {video: {url: `245983.mp4`},word: "Mezzanine",},
  {video: {url: `58416.mp4`},word: "Entrance",},
  {video: {url: `62608.mp4`},word: "Blueprint",},
  {video: {url: `155687.mp4`},word: "Town house",},
  {video: {url: `155627.mp4`},word: "Staircase",},
  {video: {url: `122822.mp4`},word: "Atrium",},
  {video: {url: `51342.mp4`},word: "Basement",},
  {video: {url: `73306.mp4`},word: "Fence",},
  {video: {url: `125996.mp4`},word: "Chimney",},
  {video: {url: `154560.mp4`},word: "Dome",},
  {video: {url: `116866.mp4`},word: "Siding",},
  {video: {url: `154514.mp4`},word: "Country house",},
  {video: {url: `154513.mp4`},word: "Cottage",},
  {video: {url: `92597.mp4`},word: "Apartment",},
  {video: {url: `123211.mp4`},word: "Balcony",},
  {video: {url: `89560.mp4`},word: "Steeple, spire, church tower",},
  {video: {url: `93034.mp4`},word: "Floor plan",},
  {video: {url: `154617.mp4`},word: "Flat roof",},
  {video: {url: `157932.mp4`},word: "Plan",},
  {video: {url: `154393.mp4`},word: "Centre",},
  {video: {url: `50600.mp4`},word: "Awning",},
  {video: {url: `153234.mp4`},word: "Built-in wardrobe",},
  {video: {url: `96482.mp4`},word: "Hall",},
  {video: {url: `73311.mp4`},word: "Front garden",},
  {video: {url: `51911.mp4`},word: "Entry",},
  {video: {url: `92734.mp4`},word: "Sauna",},
  {video: {url: `246004.mp4`},word: "Measured drawings",},
  {video: {url: `246019.mp4`},word: "Manor",},
  {video: {url: `92604.mp4`},word: "Bathroom",},
  {video: {url: `245224.mp4`},word: "Space planning",},
  {video: {url: `122540.mp4`},word: "Architect",},
  {video: {url: `155618.mp4`},word: "Specification",},
  {video: {url: `124974.mp4`},word: "Boarded wall",},
  {video: {url: `154691.mp4`},word: "Housing",},
  {video: {url: `245369.mp4`},word: "Roof space",},
  {video: {url: `246113.mp4`},word: "Interior architecture",},
  {video: {url: `244910.mp4`},word: "Fortress",},
  {video: {url: `96274.mp4`},word: "Draftsman",},
  {video: {url: `244182.mp4`},word: "Ceiling height",},
  {video: {url: `62796.mp4`},word: "Room",},
  {video: {url: `91458.mp4`},word: "Cellar",},
  {video: {url: `90108.mp4`},word: "Tower",},
  {video: {url: `96933.mp4`},word: "Mansion",},
  {video: {url: `246042.mp4`},word: "Loft",},
  {video: {url: `117049.mp4`},word: "Storm door",},
  {video: {url: `58457.mp4`},word: "Door",},
  {video: {url: `243975.mp4`},word: "Access audit",},
  {video: {url: `191449.mp4`},word: "Precinct",},
  {video: {url: `59157.mp4`},word: "Dining room",},
  {video: {url: `157946.mp4`},word: "Porch",},
  {video: {url: `155653.mp4`},word: "Supporting wall",},
  {video: {url: `89527.mp4`},word: "Interior",},
  {video: {url: `51408.mp4`},word: "Ceiling",},
  {video: {url: `117050.mp4`},word: "Storm window",},
  {video: {url: `65928.mp4`},word: "Terrace",},
  {video: {url: `117005.mp4`},word: "Stair",},
  {video: {url: `94036.mp4`},word: "Storage room",},
  {video: {url: `153215.mp4`},word: "Building act 1984",},
  {video: {url: `244038.mp4`},word: "Arch",},
  {video: {url: `51930.mp4`},word: "If",},
  {video: {url: `125015.mp4`},word: "Both",},
  {video: {url: `123624.mp4`},word: "Because",},
  {video: {url: `89862.mp4`},word: "Neither",},
  {video: {url: `125640.mp4`},word: "But",},
  {video: {url: `58545.mp4`},word: "Or",},
  {video: {url: `51880.mp4`},word: "Czech republic",},
  {video: {url: `47836.mp4`},word: "Germany",},
  {video: {url: `4038.mp4`},word: "Deaf",},
  {video: {url: `48563.mp4`},word: "Listen",},
  {video: {url: `63539.mp4`},word: "Hearing",},
  {video: {url: `94113.mp4`},word: "Fm system",},
  {video: {url: `122841.mp4`},word: "Audiology",},
  {video: {url: `94269.mp4`},word: "Lip-reading",},
  {video: {url: `93338.mp4`},word: "Earmold",},
  {video: {url: `96292.mp4`},word: "Ear thermometer",},
  {video: {url: `93337.mp4`},word: "Earache",},
  {video: {url: `93920.mp4`},word: "Deafness",},
  {video: {url: `94088.mp4`},word: "Cochlear implant",},
  {video: {url: `94172.mp4`},word: "Unilateral hearing loss",},
  {video: {url: `94286.mp4`},word: "Notetaker",},
  {video: {url: `58523.mp4`},word: "Hearing aid",},
  {video: {url: `93123.mp4`},word: "Hearing ear dog",},
  {video: {url: `93124.mp4`},word: "Hearing problem",},
  {video: {url: `91670.mp4`},word: "Ci",},
  {video: {url: `94075.mp4`},word: "Audiologist",},
  {video: {url: `91702.mp4`},word: "Audiogram",},
  {video: {url: `185074.mp4`},word: "Hearing impariment",},
  {video: {url: `94081.mp4`},word: "Bilateral hearing loss",},
  {video: {url: `87634.mp4`},word: "Singles play",},
  {video: {url: `97064.mp4`},word: "Ping-pong racket",},
  {video: {url: `123135.mp4`},word: "Back boundary line",},
  {video: {url: `51548.mp4`},word: "Badminton",},
  {video: {url: `51520.mp4`},word: "Badminton racket",},
  {video: {url: `116459.mp4`},word: "Racket",},
  {video: {url: `97063.mp4`},word: "Ping-pong",},
  {video: {url: `87674.mp4`},word: "Tennis",},
  {video: {url: `87624.mp4`},word: "Service area",},
  {video: {url: `153702.mp4`},word: "Right service court",},
  {video: {url: `124680.mp4`},word: "Birdie",},
  {video: {url: `87672.mp4`},word: "Tennis court",},
  {video: {url: `153610.mp4`},word: "Left service court",},
  {video: {url: `87627.mp4`},word: "Short service",},
  {video: {url: `89972.mp4`},word: "Table tennis",},
  {video: {url: `97033.mp4`},word: "Doubles play",},
  {video: {url: `90005.mp4`},word: "Tennis ball",},
  {video: {url: `51884.mp4`},word: "Gymnastics",},
  {video: {url: `91314.mp4`},word: "Artistic gymnastic men",},
  {video: {url: `91315.mp4`},word: "Artistic gymnastic women",},
  {video: {url: `91311.mp4`},word: "Acrobat",},
  {video: {url: `51652.mp4`},word: "Trampolining",},
  {video: {url: `63538.mp4`},word: "Gymnastics",},
  {video: {url: `97044.mp4`},word: "Gymnast",},
  {video: {url: `97049.mp4`},word: "Horizontal bars",},
  {video: {url: `153318.mp4`},word: "Floor exercise mat",},
  {video: {url: `91319.mp4`},word: "Balance beam",},
  {video: {url: `97075.mp4`},word: "Trampoline",},
  {video: {url: `97061.mp4`},word: "Parallel bars",},
  {video: {url: `92810.mp4`},word: "Wrestle",},
  {video: {url: `50828.mp4`},word: "Struggle",},
  {video: {url: `153620.mp4`},word: "Martial arts",},
  {video: {url: `153599.mp4`},word: "Judo",},
  {video: {url: `125245.mp4`},word: "Boxing",},
  {video: {url: `116442.mp4`},word: "Punch",},
  {video: {url: `91313.mp4`},word: "Arm wrestling",},
  {video: {url: `125242.mp4`},word: "Boxing glove",},
  {video: {url: `125662.mp4`},word: "Cage",},
  {video: {url: `91865.mp4`},word: "Wrestling",},
  {video: {url: `93949.mp4`},word: "Dive",},
  {video: {url: `50850.mp4`},word: "Swim",},
  {video: {url: `123143.mp4`},word: "Backstroke",},
  {video: {url: `51527.mp4`},word: "Butterfly",},
  {video: {url: `87661.mp4`},word: "Swimming pool lane",},
  {video: {url: `97072.mp4`},word: "Swim goggles",},
  {video: {url: `87665.mp4`},word: "Swimsuit",},
  {video: {url: `153739.mp4`},word: "Swim stroke",},
  {video: {url: `87658.mp4`},word: "Swim cap",},
  {video: {url: `97075.mp4`},word: "Trampoline",},
  {video: {url: `97031.mp4`},word: "Diving board",},
  {video: {url: `87677.mp4`},word: "Touch pad",},
  {video: {url: `92656.mp4`},word: "Diving",},
  {video: {url: `51916.mp4`},word: "Pool",},
  {video: {url: `117123.mp4`},word: "Swim instructor",},
  {video: {url: `89761.mp4`},word: "Swimming trunks",},
  {video: {url: `51525.mp4`},word: "Breaststroke",},
  {video: {url: `87689.mp4`},word: "Trudgen",},
  {video: {url: `91860.mp4`},word: "Snorkelling",},
  {video: {url: `87659.mp4`},word: "Swim glove",},
  {video: {url: `97030.mp4`},word: "Diver",},
  {video: {url: `87660.mp4`},word: "Swimming kick",},
  {video: {url: `87664.mp4`},word: "Swimming pool lane lines",},
  {video: {url: `51615.mp4`},word: "Pool",},
  {video: {url: `51652.mp4`},word: "Trampolining",},
  {video: {url: `97037.mp4`},word: "Flip turn",},
  {video: {url: `51597.mp4`},word: "Crawl",},
  {video: {url: `63591.mp4`},word: "Swimming",},
  {video: {url: `89611.mp4`},word: "Baby swim",},
  {video: {url: `51746.mp4`},word: "Climb",},
  {video: {url: `97042.mp4`},word: "Gps",},
  {video: {url: `87701.mp4`},word: "Walnut",},
  {video: {url: `153647.mp4`},word: "Orienteering",},
  {video: {url: `153250.mp4`},word: "Climbing way",},
  {video: {url: `51537.mp4`},word: "Climbing",},
  {video: {url: `153618.mp4`},word: "Map carrier",},
  {video: {url: `123112.mp4`},word: "Azimuth",},
  {video: {url: `153293.mp4`},word: "Dynamic rope",},
  {video: {url: `244231.mp4`},word: "Compass",},
  {video: {url: `245172.mp4`},word: "Summit",},
  {video: {url: `153648.mp4`},word: "Orienteering beacon",},
  {video: {url: `87615.mp4`},word: "Sailing",},
  {video: {url: `97095.mp4`},word: "Punt",},
  {video: {url: `125924.mp4`},word: "Channel",},
  {video: {url: `92708.mp4`},word: "Oar",},
  {video: {url: `116608.mp4`},word: "Rowboat",},
  {video: {url: `116849.mp4`},word: "Ship captain",},
  {video: {url: `92609.mp4`},word: "Boat-house",},
  {video: {url: `116779.mp4`},word: "Sailboat",},
  {video: {url: `96998.mp4`},word: "Anchor",},
  {video: {url: `92618.mp4`},word: "Canoe",},
  {video: {url: `116300.mp4`},word: "Pier",},
  {video: {url: `116567.mp4`},word: "Riverboat",},
  {video: {url: `93032.mp4`},word: "Float",},
  {video: {url: `93961.mp4`},word: "Lighthouse",},
  {video: {url: `97056.mp4`},word: "Life jacket",},
  {video: {url: `62612.mp4`},word: "Boat launch",},
  {video: {url: `124978.mp4`},word: "Boat",},
  {video: {url: `92605.mp4`},word: "Bay",},
  {video: {url: `125761.mp4`},word: "Canoeing",},
  {video: {url: `96151.mp4`},word: "Cruise ship",},
  {video: {url: `116556.mp4`},word: "Ride",},
  {video: {url: `116776.mp4`},word: "Saddle",},
  {video: {url: `90207.mp4`},word: "Way",},
  {video: {url: `96640.mp4`},word: "Jockey",},
  {video: {url: `244640.mp4`},word: "Horse riding",},
  {video: {url: `123337.mp4`},word: "Base position",},
  {video: {url: `97040.mp4`},word: "Goal line",},
  {video: {url: `244961.mp4`},word: "Youth centre",},
  {video: {url: `87657.mp4`},word: "Substitution area",},
  {video: {url: `87679.mp4`},word: "Touchdown area",},
  {video: {url: `93044.mp4`},word: "Football field",},
  {video: {url: `87637.mp4`},word: "Soccer field",},
  {video: {url: `153327.mp4`},word: "Goal area",},
  {video: {url: `153659.mp4`},word: "Penalty area",},
  {video: {url: `153336.mp4`},word: "Halfway line",},
  {video: {url: `91854.mp4`},word: "Pavillion",},
  {video: {url: `154393.mp4`},word: "Centre",},
  {video: {url: `125902.mp4`},word: "Center line",},
  {video: {url: `87631.mp4`},word: "Side line",},
  {video: {url: `92624.mp4`},word: "Chair lift",},
  {video: {url: `153668.mp4`},word: "Playing court",},
  {video: {url: `87672.mp4`},word: "Tennis court",},
  {video: {url: `87646.mp4`},word: "Stadium",},
  {video: {url: `122573.mp4`},word: "Artificial turf",},
  {video: {url: `91839.mp4`},word: "Downhill",},
  {video: {url: `125923.mp4`},word: "Changing room",},
  {video: {url: `97043.mp4`},word: "Gymnasium",},
  {video: {url: `93111.mp4`},word: "Gym",},
  {video: {url: `116856.mp4`},word: "Shoot",},
  {video: {url: `125213.mp4`},word: "Bowl, to",},
  {video: {url: `58396.mp4`},word: "Skiing",},
  {video: {url: `91318.mp4`},word: "Back set",},
  {video: {url: `87636.mp4`},word: "Snooker",},
  {video: {url: `97039.mp4`},word: "Futsal",},
  {video: {url: `51792.mp4`},word: "Blocker",},
  {video: {url: `91846.mp4`},word: "Ice-skating",},
  {video: {url: `87654.mp4`},word: "Substitute player",},
  {video: {url: `97041.mp4`},word: "Goalkeeper",},
  {video: {url: `116900.mp4`},word: "Sky diving",},
  {video: {url: `116899.mp4`},word: "Sky diver",},
  {video: {url: `51796.mp4`},word: "Aquatics",},
  {video: {url: `125214.mp4`},word: "Bowler",},
  {video: {url: `122539.mp4`},word: "Archery",},
  {video: {url: `91836.mp4`},word: "Cross country running",},
  {video: {url: `96423.mp4`},word: "Golfer",},
  {video: {url: `116364.mp4`},word: "Pool player",},
  {video: {url: `123215.mp4`},word: "Ball sport",},
  {video: {url: `51595.mp4`},word: "Cricket",},
  {video: {url: `153262.mp4`},word: "Cross country",},
  {video: {url: `123338.mp4`},word: "Baseball",},
  {video: {url: `91837.mp4`},word: "Cycling",},
  {video: {url: `123363.mp4`},word: "Basketball",},
  {video: {url: `87626.mp4`},word: "Shooting",},
  {video: {url: `93025.mp4`},word: "Fishing",},
  {video: {url: `116359.mp4`},word: "Polo player",},
  {video: {url: `91847.mp4`},word: "Indoor sports",},
  {video: {url: `92684.mp4`},word: "Golf",},
  {video: {url: `116360.mp4`},word: "Polo",},
  {video: {url: `123340.mp4`},word: "Baseball player",},
  {video: {url: `51629.mp4`},word: "Rollerblading",},
  {video: {url: `97046.mp4`},word: "Handball",},
  {video: {url: `50940.mp4`},word: "Volleyball",},
  {video: {url: `58393.mp4`},word: "Ice hockey",},
  {video: {url: `58390.mp4`},word: "Defensive player",},
  {video: {url: `97059.mp4`},word: "Offensive player",},
  {video: {url: `122538.mp4`},word: "Archer",},
  {video: {url: `91833.mp4`},word: "Bowling",},
  {video: {url: `93156.mp4`},word: "Ice skater",},
  {video: {url: `97036.mp4`},word: "Fencing",},
  {video: {url: `97066.mp4`},word: "Receiver",},
  {video: {url: `116931.mp4`},word: "Snow skier",},
  {video: {url: `116314.mp4`},word: "Pitcher",},
  {video: {url: `116892.mp4`},word: "Skateboarding",},
  {video: {url: `91316.mp4`},word: "Back",},
  {video: {url: `117103.mp4`},word: "Surfer",},
  {video: {url: `91852.mp4`},word: "Outdoor sports",},
  {video: {url: `90182.mp4`},word: "Volleyball player",},
  {video: {url: `96364.mp4`},word: "Fencer",},
  {video: {url: `51628.mp4`},word: "Roller skating",},
  {video: {url: `245137.mp4`},word: "Team sport",},
  {video: {url: `91862.mp4`},word: "Surfing",},
  {video: {url: `87702.mp4`},word: "Water polo",},
  {video: {url: `123366.mp4`},word: "Basketball player",},
  {video: {url: `92587.mp4`},word: "Brazil",},
  {video: {url: `58488.mp4`},word: "Communicate",},
  {video: {url: `109537.mp4`},word: "Block",},
  {video: {url: `153751.mp4`},word: "Third generation mobile phone",},
  {video: {url: `153300.mp4`},word: "Emoticons",},
  {video: {url: `51389.mp4`},word: "Carphone",},
  {video: {url: `116289.mp4`},word: "Phone stand",},
  {video: {url: `89998.mp4`},word: "Telephone operator",},
  {video: {url: `94373.mp4`},word: "Textphone",},
  {video: {url: `51133.mp4`},word: "Telephone",},
  {video: {url: `51848.mp4`},word: "Fax",},
  {video: {url: `48594.mp4`},word: "Mobile phone",},
  {video: {url: `186337.mp4`},word: "Conducting connection",},
  {video: {url: `153716.mp4`},word: "Sim card",},
  {video: {url: `118524.mp4`},word: "Answer machine",},
  {video: {url: `153791.mp4`},word: "Videoconference",},
  {video: {url: `109521.mp4`},word: "Apps",},
  {video: {url: `153790.mp4`},word: "Video relay service",},
  {video: {url: `125624.mp4`},word: "Bus network",},
  {video: {url: `153792.mp4`},word: "Videophone",},
  {video: {url: `51413.mp4`},word: "Cell phone",},
  {video: {url: `94253.mp4`},word: "Information and communication technology (ict/it)",},
  {video: {url: `94371.mp4`},word: "Telephone relay service",},
  {video: {url: `65889.mp4`},word: "Main station",},
  {video: {url: `109463.mp4`},word: "3g",},
  {video: {url: `93661.mp4`},word: "Insert cd",},
  {video: {url: `89565.mp4`},word: "Three-dimensional",},
  {video: {url: `153259.mp4`},word: "Cordless",},
  {video: {url: `153278.mp4`},word: "Digital",},
  {video: {url: `153249.mp4`},word: "Click",},
  {video: {url: `158043.mp4`},word: "Reset",},
  {video: {url: `97042.mp4`},word: "Gps",},
  {video: {url: `245255.mp4`},word: "Smart board",},
  {video: {url: `245970.mp4`},word: "Model animation",},
  {video: {url: `153769.mp4`},word: "User account",},
  {video: {url: `244369.mp4`},word: "Dialogue box",},
  {video: {url: `109669.mp4`},word: "Cd",},
  {video: {url: `153291.mp4`},word: "Dvd-r",},
  {video: {url: `110154.mp4`},word: "Illegal file sharing",},
  {video: {url: `122859.mp4`},word: "Av cable",},
  {video: {url: `118514.mp4`},word: "Animation",},
  {video: {url: `72906.mp4`},word: "Tachometer",},
  {video: {url: `96228.mp4`},word: "Digit",},
  {video: {url: `153292.mp4`},word: "Dvd-rw",},
  {video: {url: `94282.mp4`},word: "Networking",},
  {video: {url: `126000.mp4`},word: "Chip",},
  {video: {url: `153710.mp4`},word: "Screen",},
  {video: {url: `118584.mp4`},word: "Wii",},
  {video: {url: `96305.mp4`},word: "Electronic game",},
  {video: {url: `153276.mp4`},word: "Digital billboard",},
  {video: {url: `153754.mp4`},word: "Touch screen",},
  {video: {url: `51733.mp4`},word: "Video game",},
  {video: {url: `153084.mp4`},word: "Attention point",},
  {video: {url: `153606.mp4`},word: "Lcd",},
  {video: {url: `153770.mp4`},word: "Username",},
  {video: {url: `69339.mp4`},word: "Flow control",},
  {video: {url: `153277.mp4`},word: "Digital book",},
  {video: {url: `153669.mp4`},word: "Playstation 3",},
  {video: {url: `117092.mp4`},word: "Sunnah",},
  {video: {url: `117102.mp4`},word: "Sura",},
  {video: {url: `244789.mp4`},word: "The black stone",},
  {video: {url: `118595.mp4`},word: "Wudu",},
  {video: {url: `244864.mp4`},word: "Muslim",},
  {video: {url: `109518.mp4`},word: "Allah",},
  {video: {url: `109510.mp4`},word: "Abraham",},
  {video: {url: `92659.mp4`},word: "Ecuador",},
  {video: {url: `92631.mp4`},word: "Colombia",},
  {video: {url: `92628.mp4`},word: "Chile",},
  {video: {url: `50819.mp4`},word: "South america",},
  {video: {url: `97080.mp4`},word: "Argentina",},
  {video: {url: `62610.mp4`},word: "Blush",},
  {video: {url: `91431.mp4`},word: "Goosebumps",},
  {video: {url: `94033.mp4`},word: "Spot",},
  {video: {url: `116479.mp4`},word: "Rash",},
  {video: {url: `116304.mp4`},word: "Pimple",},
  {video: {url: `92750.mp4`},word: "Sun lotion",},
  {video: {url: `91677.mp4`},word: "Acne",},
  {video: {url: `185278.mp4`},word: "Skin",},
  {video: {url: `93058.mp4`},word: "Freckle",},
  {video: {url: `93206.mp4`},word: "Blister",},
  {video: {url: `91675.mp4`},word: "Stretch marks",},
  {video: {url: `244064.mp4`},word: "Bedsore",},
  {video: {url: `96197.mp4`},word: "Dermatology",},
  {video: {url: `154596.mp4`},word: "Fast forward",},
  {video: {url: `58385.mp4`},word: "Film",},
  {video: {url: `125557.mp4`},word: "Broadcast",},
  {video: {url: `155541.mp4`},word: "Rewind",},
  {video: {url: `50923.mp4`},word: "video",},
  {video: {url: `155684.mp4`},word: "Timeline",},
  {video: {url: `153291.mp4`},word: "Dvd-r",},
  {video: {url: `244500.mp4`},word: "Film roll",},
  {video: {url: `155704.mp4`},word: "Video recorder",},
  {video: {url: `245902.mp4`},word: "Offline edit",},
  {video: {url: `154570.mp4`},word: "Editing script",},
  {video: {url: `245979.mp4`},word: "Midpoint",},
  {video: {url: `153793.mp4`},word: "Videotape",},
  {video: {url: `90144.mp4`},word: "Tv stand",},
  {video: {url: `90040.mp4`},word: "Three-d movie",},
  {video: {url: `90039.mp4`},word: "Three-d glasses",},
  {video: {url: `155602.mp4`},word: "Slow motion",},
  {video: {url: `153351.mp4`},word: "High definition",},
  {video: {url: `50947.mp4`},word: "Tv",},
  {video: {url: `244649.mp4`},word: "In-camera edit",},
  {video: {url: `153788.mp4`},word: "Video clip",},
  {video: {url: `153100.mp4`},word: "Avid",},
  {video: {url: `51291.mp4`},word: "Cameraman",},
  {video: {url: `90143.mp4`},word: "Tv repairman",},
  {video: {url: `153290.mp4`},word: "Dvd player",},
  {video: {url: `125657.mp4`},word: "Cable tv",},
  {video: {url: `244126.mp4`},word: "Book trailer",},
  {video: {url: `153254.mp4`},word: "Color tv",},
  {video: {url: `153292.mp4`},word: "Dvd-rw",},
  {video: {url: `58388.mp4`},word: "Television",},
  {video: {url: `124962.mp4`},word: "Blu-ray disc",},
  {video: {url: `153791.mp4`},word: "Videoconference",},
  {video: {url: `153789.mp4`},word: "Video production",},
  {video: {url: `245344.mp4`},word: "Satellite dish",},
  {video: {url: `153627.mp4`},word: "Mini dv",},
  {video: {url: `244261.mp4`},word: "Continuity",},
  {video: {url: `155719.mp4`},word: "Widescreen",},
  {video: {url: `153790.mp4`},word: "Video relay service",},
  {video: {url: `97104.mp4`},word: "Video camera",},
  {video: {url: `244125.mp4`},word: "Book video",},
  {video: {url: `90174.mp4`},word: "Videographer",},
  {video: {url: `153099.mp4`},word: "Avi",},
  {video: {url: `116420.mp4`},word: "Projector",},
  {video: {url: `153792.mp4`},word: "Videophone",},
  {video: {url: `153595.mp4`},word: "Iso",},
  {video: {url: `158030.mp4`},word: "Release print",},
  {video: {url: `244326.mp4`},word: "Cutting copy",},
  {video: {url: `154559.mp4`},word: "Dolly",},
  {video: {url: `154553.mp4`},word: "Digital video",},
  {video: {url: `157952.mp4`},word: "Post-production",},
  {video: {url: `153737.mp4`},word: "Subtitle",},
  {video: {url: `154632.mp4`},word: "Footage",},
  {video: {url: `153241.mp4`},word: "Camera operator",},
  {video: {url: `244136.mp4`},word: "Capturing",},
  {video: {url: `155705.mp4`},word: "Video tape",},
  {video: {url: `153787.mp4`},word: "Vhs",},
  {video: {url: `155629.mp4`},word: "Stereo",},
  {video: {url: `122839.mp4`},word: "Audio",},
  {video: {url: `155541.mp4`},word: "Rewind",},
  {video: {url: `125861.mp4`},word: "Cd-player",},
  {video: {url: `116881.mp4`},word: "Siren",},
  {video: {url: `153342.mp4`},word: "Headset",},
  {video: {url: `116339.mp4`},word: "Pod radio",},
  {video: {url: `49265.mp4`},word: "Radio",},
  {video: {url: `62627.mp4`},word: "Boombox",},
  {video: {url: `153743.mp4`},word: "Tape-recorder",},
  {video: {url: `155611.mp4`},word: "Sound absorption",},
  {video: {url: `94095.mp4`},word: "Decibel",},
  {video: {url: `184758.mp4`},word: "Speaker",},
  {video: {url: `153285.mp4`},word: "Discman",},
  {video: {url: `77332.mp4`},word: "Muffler",},
  {video: {url: `155612.mp4`},word: "Sound effects",},
  {video: {url: `246008.mp4`},word: "Master copy",},
  {video: {url: `116338.mp4`},word: "Pod cast",},
  {video: {url: `96294.mp4`},word: "Earphones",},
  {video: {url: `244061.mp4`},word: "Audio rate",},
  {video: {url: `125841.mp4`},word: "Cassette",},
  {video: {url: `117032.mp4`},word: "Stereo",},
  {video: {url: `153588.mp4`},word: "I pod",},
  {video: {url: `109669.mp4`},word: "Cd",},
  {video: {url: `153726.mp4`},word: "Sound",},
  {video: {url: `153278.mp4`},word: "Digital",},
  {video: {url: `154702.mp4`},word: "In focus",},
  {video: {url: `157893.mp4`},word: "Out of focus",},
  {video: {url: `154641.mp4`},word: "Frame",},
  {video: {url: `154542.mp4`},word: "Deep focus",},
  {video: {url: `154532.mp4`},word: "Darkroom",},
  {video: {url: `154533.mp4`},word: "Darkslide",},
  {video: {url: `245289.mp4`},word: "Shutter",},
  {video: {url: `154606.mp4`},word: "Fine art photographer",},
  {video: {url: `91413.mp4`},word: "Focus",},
  {video: {url: `244462.mp4`},word: "F-number",},
  {video: {url: `244500.mp4`},word: "Film roll",},
  {video: {url: `153598.mp4`},word: "Jpeg",},
  {video: {url: `246032.mp4`},word: "Macro lens",},
  {video: {url: `153019.mp4`},word: "35mm",},
  {video: {url: `153614.mp4`},word: "Logo",},
  {video: {url: `153333.mp4`},word: "Graphic editor",},
  {video: {url: `124695.mp4`},word: "Bitmap",},
  {video: {url: `153317.mp4`},word: "Flash",},
  {video: {url: `244599.mp4`},word: "Graphic header",},
  {video: {url: `153305.mp4`},word: "Exposure",},
  {video: {url: `153281.mp4`},word: "Digital photo frame",},
  {video: {url: `153721.mp4`},word: "Slide show",},
  {video: {url: `244543.mp4`},word: "Framing",},
  {video: {url: `125719.mp4`},word: "Camcorder",},
  {video: {url: `125723.mp4`},word: "Camera lens",},
  {video: {url: `153758.mp4`},word: "Tripod",},
  {video: {url: `62870.mp4`},word: "Image",},
  {video: {url: `49188.mp4`},word: "Photograph",},
  {video: {url: `153672.mp4`},word: "Poster",},
  {video: {url: `155606.mp4`},word: "Snapshot",},
  {video: {url: `153723.mp4`},word: "Slr camera",},
  {video: {url: `153666.mp4`},word: "Photo",},
  {video: {url: `89542.mp4`},word: "Projection",},
  {video: {url: `154700.mp4`},word: "Image processing",},
  {video: {url: `157923.mp4`},word: "Photography",},
  {video: {url: `153279.mp4`},word: "Digital camera",},
  {video: {url: `154764.mp4`},word: "Machine print",},
  {video: {url: `244136.mp4`},word: "Capturing",},
  {video: {url: `154767.mp4`},word: "Magnification",},
  {video: {url: `51564.mp4`},word: "Camera",},
  {video: {url: `69428.mp4`},word: "Photoresistor",},
  {video: {url: `153097.mp4`},word: "Automatic camera",},
  {video: {url: `153095.mp4`},word: "Autofocus (af)",},
  {video: {url: `153351.mp4`},word: "High definition",},
  {video: {url: `96920.mp4`},word: "Overhead projector",},
  {video: {url: `153313.mp4`},word: "Film",},
  {video: {url: `154552.mp4`},word: "Digital image",},
  {video: {url: `154612.mp4`},word: "Fish-eye lens",},
  {video: {url: `153665.mp4`},word: "Photo retouching",},
  {video: {url: `157899.mp4`},word: "Panoramic",},
  {video: {url: `153667.mp4`},word: "Photoshop",},
  {video: {url: `153700.mp4`},word: "Resolution",},
  {video: {url: `155733.mp4`},word: "Zoom lens",},
  {video: {url: `245902.mp4`},word: "Offline edit",},
  {video: {url: `110180.mp4`},word: "Msn messenger",},
  {video: {url: `51551.mp4`},word: "Computer game",},
  {video: {url: `118553.mp4`},word: "Antivirus",},
  {video: {url: `153795.mp4`},word: "Word processing system",},
  {video: {url: `153333.mp4`},word: "Graphic editor",},
  {video: {url: `122518.mp4`},word: "Application software",},
  {video: {url: `186006.mp4`},word: "First person shooter",},
  {video: {url: `63628.mp4`},word: "Programmer",},
  {video: {url: `153100.mp4`},word: "Avid",},
  {video: {url: `246007.mp4`},word: "Mathematical software",},
  {video: {url: `153725.mp4`},word: "Software",},
  {video: {url: `96084.mp4`},word: "Computer program",},
  {video: {url: `153017.mp4`},word: "3d cad",},
  {video: {url: `116874.mp4`},word: "Sims",},
  {video: {url: `116901.mp4`},word: "Skype",},
  {video: {url: `118583.mp4`},word: "Widget",},
  {video: {url: `109894.mp4`},word: "Gadget",},
  {video: {url: `118592.mp4`},word: "World of warcraft",},
  {video: {url: `122517.mp4`},word: "Application",},
  {video: {url: `125659.mp4`},word: "Cad",},
  {video: {url: `153674.mp4`},word: "Powerpoint",},
  {video: {url: `153686.mp4`},word: "Quicktime player",},
  {video: {url: `153667.mp4`},word: "Photoshop",},
  {video: {url: `62861.mp4`},word: "Installation",},
  {video: {url: `245336.mp4`},word: "Screenreader",},
  {video: {url: `244250.mp4`},word: "Computer platform",},
  {video: {url: `153794.mp4`},word: "Wireless",},
  {video: {url: `153287.mp4`},word: "Download",},
  {video: {url: `153764.mp4`},word: "Upload",},
  {video: {url: `117127.mp4`},word: "Tag",},
  {video: {url: `117058.mp4`},word: "Stream",},
  {video: {url: `109463.mp4`},word: "3g",},
  {video: {url: `153594.mp4`},word: "Internet connection",},
  {video: {url: `153766.mp4`},word: "Url (uniform resorce locator)",},
  {video: {url: `123222.mp4`},word: "Bandwith",},
  {video: {url: `122862.mp4`},word: "Avatar",},
  {video: {url: `125556.mp4`},word: "Broadband",},
  {video: {url: `153596.mp4`},word: "Isp",},
  {video: {url: `58483.mp4`},word: "Ip address",},
  {video: {url: `153774.mp4`},word: "Web 2.0",},
  {video: {url: `125576.mp4`},word: "Browser",},
  {video: {url: `110177.mp4`},word: "Local area network",},
  {video: {url: `110170.mp4`},word: "Lan",},
  {video: {url: `154594.mp4`},word: "E-zine",},
  {video: {url: `116338.mp4`},word: "Pod cast",},
  {video: {url: `51859.mp4`},word: "Internet",},
  {video: {url: `153257.mp4`},word: "Connection speed",},
  {video: {url: `154586.mp4`},word: "E-publisher",},
  {video: {url: `153629.mp4`},word: "Modem",},
  {video: {url: `153269.mp4`},word: "Database",},
  {video: {url: `69419.mp4`},word: "Network",},
  {video: {url: `153773.mp4`},word: "Web",},
  {video: {url: `50782.mp4`},word: "Search, to",},
  {video: {url: `109513.mp4`},word: "Add",},
  {video: {url: `109537.mp4`},word: "Block",},
  {video: {url: `118560.mp4`},word: "Tweet",},
  {video: {url: `245934.mp4`},word: "Navigate",},
  {video: {url: `93207.mp4`},word: "Blog",},
  {video: {url: `153584.mp4`},word: "Html",},
  {video: {url: `117021.mp4`},word: "Status update",},
  {video: {url: `116942.mp4`},word: "Social network",},
  {video: {url: `109680.mp4`},word: "Communities",},
  {video: {url: `118122.mp4`},word: "Threads",},
  {video: {url: `153632.mp4`},word: "Msn",},
  {video: {url: `118561.mp4`},word: "Tweets",},
  {video: {url: `157880.mp4`},word: "Online edit",},
  {video: {url: `244599.mp4`},word: "Graphic header",},
  {video: {url: `123264.mp4`},word: "Banner",},
  {video: {url: `122824.mp4`},word: "Attachment",},
  {video: {url: `111166.mp4`},word: "Online games",},
  {video: {url: `116610.mp4`},word: "Rss",},
  {video: {url: `109884.mp4`},word: "Flickr",},
  {video: {url: `153332.mp4`},word: "Google",},
  {video: {url: `154721.mp4`},word: "Junk mail",},
  {video: {url: `155716.mp4`},word: "Web authoring",},
  {video: {url: `51905.mp4`},word: "E-mail",},
  {video: {url: `153098.mp4`},word: "Auto-responder",},
  {video: {url: `116405.mp4`},word: "Privacy settings",},
  {video: {url: `109538.mp4`},word: "Blogger",},
  {video: {url: `117019.mp4`},word: "Status",},
  {video: {url: `118585.mp4`},word: "Wikipedia",},
  {video: {url: `92982.mp4`},word: "Facebook",},
  {video: {url: `245124.mp4`},word: "The web",},
  {video: {url: `116940.mp4`},word: "Social medias",},
  {video: {url: `153776.mp4`},word: "Website",},
  {video: {url: `124736.mp4`},word: "Blog",},
  {video: {url: `153759.mp4`},word: "Twitter",},
  {video: {url: `154574.mp4`},word: "Email",},
  {video: {url: `153775.mp4`},word: "Web page",},
  {video: {url: `153076.mp4`},word: "@",},
  {video: {url: `157879.mp4`},word: "Online community",},
  {video: {url: `153299.mp4`},word: "E-mail address",},
  {video: {url: `125940.mp4`},word: "Chat room",},
  {video: {url: `153612.mp4`},word: "Link",},
  {video: {url: `153711.mp4`},word: "Search engine",},
  {video: {url: `154529.mp4`},word: "Cut and paste",},
  {video: {url: `153678.mp4`},word: "Print",},
  {video: {url: `158039.mp4`},word: "Reprint",},
  {video: {url: `153657.mp4`},word: "Paste",},
  {video: {url: `58384.mp4`},word: "Copy",},
  {video: {url: `92817.mp4`},word: "Xerox",},
  {video: {url: `154433.mp4`},word: "Copy edit",},
  {video: {url: `154536.mp4`},word: "Data entry",},
  {video: {url: `153604.mp4`},word: "Laser printer",},
  {video: {url: `153597.mp4`},word: "Italic",},
  {video: {url: `58383.mp4`},word: "Copier",},
  {video: {url: `96297.mp4`},word: "Edit",},
  {video: {url: `153753.mp4`},word: "Toner",},
  {video: {url: `244130.mp4`},word: "Bold",},
  {video: {url: `245898.mp4`},word: "Old face",},
  {video: {url: `153795.mp4`},word: "Word processing system",},
  {video: {url: `153319.mp4`},word: "Font",},
  {video: {url: `157847.mp4`},word: "Misprint",},
  {video: {url: `153653.mp4`},word: "Paper size",},
  {video: {url: `154663.mp4`},word: "Graphic communication",},
  {video: {url: `154673.mp4`},word: "Hard copy",},
  {video: {url: `48556.mp4`},word: "Letter",},
  {video: {url: `246061.mp4`},word: "Line spacing",},
  {video: {url: `153679.mp4`},word: "Printing paper",},
  {video: {url: `153592.mp4`},word: "Ink",},
  {video: {url: `63626.mp4`},word: "Printer",},
  {video: {url: `89532.mp4`},word: "Majuscule",},
  {video: {url: `65747.mp4`},word: "Letterpress",},
  {video: {url: `245053.mp4`},word: "Uppercase",},
  {video: {url: `125776.mp4`},word: "Caption",},
  {video: {url: `93312.mp4`},word: "Document",},
  {video: {url: `243965.mp4`},word: "Word document",},
  {video: {url: `154432.mp4`},word: "Copy",},
  {video: {url: `158041.mp4`},word: "Reprographics",},
  {video: {url: `153253.mp4`},word: "Color printing",},
  {video: {url: `153282.mp4`},word: "Digital printer",},
  {video: {url: `124697.mp4`},word: "Black and white printing",},
  {video: {url: `155726.mp4`},word: "Word processing",},
  {video: {url: `154742.mp4`},word: "Letter head",},
  {video: {url: `154743.mp4`},word: "Letterspace",},
  {video: {url: `125770.mp4`},word: "Capital",},
  {video: {url: `89503.mp4`},word: "Cartridge",},
  {video: {url: `154384.mp4`},word: "Caps",},
  {video: {url: `153294.mp4`},word: "Editing",},
  {video: {url: `116209.mp4`},word: "Paragraph",},
  {video: {url: `96971.mp4`},word: "Typewriter",},
  {video: {url: `243946.mp4`},word: "Jamaica",},
  {video: {url: `92687.mp4`},word: "Haiti",},
  {video: {url: `49002.mp4`},word: "North america",},
  {video: {url: `92875.mp4`},word: "Mexico",},
  {video: {url: `92638.mp4`},word: "Costa rica",},
  {video: {url: `125745.mp4`},word: "Canada",},
  {video: {url: `92645.mp4`},word: "Cuba",},
  {video: {url: `154642.mp4`},word: "Freedom of expression",},
  {video: {url: `154643.mp4`},word: "Freedom of the press",},
  {video: {url: `154644.mp4`},word: "Freedom of the speech",},
  {video: {url: `51168.mp4`},word: "Just",},
  {video: {url: `94259.mp4`},word: "Laid down",},
  {video: {url: `155545.mp4`},word: "Right",},
  {video: {url: `154745.mp4`},word: "Liable",},
  {video: {url: `155648.mp4`},word: "Sue",},
  {video: {url: `123219.mp4`},word: "Ban",},
  {video: {url: `96624.mp4`},word: "Insure",},
  {video: {url: `96348.mp4`},word: "Expire",},
  {video: {url: `89866.mp4`},word: "Obligate",},
  {video: {url: `51897.mp4`},word: "Negotiate",},
  {video: {url: `51422.mp4`},word: "Certify",},
  {video: {url: `94001.mp4`},word: "Prove",},
  {video: {url: `189047.mp4`},word: "Compulsory insurance",},
  {video: {url: `91417.mp4`},word: "Freedom",},
  {video: {url: `125899.mp4`},word: "Census",},
  {video: {url: `94263.mp4`},word: "Legislation",},
  {video: {url: `94167.mp4`},word: "Secrecy act",},
  {video: {url: `73035.mp4`},word: "Insurance",},
  {video: {url: `91386.mp4`},word: "Evidence",},
  {video: {url: `91728.mp4`},word: "Conflict",},
  {video: {url: `58320.mp4`},word: "Law",},
  {video: {url: `96132.mp4`},word: "Court of appeal",},
  {video: {url: `96644.mp4`},word: "Justice",},
  {video: {url: `91734.mp4`},word: "Court of law",},
  {video: {url: `51560.mp4`},word: "Authority",},
  {video: {url: `157979.mp4`},word: "Procedures",},
  {video: {url: `51721.mp4`},word: "Legal",},
  {video: {url: `122850.mp4`},word: "Authorization",},
  {video: {url: `244295.mp4`},word: "Cooperative agreement",},
  {video: {url: `93059.mp4`},word: "Freedom to roam",},
  {video: {url: `246073.mp4`},word: "Licensing",},
  {video: {url: `89581.mp4`},word: "Cancellation insurance",},
  {video: {url: `244888.mp4`},word: "Judge",},
  {video: {url: `116354.mp4`},word: "Policy",},
  {video: {url: `93142.mp4`},word: "Home insurance",},
  {video: {url: `51411.mp4`},word: "Check mark",},
  {video: {url: `94264.mp4`},word: "Legislative regulations",},
  {video: {url: `154740.mp4`},word: "Legal book",},
  {video: {url: `94281.mp4`},word: "Negotiation",},
  {video: {url: `116298.mp4`},word: "Picture id",},
  {video: {url: `96400.mp4`},word: "Fundamental",},
  {video: {url: `154699.mp4`},word: "Illegal contract",},
  {video: {url: `76803.mp4`},word: "License",},
  {video: {url: `244633.mp4`},word: "Human rights",},
  {video: {url: `94202.mp4`},word: "Certification",},
  {video: {url: `93333.mp4`},word: "Duty",},
  {video: {url: `189301.mp4`},word: "Human dignity",},
  {video: {url: `244540.mp4`},word: "Freedom of speech",},
  {video: {url: `89895.mp4`},word: "Permission",},
  {video: {url: `245129.mp4`},word: "Terms",},
  {video: {url: `189044.mp4`},word: "Compensation",},
  {video: {url: `244828.mp4`},word: "Rule",},
  {video: {url: `154563.mp4`},word: "Draft contract",},
  {video: {url: `96131.mp4`},word: "Court",},
  {video: {url: `94285.mp4`},word: "Norms and values",},
  {video: {url: `94284.mp4`},word: "Normalization",},
  {video: {url: `189534.mp4`},word: "Social policy",},
  {video: {url: `244194.mp4`},word: "Child labour",},
  {video: {url: `58541.mp4`},word: "Lawyer",},
  {video: {url: `94317.mp4`},word: "Public document",},
  {video: {url: `155554.mp4`},word: "Royalty rate",},
  {video: {url: `189190.mp4`},word: "European convention of human rights",},
  {video: {url: `155553.mp4`},word: "Royalty",},
  {video: {url: `245036.mp4`},word: "Void contract",},
  {video: {url: `50764.mp4`},word: "Right",},
  {video: {url: `157986.mp4`},word: "Public policy",},
  {video: {url: `155713.mp4`},word: "Warranty",},
  {video: {url: `91804.mp4`},word: "Legal protection",},
  {video: {url: `51705.mp4`},word: "Contract",},
  {video: {url: `153215.mp4`},word: "Building act 1984",},
  {video: {url: `244858.mp4`},word: "Petition",},
  {video: {url: `246074.mp4`},word: "Liability",},
  {video: {url: `49155.mp4`},word: "Obligation",},
  {video: {url: `96955.mp4`},word: "Norm",},
  {video: {url: `244887.mp4`},word: "Judgment",},
  {video: {url: `96642.mp4`},word: "Judgement",},
  {video: {url: `58421.mp4`},word: "Discrimination",},
  {video: {url: `245209.mp4`},word: "Stamp duty",},
  {video: {url: `245951.mp4`},word: "Move house",},
  {video: {url: `154564.mp4`},word: "Due",},
  {video: {url: `244772.mp4`},word: "Worthy",},
  {video: {url: `49208.mp4`},word: "Poor",},
  {video: {url: `50761.mp4`},word: "Rich",},
  {video: {url: `125560.mp4`},word: "Broke",},
  {video: {url: `89585.mp4`},word: "Economic",},
  {video: {url: `94053.mp4`},word: "Wealthy",},
  {video: {url: `90041.mp4`},word: "Thrifty",},
  {video: {url: `125941.mp4`},word: "Cheap",},
  {video: {url: `94002.mp4`},word: "Provide for",},
  {video: {url: `48554.mp4`},word: "Lend",},
  {video: {url: `51363.mp4`},word: "Beg",},
  {video: {url: `58534.mp4`},word: "Inherit",},
  {video: {url: `58547.mp4`},word: "Own",},
  {video: {url: `91553.mp4`},word: "Pay by cheque",},
  {video: {url: `91554.mp4`},word: "Pay by credit card",},
  {video: {url: `97006.mp4`},word: "Assess",},
  {video: {url: `158001.mp4`},word: "Raise money",},
  {video: {url: `89593.mp4`},word: "Pay off",},
  {video: {url: `91555.mp4`},word: "Pay in cash",},
  {video: {url: `158000.mp4`},word: "Raise finance",},
  {video: {url: `158032.mp4`},word: "Relocate",},
  {video: {url: `94062.mp4`},word: "Write off",},
  {video: {url: `96194.mp4`},word: "Depreciate",},
  {video: {url: `89805.mp4`},word: "Invest",},
  {video: {url: `58326.mp4`},word: "Provide",},
  {video: {url: `51730.mp4`},word: "Owe",},
  {video: {url: `93067.mp4`},word: "Gain",},
  {video: {url: `49179.mp4`},word: "Pay, to",},
  {video: {url: `51709.mp4`},word: "Guarantee",},
  {video: {url: `91828.mp4`},word: "State budget",},
  {video: {url: `51699.mp4`},word: "Fluctuation",},
  {video: {url: `51756.mp4`},word: "Settlement",},
  {video: {url: `51899.mp4`},word: "Gross",},
  {video: {url: `89598.mp4`},word: "Property",},
  {video: {url: `153079.mp4`},word: "Asset",},
  {video: {url: `51670.mp4`},word: "Debit",},
  {video: {url: `51714.mp4`},word: "Interest",},
  {video: {url: `154785.mp4`},word: "Market value",},
  {video: {url: `155607.mp4`},word: "Socio-economics",},
  {video: {url: `91716.mp4`},word: "Charity",},
  {video: {url: `51761.mp4`},word: "Value",},
  {video: {url: `123202.mp4`},word: "Balance",},
  {video: {url: `89589.mp4`},word: "Finance",},
  {video: {url: `93236.mp4`},word: "Coin",},
  {video: {url: `244915.mp4`},word: "Finances",},
  {video: {url: `51715.mp4`},word: "Interest rate",},
  {video: {url: `51707.mp4`},word: "Fund",},
  {video: {url: `153246.mp4`},word: "Capital gain tax",},
  {video: {url: `51773.mp4`},word: "Capital",},
  {video: {url: `92955.mp4`},word: "Nickel",},
  {video: {url: `51891.mp4`},word: "Stock exchange",},
  {video: {url: `51810.mp4`},word: "Worth",},
  {video: {url: `153034.mp4`},word: "Accounts",},
  {video: {url: `154605.mp4`},word: "Financial manager",},
  {video: {url: `245118.mp4`},word: "Think tank",},
  {video: {url: `94355.mp4`},word: "Student loan",},
  {video: {url: `92740.mp4`},word: "Service charge",},
  {video: {url: `244421.mp4`},word: "Economic recession",},
  {video: {url: `51886.mp4`},word: "Pay envelope",},
  {video: {url: `51728.mp4`},word: "Net",},
  {video: {url: `51765.mp4`},word: "Recession",},
  {video: {url: `89586.mp4`},word: "Economy",},
  {video: {url: `51777.mp4`},word: "Credit",},
  {video: {url: `51719.mp4`},word: "Invoice",},
  {video: {url: `51659.mp4`},word: "Advance payment",},
  {video: {url: `51665.mp4`},word: "Audit",},
  {video: {url: `245245.mp4`},word: "Social economy",},
  {video: {url: `51667.mp4`},word: "Creditor",},
  {video: {url: `153211.mp4`},word: "Break even point",},
  {video: {url: `154511.mp4`},word: "Cost valuation",},
  {video: {url: `48596.mp4`},word: "Money",},
  {video: {url: `89890.mp4`},word: "Owner",},
  {video: {url: `91805.mp4`},word: "Life insurance",},
  {video: {url: `93299.mp4`},word: "Dime",},
  {video: {url: `154613.mp4`},word: "Fixed costs",},
  {video: {url: `96129.mp4`},word: "Coupon",},
  {video: {url: `93320.mp4`},word: "Downgrade",},
  {video: {url: `116407.mp4`},word: "Private property",},
  {video: {url: `58415.mp4`},word: "Euro",},
  {video: {url: `51769.mp4`},word: "Minmum",},
  {video: {url: `73366.mp4`},word: "Resource depletion",},
  {video: {url: `51762.mp4`},word: "Vat (value-added tax)",},
  {video: {url: `51749.mp4`},word: "Saving",},
  {video: {url: `89571.mp4`},word: "Safe deposit box",},
  {video: {url: `91804.mp4`},word: "Legal protection",},
  {video: {url: `118187.mp4`},word: "Accounting",},
  {video: {url: `51680.mp4`},word: "Bankruptcy",},
  {video: {url: `153032.mp4`},word: "Account",},
  {video: {url: `245139.mp4`},word: "Taxation",},
  {video: {url: `245138.mp4`},word: "Taxpayer",},
  {video: {url: `123257.mp4`},word: "Bank",},
  {video: {url: `244921.mp4`},word: "Famine",},
  {video: {url: `58420.mp4`},word: "Dollar",},
  {video: {url: `92646.mp4`},word: "Currency",},
  {video: {url: `245140.mp4`},word: "Tax stamp",},
  {video: {url: `245141.mp4`},word: "Tax return",},
  {video: {url: `157983.mp4`},word: "Profit and loss statement",},
  {video: {url: `90125.mp4`},word: "Treasure",},
  {video: {url: `91446.mp4`},word: "Inheritance",},
  {video: {url: `94239.mp4`},word: "Home area system",},
  {video: {url: `91451.mp4`},word: "Landlord",},
  {video: {url: `94043.mp4`},word: "Tenant",},
  {video: {url: `89579.mp4`},word: "Bookkeeping",},
  {video: {url: `58494.mp4`},word: "Cost",},
  {video: {url: `51666.mp4`},word: "Credit agreement",},
  {video: {url: `51671.mp4`},word: "Debt",},
  {video: {url: `51676.mp4`},word: "Economics",},
  {video: {url: `245048.mp4`},word: "Variable cost",},
  {video: {url: `153681.mp4`},word: "Profit and loss accounts",},
  {video: {url: `51698.mp4`},word: "Cash flow",},
  {video: {url: `123260.mp4`},word: "Bank teller",},
  {video: {url: `94115.mp4`},word: "Financing",},
  {video: {url: `244077.mp4`},word: "Banking commissions",},
  {video: {url: `89583.mp4`},word: "Deposit",},
  {video: {url: `244078.mp4`},word: "Bank card",},
  {video: {url: `51901.mp4`},word: "Fixed asset",},
  {video: {url: `154671.mp4`},word: "Guaranteed amount",},
  {video: {url: `51896.mp4`},word: "Overdraft",},
  {video: {url: `51830.mp4`},word: "Cheque",},
  {video: {url: `92641.mp4`},word: "Credit card",},
  {video: {url: `122819.mp4`},word: "Atm",},
  {video: {url: `91732.mp4`},word: "Cost of living",},
  {video: {url: `123262.mp4`},word: "Banker",},
  {video: {url: `158038.mp4`},word: "Repossession",},
  {video: {url: `158037.mp4`},word: "Repayment",},
  {video: {url: `51673.mp4`},word: "Deflation",},
  {video: {url: `125900.mp4`},word: "Cent",},
  {video: {url: `90210.mp4`},word: "Wealth",},
  {video: {url: `154604.mp4`},word: "Financial aid",},
  {video: {url: `51744.mp4`},word: "Profit",},
  {video: {url: `89576.mp4`},word: "Balance sheet",},
  {video: {url: `90008.mp4`},word: "Territory",},
  {video: {url: `91817.mp4`},word: "Poverty",},
  {video: {url: `189047.mp4`},word: "Compulsory insurance",},
  {video: {url: `153103.mp4`},word: "Bad break",},
  {video: {url: `157949.mp4`},word: "Postage",},
  {video: {url: `158028.mp4`},word: "Rejection",},
  {video: {url: `154565.mp4`},word: "Earnings",},
  {video: {url: `189406.mp4`},word: "Minimum standard of living",},
  {video: {url: `125587.mp4`},word: "Budget",},
  {video: {url: `155675.mp4`},word: "Terms of payment",},
  {video: {url: `51672.mp4`},word: "Deficit",},
  {video: {url: `89605.mp4`},word: "Surplus",},
  {video: {url: `50855.mp4`},word: "Tax",},
  {video: {url: `125525.mp4`},word: "Breadwinner",},
  {video: {url: `189518.mp4`},word: "Subsistence minimum",},
  {video: {url: `51741.mp4`},word: "Percentage",},
  {video: {url: `125835.mp4`},word: "Cash",},
  {video: {url: `51722.mp4`},word: "Loan",},
  {video: {url: `185300.mp4`},word: "Tax avoidance",},
  {video: {url: `185301.mp4`},word: "Tax benefit",},
  {video: {url: `51912.mp4`},word: "Exchange office",},
  {video: {url: `51693.mp4`},word: "Financial advice",},
  {video: {url: `51675.mp4`},word: "Economic environment",},
  {video: {url: `51716.mp4`},word: "Investment",},
  {video: {url: `51688.mp4`},word: "Expenditure",},
  {video: {url: `51695.mp4`},word: "Financial report",},
  {video: {url: `89857.mp4`},word: "Mortgage",},
  {video: {url: `157877.mp4`},word: "Once and for all payment",},
  {video: {url: `153284.mp4`},word: "Direct debit",},
  {video: {url: `97091.mp4`},word: "Luxury",},
  {video: {url: `51712.mp4`},word: "Income",},
  {video: {url: `94034.mp4`},word: "Stamp",},
  {video: {url: `91731.mp4`},word: "Contribution",},
  {video: {url: `51890.mp4`},word: "Stock",},
  {video: {url: `51898.mp4`},word: "Inflation",},
  {video: {url: `157985.mp4`},word: "Property interest",},
  {video: {url: `51835.mp4`},word: "Expense",},
  {video: {url: `154437.mp4`},word: "Cost control",},
  {video: {url: `245781.mp4`},word: "Poverty line",},
  {video: {url: `154510.mp4`},word: "Cost estimate",},
  {video: {url: `244793.mp4`},word: "Tax collector",},
  {video: {url: `185292.mp4`},word: "Standard of living",},
  {video: {url: `122563.mp4`},word: "Arrears",},
  {video: {url: `90049.mp4`},word: "Congress is responsible for passing laws.",},
  {video: {url: `93447.mp4`},word: "I am a democrat.",},
  {video: {url: `93452.mp4`},word: "I am a republican.",},
  {video: {url: `116614.mp4`},word: "Run for office",},
  {video: {url: `93461.mp4`},word: "I am an independent.",},
  {video: {url: `51856.mp4`},word: "Independent",},
  {video: {url: `116356.mp4`},word: "Politic",},
  {video: {url: `191455.mp4`},word: "Presbyterian",},
  {video: {url: `51450.mp4`},word: "Civil",},
  {video: {url: `93003.mp4`},word: "Federal",},
  {video: {url: `96077.mp4`},word: "Communist",},
  {video: {url: `94224.mp4`},word: "Equivalent",},
  {video: {url: `116609.mp4`},word: "Royal",},
  {video: {url: `49218.mp4`},word: "Protest, to",},
  {video: {url: `153644.mp4`},word: "Oppress",},
  {video: {url: `116612.mp4`},word: "Rule",},
  {video: {url: `96426.mp4`},word: "Govern",},
  {video: {url: `118161.mp4`},word: "Abdicate",},
  {video: {url: `96898.mp4`},word: "Oppose",},
  {video: {url: `96650.mp4`},word: "Liberate",},
  {video: {url: `92961.mp4`},word: "Nominate",},
  {video: {url: `51893.mp4`},word: "Represent",},
  {video: {url: `93967.mp4`},word: "Mediate",},
  {video: {url: `93317.mp4`},word: "Dominate",},
  {video: {url: `51908.mp4`},word: "Emancipate",},
  {video: {url: `116487.mp4`},word: "Rebel",},
  {video: {url: `116548.mp4`},word: "Revolt",},
  {video: {url: `96304.mp4`},word: "Elect",},
  {video: {url: `96187.mp4`},word: "Democrat",},
  {video: {url: `93249.mp4`},word: "Congressman",},
  {video: {url: `93248.mp4`},word: "Congress",},
  {video: {url: `122856.mp4`},word: "Autonomy",},
  {video: {url: `91770.mp4`},word: "Govermental control",},
  {video: {url: `51775.mp4`},word: "Constitution",},
  {video: {url: `153303.mp4`},word: "Equivalence",},
  {video: {url: `90171.mp4`},word: "Vice president",},
  {video: {url: `92661.mp4`},word: "Embassy",},
  {video: {url: `245981.mp4`},word: "Middle class",},
  {video: {url: `94098.mp4`},word: "Deliberation (on a matter)",},
  {video: {url: `49202.mp4`},word: "Politics",},
  {video: {url: `94096.mp4`},word: "Decision-maker",},
  {video: {url: `153302.mp4`},word: "Equality between women and men",},
  {video: {url: `125752.mp4`},word: "Candidate",},
  {video: {url: `96067.mp4`},word: "Colony",},
  {video: {url: `189474.mp4`},word: "Self-goverment",},
  {video: {url: `58298.mp4`},word: "Equaly opportunity",},
  {video: {url: `244284.mp4`},word: "County",},
  {video: {url: `154539.mp4`},word: "Decision making",},
  {video: {url: `245030.mp4`},word: "Voting right",},
  {video: {url: `246045.mp4`},word: "Locality",},
  {video: {url: `92960.mp4`},word: "Noble",},
  {video: {url: `90183.mp4`},word: "Voting",},
  {video: {url: `93928.mp4`},word: "Demonstration",},
  {video: {url: `58296.mp4`},word: "Equality",},
  {video: {url: `244874.mp4`},word: "Mediator",},
  {video: {url: `244941.mp4`},word: "Conservative",},
  {video: {url: `58410.mp4`},word: "Foreign office",},
  {video: {url: `244410.mp4`},word: "Electoral system",},
  {video: {url: `93921.mp4`},word: "Debate",},
  {video: {url: `92651.mp4`},word: "Democracy",},
  {video: {url: `245968.mp4`},word: "Monarch",},
  {video: {url: `245794.mp4`},word: "Political program",},
  {video: {url: `51935.mp4`},word: "Inauguration",},
  {video: {url: `116401.mp4`},word: "Prince",},
  {video: {url: `116402.mp4`},word: "Princess",},
  {video: {url: `96224.mp4`},word: "Dictator",},
  {video: {url: `51751.mp4`},word: "Election",},
  {video: {url: `118429.mp4`},word: "Ambassador",},
  {video: {url: `185251.mp4`},word: "Regulations",},
  {video: {url: `154426.mp4`},word: "Consensus",},
  {video: {url: `245702.mp4`},word: "Rebellion",},
  {video: {url: `92848.mp4`},word: "Mayor",},
  {video: {url: `116824.mp4`},word: "Senate",},
  {video: {url: `116825.mp4`},word: "Senator",},
  {video: {url: `245409.mp4`},word: "Republic",},
  {video: {url: `47500.mp4`},word: "Country",},
  {video: {url: `51585.mp4`},word: "Independence",},
  {video: {url: `96127.mp4`},word: "Council",},
  {video: {url: `62542.mp4`},word: "Ballot",},
  {video: {url: `154597.mp4`},word: "Feminism",},
  {video: {url: `116299.mp4`},word: "Pie throwing",},
  {video: {url: `49165.mp4`},word: "Opinion",},
  {video: {url: `94213.mp4`},word: "County council",},
  {video: {url: `246046.mp4`},word: "Local government",},
  {video: {url: `246047.mp4`},word: "Local elections",},
  {video: {url: `96395.mp4`},word: "Forum",},
  {video: {url: `185923.mp4`},word: "Propaganda",},
  {video: {url: `185117.mp4`},word: "Minority",},
  {video: {url: `94252.mp4`},word: "Inequality",},
  {video: {url: `94279.mp4`},word: "National id number",},
  {video: {url: `244885.mp4`},word: "Kingdom",},
  {video: {url: `116232.mp4`},word: "Parliament",},
  {video: {url: `51687.mp4`},word: "Enemy",},
  {video: {url: `89812.mp4`},word: "Issue",},
  {video: {url: `96959.mp4`},word: "Solidarity",},
  {video: {url: `51917.mp4`},word: "Mediation",},
  {video: {url: `116357.mp4`},word: "Political science",},
  {video: {url: `91826.mp4`},word: "Society",},
  {video: {url: `93981.mp4`},word: "Oppression",},
  {video: {url: `245378.mp4`},word: "Right to vote",},
  {video: {url: `153242.mp4`},word: "Campaign",},
  {video: {url: `51900.mp4`},word: "Globalization",},
  {video: {url: `94232.mp4`},word: "Government supervision",},
  {video: {url: `65903.mp4`},word: "Prime minister",},
  {video: {url: `49261.mp4`},word: "Queen",},
  {video: {url: `48542.mp4`},word: "King",},
  {video: {url: `94272.mp4`},word: "Local environment",},
  {video: {url: `93096.mp4`},word: "Governor",},
  {video: {url: `153616.mp4`},word: "Look!",},
  {video: {url: `90078.mp4`},word: "Time is up",},
  {video: {url: `153104.mp4`},word: "Bad!",},
  {video: {url: `153329.mp4`},word: "Good!",},
  {video: {url: `58480.mp4`},word: "Sorry",},
  {video: {url: `89872.mp4`},word: "Oh!",},
  {video: {url: `153585.mp4`},word: "Hurrah!",},
  {video: {url: `96581.mp4`},word: "Huh",},
  {video: {url: `93988.mp4`},word: "Phew",},
  {video: {url: `153783.mp4`},word: "Whoops!",},
  {video: {url: `49162.mp4`},word: "Okay",},
  {video: {url: `89881.mp4`},word: "Ouch",},
  {video: {url: `92805.mp4`},word: "Wow",},
  {video: {url: `116846.mp4`},word: "Shh",},
  {video: {url: `153114.mp4`},word: "Bilingual",},
  {video: {url: `94142.mp4`},word: "Multilingual",},
  {video: {url: `50592.mp4`},word: "Although",},
  {video: {url: `93970.mp4`},word: "Name",},
  {video: {url: `50919.mp4`},word: "Translate",},
  {video: {url: `93180.mp4`},word: "Interpret",},
  {video: {url: `118160.mp4`},word: "Abbreviate",},
  {video: {url: `96931.mp4`},word: "Quote",},
  {video: {url: `50051.mp4`},word: "Alphabet",},
  {video: {url: `50841.mp4`},word: "Surname",},
  {video: {url: `244965.mp4`},word: "Yes-no question",},
  {video: {url: `94117.mp4`},word: "Foreign language",},
  {video: {url: `58255.mp4`},word: "Code",},
  {video: {url: `155724.mp4`},word: "Word formation",},
  {video: {url: `51869.mp4`},word: "Interpreter",},
  {video: {url: `157843.mp4`},word: "Metaphor",},
  {video: {url: `94277.mp4`},word: "Mother tongue",},
  {video: {url: `94260.mp4`},word: "Language impairment",},
  {video: {url: `245000.mp4`},word: "Wh-question",},
  {video: {url: `51799.mp4`},word: "Bilingualism",},
  {video: {url: `92683.mp4`},word: "Glossary",},
  {video: {url: `58424.mp4`},word: "Dictionary",},
  {video: {url: `158124.mp4`},word: "Juxtaposition",},
  {video: {url: `51082.mp4`},word: "word",},
  {video: {url: `153024.mp4`},word: "Abbreviation",},
  {video: {url: `154544.mp4`},word: "Definition",},
  {video: {url: `94276.mp4`},word: "Minority language",},
  {video: {url: `155575.mp4`},word: "Semantics",},
  {video: {url: `48562.mp4`},word: "Lip read",},
  {video: {url: `246048.mp4`},word: "Loanword",},
  {video: {url: `154753.mp4`},word: "Lingustics",},
  {video: {url: `50925.mp4`},word: "Vocabulary",},
  {video: {url: `51770.mp4`},word: "Language",},
  {video: {url: `93989.mp4`},word: "Phrase",},
  {video: {url: `244047.mp4`},word: "Analogy",},
  {video: {url: `51921.mp4`},word: "Language barrier",},
  {video: {url: `92696.mp4`},word: "Intepreter",},
  {video: {url: `58542.mp4`},word: "Linguistics",},
  {video: {url: `155682.mp4`},word: "Thesaurus",},
  {video: {url: `49262.mp4`},word: "Question",},
  {video: {url: `51280.mp4`},word: "Answer",},
  {video: {url: `118555.mp4`},word: "Antonym",},
  {video: {url: `48612.mp4`},word: "Name",},
  {video: {url: `49169.mp4`},word: "Outside",},
  {video: {url: `51078.mp4`},word: "With",},
  {video: {url: `243961.mp4`},word: "Above",},
  {video: {url: `91420.mp4`},word: "From",},
  {video: {url: `62188.mp4`},word: "Among",},
  {video: {url: `90030.mp4`},word: "Through",},
  {video: {url: `58533.mp4`},word: "In",},
  {video: {url: `90088.mp4`},word: "To",},
  {video: {url: `51079.mp4`},word: "Without",},
  {video: {url: `118366.mp4`},word: "Against",},
  {video: {url: `246118.mp4`},word: "Inside",},
  {video: {url: `96632.mp4`},word: "Into",},
  {video: {url: `124298.mp4`},word: "Beyond",},
  {video: {url: `62576.mp4`},word: "Beside",},
  {video: {url: `91368.mp4`},word: "At",},
  {video: {url: `89878.mp4`},word: "Onto",},
  {video: {url: `69326.mp4`},word: "Below",},
  {video: {url: `58572.mp4`},word: "Under",},
  {video: {url: `62579.mp4`},word: "Between",},
  {video: {url: `90107.mp4`},word: "Toward",},
  {video: {url: `110232.mp4`},word: "Moderate",},
  {video: {url: `90014.mp4`},word: "The other way round",},
  {video: {url: `92664.mp4`},word: "Extra",},
  {video: {url: `91751.mp4`},word: "Effective",},
  {video: {url: `93335.mp4`},word: "Each",},
  {video: {url: `92761.mp4`},word: "Typical",},
  {video: {url: `116400.mp4`},word: "Prime",},
  {video: {url: `96053.mp4`},word: "Central",},
  {video: {url: `110230.mp4`},word: "Mobile",},
  {video: {url: `244824.mp4`},word: "Saved",},
  {video: {url: `96102.mp4`},word: "Conserve",},
  {video: {url: `92751.mp4`},word: "Switch back",},
  {video: {url: `49180.mp4`},word: "Peace",},
  {video: {url: `96315.mp4`},word: "Engagement",},
  {video: {url: `49285.mp4`},word: "Accessibility",},
  {video: {url: `116203.mp4`},word: "Paper airplane",},
  {video: {url: `122850.mp4`},word: "Authorization",},
  {video: {url: `116200.mp4`},word: "Panel",},
  {video: {url: `96654.mp4`},word: "Necessity",},
  {video: {url: `93001.mp4`},word: "Favor",},
  {video: {url: `116956.mp4`},word: "Source",},
  {video: {url: `91767.mp4`},word: "Function",},
  {video: {url: `58307.mp4`},word: "Habit",},
  {video: {url: `191066.mp4`},word: "Pennant",},
  {video: {url: `246101.mp4`},word: "Joining",},
  {video: {url: `96643.mp4`},word: "Junction",},
  {video: {url: `96110.mp4`},word: "Content",},
  {video: {url: `185219.mp4`},word: "Penetration",},
  {video: {url: `118164.mp4`},word: "Ability",},
  {video: {url: `185229.mp4`},word: "Privilege",},
  {video: {url: `157919.mp4`},word: "Persuasion",},
  {video: {url: `154680.mp4`},word: "Health hazard",},
  {video: {url: `91384.mp4`},word: "Ethics",},
  {video: {url: `117037.mp4`},word: "Stimulus",},
  {video: {url: `93985.mp4`},word: "Participant",},
  {video: {url: `50599.mp4`},word: "Ashtray",},
  {video: {url: `51162.mp4`},word: "Truth",},
  {video: {url: `116813.mp4`},word: "Section",},
  {video: {url: `157904.mp4`},word: "Part",},
  {video: {url: `244944.mp4`},word: "Comforter",},
  {video: {url: `65760.mp4`},word: "Progression",},
  {video: {url: `93300.mp4`},word: "Dimple",},
  {video: {url: `244823.mp4`},word: "Seal",},
  {video: {url: `116535.mp4`},word: "Resource",},
  {video: {url: `93938.mp4`},word: "Discipline",},
  {video: {url: `157977.mp4`},word: "Problem-solving",},
  {video: {url: `92892.mp4`},word: "Moan",},
  {video: {url: `96948.mp4`},word: "Mixture",},
  {video: {url: `157978.mp4`},word: "Problem-sloving skills",},
  {video: {url: `51395.mp4`},word: "Category",},
  {video: {url: `116399.mp4`},word: "Primary",},
  {video: {url: `51159.mp4`},word: "Use",},
  {video: {url: `50920.mp4`},word: "Trouble",},
  {video: {url: `63567.mp4`},word: "Problem",},
  {video: {url: `244277.mp4`},word: "Consent",},
  {video: {url: `96925.mp4`},word: "Pit",},
  {video: {url: `50601.mp4`},word: "Bad luck",},
  {video: {url: `123164.mp4`},word: "Behavior",},
  {video: {url: `153055.mp4`},word: "Alternative",},
  {video: {url: `244469.mp4`},word: "Experimentator",},
  {video: {url: `244440.mp4`},word: "Exaggeration",},
  {video: {url: `93153.mp4`},word: "Humor",},
  {video: {url: `244472.mp4`},word: "Exclusion",},
  {video: {url: `244862.mp4`},word: "Offense",},
  {video: {url: `58538.mp4`},word: "Irony",},
  {video: {url: `244860.mp4`},word: "Overcomer",},
  {video: {url: `62895.mp4`},word: "Preparation",},
  {video: {url: `124707.mp4`},word: "Blame",},
  {video: {url: `116896.mp4`},word: "Skill",},
  {video: {url: `49212.mp4`},word: "Priority",},
  {video: {url: `92808.mp4`},word: "Wreck",},
  {video: {url: `189051.mp4`},word: "Conformism",},
  {video: {url: `91729.mp4`},word: "Confrontation",},
  {video: {url: `89949.mp4`},word: "Situation",},
  {video: {url: `188437.mp4`},word: "Strand",},
  {video: {url: `49270.mp4`},word: "Reception",},
  {video: {url: `155548.mp4`},word: "Risk assessment",},
  {video: {url: `92873.mp4`},word: "Mess",},
  {video: {url: `94104.mp4`},word: "Differentiation",},
  {video: {url: `116330.mp4`},word: "Pledge",},
  {video: {url: `51885.mp4`},word: "Chance",},
  {video: {url: `94193.mp4`},word: "Attendance",},
  {video: {url: `154595.mp4`},word: "Failure",},
  {video: {url: `90157.mp4`},word: "Users",},
  {video: {url: `158044.mp4`},word: "Reshaping",},
  {video: {url: `117015.mp4`},word: "State",},
  {video: {url: `117071.mp4`},word: "Stroke",},
  {video: {url: `47841.mp4`},word: "Good",},
  {video: {url: `117010.mp4`},word: "Standard",},
  {video: {url: `157872.mp4`},word: "Objective",},
  {video: {url: `90000.mp4`},word: "Temper",},
  {video: {url: `90019.mp4`},word: "Theme",},
  {video: {url: `93043.mp4`},word: "Fool",},
  {video: {url: `153067.mp4`},word: "Archetype",},
  {video: {url: `153079.mp4`},word: "Asset",},
  {video: {url: `116565.mp4`},word: "Rip",},
  {video: {url: `89892.mp4`},word: "Parachute",},
  {video: {url: `244074.mp4`},word: "Baseline",},
  {video: {url: `50768.mp4`},word: "Risk",},
  {video: {url: `244834.mp4`},word: "Revelation",},
  {video: {url: `244833.mp4`},word: "Revenge",},
  {video: {url: `91341.mp4`},word: "A simple task",},
  {video: {url: `93172.mp4`},word: "Inspection",},
  {video: {url: `51915.mp4`},word: "Gel",},
  {video: {url: `62899.mp4`},word: "Security",},
  {video: {url: `125777.mp4`},word: "Capture",},
  {video: {url: `244879.mp4`},word: "Loyalty",},
  {video: {url: `122796.mp4`},word: "Assurance",},
  {video: {url: `91331.mp4`},word: "Labyrinth",},
  {video: {url: `96503.mp4`},word: "Hassle",},
  {video: {url: `245928.mp4`},word: "Nice smell",},
  {video: {url: `92981.mp4`},word: "Face mask",},
  {video: {url: `90104.mp4`},word: "Touching",},
  {video: {url: `74940.mp4`},word: "Combination",},
  {video: {url: `89884.mp4`},word: "Outlet",},
  {video: {url: `246074.mp4`},word: "Liability",},
  {video: {url: `96142.mp4`},word: "Craze",},
  {video: {url: `93144.mp4`},word: "Honor",},
  {video: {url: `92834.mp4`},word: "Mainstream",},
  {video: {url: `245314.mp4`},word: "Sequencing",},
  {video: {url: `244845.mp4`},word: "Principle",},
  {video: {url: `245872.mp4`},word: "Originator",},
  {video: {url: `157896.mp4`},word: "Outcome",},
  {video: {url: `63613.mp4`},word: "Fault",},
  {video: {url: `49269.mp4`},word: "Reason",},
  {video: {url: `50899.mp4`},word: "Consequence",},
  {video: {url: `93178.mp4`},word: "Intellect",},
  {video: {url: `153704.mp4`},word: "Row",},
  {video: {url: `58477.mp4`},word: "Test",},
  {video: {url: `155677.mp4`},word: "Testing",},
  {video: {url: `58357.mp4`},word: "Report",},
  {video: {url: `58359.mp4`},word: "Responsibility",},
  {video: {url: `91680.mp4`},word: "Adaptation",},
  {video: {url: `63631.mp4`},word: "Warning",},
  {video: {url: `116208.mp4`},word: "Paradox",},
  {video: {url: `69331.mp4`},word: "Gloss",},
  {video: {url: `116819.mp4`},word: "Segment",},
  {video: {url: `92931.mp4`},word: "Nap",},
  {video: {url: `93927.mp4`},word: "Demand",},
  {video: {url: `125011.mp4`},word: "Borrowing",},
  {video: {url: `92925.mp4`},word: "Mystery",},
  {video: {url: `122829.mp4`},word: "Attention",},
  {video: {url: `93974.mp4`},word: "No chance",},
  {video: {url: `244276.mp4`},word: "Constant",},
  {video: {url: `245888.mp4`},word: "Open system",},
  {video: {url: `96069.mp4`},word: "Comfort",},
  {video: {url: `125857.mp4`},word: "Cause",},
  {video: {url: `125859.mp4`},word: "Caution",},
  {video: {url: `124316.mp4`},word: "Binoculars",},
  {video: {url: `116983.mp4`},word: "Split",},
  {video: {url: `94315.mp4`},word: "Prior knowledge",},
  {video: {url: `125915.mp4`},word: "Chain",},
  {video: {url: `93230.mp4`},word: "Chore",},
  {video: {url: `96281.mp4`},word: "Drift",},
  {video: {url: `91392.mp4`},word: "Experience",},
  {video: {url: `51573.mp4`},word: "Emergency",},
  {video: {url: `111122.mp4`},word: "Notch",},
  {video: {url: `92746.mp4`},word: "Specialty",},
  {video: {url: `111143.mp4`},word: "Notion",},
  {video: {url: `116511.mp4`},word: "Registration",},
  {video: {url: `90207.mp4`},word: "Way",},
  {video: {url: `154608.mp4`},word: "Fire risk",},
  {video: {url: `93910.mp4`},word: "Consideration",},
  {video: {url: `65870.mp4`},word: "Stage of development",},
  {video: {url: `244821.mp4`},word: "Separation",},
  {video: {url: `93232.mp4`},word: "Climax",},
  {video: {url: `246082.mp4`},word: "Layer",},
  {video: {url: `116882.mp4`},word: "Site",},
  {video: {url: `89814.mp4`},word: "Item",},
  {video: {url: `93131.mp4`},word: "Hero",},
  {video: {url: `51783.mp4`},word: "Movement",},
  {video: {url: `118424.mp4`},word: "Altitude",},
  {video: {url: `92814.mp4`},word: "Wrong way",},
  {video: {url: `92637.mp4`},word: "Correction",},
  {video: {url: `92642.mp4`},word: "Crisis",},
  {video: {url: `97091.mp4`},word: "Luxury",},
  {video: {url: `244924.mp4`},word: "Drunkenness",},
  {video: {url: `73332.mp4`},word: "Lawn",},
  {video: {url: `62648.mp4`},word: "Brat",},
  {video: {url: `91546.mp4`},word: "Parcel",},
  {video: {url: `123151.mp4`},word: "Badge",},
  {video: {url: `51948.mp4`},word: "Laugh",},
  {video: {url: `62539.mp4`},word: "Balancing act",},
  {video: {url: `94234.mp4`},word: "Grouping",},
  {video: {url: `91437.mp4`},word: "Home address",},
  {video: {url: `92887.mp4`},word: "Mischief",},
  {video: {url: `91379.mp4`},word: "Enlargement",},
  {video: {url: `92889.mp4`},word: "Mission",},
  {video: {url: `116340.mp4`},word: "Podium",},
  {video: {url: `63603.mp4`},word: "Contact",},
  {video: {url: `62855.mp4`},word: "Connection",},
  {video: {url: `245759.mp4`},word: "Problem-solving skills",},
  {video: {url: `157961.mp4`},word: "Precision",},
  {video: {url: `94150.mp4`},word: "Participation",},
  {video: {url: `92783.mp4`},word: "Mailbox",},
  {video: {url: `96242.mp4`},word: "Disk",},
  {video: {url: `77319.mp4`},word: "Rear",},
  {video: {url: `65767.mp4`},word: "Sequence",},
  {video: {url: `93958.mp4`},word: "Effect",},
  {video: {url: `154416.mp4`},word: "Cognition",},
  {video: {url: `89896.mp4`},word: "Philosophy",},
  {video: {url: `153120.mp4`},word: "Blur",},
  {video: {url: `116311.mp4`},word: "Pioneer",},
  {video: {url: `96419.mp4`},word: "Glory",},
  {video: {url: `244848.mp4`},word: "Presumption",},
  {video: {url: `92956.mp4`},word: "Nightmare",},
  {video: {url: `91406.mp4`},word: "Finishing",},
  {video: {url: `244844.mp4`},word: "Procession",},
  {video: {url: `244843.mp4`},word: "Proof",},
  {video: {url: `154590.mp4`},word: "Expectation",},
  {video: {url: `153712.mp4`},word: "Sector",},
  {video: {url: `90020.mp4`},word: "Theory",},
  {video: {url: `50887.mp4`},word: "Thing",},
  {video: {url: `154520.mp4`},word: "Cover",},
  {video: {url: `90034.mp4`},word: "Thought",},
  {video: {url: `48593.mp4`},word: "Mistake",},
  {video: {url: `65904.mp4`},word: "Quality",},
  {video: {url: `96357.mp4`},word: "Factor",},
  {video: {url: `93080.mp4`},word: "Gesture",},
  {video: {url: `93083.mp4`},word: "Gift",},
  {video: {url: `90190.mp4`},word: "Waiting room",},
  {video: {url: `189393.mp4`},word: "Maltreatment",},
  {video: {url: `58317.mp4`},word: "Influence",},
  {video: {url: `153027.mp4`},word: "Absence",},
  {video: {url: `96133.mp4`},word: "Courtesy",},
  {video: {url: `244872.mp4`},word: "Messenger",},
  {video: {url: `96398.mp4`},word: "Fragment",},
  {video: {url: `58526.mp4`},word: "Home",},
  {video: {url: `96932.mp4`},word: "Manner",},
  {video: {url: `157891.mp4`},word: "Origination",},
  {video: {url: `63621.mp4`},word: "Motion",},
  {video: {url: `157898.mp4`},word: "Package",},
  {video: {url: `244456.mp4`},word: "Ending",},
  {video: {url: `189466.mp4`},word: "Risk factors",},
  {video: {url: `92796.mp4`},word: "Wink",},
  {video: {url: `244413.mp4`},word: "Effectiveness",},
  {video: {url: `65757.mp4`},word: "Pattern",},
  {video: {url: `92878.mp4`},word: "Middle",},
  {video: {url: `158017.mp4`},word: "Recognition",},
  {video: {url: `110437.mp4`},word: "Motive",},
  {video: {url: `122826.mp4`},word: "Attempt",},
  {video: {url: `96431.mp4`},word: "Grasp",},
  {video: {url: `118351.mp4`},word: "Advent",},
  {video: {url: `89856.mp4`},word: "Message",},
  {video: {url: `244970.mp4`},word: "Wrapping",},
  {video: {url: `126006.mp4`},word: "Choice",},
  {video: {url: `116419.mp4`},word: "Progress",},
  {video: {url: `125901.mp4`},word: "Center",},
  {video: {url: `116345.mp4`},word: "Pointer",},
  {video: {url: `116343.mp4`},word: "Point of interest",},
  {video: {url: `154710.mp4`},word: "Interaction",},
  {video: {url: `94244.mp4`},word: "Inability",},
  {video: {url: `153306.mp4`},word: "Extent",},
  {video: {url: `92866.mp4`},word: "Memory",},
  {video: {url: `92864.mp4`},word: "Member",},
  {video: {url: `72311.mp4`},word: "Accident",},
  {video: {url: `96570.mp4`},word: "Homage",},
  {video: {url: `73026.mp4`},word: "Hazard",},
  {video: {url: `89952.mp4`},word: "Solution",},
  {video: {url: `93163.mp4`},word: "Incident",},
  {video: {url: `91484.mp4`},word: "Drop",},
  {video: {url: `48586.mp4`},word: "Meaning",},
  {video: {url: `51284.mp4`},word: "Analysis",},
  {video: {url: `93194.mp4`},word: "Assistance",},
  {video: {url: `244802.mp4`},word: "Submission",},
  {video: {url: `244892.mp4`},word: "Ingratitude",},
  {video: {url: `50900.mp4`},word: "Vengeance",},
  {video: {url: `245197.mp4`},word: "Stereotype",},
  {video: {url: `153638.mp4`},word: "Occurrence",},
  {video: {url: `93128.mp4`},word: "Heater",},
  {video: {url: `89734.mp4`},word: "Protection",},
  {video: {url: `116527.mp4`},word: "Reputation",},
  {video: {url: `51594.mp4`},word: "Order",},
  {video: {url: `51910.mp4`},word: "End",},
  {video: {url: `51589.mp4`},word: "Observation",},
  {video: {url: `154419.mp4`},word: "Common failure",},
  {video: {url: `244855.mp4`},word: "Praise",},
  {video: {url: `116476.mp4`},word: "Ramp",},
  {video: {url: `48474.mp4`},word: "Amazement",},
  {video: {url: `58268.mp4`},word: "Case",},
  {video: {url: `96930.mp4`},word: "Queue",},
  {video: {url: `124976.mp4`},word: "Boast",},
  {video: {url: `96900.mp4`},word: "Option",},
  {video: {url: `93265.mp4`},word: "Crowd",},
  {video: {url: `244779.mp4`},word: "Unity",},
  {video: {url: `116254.mp4`},word: "Peak",},
  {video: {url: `65911.mp4`},word: "Shadow",},
  {video: {url: `97007.mp4`},word: "Assembly",},
  {video: {url: `96116.mp4`},word: "Convention",},
  {video: {url: `94245.mp4`},word: "Inclusion",},
  {video: {url: `94374.mp4`},word: "Transition",},
  {video: {url: `153656.mp4`},word: "Password",},
  {video: {url: `116382.mp4`},word: "Powder",},
  {video: {url: `153655.mp4`},word: "Participator",},
  {video: {url: `75021.mp4`},word: "List",},
  {video: {url: `189725.mp4`},word: "Morale",},
  {video: {url: `154414.mp4`},word: "Coating",},
  {video: {url: `91409.mp4`},word: "First name",},
  {video: {url: `51929.mp4`},word: "Idiot",},
  {video: {url: `65933.mp4`},word: "Will",},
  {video: {url: `62694.mp4`},word: "Carton",},
  {video: {url: `110222.mp4`},word: "Mind",},
  {video: {url: `244849.mp4`},word: "Presence",},
  {video: {url: `96089.mp4`},word: "Conception",},
  {video: {url: `51423.mp4`},word: "Challenge",},
  {video: {url: `62598.mp4`},word: "Blindfold",},
  {video: {url: `154432.mp4`},word: "Copy",},
  {video: {url: `154434.mp4`},word: "Core",},
  {video: {url: `244908.mp4`},word: "Free will",},
  {video: {url: `51816.mp4`},word: "Group",},
  {video: {url: `244913.mp4`},word: "Flattery",},
  {video: {url: `96269.mp4`},word: "Dot",},
  {video: {url: `244911.mp4`},word: "Forgiveness",},
  {video: {url: `116613.mp4`},word: "Rumor",},
  {video: {url: `92996.mp4`},word: "Fancy",},
  {video: {url: `76946.mp4`},word: "Lubrication",},
  {video: {url: `153033.mp4`},word: "Accountability",},
  {video: {url: `158004.mp4`},word: "Rate",},
  {video: {url: `154686.mp4`},word: "Hierarchy",},
  {video: {url: `244866.mp4`},word: "Mockery",},
  {video: {url: `96301.mp4`},word: "Effort",},
  {video: {url: `244867.mp4`},word: "Mock",},
  {video: {url: `245991.mp4`},word: "Mentor",},
  {video: {url: `51608.mp4`},word: "Perception",},
  {video: {url: `116290.mp4`},word: "Photo album",},
  {video: {url: `116865.mp4`},word: "Side",},
  {video: {url: `91540.mp4`},word: "Packet",},
  {video: {url: `94029.mp4`},word: "Sorry",},
  {video: {url: `91364.mp4`},word: "Argument",},
  {video: {url: `51355.mp4`},word: "Luck",},
  {video: {url: `62631.mp4`},word: "Bottom",},
  {video: {url: `89865.mp4`},word: "Objection",},
  {video: {url: `94042.mp4`},word: "Temptation",},
  {video: {url: `92879.mp4`},word: "Might",},
  {video: {url: `244831.mp4`},word: "Rod",},
  {video: {url: `96326.mp4`},word: "Esteem",},
  {video: {url: `51895.mp4`},word: "Prestige",},
  {video: {url: `91351.mp4`},word: "Advantage",},
  {video: {url: `96082.mp4`},word: "Compliment",},
  {video: {url: `93244.mp4`},word: "Compromise",},
  {video: {url: `116449.mp4`},word: "Purpose",},
  {video: {url: `155537.mp4`},word: "Reversion",},
  {video: {url: `125613.mp4`},word: "Bundle",},
  {video: {url: `51195.mp4`},word: "Bunch",},
  {video: {url: `117000.mp4`},word: "Squirm",},
  {video: {url: `77330.mp4`},word: "Service",},
  {video: {url: `92965.mp4`},word: "Notification",},
  {video: {url: `185697.mp4`},word: "Perimeter",},
  {video: {url: `96196.mp4`},word: "Depth",},
  {video: {url: `157934.mp4`},word: "Planning",},
  {video: {url: `188172.mp4`},word: "Neutraliser",},
  {video: {url: `244007.mp4`},word: "Activty",},
  {video: {url: `244150.mp4`},word: "Cast",},
  {video: {url: `51623.mp4`},word: "Refreshment",},
  {video: {url: `96098.mp4`},word: "Confirmation",},
  {video: {url: `62898.mp4`},word: "Result",},
  {video: {url: `62538.mp4`},word: "Balancing",},
  {video: {url: `91818.mp4`},word: "Problem solving",},
  {video: {url: `58508.mp4`},word: "Gossip",},
  {video: {url: `122586.mp4`},word: "Asleep",},
  {video: {url: `118167.mp4`},word: "Able",},
  {video: {url: `122523.mp4`},word: "Approved",},
  {video: {url: `96091.mp4`},word: "Concrete",},
  {video: {url: `49176.mp4`},word: "Participate",},
  {video: {url: `153601.mp4`},word: "Keep an eye on",},
  {video: {url: `51109.mp4`},word: "Astonish",},
  {video: {url: `116790.mp4`},word: "Satisfy",},
  {video: {url: `116534.mp4`},word: "Resist",},
  {video: {url: `116794.mp4`},word: "Scare",},
  {video: {url: `116796.mp4`},word: "Scatter",},
  {video: {url: `96250.mp4`},word: "Distract",},
  {video: {url: `90170.mp4`},word: "Vibrate",},
  {video: {url: `96251.mp4`},word: "Diverge",},
  {video: {url: `93310.mp4`},word: "Disturb",},
  {video: {url: `96249.mp4`},word: "Distinguish",},
  {video: {url: `96248.mp4`},word: "Dissolve",},
  {video: {url: `96448.mp4`},word: "Guide",},
  {video: {url: `58515.mp4`},word: "Guess",},
  {video: {url: `96441.mp4`},word: "Grumble",},
  {video: {url: `50848.mp4`},word: "Cover, to",},
  {video: {url: `96452.mp4`},word: "Gull",},
  {video: {url: `116267.mp4`},word: "Penetrate",},
  {video: {url: `51164.mp4`},word: "Watch, to",},
  {video: {url: `116263.mp4`},word: "Peek",},
  {video: {url: `116269.mp4`},word: "Perceive",},
  {video: {url: `118411.mp4`},word: "Allow, to",},
  {video: {url: `96626.mp4`},word: "Intend",},
  {video: {url: `93179.mp4`},word: "Interfere",},
  {video: {url: `96109.mp4`},word: "Contain",},
  {video: {url: `96105.mp4`},word: "Constitute",},
  {video: {url: `116412.mp4`},word: "Procrastinate",},
  {video: {url: `116410.mp4`},word: "Proceed",},
  {video: {url: `58323.mp4`},word: "Pay attention",},
  {video: {url: `58267.mp4`},word: "Care for",},
  {video: {url: `117038.mp4`},word: "Stink",},
  {video: {url: `91743.mp4`},word: "Discharge (from hospital)",},
  {video: {url: `125551.mp4`},word: "Bring",},
  {video: {url: `116816.mp4`},word: "Seek",},
  {video: {url: `51861.mp4`},word: "Interrupt",},
  {video: {url: `125526.mp4`},word: "Break",},
  {video: {url: `116817.mp4`},word: "Seem",},
  {video: {url: `49219.mp4`},word: "Provoke",},
  {video: {url: `117124.mp4`},word: "Swipe",},
  {video: {url: `93283.mp4`},word: "Define",},
  {video: {url: `91410.mp4`},word: "Flee",},
  {video: {url: `48527.mp4`},word: "Insult",},
  {video: {url: `50782.mp4`},word: "Search, to",},
  {video: {url: `92699.mp4`},word: "Keep",},
  {video: {url: `91411.mp4`},word: "Flirt",},
  {video: {url: `94072.mp4`},word: "Apply for",},
  {video: {url: `58488.mp4`},word: "Communicate",},
  {video: {url: `96075.mp4`},word: "Commit",},
  {video: {url: `92895.mp4`},word: "Modify",},
  {video: {url: `58563.mp4`},word: "Top",},
  {video: {url: `97023.mp4`},word: "Cast",},
  {video: {url: `51519.mp4`},word: "Avail",},
  {video: {url: `96937.mp4`},word: "Matter",},
  {video: {url: `116974.mp4`},word: "Spend",},
  {video: {url: `63558.mp4`},word: "Pass, to",},
  {video: {url: `59630.mp4`},word: "Turn, to",},
  {video: {url: `72312.mp4`},word: "Adjust",},
  {video: {url: `116528.mp4`},word: "Request",},
  {video: {url: `153609.mp4`},word: "Leer",},
  {video: {url: `116603.mp4`},word: "Rotate",},
  {video: {url: `244925.mp4`},word: "Disobey",},
  {video: {url: `116503.mp4`},word: "Reduce speed",},
  {video: {url: `96275.mp4`},word: "Drag",},
  {video: {url: `51555.mp4`},word: "Cry",},
  {video: {url: `125671.mp4`},word: "Call for",},
  {video: {url: `51779.mp4`},word: "Crawl",},
  {video: {url: `122511.mp4`},word: "Applaud",},
  {video: {url: `51272.mp4`},word: "Appear",},
  {video: {url: `91475.mp4`},word: "Cut",},
  {video: {url: `116831.mp4`},word: "Settle",},
  {video: {url: `93074.mp4`},word: "Gather",},
  {video: {url: `89809.mp4`},word: "Involve",},
  {video: {url: `89806.mp4`},word: "Investigate",},
  {video: {url: `96123.mp4`},word: "Correlate",},
  {video: {url: `51616.mp4`},word: "Praise",},
  {video: {url: `123616.mp4`},word: "Bear",},
  {video: {url: `96548.mp4`},word: "Hesitate",},
  {video: {url: `92998.mp4`},word: "Fascinate",},
  {video: {url: `117056.mp4`},word: "Stray",},
  {video: {url: `96371.mp4`},word: "Figure out",},
  {video: {url: `92806.mp4`},word: "Wrap",},
  {video: {url: `96981.mp4`},word: "Vanish",},
  {video: {url: `96983.mp4`},word: "Vary",},
  {video: {url: `93280.mp4`},word: "Declare",},
  {video: {url: `58866.mp4`},word: "Blaze, to/ flame, to",},
  {video: {url: `124720.mp4`},word: "Blend",},
  {video: {url: `96208.mp4`},word: "Determine",},
  {video: {url: `89983.mp4`},word: "Tap",},
  {video: {url: `246029.mp4`},word: "Make a pitch",},
  {video: {url: `89977.mp4`},word: "Take advantage of",},
  {video: {url: `155623.mp4`},word: "Spot",},
  {video: {url: `58606.mp4`},word: "Detach",},
  {video: {url: `96201.mp4`},word: "Destruct",},
  {video: {url: `158016.mp4`},word: "Reckon",},
  {video: {url: `116238.mp4`},word: "Passing by in hallway",},
  {video: {url: `93251.mp4`},word: "Consider",},
  {video: {url: `97029.mp4`},word: "Forewarn",},
  {video: {url: `96103.mp4`},word: "Constipate",},
  {video: {url: `125528.mp4`},word: "Break a promise",},
  {video: {url: `246069.mp4`},word: "Lift",},
  {video: {url: `51726.mp4`},word: "Monitoring systems",},
  {video: {url: `91438.mp4`},word: "Honour",},
  {video: {url: `63549.mp4`},word: "Need",},
  {video: {url: `158042.mp4`},word: "Require",},
  {video: {url: `94225.mp4`},word: "Exempt",},
  {video: {url: `94240.mp4`},word: "Implement",},
  {video: {url: `117069.mp4`},word: "Strip",},
  {video: {url: `117009.mp4`},word: "Stamp",},
  {video: {url: `50934.mp4`},word: "Wake up, to",},
  {video: {url: `123607.mp4`},word: "Be allowed to",},
  {video: {url: `93050.mp4`},word: "Forgive",},
  {video: {url: `153628.mp4`},word: "Miss",},
  {video: {url: `91428.mp4`},word: "Get stuck",},
  {video: {url: `48611.mp4`},word: "Must",},
  {video: {url: `50886.mp4`},word: "Think",},
  {video: {url: `153061.mp4`},word: "Appreciate",},
  {video: {url: `97009.mp4`},word: "Assure",},
  {video: {url: `96617.mp4`},word: "Inject",},
  {video: {url: `58507.mp4`},word: "Go",},
  {video: {url: `93171.mp4`},word: "Insist",},
  {video: {url: `49277.mp4`},word: "Remember",},
  {video: {url: `96620.mp4`},word: "Inspect",},
  {video: {url: `62501.mp4`},word: "Associate",},
  {video: {url: `62500.mp4`},word: "Assist",},
  {video: {url: `93290.mp4`},word: "Deny",},
  {video: {url: `96312.mp4`},word: "Emerge",},
  {video: {url: `123142.mp4`},word: "Backslide",},
  {video: {url: `116541.mp4`},word: "Retard",},
  {video: {url: `51353.mp4`},word: "Become",},
  {video: {url: `93040.mp4`},word: "Fold",},
  {video: {url: `90195.mp4`},word: "Wander",},
  {video: {url: `51851.mp4`},word: "Give back",},
  {video: {url: `50859.mp4`},word: "Sit, to",},
  {video: {url: `116274.mp4`},word: "Permit",},
  {video: {url: `118163.mp4`},word: "Abide",},
  {video: {url: `246105.mp4`},word: "Jeopardize",},
  {video: {url: `245868.mp4`},word: "Outweigh",},
  {video: {url: `116544.mp4`},word: "Retreat",},
  {video: {url: `116547.mp4`},word: "Revise",},
  {video: {url: `116546.mp4`},word: "Revenge",},
  {video: {url: `116549.mp4`},word: "Revolve",},
  {video: {url: `125748.mp4`},word: "Cancel",},
  {video: {url: `48566.mp4`},word: "Lose",},
  {video: {url: `125921.mp4`},word: "Change",},
  {video: {url: `50822.mp4`},word: "Stay",},
  {video: {url: `94116.mp4`},word: "Follow up",},
  {video: {url: `94039.mp4`},word: "Suit",},
  {video: {url: `153593.mp4`},word: "Insert",},
  {video: {url: `91377.mp4`},word: "Blow",},
  {video: {url: `116369.mp4`},word: "Populate",},
  {video: {url: `116306.mp4`},word: "Pinch",},
  {video: {url: `117112.mp4`},word: "Suspend",},
  {video: {url: `117107.mp4`},word: "Surround",},
  {video: {url: `117052.mp4`},word: "Straddle",},
  {video: {url: `50785.mp4`},word: "Send",},
  {video: {url: `116955.mp4`},word: "Sort",},
  {video: {url: `116820.mp4`},word: "Select",},
  {video: {url: `96226.mp4`},word: "Differ",},
  {video: {url: `96225.mp4`},word: "Did",},
  {video: {url: `92968.mp4`},word: "Except",},
  {video: {url: `65762.mp4`},word: "Roll",},
  {video: {url: `96344.mp4`},word: "Exclude",},
  {video: {url: `92927.mp4`},word: "Nag",},
  {video: {url: `244836.mp4`},word: "Reject",},
  {video: {url: `93946.mp4`},word: "Distribute",},
  {video: {url: `91309.mp4`},word: "Take a chance",},
  {video: {url: `91294.mp4`},word: "Follow",},
  {video: {url: `93939.mp4`},word: "Discriminate",},
  {video: {url: `93239.mp4`},word: "Combine",},
  {video: {url: `93982.mp4`},word: "Overlook",},
  {video: {url: `96438.mp4`},word: "Grip",},
  {video: {url: `51406.mp4`},word: "Cease",},
  {video: {url: `96437.mp4`},word: "Grin",},
  {video: {url: `116301.mp4`},word: "Pile",},
  {video: {url: `116979.mp4`},word: "Spit-expectorate",},
  {video: {url: `124314.mp4`},word: "Bind",},
  {video: {url: `96214.mp4`},word: "Devise",},
  {video: {url: `96215.mp4`},word: "Devote",},
  {video: {url: `116507.mp4`},word: "Reflect",},
  {video: {url: `116508.mp4`},word: "Refuse",},
  {video: {url: `93295.mp4`},word: "Develop",},
  {video: {url: `91348.mp4`},word: "Activate",},
  {video: {url: `49189.mp4`},word: "Pick up",},
  {video: {url: `90004.mp4`},word: "Tend",},
  {video: {url: `91344.mp4`},word: "Accomplish",},
  {video: {url: `51641.mp4`},word: "Slide",},
  {video: {url: `51636.mp4`},word: "Show",},
  {video: {url: `93960.mp4`},word: "Emphasize",},
  {video: {url: `153110.mp4`},word: "Be located in",},
  {video: {url: `117086.mp4`},word: "Summon",},
  {video: {url: `244907.mp4`},word: "Fulfill",},
  {video: {url: `96306.mp4`},word: "Elevate",},
  {video: {url: `116848.mp4`},word: "Shine",},
  {video: {url: `116521.mp4`},word: "Remind",},
  {video: {url: `116520.mp4`},word: "Remain",},
  {video: {url: `116517.mp4`},word: "Release",},
  {video: {url: `116519.mp4`},word: "Rely",},
  {video: {url: `116518.mp4`},word: "Relieve",},
  {video: {url: `116515.mp4`},word: "Relate",},
  {video: {url: `116514.mp4`},word: "Reinforce",},
  {video: {url: `118172.mp4`},word: "Abstain",},
  {video: {url: `51505.mp4`},word: "Behave",},
  {video: {url: `89874.mp4`},word: "Omit",},
  {video: {url: `51877.mp4`},word: "Cross",},
  {video: {url: `118356.mp4`},word: "Advice",},
  {video: {url: `50945.mp4`},word: "Warn, to",},
  {video: {url: `92891.mp4`},word: "Mix",},
  {video: {url: `96099.mp4`},word: "Confront",},
  {video: {url: `93931.mp4`},word: "Destroy",},
  {video: {url: `93246.mp4`},word: "Confuse",},
  {video: {url: `92890.mp4`},word: "Misunderstand",},
  {video: {url: `91378.mp4`},word: "Enlarge",},
  {video: {url: `91308.mp4`},word: "Oversleep oneself",},
  {video: {url: `92861.mp4`},word: "Meditate",},
  {video: {url: `116506.mp4`},word: "Refer",},
  {video: {url: `116344.mp4`},word: "Point",},
  {video: {url: `62857.mp4`},word: "Cut off",},
  {video: {url: `116396.mp4`},word: "Pretend",},
  {video: {url: `122596.mp4`},word: "Assemble",},
  {video: {url: `62580.mp4`},word: "Beware",},
  {video: {url: `116389.mp4`},word: "Predict",},
  {video: {url: `116395.mp4`},word: "Preserve",},
  {video: {url: `118370.mp4`},word: "Agree",},
  {video: {url: `50812.mp4`},word: "Sleep",},
  {video: {url: `63577.mp4`},word: "Smile",},
  {video: {url: `51161.mp4`},word: "Try",},
  {video: {url: `116828.mp4`},word: "Separate",},
  {video: {url: `116830.mp4`},word: "Set",},
  {video: {url: `58552.mp4`},word: "Tease",},
  {video: {url: `187689.mp4`},word: "Crease",},
  {video: {url: `97110.mp4`},word: "Magnify",},
  {video: {url: `51264.mp4`},word: "Approach",},
  {video: {url: `51262.mp4`},word: "Approve",},
  {video: {url: `153053.mp4`},word: "Alert",},
  {video: {url: `97001.mp4`},word: "Arise",},
  {video: {url: `96614.mp4`},word: "Inflate",},
  {video: {url: `63614.mp4`},word: "Fix",},
  {video: {url: `91394.mp4`},word: "Expose",},
  {video: {url: `94018.mp4`},word: "See through",},
  {video: {url: `125937.mp4`},word: "Chase",},
  {video: {url: `65917.mp4`},word: "Slap",},
  {video: {url: `96323.mp4`},word: "Equip",},
  {video: {url: `116924.mp4`},word: "Snip",},
  {video: {url: `116562.mp4`},word: "Ring",},
  {video: {url: `116480.mp4`},word: "Rattle",},
  {video: {url: `116482.mp4`},word: "Reach",},
  {video: {url: `93182.mp4`},word: "Adapt",},
  {video: {url: `118183.mp4`},word: "Acclaim",},
  {video: {url: `93188.mp4`},word: "Affect",},
  {video: {url: `48522.mp4`},word: "Ignore",},
  {video: {url: `96494.mp4`},word: "Hang",},
  {video: {url: `116921.mp4`},word: "Sneak",},
  {video: {url: `93181.mp4`},word: "Accept",},
  {video: {url: `116923.mp4`},word: "Sniff",},
  {video: {url: `153622.mp4`},word: "Mean",},
  {video: {url: `92991.mp4`},word: "Fall",},
  {video: {url: `74953.mp4`},word: "Crumble",},
  {video: {url: `90191.mp4`},word: "Wake",},
  {video: {url: `96384.mp4`},word: "Flex",},
  {video: {url: `122603.mp4`},word: "Assume",},
  {video: {url: `96093.mp4`},word: "Conduct",},
  {video: {url: `50914.mp4`},word: "Touch, to",},
  {video: {url: `116868.mp4`},word: "Sigh",},
  {video: {url: `96561.mp4`},word: "Hold",},
  {video: {url: `116276.mp4`},word: "Persuade",},
  {video: {url: `157890.mp4`},word: "Originate",},
  {video: {url: `116554.mp4`},word: "Rid",},
  {video: {url: `126010.mp4`},word: "Choose",},
  {video: {url: `122583.mp4`},word: "Ask",},
  {video: {url: `50758.mp4`},word: "Return",},
  {video: {url: `91299.mp4`},word: "Go in",},
  {video: {url: `63592.mp4`},word: "Take",},
  {video: {url: `91300.mp4`},word: "Go out",},
  {video: {url: `94020.mp4`},word: "Should",},
  {video: {url: `63622.mp4`},word: "Move",},
  {video: {url: `96657.mp4`},word: "Occupy",},
  {video: {url: `96658.mp4`},word: "Occur",},
  {video: {url: `51748.mp4`},word: "Find, to",},
  {video: {url: `89867.mp4`},word: "Observe",},
  {video: {url: `117101.mp4`},word: "Suppress",},
  {video: {url: `49197.mp4`},word: "Plan",},
  {video: {url: `51167.mp4`},word: "Leave",},
  {video: {url: `116799.mp4`},word: "Scoop",},
  {video: {url: `58613.mp4`},word: "Fill",},
  {video: {url: `116804.mp4`},word: "Scratch",},
  {video: {url: `92969.mp4`},word: "Excuse",},
  {video: {url: `122547.mp4`},word: "Argue",},
  {video: {url: `118186.mp4`},word: "Accompany",},
  {video: {url: `51894.mp4`},word: "Do",},
  {video: {url: `51654.mp4`},word: "Trip",},
  {video: {url: `244838.mp4`},word: "Receive",},
  {video: {url: `122827.mp4`},word: "Attend",},
  {video: {url: `91375.mp4`},word: "Be called",},
  {video: {url: `96596.mp4`},word: "Identify",},
  {video: {url: `96087.mp4`},word: "Concentrate",},
  {video: {url: `51302.mp4`},word: "Attach",},
  {video: {url: `116347.mp4`},word: "Poke",},
  {video: {url: `91390.mp4`},word: "Exist",},
  {video: {url: `91382.mp4`},word: "Establish",},
  {video: {url: `92865.mp4`},word: "Memorize",},
  {video: {url: `50877.mp4`},word: "Seduce",},
  {video: {url: `77338.mp4`},word: "Start",},
  {video: {url: `116851.mp4`},word: "Shiver",},
  {video: {url: `116275.mp4`},word: "Persist",},
  {video: {url: `96961.mp4`},word: "Strive",},
  {video: {url: `116277.mp4`},word: "Pervert",},
  {video: {url: `116586.mp4`},word: "Roam",},
  {video: {url: `96207.mp4`},word: "Deteriorate",},
  {video: {url: `96204.mp4`},word: "Detect",},
  {video: {url: `96203.mp4`},word: "Detain",},
  {video: {url: `243988.mp4`},word: "Absorb",},
  {video: {url: `96200.mp4`},word: "Destine",},
  {video: {url: `93166.mp4`},word: "Indicate",},
  {video: {url: `90002.mp4`},word: "Tempt",},
  {video: {url: `91435.mp4`},word: "Have time",},
  {video: {url: `154516.mp4`},word: "Couple",},
  {video: {url: `93164.mp4`},word: "Include",},
  {video: {url: `74977.mp4`},word: "Dry up",},
  {video: {url: `51288.mp4`},word: "Analyze",},
  {video: {url: `51768.mp4`},word: "Take care of",},
  {video: {url: `93997.mp4`},word: "Prevent",},
  {video: {url: `125741.mp4`},word: "Can",},
  {video: {url: `91434.mp4`},word: "Happen",},
  {video: {url: `90118.mp4`},word: "Transfer",},
  {video: {url: `96260.mp4`},word: "Dodge",},
  {video: {url: `91297.mp4`},word: "Freshen up",},
  {video: {url: `116525.mp4`},word: "Report",},
  {video: {url: `51591.mp4`},word: "Offer",},
  {video: {url: `50048.mp4`},word: "Acknowledge",},
  {video: {url: `93973.mp4`},word: "Neglect",},
  {video: {url: `51944.mp4`},word: "Help",},
  {video: {url: `125272.mp4`},word: "Brag",},
  {video: {url: `62606.mp4`},word: "Blow out",},
  {video: {url: `58592.mp4`},word: "Coat",},
  {video: {url: `155686.mp4`},word: "Touch up",},
  {video: {url: `153683.mp4`},word: "Propose",},
  {video: {url: `91298.mp4`},word: "Get rid of",},
  {video: {url: `59550.mp4`},word: "Prepare",},
  {video: {url: `94026.mp4`},word: "Slip",},
  {video: {url: `96112.mp4`},word: "Contribute",},
  {video: {url: `96114.mp4`},word: "Convene",},
  {video: {url: `116997.mp4`},word: "Sprinkle",},
  {video: {url: `58471.mp4`},word: "Protect",},
  {video: {url: `62573.mp4`},word: "Belong",},
  {video: {url: `50790.mp4`},word: "Share, to",},
  {video: {url: `116381.mp4`},word: "Pout",},
  {video: {url: `50816.mp4`},word: "Solve",},
  {video: {url: `116838.mp4`},word: "Shake",},
  {video: {url: `117084.mp4`},word: "Suggest",},
  {video: {url: `49216.mp4`},word: "Promise",},
  {video: {url: `93306.mp4`},word: "Disappoint",},
  {video: {url: `96234.mp4`},word: "Discourage",},
  {video: {url: `116486.mp4`},word: "Realize",},
  {video: {url: `154629.mp4`},word: "Focus",},
  {video: {url: `244009.mp4`},word: "Acquire",},
  {video: {url: `62693.mp4`},word: "Carry",},
  {video: {url: `123103.mp4`},word: "Avoid",},
  {video: {url: `123104.mp4`},word: "Awake",},
  {video: {url: `153762.mp4`},word: "Unwrap, to",},
  {video: {url: `118422.mp4`},word: "Alternate, to",},
  {video: {url: `97000.mp4`},word: "Apprehend",},
  {video: {url: `122584.mp4`},word: "Ask for",},
  {video: {url: `51122.mp4`},word: "Blink",},
  {video: {url: `96320.mp4`},word: "Equate",},
  {video: {url: `49009.mp4`},word: "Obey",},
  {video: {url: `116493.mp4`},word: "Recognize",},
  {video: {url: `116494.mp4`},word: "Recommend",},
  {video: {url: `122508.mp4`},word: "Apologize",},
  {video: {url: `117100.mp4`},word: "Suppose",},
  {video: {url: `74956.mp4`},word: "Crush",},
  {video: {url: `96970.mp4`},word: "Tumble",},
  {video: {url: `116612.mp4`},word: "Rule",},
  {video: {url: `96409.mp4`},word: "Generate",},
  {video: {url: `58491.mp4`},word: "Continue",},
  {video: {url: `58489.mp4`},word: "Complicate",},
  {video: {url: `58500.mp4`},word: "Demonstrate",},
  {video: {url: `93305.mp4`},word: "Disappear",},
  {video: {url: `244426.mp4`},word: "Empower",},
  {video: {url: `58536.mp4`},word: "Introduce",},
  {video: {url: `153245.mp4`},word: "Can\u2019t",},
  {video: {url: `244606.mp4`},word: "Haven\u2019t got",},
  {video: {url: `154561.mp4`},word: "Don\u2019t know",},
  {video: {url: `154562.mp4`},word: "Don\u2019t understand",},
  {video: {url: `51865.mp4`},word: "Judge, to",},
  {video: {url: `96193.mp4`},word: "Deplete",},
  {video: {url: `50828.mp4`},word: "Struggle",},
  {video: {url: `50767.mp4`},word: "Rise, to",},
  {video: {url: `116864.mp4`},word: "Shrink",},
  {video: {url: `51920.mp4`},word: "Give, to",},
  {video: {url: `92804.mp4`},word: "Would",},
  {video: {url: `92800.mp4`},word: "Withdraw",},
  {video: {url: `50826.mp4`},word: "Stop, to",},
  {video: {url: `62637.mp4`},word: "Bow",},
  {video: {url: `62635.mp4`},word: "Bounce",},
  {video: {url: `125010.mp4`},word: "Borrow",},
  {video: {url: `125016.mp4`},word: "Bother",},
  {video: {url: `63595.mp4`},word: "To pick up",},
  {video: {url: `50874.mp4`},word: "To meet",},
  {video: {url: `96487.mp4`},word: "Halt",},
  {video: {url: `59581.mp4`},word: "Smell, to",},
  {video: {url: `91391.mp4`},word: "Expect",},
  {video: {url: `93953.mp4`},word: "Drool",},
  {video: {url: `96330.mp4`},word: "Evade",},
  {video: {url: `96086.mp4`},word: "Conceal",},
  {video: {url: `116883.mp4`},word: "Situate",},
  {video: {url: `92973.mp4`},word: "Explore",},
  {video: {url: `94044.mp4`},word: "Think of",},
  {video: {url: `91424.mp4`},word: "Fuss",},
  {video: {url: `93243.mp4`},word: "Comprehend",},
  {video: {url: `116453.mp4`},word: "Push",},
  {video: {url: `92793.mp4`},word: "Will not",},
  {video: {url: `116454.mp4`},word: "Put",},
  {video: {url: `50875.mp4`},word: "To get",},
  {video: {url: `116999.mp4`},word: "Squeeze",},
  {video: {url: `62578.mp4`},word: "Betray",},
  {video: {url: `117002.mp4`},word: "Stack",},
  {video: {url: `63552.mp4`},word: "Oblige",},
  {video: {url: `51626.mp4`},word: "Rest",},
  {video: {url: `96183.mp4`},word: "Defy",},
  {video: {url: `96182.mp4`},word: "Deflate",},
  {video: {url: `91293.mp4`},word: "Fall for",},
  {video: {url: `48558.mp4`},word: "Lie",},
  {video: {url: `47504.mp4`},word: "Criticize",},
  {video: {url: `125943.mp4`},word: "Check",},
  {video: {url: `96198.mp4`},word: "Designate",},
  {video: {url: `58532.mp4`},word: "Improve",},
  {video: {url: `96606.mp4`},word: "Impress",},
  {video: {url: `63547.mp4`},word: "Look for",},
  {video: {url: `125759.mp4`},word: "Cannot",},
  {video: {url: `51625.mp4`},word: "Rescue",},
  {video: {url: `244794.mp4`},word: "Take away",},
  {video: {url: `116302.mp4`},word: "Pillow throw",},
  {video: {url: `116436.mp4`},word: "Puff",},
  {video: {url: `116920.mp4`},word: "Snappin fingers",},
  {video: {url: `116411.mp4`},word: "Processing",},
  {video: {url: `62696.mp4`},word: "Catch",},
  {video: {url: `50889.mp4`},word: "There",},
  {video: {url: `153297.mp4`},word: "Either",},
  {video: {url: `93995.mp4`},word: "Preferably",},
  {video: {url: `48553.mp4`},word: "Left",},
  {video: {url: `96271.mp4`},word: "Down",},
  {video: {url: `58549.mp4`},word: "Slowly",},
  {video: {url: `62282.mp4`},word: "Around",},
  {video: {url: `91442.mp4`},word: "However",},
  {video: {url: `90021.mp4`},word: "Therefore",},
  {video: {url: `48584.mp4`},word: "Maybe",},
  {video: {url: `89882.mp4`},word: "Out",},
  {video: {url: `157865.mp4`},word: "Not allowed",},
  {video: {url: `93986.mp4`},word: "Past",},
  {video: {url: `51067.mp4`},word: "Where",},
  {video: {url: `91422.mp4`},word: "Fully",},
  {video: {url: `63624.mp4`},word: "Off",},
  {video: {url: `63597.mp4`},word: "Together",},
  {video: {url: `50050.mp4`},word: "Almost",},
  {video: {url: `96608.mp4`},word: "Indeed",},
  {video: {url: `122506.mp4`},word: "Apart",},
  {video: {url: `51136.mp4`},word: "Too",},
  {video: {url: `91307.mp4`},word: "Once more",},
  {video: {url: `51068.mp4`},word: "Why",},
  {video: {url: `123153.mp4`},word: "Badly",},
  {video: {url: `48823.mp4`},word: "No",},
  {video: {url: `244535.mp4`},word: "From time to time",},
  {video: {url: `69324.mp4`},word: "Above",},
  {video: {url: `93175.mp4`},word: "Instead",},
  {video: {url: `94056.mp4`},word: "Willingly",},
  {video: {url: `153054.mp4`},word: "Allowed",},
  {video: {url: `155698.mp4`},word: "Undone",},
  {video: {url: `92906.mp4`},word: "Most",},
  {video: {url: `118365.mp4`},word: "Again",},
  {video: {url: `92963.mp4`},word: "Not",},
  {video: {url: `89875.mp4`},word: "On",},
  {video: {url: `153649.mp4`},word: "Otherwise",},
  {video: {url: `245891.mp4`},word: "Once",},
  {video: {url: `153591.mp4`},word: "In reality",},
  {video: {url: `96982.mp4`},word: "Various",},
  {video: {url: `91370.mp4`},word: "At home",},
  {video: {url: `244782.mp4`},word: "Truly",},
  {video: {url: `124282.mp4`},word: "Beneath",},
  {video: {url: `96393.mp4`},word: "Forth",},
  {video: {url: `91445.mp4`},word: "In general",},
  {video: {url: `116268.mp4`},word: "Per",},
  {video: {url: `50755.mp4`},word: "Behind",},
  {video: {url: `48810.mp4`},word: "Near",},
  {video: {url: `244516.mp4`},word: "For the first time",},
  {video: {url: `63599.mp4`},word: "When",},
  {video: {url: `62752.mp4`},word: "Right",},
  {video: {url: `124288.mp4`},word: "Besides",},
  {video: {url: `51934.mp4`},word: "In front of",},
  {video: {url: `63533.mp4`},word: "Especially",},
  {video: {url: `51089.mp4`},word: "This year",},
  {video: {url: `58561.mp4`},word: "Tonight",},
  {video: {url: `93270.mp4`},word: "Daily",},
  {video: {url: `92901.mp4`},word: "Monthly",},
  {video: {url: `51864.mp4`},word: "January",},
  {video: {url: `122858.mp4`},word: "Autumn",},
  {video: {url: `51076.mp4`},word: "Winter",},
  {video: {url: `122525.mp4`},word: "April",},
  {video: {url: `48816.mp4`},word: "Next week",},
  {video: {url: `48599.mp4`},word: "Month",},
  {video: {url: `51881.mp4`},word: "Date",},
  {video: {url: `48546.mp4`},word: "Last week",},
  {video: {url: `51149.mp4`},word: "March",},
  {video: {url: `51108.mp4`},word: "Yesterday",},
  {video: {url: `50867.mp4`},word: "Today",},
  {video: {url: `118363.mp4`},word: "Afternoon",},
  {video: {url: `51642.mp4`},word: "Season",},
  {video: {url: `50911.mp4`},word: "Tomorrow",},
  {video: {url: `50838.mp4`},word: "Summer",},
  {video: {url: `50820.mp4`},word: "Spring",},
  {video: {url: `49160.mp4`},word: "October",},
  {video: {url: `50863.mp4`},word: "September",},
  {video: {url: `49005.mp4`},word: "November",},
  {video: {url: `51868.mp4`},word: "June",},
  {video: {url: `48585.mp4`},word: "May",},
  {video: {url: `122844.mp4`},word: "August",},
  {video: {url: `51866.mp4`},word: "July",},
  {video: {url: `63590.mp4`},word: "Sunday",},
  {video: {url: `48547.mp4`},word: "Last year",},
  {video: {url: `92992.mp4`},word: "Fall",},
  {video: {url: `51103.mp4`},word: "Year",},
  {video: {url: `50845.mp4`},word: "Saturday",},
  {video: {url: `50928.mp4`},word: "Tuesday",},
  {video: {url: `50882.mp4`},word: "Thursday",},
  {video: {url: `50953.mp4`},word: "Wednesday",},
  {video: {url: `48818.mp4`},word: "Next year",},
  {video: {url: `48595.mp4`},word: "Monday",},
  {video: {url: `49182.mp4`},word: "Pen",},
  {video: {url: `155600.mp4`},word: "Sketchbook",},
  {video: {url: `92964.mp4`},word: "Notepad",},
  {video: {url: `49183.mp4`},word: "Pencil sharpener",},
  {video: {url: `49171.mp4`},word: "Paper",},
  {video: {url: `154657.mp4`},word: "Glue stick",},
  {video: {url: `96563.mp4`},word: "Hole punsch",},
  {video: {url: `89984.mp4`},word: "Tape",},
  {video: {url: `157914.mp4`},word: "Pencil case",},
  {video: {url: `117018.mp4`},word: "Stationery",},
  {video: {url: `96619.mp4`},word: "Ink bottle",},
  {video: {url: `157928.mp4`},word: "Piece of paper",},
  {video: {url: `116978.mp4`},word: "Spiral binder",},
  {video: {url: `65893.mp4`},word: "Notebook",},
  {video: {url: `65923.mp4`},word: "Stapler",},
  {video: {url: `51876.mp4`},word: "Folder",},
  {video: {url: `89938.mp4`},word: "Rubber",},
  {video: {url: `116598.mp4`},word: "Rolodex",},
  {video: {url: `116266.mp4`},word: "Pencil cup",},
  {video: {url: `116265.mp4`},word: "Pencil box",},
  {video: {url: `96552.mp4`},word: "Highlighter",},
  {video: {url: `244477.mp4`},word: "Felt-tipped pen",},
  {video: {url: `116204.mp4`},word: "Paper clip",},
  {video: {url: `244512.mp4`},word: "Form",},
  {video: {url: `89637.mp4`},word: "Herbal",},
  {video: {url: `189432.mp4`},word: "Painkiller",},
  {video: {url: `185228.mp4`},word: "Prescription",},
  {video: {url: `189496.mp4`},word: "Vaccination",},
  {video: {url: `245867.mp4`},word: "Overdose",},
  {video: {url: `189495.mp4`},word: "Vaccine",},
  {video: {url: `58321.mp4`},word: "Medicines",},
  {video: {url: `188318.mp4`},word: "Anaesthetic",},
  {video: {url: `91694.mp4`},word: "Amphetamine",},
  {video: {url: `188317.mp4`},word: "Anaesthesia",},
  {video: {url: `122590.mp4`},word: "Aspirin",},
  {video: {url: `191286.mp4`},word: "Pharmacology",},
  {video: {url: `125756.mp4`},word: "Cannabis",},
  {video: {url: `92859.mp4`},word: "Medicine bottle",},
  {video: {url: `90180.mp4`},word: "Vitamin c",},
  {video: {url: `189240.mp4`},word: "General anaesthesia",},
  {video: {url: `189512.mp4`},word: "Syrup",},
  {video: {url: `185312.mp4`},word: "Vitamin",},
  {video: {url: `91697.mp4`},word: "Antibiotic",},
  {video: {url: `189294.mp4`},word: "Hormone",},
  {video: {url: `157839.mp4`},word: "Medicine",},
  {video: {url: `96659.mp4`},word: "Ointment",},
  {video: {url: `91683.mp4`},word: "Adrenalin",},
  {video: {url: `48575.mp4`},word: "Marijuana",},
  {video: {url: `96616.mp4`},word: "Inhaler",},
  {video: {url: `92860.mp4`},word: "Medicine cabinet",},
  {video: {url: `89919.mp4`},word: "Anything for dessert?",},
  {video: {url: `89916.mp4`},word: "A table for two, please",},
  {video: {url: `90024.mp4`},word: "Can we reserve a table?",},
  {video: {url: `90036.mp4`},word: "Check, please.",},
  {video: {url: `89975.mp4`},word: "Can i take your order?",},
  {video: {url: `91515.mp4`},word: "Make a reservation",},
  {video: {url: `74877.mp4`},word: "Ask for the menu",},
  {video: {url: `91535.mp4`},word: "Order, to",},
  {video: {url: `59562.mp4`},word: "Reserve",},
  {video: {url: `89945.mp4`},word: "Serve",},
  {video: {url: `74876.mp4`},word: "Area boss",},
  {video: {url: `59637.mp4`},word: "Waitress",},
  {video: {url: `125590.mp4`},word: "Buffet",},
  {video: {url: `92640.mp4`},word: "Cover charge",},
  {video: {url: `74911.mp4`},word: "Chef",},
  {video: {url: `59255.mp4`},word: "Kitchen assistant",},
  {video: {url: `189633.mp4`},word: "Maitre d",},
  {video: {url: `74886.mp4`},word: "Bill",},
  {video: {url: `51850.mp4`},word: "Men's bathroom",},
  {video: {url: `59564.mp4`},word: "Restaurant director",},
  {video: {url: `75033.mp4`},word: "Menu",},
  {video: {url: `59636.mp4`},word: "Waiter",},
  {video: {url: `91580.mp4`},word: "Pub",},
  {video: {url: `74898.mp4`},word: "Cafeteria",},
  {video: {url: `47486.mp4`},word: "Bar",},
  {video: {url: `91636.mp4`},word: "Snack-bar",},
  {video: {url: `116859.mp4`},word: "Short order cook",},
  {video: {url: `48545.mp4`},word: "Ladies toilet",},
  {video: {url: `244299.mp4`},word: "Cook",},
  {video: {url: `116317.mp4`},word: "Pizza delivery person",},
  {video: {url: `92658.mp4`},word: "Eating room",},
  {video: {url: `116318.mp4`},word: "Pizza maker",},
  {video: {url: `74972.mp4`},word: "Dish of the day",},
  {video: {url: `125660.mp4`},word: "Cafe",},
  {video: {url: `93197.mp4`},word: "Bakery",},
  {video: {url: `50756.mp4`},word: "Restaurant",},
  {video: {url: `92849.mp4`},word: "Mcdonald's",},
  {video: {url: `125661.mp4`},word: "Cafeteria worker",},
  {video: {url: `91777.mp4`},word: "Health consultant",},
  {video: {url: `191338.mp4`},word: "Podiatrist",},
  {video: {url: `91759.mp4`},word: "Family doctor",},
  {video: {url: `96899.mp4`},word: "Optician",},
  {video: {url: `189453.mp4`},word: "Psychotherapist",},
  {video: {url: `91685.mp4`},word: "Aid",},
  {video: {url: `244385.mp4`},word: "District nurse",},
  {video: {url: `116429.mp4`},word: "Psychologist",},
  {video: {url: `91811.mp4`},word: "Midwife",},
  {video: {url: `245913.mp4`},word: "Nursing staff",},
  {video: {url: `189441.mp4`},word: "Personal assistance",},
  {video: {url: `117104.mp4`},word: "Surgeon",},
  {video: {url: `116288.mp4`},word: "Pharmacist",},
  {video: {url: `96942.mp4`},word: "Medical technician",},
  {video: {url: `116215.mp4`},word: "Paramedic",},
  {video: {url: `245738.mp4`},word: "Public health nurse",},
  {video: {url: `96940.mp4`},word: "Medic",},
  {video: {url: `244241.mp4`},word: "Community care worker",},
  {video: {url: `185304.mp4`},word: "Therapist",},
  {video: {url: `96313.mp4`},word: "Emergency medical service",},
  {video: {url: `188320.mp4`},word: "Anaesthetist",},
  {video: {url: `245813.mp4`},word: "Physiotherapist",},
  {video: {url: `94171.mp4`},word: "Support assistant",},
  {video: {url: `94075.mp4`},word: "Audiologist",},
  {video: {url: `91719.mp4`},word: "Chief physician",},
  {video: {url: `189038.mp4`},word: "Clinician",},
  {video: {url: `126004.mp4`},word: "Chiropractor",},
  {video: {url: `91775.mp4`},word: "Head nurse",},
  {video: {url: `91692.mp4`},word: "Ambulance attendant",},
  {video: {url: `116427.mp4`},word: "Psychiatrist",},
  {video: {url: `49008.mp4`},word: "Nurse",},
  {video: {url: `91525.mp4`},word: "Non-alcoholic",},
  {video: {url: `244103.mp4`},word: "Black tea",},
  {video: {url: `59599.mp4`},word: "Still water",},
  {video: {url: `59333.mp4`},word: "Lemonade/soda",},
  {video: {url: `59350.mp4`},word: "Liquid",},
  {video: {url: `116943.mp4`},word: "Soda",},
  {video: {url: `89791.mp4`},word: "Beverage",},
  {video: {url: `59205.mp4`},word: "Hot chocolate",},
  {video: {url: `74941.mp4`},word: "Coffee",},
  {video: {url: `245146.mp4`},word: "Tap water",},
  {video: {url: `75002.mp4`},word: "Fruit juice",},
  {video: {url: `91523.mp4`},word: "Mineral water",},
  {video: {url: `96594.mp4`},word: "Ice tea",},
  {video: {url: `91464.mp4`},word: "Cocoa",},
  {video: {url: `75034.mp4`},word: "Milk",},
  {video: {url: `91521.mp4`},word: "Milkshake",},
  {video: {url: `58485.mp4`},word: "Coke",},
  {video: {url: `59584.mp4`},word: "Soft drink",},
  {video: {url: `59611.mp4`},word: "Tea",},
  {video: {url: `244993.mp4`},word: "White tea",},
  {video: {url: `91454.mp4`},word: "Cappuccino",},
  {video: {url: `91597.mp4`},word: "Punch",},
  {video: {url: `91462.mp4`},word: "Coca-cola",},
  {video: {url: `243913.mp4`},word: "7 up",},
  {video: {url: `59641.mp4`},word: "Water",},
  {video: {url: `59213.mp4`},word: "Juice",},
  {video: {url: `74959.mp4`},word: "Cup of coffee",},
  {video: {url: `91643.mp4`},word: "Squash",},
  {video: {url: `245690.mp4`},word: "Red tea",},
  {video: {url: `245691.mp4`},word: "Red fruit tea",},
  {video: {url: `59592.mp4`},word: "Sparkling water",},
  {video: {url: `74960.mp4`},word: "Cup of tea",},
  {video: {url: `74948.mp4`},word: "Cool drink",},
  {video: {url: `244597.mp4`},word: "Green tea",},
  {video: {url: `245366.mp4`},word: "Ros\u00e9",},
  {video: {url: `74890.mp4`},word: "Brandy",},
  {video: {url: `58849.mp4`},word: "Alcohol",},
  {video: {url: `74910.mp4`},word: "Champagne",},
  {video: {url: `246005.mp4`},word: "Mature wine",},
  {video: {url: `74978.mp4`},word: "Dry wine",},
  {video: {url: `59605.mp4`},word: "Sweet wine",},
  {video: {url: `59643.mp4`},word: "White wine",},
  {video: {url: `122532.mp4`},word: "Aquavit",},
  {video: {url: `74937.mp4`},word: "Cocktail",},
  {video: {url: `74885.mp4`},word: "Beer",},
  {video: {url: `59559.mp4`},word: "Red wine",},
  {video: {url: `92789.mp4`},word: "Whiskey",},
  {video: {url: `245345.mp4`},word: "Sangria",},
  {video: {url: `92795.mp4`},word: "Wine",},
  {video: {url: `59356.mp4`},word: "Liquor",},
  {video: {url: `91461.mp4`},word: "Cider",},
  {video: {url: `59545.mp4`},word: "Port wine",},
  {video: {url: `74914.mp4`},word: "Chestnut",},
  {video: {url: `75043.mp4`},word: "Nut",},
  {video: {url: `89794.mp4`},word: "Brasil nut",},
  {video: {url: `59542.mp4`},word: "Pop corn",},
  {video: {url: `74872.mp4`},word: "Almond",},
  {video: {url: `91557.mp4`},word: "Peanut",},
  {video: {url: `59603.mp4`},word: "Sweet almond",},
  {video: {url: `91635.mp4`},word: "Snack",},
  {video: {url: `59189.mp4`},word: "Hazelnut",},
  {video: {url: `89662.mp4`},word: "Pine nut",},
  {video: {url: `116367.mp4`},word: "Popcorn salt",},
  {video: {url: `116366.mp4`},word: "Popcorn cart",},
  {video: {url: `59567.mp4`},word: "Salt",},
  {video: {url: `89802.mp4`},word: "Can",},
  {video: {url: `59421.mp4`},word: "Loaf",},
  {video: {url: `89793.mp4`},word: "Blue cheese",},
  {video: {url: `74981.mp4`},word: "Egg yolk",},
  {video: {url: `153296.mp4`},word: "Egg",},
  {video: {url: `74895.mp4`},word: "Brown rice",},
  {video: {url: `91666.mp4`},word: "Wheat",},
  {video: {url: `89797.mp4`},word: "Broth",},
  {video: {url: `96137.mp4`},word: "Cracker",},
  {video: {url: `244258.mp4`},word: "Convenience food",},
  {video: {url: `74907.mp4`},word: "Cereal",},
  {video: {url: `91650.mp4`},word: "Sweetener",},
  {video: {url: `59158.mp4`},word: "Egg-shell",},
  {video: {url: `75015.mp4`},word: "Ice",},
  {video: {url: `59208.mp4`},word: "Hot sauce",},
  {video: {url: `91549.mp4`},word: "Pasta",},
  {video: {url: `91550.mp4`},word: "Pastry",},
  {video: {url: `59154.mp4`},word: "Crispbread",},
  {video: {url: `74986.mp4`},word: "Fat",},
  {video: {url: `89635.mp4`},word: "Grain",},
  {video: {url: `91471.mp4`},word: "Cream",},
  {video: {url: `96434.mp4`},word: "Gravy",},
  {video: {url: `74951.mp4`},word: "Crumb",},
  {video: {url: `123322.mp4`},word: "Barley",},
  {video: {url: `74891.mp4`},word: "Bread",},
  {video: {url: `51402.mp4`},word: "Catsup",},
  {video: {url: `89786.mp4`},word: "Baguette",},
  {video: {url: `59633.mp4`},word: "Vanilla",},
  {video: {url: `245977.mp4`},word: "Millet",},
  {video: {url: `125611.mp4`},word: "Bun",},
  {video: {url: `91530.mp4`},word: "Olive oil",},
  {video: {url: `58862.mp4`},word: "Biscuit",},
  {video: {url: `153641.mp4`},word: "Oil",},
  {video: {url: `75000.mp4`},word: "Frozen food",},
  {video: {url: `187050.mp4`},word: "Edible fungi",},
  {video: {url: `125952.mp4`},word: "Cheese",},
  {video: {url: `116397.mp4`},word: "Pretzel",},
  {video: {url: `58870.mp4`},word: "Bouillon",},
  {video: {url: `59569.mp4`},word: "Sauce",},
  {video: {url: `75024.mp4`},word: "Lump sugar",},
  {video: {url: `74896.mp4`},word: "Butter",},
  {video: {url: `75025.mp4`},word: "Macaroni",},
  {video: {url: `58858.mp4`},word: "Baking powder",},
  {video: {url: `244966.mp4`},word: "Yellow sugar",},
  {video: {url: `96435.mp4`},word: "Grease",},
  {video: {url: `75027.mp4`},word: "Margarine",},
  {video: {url: `59645.mp4`},word: "Wine vinegar",},
  {video: {url: `59591.mp4`},word: "Spaghetti",},
  {video: {url: `59590.mp4`},word: "Sour cream",},
  {video: {url: `116255.mp4`},word: "Peanut butter",},
  {video: {url: `59580.mp4`},word: "Slice of bread",},
  {video: {url: `59601.mp4`},word: "Sugar",},
  {video: {url: `244177.mp4`},word: "Cereal products",},
  {video: {url: `92896.mp4`},word: "Molasses",},
  {video: {url: `89656.mp4`},word: "Dairy products",},
  {video: {url: `59647.mp4`},word: "Yolk of an egg",},
  {video: {url: `91520.mp4`},word: "Mayonnaise",},
  {video: {url: `74992.mp4`},word: "Food",},
  {video: {url: `75016.mp4`},word: "Jam",},
  {video: {url: `91524.mp4`},word: "Mustard",},
  {video: {url: `89619.mp4`},word: "Buckwheat",},
  {video: {url: `91657.mp4`},word: "Toast",},
  {video: {url: `74991.mp4`},word: "Flour",},
  {video: {url: `91563.mp4`},word: "Pepper-mint",},
  {video: {url: `91618.mp4`},word: "Shell",},
  {video: {url: `91660.mp4`},word: "Truffle",},
  {video: {url: `123156.mp4`},word: "Bagel",},
  {video: {url: `91664.mp4`},word: "Vinegar",},
  {video: {url: `74926.mp4`},word: "Chili sauce",},
  {video: {url: `111090.mp4`},word: "Noodle",},
  {video: {url: `75014.mp4`},word: "Honey",},
  {video: {url: `62518.mp4`},word: "Baby formula",},
  {video: {url: `189395.mp4`},word: "Maternal hospital",},
  {video: {url: `185341.mp4`},word: "Permanent care",},
  {video: {url: `94361.mp4`},word: "Support services",},
  {video: {url: `245389.mp4`},word: "Retirement home",},
  {video: {url: `91691.mp4`},word: "Ambulance",},
  {video: {url: `116495.mp4`},word: "Recovery room",},
  {video: {url: `91726.mp4`},word: "Community residential facility",},
  {video: {url: `189430.mp4`},word: "Outpatient department",},
  {video: {url: `244242.mp4`},word: "Community care",},
  {video: {url: `91713.mp4`},word: "Care institution",},
  {video: {url: `245876.mp4`},word: "Organisation of healthcare",},
  {video: {url: `185101.mp4`},word: "Institutional care",},
  {video: {url: `189422.mp4`},word: "Nursing hospital",},
  {video: {url: `91701.mp4`},word: "Asylum",},
  {video: {url: `189461.mp4`},word: "Residential nursery",},
  {video: {url: `185256.mp4`},word: "Residential care",},
  {video: {url: `189462.mp4`},word: "Residential care home",},
  {video: {url: `91722.mp4`},word: "Clinic",},
  {video: {url: `93168.mp4`},word: "Infirmary",},
  {video: {url: `91753.mp4`},word: "Emergency department",},
  {video: {url: `124952.mp4`},word: "Blood bank",},
  {video: {url: `92689.mp4`},word: "Health resort",},
  {video: {url: `97060.mp4`},word: "Offside",},
  {video: {url: `97062.mp4`},word: "Pass",},
  {video: {url: `51521.mp4`},word: "Ball conduction",},
  {video: {url: `123214.mp4`},word: "Ball reception",},
  {video: {url: `97038.mp4`},word: "Full back",},
  {video: {url: `97057.mp4`},word: "Linesman",},
  {video: {url: `97040.mp4`},word: "Goal line",},
  {video: {url: `87654.mp4`},word: "Substitute player",},
  {video: {url: `189292.mp4`},word: "Hooliganism",},
  {video: {url: `91843.mp4`},word: "Goal",},
  {video: {url: `97051.mp4`},word: "Indirect free kick",},
  {video: {url: `93045.mp4`},word: "Football player",},
  {video: {url: `51531.mp4`},word: "Center circle",},
  {video: {url: `93044.mp4`},word: "Football field",},
  {video: {url: `87637.mp4`},word: "Soccer field",},
  {video: {url: `153327.mp4`},word: "Goal area",},
  {video: {url: `87638.mp4`},word: "Soccer",},
  {video: {url: `153692.mp4`},word: "Red card",},
  {video: {url: `153316.mp4`},word: "Five-a-side football",},
  {video: {url: `153320.mp4`},word: "Football boots",},
  {video: {url: `153660.mp4`},word: "Penalty kick",},
  {video: {url: `116939.mp4`},word: "Soccer player",},
  {video: {url: `58390.mp4`},word: "Defensive player",},
  {video: {url: `51598.mp4`},word: "Corner kick",},
  {video: {url: `97059.mp4`},word: "Offensive player",},
  {video: {url: `87625.mp4`},word: "Set play",},
  {video: {url: `97055.mp4`},word: "Kick-off",},
  {video: {url: `97041.mp4`},word: "Goalkeeper",},
  {video: {url: `91855.mp4`},word: "Premier league",},
  {video: {url: `153650.mp4`},word: "Own goal",},
  {video: {url: `87708.mp4`},word: "World cup",},
  {video: {url: `153661.mp4`},word: "Penalty line",},
  {video: {url: `153714.mp4`},word: "Shin guard",},
  {video: {url: `153304.mp4`},word: "European football championship",},
  {video: {url: `91316.mp4`},word: "Back",},
  {video: {url: `87643.mp4`},word: "Sprint",},
  {video: {url: `153757.mp4`},word: "Triathlon",},
  {video: {url: `87619.mp4`},word: "Sand box",},
  {video: {url: `87687.mp4`},word: "Triple jump",},
  {video: {url: `97050.mp4`},word: "Hurdle race",},
  {video: {url: `97047.mp4`},word: "High jump",},
  {video: {url: `122816.mp4`},word: "Athletics",},
  {video: {url: `153619.mp4`},word: "Marathon",},
  {video: {url: `91845.mp4`},word: "Hurdle",},
  {video: {url: `87666.mp4`},word: "Swing race",},
  {video: {url: `153709.mp4`},word: "Scissors jump",},
  {video: {url: `87649.mp4`},word: "Starting block",},
  {video: {url: `51639.mp4`},word: "Skipping",},
  {video: {url: `153719.mp4`},word: "Skipped step",},
  {video: {url: `96235.mp4`},word: "Discus",},
  {video: {url: `97074.mp4`},word: "Track and field athletics",},
  {video: {url: `97065.mp4`},word: "Pole vault",},
  {video: {url: `97045.mp4`},word: "Hammer throw",},
  {video: {url: `97053.mp4`},word: "Javelin",},
  {video: {url: `97058.mp4`},word: "Long jump",},
  {video: {url: `93900.mp4`},word: "Breast feed",},
  {video: {url: `96962.mp4`},word: "Suckle",},
  {video: {url: `51779.mp4`},word: "Crawl",},
  {video: {url: `51517.mp4`},word: "Baptize",},
  {video: {url: `93970.mp4`},word: "Name",},
  {video: {url: `157925.mp4`},word: "Picture book",},
  {video: {url: `154402.mp4`},word: "Children's book",},
  {video: {url: `96221.mp4`},word: "Diaper bag",},
  {video: {url: `93297.mp4`},word: "Diaper",},
  {video: {url: `96223.mp4`},word: "Diaper rash",},
  {video: {url: `96222.mp4`},word: "Diaper pail",},
  {video: {url: `96138.mp4`},word: "Cradle",},
  {video: {url: `92627.mp4`},word: "Children's club",},
  {video: {url: `51567.mp4`},word: "Children's literature",},
  {video: {url: `51568.mp4`},word: "Children's song",},
  {video: {url: `153735.mp4`},word: "Stroller",},
  {video: {url: `51587.mp4`},word: "Infant",},
  {video: {url: `123115.mp4`},word: "Baby",},
  {video: {url: `117082.mp4`},word: "Sucking your thumb",},
  {video: {url: `51772.mp4`},word: "Baby clothes",},
  {video: {url: `51324.mp4`},word: "Baby bag",},
  {video: {url: `123117.mp4`},word: "Baby blanket",},
  {video: {url: `89995.mp4`},word: "Teddy bear",},
  {video: {url: `62524.mp4`},word: "Baby spoon",},
  {video: {url: `62525.mp4`},word: "Baby stroller",},
  {video: {url: `123130.mp4`},word: "Baby toy",},
  {video: {url: `62514.mp4`},word: "Baby bootie",},
  {video: {url: `51333.mp4`},word: "Baby bottle",},
  {video: {url: `153673.mp4`},word: "Potty",},
  {video: {url: `124683.mp4`},word: "Brith certificate",},
  {video: {url: `51914.mp4`},word: "Toddler",},
  {video: {url: `51334.mp4`},word: "Baby carriage",},
  {video: {url: `51332.mp4`},word: "Baby bonnet",},
  {video: {url: `62526.mp4`},word: "Baby walker",},
  {video: {url: `65926.mp4`},word: "Swing",},
  {video: {url: `96146.mp4`},word: "Crib",},
  {video: {url: `116594.mp4`},word: "Rocking horse",},
  {video: {url: `153651.mp4`},word: "Pacifier",},
  {video: {url: `92894.mp4`},word: "Model train",},
  {video: {url: `51570.mp4`},word: "Doll",},
  {video: {url: `93238.mp4`},word: "Colouring book",},
  {video: {url: `62515.mp4`},word: "Baby carrier",},
  {video: {url: `62516.mp4`},word: "Baby changing table",},
  {video: {url: `51631.mp4`},word: "Sandcastle",},
  {video: {url: `125534.mp4`},word: "Breast milk",},
  {video: {url: `51760.mp4`},word: "Toy",},
  {video: {url: `123268.mp4`},word: "Baptism",},
  {video: {url: `124742.mp4`},word: "Blocks",},
  {video: {url: `124682.mp4`},word: "Birth announcement",},
  {video: {url: `65885.mp4`},word: "Kite",},
  {video: {url: `123125.mp4`},word: "Baby powder",},
  {video: {url: `62523.mp4`},word: "Baby shower",},
  {video: {url: `123127.mp4`},word: "Baby seat",},
  {video: {url: `62521.mp4`},word: "Baby pacifier",},
  {video: {url: `62520.mp4`},word: "Baby mittens",},
  {video: {url: `62522.mp4`},word: "Baby rattle",},
  {video: {url: `62581.mp4`},word: "Bib",},
  {video: {url: `62517.mp4`},word: "Baby cradle",},
  {video: {url: `51647.mp4`},word: "Stuffed animal",},
  {video: {url: `62519.mp4`},word: "Baby lotion",},
  {video: {url: `62518.mp4`},word: "Baby formula",},
  {video: {url: `58499.mp4`},word: "Cute",},
  {video: {url: `123620.mp4`},word: "Beautiful",},
  {video: {url: `93094.mp4`},word: "Gorgeous",},
  {video: {url: `96124.mp4`},word: "Cosmetic",},
  {video: {url: `96399.mp4`},word: "Fragrant",},
  {video: {url: `122835.mp4`},word: "Attractive",},
  {video: {url: `50950.mp4`},word: "Ugly",},
  {video: {url: `116271.mp4`},word: "Perfume",},
  {video: {url: `244043.mp4`},word: "Appearance",},
  {video: {url: `92929.mp4`},word: "Nail polish",},
  {video: {url: `92928.mp4`},word: "Nail clippers",},
  {video: {url: `89855.mp4`},word: "Mascara",},
  {video: {url: `89982.mp4`},word: "Tanning bed",},
  {video: {url: `89979.mp4`},word: "Tan",},
  {video: {url: `244582.mp4`},word: "Glamour",},
  {video: {url: `124969.mp4`},word: "Blush brush",},
  {video: {url: `62609.mp4`},word: "Blush",},
  {video: {url: `90145.mp4`},word: "Tweezers",},
  {video: {url: `93254.mp4`},word: "Cotton swab",},
  {video: {url: `92837.mp4`},word: "Makeup-removal",},
  {video: {url: `89715.mp4`},word: "Manicure",},
  {video: {url: `87925.mp4`},word: "Hand protection lotion",},
  {video: {url: `89716.mp4`},word: "Manicurist",},
  {video: {url: `244461.mp4`},word: "Facial expression",},
  {video: {url: `92978.mp4`},word: "Eyeliner",},
  {video: {url: `92976.mp4`},word: "Eye shadow",},
  {video: {url: `93255.mp4`},word: "Cosmetic surgery",},
  {video: {url: `62564.mp4`},word: "Beauty queen",},
  {video: {url: `62566.mp4`},word: "Beauty shop",},
  {video: {url: `62561.mp4`},word: "Beautician",},
  {video: {url: `62563.mp4`},word: "Beauty consultant",},
  {video: {url: `62562.mp4`},word: "Beauty",},
  {video: {url: `89990.mp4`},word: "Tattoo",},
  {video: {url: `91675.mp4`},word: "Stretch marks",},
  {video: {url: `62565.mp4`},word: "Beauty salon",},
  {video: {url: `187759.mp4`},word: "Eyelash curler",},
  {video: {url: `48573.mp4`},word: "Make up",},
  {video: {url: `96374.mp4`},word: "Fingernail scissors",},
  {video: {url: `89759.mp4`},word: "Styling",},
  {video: {url: `89726.mp4`},word: "Pedicure",},
  {video: {url: `87932.mp4`},word: "Lotion",},
  {video: {url: `117087.mp4`},word: "Sunbather",},
  {video: {url: `93117.mp4`},word: "Hand lotion",},
  {video: {url: `89850.mp4`},word: "Lipstick",},
  {video: {url: `89671.mp4`},word: "At the front",},
  {video: {url: `89672.mp4`},word: "At the sides",},
  {video: {url: `89670.mp4`},word: "At the back",},
  {video: {url: `89673.mp4`},word: "At the top",},
  {video: {url: `89731.mp4`},word: "Plastic bowl for color",},
  {video: {url: `188460.mp4`},word: "Tinfoil",},
  {video: {url: `188255.mp4`},word: "Salon trolley",},
  {video: {url: `89736.mp4`},word: "Pump for shampoo",},
  {video: {url: `187713.mp4`},word: "Cutting collar",},
  {video: {url: `87910.mp4`},word: "Hairdressing salon",},
  {video: {url: `188464.mp4`},word: "Tinting bowl",},
  {video: {url: `89775.mp4`},word: "Water spray",},
  {video: {url: `187958.mp4`},word: "Hair tool belt",},
  {video: {url: `87907.mp4`},word: "Hairdressing gloves",},
  {video: {url: `89710.mp4`},word: "Highlighting cap and hook",},
  {video: {url: `87923.mp4`},word: "Hood hair dryer",},
  {video: {url: `87913.mp4`},word: "Hairdressing tool bag",},
  {video: {url: `188041.mp4`},word: "Holder for hair dryer and iron",},
  {video: {url: `87903.mp4`},word: "Hairdressing apron",},
  {video: {url: `89680.mp4`},word: "Case scissors for hair",},
  {video: {url: `87911.mp4`},word: "Hairdressing scissors",},
  {video: {url: `87901.mp4`},word: "Hairdressing",},
  {video: {url: `87909.mp4`},word: "Hairdressing mirror",},
  {video: {url: `87905.mp4`},word: "Hairdressing chair",},
  {video: {url: `87916.mp4`},word: "Hairdressing wall",},
  {video: {url: `87715.mp4`},word: "Appointment book",},
  {video: {url: `188484.mp4`},word: "Wash basin",},
  {video: {url: `87719.mp4`},word: "Barber",},
  {video: {url: `187714.mp4`},word: "Cutting gown",},
  {video: {url: `89708.mp4`},word: "Hairstylist",},
  {video: {url: `51335.mp4`},word: "Barber chair",},
  {video: {url: `87716.mp4`},word: "Appointment card",},
  {video: {url: `89685.mp4`},word: "Curly",},
  {video: {url: `62540.mp4`},word: "Bald",},
  {video: {url: `125553.mp4`},word: "Brittle",},
  {video: {url: `87894.mp4`},word: "Greasy",},
  {video: {url: `51847.mp4`},word: "Dark-haired",},
  {video: {url: `124740.mp4`},word: "Blond",},
  {video: {url: `244124.mp4`},word: "Braid",},
  {video: {url: `96163.mp4`},word: "Curling iron",},
  {video: {url: `89705.mp4`},word: "Hairgrowth",},
  {video: {url: `187729.mp4`},word: "Diffuser",},
  {video: {url: `89699.mp4`},word: "Hair extension",},
  {video: {url: `187998.mp4`},word: "Head cap",},
  {video: {url: `58436.mp4`},word: "Damage",},
  {video: {url: `188260.mp4`},word: "Scrunch dry",},
  {video: {url: `51178.mp4`},word: "Braid",},
  {video: {url: `87899.mp4`},word: "Haircut",},
  {video: {url: `87931.mp4`},word: "Long hair",},
  {video: {url: `87731.mp4`},word: "Bunch",},
  {video: {url: `188233.mp4`},word: "Prolonged hair",},
  {video: {url: `89695.mp4`},word: "Flatiron for hair",},
  {video: {url: `89749.mp4`},word: "Short hair",},
  {video: {url: `89743.mp4`},word: "Serum",},
  {video: {url: `89766.mp4`},word: "Trim",},
  {video: {url: `188466.mp4`},word: "Tone",},
  {video: {url: `89691.mp4`},word: "Dreadlocks",},
  {video: {url: `89677.mp4`},word: "Brunette",},
  {video: {url: `87727.mp4`},word: "Blow-drier",},
  {video: {url: `116843.mp4`},word: "Shaving kit",},
  {video: {url: `188049.mp4`},word: "Kinky hair",},
  {video: {url: `89703.mp4`},word: "Hair streaked with grey",},
  {video: {url: `116842.mp4`},word: "Shaving cream",},
  {video: {url: `89684.mp4`},word: "Curling pins",},
  {video: {url: `89722.mp4`},word: "Oily and dry hair",},
  {video: {url: `89701.mp4`},word: "Hair loss",},
  {video: {url: `50799.mp4`},word: "Comb",},
  {video: {url: `188245.mp4`},word: "Roller",},
  {video: {url: `89682.mp4`},word: "Conditioner",},
  {video: {url: `188059.mp4`},word: "Laser comb",},
  {video: {url: `89727.mp4`},word: "Perm",},
  {video: {url: `116841.mp4`},word: "Shaving brush",},
  {video: {url: `89675.mp4`},word: "Bleach",},
  {video: {url: `125641.mp4`},word: "Butch",},
  {video: {url: `123329.mp4`},word: "Barrette",},
  {video: {url: `87897.mp4`},word: "Hairbrush",},
  {video: {url: `87724.mp4`},word: "Blow dry",},
  {video: {url: `118145.mp4`},word: "A-line bob",},
  {video: {url: `87729.mp4`},word: "Braiding",},
  {video: {url: `184995.mp4`},word: "Weaving",},
  {video: {url: `89757.mp4`},word: "Straightening gel",},
  {video: {url: `89707.mp4`},word: "Hairpin",},
  {video: {url: `187939.mp4`},word: "Hair grip",},
  {video: {url: `89776.mp4`},word: "Wax",},
  {video: {url: `89746.mp4`},word: "Shaver",},
  {video: {url: `89704.mp4`},word: "Hair-straightener",},
  {video: {url: `184886.mp4`},word: "Pigtail",},
  {video: {url: `89667.mp4`},word: "Afro comb",},
  {video: {url: `187697.mp4`},word: "Curl enhancer",},
  {video: {url: `89681.mp4`},word: "Children's hair balm",},
  {video: {url: `89686.mp4`},word: "Curly hair",},
  {video: {url: `89758.mp4`},word: "Straight hair",},
  {video: {url: `89696.mp4`},word: "Hair band",},
  {video: {url: `89676.mp4`},word: "Bow",},
  {video: {url: `89778.mp4`},word: "Wig",},
  {video: {url: `188219.mp4`},word: "Plait",},
  {video: {url: `125610.mp4`},word: "Bun",},
  {video: {url: `89733.mp4`},word: "Ponytail",},
  {video: {url: `89698.mp4`},word: "Hair dye",},
  {video: {url: `89683.mp4`},word: "Curler",},
  {video: {url: `184874.mp4`},word: "Hairspray",},
  {video: {url: `89709.mp4`},word: "Highlight",},
  {video: {url: `89756.mp4`},word: "Straightening iron",},
  {video: {url: `124741.mp4`},word: "Blonde",},
  {video: {url: `96435.mp4`},word: "Grease",},
  {video: {url: `89694.mp4`},word: "Electric clippers",},
  {video: {url: `87720.mp4`},word: "Beard",},
  {video: {url: `65912.mp4`},word: "Shampoo",},
  {video: {url: `118337.mp4`},word: "Add-ins",},
  {video: {url: `184875.mp4`},word: "Headband",},
  {video: {url: `89693.mp4`},word: "Elastic band",},
  {video: {url: `186292.mp4`},word: "Bouffant",},
  {video: {url: `186256.mp4`},word: "Anti-static brush",},
  {video: {url: `89760.mp4`},word: "Styling product",},
  {video: {url: `188435.mp4`},word: "Strand of hair",},
  {video: {url: `89754.mp4`},word: "Split ends",},
  {video: {url: `89706.mp4`},word: "Hairnet",},
  {video: {url: `188467.mp4`},word: "Topknot",},
  {video: {url: `89700.mp4`},word: "Hair gel",},
  {video: {url: `89720.mp4`},word: "Moustache",},
  {video: {url: `184884.mp4`},word: "Parting",},
  {video: {url: `96162.mp4`},word: "Curl",},
  {video: {url: `89697.mp4`},word: "Hair colour chart",},
  {video: {url: `89777.mp4`},word: "White hair",},
  {video: {url: `188179.mp4`},word: "Ornamental comb",},
  {video: {url: `188200.mp4`},word: "Perm rod",},
  {video: {url: `188262.mp4`},word: "Sectioning",},
  {video: {url: `188165.mp4`},word: "Neck brush",},
  {video: {url: `89668.mp4`},word: "Aluminium foil",},
  {video: {url: `89751.mp4`},word: "Shower cap",},
  {video: {url: `89702.mp4`},word: "Hair ribbon",},
  {video: {url: `87922.mp4`},word: "Hairtexture",},
  {video: {url: `89689.mp4`},word: "Dandruff",},
  {video: {url: `187894.mp4`},word: "Fringe",},
  {video: {url: `89712.mp4`},word: "Layered cut",},
  {video: {url: `187824.mp4`},word: "Finishing spray",},
  {video: {url: `109916.mp4`},word: "He is 87 years old",},
  {video: {url: `96645.mp4`},word: "Juvenile",},
  {video: {url: `51114.mp4`},word: "Young",},
  {video: {url: `49163.mp4`},word: "Old",},
  {video: {url: `63528.mp4`},word: "Adult",},
  {video: {url: `118367.mp4`},word: "Age",},
  {video: {url: `243999.mp4`},word: "Adolescence",},
  {video: {url: `51115.mp4`},word: "Youth",},
  {video: {url: `123115.mp4`},word: "Baby",},
  {video: {url: `93979.mp4`},word: "Old woman",},
  {video: {url: `244025.mp4`},word: "Aging",},
  {video: {url: `93978.mp4`},word: "Old man",},
  {video: {url: `91426.mp4`},word: "Generation",},
  {video: {url: `125977.mp4`},word: "Child",},
  {video: {url: `91323.mp4`},word: "Childhood",},
  {video: {url: `89996.mp4`},word: "Teen",},
  {video: {url: `96647.mp4`},word: "Kid",},
  {video: {url: `51914.mp4`},word: "Toddler",},
  {video: {url: `245899.mp4`},word: "Old age",},
  {video: {url: `89940.mp4`},word: "Rubber boot",},
  {video: {url: `65913.mp4`},word: "Shoelace",},
  {video: {url: `50763.mp4`},word: "Boots",},
  {video: {url: `50927.mp4`},word: "Sneakers",},
  {video: {url: `116912.mp4`},word: "Slippers",},
  {video: {url: `50804.mp4`},word: "Shoes",},
  {video: {url: `65908.mp4`},word: "Sandals",},
  {video: {url: `116854.mp4`},word: "Shoe horn",},
  {video: {url: `116853.mp4`},word: "Shoe shiner",},
  {video: {url: `116855.mp4`},word: "Shoemaker",},
  {video: {url: `51135.mp4`},word: "Tie",},
  {video: {url: `96417.mp4`},word: "Glisten",},
  {video: {url: `96492.mp4`},word: "Handbag",},
  {video: {url: `246103.mp4`},word: "Jewellery design",},
  {video: {url: `93296.mp4`},word: "Diamond",},
  {video: {url: `65881.mp4`},word: "Handkerchief",},
  {video: {url: `116379.mp4`},word: "Pouch",},
  {video: {url: `110229.mp4`},word: "Mitt",},
  {video: {url: `125263.mp4`},word: "Bracelet",},
  {video: {url: `116336.mp4`},word: "Pocket watch",},
  {video: {url: `188457.mp4`},word: "Tie clasp",},
  {video: {url: `93972.mp4`},word: "Necklace",},
  {video: {url: `51145.mp4`},word: "Bow tie",},
  {video: {url: `51154.mp4`},word: "Hat",},
  {video: {url: `110433.mp4`},word: "Money clip",},
  {video: {url: `125584.mp4`},word: "Buckle",},
  {video: {url: `123221.mp4`},word: "Bandana",},
  {video: {url: `93266.mp4`},word: "Crown",},
  {video: {url: `118181.mp4`},word: "Accessories",},
  {video: {url: `96646.mp4`},word: "Kerchief",},
  {video: {url: `96159.mp4`},word: "Cufflink",},
  {video: {url: `125721.mp4`},word: "Cameo",},
  {video: {url: `62685.mp4`},word: "Cap",},
  {video: {url: `65932.mp4`},word: "Wallet",},
  {video: {url: `244850.mp4`},word: "Precious stone",},
  {video: {url: `97100.mp4`},word: "Sunglasses",},
  {video: {url: `245835.mp4`},word: "Pearl",},
  {video: {url: `188171.mp4`},word: "Neckpiece",},
  {video: {url: `65909.mp4`},word: "Scarf",},
  {video: {url: `62662.mp4`},word: "Brooch",},
  {video: {url: `91365.mp4`},word: "Armlet",},
  {video: {url: `94061.mp4`},word: "Wristwatch",},
  {video: {url: `96295.mp4`},word: "Earring",},
  {video: {url: `91429.mp4`},word: "Glitter",},
  {video: {url: `124276.mp4`},word: "Belt buckle",},
  {video: {url: `154716.mp4`},word: "Jewellery",},
  {video: {url: `87891.mp4`},word: "Glove",},
  {video: {url: `117113.mp4`},word: "Suspenders",},
  {video: {url: `93339.mp4`},word: "Earmuffs",},
  {video: {url: `93076.mp4`},word: "Gem",},
  {video: {url: `189411.mp4`},word: "Mittens",},
  {video: {url: `153772.mp4`},word: "Watch",},
  {video: {url: `116450.mp4`},word: "Purse",},
  {video: {url: `49164.mp4`},word: "Old-fashioned",},
  {video: {url: `65877.mp4`},word: "Fashionable",},
  {video: {url: `93323.mp4`},word: "Dressed up",},
  {video: {url: `89572.mp4`},word: "Second-hand",},
  {video: {url: `96976.mp4`},word: "Unisex",},
  {video: {url: `125614.mp4`},word: "Bundled up",},
  {video: {url: `244586.mp4`},word: "Get dressed",},
  {video: {url: `90194.mp4`},word: "Wear",},
  {video: {url: `116470.mp4`},word: "Raincoat",},
  {video: {url: `191366.mp4`},word: "Poncho",},
  {video: {url: `51590.mp4`},word: "Mask",},
  {video: {url: `90142.mp4`},word: "Tuxedo",},
  {video: {url: `116907.mp4`},word: "Sleeve",},
  {video: {url: `117016.mp4`},word: "Static free clothing",},
  {video: {url: `62557.mp4`},word: "Beachwear",},
  {video: {url: `245021.mp4`},word: "Warm clothes",},
  {video: {url: `244777.mp4`},word: "Veil",},
  {video: {url: `244832.mp4`},word: "Robe",},
  {video: {url: `245942.mp4`},word: "National costume",},
  {video: {url: `49220.mp4`},word: "Pullover",},
  {video: {url: `65929.mp4`},word: "Trousers",},
  {video: {url: `51939.mp4`},word: "Jeans",},
  {video: {url: `94023.mp4`},word: "Skirt",},
  {video: {url: `87766.mp4`},word: "Fashion",},
  {video: {url: `153671.mp4`},word: "Pocket",},
  {video: {url: `92747.mp4`},word: "Suit",},
  {video: {url: `50749.mp4`},word: "Bathrobe",},
  {video: {url: `96237.mp4`},word: "Disguise",},
  {video: {url: `74875.mp4`},word: "Apron",},
  {video: {url: `50791.mp4`},word: "Shirt",},
  {video: {url: `50852.mp4`},word: "T-shirt",},
  {video: {url: `124957.mp4`},word: "Blouse",},
  {video: {url: `93120.mp4`},word: "Hanger",},
  {video: {url: `154512.mp4`},word: "Costumes",},
  {video: {url: `117040.mp4`},word: "Stocking",},
  {video: {url: `90189.mp4`},word: "Waist",},
  {video: {url: `96135.mp4`},word: "Coverall",},
  {video: {url: `96279.mp4`},word: "Dressing gown",},
  {video: {url: `244521.mp4`},word: "Folk costume",},
  {video: {url: `96919.mp4`},word: "Overcoat",},
  {video: {url: `51772.mp4`},word: "Baby clothes",},
  {video: {url: `187762.mp4`},word: "Fashionmonger",},
  {video: {url: `87768.mp4`},word: "Fashion model",},
  {video: {url: `89773.mp4`},word: "Vest",},
  {video: {url: `188413.mp4`},word: "Shirt tunic",},
  {video: {url: `89721.mp4`},word: "Night shirt",},
  {video: {url: `187896.mp4`},word: "Frock",},
  {video: {url: `89676.mp4`},word: "Bow",},
  {video: {url: `89761.mp4`},word: "Swimming trunks",},
  {video: {url: `89748.mp4`},word: "Shirt button",},
  {video: {url: `154687.mp4`},word: "Hippie",},
  {video: {url: `184903.mp4`},word: "Sleeve link",},
  {video: {url: `51553.mp4`},word: "Costume",},
  {video: {url: `65876.mp4`},word: "Fancy dress",},
  {video: {url: `65892.mp4`},word: "Nightdress",},
  {video: {url: `116199.mp4`},word: "Pajamas",},
  {video: {url: `125254.mp4`},word: "Bra",},
  {video: {url: `94050.mp4`},word: "Underpants",},
  {video: {url: `51169.mp4`},word: "Panties",},
  {video: {url: `188052.mp4`},word: "Knickerboxers",},
  {video: {url: `188135.mp4`},word: "Long trousers",},
  {video: {url: `89725.mp4`},word: "Pants",},
  {video: {url: `187736.mp4`},word: "Dandy",},
  {video: {url: `110224.mp4`},word: "Mini skirt",},
  {video: {url: `110178.mp4`},word: "Long skirt",},
  {video: {url: `118589.mp4`},word: "Woman's suit",},
  {video: {url: `110212.mp4`},word: "Men's suit",},
  {video: {url: `93115.mp4`},word: "Halter top",},
  {video: {url: `116986.mp4`},word: "Sports coat",},
  {video: {url: `65921.mp4`},word: "Socks",},
  {video: {url: `122831.mp4`},word: "Attire",},
  {video: {url: `184897.mp4`},word: "Shorts",},
  {video: {url: `188168.mp4`},word: "Neckline",},
  {video: {url: `89981.mp4`},word: "Tank top",},
  {video: {url: `116850.mp4`},word: "Shirt and tie",},
  {video: {url: `118559.mp4`},word: "Tuxedo shirt",},
  {video: {url: `118558.mp4`},word: "Turtleneck shirt",},
  {video: {url: `110211.mp4`},word: "Men's shirt",},
  {video: {url: `188075.mp4`},word: "Layering",},
  {video: {url: `96158.mp4`},word: "Cuff",},
  {video: {url: `89674.mp4`},word: "Blazer",},
  {video: {url: `96427.mp4`},word: "Gown",},
  {video: {url: `188479.mp4`},word: "Undergarment",},
  {video: {url: `89774.mp4`},word: "Waistcoat",},
  {video: {url: `184834.mp4`},word: "Clothing",},
  {video: {url: `87743.mp4`},word: "Collar",},
  {video: {url: `116911.mp4`},word: "Slip",},
  {video: {url: `245116.mp4`},word: "Tights",},
  {video: {url: `89759.mp4`},word: "Styling",},
  {video: {url: `62587.mp4`},word: "Bikini",},
  {video: {url: `51940.mp4`},word: "Jogging suit",},
  {video: {url: `65871.mp4`},word: "Austria",},
  {video: {url: `93240.mp4`},word: "Comic",},
  {video: {url: `62189.mp4`},word: "Amuse",},
  {video: {url: `154658.mp4`},word: "Go to an exhibition",},
  {video: {url: `90073.mp4`},word: "Tickle",},
  {video: {url: `96316.mp4`},word: "Entertain",},
  {video: {url: `51444.mp4`},word: "Chuckle",},
  {video: {url: `62190.mp4`},word: "Amusement park",},
  {video: {url: `158008.mp4`},word: "Reading circle",},
  {video: {url: `91362.mp4`},word: "April fool",},
  {video: {url: `51822.mp4`},word: "Entertainment",},
  {video: {url: `51558.mp4`},word: "Amusement",},
  {video: {url: `51808.mp4`},word: "Face painting",},
  {video: {url: `51801.mp4`},word: "Clapping",},
  {video: {url: `51735.mp4`},word: "Water park",},
  {video: {url: `58257.mp4`},word: "Clown shoes",},
  {video: {url: `93221.mp4`},word: "Carousel horse",},
  {video: {url: `117014.mp4`},word: "State fair",},
  {video: {url: `92655.mp4`},word: "Disneyland",},
  {video: {url: `116299.mp4`},word: "Pie throwing",},
  {video: {url: `116287.mp4`},word: "Petting zoo",},
  {video: {url: `92602.mp4`},word: "Bathing place",},
  {video: {url: `116818.mp4`},word: "Seesaw",},
  {video: {url: `48608.mp4`},word: "Museum",},
  {video: {url: `51631.mp4`},word: "Sandcastle",},
  {video: {url: `50796.mp4`},word: "Circus",},
  {video: {url: `92603.mp4`},word: "Bathing pool",},
  {video: {url: `93153.mp4`},word: "Humor",},
  {video: {url: `154615.mp4`},word: "Flash mob",},
  {video: {url: `116193.mp4`},word: "Pageant",},
  {video: {url: `116596.mp4`},word: "Rollercoaster",},
  {video: {url: `125609.mp4`},word: "Bumper car",},
  {video: {url: `116934.mp4`},word: "Snowman",},
  {video: {url: `96638.mp4`},word: "Jester",},
  {video: {url: `125021.mp4`},word: "Bouncy castle",},
  {video: {url: `90140.mp4`},word: "Tunnel of love",},
  {video: {url: `92620.mp4`},word: "Casino",},
  {video: {url: `93052.mp4`},word: "Fortuneteller",},
  {video: {url: `97022.mp4`},word: "Carousel",},
  {video: {url: `90203.mp4`},word: "Water slide",},
  {video: {url: `125803.mp4`},word: "Carnival game",},
  {video: {url: `125802.mp4`},word: "Carnival barker",},
  {video: {url: `96366.mp4`},word: "Ferris wheel",},
  {video: {url: `62731.mp4`},word: "Clap",},
  {video: {url: `125801.mp4`},word: "Carnival",},
  {video: {url: `89611.mp4`},word: "Baby swim",},
  {video: {url: `92662.mp4`},word: "Estonia",},
  {video: {url: `243957.mp4`},word: "North estonia",},
  {video: {url: `92900.mp4`},word: "Montana",},
  {video: {url: `92688.mp4`},word: "Hawaii",},
  {video: {url: `92833.mp4`},word: "Maine",},
  {video: {url: `125668.mp4`},word: "California",},
  {video: {url: `110196.mp4`},word: "Massachusetts",},
  {video: {url: `92949.mp4`},word: "New york",},
  {video: {url: `110216.mp4`},word: "Michigan",},
  {video: {url: `92948.mp4`},word: "Nevada",},
  {video: {url: `18519.mp4`},word: "Usa",},
  {video: {url: `125969.mp4`},word: "Chicago",},
  {video: {url: `58575.mp4`},word: "Virginia",},
  {video: {url: `92882.mp4`},word: "Milwaukee",},
  {video: {url: `58573.mp4`},word: "United states",},
  {video: {url: `92940.mp4`},word: "Nebraska",},
  {video: {url: `58579.mp4`},word: "Washington",},
  {video: {url: `92845.mp4`},word: "Maryland",},
  {video: {url: `110226.mp4`},word: "Minnesota",},
  {video: {url: `90169.mp4`},word: "Vermont",},
  {video: {url: `96599.mp4`},word: "Illinois",},
  {video: {url: `50765.mp4`},word: "Bulgaria",},
  {video: {url: `50843.mp4`},word: "Sweden",},
  {video: {url: `63582.mp4`},word: "Spain",},
  {video: {url: `244181.mp4`},word: "Central greece",},
  {video: {url: `110172.mp4`},word: "Latvia",},
];
