import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainDashboardContentMobile } from "./MainDashboardContentMobile";
import { EnablicJourneyMobile } from "./EnablicJourneyMobile/EnablicJourneyMobile";

export const DashboardContentMobile = () => {
  const [content, setContent] = useState(null);
  const { tab } = useParams();
  useEffect(() => {
    switch (tab) {
      case "enablicJourney":
        setContent(<EnablicJourneyMobile />);
        break;
      default:
        setContent(<MainDashboardContentMobile />);
    }
  }, [tab]);

  return <div>{content}</div>;
};
