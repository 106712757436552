import React from "react";
import Beginning from "../GameComponents/Beginning";
import Quiz from "../GameComponents/Quiz";
import Done from "../GameComponents/Done";
import Timmer from "../GameComponents/Timmer";
import Lifes from "../GameComponents/Lifes";
import Points from "../GameComponents/Points";
import "./MathQuiz.css";
import TableScore from "../GameComponents/TableScore";

class MathQuiz extends React.Component {
  state = {
    isBeginningDone: false,
    lastPoints: 0,
  };

  retryGame = () => {
    this.setState({ isBeginningDone: false });
    this.props.onRetryGame();
  };

  completeBeginning = () => {
    this.setState({ isBeginningDone: true });
  };

  render() {
    // console.log("this.props -> ", this.props);
    return !this.props.isFinished ? (
      <div>
        {!this.state.isBeginningDone ? (
          <Beginning isComplete={this.completeBeginning} />
        ) : (
          <div>
            <div className="MathsQuiz-header-bar">
              <Timmer {...this.props} />
              <Lifes {...this.props} />
              <Points {...this.props} />
            </div>
            <Quiz {...this.props} />
          </div>
        )}
      </div>
    ) : (
      <Done {...this.props} retryGame={this.retryGame}>
        <TableScore {...this.props} />
      </Done>
    );
  }
}

export default MathQuiz;
