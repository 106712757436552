import {
  CHANGE_AMOUNT,
  CHANGE_DIFFICULTY,
  CHANGE_TYPE,
  CHANGE_CATEGORY,
  CHANGE_SCORE,
  CHANGE_SIGNIN,
  CHANGE_TESTOVER,
  CHANGE_TOTALQUESTION,
  CHANGE_WORDLEGAMEOVER,
  CHANGE_SCHEDULE,
  CHANGE_STARTGAME,
  SET_TIME,
  GAIN_POINTS,
  REMOVE_LIVE,
  CORRECT_ANSWER,
  FINISH_GAME,
  RESTART_GAME,
  REBOOT_GAME,
  START_GAME,
  EARN_LIFE,
  EXPLORE_THE_PLATFORM,
  PROGRESS_ITEM_TUTORIAL,
  ENABLIC_JOURNEY_TUTORIAL,
} from "./actionsTypes";

const initialState = {
  question_category: "",
  question_difficulty: "",
  question_type: "",
  amount_of_question: 10,
  testOver: false,
  score: 0,
  signinData: "",
  totalQuestion: 0,
  changeWordleGameOver: false,
  changeStartGame: false,
  currentSchedule: 0,

  exploreThePlatform: false,
  progressItemTutorial: false,
  enablicJourneyTutorial: false,

  points: 0,
  lifes: 3,
  seconds: 20,
  level: 1,
  isFinished: false,
  isStarted: false,
};
const reducer = (state = initialState, action) => {
  // console.log("action", action);
  switch (action.type) {
    case CHANGE_CATEGORY:
      return {
        ...state,
        question_category: action.payload,
      };
    case CHANGE_DIFFICULTY:
      return {
        ...state,
        question_difficulty: action.payload,
      };
    case CHANGE_TYPE:
      return {
        ...state,
        question_type: action.payload,
      };
    case CHANGE_AMOUNT:
      return {
        ...state,
        amount_of_question: action.payload,
      };
    case CHANGE_SCORE:
      return {
        ...state,
        score: action.payload,
      };
    case CHANGE_SIGNIN:
      return {
        ...state,
        signinData: action.payload,
      };
    case CHANGE_TESTOVER:
      return {
        ...state,
        testOver: action.payload,
      };
    case CHANGE_TOTALQUESTION:
      return {
        ...state,
        totalQuestion: action.payload,
      };
    case CHANGE_WORDLEGAMEOVER:
      return {
        ...state,
        changeWordleGameOver: action.payload,
      };
    case CHANGE_STARTGAME:
      return {
        ...state,
        changeStartGame: action.payload,
      };
    case CHANGE_SCHEDULE:
      return {
        ...state,
        currentSchedule: action.payload,
      };
    case EXPLORE_THE_PLATFORM:
      return {
        ...state,
        exploreThePlatform: action.payload,
      };
    case PROGRESS_ITEM_TUTORIAL:
      return {
        ...state,
        progressItemTutorial: action.payload,
      };
    case ENABLIC_JOURNEY_TUTORIAL:
      return {
        ...state,
        enablicJourneyTutorial: action.payload,
      };

    case GAIN_POINTS:
      const newPonts = state.points + action.payload;
      return Object.assign({}, state, {
        points: newPonts,
      });
    case SET_TIME:
      return Object.assign({}, state, {
        seconds: action.payload,
      });
    case CORRECT_ANSWER:
      const newCorrectState = {
        points: state.points + action.payload.points,
        level: state.level + action.payload.level,
      };
      return Object.assign({}, state, newCorrectState);
    case REMOVE_LIVE:
      const newLive = state.lifes - action.payload;
      return Object.assign({}, state, {
        lifes: newLive,
      });
    case FINISH_GAME:
      return Object.assign({}, state, {
        isFinished: action.payload,
      });
    case RESTART_GAME:
      return Object.assign({}, state, {
        points: 0,
        lifes: 3,
        seconds: 20,
        level: 1,
        isFinished: false,
      });
    case START_GAME:
      return Object.assign({}, state, {
        isStarted: true,
      });
    case EARN_LIFE:
      return Object.assign({}, state, {
        lifes: state.lifes + 1,
      });
    case REBOOT_GAME:
      return Object.assign({}, state, {
        points: 0,
        lifes: 3,
        seconds: 20,
        level: 1,
        isFinished: false,
        isStarted: false,
      });

    default:
      return state;
  }
};

export default reducer;
