import React, { Component } from "react";
import "./PokeAMole.css";
import anime from "animejs";
import Score from "./Score.js";
import GameOver from "./GameOver.js";
import StartButton from "./StartButton.js";
import MoleHole from "./MoleHole.js";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { gameData } from "../GameData/gameData";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { connect } from "react-redux";
import { API } from "../../../../backend";
import { NavLink } from "react-router-dom";
import VideoTutorial from "../../GameTutorial/VideoTutorial";
// import Confetti from "react-confetti";
import MascotIndicate from "../../Mascot/MascotIndicate";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";

class PokeAMole extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      1: "translate(0, 110%)",
      2: "translate(0, 110%)",
      3: "translate(0, 110%)",
      4: "translate(0, 110%)",
      5: "translate(0, 110%)",
      6: "translate(0, 110%)",
      7: "translate(0, 110%)",
      8: "translate(0, 110%)",
      9: "translate(0, 110%)",
      shake: "translate(0, 0)",
      gameHasStarted: false,
      moleHasBeenWhacked: false,
      score: 0,
      lastMole: "",
      display: "none",
      buttonMessage: "Start Game",
      gameOver: "none",
      buttonDisplay: "inline-block",
      titleMargin: "15px",
      localSigninData: JSON.parse(localStorage.getItem("jwt")),
      open: false,
      openSnackbar: false,
      severity: "error",
      message: "",
    };
  }

  animate(el) {
    anime({
      targets: el,
      direction: "alternate",
      loop: true,
      easing: "easeInQuad",
      duration: 1600,
      scale: function (el, i, l) {
        return l - i + 0.08;
      },
    });
  }

  timeOut(num) {
    if (this.state.gameHasStarted) {
      return;
    }
    this.setState({
      buttonDisplay: "none",
      display: "block",
      gameOver: "none",
      titleMargin: 0,
    });
    this.shakeScreen();
    window.setTimeout(() => {
      this.startGame();
    }, num);
  }

  startGame() {
    if (this.state.gameHasStarted) {
      return;
    }

    this.setState({
      gameHasStarted: true,
      score: 0,
    });

    let x = 0;
    const intervalID = setInterval(() => {
      this.displayMoles();
      if (++x === 16) {
        window.clearInterval(intervalID);
        this.clearMoles();
        this.setState({ gameHasStarted: false });
        window.setTimeout(() => {
          this.setState({
            display: "none",
            gameOver: "block",
            buttonMessage: "Play again",
            buttonDisplay: "inline-block",
            titleMargin: "15px",
          });
          // this.animate(this.refs.gameOver);
        }, 850);
      }
    }, 1350);
  }

  clearMoles() {
    for (let value in this.state) {
      if (!isNaN(value)) {
        this.setState({
          [value]: "translate(0, 110%)",
        });
      }
    }
  }

  displayMoles() {
    let activeMole = Math.ceil(Math.random() * 9);
    if (this.state.lastMole[0] === activeMole) {
      this.displayMoles();
      return;
    }
    this.clearMoles();
    this.setState({
      [activeMole]: "translate(0, 15%)",
      lastMole: [activeMole],
    });
  }

  lockOutClick() {
    window.setTimeout(() => {
      this.setState({ moleHasBeenWhacked: false });
    }, 350);
  }

  addToScore(e) {
    if (this.state.moleHasBeenWhacked) {
      return;
    }
    let target = e.target;
    target.parentNode.classList.add("game__cross");
    target.classList.add("no-background");
    this.lockOutClick();
    this.setState({
      background: "75px",
      moleHasBeenWhacked: true,
      score: [parseInt(this.state.score, 10) + 1],
    });
    window.setTimeout(function () {
      target.parentNode.classList.remove("game__cross");
      target.classList.remove("no-background");
    }, 500);
  }

  shakeScreen() {
    let posOrNeg = "+";
    let i = 0;
    let shake = () => {
      if (i === 15) {
        this.setState({ shake: "translate(0, 0)" });
        return;
      }
      window.setTimeout(() => {
        posOrNeg = posOrNeg === "-" ? "+" : "-";
        this.setState({ shake: `translate(${posOrNeg}${i}px, 0)` });
        shake();
      }, 80);
      i++;
    };
    shake();
  }

  createMoleHoles() {
    var holes = [];
    for (let i = 1; i <= 9; i++) {
      holes.push(
        <MoleHole
          key={i}
          context={this.state}
          onClick={this.addToScore.bind(this)}
          holeNumber={i}
        />
      );
    }
    return <div className="board">{holes}</div>;
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  setOpen = () => {
    this.setState({
      open: false,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.gameOver !== this.state.gameOver) {
      axios
        .get(`${API}/games/getByName/${gameData["PokeAMole"].name}`, {
          headers: {
            token: `${this.localSigninData && this.localSigninData.data.token}`,
          },
        })
        .then((val) => {
          if (window.location.pathname === "/gameplay") {
            SubmitScore(
              this.state.score[0],
              15 - this.state.score[0],
              val.data.data._id,
              false
            );
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              this.state.score[0],
              15 - this.state.score[0],
              val.data.data._id,
              true,
              this.props.currentSchedule
            );
            setTimeout(() => {
              this.props.handleChangeStartGame(false);
            }, 3000);
          }
        })
        .catch((err) => {
          this.setState({
            openSnackbar: true,
            message: err.message,
          });
        });
    }
  }
  render() {
    return (
      <>
        {this.state.gameOver === "block" ? (
          this.state.score[0] > 9 ? (
            <>
              <ConfettiEffect />
              <WinnerMascot
                textBubble={`Good Job ${this.state.localSigninData?.data?.user?.first_name}, you are doing well, keep going.`}
              />
            </>
          ) : (
            <>
              <GymMascot
                textBubble={`One only learns from their mistakes. It’s okay, we can try again!`}
              />
            </>
          )
        ) : (
          <></>
        )}
        {!this.state.gameHasStarted && this.state.lastMole === "" ? (
          <MascotIndicate
            textBubble={"Tap or click on the Mole, to get points."}
          />
        ) : (
          ""
        )}

        <div className="main-container">
          <div
            className="game"
            style={{
              WebkitTransform: this.state["shake"],
              width: "400px",
              borderRadius: "12px",
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(108 108 108 / 40%)",
            }}
          >
            <h1
              className="game__title"
              style={{ margin: this.state.titleMargin }}
            >
              Pok-é-mole
            </h1>
            <GameOver context={this} />
            <div ref={"gameOver"} className="game__button-container">
              <VideoTutorial
                videoLink={
                  "https://d28u08h3rd64o.cloudfront.net/pokeAmole/pokeAmole.m3u8"
                }
                open={this.state.open}
                setOpen={() => this.setOpen()}
              />
              <StartButton
                context={this.state}
                onClick={this.timeOut.bind(this)}
              />

              {window.location.pathname === "/gameplay" ? (
                <div className="game__start-button">
                  <button
                    onClick={this.handleOpen}
                    style={{
                      display: this.state.gameHasStarted
                        ? "none"
                        : this.state.display === "block"
                          ? "none"
                          : "inline-block",
                    }}
                  >
                    How to play
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            {this.state.gameOver === "block" &&
              window.location.pathname === "/gameplay" ? (
              <NavLink to="/games">
                <div className="game__start-button">
                  <button>Game Section</button>
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {this.createMoleHoles()}
            <Score context={this.state} />
          </div>
        </div>
        <CustomizedSnackbars
          open={this.state.open}
          severity={this.state.severity}
          message={this.state.message}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSchedule: state.currentSchedule,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeStartGame: () => dispatch({ type: "CHANGE_STARTGAME" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PokeAMole);
