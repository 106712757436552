import React, { useEffect, useState } from "react";
import { ReactComponent as Ellipse } from "../../common/Games/assets/gameSection.svg";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RightBrain from "../../common/Games/assets/RightBrain.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { AssessmentData } from "./AssessmentData/AssessmentData";
import AssessmentDropdownAttribute from "../Components/AssessmentDropdownAttribute/AssessmentDropdownAttribute";
import { GameContentHeader } from "../../common/Games/GameContentHeader";
import "../Assessment.css";
import Mascot from "../../common/Mascot/Mascot";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const AssessmentContent = ({ sidebarWidth }) => {
  const navigate = useNavigate();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [assessmentList, setAssessmentList] = useState([]);
  const [, setAssessmentNameData] = useState([]);

  const [assessment, setAssessment] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const assessmentObj = Object.values(AssessmentData);
    const assessmentName = Object.keys(AssessmentData);
    setAssessmentList(assessmentObj);
    setAssessmentNameData(assessmentName);
  }, []);

  useEffect(() => {
    const lists = Object.values(AssessmentData).filter(
      (assessmentFilterData, index) => {
        return !assessment || assessmentFilterData.type.includes(assessment);
      }
    );

    setAssessmentList(lists);

    // console.log("lists------> ", lists);
  }, [assessment]);

  const handleAssessment = (val) => {
    console.log("assessment is called", val);
    if (val.length !== 0) {
      setAssessment(val);
    } else {
      setAssessment();
    }
  };

  return (
    // console.log("gameList ", gameList),
    // console.log("exploreThePlatform ",exploreThePlatform),

    <div>
      <div className="ellipseGameSection">
        <Ellipse />
      </div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GameContentHeader />
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            width: "98%",
            display: "flex",
            justifyContent: "flex-end",
            filter: "drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.2))",
            borderRadius: "10.2609px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "291px",
              color: "#ffffff",
              overflow: "hidden",
              position: "absolute",
              borderRadius: "10px",
              background:
                "linear-gradient(90deg, rgba(104,130,255,1) 0%, rgba(104,130,255,1) 15%, rgba(132,119,222,1) 41%, rgba(255,70,79,0.38728995015975143) 71%)",
            }}
          >
            <div style={{ margin: "0 0 0 25px" }}>
              <h1>Assessments</h1>
              <p style={{ width: "300px", fontWeight: "400" }}>
                We provide Mind train Games Everyday to our users for Free.
              </p>
              <span style={{ margin: "100px 0 0 0", fontWeight: "300" }}>
                A daily taste of assorted cognitive tasks.
              </span>
            </div>
          </div>

          <img
            src={RightBrain}
            height="291px"
            style={{
              objectFit: "cover",
              objectPosition: "80% 100%",
            }}
            alt="Brain"
          />
        </div>
        <div className="assessmentSection">
          <h1 className="assessmentText">Assessments</h1>
          <div className="assessmentDropdown" id="selectCategory">
            <AssessmentDropdownAttribute
              assessment={(val) => {
                handleAssessment(val);
              }}
            />
          </div>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {assessmentList.length > 0 ? (
                assessmentList.map((data, index) => (
                  <Grid item xs={6} md={4} sm={6} lg={3}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        borderRadius: "10px",
                        background:
                          "linear-gradient(93.5deg, #51355E 4.67%, #24152C 96.2%)",
                        boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.31)",
                      }}
                      style={{
                        position: "relative",
                        height: "260px",
                      }}
                      id="playTheGame"
                    >
                      {data.lock ? (
                        <div className="lockChild">
                          <div className="assessmentLockGlass" />
                        </div>
                      ) : (
                        ""
                      )}
                      <CardActionArea
                        onClick={() => {
                          // if there is no test taken before redirect to the test else show a dialog
                          if (
                            localSigninData.data.user.current_journey_cycle > -1
                          )
                            handleClickOpen();
                          else navigate(data.link);
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={`${data.image}`}
                          alt={`${data.title}`}
                        />
                        <CardContent style={{ position: "relative" }}>
                          <div className="gameName">{data.title}</div>
                          <div className="credits">
                            <div style={{ width: "80%", display: "flex" }}>
                              <div style={{ margin: "5px" }}>{data.level}</div>
                            </div>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              ) : (
                <h1
                  style={{
                    width: "100%",
                    height: "600px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#51355E",
                  }}
                >
                  More to come on this space
                </h1>
              )}
            </Grid>
          </Box>
        </div>
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You brain gym routine will reset if you take the test before
            completing the Enablic Journey games.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleClose();
              navigate("/psychometricTest");
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Mascot textBubble={"We will unlock this for you soon."} />
    </div>
  );
};
