import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  handleChangeScore,
  handleChangeTestOver,
  handleChangeWordleGameOver,
  handleSignin,
  handleTotalQuestion,
} from "../../redux/actions";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import { useNavigate } from "react-router-dom";

import "./Logout.css";

const Logout = () => {
  const navigate = useNavigate();
  localStorage.removeItem("jwt");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleSignin(""));
    dispatch(handleChangeScore(0));
    dispatch(handleChangeTestOver(false));
    dispatch(handleTotalQuestion(0));
    dispatch(handleChangeWordleGameOver(false));
    setTimeout(() => {
      navigate("/signin");
    }, 3000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("signinData on logout ", signinData);
  return (
    <div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      <div className="logout-web-view">
        <div className="brainImg">
          <img src={"/assets/character_bye.gif"} alt={"brain"} />
          <span
            style={{ fontSize: "2rem", fontWeight: "400", color: "#cd9ee2" }}
          >
            Waiting to see you again!
          </span>
        </div>
      </div>
      <div className="logout-mobile-view">
        <div className="brainImg">
          <img src={"/assets/character_bye.gif"} alt={"brain"} width={200} />
          <span
            style={{ fontSize: "1.2rem", fontWeight: "400", color: "#cd9ee2" }}
          >
            Waiting to see you again!
          </span>
        </div>
      </div>
    </div>
  );
};

export default Logout;
