import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ConfettiEffect } from "../../../ConfettiEffect/ConfettiEffect";

const style = {
  divider: {
    height: 0,
    border: "0.5px solid #61dafb",
  },
  poinstStyle: {
    color: "#61dafb",
    fontWeight: "bold",
  },
};
class Done extends React.Component {
  state = {
    submitted: false,
    localSigninData: JSON.parse(localStorage.getItem("jwt")),
  };

  handleBack() {
    this.setState({
      submitted: true,
    });
  }

  render() {
    const { divider, poinstStyle } = style;
    return (
      <div>
        {this.props.points > 500 ? <ConfettiEffect /> : ""}
        <h1>GAME OVER</h1>
        <hr style={divider} />
        <h3>
          FINAL SCORE <b style={poinstStyle}>{this.props.points}</b>
        </h3>
        <br />
        {window.location.pathname === "/gameplay" ? (
          <div>
            <div className="home-play-btn">
              <button className="game-section-return-btn" onClick={this.props.retryGame}>
                <span>Play Again</span>
              </button>
            </div>
            <NavLink to="/games">
              <div className="home-play-btn">
                <button>
                  <span>Game Section</span>
                </button>
              </div>
            </NavLink>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Done.propTypes = {
  points: PropTypes.number.isRequired,
  retryGame: PropTypes.func.isRequired,
  onReStartGame: PropTypes.func.isRequired,
};
export default Done;
