import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Steps } from "intro.js-react";
import {
  handleExplore,
  handleChangeProgressItemTutorial,
  handleChangeEnablicJourneyTutorial,
} from "../../../redux/actions";
import "intro.js/introjs.css";
import "./Tutorial.css";

function EnablicJourneyGame() {
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [initialStep] = useState(0);

  const onExit = () => {
    setEnabled(false);
    dispatch(handleExplore(false));
  };

  const steps = [
    {
      element: "#playNow",
      intro: "Click the button to play the game",
      position: "right",
    },
    {
      element: "#leadersScore",
      intro: "Check the Leader's score over here",
    },
    {
      element: "#yourHighestScore",
      intro: `Your highest score`,
    },
  ];

  return (
    <div>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        // onBeforeExit={() => {
        //   console.log("journey onBeforeExit is called");
        //   dispatch(handleChangeProgressItemTutorial(false));
        //   dispatch(handleChangeEnablicJourneyTutorial(false));
        // }}
        onComplete={() => {
          // console.log("journey completed");
          dispatch(handleChangeProgressItemTutorial(false));
          dispatch(handleChangeEnablicJourneyTutorial(false));
        }}
        options={{
          hideNext: false,
          showBullets: false,
          tooltipClass: "Tuts",
          // keyboardNavigation: true,
          // exitOnEsc: false,
          // exitOnOverlayClick: false,
        }}
      />
    </div>
  );
}

export default EnablicJourneyGame;
