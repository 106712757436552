import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import VideoTutorial from "../../GameTutorial/VideoTutorial";
import TileMaster from "./TileMaster";
import MascotIndicate from "../../Mascot/MascotIndicate";

export const StartTileMaster = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"))?.data?.user
    ?.first_name;
  const [startTileMasterGame, setStartTileMasterGame] = useState();
  const [counter, setCounter] = useState(3);
  const [startGame, setStartGame] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (counter === 0) {
      setStartTileMasterGame(true);
    }
    if (startGame) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, startGame]);
  const handleStartGame = () => {
    setStartGame(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems:"center",
          height:"80vh"
        }}
      >
        <div
          style={{
            maxWidth: "1536px",
            display: "flex",
          }}
        >
          <Box
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                padding: 1,
                // marginLeft: "5%",
                // width: "90%",
                // minHeight: "526px",
                // maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {startTileMasterGame === true ? <TileMaster /> : ""}
                {startTileMasterGame === undefined ? (
                  <>
                    {startGame ? (
                      <Box
                        mt={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h1>GET Ready...</h1>
                        <h1>{counter}</h1>
                      </Box>
                    ) : (
                      <div
                        className="dashboard-play-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h1 style={{ color: "#ffffff" }}>Tile Master</h1>
                        <button onClick={handleStartGame}>Start Game</button>
                        <button onClick={handleOpen}>How to play?</button>
                        <VideoTutorial
                          videoLink={
                            "https://d28u08h3rd64o.cloudfront.net/tile_master/tile_master.m3u8"
                          }
                          open={open}
                          setOpen={setOpen}
                          handleClose={() => handleClose()}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
      {counter === 3 ? (
        <MascotIndicate
          textBubble={`Hey ${localSigninData}, Lets play Tile Master. Click on the Start Button.`}
        />
      ) : (
        ""
      )}
    </div>
  );
};
