import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainDashboardContent from "./MainDashboardContent";
import EnablicJourney from "./EnablicJourney/EnablicJourney";
import RightBarContent from "./RightBarContent";
import RightBarContentMobile from "./RightBarContentMobile";
// import { ReactComponent as SearchIcon } from "./assets/MagnifyingGlass.svg";
import "./Dashboard.css";
import { GameContentHeader } from "../common/Games/GameContentHeader";

const DashboardContent = ({ sidebarWidth }) => {
  const [content, setContent] = useState(null);
  const { tab } = useParams();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    switch (tab) {
      case "enablicJourney":
        setContent(<EnablicJourney />);
        break;
      default:
        setContent(<MainDashboardContent />);
    }
  }, [tab]);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  return (
    <div>
      <div
        className="dashboard-content"
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
        }}
      >
        <div className="main">
          <div className="header">
            <h1 className="text">
              Welcome, {localSigninData?.data?.user?.first_name}
            </h1>
            {/* <div className="search-container">
              <SearchIcon
                style={{
                  position: "absolute",
                  right: "50px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
              <input placeholder="Search here" />
            </div> */}
          </div>
          <div className="tabViewHeader">
            <GameContentHeader />
          </div>
          {content}
        </div>
        <RightBarContent />
      </div>
      {windowWidth < 1200 ? <RightBarContentMobile /> : ""}
    </div>
  );
};

export default DashboardContent;
