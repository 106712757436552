import {
  CHANGE_AMOUNT,
  CHANGE_CATEGORY,
  CHANGE_DIFFICULTY,
  CHANGE_SCORE,
  CHANGE_TYPE,
  CHANGE_SIGNIN,
  CHANGE_TESTOVER,
  CHANGE_TOTALQUESTION,
  CHANGE_WORDLEGAMEOVER,
  CHANGE_STARTGAME,
  CHANGE_SCHEDULE,
  EXPLORE_THE_PLATFORM,
  PROGRESS_ITEM_TUTORIAL,
  ENABLIC_JOURNEY_TUTORIAL,
  REMOVE_LIVE,
  CORRECT_ANSWER,
  SET_TIME,
  FINISH_GAME,
  RESTART_GAME,
  REBOOT_GAME,
  START_GAME,
  EARN_LIFE,
} from "./actionsTypes";

export const handleChangeCategory = (payload) => ({
  type: CHANGE_CATEGORY,
  payload,
});

export const handleChangeDifficulty = (payload) => ({
  type: CHANGE_DIFFICULTY,
  payload,
});

export const handleChangeAmount = (payload) => ({
  type: CHANGE_AMOUNT,
  payload,
});

export const handleChangeScore = (payload) => ({
  type: CHANGE_SCORE,
  payload,
});

export const handleChangeType = (payload) => ({
  type: CHANGE_TYPE,
  payload,
});
export const handleChangeTestOver = (payload) => ({
  type: CHANGE_TESTOVER,
  payload,
});
export const handleSignin = (payload) => ({
  type: CHANGE_SIGNIN,
  payload,
});
export const handleTotalQuestion = (payload) => ({
  type: CHANGE_TOTALQUESTION,
  payload,
});
export const handleChangeWordleGameOver = (payload) => ({
  type: CHANGE_WORDLEGAMEOVER,
  payload,
});
export const handleChangeStartGame = (payload) => ({
  type: CHANGE_STARTGAME,
  payload,
});
export const handleCurrentSchedule = (payload) => ({
  type: CHANGE_SCHEDULE,
  payload,
});
export const handleExplore = (payload) => ({
  type: EXPLORE_THE_PLATFORM,
  payload,
});
export const handleChangeProgressItemTutorial = (payload) => ({
  type: PROGRESS_ITEM_TUTORIAL,
  payload,
});
export const handleChangeEnablicJourneyTutorial = (payload) => ({
  type: ENABLIC_JOURNEY_TUTORIAL,
  payload,
});

export const correctAnswer = () => (dispatch) => {
  dispatch({ type: CORRECT_ANSWER, payload: { points: 100, level: 1 } });
};

export const wrongAnswer = () => (dispatch) => {
  dispatch({ type: REMOVE_LIVE, payload: 1 });
};

export const timeChaged = (seconds) => (dispatch) => {
  dispatch({ type: SET_TIME, payload: seconds });
};

export const stateGame = (bol) => (dispatch) => {
  if (bol) {
    dispatch({ type: FINISH_GAME, payload: true });
  } else {
    dispatch({ type: RESTART_GAME, payload: true });
  }
};

export const reStartGame = () => (dispatch) => {
  dispatch({ type: REBOOT_GAME });
};

export const startGame = () => (dispatch) => {
  dispatch({ type: START_GAME });
};

export const earnLife = () => (dispatch) => {
  dispatch({ type: EARN_LIFE });
};
