import React from "react";
import "./game.css";
import ProfileAvatarNavbar from "./assets/ProfileAvatarNavbar";
import Dropdown from "../Dropdown/Dropdown";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";

export const GameContentHeader = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="headerFullWidth">
        <div className="gameContentHeader">
          <div className="welcomeHeaderText">
            <div className="welcome">
              Welcome, {localSigninData?.data?.user?.first_name}
            </div>
          </div>
          {location.pathname !== '/profile' && <div className="profileSection">
            <div className="navbar-profile-avatar">
              <ProfileAvatarNavbar localSigninData={localSigninData} />
            </div>
            <div className="profile">
              <div
                style={{
                  width: "75%",
                  alignItems: "right",
                  paddingRight: "1rem",
                }}
              >
                <div className="firstnameLastname">
                  {" "}
                  {localSigninData?.data?.user?.first_name}{" "}
                  {localSigninData?.data?.user?.last_name}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className="view-profile"
                    onClick={() => navigate("/profile")}
                  >
                    View Profile
                  </div>
                </div>
              </div>
              {/* <div className="profileIcon">
                <Profile height={45} width={45} />
              </div> */}
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                className="CaretDown"
              >
                <Dropdown />
              </motion.div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};
