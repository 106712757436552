import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import RightArrow from "../../../assets/icons/RightArrow";
import { RightContent } from "./RightContent";
import "./RightBarContentMobile.css";

export default function RightBarContentMobile() {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            id="view-right-panel-arrow"
            // style={{
            //   display: "flex",
            //   justifyContent: "flex-start",
            //   alignItems: "center",
            //   position: "fixed",
            //   top: "300px",
            //   right: "-30px",
            //   width: "64px",
            //   background: "rgba(131, 101, 150, 0.31)",
            //   height: "64px",
            //   borderRadius: "50%",
            //   backdropFilter: "blur(5px)",
            // }}
            component={motion.div}
            // initial={{ background: "rgba(131, 101, 150, 0.31)" }}
            animate={{
              backgroundColor: ["rgba(131, 101, 150, 0.31)", "#7c90f0"],
              boxShadow: "-3px 0px 20px #7c90f0",
              border: "1px solid #fff",
              transition: { duration: 2, repeat: Infinity, ease: "easeInOut" }
            }}
            whileHover={{
              backgroundColor: "#7c90f0",
              borderColor: "#7c90f0",
              boxShadow: "0px 0px 10px #7c90f0"
            }}

            onClick={() => toggleDrawer(anchor, true)}
          >
            <RightArrow width={30} height={30} />
          </Button>
          <Drawer
            PaperProps={{
              sx: {
                backgroundColor: "#2215276f",
                backdropFilter: "blur(10px)",
                color: "#ffffff",
                overflowX: "hidden",
                height: "100vh",
              },
            }}
            anchor={"right"}
            open={state[anchor]}
            onClose={() => toggleDrawer(anchor, false)}
          >
            <RightContent toggleDrawer={() => toggleDrawer(anchor, false)} />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
