import React from "react";
import TrophyIcon from "./TrophyIcon";
export default function Trophy(props) {
  return (
    <div
      style={{
        ...props,
        position: "relative",
      }}
    >
      <div
        style={{
        //   background: "red",
          width: "18px",
          height: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "15px",
          left: "20px",
          fontWeight:400,
          fontSize:"20px"
        }}
      >
        {props.level}
      </div>
      <TrophyIcon width="100%" />
    </div>
  );
}
