import React, { useEffect, useState } from "react";
import "./EnablicJourney.css";
import ProgressItem from "./ProgressItem";
import { MainContent } from "./MainContent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { gameData } from "../../common/Games/GameData/gameData";
import CustomizedSnackbars from "../../common/Snackbar/CustomizedSnackbars";
import { journeyProgress, getCurrentJourney } from "./index";
import {
  handleCurrentSchedule,
  handleChangeStartGame,
} from "../../../redux/actions";
import RingLoader from "react-spinners/RingLoader";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const EnablicJourney = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { changeStartGame, currentSchedule } = useSelector((state) => state);

  const [gameIndex, setGameIndex] = useState();
  const [currentJourneyCycle, setCurrentJourneyCycle] = useState();
  const [progressItems, setProgressItems] = useState();
  const [gamePlayed, setGamePlayed] = useState([]);
  const [journeyDataProgress, setJourneyDataProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    const getCurrentJourneyDetails = getCurrentJourney();
    getCurrentJourneyDetails
      .then((val) => {
        // let scheduleNo;
        let played = [];
        // console.log("val -----66 ", val.data.data);
        setJourneyDataProgress(val.data.data.current_journey_progress);
        setCurrentJourneyCycle(val.data.data.current_journey_cycle);
        Object.values(val.data.data.journey).forEach((data, i) => {
          if (data.played) {
            played.push(i);
          }
          setGamePlayed(played);
        });
        const gameObj = Object.keys(val.data.data.journey).map((data, i) => {
          let name = val.data.data.journey[`${data}`].game.name;
          let itemData = { ...gameData[name] };
          itemData.scheduleNo = data;
          return itemData;
        });
        setProgressItems(gameObj);
      })
      .catch(() => {
        setLoading(false);
        setOpenSnackbar(true);
        setMessage("Something Went wrong");
        setSeverity("error");
      });

    return () => {
      dispatch(handleChangeStartGame(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule]);

  // change index onClick
  // while clicking on progressData
  useEffect(() => {
    if (!gameIndex) {
      setGameIndex(0);
    }
  }, [gameIndex]);

  //change the progress % and change the played array
  useEffect(() => {
    const Jdata = journeyProgress();

    Jdata.then((data) => setJourneyDataProgress(data)).catch(() => {
      setLoading(false);
      setOpenSnackbar(true);
      setMessage("Network Error, please try again");
      setSeverity("error");
    });
    const getCurrentJourneyDetails = getCurrentJourney();
    getCurrentJourneyDetails
      .then((val) => {
        let played = [];
        setJourneyDataProgress(val.data.data.current_journey_progress);
        setCurrentJourneyCycle(val.data.data.current_journey_cycle);
        Object.values(val.data.data.journey).forEach((data, i) => {
          if (data.played) {
            played.push(i);
          }
          setGamePlayed(played);
        });
      })
      .catch(() => {
        setLoading(false);
        setOpenSnackbar(true);
        setMessage("Network Error, please try again");
        setSeverity("error");
      });
  }, [changeStartGame]);

  useEffect(() => {
    if (!progressItems) setLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTestNavigate = () => {
    navigate("/psychometricTest");
  };
  return (
    <div>
      {progressItems ? (
        <>
          {currentJourneyCycle === undefined ? null : currentJourneyCycle >=
              0 && journeyDataProgress !== 100 ? (
            <div>
              <div className="enablicJourney">
                <div className="progress-container">
                  {/* {progressItems?.map((item, index) => (
                  <ProgressItem
                    played={gamePlayed.includes(index)}
                    journeyDataProgress={journeyDataProgress}
                    item={item}
                    key={index}
                    index={index}
                    onClick={() => {
                      setGameIndex(index);
                      if (!gamePlayed.includes(index)) {
                        dispatch(handleCurrentSchedule(`${item.scheduleNo}`));
                      }
                    }}
                  />
                ))} */}
                  {progressItems !== undefined ? (
                    <>
                      {progressItems.map((item, index) => (
                        <ProgressItem
                          played={gamePlayed.includes(index)}
                          journeyDataProgress={journeyDataProgress}
                          item={item}
                          key={index}
                          index={index}
                          onClick={() => {
                            setGameIndex(index);
                            if (!gamePlayed.includes(index)) {
                              dispatch(
                                handleCurrentSchedule(`${item.scheduleNo}`)
                              );
                            }
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="score-container">
                  {!changeStartGame ? (
                    <MainContent
                      progressItems={progressItems && progressItems[gameIndex]}
                    />
                  ) : (
                    progressItems &&
                    progressItems[gameIndex] &&
                    progressItems[gameIndex].game
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  height: "calc(100vh - 71px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    height: "80vh",
                    background: "rgb(18 5 24)",
                    borderRadius: "10px",
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      height: "10%",
                    }}
                  >
                    <div>
                      Kindly complete the Psychometric test to start Enablic
                      Journey
                    </div>
                  </div>
                  <div>
                    <div className="home-play-btn">
                      <button onClick={handleTestNavigate}>
                        Psychometric Test
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="loaderCss">
          <RingLoader
            color="#1abcf3"
            cssOverride={override}
            loading={loading}
            size={120}
            speedMultiplier={1}
          />
        </div>
      )}
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};

export default EnablicJourney;
