import { API } from "../../backend.js";
import axios from "axios";

export const forgotpassword = async (data) => {
  // console.log("email ", data.email);
  try {
    const response = await axios.post(
      `${API}/users/initiatepasswordreset`,
      { email: data.email },
      {
        "Content-Type": "application/json",
        header: "Access-Control-Allow-Origin",
      }
    );
    // console.log("await response.data", response.data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
