import React from "react";

function ProfileMobView(props) {
  return (
    <svg
      {...props}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{ fill: "#E6ECFF" }}
        d="M256,508C117.04,508,4,394.96,4,256S117.04,4,256,4s252,113.04,252,252S394.96,508,256,508z"
      />
      <path
        style={{ fill: "#ffd600" }}
        d="M256,8c136.752,0,248,111.248,248,248S392.752,504,256,504S8,392.752,8,256S119.248,8,256,8 M256,0
	C114.608,0,0,114.608,0,256s114.608,256,256,256s256-114.608,256-256S397.392,0,256,0L256,0z"
      />
      <g>
        <ellipse
          style={{ fill: "#0BA4E0" }}
          cx="256"
          cy="175.648"
          rx="61.712"
          ry="60.48"
        />
        <path
          style={{ fill: "#0BA4E0" }}
          d="M362.592,360.624c0-57.696-47.728-104.464-106.592-104.464s-106.592,46.768-106.592,104.464H362.592
		z"
        />
      </g>
    </svg>
  );
}

export default ProfileMobView;
