import * as React from "react";
const GraduationCap = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5294 3.11765C15.8235 2.96078 16.1765 2.96078 16.4706 3.11765L31.4706 11.1176C31.7965 11.2914 32 11.6307 32 12C32 12.3693 31.7965 12.7086 31.4706 12.8824L16.4706 20.8824C16.1765 21.0392 15.8235 21.0392 15.5294 20.8824L0.529412 12.8824C0.203548 12.7086 0 12.3693 0 12C0 11.6307 0.203548 11.2914 0.529412 11.1176L15.5294 3.11765ZM3.125 12L16 18.8667L28.875 12L16 5.13333L3.125 12Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1176 11.5294C15.3775 11.0421 15.9833 10.8578 16.4706 11.1177L23.9706 15.1177C24.2964 15.2915 24.5 15.6307 24.5 16V30C24.5 30.5523 24.0523 31 23.5 31C22.9477 31 22.5 30.5523 22.5 30V16.6L15.5294 12.8824C15.0421 12.6225 14.8577 12.0167 15.1176 11.5294Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12.8625C5.05229 12.8625 5.5 13.3102 5.5 13.8625V20.6749L5.50219 20.6778L5.50214 20.6778C6.24374 21.674 9.53199 25.5 16 25.5C22.468 25.5 25.7563 21.674 26.4979 20.6778L26.5 20.675V13.8625C26.5 13.3102 26.9477 12.8625 27.5 12.8625C28.0523 12.8625 28.5 13.3102 28.5 13.8625V20.6875L28.5 20.6917C28.4982 21.1197 28.3574 21.5354 28.099 21.8763C27.1602 23.1357 23.3488 27.5 16 27.5C8.65116 27.5 4.83971 23.1356 3.90098 21.8763C3.64257 21.5354 3.50182 21.1196 3.50001 20.6917L3.49999 20.6875H3.5V13.8625C3.5 13.3102 3.94772 12.8625 4.5 12.8625Z"
      fill="white"
    />
  </svg>
);
export default GraduationCap;
