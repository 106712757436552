import React, { useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { GameContent } from "./GameContent";
import MobileViewNavBar from "../../MobileViewNavBar/MobileViewNavBar";
import { GameSectionMobile } from "./GameSectionMobile";

const Games = () => {
  const [sidebarToggled, setSidebarToggled] = useState(false);

  return (
    <div>
      <div className="webView">
        <SideNavBar setSidebarToggled={setSidebarToggled} />
        <GameContent sidebarWidth={!sidebarToggled ? 65 : 240} />
      </div>
      <div className="mobileView">
        <MobileViewNavBar />
        <GameSectionMobile />
      </div>
    </div>
  );
};

export default Games;
