import React, { useState, useEffect } from "react";
import { ReactComponent as Coin } from "./assets/coin.svg";
import { ReactComponent as GamePlayBrain } from "./assets/game_play_brain.svg";
import { gameData } from "./GameData/gameData";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PlayButton from "./assets/PlayButton";
import GameSectionTutorialMobile from "../../Tutorial/EnablicJourneyGame/GameSectionTutorialMobile";
import { useSelector } from "react-redux";
import "./GameSection.css";
import Mascot from "../Mascot/Mascot";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

export const GameSectionMobile = () => {
  const navigate = useNavigate();
  const { exploreThePlatform } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [gameList, setGameList] = useState([]);
  const [, setGameNameData] = useState([]);
  const [academicSkillsGame, setAcademicSkillsGame] = useState();
  const [cognitiveSkillsGame, setCognitiveSkillsGame] = useState();
  const [ageSkillsGame, setAgeSkillsGame] = useState();
  const [abledSkillsGame, setAbledSkillsGame] = useState();
  const [physicalSkillsGame] = useState();
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    // console.log("current values=========================")
    // console.log("academicSkillsGame: ", academicSkillsGame)
    // console.log("cognitiveSkillsGame: ", cognitiveSkillsGame)
    // console.log("ageSkillsGame: ", ageSkillsGame)
    // console.log("abledSkillGame: ", abledSkillsGame)

    const lists = Object.values(gameData).filter((gameFilterData, index) => {
      return (
        (!academicSkillsGame ||
          gameFilterData.academicSkills.includes(academicSkillsGame)) &&
        (!cognitiveSkillsGame ||
          gameFilterData.improves.includes(cognitiveSkillsGame)) &&
        (!ageSkillsGame || gameFilterData.ageGroups.includes(ageSkillsGame)) &&
        (!abledSkillsGame || gameFilterData.abled.includes(abledSkillsGame))
      );
    });

    setGameList(lists);

    // console.log("lists------> ", lists);
  }, [
    academicSkillsGame,
    cognitiveSkillsGame,
    ageSkillsGame,
    abledSkillsGame,
    physicalSkillsGame,
  ]);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const handleChangeAcademicSkills = (event) => {
    setAcademicSkillsGame(event.target.value);
  };
  const handleChangeCognitiveSkills = (event) => {
    setCognitiveSkillsGame(event.target.value);
  };
  const handleChangeAge = (event) => {
    setAgeSkillsGame(event.target.value);
  };
  const handleChangeAbilities = (event) => {
    setAbledSkillsGame(event.target.value);
  };

  const playgame = (name) => {
    // console.log("click", name);
    navigate("/gameplay", {
      state: { gameid: name },
    });
  };
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const gameObj = Object.values(gameData);
    const gameName = Object.keys(gameData);
    setGameList(gameObj);
    setGameNameData(gameName);
  }, []);

  return (
    // console.log("academicSkillsGame ", academicSkillsGame),
    // console.log("cognitiveSkillsGame ", cognitiveSkillsGame),
    // console.log("ageSkillsGame ", ageSkillsGame),
    // console.log("abledSkillsGame ", abledSkillsGame),
    // console.log("gameNameData ", gameNameData),
    // console.log("windowWidth ", windowWidth),
    // console.log("exploreThePlatform", exploreThePlatform),
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="frosted-glass">
          <div className="game-section-header-bg">
            <div className="game-section-header-content">
              <div className="game-section-header-content-text">
                <div>
                  <div className="game-section-header-content-text-heading">
                    Game Section
                  </div>
                  <div className="game-section-header-content-text-description">
                    We provide Mind train Games Everyday to our users for Free.
                  </div>
                </div>
              </div>
              <div>
                <GamePlayBrain className="game-section-header-content-text-img" />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
              }}
            >
              <div className="game-section-header-bottom-bg">
                <div className="game-section-header-bottom-text">
                  A daily taste of assorted cognitive tasks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem onClick={handleClick} id="selectCategoryMobile">
          <ListItemText primary="Categories" style={{ color: "#ffffff" }} />
          {open ? (
            <Button
              className="dropdown-arrow"
              component={motion.div}
              style={{
                padding: "0px",
                minWidth: "auto",
                borderRadius: "50%",
                color: "#fff"
              }}
              animate={{
                backgroundColor: ["rgba(131, 101, 150, 0.31)", "#7c90f0"],
                boxShadow: "-3px 0px 20px #7c90f0",
                border: "1px solid #fff",
                transition: { duration: 3, repeat: Infinity, ease: "easeInOut" }
              }}
              whileHover={{
                backgroundColor: "#7c90f0",
                borderColor: "#7c90f0",
                boxShadow: "0px 0px 10px #7c90f0"
              }}>
              <ExpandLessIcon />
            </Button>
          ) : (
            <Button
              className="dropdown-arrow"
              component={motion.div}
              style={{
                padding: "0px",
                minWidth: "auto",
                borderRadius: "50%",
                color: "#fff"
              }}
              animate={{
                backgroundColor: ["rgba(131, 101, 150, 0.31)", "#7c90f0"],
                boxShadow: "-3px 0px 20px #7c90f0",
                border: "1px solid #fff",
                transition: { duration: 3, repeat: Infinity, ease: "easeInOut" }
              }}
              whileHover={{
                backgroundColor: "#7c90f0",
                borderColor: "#7c90f0",
                boxShadow: "0px 0px 10px #7c90f0"
              }}>
              <ExpandMoreIcon />
            </Button>
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "fit-content",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6} sm={4}>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 160,
                      borderRadius: "10px",
                      background: "#51355E",
                      "& .MuiInputLabel-root": {
                        color: '#fff',
                        '&.Mui-focused': {
                          color: '#fff'
                        }
                      },
                      "& .MuiOutlinedInput-root": {
                        // "& > fieldset": { borderColor: "#fff" },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        }
                      }
                    }}
                    color="secondary"
                    size="small"
                  >
                    <InputLabel
                      id="demo-simple-select-autowidth-label"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      Academic skills
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={academicSkillsGame}
                      onChange={handleChangeAcademicSkills}
                      autoWidth
                      label="Academic Skills"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Maths"}>Maths</MenuItem>
                      <MenuItem value={"Biology"}>Biology</MenuItem>
                      <MenuItem value={"Chemistry"}>Chemistry</MenuItem>
                      <MenuItem value={"Physics"}>Physics</MenuItem>
                      <MenuItem value={"English"}>English</MenuItem>
                      <MenuItem value={"GeneralKnowledge"}>
                        General Knowledge
                      </MenuItem>
                      <MenuItem value={"Verbal"}>Verbal</MenuItem>
                      <MenuItem value={"NonVerbal"}>Non Verbal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 160,
                      borderRadius: "10px",
                      background: "#51355E",
                      "& .MuiInputLabel-root": {
                        color: '#fff',
                        '&.Mui-focused': {
                          color: '#fff'
                        }
                      },
                      "& .MuiOutlinedInput-root": {
                        // "& > fieldset": { borderColor: "#fff" },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        }
                      }
                    }}
                    color="secondary"
                    size="small"
                  >
                    <InputLabel
                      id="demo-simple-select-autowidth-label"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      Cognitive Skills
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={cognitiveSkillsGame}
                      onChange={handleChangeCognitiveSkills}
                      autoWidth
                      label="Cognitive Skills"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Memory"}>Memory</MenuItem>
                      <MenuItem value={"Attention"}>Attention</MenuItem>
                      <MenuItem value={"Perception"}>Perception</MenuItem>
                      <MenuItem value={"CreativeThinking"}>
                        Creative Thinking
                      </MenuItem>
                      <MenuItem value={"CriticalThinking"}>
                        Critical Thinking
                      </MenuItem>
                      <MenuItem value={"DecisionMaking"}>
                        Decision Making
                      </MenuItem>
                      <MenuItem value={"Language"}>Language</MenuItem>
                      <MenuItem value={"Language"}>Language</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 160,
                      borderRadius: "10px",
                      background: "#51355E",
                      "& .MuiInputLabel-root": {
                        color: '#fff',
                        '&.Mui-focused': {
                          color: '#fff'
                        }
                      },
                      "& .MuiOutlinedInput-root": {
                        // "& > fieldset": { borderColor: "#fff" },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        }
                      }
                    }}
                    color="secondary"
                    size="small"
                  >
                    <InputLabel
                      sx={{
                        color: "#ffffff",
                      }}
                      id="demo-simple-select-autowidth-label"
                    >
                      Age
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={ageSkillsGame}
                      onChange={handleChangeAge}
                      autoWidth
                      label="Age"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"PrimarySchool"}>
                        Primary School
                      </MenuItem>
                      <MenuItem value={"SecondarySchool"}>
                        Secondary School
                      </MenuItem>
                      <MenuItem value={"18"}>18 and above</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 160,
                      borderRadius: "10px",
                      background: "#51355E",
                      "& .MuiInputLabel-root": {
                        color: '#fff',
                        '&.Mui-focused': {
                          color: '#fff'
                        }
                      },
                      "& .MuiOutlinedInput-root": {
                        // "& > fieldset": { borderColor: "#fff" },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        }
                      }
                    }}
                    color="secondary"
                    size="small"
                  >
                    <InputLabel
                      sx={{
                        color: "#ffffff",
                      }}
                      id="demo-simple-select-autowidth-label"
                    >
                      Abilities
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={abledSkillsGame}
                      onChange={handleChangeAbilities}
                      autoWidth
                      label="Abilities"
                      sx={{
                        color: "#ffffff",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Physical"}>Physical</MenuItem>
                      <MenuItem value={"Dyscalculia"}>Dyscalculia</MenuItem>
                      <MenuItem value={"Dyslexia"}>Dyslexia</MenuItem>
                      <MenuItem value={"Abled"}>
                        Speech and Language Disorder
                      </MenuItem>
                      <MenuItem value={"ADHD"}>ADHD</MenuItem>
                      <MenuItem value={"Autism"}>Autism</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Collapse>
      </List>
      {gameList.length > 0 && windowWidth < 950 && exploreThePlatform ? (
        <GameSectionTutorialMobile />
      ) : (
        ""
      )}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {gameList.length > 0 ? (
          gameList.map((data, index) => (
            <Grid item xs={windowWidth > 600 ? 6 : 12}>
              <div
                className="game-section-middle-parent"
                id="playTheGameMobile"
                onClick={() => playgame(data.name)}
              >
                <img
                  className="game-section-middle-img"
                  src={data.image}
                  alt="GameImage"
                  width={50}
                  height={50}
                  style={{ objectFit: "cover" }}
                />
                <div className="game-section-middle-bg">
                  <div className="game-section-middle-game-name">
                    {data.title}
                  </div>
                  <div className="game-section-middle-lower">
                    <div className="coin">
                      <Coin /> <div style={{ padding: "10px" }}>124</div>
                    </div>
                    <div className="playButton">
                      {/* <Play /> */}
                      <PlayButton width={40} height={40} />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ))
        ) : (
          <h1 className="placeholderDisp">More to come on this space</h1>
        )}
      </Grid>
      <Mascot textBubble={"Play and practice exciting games over here."} />
    </div>
  );
};
