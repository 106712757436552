import React from "react";

export default function Star(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill="#fff"
        d="M11.153 1.44c.683-1.643 3.01-1.643 3.694 0l1.916 4.607a2 2 0 001.687 1.226l4.974.398c1.773.143 2.492 2.356 1.141 3.513l-3.79 3.246a2 2 0 00-.644 1.983l1.158 4.854c.413 1.73-1.47 3.098-2.988 2.17l-4.258-2.6a2 2 0 00-2.085 0l-4.259 2.6c-1.518.928-3.4-.44-2.988-2.17l1.158-4.854a2 2 0 00-.644-1.983l-3.79-3.246C.084 10.027.803 7.814 2.576 7.671l4.974-.398a2 2 0 001.687-1.226l1.916-4.607z"
      ></path>
    </svg>
  );
}
