import { API } from "../../backend.js";
import axios from "axios";

export const signin = async (user) => {
  try {
    const response = await axios.post(`${API}/users/login`, user, {
      "Content-Type": "application/json",
      header: "Access-Control-Allow-Origin",
    });
    // console.log("await response.data", response.data);
    return response.data;
  } catch (err) {
    return err.response.data.error;
  }
};
export const isAutheticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};
