import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const { signinData } = useSelector((state) => state);
  // if (JSON.parse(localStorage.getItem("jwt"))) {
  //     navigate("/settings");
  //   }
  // console.log(JSON.parse(localStorage.getItem("jwt")));
  // console.log(
  //   "signinData.data.user",
  //   signinData && signinData.data.token.length !== 0
  // );
  return (signinData && signinData.data.token.length !== 0) ||
    JSON.parse(localStorage.getItem("jwt")) ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace />
  );
};

export default PrivateRoutes;
