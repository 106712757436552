import React from "react";
import { useRef, useEffect, useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import DotsMobileStepper from "../../Circle/DotsMobileStepper";
import { useNavigate } from "react-router-dom";
import { handleChangeStartGame } from "../../../../redux/actions";
import { SubmitScore } from "../SubmitScore/SubmitScore";

import { gameData } from "../GameData/gameData";
import Trophy from "../TileMaster/assets/Trophy";
import Heart from "../TileMaster/assets/Heart";
import { API } from "../../../../backend";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import "./Sequence.css";

import { useDispatch, useSelector } from "react-redux";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import sound from "../../../../assets/how_are_you.mp3";

export default function Sequence() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSchedule } = useSelector((state) => state);
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const ref = useRef(null);
  const [levelIncrement, setlevelIncrement] = useState(1);
  const [generatedValue, setGeneratedValue] = useState("");
  const [, setIndexData] = useState(null);
  const [wrong, setWrong] = useState(false);
  const [correct, setcorrect] = useState(false);
  const [digitAnimate, setDigitAnimate] = useState("");
  const [chancesLeft, setChancesLeft] = useState(3);
  const [, setGameScore] = useState(0);
  const [originalScore, setOriginalScore] = useState(0);
  const [missedScore, setMissedScore] = useState(0);
  const enablicJourneyScore = true;
  const [level, setLevel] = useState(1);
  const [gameId, setGameId] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [counter, setCounter] = useState(0);
  const [generating, setGenerating] = useState(false);
  const [newRound, setNewRound] = useState(false);

  const [music] = useState(new Audio(sound));
  const [musicPlaying, setMusicPlaying] = useState(true);

  function levelGen(level, base = 4) {
    let min = parseInt(`${Math.pow(10, level - 1)}`, base); //1000 for base 4
    let max = parseInt(`${base - 1}`.repeat(level), base); //3333 for base 4
    return { min: min, max: max };
  }

  useEffect(() => {
    if(!musicPlaying && music.ended) {
      setMusicPlaying(true);
    }
    musicPlaying ? music.play() : music.pause();
  }, [musicPlaying]);

  useEffect(() => {
    music.addEventListener('ended', () => {setMusicPlaying(false)});
    return () => {
      music.removeEventListener('ended', () => {setMusicPlaying(true)});
    };
  }, []);

  useEffect(() => {
    setIndexData(0);
    if (chancesLeft > 0) {
      function numGen(level, base = 4) {
        const min = levelGen(level + 3).min;
        const max = levelGen(level + 3).max;
        // generating a random number
        const a = Math.floor(Math.random() * (max - min + 1)) + min; //generate random no according to level
        return a.toString(base); //convert the random no into the base and return
      }
      setGeneratedValue(numGen(level + 3));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (chancesLeft > 0) {
        if (generatedValue !== "" && !/(.)\1/.test(generatedValue)) {
          for (let i = 0; i < generatedValue.length; i++) {
            setTimeout(async () => {
              setGenerating(true);
              setWrong(false);
              setcorrect(false);
              setDigitAnimate(generatedValue[i]);
            }, 1500 * i);
            setDigitAnimate("");
          }
          setTimeout(async () => {
            setGenerating(false);
          }, 1500 * (generatedValue.length - 1) + 500);
        } else {
          setIndexData(0);
          function numGen(level, base = 4) {
            const min = levelGen(level).min;
            const max = levelGen(level).max;

            // generating a random number
            const a = Math.floor(Math.random() * (max - min + 1)) + min; //generate random no according to level
            return a.toString(base); //convert the random no into the base and return
          }
          setGeneratedValue(numGen(level + 3));
        }
      }
    });
    // test();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedValue]);

  useEffect(() => {
    if (wrong && chancesLeft > 0) {
      // setNewRound(true);
      // setGenerating(true);
      setChancesLeft((prevChancesLeft) => prevChancesLeft - 1);
      setMissedScore((prevMissedScore) => prevMissedScore + 100);
      setDigitAnimate("");
      setTimeout(() => {
        setIndexData(0);
        setGenerating(true);
        setWrong(false);
        setcorrect(false);
        function numGen(level, base = 4) {
          const min = levelGen(level).min;
          const max = levelGen(level).max;

          // generating a random number
          const a = Math.floor(Math.random() * (max - min + 1)) + min; //generate random no according to level
          return a.toString(base); //convert the random no into the base and return
        }
        setGeneratedValue(numGen(level + 3));
        setGenerating(false);
        setNewRound(false);
      }, 3000);
    } else if (correct && !wrong && chancesLeft > 0) {
      // setGenerating(true);
      // setNewRound(true);
      setGameScore((prevScore) => prevScore + 1);
      setOriginalScore((prevScore) => prevScore + 100);
      setlevelIncrement((prevLevel) => prevLevel + 1);
      if (levelIncrement === 3) {
        setlevelIncrement(1);

        setLevel((prevLevel) => prevLevel + 1);
      }
      setDigitAnimate("");
      setTimeout(() => {
        setNewRound(false);
        setGenerating(true);
        setIndexData(0);
        setWrong(false);
        setcorrect(false);
        function numGen(level, base = 4) {
          const min = levelGen(level).min;
          const max = levelGen(level).max;

          // generating a random number
          const a = Math.floor(Math.random() * (max - min + 1)) + min; //generate random no according to level
          return a.toString(base); //convert the random no into the base and return
        }
        setGeneratedValue(numGen(level + 3));
        setGenerating(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrong, correct]);

  useEffect(() => {
    if (chancesLeft === 0 && window.location.pathname === "/gameplay") {
      SubmitScore(originalScore, missedScore, gameId, false);
    } else if (chancesLeft === 0 && window.location.pathname !== "/gameplay") {
      SubmitScore(
        originalScore,
        missedScore,
        gameId,
        enablicJourneyScore,
        currentSchedule
      );

      setTimeout(() => {
        dispatch(handleChangeStartGame(false));
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chancesLeft,
    currentSchedule,
    enablicJourneyScore,
    gameId,
    missedScore,
    originalScore,
  ]);

  useEffect(() => {
    // get the game id
    axios
      .get(`${API}/games/getByName/${gameData["MatchTheSequence"].name}`, {
        headers: {
          token: `${localSigninData && localSigninData.data.token}`,
        },
      })
      .then((val) => {
        // console.log("val", val.data.data._id);
        setGameId(val.data.data._id);
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setMessage(err.message);
        setSeverity("error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    if (!generating) {
      if (chancesLeft > 0) {
        if (event.currentTarget.id === generatedValue[counter]) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setActiveStep(0);
          setCounter(0);
          setWrong(true);
          setNewRound(true);
          setGenerating(true);
        }
        if (counter === level + 2) {
          event.currentTarget.id === generatedValue[counter]
            ? setcorrect(true)
            : setWrong(true);
          setActiveStep(0);
          setCounter(0);
          setNewRound(true);
          setGenerating(true);
        } else {
          event.currentTarget.id !== generatedValue[counter]
            ? setCounter(0)
            : setCounter((prevCounter) => prevCounter + 1);
        }
      }
    }
  };

  const variants = {
    animate: {
      scale: [1, 1.1, 1],
      boxShadow: [
        "#0ff 0px 0px 0px 0px",
        "#0ff 0px 3px 20px 0px",
        "#0ff 0px 0px 0px 0px",
      ],
      opacity: [0.4, 1, 0.4],
      transition: {
        duration: 0.5,
      },
    },
  };

  // const handleClickNewGame = () => {
  //   setChancesLeft(3);
  //   setOriginalScore(0);
  //   setMissedScore(0);
  //   setlevelIncrement(1);
  //   setGeneratedValue("");
  //   setWrong(false);
  //   setcorrect(false);
  //   setDigitAnimate("");
  //   setGameScore(0);
  //   setOriginalScore(0);
  //   setLevel(1);
  // };

  const handleBack = () => {
    navigate("/games");
  };
  return (
    <>
      {chancesLeft === 0 ? (
        originalScore >= 500 ? (
          <>
            <ConfettiEffect />
            <WinnerMascot
              textBubble={`Good Job ${localSigninData?.data?.user?.first_name}, you are doing well, keep going.`}
            />
          </>
        ) : (
          <>
            <GymMascot
              textBubble={`It’s okay, let's play something different now! We will play this again later.`}
            />
          </>
        )
      ) : (
        <></>
      )}
      <div
        div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "1536px",
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            style={{
              width: "100%",
            }}
          >
            <Card
              style={{
                padding: 1,
                width: "50vw",
                height: "fit-content",
                // maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
                background: "rgb(36 3 43 / 65%)",
                backdropFilter: "saturate(50%) blur(10px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "100px",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(39, 39, 39, 0.19)",
                  padding: "0px 10px",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Trophy width={60} level={level} />
                </div>
                <div className="scoreCard">Score: {originalScore}</div>
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {musicPlaying ? (
                    <VolumeUpIcon
                      sx={{ fontSize: "1.75rem", color: "#fff", marginRight: "1.5rem", cursor: "pointer" }}
                      onClick={() => {
                        setMusicPlaying(false);
                      }}
                    />
                  ) : (
                    <VolumeOffIcon
                      sx={{ fontSize: "1.75rem", color: "#fff", marginRight: "1.5rem", cursor: "pointer" }}
                      onClick={() => {
                        setMusicPlaying(true);
                      }}
                    />
                  )}
                  <Heart height={47} wdith={30} life={chancesLeft} />
                </div>
              </div>
              {chancesLeft > 0 ? (
                <CardContent>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      marginBottom: "15px",
                    }}
                  >
                    <p
                      className="questionText"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "35px",
                        padding: wrong || correct ? "4px 25px" : "0px",
                        backgroundColor:
                          !wrong && !correct
                            ? "transparent"
                            : wrong
                            ? "#d32f2f"
                            : "#2e7d32",
                        marginBlockStart: "0px",
                        marginBlockEnd: "0px",
                        borderRadius: "8px",
                      }}
                    >
                      {!newRound
                        ? generating && !wrong && !correct
                          ? "WAIT..."
                          : digitAnimate.length > 0
                          ? "MATCH NOW!"
                          : "WAIT..."
                        : ""}
                      {wrong ? "WRONG" : " "}
                      {correct ? "CORRECT" : " "}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <motion.div
                      animate={wrong ? { x: [0, 20, -20, 0] } : { x: 0 }}
                    >
                      <motion.div style={{ display: "flex" }}>
                        <motion.button
                          initial={{ opacity: 0.4 }}
                          variants={variants}
                          animate={
                            digitAnimate === "0" ? "animate" : { opacity: 0.4 }
                          }
                          whileTap={
                            generating
                              ? {}
                              : { background: "#c7c7c7", scale: 0.9 }
                          }
                          disabled={generating}
                          ref={ref}
                          id="0"
                          onClick={handleClick}
                          style={{
                            background: "#D21404",
                            border: "solid 1px #D21404",
                            width: "150px",
                            height: "150px",
                            margin: "10px",
                            borderRadius: "20px",
                            cursor: generating ? "no-drop" : "pointer",
                          }}
                        ></motion.button>
                        <motion.button
                          variants={variants}
                          initial={{
                            opacity: 0.4,
                          }}
                          animate={
                            digitAnimate === "1" ? "animate" : { opacity: 0.4 }
                          }
                          ref={ref}
                          disabled={generating}
                          whileTap={
                            generating
                              ? {}
                              : { background: "#c7c7c7", scale: 0.9 }
                          }
                          id="1"
                          onClick={handleClick}
                          style={{
                            width: "150px",
                            height: "150px",
                            background: "#fd7702",
                            margin: "10px",
                            borderRadius: "20px",
                            cursor: generating ? "no-drop" : "pointer",
                          }}
                        ></motion.button>
                      </motion.div>
                      <div style={{ display: "flex" }}>
                        <motion.button
                          initial={{ opacity: 0.4 }}
                          variants={variants}
                          animate={
                            digitAnimate === "2" ? "animate" : { opacity: 0.4 }
                          }
                          ref={ref}
                          disabled={generating}
                          whileTap={
                            generating
                              ? {}
                              : { background: "#c7c7c7", scale: 0.9 }
                          }
                          id="2"
                          onClick={handleClick}
                          style={{
                            width: "150px",
                            height: "150px",
                            background: "#8C65D3",
                            margin: "10px",
                            borderRadius: "20px",
                            cursor: generating ? "no-drop" : "pointer",
                          }}
                        ></motion.button>
                        <motion.button
                          initial={{ opacity: 0.4 }}
                          variants={variants}
                          animate={
                            digitAnimate === "3" ? "animate" : { opacity: 0.4 }
                          }
                          ref={ref}
                          disabled={generating}
                          whileTap={
                            generating
                              ? {}
                              : { background: "#c7c7c7", scale: 0.9 }
                          }
                          id="3"
                          onClick={handleClick}
                          style={{
                            width: "150px",
                            height: "150px",
                            background: "#7CFC00",
                            margin: "10px",
                            borderRadius: "20px",
                            cursor: generating ? "no-drop" : "pointer",
                          }}
                        ></motion.button>
                      </div>
                      <div
                        style={{
                          margin: "10px",
                          height: "3px",
                          display: "flex",
                          gap: "20px",
                        }}
                      >
                        <DotsMobileStepper
                          steps={level + 3}
                          activeStep={activeStep}
                        />
                      </div>
                    </motion.div>
                  </div>
                </CardContent>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    height: "40vh",
                  }}
                >
                  <h1>Game Over</h1>
                  <hr
                    style={{
                      width: "400px",
                      height: "0",
                      border: "0.5px solid #61dafb",
                    }}
                  />
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#ffffff",
                      fontWeight: "700",
                    }}
                  >
                    FINAL SCORE: {originalScore}
                  </h3>
                  {window.location.pathname === "/gameplay" ? (
                    <div className="game-section-btn">
                      <button onClick={handleBack}>
                        <span>Game Section</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Card>
          </Box>
        </div>
        <CustomizedSnackbars
          open={openSnackbar}
          message={message}
          severity={severity}
        />
      </div>
    </>
  );
}
