import React from "react";

function DeviceTablet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
     {...props}
      fill="none"
      viewBox="0 0 62 100"
    >
      <path
        fill={props.fill ? props.fill : "#000"}
        fillRule="evenodd"
        d="M5.225 2.004C3.424 3.102 1.511 5.155.975 6.566c-1.3 3.419-1.3 83.449 0 86.868.536 1.411 2.449 3.464 4.25 4.562 3.017 1.839 5.047 1.997 25.775 1.997 20.728 0 22.758-.158 25.775-1.997 1.801-1.098 3.714-3.151 4.25-4.562 1.3-3.419 1.3-83.449 0-86.868-.536-1.411-2.449-3.464-4.25-4.562C53.758.165 51.728.007 31 .007 10.272.007 8.242.165 5.225 2.004zM16.818 8.5C17.948 10.981 18.058 11 31 11s13.052-.019 14.182-2.5c.877-1.924 1.892-2.5 4.407-2.5C56.178 6 56 4.775 56 50c0 35.81-.194 41.051-1.571 42.429-2.348 2.348-44.51 2.348-46.858 0C6.194 91.051 6 85.81 6 50 6 4.775 5.822 6 12.411 6c2.515 0 3.53.576 4.407 2.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DeviceTablet;
