import React, { useState, useEffect } from "react";
import { ReactComponent as LeftBrain } from "./assets/left-brain.svg";
import { ReactComponent as Football } from "./assets/football.svg";
import { ReactComponent as PlayBtn } from "./assets/play.svg";
import { ReactComponent as Star } from "./assets/star.svg";
import { ReactComponent as SmallBrain } from "./assets/smallbrain.svg";
import { useNavigate } from "react-router-dom";
import { workoutItems } from "./DashboardData";
import DashboardTutorial from "../Tutorial/EnablicJourneyGame/DashboardTutorial";
import Mascot from "../common/Mascot/Mascot";

const MainDashboardContent = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/games");
  };

  return (
    // console.log("localSigninData", localSigninData?.data?.user?.first_name),
    (
      <div className="main">
        {windowWidth >= 950 ? <DashboardTutorial /> : ""}
        <div className="first">
          <div className="top">
            <div className="left">
              <h1>Play Everyday Free!</h1>
              <p>We provide Mind train Games Everyday to our users for Free.</p>
            </div>
            <div className="right" style={{ position: "relative" }}>
              <LeftBrain style={{ height: "90%" }} />
              <Football
                style={{
                  height: "50px",
                  position: "absolute",
                  bottom: 0,
                  left: "32%",
                }}
              />
            </div>
          </div>

          <div className="bottom">
            <div className="left">
              <span style={{ color: "#EFEFEF" }}>Basic</span>
              <div className="dot"></div>
              <span style={{ color: "#BDBDBD" }}>
                A daily taste of assorted cognitive tasks.
              </span>
            </div>
            <div className="dashboard-play-btn" id="playNow">
              <button onClick={handleClick}>
                <PlayBtn /> <span>PLAY NOW</span>
              </button>
            </div>
          </div>
        </div>

        <div className="second">
          <div className="top">
            <Star />
            Premium Brain Workouts
          </div>
          <div className="workouts">
            {workoutItems.map((item) => (
              <div className="workout-item" key={item.name}>
                <div className="icon" style={{ backgroundColor: item.color }}>
                  <SmallBrain />
                </div>
                <div className="text">
                  <div className="name">{item.name}</div>
                  <div className="description">{item.description}</div>
                </div>

                <button className="unlock">Unlock</button>
              </div>
            ))}
            <div className="lockGlass" />
          </div>
        </div>
        <Mascot
          textBubble={`Hi ${localSigninData?.data?.user?.first_name}, Good to see you here. Click on the Explore button`}
        />
      </div>
    )
  );
};

export default MainDashboardContent;
