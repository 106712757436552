import React, { Component } from "react";
import Board from "./Board";

import "./puzzle.css";

class Puzzle extends Component {
  render() {
    return (
      <div className="puzzle">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "64%",
              justifyContent: "center",
              background: "rgb(112 47 125 / 33%)",
              backdropFilter: "saturate(50%) blur(10px)",
              height: "500px",
              borderRadius: "12px",
            }}
          >
            <header className="puzzle-header">
              Slider Puzzle
              <Board />
            </header>
          </div>
        </div>
      </div>
    );
  }
}

export default Puzzle;
