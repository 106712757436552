import axios from "axios";
import React from "react";
import { API } from "../../../../backend";

function ProfileAvatarNavbar({ localSigninData }) {
  const [profileAvatar, setProfileAvatar] = React.useState();

  const avatars = [
    {
      id: 1,
      name: "unicorn",
      url: "https://images.emojiterra.com/google/android-10/512px/1f984.png",
    },
    {
      id: 2,
      name: "astronaut",
      url: "https://images.emojiterra.com/google/android-10/512px/1f468-1f680.png",
    },
    {
      id: 3,
      name: "fox",
      url: "https://images.emojiterra.com/google/android-11/512px/1f98a.png",
    },
    {
      id: 4,
      name: "health_worker",
      url: "https://images.emojiterra.com/google/android-11/512px/1f9d1-2695.png",
    },
    {
      id: 5,
      name: "firefighter",
      url: "https://images.emojiterra.com/google/android-12l/512px/1f9d1-1f692.png",
    },
  ];

  const getProfileAvatar = async () => {
    try {
      const data = await axios.get(
        `${API}/users/getbyid/${
          localSigninData && localSigninData.data.user._id
        }`,
        {
          "Content-Type": "application/json",
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        }
      );
      setProfileAvatar(data.data.data.profile_emoji);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProfileAvatar();
  }, []);

  return (
    <>
      {profileAvatar ? (
        <img
          src={
            profileAvatar
              ? avatars.filter((e) => e.name === profileAvatar)[0].url
              : "https://images.emojiterra.com/openmoji/v13.1/512px/2754.png"
          }
          alt="profile_avatar"
          style={{
            width: "30px",
          }}
        />
      ) : (
        <img
          src="https://images.emojiterra.com/openmoji/v13.1/512px/2754.png"
          alt="profile_avatar"
          style={{
            width: "30px",
          }}
        />
      )}
    </>
  );
}

export default ProfileAvatarNavbar;
