import React from "react";

function Callout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 770 544"
    >
      <rect width="770" height="366" fill="#D9D9D9" rx="62"></rect>
      <path
        fill="#D9D9D9"
        d="M662.132 502.326L496.566 367.953l107.738-70.869 57.828 205.242z"
      ></path>
    </svg>
  );
}

export default Callout;
