import React, { useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { handleChangeTestOver } from "../../redux/actions";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as RectangleBlue } from "./assets/Rectangle_blue.svg";
import { ReactComponent as GameRemote } from "./assets/game_remote.svg";
import axios from "axios";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import { API } from "../../backend";
import "./scorescreen.css";
import RingLoader from "react-spinners/RingLoader";
import Rating from "@mui/material/Rating";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import WinnerMascot from "../common/Mascot/WinnerMascot";
import GymMascot from "../common/Mascot/GymMascot";
import { ScoreScreenConfetti } from "../common/ConfettiEffect/ScoreScreenConfetti";
import DescriptionModal from "./DescriptionModal";
import { motion } from "framer-motion";
import backgroundImage from "./assets/results.jpg";

function createData(category, marks) {
  return { category, marks };
}

function splitCamelCase(s) {
  return s.split(/(?=[A-Z])/).join(" ");
}

function ScoreScreen() {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setIsLoading] = useState(true);
  const [maxScore, setMaxScore] = useState();
  const [finalScore, setFinalScore] = useState();
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const getGroupedResults = async () => {
    try {
      const responseData = await axios.get(
        `${API}/scorecards/getGroupedScoreById/${location.state.scoreCardId}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      setMaxScore(responseData.data.data.test.max_score);
      // console.log("responseData ", responseData.data.data.test.maxScore);
      // console.log("responseData ", responseData);

      let data = [];
      for (const cat in responseData.data.data.groupedData) {
        // console.log(
        //   cat,
        //   responseData.data.data.groupedData[cat]["actualScore"],
        //   "/",
        //   responseData.data.data.groupedData[cat]["totalScore"]
        // );
        data.push(
          createData(
            cat,
            `${responseData.data.data.groupedData[cat]["actualScore"]}` /
              `${responseData.data.data.groupedData[cat]["totalScore"]}`
          )
        );
      }
      setRowData(data);
      setFinalScore(responseData.data.data.finalScore);
      // setMaxScore(responseData.test.maxScore);
    } catch (err) {
      setOpen(true);
      setMessage(err.message);
      setSeverity("error");
    }
  };

  useEffect(() => {
    Promise.all([getGroupedResults()]).then(() => {
      setIsLoading(false);
    });
  }, []); //eslint-disable-line

  const handleClickRetake = () => {
    dispatch(handleChangeTestOver(false));

    navigate("/dashboard");
  };

  const screenWidthMatches = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {rowData.length !== 0 ? (
        finalScore / maxScore >= 0.5 ? (
          <>
            {/* <ConfettiEffect /> */}
            <ScoreScreenConfetti />
            <WinnerMascot
              textBubble={`Great Job! You performed well in the test.`}
            />
          </>
        ) : (
          <>
            <GymMascot
              textBubble={`“Fall seven times, stand up eight!” Keep practicing till you get better.`}
            />
          </>
        )
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
          position: "absolute",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "top left",
          backgroundSize: "cover",
          backgroundRepeat: "round",
        }}
      >
        <div
          style={{
            // display: "flex",
            // justifyContent: "center",
            // maxWidth: "1536px",
            width: "100%",
          }}
        >
          {/* <Box>
            <RectangleBlue className="rectangle" />
          </Box> */}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              // alignItems: screenWidthMatches ? "center" : "",
              // paddingLeft: screenWidthMatches ? "" : "",
            }}
          >
            <div className="enablicText1">
              <GameRemote />
              <div className="toggleEnablicText"> Enablic</div>
            </div>
            <Card
              sx={{
                margin: "0 auto",
                marginTop: "4vh",
                marginBottom: "4vh",
                padding: 1,
                width: screenWidthMatches ? "90%" : "80%",
                maxWidth: "942px",
                // maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
                background: "rgba(0, 0, 0, 0.7)",
                overflow: "visible",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  height: "50px",
                  marginTop: "10px",
                  marginBottom: "20px",
                  paddingBottom: "20px",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.19)",
                }}
              >
                <div className="testHeading">
                  Psychometric Tests Results
                  <div>
                    <motion.button
                      className="know-more-button"
                      onClick={() => setOpenModal(true)}
                      whileHover={{
                        fontWeight: 700,
                      }}
                    >
                      Know more
                    </motion.button>
                  </div>
                </div>
              </div>
              {rowData.length === 0 ? (
                <div
                  className="loaderCss"
                  style={{ height: "500px", background: "transparent" }}
                >
                  <RingLoader
                    color="#1abcf3"
                    cssOverride={override}
                    // loading={loading}
                    size={120}
                    speedMultiplier={1}
                  />
                </div>
              ) : (
                <>
                  <TableContainer
                    component={Paper}
                    sx={{ overflow: "visible", background: "rgba(0, 0, 0, 0.3)" }}
                  >
                    <Table
                      sx={{ minWidth: 250, }}
                      aria-label="simple table"
                    >
                      <TableBody>
                        {rowData.map((row) => (
                          <TableRow key={row.category}>
                            {screenWidthMatches ? (
                              <TableCell
                                className="categoryText"
                                sx={{
                                  fontFamily: "Circular Spotify Text",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                  lineHeight: "28px",
                                  color: "#efefef",
                                  width: "100%",
                                  maxWidth: "95%",
                                  // margin: "auto auto 30px 30px",
                                  paddingLeft: "30px",
                                  borderBottom: 0,
                                }}
                                component="th"
                                scope="row"
                              >
                                {row.category}
                              </TableCell>
                            ) : (
                              <TableCell
                                className="categoryText"
                                sx={{
                                  fontFamily: "Circular Spotify Text",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                  lineHeight: "28px",
                                  color: "#efefef",
                                  width: "100%",
                                  maxWidth: "95%",
                                  // margin: "auto auto 30px 30px",
                                  paddingLeft: "30px",
                                  borderBottom: 0,
                                }}
                                component="th"
                                scope="row"
                              >
                                {splitCamelCase(row.category)}
                              </TableCell>
                            )}
                            {screenWidthMatches ? (
                              <TableCell
                                align="right"
                                sx={{
                                  fontFamily: "Circular Spotify Text",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                  lineHeight: "28px",
                                  color: "#3A3A39",
                                  width: "100%",
                                  maxWidth: "95%",
                                  margin: "auto auto 30px 30px",
                                  borderBottom: 0,
                                  // padding: "16px 64px 16px 0px",
                                }}
                              >
                                <span style={{ display: "flex" }}>
                                  {isNaN(row.marks) ? (
                                    <></>
                                  ) : (
                                    <Rating
                                      name="half-rating-read"
                                      defaultValue={row.marks * 5}
                                      precision={0.5}
                                      readOnly
                                    />
                                  )}
                                </span>
                                {/* {row.marks} */}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="right"
                                sx={{
                                  fontFamily: "Circular Spotify Text",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                  lineHeight: "28px",
                                  color: "#3A3A39",
                                  width: "100%",
                                  maxWidth: "95%",
                                  margin: "auto auto 30px 30px",
                                  borderBottom: 0,
                                  padding: "16px 64px 16px 0px",
                                }}
                              >
                                <span style={{ display: "flex" }}>
                                  {isNaN(row.marks) ? (
                                    <></>
                                  ) : (
                                    <Rating
                                      name="half-rating-read"
                                      defaultValue={row.marks * 5}
                                      precision={0.5}
                                      readOnly
                                    />
                                  )}
                                </span>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="totalScore">
                    <div
                      style={{
                        marginRight: screenWidthMatches ? "0px" : "30px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: screenWidthMatches ? "20px" : "inherit",
                        borderRadius: "4px",
                      }}
                    >
                      Total Score:
                      <span style={{ marginLeft: "10px" }}>
                        {isNaN(finalScore / maxScore) ? (
                          <></>
                        ) : (
                          <Rating
                            name="half-rating-read"
                            defaultValue={(finalScore / maxScore) * 5}
                            precision={0.005}
                            readOnly
                            size="large"
                          />
                        )}
                      </span>
                      {/* {(finalScore / maxScore) * 5} */}
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="comments">
                      Press continue to check your report
                    </div>
                    <div className="home-play-btn">
                      <button type="submit" onClick={handleClickRetake}>
                        <span>Continue</span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </Box>
        </div>
      </div>
      <DescriptionModal
        open={openModal}
        setOpen={setOpenModal}
        data={rowData}
        splitCamelCase={splitCamelCase}
      />
      <CustomizedSnackbars open={open} message={message} severity={severity} />
    </>
  );
}

export default ScoreScreen;
