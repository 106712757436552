import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";
import { API } from "./backend";

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (
      err.response?.status === 403 &&
      err.response?.data?.error === "token has been expired"
    ) {
      try {
        const jwt = JSON.parse(localStorage.getItem("jwt"));
        const getRefreshToken = jwt.data.refreshToken;
        const tokenData = await axios.post(`${API}/users/refreshToken`, {
          refreshToken: getRefreshToken,
        });

        jwt.data.token = tokenData.data.data.token;

        localStorage.setItem("jwt", JSON.stringify(jwt));
        err.config.headers["token"] = jwt.data.token;
        return axios.request(err.config);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("jwt");
        window.location.href = "/";
      }
    } else {
      return Promise.reject(err);
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
