import React, { useState, useEffect } from "react";
import { GameContentHeader } from "../../common/Games/GameContentHeader";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import axios from "axios";
import moment from "moment";
import { API } from "../../../backend";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, country, age, gamesPlayed, level, abilities) {
  // console.log(abilities);
  return {
    name,
    country,
    age,
    gamesPlayed,
    level,
    abilities,
  };
}
function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& th, td": { borderBottom: "unset", fontWeight: 700, fontSize: "1rem" } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.country === undefined ? "--" : row.country}</TableCell>
        <TableCell align="right">{row.age}</TableCell>
        <TableCell align="right">{row.gamesPlayed}</TableCell>
        <TableCell align="right">{row.level === -1 ? "--" : row.level}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "1px solid rgb(163,143,169)", }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div"
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                  borderBottom: "1px solid rgb(163,143,169)",
                  paddingBottom: "6px",
                }}>
                Ability Scores
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ "& th, td": { fontSize: "1rem", borderBottom: "1px solid rgb(163,143,169)", width: "50%", } }}>
                    <TableCell>Attribute</TableCell>
                    <TableCell>Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.abilities.map((historyRow) => (
                    <TableRow key={historyRow.score} sx={{ "& th, td": { fontWeight: 400, border: "none", width: "50%", } }}>
                      <TableCell component="th" scope="row">
                        {historyRow.attribute}
                      </TableCell>
                      <TableCell>{historyRow.score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    country: PropTypes.number.isRequired,
    gamesPlayed: PropTypes.number.isRequired,
    age: PropTypes.number.isRequired,
    abilities: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.number.isRequired,
        score: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
  }).isRequired,
};

export const UserAnalyticsContent = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [rowData, setRowData] = useState([]);
  const rows = rowData;

  useEffect(() => {
    (async () => {
      try {
        const UserAnalyticsData = await axios.get(
          `${API}/users/getAllUsersAnalytics`,
          {
            "Content-Type": "application/json",
            headers: {
              token: `${localSigninData && localSigninData.data.token}`,
            },
          }
        );
        let dataArr = [];

        UserAnalyticsData.data.data.forEach((data, i) => {
          const abilityArr = [];
          Object.entries(data.ability_scores).forEach((data, i) => {
            const obj = {};
            obj["attribute"] = data[0];
            obj["score"] = data[1];
            // console.log(obj);
            abilityArr.push(obj);
            // console.log(data[0]," ",data[1]);
          });
          dataArr.push(
            createData(
              data.first_name + " " + data.last_name,
              data.country,
              moment().diff(
                moment(`${data.dob}`).utc().format("YYYY-MM-DD"),
                "years"
              ),
              data.game_statistics.total_games_played,
              data.current_journey_cycle,
              abilityArr
            )
          ); //name
        });
        // console.log("dataArr ", dataArr);
        setRowData(dataArr);
      } catch (err) { }
    })();
  }, []);
  return (
    <div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GameContentHeader />
        <div className="insightsSection">
          <h1 className="insightsText">User Analytics</h1>
          <div
            style={{
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              width: "100%",
              borderRadius: "10px"
              // minWidth: "900px",
            }}
            id="selectInsights"
          >
            <TableContainer
              component={Paper}
              sx={{
                justifyContent: "center",
                background: "#c7b1cd",
                overflow: "visible",
              }}
            >
              <Table aria-label="collapsible table">
                <TableHead style={{ background: "#281742" }}>
                  <TableRow sx={{ "& th, td": { fontSize: "1.2rem" } }}>
                    <TableCell style={{ color: "#fff" }}>Name</TableCell>
                    <TableCell style={{ color: "#fff" }} align="right">
                      Country
                    </TableCell>
                    <TableCell style={{ color: "#fff" }} align="right">
                      Age
                    </TableCell>
                    <TableCell style={{ color: "#fff" }} align="right">
                      Games&nbsp;Played
                    </TableCell>
                    <TableCell style={{ color: "#fff" }} align="right">
                      Level
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <Row key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
