import React from "react";

// components
import { Row } from "./Row";

export const Grid = ({
  guesses,
  setGuesses,
  currentGuess,
  setCurrentGuess,
  turn,
  setTurn,
}) => {
  return (
    <div>
      {guesses.map((g, i) => {
        if (turn === i) {
          return (
            <Row
              key={i}
              currentGuess={currentGuess}
              setCurrentGuess={setCurrentGuess}
            />
          );
        }
        return <Row key={i} guess={g} setGuesses={setGuesses} />;
      })}
    </div>
  );
};
