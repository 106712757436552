import React, { useState } from "react";
import SideNavBar from "../../../components/SideNavBar/SideNavBar";
import { ReactComponent as EllipseDashboard } from "../Games/assets/ellipseDashboard.svg";
import { ReactComponent as Ellipseblue } from "../Games/assets/ellipseBlue.svg";
import { GamePlay } from "./GamePlay";
import "./game.css";

export const GameSection = () => {
  const [sidebarToggled, setSidebarToggled] = useState(false);

  return (
    <div>
      <div className="webView">
        <div className="ellipseDashboard">
          <EllipseDashboard />
        </div>
        <div className="ellipseBlue">
          <Ellipseblue />
        </div>
        <SideNavBar setSidebarToggled={setSidebarToggled} />
        <GamePlay sidebarWidth={!sidebarToggled ? 65 : 240} />
      </div>
      <div className="mobileView">
        <GamePlay />
      </div>
    </div>
  );
};
