import React from "react";
import { ReactComponent as QuestionIcon } from "../../../common/Games/assets/question.svg";
import PsychometricAssessment from "../../../common/Games/assets/PsychometricAssessment.png";
import IQAssesment from "../../../common/Games/assets/IQAssesment.png";
import EmotionalIntelligence from "../../../common/Games/assets/EmotionalIntelligence.png";
import BrainGym from "../../../common/Games/assets/BrainGym";

//This is a central database for the Assessment details

export const InsightsData = {
  EQ: {
    name: "EmotionalQuotient",
    title: "Emotional Quotient",
    type: ["EQ"],
  },

  IQ: {
    name: "IntelligenceQuotient",
    title: "Intelligence Quotient",
    type: ["IQ"],
  },

  Psychometric: {
    name: "Psychometric",
    title: "Psychometric",
    type: ["Psychometric"],
  },
  Level1: {
    name: "Level1",
    title: "Level1",
    type: ["Level1"],
  },
  Level2: {
    name: "Level2",
    title: "Level2",
    type: ["Level2"],
  },
  Current: {
    name: "Current",
    title: "Current",
    type: ["Current"],
  },
  History: {
    name: "History",
    title: "History",
    type: ["History"],
  },
};
