import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const GenrePieChart = (props) => {
  const data = {
    labels: props.genreLabel,
    datasets: [
      {
        data: props.individualGenreScore,
        backgroundColor: props.colorArray,
        borderColor: ["rgb(233 233 233 / 23%)"],
        borderWidth: 3,
      },
    ],
    options: {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            color: "white",
            usePointStyle: true,
            font: {
              size: window.innerWidth < 300 ? 10 : 12,
            },
          },
        },
      },
    },
  };
  // const data = {
  //   labels: props.genreLabel,
  //   datasets: [
  //     {
  //       data: props.scoreData,
  //       backgroundColor: ["aqua", "orange", "grey"],
  //     },
  //   ],
  // };
  return (
    // console.log("GenrePieChart props ", props),
    (
      // console.log("GenrePieChart ", data),
      <div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pie data={data} options={data.options}></Pie>
        </div>
      </div>
    )
  );
};
