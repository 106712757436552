import React, { useState } from "react";
import "./TopNavBar.css";
import { ReactComponent as GameRemote } from "./assets/game_remote.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const TopNavBar = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"))?.data?.token;
  const navigate = useNavigate();

  const [, setSelectedIndex] = useState();
  const onClickHome = () => {
    setSelectedIndex(0);
    navigate("/");
  };
  const onClickBrainGames = () => {
    setSelectedIndex(1);
  };
  const onClickOurScience = () => {
    setSelectedIndex(2);
  };
  const onClickAlreadyAMember = () => {
    navigate("/signin");
  };
  const onClickNewHere = () => {
    navigate("/signup");
  };
  return (
    // console.log("window.location", window.location.pathname),
    // console.log("selectedIndex", selectedIndex),
    (
      <div>
        <div className="topNavBarMain">
          <div className="enablicTextHeader" onClick={onClickHome}>
            <GameRemote />
            Enablic
          </div>
          <div
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <motion.div
              animate={{
                boxShadow:
                  window.location.pathname === "/" ? "0px 5px #FBC9CE" : "none",
              }}
              className="navBarText"
              onClick={onClickHome}
            >
              Home
            </motion.div>
            <motion.div
              animate={{
                boxShadow:
                  window.location.pathname === "/braingames"
                    ? "0px 5px #FBC9CE"
                    : "none",
              }}
              className="navBarText"
              onClick={onClickBrainGames}
            >
              Brain Games
            </motion.div>
            <motion.div
              className="navBarText"
              animate={{
                boxShadow:
                  window.location.pathname === "/ourscience"
                    ? "0px 5px #FBC9CE"
                    : "none",
              }}
              onClick={onClickOurScience}
            >
              Our Science
            </motion.div>
            {!localSigninData ? (
              <>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.1 },
                  }}
                  className="navBarTextAlreadyAMember alreadyAMember"
                  onClick={onClickAlreadyAMember}
                >
                  Already a member?
                </motion.div>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.1 },
                  }}
                  className="navBarTextAlreadyAMember newHere"
                  onClick={onClickNewHere}
                >
                  <span>New Here?</span>
                </motion.div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    )
  );
};
