import React, { useEffect, useState } from "react";
import useWordle from "./hooks/useWordle";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubmitScore } from "../SubmitScore/SubmitScore";

import { gameData } from "../GameData/gameData";
import { API } from "../../../../backend";
import axios from "axios";
import {
  handleChangeStartGame,
} from "../../../../redux/actions";

// components
import { Grid } from "./Grid";
import { Keypad } from "./Keypad";
// import Confetti from "react-confetti";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

export const Wordle = ({ solution, meaning }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentSchedule } = useSelector((state) => state);
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState(false);
  const enablicJourneyScore = true;
  const {
    turn,
    setTurn,
    currentGuess,
    // setCurrentGuess,
    guesses,
    // setGuesses,
    isCorrect,
    setIsCorrect,
    usedKeys,
    setUsedKeys,
    handleKeyup,
    dispSnackbarMsg,
  } = useWordle(solution);
  // console.log("usedKeys", usedKeys);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);

    if (dispSnackbarMsg) {
      // console.log("dispSnackbarMsg", dispSnackbarMsg);
      setOpenSnackbar(true);
    } else {
      setOpenSnackbar(false);
    }
    if (isCorrect) {
      // console.log("iscorrect from isCorrect ", isCorrect);
      // setMsg("Congratulations you have won the game.");
      setWinner(true);
      setTurn(-1);

      setGameOver(true);
      setIsCorrect(false);
      submitScoreToBackend();
      window.removeEventListener("keyup", handleKeyup);
    } else if (turn === 6 && !isCorrect) {
      setTurn(-1);

      setWinner(false);
      setGameOver(true);
      setIsCorrect(false);
      submitScoreToBackend();
      window.removeEventListener("keyup", handleKeyup);
    }
    function submitScoreToBackend() {
      axios
        .get(`${API}/games/getByName/${gameData["Wordle"].name}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          // console.log("val", val.data.data._id);
          if (window.location.pathname === "/gameplay") {
            SubmitScore(6 - turn, turn, val.data.data._id, false);
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              7 - turn,
              turn,
              val.data.data._id,
              enablicJourneyScore,
              currentSchedule
            );
            setTimeout(() => {
              dispatch(handleChangeStartGame(false));
            }, 1000);
          }
        })
        .catch((err) => {
          console.error("err ", err);
        });
    }
    return () => {
      window.removeEventListener("keyup", handleKeyup);
      // setDispMsg(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turn, isCorrect, handleKeyup, dispSnackbarMsg]);

  const handleBack = () => {
    navigate("/games");
  };
  return (
    <div>
      {/* <div>solution - {solution}</div>
      <div>Current Guess - {currentGuess}</div> */}
      {gameOver ? (
        winner ? (
          <>
            <ConfettiEffect />
            <WinnerMascot
              textBubble={`Good Job ${localSigninData?.data?.user?.first_name}! Let's play something else.`}
            />
          </>
        ) : (
          <>
            <GymMascot
              textBubble={`One only learns from their mistakes. It’s okay, we can try again!`}
            />
          </>
        )
      ) : (
        <></>
      )}
      {!gameOver ? (
        <>
          <Grid
            guesses={guesses}
            // setGuesses={setGuesses}
            currentGuess={currentGuess}
            // setCurrentGuess={setCurrentGuess}
            turn={turn}
          // setTurn={setTurn}
          />
          <Keypad usedKeys={usedKeys} setUsedKeys={setUsedKeys} />
        </>
      ) : !winner ? (
        <div>
          <h1
            style={{
              color: "#ffffff",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "40vh",
              width: "100%",
            }}
          >
            The correct solution is {solution}.
          </h1>
          <div className="home-play-btn">
            <button onClick={handleBack}>
              <span>Game Section</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <h1
            style={{
              color: "#ffffff",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: "40vh",
              width: "100%",
            }}
          >
            Congratulations, you have won the game
          </h1>
          <div className="home-play-btn">
            <button onClick={handleBack}>
              <span>Game Section</span>
            </button>
          </div>
        </>
      )}

      {/* <Modal
        open={open}
        onClose={handleClose}
        disableRestoreFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {msg}
          </Typography>
          <Button
            onClick={handleClickNewGame}
            color="secondary"
            variant="contained"
            size="large"
            type="submit"
          >
            New Game
          </Button>
        </Box>
      </Modal> */}
      <CustomizedSnackbars
        open={openSnackbar}
        message={dispSnackbarMsg}
        severity={"warning"}
      />
    </div>
  );
};
