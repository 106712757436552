import React from "react";

export const GooglePlay = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "95%",
          padding: "12px 0px",
          height: "80vh",
          marginTop: "15px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "95%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(255, 255, 255)",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          To play the games download our App on
        </div>
        <div
          style={{
            width: "95%",
            padding: "12px 0px",
            height: "20vh",
            marginTop: "15px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={"/assets/google_play_logo.svg"} alt="Google Play" />
        </div>
      </div>
    </div>
  );
};
