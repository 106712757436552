import React from "react";
import HeartIcon from "./HeartIcon";

export default function Heart(props) {
  return (
    <div
      style={{
        ...props,
        position: "relative",
      }}
    >
      <div
        style={{
          //   background: "red",
          width: "18px",
          height: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "11px",
          left: "19px",
          fontWeight: 400,
          fontSize: "20px",
          color:"#ffffff"
        }}
      >
        {props.life}
      </div>
      <HeartIcon width="100%" height="100%" />
    </div>
  );
}
