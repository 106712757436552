import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomizedSnackbars from "../../common/Snackbar/CustomizedSnackbars";
import { API } from "../../../backend";
import { Bar } from "react-chartjs-2";
import "./PerformanceSection.css";

function splitCamelCase(s) {
  return s.split(/(?=[A-Z])/).join(" ");
}

export const HorizontalChart = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [genreLabels, setGenreLabels] = useState([]);
  const [genreLabelValues, setGenreLabelValue] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  //fetch the game statistics data
  useEffect(() => {
    
      (async () => {
        try {
        const genreDataResponse = await axios.get(
          `${API}/users/getStatistics/${localSigninData?.data?.user?._id}`,
          {
            "Content-Type": "application/json",
            headers: {
              token: `${localSigninData && localSigninData.data.token}`,
            },
          }
        );
        // add space between camel casing string
        const newArr = Object.keys(
          genreDataResponse.data.data.ability_scores
        ).map((data) => splitCamelCase(data));
        setGenreLabels(newArr);
        setGenreLabelValue(
          Object.values(genreDataResponse.data.data.ability_scores)
        );
      } catch (err) {
        setOpenSnackbar(true);
        setMessage(err.message);
        setSeverity("error");
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dataHorBar = {
    labels: genreLabels,
    datasets: [
      {
        axis: "y",
        label: "Brain Attributes",
        data: genreLabelValues,
        fill: false,
        backgroundColor: [
          "rgba(255, 99, 132, 0.4)",
          "rgba(255, 159, 64, 0.4)",
          "rgba(255, 205, 86, 0.4)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(54, 162, 235, 0.4)",
          "rgba(153, 102, 255, 0.4)",
          "rgba(201, 203, 207, 0.4)",
        ],
        barThickness: 13,
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="horizontalChart">
      <Bar
        options={{
          indexAxis: "y",
          scales: {
            x: {
              display: false,
              max: 160,
              min: 0,
            },
          },
        }}
        data={dataHorBar}
      />
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};
