import React from "react";
import { ReactComponent as LeftBrain } from "../assets/left-brain.svg";
import Dropdown from "../../common/Dropdown/Dropdown";
import "./RightBarContent.css";
import { motion } from "framer-motion";
import ProgressBarCircular from "./Components/ProgressBarCircular";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleExplore } from "../../../redux/actions";
import ProfileAvatarNavbar from "../../common/Games/assets/ProfileAvatarNavbar";

const RightBarContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exploreThePlatform } = useSelector((state) => state);
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  return (
    <div className="right-bar">
      <div className="top">
        {/* <Notification /> */}
        <div className="navbar-profile-avatar">
          <ProfileAvatarNavbar localSigninData={localSigninData} />
        </div>
        <div className="right">
          <div className="text">
            <div className="name">
              {localSigninData?.data?.user?.first_name}{" "}
              {localSigninData?.data?.user?.last_name}
            </div>
            <div className="view-profile" onClick={() => navigate("/profile")}>
              View Profile
            </div>
          </div>
          {/* <img src={"/assets/user.png"} width={45} height={45} alt="profile" /> */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
            style={{ cursor: "pointer" }}
          >
            <Dropdown />
          </motion.div>
        </div>
      </div>

      <div className="middle-card">
        <LeftBrain
          style={{
            position: "absolute",
            bottom: 0,
            right: "-30%",
            height: "180px",
            transform: "translateY(35%) rotateZ(-30deg)",
          }}
        />
        <div className="text">Take a Quick Tour</div>
        <div className="sub-text">To have a walkthrough, how this works</div>
        <div className="explore">
          <motion.button
            whileHover={{
              borderColor: "#7c90f0",
              boxShadow: "0px 0px 10px #7c90f0",
            }}
            onClick={() => {
              if (window.location.pathname === "/dashboard/enablicJourney") {
                navigate("/dashboard");
                setTimeout(() => {
                  dispatch(handleExplore(!exploreThePlatform));
                }, 3000);
              } else {
                dispatch(handleExplore(!exploreThePlatform));
              }
            }}
          >
            EXPLORE
          </motion.button>
        </div>
      </div>

      <div className="last-card">
        <ProgressBarCircular />
      </div>
    </div>
  );
};

export default RightBarContent;
