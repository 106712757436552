import React from "react";
import PropTypes from "prop-types";

function Button({ children, isClicked }) {
  return (
    <div className="home-play-btn">
      <button className="game-section-return-btn" onClick={isClicked}>{children}</button>
    </div>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  isClicked: PropTypes.func,
};

export default Button;
