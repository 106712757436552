import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Steps } from "intro.js-react";
import { useNavigate } from "react-router-dom";
import {
  handleExplore,
} from "../../../redux/actions";
import "intro.js/introjs.css";
import "./Tutorial.css";

function GameSectionTutorialMobile() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickJourney = () => {
    navigate("/dashboard/enablicJourney");
  };
  const [enabled, setEnabled] = useState(true);
  const [initialStep] = useState(0);
  const [tutCompleted, setTutCompleted] = useState(false);
  const [tutExit, setTutExit] = useState(false);

  const onExit = () => {
    // console.log("gamesection exit");
    setTutExit(true);
    if (tutCompleted) {
      dispatch(handleExplore(false));
    }
    if (!tutCompleted && tutExit) {
      dispatch(handleExplore(false));
    }
    // if (tutExit) {
    //   dispatch(handleExplore(false));
    // }
    setEnabled(false);
  };

  const steps = [
    {
      element: "#playTheGameMobile",
      intro: "Click to play the game",
      //   position: "left",
    },
    {
      element: "#selectCategoryMobile",
      intro: "Choose the Categories",
    },
  ];

  return (
    // console.log("gamesectiontutorialMobile"),
    (
      <div>
        <Steps
          enabled={enabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
          // exitOnEsc={false}
          // exitOnOverlayClick={false}
          // onBeforeExit={() => {
          //   console.log("journey onBeforeExit is called");
          //   //   dispatch(handleChangeProgressItemTutorial(false));
          //   //   dispatch(handleChangeEnablicJourneyTutorial(false));
          // }}
          onComplete={() => {
            // console.log("gameSection completed");
            handleClickJourney();
            setTutCompleted(true);
            //   dispatch(handleChangeProgressItemTutorial(false));
            //   dispatch(handleChangeEnablicJourneyTutorial(false));
          }}
          options={{
            hideNext: false,
            doneLabel: "Next Page",
            showBullets: false,
            tooltipClass: "Tuts",
            exitOnEsc: false,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
          }}
        />
      </div>
    )
  );
}

export default GameSectionTutorialMobile;
