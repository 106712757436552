import React from "react";
import { GameContentHeader } from "../common/Games/GameContentHeader";
import { ReactComponent as Ellipse } from "../common/Games/assets/gameSection.svg";
import ProfileForm from "./ProfileForm";
// import Profile from "../common/Games/assets/Profile";
import { API } from "../../backend";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { RingLoader } from "react-spinners";

const ProfileContent = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(
    dayjs(`${dayjs().subtract(2, "year").format()}`)
  );
  const [initialValues, setInitialValues] = React.useState({
    profileAvatar: "",
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    country: "",
  });

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const getUserDetails = async () => {
    try {
      dayjs.extend(utc);
      const data = await axios.get(
        `${API}/users/getbyid/${
          localSigninData && localSigninData.data.user._id
        }`,
        {
          "Content-Type": "application/json",
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        }
      );
      // console.log(data.data.data);
      const userData = {
        profileAvatar: data.data.data.profile_emoji,
        firstName: data.data.data.first_name,
        lastName: data.data.data.last_name,
        email: data.data.data.email,
        // birthDate: data.data.data.dob.split('T')[0],
        country: data.data.data.country,
      };
      // console.log("response data: ", userData);
      setInitialValues((prevState) => ({ ...prevState, ...userData }));
      setValue(dayjs(data.data.data.dob).local().format("MM/DD/YYYY"));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getUserDetails();
    // console.log("initial values", initialValues);
  }, []);

  return (
    <div>
      <div className="ellipseGameSection">
        <Ellipse />
      </div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GameContentHeader />
        <div
          className="insightsSection"
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
            boxSizing: "border-box",
            width: "98%",
          }}
        >
          <h1 className="insightsText">Your Profile</h1>
          {loading ? (
            <div
              style={{
                margin: "auto",
                width: "100%",
                height: "490px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RingLoader
                color="#1abcf3"
                cssOverride={override}
                loading={loading}
                size={120}
                speedMultiplier={1}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "2.5rem",
                width: "100%",
              }}
            >
              <div className="form-container-web">
                {/* <div
                  className="profileIcon"
                  style={{
                    border: "1px solid #ffd600",
                    borderRadius: "50%",
                  }}
                >
                  <Profile height={125} width={125} />
                </div> */}
                <div>
                  <ProfileForm
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    value={value}
                    setValue={setValue}
                    setLoading={setLoading}
                  />
                </div>
              </div>
              <div className="additional-section">
                <div className="additional-details">
                  <h3>More to come in this space</h3>
                </div>
                <div className="additional-details">
                  <h3>More to come in this space</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileContent;
