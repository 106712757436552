import { API } from "../../backend.js";
import axios from "axios";

export const signup = async (user) => {
  // console.log("user", user);
  try {
    const response = await axios.post(`${API}/users/register`, user, {
      "Content-Type": "application/json",
      header: "Access-Control-Allow-Origin",
    });
    // console.log("await response.data", response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
  
};
