import React, { useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import MobileViewNavBar from "../MobileViewNavBar/MobileViewNavBar";
import "./Insights.css";
import { InsightsContent } from "./Components/InsightsContent";
import { InsightsContentMobile } from "./Components/InsightsContentMobile";

export const Insights = () => {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  return (
    <div>
      <div className="webView">
        <SideNavBar setSidebarToggled={setSidebarToggled} />
        <InsightsContent sidebarWidth={!sidebarToggled ? 65 : 240} />
      </div>
      <div className="insights-mobile-view">
        <MobileViewNavBar />
        <InsightsContentMobile/>
      </div>
    </div>
  );
};
