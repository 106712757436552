import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { ReactComponent as PlaySmall } from "../assets/play-small.svg";
import Right from "../../../assets/icons/Right";
import { ProgressItemsTutorial } from "../../Tutorial/EnablicJourneyGame/ProgressItemsTutorial";
import { useSelector } from "react-redux";

const ProgressItem = ({
  played,
  item,
  index,
  onClick,
  journeyDataProgress,
}) => {
  const { exploreThePlatform } = useSelector((state) => state);
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  // console.log("played ", played);
  return (
    <>
      {windowWidth >= 950 && exploreThePlatform? <ProgressItemsTutorial /> : ""}
      <div onClick={onClick} className="item" style={item?.style}>
        <div className="left">
          <div className="title" id="gameName">
            {item?.title}
          </div>
          <div className="subtitle" id="playedOrNot">
            {played ? "Played" : item?.subtitle}
          </div>
        </div>

        {played ? <Right width={32} height={32} /> : item?.icon}
      </div>

      <div className={`progress-stat ${index === 0 ? "open" : ""}`}>
        {index === 0 && (
          <>
            <div className="left">
              <p className="your-progress">Your Progress</p>
              <p
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
                className="now-playing"
              >
                <PlaySmall /> Now Playing
              </p>
            </div>
            <div className="right" id="progressPercent">
              <CircularProgress
                value={journeyDataProgress}
                variant="determinate"
                style={{
                  color: "#FFD600",
                  height: "calc(100% + 7.2px)",
                  width: "calc(100% + 7.2px)",
                  position: "absolute",
                }}
              />
              <div className="percentage">{journeyDataProgress}%</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProgressItem;
