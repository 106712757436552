import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { InputAdornment, IconButton, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Google, Apple, Visibility, VisibilityOff } from "@mui/icons-material";
import { signup } from "./index";
import { authenticate } from "../Signin";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleSignin } from "../../redux/actions";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Copyright } from "../../assets/icons/copyright.svg";
import { ReactComponent as Ecllipse1 } from "../../assets/icons/ellipse1.svg";
import { ReactComponent as Ecllipse2 } from "../../assets/icons/ellipse2.svg";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import RingLoader from "react-spinners/RingLoader";
import { countries } from "./data/countries";
import "./Signup.css";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const didRedirect = () => {
    // console.log(JSON.parse(localStorage.getItem("jwt")));
    if (JSON.parse(localStorage.getItem("jwt"))) {
      navigate("/psychometricTest");
    }
  };
  didRedirect();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let [loading, setLoading] = useState(false);

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirm = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleSignInRedirect = () => {
    navigate("/signin");
  };
  const [value, setValue] = React.useState(
    dayjs(`${dayjs().subtract(2, "year").format()}`)
  );
  const date = `${dayjs(`${dayjs().subtract(2, "year").format()}`).format(
    "YYYY-MM-DD"
  )}`;
  // console.log("date", date);
  // console.log("back date: ", dayjs().subtract(2, 'year').format())

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      // username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirm: "",
      birthDate: "",
      country: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "First Name must have alteast 3 characters")
        .required("This field is required"),
      lastName: Yup.string().min(3, "Last Name must have 3 characters"),
      //   .required("This field is required"),
      // username: Yup.string()
      //   .min(3, "Username must have 3 characters")
      //   .required("This field is required"),
      email: Yup.string()
        .email("Please enter a valid Email Id")
        .required("This field is required"),
      password: Yup.string()
        .required("No password provided")
        .min(8, "Password is too short - should be 8 chars minimum."),
      // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      confirm: Yup.string()
        .required("This field is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      birthDate: Yup.date()
        .typeError("please enter a valid date")
        .required()
        .min("1930-01-01", "Date is too early")
        .max(date, "Invalid Date of Birth"),
      country: Yup.string()
        .required("Please select your country")
        .min(2, "Please select your country"),
    }),
    onSubmit: (values) => {
      // console.log("values", values);
      setLoading(true);
      const data = signup({
        // username: values.username.toLowerCase(),
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email.toLowerCase(),
        password: values.password,
        country: values.country,
        dob: dayjs(`${values.birthDate}`, "MM/DD/YYYY").toISOString(),
      });
      data
        .then((val) => {
          console.log("val", val);
          if (val.error) {
            setLoading(false);
            setOpen(true);
            setSeverity("error");
            setMessage(`${data.error}`);
          } else {
            setLoading(false);
            authenticate(
              {
                ...val,
              },
              () => {
                didRedirect();
                dispatch(handleSignin(val));
              }
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setOpen(true);
          setSeverity("error");
          setMessage("SignUp request failed");
        });
    },
  });
  // console.log("formik.errors", formik.errors);

  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <div className="ellipse1">
        <Ecllipse1 />
      </div>
      <div className="ellipse2">
        <Ecllipse2 />
      </div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      {loading ? (
        <>
          <div className="loaderCss">
            <RingLoader
              color="#1abcf3"
              cssOverride={override}
              loading={loading}
              size={120}
              speedMultiplier={1}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "95%",
              height: "50%",
              maxWidth: "458px",
              minWidth: "340px",
              borderRadius: "15px",
              overflow: "hidden",
              background: "red",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Card
              sx={{
                // padding: 1,
                width: "100%",
                height: "50%",

                maxHeight: "calc(95vh - 68px)",
                justifyContent: "center",
                overflowY: "auto",
                borderRadius: "15px",
                // "&::-webkit-scrollbar": {
                //   display: "none",
                // },
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: 900,
                    fontFamily: "Circular Spotify Text",
                    lineHeight: "56px",
                    fontSize: "30.5px",
                  }}
                  variant="h3"
                  component="h3"
                >
                  Your Mind Matters
                </Typography>
                <Typography
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontWeight: 400,
                    fontFamily: "Circular Spotify Text",
                    lineHeight: "24px",
                    fontSize: "18.5px",
                    marginTop: "6px",
                    color: "#81899A",
                  }}
                  variant="h3"
                  component="h3"
                >
                  Sign up to train your brain for free
                </Typography>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      width: "95%",
                      maxwidth: "40ch",
                      height: "50%",
                    },
                    margin: "27px auto 0",
                    maxWidth: "400px",
                  }}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <TextField
                    id="firstName"
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    required
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText
                      style={{ marginTop: "0" }}
                      error
                    >{`${errors.firstName}`}</FormHelperText>
                  )}
                  <TextField
                    id="lastName"
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText
                      style={{ marginTop: "0" }}
                      error
                    >{`${errors.lastName}`}</FormHelperText>
                  )}
                  {/* <TextField
              id="username"
              label="User Name"
              fullWidth
              variant="outlined"
              required
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
            />
            {touched.username && errors.username && (
              <FormHelperText
                style={{ marginTop: "0" }}
                error
              >{`${errors.username}`}</FormHelperText>
            )} */}
                  <TextField
                    id="Email-Id"
                    label="Email"
                    variant="outlined"
                    required
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      style={{ marginTop: "0" }}
                      error
                    >{`${errors.email}`}</FormHelperText>
                  )}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        label="Birth Date"
                        inputFormat="MM/DD/YYYY"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => {
                          values.birthDate = params.inputProps.value;
                          return (
                            <TextField
                              style={{ marginTop: "8px" }}
                              {...params}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={params.inputProps.value}
                            />
                          );
                        }}
                      />
                    </Stack>
                    {touched.birthDate && errors.birthDate && (
                      <FormHelperText
                        style={{ marginTop: "0" }}
                        error
                      >{`${errors.birthDate}`}</FormHelperText>
                    )}
                  </LocalizationProvider>
                  <Autocomplete
                    id="country"
                    name="country"
                    // value={values.country}
                    // inputValue={values.country}
                    sx={{ width: 300, display: "inline-flex" }}
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    onBlur={handleBlur}
                    onChange={(e, value) => {
                      // console.log(value);
                      setFieldValue(
                        "country",
                        value !== null ? value.label : ""
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                  {touched.country && errors.country && (
                    <FormHelperText
                      style={{ marginTop: "0" }}
                      error
                    >{`${errors.country}`}</FormHelperText>
                  )}
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClick} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    required
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                    >{`${errors.password}`}</FormHelperText>
                  )}
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickConfirm} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    required
                    name="confirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm}
                  />
                  {touched.confirm && errors.confirm && (
                    <FormHelperText error>{`${errors.confirm}`}</FormHelperText>
                  )}
                  <Typography
                    sx={{
                      fontSize: "15px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      fontWeight: 400,
                      fontFamily: "Circular Spotify Text",
                      lineHeight: "24px",
                      color: "#3A3A39",
                      "& a": {
                        textDecoration: "none",
                      },
                    }}
                    variant="h3"
                    component="h3"
                  >
                    <div style={{ marginTop: "23px" }}>
                      By signing up, you agree to our{" "}
                      <a href="#!">Terms of services</a> and{" "}
                      <a href="#!">Privacy policy</a>
                    </div>
                  </Typography>

                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      style={{
                        fontFamily: "Circular Spotify Text",
                        fontWeight: 900,
                        width: "100%",
                        textTransform: "none",
                        height: "50px",
                        marginLeft: "-8px",
                        borderRadius: "8px",
                        background:
                          "radial-gradient(98.44% 1567.43% at 97.46% 50.27%, #7DA9F8 0%, #7C90F0 100%)",
                      }}
                    >
                      Sign Up
                    </Button>
                  </CardActions>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "13.5px",
                      margin: "48px auto 23px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "34px",
                        height: "1px",
                        backgroundColor: "#000",
                      }}
                    ></div>
                    <Typography
                      sx={{
                        fontSize: "15.2px",
                        lineHeight: "24px",
                        fontFamily: "Circular Spotify Text",
                        fontWeight: 400,
                        color: "#3A3A39",
                      }}
                      variant="h3"
                      component="h3"
                    >
                      Or Sign in with
                    </Typography>
                    <div
                      style={{
                        width: "34px",
                        height: "1px",
                        backgroundColor: "#000",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "9px",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                    className="signin-other-section"
                  >
                    <Button
                      size="large"
                      disabled
                      style={{
                        textTransform: "none",
                        height: "50px",
                        borderRadius: "8px",
                        background: "#fff",
                        border: "0.754076px solid #C4C4C4",
                        color: "#545453de",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "114px",
                        fontSize: "15px",
                      }}
                    >
                      <Google /> Google
                    </Button>
                    <Button
                      size="large"
                      disabled
                      style={{
                        textTransform: "none",
                        height: "50px",
                        borderRadius: "8px",
                        background: "#fff",
                        border: "0.754076px solid #C4C4C4",
                        color: "#545453de",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "114px",
                        fontSize: "15px",
                      }}
                    >
                      <Apple /> Apple ID
                    </Button>
                    <Button
                      size="large"
                      disabled
                      style={{
                        textTransform: "none",
                        height: "50px",
                        borderRadius: "8px",
                        background: "#fff",
                        border: "0.754076px solid #C4C4C4",
                        color: "#545453de",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "114px",
                        fontSize: "15px",
                      }}
                    >
                      <Facebook /> Facebook
                    </Button>
                  </div>
                </Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?
                  <Link
                    underline="hover"
                    style={{ cursor: "pointer", padding: "10px" }}
                    onClick={handleSignInRedirect}
                  >
                    Log In
                  </Link>
                </div>
              </CardContent>
            </Card>
          </div>
          <CustomizedSnackbars
            open={open}
            message={message}
            severity={severity}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontFamily: "Circular Spotify Text",
              fontWeight: 400,
              gap: "10px",
              margin: "30px auto 10px",
            }}
          >
            <Copyright />
            <span
              style={{ paddingRight: "10px", borderRight: "2px solid #fff" }}
            >
              Copyright @Enablic
            </span>
            <span>Privacy Policy</span>
          </Box>
        </div>
      )}
    </div>
  );
}
