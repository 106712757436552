import React from 'react';
import brainGym from "./mascot_gym.gif";

export default function BrainGym() {
  return (
    <>
    <img src={brainGym} alt="gym"/>
    </>
  )
}
