import React from "react";
import Trophy from "../TileMaster/assets/Trophy";
import Heart from "../TileMaster/assets/Heart";
export class GenNumber extends React.Component {
  componentDidUpdate() {
    let time, digit;
    // increase showing time depend on level
    digit = this.props.level.main + 2;
    time = 100 * Math.min(digit, 5) + 400 * Math.max(digit - 5, 0);

    let number = document.getElementById("number");
    setTimeout(function () {
      number.innerHTML = number.innerHTML.replace(/\w/gi, "&#183;");
    }, time);
  }
  componentDidMount() {
    let number = document.getElementById("number");
    setTimeout(function () {
      number.innerHTML = number.innerHTML.replace(/\w|\W/gi, "&#183;");
    }, 1200);
  }
  render() {
    return (
      <div>
        <div
          style={{
            boxSizing: "border-box",
            padding: "0 42px 0 42px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
            alignItems: "center",
            borderBottom: "1px solid rgba(255, 255, 255, 0.19)",
          }}
        >
          <div
            style={{
              fontFamily: "Circular Spotify Text",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "33px",
              color: "#ffffff",
            }}
          >
            <Trophy width={60} level={this.props.level.main} />
          </div>
          <div className="scoreText">
            <Heart height={47} wdith={30} life={3-this.props.wrong} />
          </div>
        </div>
        <h1 className="num">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            Number is
          </div>
          <div
            style={{
              height: "100px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <p id="number">
              {this.props.wrong < 3 ? atob(this.props.question) : "????"}
            </p>
          </div>
        </h1>
      </div>
    );
  }
}
