import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Spin as Hamburger } from "hamburger-react";
import { ReactComponent as HomeIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as GameIcon } from "../../assets/icons/playcircle.svg";
import { ReactComponent as PerformanceIcon } from "../../assets/icons/gauge.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/insights.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as EnablicIcon } from "../../assets/icons/enablic.svg";

import "./MobileViewNavBar.css";
import GraduationCap from "../../assets/icons/GraduationCap";

function MobileViewNavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const listItemData = [
    { label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },
    {
      label: "Enablic Journey",
      link: "/dashboard/enablicJourney",
      icon: <HomeIcon />,
    },
    { label: "Games", link: "/games", icon: <GameIcon /> },
    { label: "Performance", link: "/performance", icon: <PerformanceIcon /> },
    { label: "Assessment", link: "/assessments", icon: <GraduationCap /> },
    { label: "Insights", link: "/insights", icon: <InsightsIcon /> },
    { label: "Profile", link: "/profile", icon: "" },
    { label: "Logout", link: "/logout", icon: <SettingsIcon /> },
  ];

  const menuVariants = {
    opened: {
      top: 71,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    closed: {
      top: "-100vh",
    },
  };

  const linkVariants = {
    opened: {
      opacity: 1,
      //   y: 50,
    },
    closed: {
      opacity: 0,
      y: 0,
    },
  };

  useEffect(() => {
    // console.log(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    // console.log("isOpen", isOpen),
    <div>
      <motion.div className="header">
        <motion.div
          animate={isOpen ? "opened" : "closed"}
          whileHover={{ scale: 1.4 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Hamburger direction="left" color="#FFFFFF" toggled={isOpen} />
        </motion.div>
        <motion.div className="enablic_icon_mob" whileHover={{ scale: 1.4 }}>
          <EnablicIcon />
        </motion.div>
      </motion.div>

      <motion.div
        initial={false}
        variants={menuVariants}
        animate={isOpen ? "opened" : "closed"}
        className="navbar"
      >
        {listItemData.map((data) => (
          <motion.div
            className="listStyle"
            variants={linkVariants}
            onClick={() => {
              setIsOpen(false);
              // console.log("on close");
              navigate(`${data.link}`);
            }}
          >
            {data.label}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default MobileViewNavBar;
