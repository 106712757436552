import React from "react";

function PlayButton(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 69 68"
    >
      <g filter="url(#filter0_d_48_2)">
        <rect
          width="51"
          height="51"
          x="9"
          y="6"
          fill="#fff"
          fillOpacity="0.05"
          rx="25.5"
          shapeRendering="crispEdges"
        ></rect>
        <path
          fill="#3A3A39"
          d="M45.345 31.5l-16.267 9.392V22.108L45.345 31.5z"
        ></path>
      </g>
      <rect
        width="46.31"
        height="46.31"
        x="11.345"
        y="8.345"
        fill="#F7F7F7"
        rx="23.155"
      ></rect>
      <path
        fill="#3A3A39"
        d="M45.345 31.5l-16.267 9.392V22.108L45.345 31.5z"
      ></path>
      <defs>
        <filter
          id="filter0_d_48_2"
          width="67.414"
          height="67.414"
          x="0.793"
          y="0.138"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.345"></feOffset>
          <feGaussianBlur stdDeviation="4.103"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.15 0 0 0 0 0.02125 0 0 0 0 0.0290316 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_48_2"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_48_2"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default PlayButton;