import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  handleChangeProgressItemTutorial,
  handleExplore,
} from "../../../redux/actions";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import "./Tutorial.css";

export const ProgressItemsTutorial = () => {
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(true);
  const [initialStep] = useState(0);
  const [tutCompleted, setTutCompleted] = useState(false);

  const onExit = () => {
    // console.log("progressitems exit");
    if (!tutCompleted) {
      dispatch(handleExplore(false));
    }
    setEnabled(false);
  };

  const steps = [
    {
      element: "#gameName",
      intro: "Name of the game",
      position: "right",
    },
    {
      element: "#progressPercent",
      intro: "Check your Journey Progress over here",
    },
    {
      element: "#playedOrNot",
      intro: `Played or not`,
    },
  ];

  return (
    <div>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        // exitOnEsc={false}
        // exitOnOverlayClick={false}
        // onBeforeExit={() => {
        //   console.log("progress onBeforeExit is called");
        //   dispatch(handleChangeProgressItemTutorial(true));
        // }}
        onComplete={() => {
          // console.log("progress completed is called");
          setTutCompleted(true);
          dispatch(handleChangeProgressItemTutorial(true));
        }}
        options={{
          hideNext: false,
          doneLabel: "Next",
          disableInteraction: true,
          showBullets: false,
          tooltipClass: "Tuts",
          exitOnEsc: false,
          exitOnOverlayClick: false,
          keyboardNavigation: true,
        }}
      />
    </div>
  );
};
