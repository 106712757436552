import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import axios from "axios";
import { API } from "../../../backend";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  CardContent,
  List,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Collapse,
  Button,
} from "@mui/material";
import { GameContentHeader } from "../../common/Games/GameContentHeader";
import { GenrePieChart } from "./GenrePieChart/GenrePieChart";

import "../Insights.css";
import { motion } from "framer-motion";

ChartJS.register(ArcElement, Tooltip, Legend);

export const InsightsContentMobile = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [open, setOpen] = useState(false);
  const [assessmentScore, setAssessmentScore] = useState("Psychometric");
  const [assessmentLevel, setAssessmentLevel] = useState("Level1");
  const [duration, setDuration] = useState("Current");
  const [scoreData, setScoreData] = useState([]);
  const [genreLabel, setGenreLabel] = useState([]);
  // const [colorArray, setColorArray] = useState([]);
  const [individualGenreScore, setIndividualScore] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  const pieChartBackgroundColor = [
    "rgba(255, 99, 132)",
    "rgba(255, 159, 64)",
    "rgba(255, 205, 86)",
    "rgba(75, 192, 192)",
    "rgba(54, 162, 235)",
    "rgba(153, 102, 255)",
    "rgba(233 233 233 / 53%)",
  ];

  const data = {
    labels: [...genreLabel, "Missed Score"],
    datasets: [
      {
        data: scoreData,
        backgroundColor: pieChartBackgroundColor,
        borderColor: ["rgb(233 233 233 / 23%)"],
        borderWidth: 3,
      },
    ],
    options: {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            color: "white",
            usePointStyle: true,
            font: {
              size: window.innerWidth < 300 ? 10 : 12,
            },
          },
        },
      },
    },
  };

  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    (async () => {
      let lastTestData;
      let lastTestGenreLength;
      try {
        const getUserScoreDetails = await axios.get(
          `${API}/scorecards/getScoreHistoryByUserId/${localSigninData?.data?.user?._id}`,
          {
            headers: {
              token: localSigninData?.data?.token,
            },
          }
        );
        lastTestData = getUserScoreDetails.data.data;
        lastTestGenreLength = lastTestData.length - 1;
        // console.log(
        //   "no of genres ",
        //   Object.entries(lastTestData[lastTestGenreLength].groupedScore).length
        // );
        // console.log(
        //   "getUserScoreDetails.data.data",
        //   lastTestData[lastTestGenreLength].groupedScore
        // );
        // console.log("lastTestData", lastTestData[lastTestGenreLength]);
        let percentage = Object.entries(
          lastTestData[lastTestGenreLength].groupedScore
        ).map((data) => {
          // console.log("data[1].percentageScore", data[1].percentageScore);
          return (data[1]["actualScore"] / data[1]["totalScore"]) * 100;
        });
        setIndividualScore([...percentage]);
        // console.log("percentage ", percentage);
        let genreLabelArr = Object.entries(
          lastTestData[lastTestGenreLength].groupedScore
        ).map((data) => {
          // console.log("data[0]", data[0]);
          return data[0].split(/(?=[A-Z])/).join(" ");
        });

        setGenreLabel(genreLabelArr);
        // setGenreLabel([...genreLabelArr,"Missed Score"]);
        // console.log("genreLabel ", genreLabel);
        let missedScore =
          100 * percentage.length -
          percentage.reduce((total, currentValue) => {
            return total + currentValue;
          }, 0);
        percentage.push(missedScore);

        // converting absolute values to percentages
        let total_scores = percentage.reduce((t, v) => t + v);
        percentage = percentage.map((v) => 100 * (v / total_scores));

        setScoreData(percentage);
        // console.log("percentage ->",percentage);
      } catch { }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAssessmentScore = (event) => {
    // console.log("event ",event);
    // console.log("event.target.value",event.target.value);
    setAssessmentScore(event.target.value);
    // console.log("assessmentScore ",assessmentScore);
    // console.log("props ",props);
  };
  const handleChangeAssessmentLevel = (event) => {
    setAssessmentLevel(event.target.value);
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
  };

  return (
    // console.log("assessmentList ", assessmentList),
    // console.log("exploreThePlatform ",exploreThePlatform),
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <div className="assessmentGridBackgroundParent">
        <div className="assessmentGridBackground" style={{ marginTop: "60px" }}>
          <h1 className="insightsText">Assessment Insights</h1>

          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem onClick={handleClick} id="selectCategoryMobile">
              <ListItemText primary="Categories" style={{ color: "#ffffff" }} />
              {open ? (
                <Button
                  className="dropdown-arrow"
                  component={motion.div}
                  style={{
                    padding: "0px",
                    minWidth: "auto",
                    borderRadius: "50%",
                    color: "#fff"
                  }}
                  animate={{
                    backgroundColor: ["rgba(131, 101, 150, 0.31)", "#7c90f0"],
                    boxShadow: "-3px 0px 20px #7c90f0",
                    border: "1px solid #fff",
                    transition: { duration: 3, repeat: Infinity, ease: "easeInOut" }
                  }}
                  whileHover={{
                    backgroundColor: "#7c90f0",
                    borderColor: "#7c90f0",
                    boxShadow: "0px 0px 10px #7c90f0"
                  }}>
                  <ExpandLessIcon />
                </Button>
              ) : (
                <Button
                  className="dropdown-arrow"
                  component={motion.div}
                  style={{
                    padding: "0px",
                    minWidth: "auto",
                    borderRadius: "50%",
                    color: "#fff"
                  }}
                  animate={{
                    backgroundColor: ["rgba(131, 101, 150, 0.31)", "#7c90f0"],
                    boxShadow: "-3px 0px 20px #7c90f0",
                    border: "1px solid #fff",
                    transition: { duration: 3, repeat: Infinity, ease: "easeInOut" }
                  }}
                  whileHover={{
                    backgroundColor: "#7c90f0",
                    borderColor: "#7c90f0",
                    boxShadow: "0px 0px 10px #7c90f0"
                  }}>
                  <ExpandMoreIcon />
                </Button>
              )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 250,
                          borderRadius: "10px",
                          background: "#51355E",
                          "& .MuiInputLabel-root": {
                            color: '#fff',
                            '&.Mui-focused': {
                              color: '#fff'
                            }
                          },
                          "& .MuiOutlinedInput-root": {
                            // "& > fieldset": { borderColor: "#fff" },
                            '&:hover fieldset': {
                              borderColor: '#fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#fff',
                            }
                          }
                        }}
                        color="secondary"
                        size="small"
                      >
                        <InputLabel
                          id="demo-simple-select-autowidth-label"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          Assessment Score
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={assessmentScore}
                          onChange={handleChangeAssessmentScore}
                          autoWidth
                          label="Assessment Score"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          <MenuItem value={"EQ"}>
                            Emotional Quotient (EQ)
                          </MenuItem>
                          <MenuItem value={"IQ"}>
                            Intelligence Quotient (IQ)
                          </MenuItem>
                          <MenuItem value={"Psychometric"}>
                            Psychometric
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 160,
                          borderRadius: "10px",
                          background: "#51355E",
                          "& .MuiInputLabel-root": {
                            color: '#fff',
                            '&.Mui-focused': {
                              color: '#fff'
                            }
                          },
                          "& .MuiOutlinedInput-root": {
                            // "& > fieldset": { borderColor: "#fff" },
                            '&:hover fieldset': {
                              borderColor: '#fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#fff',
                            }
                          }
                        }}
                        color="secondary"
                        size="small"
                      >
                        <InputLabel
                          id="demo-simple-select-autowidth-label"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          Assessment Level
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={assessmentLevel}
                          onChange={handleChangeAssessmentLevel}
                          autoWidth
                          label="Assessment Level"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          <MenuItem value={"Level1"}>Level 1</MenuItem>
                          <MenuItem value={"Level2"}>Level 2</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 160,
                          borderRadius: "10px",
                          background: "#51355E",
                          "& .MuiInputLabel-root": {
                            color: '#fff',
                            '&.Mui-focused': {
                              color: '#fff'
                            }
                          },
                          "& .MuiOutlinedInput-root": {
                            // "& > fieldset": { borderColor: "#fff" },
                            '&:hover fieldset': {
                              borderColor: '#fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#fff',
                            }
                          }
                        }}
                        color="secondary"
                        size="small"
                      >
                        <InputLabel
                          sx={{
                            color: "#ffffff",
                          }}
                          id="demo-simple-select-autowidth-label"
                        >
                          Duration
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={duration}
                          onChange={handleChangeDuration}
                          autoWidth
                          label="Duration"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          <MenuItem value={"Current"}>Current</MenuItem>
                          <MenuItem value={"History"}>History</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Collapse>
          </List>
          <>
            {assessmentScore === "Psychometric" &&
              assessmentLevel === "Level1" &&
              duration === "Current" ? (
              <>
                <div
                  style={{
                    width: "100%",
                    // height: "500px",
                    height: "fit-content",
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "rgb(233 233 233 / 23%)",
                      backdropFilter: "blur(2px)",
                      borderRadius: "10px",
                      paddingBottom: "16px",
                    }}
                  >
                    <div className="scoreDistribution" style={{}}>
                      Total Score Distribution
                    </div>
                    <div
                      // style={{
                      //   width: "350px",
                      //   height: "350px",
                      //   marginTop: "40px",
                      // }}
                      className="canvas-container"
                    >
                      <Pie data={data} options={data.options}></Pie>
                    </div>
                  </div>
                </div>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {genreLabel.length > 0 ? (
                      genreLabel.map((data, index) => (
                        <Grid item xs={6} md={4} sm={6} lg={3}>
                          <Card
                            sx={{
                              maxWidth: 345,
                              borderRadius: "10px",
                              background: "rgb(233 233 233 / 23%)",
                              backdropFilter: "blur(1px)",
                              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.31)",
                            }}
                            id="playTheGame"
                          >
                            <CardActionArea>
                              <CardContent style={{ position: "relative" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    marginBottom: "15px",
                                    flexShrink: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "1em",
                                    boxSizing: "border-box",
                                    fontWeight: "bold",
                                    color: "#ffffff",
                                  }}
                                >
                                  {data}
                                </div>
                                <GenrePieChart
                                  genreLabel={[`${data}`, "Missed Score"]}
                                  colorArray={[
                                    pieChartBackgroundColor[index],
                                    "rgba(233 233 233 / 53%)",
                                  ]}
                                  individualGenreScore={[
                                    individualGenreScore[index],
                                    100 - individualGenreScore[index],
                                  ]}
                                />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <h1
                        style={{
                          width: "100%",
                          height: "600px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#51355E",
                        }}
                      >
                        More to come on this space
                      </h1>
                    )}
                  </Grid>
                </Box>
              </>
            ) : (
              <h1
                style={{
                  width: "100%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#51355E",
                }}
              >
                Yet to be assessed.
              </h1>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
