import React from "react";
import { ReactComponent as QuestionIcon } from "../../../common/Games/assets/question.svg";
import PsychometricAssessment from "../../../common/Games/assets/PsychometricAssessment.png";
import IQAssesment from "../../../common/Games/assets/IQAssesment.png";
import EmotionalIntelligence from "../../../common/Games/assets/EmotionalIntelligence.png";
import BrainGym from "../../../common/Games/assets/BrainGym";

//This is a central database for the Assessment details

export const AssessmentData = {
  Psychometric1: {
    name: "Psychometric",
    title: "Psychometric",
    subtitle: "Unlock",
    // game: <StartSequence />,
    icon: <QuestionIcon />,
    image: PsychometricAssessment,
    brainGym: <BrainGym />,
    improves: ["Memory", "Attention"],
    level: "Level 1",
    type: ["Psychometric"],
    lock: false, //hide lock icon
    link:"/psychometricTest"
  },
  Psychometric2: {
    name: "Psychometric",
    title: "Psychometric",
    subtitle: "Unlock",
    // game: <StartSequence />,
    icon: <QuestionIcon />,
    image: PsychometricAssessment,
    brainGym: <BrainGym />,
    improves: ["Memory", "Attention"],
    level: "Level 2",
    type: ["Psychometric"],
    lock: true,
    link:"/"
  },
  EQ1: {
    name: "EmotionalQuotient",
    title: "Emotional Quotient",
    subtitle: "Unlock",
    // game: <StartMemory />,
    icon: <QuestionIcon />,
    image: EmotionalIntelligence,
    brainGym: <BrainGym />,
    level: "Level 1",
    type: ["EQ"],
    lock: true, //show lock icon
    link:""
  },
  EQ2: {
    name: "EmotionalQuotient",
    title: "Emotional Quotient",
    subtitle: "Unlock",
    // game: <StartMemory />,
    icon: <QuestionIcon />,
    image: EmotionalIntelligence,
    brainGym: <BrainGym />,
    level: "Level 2",
    type: ["EQ"],
    lock: true,
    link:""
  },
  IQ1: {
    name: "IntelligenceQuotient",
    title: "Intelligence Quotient",
    subtitle: "Unlock",
    // game: <StartWordle />,
    icon: <QuestionIcon />,
    image: IQAssesment,
    brainGym: <BrainGym />,
    improves: ["Language"],
    level: "Level 1",
    type: ["IQ"],
    lock: true,
    link:""
  },
  IQ2: {
    name: "IntelligenceQuotient",
    title: "Intelligence Quotient",
    subtitle: "Unlock",
    // game: <StartWordle />,
    icon: <QuestionIcon />,
    image: IQAssesment,
    brainGym: <BrainGym />,
    improves: ["Language"],
    level: "Level 2",
    type: ["IQ"],
    lock: true,
    link:""
  },
};
