import React, { Component } from "react";
import { connect } from "react-redux";
import Start from "./GameContainers/Start";
import MathQuiz from "./GameContainers/MathQuiz";
import { gameData } from "../GameData/gameData";
import { mapDispatchToProps, mapStateToProps } from "../../../../redux/index";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { API } from "../../../../backend";
import "./MathsQuizGame.css";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import MascotIndicate from "../../Mascot/MascotIndicate";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

class MathsQuizGame extends Component {
  state = {
    localSigninData: JSON.parse(localStorage.getItem("jwt")),
    open: false,
    severity: "error",
    message: "",
  };
  gameStart = () => {
    this.props.onStartGame();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.isFinished !== this.props.isFinished) {
      axios
        .get(`${API}/games/getByName/${gameData["MentalMath"].name}`, {
          headers: {
            token: `${this.localSigninData && this.localSigninData.data.token}`,
          },
        })
        .then((val) => {
          if (window.location.pathname === "/gameplay") {
            SubmitScore(this.props.points, 300, val.data.data._id, false);
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              this.props.points,
              300,
              val.data.data._id,
              true,
              this.props.currentSchedule
            );
            setTimeout(() => {
              this.props.handleChangeStartGame(false);
            }, 3000);
          }
        })
        .catch((err) => {
          this.setState({
            open: true,
            message: err.message,
          });
        });
    }
  }
  componentWillUnmount() {
    this.props.onReStartGame();
  }
  render() {
    return (
      <>
        {this.props.isFinished ? (
          this.props.points >= 500 ? (
            <>
              <ConfettiEffect />
              <WinnerMascot
                textBubble={`Good Job ${this.state.localSigninData?.data?.user?.first_name}, you are doing well, keep going.`}
              />
            </>
          ) : (
            <>
              <GymMascot
                textBubble={`One only learns from their mistakes. It’s okay, we can try again!`}
              />
            </>
          )
        ) : (
          <></>
        )}
        <div className="MathsQuiz">
          <header className="MathsQuiz-header">
            {!this.props.isStarted ? (
              <Start startPressed={this.gameStart} />
            ) : (
              <MathQuiz {...this.props} gameStart={this.gameStart} />
            )}
          </header>
        </div>
        {!this.props.isStarted ? (
          <MascotIndicate textBubble={"Help me to solve few Maths problems."} />
        ) : (
          ""
        )}
        <CustomizedSnackbars
          open={this.state.open}
          message={this.state.message}
          severity={this.state.severity}
        />
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MathsQuizGame);
