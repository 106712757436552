import React from "react";
import "./InputNumber.css";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { gameData } from "../GameData/gameData";
import axios from "axios";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { API } from "../../../../backend";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import GymMascot from "../../Mascot/GymMascot";

class InputNumber extends React.Component {
  constructor() {
    super();
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }
  state = {
    submitted: false,
    questionCounter: 0,
    localSigninData: JSON.parse(localStorage.getItem("jwt")),
    originalScore: 0,
    missedScore: 0,
    gameId: "",
    enblicJourneyScore: true,
    open: false,
    severity: "error",
    message: "",
  };
  componentDidUpdate(prevProps) {
    if (this.props.wrong === 3) {
      if (this.props.wrong !== prevProps.wrong) {
        this.setState({
          originalScore: (this.state.questionCounter - this.props.wrong) * 100,
          missedScore: 300,
        });
        axios
          .get(`${API}/games/getByName/${gameData["NumberMatch"].name}`, {
            headers: {
              token: `${this.localSigninData && this.localSigninData.data.token
                }`,
            },
          })
          .then((val) => {
            if (window.location.pathname === "/gameplay") {
              SubmitScore(
                this.state.originalScore,
                this.state.missedScore,
                val.data.data._id,
                false
              );
            } else if (window.location.pathname !== "/gameplay") {
              SubmitScore(
                this.state.originalScore,
                this.state.missedScore,
                val.data.data._id,
                true,
                this.props.currentSchedule
              );
              setTimeout(() => {
                this.props.handleChangeStartGame(false);
              }, 3000);
            }
          })
          .catch((err) => {
            this.setState({
              open: true,
              message: err.message,
            });
          });
      }
    }
  }
  handleUserInput(e) {
    e.preventDefault();
    this.setState({
      questionCounter: this.state.questionCounter + 1,
    });
    let userNumber = btoa(this.userNumber.value);
    this.userNumber.value = "";
    this.props.compareUserInput(userNumber);
  }
  handleReset() {
    this.setState({
      questionCounter: 0
    })
    this.props.onReset();
  }
  handleBack() {
    this.setState({
      submitted: true,
    });
  }
  render() {
    let layout;
    if (this.props.wrong < 3) {
      layout = (
        <div>
          <form onSubmit={this.handleUserInput}>
            <label className="labelText" for="text">
              Press Enter to Submit
            </label>
            <br />
            <input
              className="input-box"
              id="text"
              pattern="[0-9]+"
              type="text"
              placeholder="Enter Number"
              ref={(ref) => (this.userNumber = ref)}
              required
              autoFocus
            />
            <br />
            <br />
          </form>

          {/* <div className="restart-play-btn">
            <button onClick={this.handleReset}>Start</button>
          </div> */}
        </div>
      );
    } else {
      layout = (
        <div>
          <h1
            style={{
              color: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            Game Over
            <div>Score: {this.state.originalScore}</div>
          </h1>
          <br />
          {window.location.pathname === "/gameplay" ? (
            <div>
              {this.state.submitted && <Navigate to={"/games"} />}
              <div className="dashboard-play-btn">
                <button onClick={this.handleBack}>Game Section</button>
              </div>
              <div className="dashboard-play-btn" style={{ marginTop: "15px" }}>
                <button onClick={this.handleReset}>Restart</button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      // console.log("this.state.questionCounter ", this.state.questionCounter),
      // console.log("original score", this.state.originalScore),
      // console.log("missed score", this.state.missedScore),
      <>
        {this.props.wrong >= 3 ? (
          this.state.originalScore > 500 ? (
            <>
              <ConfettiEffect />
              <WinnerMascot
                textBubble={`Fantastic! You are on a roll, ${this.state.localSigninData?.data?.user?.first_name}`}
              />
            </>
          ) : (
            <>
              <GymMascot
                textBubble={`It’s okay, we can try again!`}
              />
            </>
          )
        ) : (
          <></>
        )}
        {layout}
        <CustomizedSnackbars
          open={this.state.open}
          severity={this.state.severity}
          message={this.state.message}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("state ", state);
  return {
    currentSchedule: state.currentSchedule,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeStartGame: () => dispatch({ type: "CHANGE_STARTGAME" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InputNumber);
