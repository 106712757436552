import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Box from "./Box";
import BoardLogic from "./BoardLogic";
import Timer from "./Timer";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { gameData } from "../GameData/gameData";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { API } from "../../../../backend";
import "./puzzle.css";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

class Board extends Component {
  static defaultProps = {
    size: 3,
    localSigninData: JSON.parse(localStorage.getItem("jwt")),
    onMove: (i, j) => {
      // console.log(`Clicked tile ${i},${j}`);
    },
  };

  constructor(props) {
    super(props);
    this.state = this.initialGameState();
  }

  componentDidUpdate() {
    if (this.state.isWin && !this.state.gameId) {
      axios
        .get(`${API}/games/getByName/${gameData["Puzzle"].name}`, {
          headers: {
            token: `${this.localSigninData && this.localSigninData.data.token}`,
          },
        })
        .then((val) => {
          const gameId = val.data.data._id;
          this.setState({ gameId: gameId });
          if (this.state.isWin && window.location.pathname === "/gameplay") {
            SubmitScore(
              this.state.originalScore,
              this.state.missedScore,
              val.data.data._id,
              false
            );
          } else if (
            this.state.isWin &&
            window.location.pathname !== "/gameplay"
          ) {
            SubmitScore(
              this.state.originalScore,
              this.state.missedScore,
              val.data.data._id,
              this.state.enblicJourneyScore,
              this.props.currentSchedule
            );
            setTimeout(() => {
              this.props.handleChangeStartGame(false);
            }, 3000);
          }
        })
        .catch((err) => {
          console.error("err ", err);
        });
    }
  }

  initialGameState = () => {
    this.boardLogic = new BoardLogic(this.props.data || this.props.size);

    return {
      board: this.props.data
        ? this.boardLogic.matrix
        : this.boardLogic.scramble(),
      moves: 0,
      isWin: this.boardLogic.checkWin(),
      checkTimeIsUp: false,
      originalScore: 0,
      missedScore: 0,
      gameId: "",
      enblicJourneyScore: true,
    };
  };

  //note declaring class function as an arrow function gives us automatic 'this' binding.
  move = (i, j) => {
    if (this.state.isWin) {
      return;
    }

    if (this.boardLogic.move(i, j)) {
      this.props.onMove(i, j);
      this.setState((prevState) => ({
        board: this.boardLogic.matrix,
        moves: prevState.moves + 1,
        originalScore: prevState.moves + 1,
        missedScore: Math.max(0, (prevState.moves + 1 - 28) * 2),
        isWin: this.boardLogic.checkWin(),
      }));
    }
  };
  timerCheck = (val) => {
    this.setState({ checkTimeIsUp: val });
  };

  /**
   * returns a single slider row given the row data
   * @param {Object} rowData row data
   * @param {Number} i row number
   */
  getRow = (rowData, j) => {
    return (
      <div key={j}>
        {rowData.map((bNum, i) => (
          <Box key={bNum} boxNumber={bNum} onClick={() => this.move(i, j)} />
        ))}
      </div>
    );
  };

  newGame = () => {
    this.setState(this.initialGameState());
  };

  render() {
    let rows = this.state.board.map(this.getRow);
    let message =
      (this.state.isWin ? "Winner !!! " : "Total ") +
      `Moves: ${this.state.moves}`;
    return (
      // console.log(window.location.pathname),
      (
        <div>
          {this.state.isWin ? (
            <>
              {" "}
              <ConfettiEffect /> <WinnerMascot textBubble={"Hurrah! you helped me to solve the puzzle."} />{" "}
            </>
          ) : (
            this.state.checkTimeIsUp ? (
              <>
                <GymMascot
                  textBubble={`No worries! We can try again later.`}
                />
              </>
            ) : (
              <></>
            )
          )}
          {this.state.moves === 0 ? (
            <h3>
              <i className="fas fa-clock"></i> 5:00{" "}
            </h3>
          ) : this.state.isWin ? (
            <>
              <h3>Congratulations</h3>
            </>
          ) : (
            <Timer timerCheck={(val) => this.timerCheck(val)} />
          )}
          <div className="slider-board">
            {this.state.checkTimeIsUp ? (
              <>
                <h1>Game Over</h1>
              </>
            ) : this.state.isWin ? (
              ""
            ) : (
              rows
            )}
            <span className="slider-msg">{message}</span>
            {(this.state.isWin && window.location.pathname === "/gameplay") || this.state.checkTimeIsUp ? (
              <div className="puzzle-btn-new-game">
                <button className="puzzle-btn" onClick={this.newGame}>
                  Play Again
                </button>
                <NavLink to="/games">
                  <button className="puzzle-btn"> Game Section</button>
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    );
  }
}

Board.propTypes = {
  data: PropTypes.array,
  size: PropTypes.number,
  onMove: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentSchedule: state.currentSchedule,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeStartGame: () => dispatch({ type: "CHANGE_STARTGAME" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Board);
