import React from "react";
import Link from "@mui/material/Link";

import { useNavigate } from "react-router-dom";

import { ReactComponent as BrainError } from "../../assets/icons/404.svg";

const Error404 = () => {
  const navigate = useNavigate();
  const handleBackToHome = () => {
    navigate("/");
  };
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div
        style={{
          marginTop: "5%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <BrainError />
        </div>
        <h1 className="home-header">
          Error <span>404</span>: <span>Page</span> not found{" "}
          <Link
            underline="hover"
            style={{ cursor: "pointer" }}
            onClick={handleBackToHome}
          >
            Back to Home Page
          </Link>
        </h1>
      </div>
    </div>
  );
};

export default Error404;
