import { useState } from "react";

const useWordle = (solution) => {
  const [turn, setTurn] = useState(0);
  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, setGuesses] = useState([...Array(6)]); // each guess is an array
  const [history, setHistory] = useState([]); // each guess is a string
  const [isCorrect, setIsCorrect] = useState(false);
  const [usedKeys, setUsedKeys] = useState({}); //{a:"green",b:"yellow",c:"grey"}
  const [dispSnackbarMsg, setDispSnackbarMsg] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // format a guess into an array of letter objects
  // e.g. [{key: 'a', color: 'yellow'}]
  const formatGuess = () => {
    setOpenSnackbar(false);
    setDispSnackbarMsg(null);
    let solutionArray = [...solution];
    let formattedGuess = [...currentGuess].map((l) => {
      return { key: l, color: "grey" };
    });

    // find any green letters
    formattedGuess.forEach((l, i) => {
      if (solution[i] === l.key) {
        formattedGuess[i].color = "green";
        solutionArray[i] = null;
      }
    });

    // find any yellow letters
    formattedGuess.forEach((l, i) => {
      if (solutionArray.includes(l.key) && l.color !== "green") {
        formattedGuess[i].color = "yellow";
        solutionArray[solutionArray.indexOf(l.key)] = null;
      }
    });

    return formattedGuess;
  };

  // add a new guess to the guesses state
  // update the isCorrect state if the guess is correct
  // add one to the turn state
  const addNewGuess = (formattedGuess) => {
    if (currentGuess === solution) {
      setIsCorrect(true);
      // console.log("history", history);
      setHistory([]);
    }
    setGuesses((prevGuesses) => {
      let newGuesses = [...prevGuesses];
      newGuesses[turn] = formattedGuess;
      return newGuesses;
    });
    if (!isCorrect) {
      if(turn!==5){

        setHistory((prevHistory) => {
          return [...prevHistory, currentGuess];
        });
      }
      else{
        setHistory([])
        }
      // console.log("istor", history);
      // console.log("turn", turn);
    }
    setTurn((prevTurn) => {
      return prevTurn + 1;
    });
    setUsedKeys((prevUsedKeys) => {
      formattedGuess.forEach((l) => {
        const currentColor = prevUsedKeys[l.key];

        if (l.color === "green") {
          prevUsedKeys[l.key] = "green";
          return;
        }
        if (l.color === "yellow" && currentColor !== "green") {
          prevUsedKeys[l.key] = "yellow";
          return;
        }
        if (l.color === "grey" && currentColor !== ("green" || "yellow")) {
          prevUsedKeys[l.key] = "grey";
          return;
        }
      });

      return prevUsedKeys;
    });
    setCurrentGuess("");
  };

  // handle keyup event & track current guess
  // if user presses enter, add the new guess
  const handleKeyup = ({ key }) => {
    if (key === "Enter") {
      // only add guess if turn is less than 5
      if (turn > 5) {
        setDispSnackbarMsg("You used all your guesses!");
        setOpenSnackbar(true);
        // console.log("you used all your guesses!");
        setHistory([]);
        return;
      }
      // do not allow duplicate words
      if (history.includes(currentGuess)) {
        setDispSnackbarMsg("You had already tried that word.");
        setOpenSnackbar(true);

        // console.log("you already tried that word.");
        return;
      }
      // check word is 5 chars
      if (currentGuess.length !== 5) {
        setDispSnackbarMsg("Word must be of 5 characters.");
        setOpenSnackbar(true);

        // console.log("word must be of 5 chars.");
        return;
      }
      const formatted = formatGuess();
      addNewGuess(formatted);
    }
    if (key === "Backspace") {
      setCurrentGuess((prev) => prev.slice(0, -1));
      return;
    }
    if (/^[A-Za-z]$/.test(key)) {
      if (currentGuess.length < 5) {
        setCurrentGuess((prev) => prev + key);
      }
    }
  };

  return {
    turn,
    setTurn,
    currentGuess,
    // setCurrentGuess,
    guesses,
    // setGuesses,
    isCorrect,
    setIsCorrect,
    usedKeys,
    setUsedKeys,
    handleKeyup,
    dispSnackbarMsg,
    openSnackbar,
  };
};

export default useWordle;
