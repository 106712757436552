import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropdownAttribute(props) {
  const [academicSkills, setAcademicSkills] = useState("");
  const [cognitiveSkills, setCognitiveSkills] = useState("");
  const [abledSkillsGame, setAbledSkillsGame] = useState();

  const [age, setAge] = useState("");

  const handleChangeAcademicSkills = (event) => {
    console.log("props ", props);
    setAcademicSkills(event.target.value);
    props.academicSkills(event.target.value);
  };
  const handleChangeCognitiveSkills = (event) => {
    setCognitiveSkills(event.target.value);
    props.cognitiveSkills(event.target.value);
  };
  const handleChangeAge = (event) => {
    setAge(event.target.value);
    props.age(event.target.value);
  };
  const handleChangeAbilities = (event) => {
    setAbledSkillsGame(event.target.value);
    props.abilities(event.target.value);
  };

  return (

    <div
      style={{
        display: "flex",
        width: "100%",
        height: "80px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{
                color: "#ffffff",
              }}
            >
              Academic skills
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={academicSkills}
              onChange={handleChangeAcademicSkills}
              autoWidth
              label="Academic Skills"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Maths"}>Maths</MenuItem>
              <MenuItem value={"Biology"}>Biology</MenuItem>
              <MenuItem value={"Chemistry"}>Chemistry</MenuItem>
              <MenuItem value={"Physics"}>Physics</MenuItem>
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"GeneralKnowledge"}>General Knowledge</MenuItem>
              <MenuItem value={"Verbal"}>Verbal</MenuItem>
              <MenuItem value={"NonVerbal"}>Non Verbal</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{
                color: "#ffffff",
              }}
            >
              Cognitive Skills
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={cognitiveSkills}
              onChange={handleChangeCognitiveSkills}
              autoWidth
              label="Cognitive Skills"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Memory"}>Memory</MenuItem>
              <MenuItem value={"Attention"}>Attention</MenuItem>
              <MenuItem value={"Perception"}>Perception</MenuItem>
              <MenuItem value={"CreativeThinking"}>Creative Thinking</MenuItem>
              <MenuItem value={"CriticalThinking"}>Critical Thinking</MenuItem>
              <MenuItem value={"DecisionMaking"}>Decision Making</MenuItem>
              <MenuItem value={"Language"}>Language</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              sx={{
                color: "#ffffff",
              }}
              id="demo-simple-select-autowidth-label"
            >
              Age
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={age}
              onChange={handleChangeAge}
              autoWidth
              label="Age"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"PrimarySchool"}>Primary School</MenuItem>
              <MenuItem value={"SecondarySchool"}>Secondary School</MenuItem>
              <MenuItem value={"18"}>18 and above</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              sx={{
                color: "#ffffff",
              }}
              id="demo-simple-select-autowidth-label"
            >
              Abilities
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={abledSkillsGame}
              onChange={handleChangeAbilities}
              autoWidth
              label="Abilities"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Physical"}>Physical</MenuItem>
              <MenuItem value={"Dyscalculia"}>Dyscalculia</MenuItem>
              <MenuItem value={"Dyslexia"}>Dyslexia</MenuItem>
              <MenuItem value={"Abled"}>Speech and Language Disorder</MenuItem>
              <MenuItem value={"ADHD"}>ADHD</MenuItem>
              <MenuItem value={"Autism"}>Autism</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
