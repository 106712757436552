import { Card, CardContent, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const DescriptionModal = (props) => {
  return (
    <Modal open={props.open}>
      <Card
      className="modal-card"
        sx={{
          padding: "1rem 0.5rem",
          width: "70%",
          maxHeight: "calc(100vh - 15%)",
          overflow: "auto",
          background: "#f1f2f1",
          margin: "auto",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 1rem 0 1rem",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            fontWeight={700}
            component="div"
            sx={{ color: "#464646" }}
          >
            Know more about the Genres
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => props.setOpen(false)}
          />
        </div>
        <CardContent
          className="avatar-emoji"
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {props.data.map((row) => (
            <Card
              key={row.category}
              //   variant="outlined"
              sx={{ width: "100%", background: "#e9e9e9" }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                paddingLeft={"1rem"}
                sx={{ paddingTop: "16px", color: "#464646" }}
              >
                {props.splitCamelCase(row.category)}
              </Typography>
              <CardContent>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent consectetur, justo at congue accumsan, nisl magna
                convallis metus, sit amet ultricies ante urna non odio. Nullam
                at velit ex. Duis mattis neque eget felis viverra, non ornare
                sem venenatis. Praesent magna metus, suscipit quis elit vel,
                vulputate interdum mauris. Sed in vehicula ligula, sed dapibus
                justo. Ut vel ligula magna. Quisque enim quam, aliquam sed augue
                hendrerit, volutpat porttitor nunc.
              </CardContent>
            </Card>
          ))}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DescriptionModal;
