import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Callout from "./Components/Callout";
import Close from "./Components/Close";
import brainGym from "../Games/assets/mascot_gym.gif";

export default function GymMascot(props) {
    const [textHeightInc, setTextHeightInc] = useState(0);
    const [closeCallout, setCloseCallout] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTextHeightInc((prev) => {
                if (prev === -100) {
                    return 0;
                } else {
                    return prev - 10;
                }
            });
        }, 4000);
        return () => { };
    }, [textHeightInc]);

    const handleClose = () => {
        setCloseCallout(true);
    };

    return (
        <div
            style={{
                // position: "relative",

                position: "fixed",
                bottom: "-34px",
                right: "-100px",
                display: "flex",
                zIndex: "1009",
            }}
        >
            {!closeCallout ? (
                <>
                    <div
                        style={{
                            position: "absolute",
                            top: "-110px",
                            left: "-123px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                            overflow: "hidden",
                        }}
                    >
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            style={{
                                height: "30px",
                                width: "30px",
                                position: "absolute",
                                color: "#000000",
                                fontWeight: 900,
                                overflow: "hidden",
                                right: "11px",
                                bottom: "170px",
                                borderRadius: "100%",
                                zIndex: 100,
                            }}
                        >
                            <Close onClick={handleClose} />
                        </motion.div>
                        <div
                            style={{
                                height: "130px",
                                width: "250px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                color: "#000000",
                                fontWeight: 900,
                                overflow: "hidden",
                                bottom: "65px",
                            }}
                        >
                            {props.textBubble}
                        </div>

                        <Callout width={300} height={200} />
                    </div>
                </>
            ) : (
                ""
            )}
            <img
                src={brainGym}
                alt={"brain"}
                width={384}
                height={216}
            />
        </div>
    );
}
