import React from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import { GenNumber } from "./GenNumber";
import InputNumber from "./InputNumber";
import "./NumberMatch.css";

export class NumberMatch extends React.Component {
  constructor() {
    super();
    this.compareUserInput = this.compareUserInput.bind(this);
    this.randomGenerate = this.randomGenerate.bind(this);
    this.resetState = this.resetState.bind(this);
    this.state = {
      question: btoa(this.randomGenerate(2)),
      level: { main: 1, sub: 1 },
      wrong: 0,
    };
  }
  resetState() {
    this.setState({
      question: btoa(this.randomGenerate(2)),
      level: { main: 1, sub: 1 },
      wrong: 0,
      localSigninData: JSON.parse(localStorage.getItem("jwt")),
      originalScore: 0,
      missedScore: 0,
      gameId: "",
      enblicJourneyScore: true,
    });
  }
  randomGenerate(digit) {
    let max = Math.pow(10, digit) - 1,
      min = Math.pow(10, digit - 1);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  compareUserInput(userNumber) {
    let currQuestion = this.state.question,
      mainLevel = this.state.level.main,
      subLevel = this.state.level.sub,
      wrong = this.state.wrong,
      originalScore = this.state.originalScore,
      missedScore = this.state.missedScore,
      digit;

    if (userNumber === currQuestion) {
      ++originalScore;
      if (subLevel < 3) {
        ++subLevel;
      } else if (subLevel === 3) {
        ++mainLevel;
        subLevel = 1;
      }
    } else {
      ++wrong;
      ++missedScore;
    }
    digit = mainLevel + 2;

    this.setState({
      question: btoa(this.randomGenerate(digit)),
      level: { main: mainLevel, sub: subLevel },
      wrong: wrong,
      originalScore: originalScore,
      missedScore: missedScore,
    });
  }
  render() {
    return (
      // console.log("originalScore ", this.originalScore),
      // console.log("wrong", this.wrong),
      (
        <div>
          <div>
            <Box
              style={{
                width: "100%",
              }}
            >
              <Card
                style={{
                  padding: 1,
                  marginLeft: "5%",
                  width: "90%",
                  height: "fit-content",
                  minHeight: "520px",
                  // overflowY: "auto",
                  maxHeight: "calc(100vh - 68px)",
                  justifyContent: "center",
                  borderRadius: "20px",
                  background: "rgb(34 4 57 / 74%)",
                  backdropFilter: "saturate(180%) blur(10px)",
                  display: this.state.wrong === 3 ? "flex" : "block",
                  alignItems: "center"
                }}
              >
                {this.state.wrong < 3 ? (
                  <GenNumber
                    question={this.state.question}
                    level={this.state.level}
                    wrong={this.state.wrong}
                  />
                ) : (<></>)}

                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <InputNumber
                    compareUserInput={this.compareUserInput}
                    wrong={this.state.wrong}
                    onReset={this.resetState}
                  />
                </div>
              </Card>
            </Box>
          </div>
        </div>
      )
    );
  }
}
