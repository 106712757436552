import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import VideoTutorial from "../../GameTutorial/VideoTutorial";
import WordleGame from "./WordleGame";

export const StartWordle = () => {
  const [startWordleGame, setStartWordleGame] = useState();
  const [counter, setCounter] = useState(3);
  const [startGame, setStartGame] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (counter === 0) {
      setStartWordleGame(true);
    }
    if (startGame) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, startGame]);
  const handleStartGame = () => {
    setStartGame(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "1536px",
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                padding: 1,
                marginLeft: "5%",
                width: "90%",
                height: "526px",
                maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {startWordleGame === true ? <WordleGame /> : ""}
                {startWordleGame === undefined ? (
                  <>
                    {startGame ? (
                      <Box
                        mt={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h1>GET Ready...</h1>
                        <h1>{counter}</h1>
                      </Box>
                    ) : (
                      <div
                        className="dashboard-play-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h1 style={{ color: "#ffffff" }}>Wordle</h1>
                        <button onClick={handleStartGame}>Start Game</button>
                        <button onClick={handleOpen}>How to play?</button>
                        <VideoTutorial
                          videoLink={
                            "https://d28u08h3rd64o.cloudfront.net/wordle/wordle.m3u8"
                          }
                          open={open}
                          setOpen={setOpen}
                          handleClose={() => handleClose()}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};
