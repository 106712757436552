import React from "react";
import Button from "../GameComponents/Button";
import { Session } from "../GameUtils/storage";
import VideoTutorial from "../../../GameTutorial/VideoTutorial";

class Start extends React.Component {
  state = {
    player: "player",
    open: false,
  };

  clicked = () => {
    Session.set("onlinePlayer", this.state.player);
    this.props.startPressed();
  };
  handleOpen = () => {
    this.setState({
      open: true,
    });
  };
  setOpen = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    return (
      <div>
        <div className="MathsQuiz-brandname">
          <img
            src={"/assets/calculator.png"}
            alt="Calculator"
            height="70"
            width="70"
          />

          <br />
          <h3>Do You Know</h3>
          <h1>Mental Math?</h1>
        </div>
        <p>Press to start the game</p>
        <Button isClicked={this.clicked}>Start</Button>
        <div className="home-play-btn">
          <button onClick={this.handleOpen}>How to play</button>
        </div>
        <VideoTutorial
          videoLink={
            "https://d28u08h3rd64o.cloudfront.net/Mental_maths/mental_maths.m3u8"
          }
          open={this.state.open}
          setOpen={() => this.setOpen()}
        />
      </div>
    );
  }
}
export default Start;
