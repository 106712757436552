import React from "react";
import ReactHlsPlayer from "react-hls-player";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Close from "./assets/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export default function VideoTutorial(props) {
  const handleClose = () => props.setOpen(false);

  return (
    // console.log("props ",props),
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <ReactHlsPlayer
              src={props.videoLink}
              autoPlay={true}
              controls={true}
              muted
              style={{
                position: "relative",
                width: "400px",
                borderRadius: "5px",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: "260px",
                right: "10px",
              }}
            >
              <Close
                height={24}
                width={24}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
