import React, { useEffect, useState } from "react";
import { ReactComponent as Ellipse } from "./assets/gameSection.svg";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RightBrain from "./assets/RightBrain.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { ReactComponent as Coin } from "./assets/coin.svg";
import { ReactComponent as Play } from "./assets/playButton.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { gameData } from "./GameData/gameData";
import DropdownAttribute from "./DropdownAttribute/DropdownAttribute";
import { GameContentHeader } from "./GameContentHeader";
import GameSectionTutorial from "../../Tutorial/EnablicJourneyGame/GameSectionTutorial";
import { useSelector } from "react-redux";
import Mascot from "../Mascot/Mascot";
import "./game.css";

export const GameContent = ({ sidebarWidth }) => {
  const { exploreThePlatform } = useSelector((state) => state);
  const navigate = useNavigate();

  const [gameList, setGameList] = useState([]);
  const [, setGameNameData] = useState([]);
  const [academicSkillsGame, setAcademicSkillsGame] = useState();
  const [cognitiveSkillsGame, setCognitiveSkillsGame] = useState();
  const [ageSkillsGame, setAgeSkillsGame] = useState();
  const [abledSkillsGame, setAbledSkillsGame] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const gameObj = Object.values(gameData);
    const gameName = Object.keys(gameData);
    setGameList(gameObj);
    setGameNameData(gameName);
  }, []);

  useEffect(() => {
    // console.log("current values=========================")
    console.log("academicSkillsGame: ", academicSkillsGame)
    console.log("cognitiveSkillsGame: ", cognitiveSkillsGame)
    console.log("ageSkillsGame: ", ageSkillsGame)
    console.log("abledSkillGame: ", abledSkillsGame)

    const lists = Object.values(gameData).filter((gameFilterData, index) => {
      return (
        (!academicSkillsGame ||
          gameFilterData.academicSkills.includes(academicSkillsGame)) &&
        (!cognitiveSkillsGame ||
          gameFilterData.improves.includes(cognitiveSkillsGame)) &&
        (!ageSkillsGame || gameFilterData.ageGroups.includes(ageSkillsGame)) &&
        (!abledSkillsGame || gameFilterData.abled.includes(abledSkillsGame))
      );
    });

    setGameList(lists);

    console.log("lists------> ", lists);
  }, [academicSkillsGame, cognitiveSkillsGame, ageSkillsGame, abledSkillsGame]);
  const playgame = (name) => {
    // console.log("click", name);
    navigate("/gameplay", {
      state: { gameid: name },
    });
  };

  const handleAcademicSkills = (val) => {
    console.log("academicSkills is called", val);
    if (val.length !== 0) setAcademicSkillsGame(val);
    else setAcademicSkillsGame();
  };

  const handleCognitiveSkills = (val) => {
    if (val.length !== 0) {
      setCognitiveSkillsGame(val);
    } else {
      setCognitiveSkillsGame();
    }
  };
  const handleAge = (val) => {
    // console.log("age is called", val);
    if (val.length !== 0) {
      setAgeSkillsGame(val);
    } else {
      setAgeSkillsGame();
    }
  };
  const handleAbilities = (val) => {
    // console.log("abilities is called", val);
    if (val.length !== 0) {
      setAbledSkillsGame(val);
    } else {
      setAbledSkillsGame();
    }
  };
  // const handlePhysical = (val) => {
  //   console.log("Physical is called", val);
  //   if (val === true) {
  //     setPhysicalSkillsGame(true);
  //   } else {
  //     setPhysicalSkillsGame(false);
  //   }
  // };
  // const handleAbled = (val) => {
  //   console.log("Abled is called", val);
  //   if (val === true) {
  //     setAbledSkillsGame(true);
  //   } else {
  //     setAbledSkillsGame(false);
  //   }
  // };
  return (
    // console.log("gameList ", gameList),
    // console.log("exploreThePlatform ",exploreThePlatform),
    <div>
      <div className="ellipseGameSection">
        <Ellipse />
      </div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GameContentHeader />
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            width: "98%",
            display: "flex",
            justifyContent: "flex-end",
            filter: "drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.2))",
            borderRadius: "10.2609px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "291px",
              color: "#ffffff",
              overflow: "hidden",
              position: "absolute",
              borderRadius: "10px",
              background:
                "linear-gradient(90deg, rgba(104,130,255,1) 0%, rgba(104,130,255,1) 15%, rgba(132,119,222,1) 41%, rgba(255,70,79,0.38728995015975143) 71%)",
            }}
          >
            <div style={{ margin: "0 0 0 25px" }}>
              <h1>Game Section</h1>
              <p style={{ width: "300px", fontWeight: "400" }}>
                We provide Mind train Games Everyday to our users for Free.
              </p>
              <span style={{ margin: "100px 0 0 0", fontWeight: "300" }}>
                A daily taste of assorted cognitive tasks.
              </span>
            </div>
          </div>

          <img
            src={RightBrain}
            height="291px"
            style={{
              objectFit: "cover",
              objectPosition: "80% 100%",
            }}
            alt="Brain"
          />
        </div>
        <div className="categorySection">
          <h1 className="categoryText">Categories</h1>
          <div
            style={{
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              width: "100%",
              // minWidth: "900px",
            }}
            id="selectCategory"
          >
            <DropdownAttribute
              academicSkills={(val) => {
                handleAcademicSkills(val);
              }}
              cognitiveSkills={(val) => {
                handleCognitiveSkills(val);
              }}
              age={(val) => {
                handleAge(val);
              }}
              abilities={(val) => {
                handleAbilities(val);
              }}
            />
          </div>
          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {gameList.length > 0 ? (
                gameList.map((data, index) => (
                  <Grid item xs={6} md={4} sm={6} lg={3}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        borderRadius: "10px",
                        background:
                          "linear-gradient(93.5deg, #51355E 4.67%, #24152C 96.2%)",
                        boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.31)",
                      }}
                      id="playTheGame"
                      onClick={() => playgame(data.name)}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={`${data.image}`}
                          alt={`${data.title}`}
                        />
                        <CardContent style={{ position: "relative" }}>
                          <div className="gameName" style={{paddingBottom: "5px"}}>{data.title}</div>
                          {/* <div className="credits">
                            <div style={{ width: "80%", display: "flex" }}>
                              <Coin />
                              <div style={{ margin: "5px" }}>
                                Earn 150 credits
                              </div>
                            </div>
                          </div> */}
                          <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="playicon"
                          >
                            <Play />
                          </motion.div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              ) : (
                <h1
                  style={{
                    width: "100%",
                    height: "600px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#51355E",
                  }}
                >
                  More to come on this space
                </h1>
              )}
            </Grid>
          </Box>
        </div>
        {gameList.length > 0 && windowWidth >= 950 && exploreThePlatform ? (
          <GameSectionTutorial />
        ) : (
          ""
        )}
      </div>
      <Mascot textBubble={"Play and practice exciting games over here."} />
    </div>
  );
};
