import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";
import Questions from "./components/Quiz/Questions";
import PsychometricTest from "./components/Quiz/PsychometricTest";
import ScoreScreen from "./components/Quiz/ScoreScreen";
import Error404 from "./components/Error404/Error404";
import Logout from "./components/Logout/Logout";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Games from "./components/common/Games/Games";
import ScoreCards from "./components/ScoreCards/ScoreCards";
import { Performance } from "./components/Performance/Performance";
import { Insights } from "./components/Insights/Insights";
import { useDispatch } from "react-redux";
import {
  handleChangeScore,
  handleChangeTestOver,
  handleTotalQuestion,
} from "./redux/actions.js";
import Dashboard from "./components/Dashboard/Dashboard";
import { GameSection } from "./components/common/Games/GameSection";
import Forgotpassword from "./components/Forgotpassword/Forgotpassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import { Assessments } from "./components/Assessments/Assessments";
import PrivateScoreRoute from "./components/PrivateRoutes/PrivateScoreRoute";
import { UserAnalytics } from "./components/UserAnalytics/UserAnalytics";
import PrivateUserAnalyticsRoute from "./components/PrivateRoutes/PrivateUserAnalyticsRoute";
import UserProfile from "./components/UserProfile/UserProfile";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleChangeScore(0));
    dispatch(handleChangeTestOver(false));
    dispatch(handleTotalQuestion(0));
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        {/* {(signinData && signinData.data.token.length !== 0) ||
        localSigninData ? (
          ""
        ) : (
          // <HomeNavBar />
          <TopNavBar />
        )} */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route
            exact
            path="/userPasswordReset/:id/:token"
            element={<ResetPassword />}
          />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/questions" element={<Questions />} />
            <Route
              path="/psychometricTest"
              element={
                <div>
                  <PsychometricTest />
                </div>
              }
            />
            <Route element={<PrivateScoreRoute />}>
              <Route path="/score" element={<ScoreScreen />} />
            </Route>
            <Route path="/games" element={<Games />} />
            <Route path="/scorecards" element={<ScoreCards />} />
            <Route path="/performance" element={<Performance />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/gameplay" element={<GameSection />} />
            <Route path="/assessments" element={<Assessments />} />
            <Route element={<PrivateUserAnalyticsRoute />}>
              <Route path="/userAnalytics" element={<UserAnalytics />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path=":tab" element={<Dashboard />} />
            </Route>
            <Route path="/profile" element={<UserProfile />} />
          </Route>
          <Route path="*" element={<Error404 />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
