import React from 'react';
import { Outlet, Navigate, useLocation } from "react-router-dom";

const PrivateScoreRoute = () => {
    const location = useLocation();
    return ((location.state !== null) ? (
        <Outlet />
    ) : (
        <Navigate to="/psychometricTest" replace />
    )
    )
}

export default PrivateScoreRoute