import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Steps } from "intro.js-react";
import { useNavigate } from "react-router-dom";
import "intro.js/introjs.css";
import "./Tutorial.css";

function DashboardTutorial() {
  let navigate = useNavigate();
  const { exploreThePlatform } = useSelector((state) => state);

  const handleClickGames = () => {
    navigate("/games");
  };
  const [initialStep] = useState(0);

  const onExit = () => {
    // console.log("dashboard onexit");
    // dispatch(handleExplore(false));
  };

  const steps = [
    {
      element: "#playNow",
      intro: "Go to Game Section",
    },
  ];

  return (
    // console.log("enabled", enabled),
    // console.log("exploreThePlatform", exploreThePlatform),
    <div>
      <Steps
        enabled={exploreThePlatform}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        // onBeforeExit={() => {
        //   console.log("dashboard onBeforeExit");

        //   // dispatch(handleExplore(false));
        //   // console.log("journey onBeforeExit is called");
        // }}
        onComplete={() => {
          // console.log("dashboard onComplete");
          // console.log("journey completed");
          handleClickGames();
          //   dispatch(handleChangeProgressItemTutorial(false));
          //   dispatch(handleChangeEnablicJourneyTutorial(false));
        }}
        options={{
          hideNext: false,
          doneLabel: "Next Page",
          disableInteraction: true,
          showBullets: false,
          tooltipClass: "Tuts",
          exitOnEsc:false,
          exitOnOverlayClick:false,
          keyboardNavigation: true,
        }}
      />
    </div>
  );
}

export default DashboardTutorial;
