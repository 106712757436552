import React, { useEffect, useState } from "react";
import { ReactComponent as Ellipse } from "../../../assets/icons/ellipse1.svg";
import axios from "axios";
import CustomizedSnackbars from "../../common/Snackbar/CustomizedSnackbars";
import { API } from "../../../backend";
import "../Performance.css";
import { GameContentHeader } from "../../common/Games/GameContentHeader";
import { HorizontalChart } from "./HorizontalChart";
import Mascot from "../../common/Mascot/Mascot";

export const PerformanceContent = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const genreDataResponse = await axios.get(
          `${API}/users/getStatistics/${localSigninData?.data?.user?._id}`,
          {
            "Content-Type": "application/json",
            headers: {
              token: `${localSigninData && localSigninData.data.token}`,
            },
          }
        );
        setGamesPlayed(
          genreDataResponse.data.data.game_statistics.total_games_played
        );
      } catch (err) {
        setOpenSnackbar(true);
        setMessage(err.message);
        setSeverity("error");
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="ellipseGameSection">
        <Ellipse />
      </div>
      <div
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100% - ${sidebarWidth}px)`,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <GameContentHeader />
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            width: "98%",
            height: "291px",
            display: "flex",
            justifyContent: "flex-end",
            filter: "drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.2))",
            borderRadius: "10.2609px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "291px",
              color: "#ffffff",
              overflow: "hidden",
              position: "absolute",
              borderRadius: "10px",
              background:
                "linear-gradient(90deg, rgba(104,130,255,1) 0%, rgba(104,130,255,1) 15%, rgba(132,119,222,1) 61%, rgba(255,70,79,0.38728995015975143) 91%)",
            }}
          >
            <div style={{ margin: "0 0 0 25px", height: "291px" }}>
              <h1>Performance</h1>
              <p style={{ width: "300px", fontWeight: "400" }}>
                We provide Mind train Games Everyday to our users for Free.
              </p>
              <span style={{ margin: "100px 0 0 0", fontWeight: "300" }}>
                A daily taste of assorted cognitive tasks.
              </span>
            </div>
          </div>

          <img
            src={"/assets/performance-png-9602.png"}
            width="451px"
            height="250px"
            style={{
              marginRight: "25px",
              marginTop: "40px",
            }}
            alt="Brain"
          />
        </div>
        <div className="performanceSection">
          <h2
            style={{
              color: "#ffffff",
            }}
          >
            Brain Attribute Chart
          </h2>
          <HorizontalChart />
          <h2
            style={{
              color: "#ffffff",
            }}
          >
            Total Games Played: {gamesPlayed}
          </h2>
        </div>
      </div>
      <Mascot textBubble={"Check your progress over here"} />
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};
