import React, { useEffect, useState } from "react";

import LeftBrain from "./assets/left-brain.png";
import Star from "./assets/star.png";
import { ReactComponent as PlayBtn } from "./assets/play.svg";
import { ReactComponent as SmallBrain } from "./assets/smallbrain.svg";
import { useNavigate } from "react-router-dom";
import { workoutItems } from "./DashboardData";
import "./Dashboard.css";
import RightBarContentMobile from "./RightBarContentMobile";
import DashboardTutorialMobile from "../Tutorial/EnablicJourneyGame/DashboardTutotialMobile";
import Mascot from "../common/Mascot/Mascot";

export const MainDashboardContentMobile = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  let navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);
  const handleClick = () => {
    navigate("/games");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {windowWidth < 950 ? <DashboardTutorialMobile /> : ""}
      <div
        style={{
          width: "90%",
          background: "rgba(255, 255, 255, 0.06)",
          borderRadius: "10.2609px",
          padding: "10px",
          margin: "22px 27px",
          marginTop: "80px",
        }}
      >
        <div className="main-dashboard-content-mobile-header">
          <div
            style={{
              width: "100%",
              height: "20vh",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "20vh",
                display: "flex",
                padding: "0 20px",
              }}
            >
              <div>
                <div className="main-dashboard-content-mobile-header-heading">
                  Play Everyday Free!
                </div>
                <div className="main-dashboard-content-mobile-header-text">
                  We provide Mind train Games Everyday to our users for Free.
                </div>
              </div>
            </div>
            <div>
              <img
                className="main-dashboard-content-mobile-header-img"
                src={LeftBrain}
                alt="start"
              />
            </div>
          </div>
          <div className="main-dashboard-content-mobile-header-lower">
            <div className="main-dashboard-content-mobile-header-lower-bg">
              <div className="dashboard-play-btn" id="playNowMobile">
                <button onClick={handleClick}>
                  <PlayBtn /> <span>PLAY NOW</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-dashboard-content-mobile-middle">
        <div className="main-dashboard-content-mobile-middle-header">
          <div className="main-dashboard-content-mobile-middle-header-bg">
            <div className="main-dashboard-content-mobile-middle-header-img-text">
              <img src={Star} alt="start" />
              Premium Brain Workouts
            </div>
          </div>
          <div className="workoutsMobile">
            {workoutItems.map((data) => (
              <div className="main-dashboard-content-mobile-middle-content">
                <div className="main-dashboard-content-mobile-middle-bg">
                  <div className="main-dashboard-content-mobile-text-brain-parent">
                    <div className="main-dashboard-content-mobile-text-brain">
                      <div className="main-dashboard-content-mobile-brain">
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            background: `${data.color}`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                          }}
                        >
                          <SmallBrain />
                        </div>
                      </div>
                      <div className="main-dashboard-content-mobile-name">
                        {data.name}
                      </div>
                    </div>
                    <div className="main-dashboard-content-mobile-desc">
                      {data.description}
                    </div>
                    <div className="main-dashboard-content-mobile-unlock">
                      <button className="main-dashboard-content-mobile-unlock-button">
                        unlock
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="lockGlassMobile" />
          </div>
        </div>
      </div>
      <RightBarContentMobile />
      <Mascot
        textBubble={`Hi ${localSigninData?.data?.user?.first_name}, Good to see you here. Click on the Explore button`}
      />
    </div>
  );
};
