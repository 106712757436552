import React, { useEffect, useState } from "react";
import { ReactComponent as GamePlayBrain } from "../../common/Games/assets/game_play_brain.svg";
import Grid from "@mui/material/Grid";
import { AssessmentData } from "./AssessmentData/AssessmentData";
import "../Assessment.css";
import AssessmentDropdownAttribute from "./AssessmentDropdownAttribute/AssessmentDropdownAttribute";
import Mascot from "../../common/Mascot/Mascot";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const AssessmentContentMobile = ({ sidebarWidth }) => {
  const navigate = useNavigate();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [assessmentList, setAssessmentList] = useState([]);
  const [, setAssessmentNameData] = useState([]);

  const [assessment, setAssessment] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const assessmentObj = Object.values(AssessmentData);
    const assessmentName = Object.keys(AssessmentData);
    setAssessmentList(assessmentObj);
    setAssessmentNameData(assessmentName);
  }, []);

  useEffect(() => {
    const lists = Object.values(AssessmentData).filter(
      (assessmentFilterData, index) => {
        return !assessment || assessmentFilterData.type.includes(assessment);
      }
    );

    setAssessmentList(lists);

    // console.log("lists------> ", lists);
  }, [assessment]);

  const handleAssessment = (val) => {
    console.log("assessment is called", val);
    if (val.length !== 0) {
      setAssessment(val);
    } else {
      setAssessment();
    }
  };

  return (
    // console.log("assessmentList ", assessmentList),
    // console.log("exploreThePlatform ",exploreThePlatform),
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="frosted-glass">
          <div className="game-section-header-bg">
            <div className="game-section-header-content">
              <div className="game-section-header-content-text">
                <div>
                  <div className="game-section-header-content-text-heading">
                    Assessment
                  </div>
                  <div className="game-section-header-content-text-description">
                    We provide Mind train Games Everyday to our users for Free.
                  </div>
                </div>
              </div>
              <div>
                <GamePlayBrain className="game-section-header-content-text-img" />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
              }}
            >
              <div className="game-section-header-bottom-bg">
                <div className="game-section-header-bottom-text">
                  A daily taste of assorted cognitive tasks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="assessmentGridBackgroundParent" style={{ padding: "0px 12px 12px 12px" }}>
        <div className="assessmentGridBackground">
          <h1 className="assessmentText">Assessments</h1>
          <div className="assessmentDropdown" id="selectCategory">
            <AssessmentDropdownAttribute
              assessment={(val) => {
                handleAssessment(val);
              }}
            />
          </div>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {assessmentList.length > 0 ? (
              assessmentList.map((data, index) => (
                <Grid item xs={windowWidth > 600 ? 6 : 12}>
                  <div
                    className="assessment-section-middle-parent"
                    style={
                      { cursor: `${!data.lock ? "pointer" : ""}` }
                    }
                    onClick={() => {
                      // if there is no test taken before redirect to the test else show a dialog
                      if (!data.lock) {
                        if (
                          localSigninData.data.user.current_journey_cycle > -1
                        )
                          handleClickOpen();
                        else navigate(data.link);
                      }
                    }}
                  >
                    {data.lock ? (
                      <div className="lockChild">
                        <div className="assessmentLockGlass" />
                      </div>
                    ) : (
                      ""
                    )}
                    <img
                      className="game-section-middle-img"
                      src={data.image}
                      alt="GameImage"
                      width={50}
                      height={50}
                      style={{ objectFit: "cover" }}
                    />
                    <div className="game-section-middle-bg">
                      <div className="game-section-middle-game-name">
                        {data.title}
                      </div>
                      <div className="game-section-middle-game-name">
                        {data.level}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Grid>
              ))
            ) : (
              <h1 className="placeholderDisp">More to come on this space</h1>
            )}
          </Grid>
        </div>
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You brain gym routine will reset if you take the test before
            completing the Enablic Journey games.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleClose();
              navigate("/psychometricTest");
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Mascot textBubble={"We will unlock this for you soon."} />
    </div>
  );
};
