import React, { useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import DashboardContent from "./DashboardContent";
import { ReactComponent as EllipseDashboard } from "./assets/ellipseDashboard.svg";
import { ReactComponent as Ellipseblue } from "./assets/ellipseBlue.svg";
import MobileViewNavBar from "../MobileViewNavBar/MobileViewNavBar";
import "./Dashboard.css";
import { DashboardContentMobile } from "./DashboardContentMobile";

export default function Dashboard() {
  const [sidebarToggled, setSidebarToggled] = useState(false);

  return (
    <div>
      <div className="webView">
        <div className="ellipseDashboard">
          <EllipseDashboard />
        </div>
        <div className="ellipseBlue">
          <Ellipseblue />
        </div>
        <SideNavBar setSidebarToggled={setSidebarToggled} />
        <DashboardContent sidebarWidth={!sidebarToggled ? 65 : 240} />
      </div>
      <div className="mobileView">
        <MobileViewNavBar />
        <DashboardContentMobile />
      </div>
    </div>
  );
}
