import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  handleChangeScore,
  handleChangeTestOver,
  handleTotalQuestion,
} from "../../redux/actions";
import axios from "axios";
import CustomizedSnackbars from "../common/Snackbar/CustomizedSnackbars";
import Counter from "../common/Counter/Counter";
import { API } from "../../backend";
// import { ReactComponent as RectangleBlue } from "./assets/Rectangle_blue.svg";
// import { ReactComponent as RectangleGreen } from "./assets/Rectangle_green.svg";
// import { ReactComponent as RectangleRed } from "./assets/Rectangle_red.svg";
// import { ReactComponent as RectangleViolet } from "./assets/Rectangle_violet.svg";
// import { ReactComponent as RectangleSky } from "./assets/Rectangle_sky.svg";
import { ReactComponent as GameRemote } from "./assets/game_remote.svg";
import backgroundImage1 from "./assets/kid-concentrating-with-mathematics-homework-learning.jpg";
import backgroundImage2 from "./assets/baby-drawing-with-orange-crayon-paper.jpg";
import backgroundImage3 from "./assets/elevated-view-little-girl-talking-with-her-female-psychologist-taking-note-clipboard.jpg";
import backgroundImage4 from "./assets/unrecognizable-girl-coloring-picture.jpg";

import { motion } from "framer-motion";
import "./questions.css";

const getRandomInt = (num) => Math.floor(Math.random() * num);

function removeDuplicates(arr) {
  return [...new Set(arr)];
}

// const categoryObj = [
//   { color: "#230D93", svgImg: <div style={{ width: "400px", height: "100%", background: `url(${backgroundImage1})`}}></div> },
//   { color: "#0D936B", svgImg: <RectangleGreen className="rectangle" /> },
//   { color: "#930D0D", svgImg: <RectangleRed className="rectangle" /> },
//   { color: "#580D93", svgImg: <RectangleViolet className="rectangle" /> },
//   { color: "#0D7393", svgImg: <RectangleSky className="rectangle" /> },
// ];

const backgroundImgs = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage1,
  backgroundImage3,
  backgroundImage4,
];

const Questions = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const { score, testOver } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [quesIndex, setQuesIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [response, setResponse] = useState(null);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [totalQues, setTotalQues] = useState(false);
  const [scoreUpdates, setScoreUpdates] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState(null);
  // console.log("location", location);
  useEffect(() => {
    if (location.state && location.state.test?.id) {
      axios
        .get(`${API}/tests/getTestById/${location.state.test.id}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          setLoading(false);
          setResponse(val);
        })
        .catch((err) => {
          setOpen(true);
          setMessage(err.message);
          setSeverity("error");
        });
    } else {
      // console.log("No data found");
      setOpen(true);
      setMessage("No data found");
      setSeverity("warning");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (!response) return;
    let inCorrectAnswers = [];
    let correctAnswer = [];
    // console.log(
    //   "response && response.data.data.questions ",
    //   response && response.data.data.questions
    // );
    const genreData =
      response &&
      response.data.data.questions.map((genre) => {
        // console.log("genre", genre.genre);
        return genre.genre;
      });
    // console.log("genreData", genreData);
    // console.log(removeDuplicates(genreData));
    // console.log("categoryObj", categoryObj);
    const question = response && response.data.data.questions[quesIndex];
    const questionLength = response?.data.data.questions.length;
    // console.log("question", question.genre);
    const index = removeDuplicates(genreData).findIndex(
      (item) => item === question.genre
    );
    setCategoryIndex(index);
    console.log(" index ", index);
    if (!totalQues && questionLength !== null) {
      // console.log("questionLength", questionLength);
      dispatch(handleTotalQuestion(questionLength));
    } else {
      setTotalQues(true);
    }
    question?.options.map((ans) => {
      if (!ans.is_correct) {
        inCorrectAnswers.push(ans.title);
      } else {
        correctAnswer.push(ans.title);
      }
      return 0;
    });
    let answers = [...inCorrectAnswers];
    answers.splice(getRandomInt(question?.options.length), 0, correctAnswer);
    // console.log("testover", testOver);
    setOptions(answers);
    if (!testOver) {
      window.onbeforeunload = () => {
        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
  }, [quesIndex, response, testOver, dispatch, totalQues]);

  const handleClickAnwser = (e, quesIndex) => {
    setBtnDisabled(true);
    // console.log("%ce", "background:pink", e.target.textContent);
    // console.log("quesIndex", quesIndex);
    // console.log("response ", response);
    // console.log(
    //   " response?.data.data.questions[quesIndex].options.filter((data)=>if(data.is_correct===true))",
    //   response?.data.data.questions[quesIndex].options.filter(
    //     (data) => data.is_correct === true
    //   )[0].title
    // );
    const updateObj = {};
    updateObj.question_id = response?.data.data.questions[quesIndex]._id;
    const opt = response?.data.data.questions[quesIndex].options.find(
      (data) => data.title === e.target.textContent
    )._id;
    // console.log("opt ",opt);
    updateObj.selected_options = [opt];
    setScoreUpdates((prev) => [...prev, updateObj]);

    if (
      e.target.textContent ===
        response?.data.data.questions[quesIndex].options.filter(
          (data) => data.is_correct === true
        )[0].title &&
      !testOver
    ) {
      dispatch(handleChangeScore(score + 1));
      // setOpen(true);
      // setSeverity("success");
      // setMessage("Right");
      setTimeout(() => {
        nextQuestion();
        setMessage("");
        setOpen(false);
      }, 1000);
      // setCorrectAnswer("");
    } else {
      // setOpen(true);
      // setSeverity("error");
      // setMessage(
      //   `Correct Ans is ${
      //     response?.data.data.questions[quesIndex].options.filter(
      //       (data) => data.is_correct === true
      //     )[0].title
      //   }`
      // );
      setTimeout(() => {
        nextQuestion();
        setMessage("");
        setOpen(false);
      }, 1000);
    }
    const nextQuestion = async () => {
      setBtnDisabled(false);

      if (quesIndex + 1 < response?.data.data.questions.length) {
        setQuesIndex(quesIndex + 1);
      } else {
        await updateScore();
        dispatch(handleChangeTestOver(true));
        // console.log(
        //   "%cTest Over",
        //   "color:orange;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold",
        //   score
        // );
      }
    };
  };

  const updateScore = async () => {
    try {
      await axios.patch(
        `${API}/scorecards/updateScore/${location.state?.scoreCardId}`,
        { end_time: new Date().toISOString(), score_updates: scoreUpdates },
        {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        }
      );
    } catch (err) {
      setOpen(true);
      setMessage(err.message);
      setSeverity("error");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
          position: "absolute",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImgs[categoryIndex % 5]})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "4vh",
        }}
      >
        <div
          style={{
            maxWidth: "1536px",
            display: "flex",
            width: "100%",
          }}
        >
          {/* <Box>{categoryObj[categoryIndex % 5].svgImg},</Box> */}
          <Box
            style={{
              width: "100%",
            }}
          >
            <div className="enablicText1">
              <GameRemote />
              <div className="toggleEnablicText"> Enablic</div>
            </div>
            <Card
              style={{
                marginTop: "4vh",
                padding: 1,
                paddingBottom: "2rem",
                marginLeft: "5%",
                width: "90%",
                // height: "526px",
                // maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
                background: "rgba(0,0,0,0.7)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "50px",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.19)",
                }}
              >
                {/* <div className="questionText">Question: {quesIndex + 1}</div> */}
                <div className="questionText">Q: {quesIndex + 1}</div>
                <div>
                  <Counter
                    start={true}
                    duration={location.state.test.duration}
                    updateScore={updateScore}
                  />
                </div>
                <div className="scoreText">
                  Score {score}/{location.state.test.max_score}{" "}
                </div>
              </div>
              <CardContent className="questionHeight">
                {loading ? (
                  <Box
                    style={{
                      minHeight: "calc(50vh - 120px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="secondary" />
                  </Box>
                ) : !testOver ? (
                  <div>
                    <div className="questionTitleTest">
                      {response?.data.data.questions[quesIndex].title}
                    </div>
                    {options.map((opt, i) => (
                      <Box mt={2}>
                        <motion.button
                          className="btn-options"
                          initial={{
                            background: "#ffffff00",
                          }}
                          whileTap={{
                            background: "#ffffff",
                            color: "#1A0F1F",
                            scale: 0.98,
                          }}
                          whileHover={{
                            backgroundColor: "#ffffffa8",
                            color: "#1A0F1F",
                          }}
                          onClick={(e) => handleClickAnwser(e, quesIndex)}
                          disabled={btnDisabled}
                        >
                          {opt}
                        </motion.button>
                      </Box>
                    ))}
                  </div>
                ) : (
                  // <Navigate to={"/score"} replace />
                  navigate("/score", {
                    state: { scoreCardId: location.state?.scoreCardId },
                  })
                )}
              </CardContent>
              <CustomizedSnackbars
                open={open}
                message={message}
                severity={severity}
              />
            </Card>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Questions;
