import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import { forgotpassword } from "./index";
import { Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Copyright } from "../../assets/icons/copyright.svg";
import { ReactComponent as Ecllipse1 } from "../../assets/icons/ellipse1.svg";
import { ReactComponent as Ecllipse2 } from "../../assets/icons/ellipse2.svg";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import "./Forgotpasword.css";

export default function Forgotpassword() {
  // const { signinData } = useSelector((state) => state);
  const navigate = useNavigate();
  // const { user } = isAutheticated();

  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;

  const handleCloseSnackbar = (event) => {
    setState({ ...state });
    setSuccess(false);
  };
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Please enter a valid Email Id")
          .required("This field is required"),
      }),
      onSubmit: (values) => {
        const data = forgotpassword({
          email: values.email.toLowerCase(),
          device_type: "web",
        });

        data
          .then((val) => {
            if (!val.status) {
              setState({ vertical: "bottom", horizontal: "left" });
              setSuccess(true);
              setMessage(`${val.error}`);
            } else {
              setState({ vertical: "bottom", horizontal: "left" });
              setSuccess(true);
              setMessage(`Email has been sent to reset your password.`);
              // console.log("value ", val.data.refId);
            }
          })
          .catch((err) => {
            setState({ vertical: "bottom", horizontal: "left" });
            setSuccess(true);
            setMessage("Unable to Reset password");
          });
      },
    });
  const handleBackToLogin = () => {
    navigate("/signin");
  };
  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <div className="ellipse1">
        <Ecllipse1 />
      </div>
      <div className="ellipse2">
        <Ecllipse2 />
      </div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card
          sx={{
            padding: 1,
            margin: 1,
            width: "442px",
            height: "90%",
            maxWidth: "90%",
            maxHeight: "calc(95vh - 68px)",
            justifyContent: "center",
            overflowY: "auto",
            borderRadius: "15px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CardContent>
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "30.5354px",
                lineHeight: "56px",
                fontFamily: "Circular Spotify Text",
                fontWeight: 900,
              }}
              variant="h3"
              component="h3"
            >
              Reset Password
            </Typography>
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "18px",
                lineHeight: "24px",
                fontFamily: "Circular Spotify Text",
                color: "#81899A",
                fontWeight: "light",
                textAlign: "center",
                maxWidth: "291px",
                margin: "8px auto 0",
              }}
              variant="h3"
              component="h3"
            >
              Hey! Enter your email to reset password
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "95%", maxwidth: "40ch" },
              }}
              style={{ marginTop: "30px" }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="Email-Id"
                label="Email"
                variant="outlined"
                required
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  style={{ marginTop: "0" }}
                  error
                >{`${errors.email}`}</FormHelperText>
              )}

              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    fontFamily: "Circular Spotify Text",
                    fontWeight: 900,
                    width: "100%",
                    textTransform: "none",
                    height: "50px",
                    marginLeft: "-8px",
                    borderRadius: "8px",
                    background:
                      "radial-gradient(98.44% 1567.43% at 97.46% 50.27%, #7DA9F8 0%, #7C90F0 100%)",
                  }}
                >
                  Submit
                </Button>
              </CardActions>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "13.5px",
                  margin: "23px auto",
                  justifyContent: "center",
                }}
              >
                <Link
                  underline="hover"
                  style={{ cursor: "pointer" }}
                  onClick={handleBackToLogin}
                >
                  Back to Log in
                </Link>
              </div>
            </Box>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={success}
          message={message}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontFamily: "Circular Spotify Text",
            fontWeight: 400,
            gap: "10px",
            margin: "30px auto 10px",
          }}
        >
          <Copyright />
          <span style={{ paddingRight: "10px", borderRight: "2px solid #fff" }}>
            Copyright @Enablic
          </span>
          <span>Privacy Policy</span>
        </Box>
      </div>
    </div>
  );
}
