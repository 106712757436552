import React from "react";
import PokeAMole from "../PokeAMole/PokeAMole";
import MathsQuizGame from "../MathQuizGame/MathsQuizGame";
import { ReactComponent as QuestionIcon } from "../assets/question.svg";
import Matching from "../assets/Matching.png";
import Poke_a_Mole from "../assets/PokeAMole.png";
import MentalMaths from "../assets/MentalMaths.png";
import bingo from "../assets/Bingo.png";
import puzzle from "../assets/Puzzle.png";
import sequence from "../assets/Sequence.png";
import sign_language from "../assets/SignLanguage.png";
import number_match from "../assets/NumberMath.png";
import tile_master from "../assets/TileMaster.png";
import wordle from "../assets/Wordle.png";
import BrainGym from "../assets/BrainGym";
import { StartMemory } from "../Memory/StartMemory";
import { StartSequence } from "../Sequence/StartSequence";
import { StartNumberMatch } from "../NumberMatch/StartNumberMatch";
import { StartTileMaster } from "../TileMaster/StartTileMaster";
import { StartSignLanguage } from "../SignLanguage/StartSignLanguage";
import { StartWordle } from "../Wordle/StartWordle";
import { StartBingo } from "../CountryAnimalName/StartBingo";
import { StartPuzzle } from "../Puzzle/StartPuzzle";

//This is a central database for the game details

export const gameData = {
  MatchingCards: {
    name: "MatchingCards",
    title: "Matching",
    subtitle: "Unlock",
    game: <StartMemory />,
    icon: <QuestionIcon />,
    image: Matching,
    brainGym: <BrainGym />,
    improves: ["Memory"],
    academicSkills: [],
    ageGroups: ["PrimarySchool", "SecondarySchool", "18"],
    abled: ["Dyslexia"],
    physical: [],
  },
  Wordle: {
    name: "Wordle",
    title: "Wordle",
    subtitle: "Unlock",
    game: <StartWordle />,
    icon: <QuestionIcon />,
    image: wordle,
    brainGym: <BrainGym />,
    improves: ["Language"],
    academicSkills: [],
    ageGroups: ["SecondarySchool", "18"],
    abled: [],
    physical: [],
  },
  MatchTheSequence: {
    name: "MatchTheSequence",
    title: "Sequence",
    subtitle: "Unlock",
    game: <StartSequence />,
    icon: <QuestionIcon />,
    image: sequence,
    brainGym: <BrainGym />,
    improves: ["Memory", "Attention"],
    academicSkills: [],
    ageGroups: ["PrimarySchool", "SecondarySchool", "18"],
    abled: ["ADHD"],
    physical: [],
  },
  SignLanguage: {
    name: "SignLanguage",
    title: "Sign Language",
    subtitle: "Unlock",
    game: <StartSignLanguage />,
    icon: <QuestionIcon />,
    image: sign_language,
    brainGym: <BrainGym />,
    improves: ["Perception", "Language"],
    academicSkills: [],
    ageGroups: ["SecondarySchool", "18"],
    abled: ["Abled"],
    physical: [],
  },

  NumberMatch: {
    name: "NumberMatch",
    title: "Number Match",
    subtitle: "Unlock",
    game: <StartNumberMatch />,
    icon: <QuestionIcon />,
    image: number_match,
    brainGym: <BrainGym />,
    improves: ["Memory"],
    academicSkills: [],
    ageGroups: ["PrimarySchool", "SecondarySchool", "18"],
    abled: ["Dyscalculia"],
    physical: [],
  },
  Bingo: {
    name: "Bingo",
    title: "Bingo",
    subtitle: "Unlock",
    game: <StartBingo />,
    icon: <QuestionIcon />,
    image: bingo,
    brainGym: <BrainGym />,
    improves: ["Language"],
    academicSkills: [],
    ageGroups: ["SecondarySchool", "18"],
    abled: [],
    physical: [],
  },
  Puzzle: {
    name: "Puzzle",
    title: "Puzzle",
    subtitle: "Unlock",
    game: <StartPuzzle />,
    icon: <QuestionIcon />,
    image: puzzle,
    brainGym: <BrainGym />,
    improves: ["DecisionMaking"],
    academicSkills: [],
    ageGroups: ["SecondarySchool", "18"],
    abled: [],
    physical: [],
  },
  PokeAMole: {
    name: "PokeAMole",
    title: "Pok-é-mole",
    subtitle: "Unlock",
    game: <PokeAMole />,
    icon: <QuestionIcon />,
    image: Poke_a_Mole,
    brainGym: <BrainGym />,
    improves: ["Attention"],
    academicSkills: [],
    ageGroups: ["PrimarySchool", "SecondarySchool", "18"],
    abled: ["ADHD"],
    physical: [],
  },

  MentalMath: {
    name: "MentalMath",
    title: "Mental Maths",
    subtitle: "Unlock",
    game: <MathsQuizGame />,
    icon: <QuestionIcon />,
    image: MentalMaths,
    brainGym: <BrainGym />,
    improves: ["LogicalReasoning"],
    academicSkills: ["Maths"],
    ageGroups: ["SecondarySchool", "18"],
    abled: [],
    physical: [],
  },
  TileMaster: {
    name: "TileMaster",
    title: "Tile Master",
    subtitle: "Unlock",
    game: <StartTileMaster />,
    icon: <QuestionIcon />,
    image: tile_master,
    brainGym: <BrainGym />,
    improves: ["Memory", "Attention"],
    academicSkills: [],
    ageGroups: ["PrimarySchool", "SecondarySchool", "18"],
    abled: ["ADHD"],
    physical: [],
  },
};
