import React from "react";
import { useEffect, useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import "./SignLanguage.css";
import { handleChangeStartGame } from "../../../../redux/actions";
import { solutions } from "./data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { gameData } from "../GameData/gameData";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { API } from "../../../../backend";
import { pathUrl } from "./data";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import Right from "../../../../assets/icons/Right";
import Wrong from "../../../../assets/icons/Wrong";
import GymMascot from "../../Mascot/GymMascot";

const handleCalls = (solutions) => {
  const randomIndex = Math.floor(Math.random() * solutions.length);
  return randomIndex;
};
const getRandomInt = (num) => Math.floor(Math.random() * num);

export const SignLanguage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [correctSolution, setCorrectSolution] = useState();
  const [correctCount, setCorrectCount] = useState(0); //calculate the correct counter
  const [wrongCount, setWrongCount] = useState(0); //calculate the wrong counter
  const [btnDisabled, setBtnDisabled] = useState(false); //disable the button
  const [options, setOptions] = useState([]); // display 3 options
  const [messageChange, setMessageChange] = useState(""); // change the message on correct or wrong
  const [replayGame, setReplayGame] = useState(false); //restart the game
  const [wrong, setWrong] = useState(false);
  const [open, setOpen] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [originalScore, setOriginalScore] = useState(0);
  const [missedScore, setMissedScore] = useState(0);
  const enablicJourneyScore = true;
  const { currentSchedule } = useSelector((state) => state);

  const handleBack = () => {
    navigate("/games");
  };

  const generateQuestion = () => {
    const optionsArr = [];
    let data = {};
    for (let i = 0; i < 3; i++) {
      const solutionIndex = handleCalls(solutions);
      data = { ...solutions[solutionIndex] };
      data.isCorrect = false;
      optionsArr.push(data);
    }
    let randomIndex = getRandomInt(3);
    data = { ...optionsArr[randomIndex] };
    data.isCorrect = true;
    optionsArr[randomIndex] = data;

    setOptions(optionsArr);
    setCorrectSolution(optionsArr[randomIndex]);
  };
  useEffect(() => {
    if (replayGame) {
      setOriginalScore(0);
      setReplayGame(false);
    }
    if (wrongCount !== 5) {
      generateQuestion();
    } else {
      axios
        .get(`${API}/games/getByName/${gameData["SignLanguage"].name}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          if (window.location.pathname === "/gameplay") {
            SubmitScore(originalScore, missedScore, val.data.data._id, false);
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              originalScore,
              missedScore,
              val.data.data._id,
              enablicJourneyScore,
              currentSchedule
            );
            setTimeout(() => {
              dispatch(handleChangeStartGame(false));
            }, 3000);
          }
        })
        .catch((err) => {
          setOpenSnackbar(true);
          setMessage(err.message);
          setSeverity("error");
        });

      setOpen(false);
      dispatch(handleChangeStartGame(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrongCount]); //useEffect to generate question at the begining
  const nextQuestion = async () => {
    //function to generate next question
    setWrong(false);
    setBtnDisabled(false);
    setMessageChange("");
    generateQuestion();
  };
  const handleClick = (e, i) => {
    if (e.target.value === correctSolution.word) {
      setMessageChange("Correct");
      setBtnDisabled(true);
      setCorrectCount((prev) => prev + 1);
      setOriginalScore((prev) => prev + 100);
      setTimeout(() => {
        nextQuestion();
      }, 2000);
    } else {
      setMessageChange("Incorrect");
      setOpenSnackbar(true);
      setMessage(`Correct answer: ${correctSolution.word}`);
      setSeverity("error");
      setWrongCount((prev) => prev + 1);
      setMissedScore((prev) => prev + 100);
      const newOptions = options.filter((_, index) => index !== i);
      setOptions(newOptions);
      setWrong(true);

      setTimeout(() => {
        setOpenSnackbar(false);
        setWrong(false);
        setBtnDisabled(false);
        setMessageChange("");
      }, 2000);
    }
  };
  useEffect(() => {
    if (replayGame) {
      setCorrectSolution();
      setCorrectCount(0);
      setWrongCount(0);
      setBtnDisabled(false);
      setOptions([]);
      setMessageChange("");
      setWrong(false);
      setOpen(true);
    }
  }, [replayGame]);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "1536px",
          display: "flex",
          width: "100%",
        }}
      >
        <Box
          style={{
            width: "100%",
          }}
        >
          <Card
            style={{
              padding: 1,
              // marginLeft: "5%",
              width: "50vw",
              height: "90vh",
              justifyContent: "center",
              borderRadius: "20px",
              background: "rgb(88 29 134 / 54%)",
              backdropFilter: "saturate(180%) blur(10px)",
            }}
          >
            {open ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    height: "50px",
                    alignItems: "center",
                    borderBottom: "1px solid rgba(39, 39, 39, 0.19)",
                  }}
                >
                  <div className="correctText">
                    <Right
                      width={32}
                      height={32}
                      style={{ marginRight: "0.5rem" }}
                    />{" "}
                    {correctCount}
                  </div>
                  <div className="correctText">
                    <Wrong
                      width={32}
                      height={32}
                      style={{ marginRight: "0.5rem" }}
                    />{" "}
                    {wrongCount}
                  </div>
                </div>
                <CardContent>
                  <motion.div
                    animate={wrong ? { x: [0, 20, -20, 0] } : { x: 0 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "356px",
                      flexDirection: "column",
                    }}
                  >
                    <video
                      style={{
                        width: "500px",
                        borderRadius: "5px",
                      }}
                      src={
                        correctSolution &&
                        `${pathUrl}${correctSolution.video.url}`
                      }
                      autoPlay
                      controls
                      muted
                    />

                    <div
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "7px",
                        color: "white",
                        fontWeight: "700",
                        fontSize: "24px",
                      }}
                    >
                      {messageChange}
                      {console.log("ans: ", correctSolution?.word)}
                      <div style={{ margin: "3px" }}>
                        {messageChange === "Correct" ? (
                          <>
                            <div
                              style={{
                                color: "rgb(49, 205, 97)",
                              }}
                            >
                              <i className="fas fa-check"></i>
                            </div>
                          </>
                        ) : messageChange === "Incorrect" ? (
                          <>
                            <div
                              style={{
                                color: "rgb(255, 37, 99)",
                              }}
                            >
                              <i className="fas fa-times-circle"></i>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* {messageChange === "Incorrect" ? (
                      <div style={{
                        color: "#fff",
                        fontSize: "20px",
                        fontWeight: "700"
                      }}>
                        <p>Answer is: {correctSolution.word}</p>
                      </div>) : (
                      <></>
                    )} */}
                    {!messageChange ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          width: "100%",
                          gap: "8px 8px",
                          marginTop: "20px",
                        }}
                      >
                        {options &&
                          options.map((data, i) => (
                            <motion.button
                              key={data.id}
                              style={{
                                borderRadius: "9px",
                                fontSize: "20px",
                                lineHeight: "30px",
                                color: "#ffffff",
                                padding: "5px 10px ",
                                borderColor: "#ffffff",
                                minWidth: "100px",
                              }}
                              initial={{
                                background: "#ff000000",
                              }}
                              whileTap={{
                                background: "#ffffff",
                                color: "#1A0F1F",
                                scale: 0.98,
                              }}
                              whileHover={{
                                backgroundColor: "#ffffffa8",
                                color: "#1A0F1F",
                              }}
                              value={data.word}
                              onClick={(e) => handleClick(e, i)}
                              disabled={btnDisabled}
                            >
                              {data.word}
                            </motion.button>
                          ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </motion.div>
                </CardContent>
              </div>
            ) : (
              <>
                {originalScore > 500 ? (
                  <>
                    <ConfettiEffect />
                    <WinnerMascot
                      textBubble={`Good Job ${localSigninData?.data?.user?.first_name}, you are doing well, keep going.`}
                    />
                  </>
                ) : (
                  <>
                    <GymMascot
                      textBubble={`It’s okay, let's play again! You will surely beat me this time...`}
                    />
                  </>
                )}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "#ffffff",
                  }}
                >
                  <h1>GAME OVER</h1>
                  <hr
                    style={{
                      height: "0",
                      width: "190px",
                      border: "0.5px solid #61dafb",
                    }}
                  />
                  <h3>
                    FINAL SCORE{" "}
                    <b style={{ color: "#61dafb", fontWeight: "bold" }}>
                      {originalScore}
                    </b>
                  </h3>

                  {window.location.pathname === "/gameplay" ? (
                    <div className="sign-lang-play-btn">
                      <button onClick={() => setReplayGame(true)}>
                        <span>Play Again</span>
                      </button>
                      <button onClick={handleBack}>
                        <span>Game Section</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Card>
        </Box>
      </div>
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div >
  );
};
