import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../backend";
import { Outlet, Navigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const PrivateUserAnalyticsRoute = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [roleManagement, setRoleManagement] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const checkRole = await axios.get(
        `${API}/users/getbyid/${localSigninData?.data?.user?._id}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      setRoleManagement(checkRole.data.data.role);
      setLoading(false);
    })();
  }, [roleManagement, loading]);

  return (
    loading ? (
      <div className="loaderCss" style={{ height: "100vh", background: "transparent" }}>
        <RingLoader
          color="#1abcf3"
          cssOverride={override}
          loading={loading}
          size={120}
          speedMultiplier={1}
        />
      </div>
    ) : (
      <>
        {roleManagement === "admin" ? (
          <>
            <Outlet />
          </>
        ) : (
          <>
            <Navigate to="/dashboard" replace />
          </>
        )
        }
      </>
    ))
};

export default PrivateUserAnalyticsRoute;
