import React, { useState, useEffect } from "react";
import { ReactComponent as LeftBrain } from "../assets/left-brain.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressBarCircular from "./Components/ProgressBarCircular";
import "./RightBarContentMobile.css";
import { handleExplore } from "../../../redux/actions";
import { motion } from "framer-motion";

import { Cancel } from "@mui/icons-material";

export const RightContent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exploreThePlatform } = useSelector((state) => state);

  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div className="right-bar-mobile">
      <div className="top">
        <Cancel
          onClick={() => {
            props.toggleDrawer();
          }}
        />
      </div>

      <div className="middle-card">
        {windowWidth > 950 ? (
          <LeftBrain
            style={{
              position: "absolute",
              bottom: 0,
              right: "-30%",
              height: "180px",
              transform: "translateY(35%) rotateZ(-30deg)",
            }}
          />
        ) : (
          ""
        )}
        <div className="text">Take a Quick Tour</div>
        <div className="sub-text">To have a walkthrough, how this works</div>
        <div className="explore">
          <motion.button
            whileHover={{ borderColor: "#7c90f0", boxShadow: "0px 0px 10px #7c90f0" }}
            onClick={() => {
              // console.log("explore");
              if (window.location.pathname === "/dashboard/enablicJourney") {
                navigate("/dashboard");
                props.toggleDrawer();
                setTimeout(() => {
                  dispatch(handleExplore(!exploreThePlatform));
                }, 3000);
              } else {
                props.toggleDrawer();
                dispatch(handleExplore(!exploreThePlatform));
              }
            }}
          >
            EXPLORE
          </motion.button>
        </div>
      </div>

      <div className="last-card">
        <ProgressBarCircular />
      </div>
    </div>
  );
};
