import React, { useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import { PerformanceContent } from "./Components/PerformanceContent";
import MobileViewNavBar from "../MobileViewNavBar/MobileViewNavBar";
import { PerformanceSectionMobile } from "./Components/PerformanceSectionMobile";

export const Performance = () => {
  const [sidebarToggled, setSidebarToggled] = useState(false);

  return (
    <div>
      <div className="webView">
        <SideNavBar setSidebarToggled={setSidebarToggled} />
        <PerformanceContent sidebarWidth={!sidebarToggled ? 65 : 240} />
      </div>
      <div className="mobileView">
        <MobileViewNavBar />
        <PerformanceSectionMobile />
      </div>
    </div>
  );
};
