import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as PlayBtn } from "../assets/play.svg";
import {
  handleChangeStartGame,
  handleChangeEnablicJourneyTutorial,
  handleChangeProgressItemTutorial,
} from "../../../redux/actions";
import { gameData } from "../../common/Games/GameData/gameData";
import axios from "axios";
import CustomizedSnackbars from "../../common/Snackbar/CustomizedSnackbars";
import { API } from "../../../backend";

import { useDispatch, useSelector } from "react-redux";
import EnablicJourneyGame from "../../Tutorial/EnablicJourneyGame/EnablicJourneyGame";

export const MainContent = (props) => {
  const dispatch = useDispatch();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [counter, setCounter] = useState(3);
  const [startGame, setStartGame] = useState(false);
  const [userScoreData, setuserScoreData] = useState(0);
  const [leaderScoreData, setLeaderScoreData] = useState(0);
  const [gamePlayed, setGamePlayed] = useState([]);
  const [gotRes, setGotRes] = useState();
  const { progressItemTutorial, exploreThePlatform } = useSelector(
    (state) => state
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleStartGame = () => {
    // console.log(
    //   "%cstore",
    //   "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold",
    //   store.getState()
    // );
    setStartGame(true);
    // if (counter === 0) {
    //   dispatch(handleChangeStartGame(true));
    // }
  };
  useEffect(() => {
    axios
      .get(`${API}/users/getCurrentJourney/${localSigninData.data.user._id}`, {
        headers: {
          token: `${localSigninData && localSigninData.data.token}`,
        },
      })
      .then((val) => {
        //setting response
        setGotRes(val);
        // let scheduleNo;
        let played = [];
        // console.log("val -----66 ", val.data.data.journey);
        Object.values(val.data.data.journey).forEach((data, i) => {
          if (data.played) {
            played.push(i);
          }
          setGamePlayed(played);
        });
      })
      .catch((err) => {
        setGotRes();
        setOpenSnackbar(true);
        setMessage(err.message);
        setSeverity("error");
      });
    return () => {
      dispatch(handleChangeProgressItemTutorial(false));
      dispatch(handleChangeEnablicJourneyTutorial(false));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (counter === 0) {
      dispatch(handleChangeStartGame(true));
    }
    if (startGame) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, startGame]);

  useEffect(() => {
    try {
      (async () => {
        const scoreDataResponse = await axios.post(
          `${API}/users/getHighScore`,
          {
            user_id: `${localSigninData?.data?.user?._id}`,
            game_name: `${gameData[`${props.progressItems.name}`].name}`,
          },
          {
            "Content-Type": "application/json",
            headers: {
              token: `${localSigninData && localSigninData.data.token}`,
            },
          }
        );
        setuserScoreData(scoreDataResponse.data.data);
        const leaderScoreResponse = await axios.get(
          `${API}/games/getByName/${props.progressItems.name}`,
          {
            headers: {
              token: localSigninData?.data?.token,
            },
          }
        );
        setLeaderScoreData(
          leaderScoreResponse?.data?.data?.leaderboard?.top_score
        );
      })();
    } catch (err) {
      setOpenSnackbar(true);
      setMessage(err.message);
      setSeverity("error");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.progressItems && props.progressItems.name]);

  return (
    // console.log("gamePlayed ", gamePlayed),
    // console.log(
    //   "%cstore",
    //   "color:red;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold",
    //   store.getState()
    // ),
    // console.log("props.progressItems.name ", props.progressItems),
    // console.log("progressItemTutorial ", progressItemTutorial),
    // console.log(
    //   "props.progressItems.name ",
    //   parseInt(props.progressItems.scheduleNo)
    // ),
    // console.log("props.gamePlayed", props.gamePlayed),
    // console.log(
    //   "props.gamePlayed.includes(props.progressItems.scheduleNo) ",
    //   props.gamePlayed.includes(parseInt(props.progressItems.scheduleNo))
    // ),
    <div className="content">
      <div className="icon">
        {props.progressItems && props.progressItems.brainGym}
      </div>
      <div className="title">
        {props.progressItems && props.progressItems.title}
      </div>
      <div className="subtitle">
        {props.progressItems && props.progressItems.subtitle}
      </div>

      <div className="scores">
        <div className="left">
          <p>Your highest score</p>
          <p id="yourHighestScore">{userScoreData}</p>
        </div>
        <div className="right">
          <p>Leader's Score</p>
          <p id="leadersScore">{leaderScoreData}</p>
        </div>
      </div>

      <div
        className="dashboard-play-btn"
        style={{ marginTop: "58px", marginRight: 0, marginBottom: "240px" }}
      >
        {gotRes ? (
          startGame ? (
            <Box mt={6}>
              <p className="gameNameText">{counter}</p>
            </Box>
          ) : gamePlayed.includes(parseInt(props.progressItems.scheduleNo)) ? (
            ""
          ) : (
            <>
              {progressItemTutorial && exploreThePlatform ? (
                <EnablicJourneyGame />
              ) : (
                ""
              )}
              <button onClick={handleStartGame}>
                <PlayBtn /> <span id="playNow">PLAY NOW</span>
              </button>
            </>
          )
        ) : null}
      </div>
      <CustomizedSnackbars
        open={openSnackbar}
        message={message}
        severity={severity}
      />
    </div>
  );
};
