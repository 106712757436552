import { useEffect, useState } from "react";
import { solutions } from "./data/data";
import { Wordle } from "./Wordle";
import { Typography, Box, Modal } from "@mui/material";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const WordleGame = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [solution, setSolution] = useState(null);
  const [meaning, setMeaning] = useState(null);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const handleCalls = (solutions) => {
      const randomIndex = Math.floor(Math.random() * solutions.length);
      return randomIndex;
    };
    const solutionIndex = handleCalls(solutions);
    setSolution(solutions[solutionIndex].word);
    setMeaning(solutions[solutionIndex].meaning);
  }, []);
  const handleClickInfo = () => {
    setMsg(
      "You have to guess the Wordle in six goes or less. A correct letter turns green. A correct letter in the wrong place turns yellow. An incorrect letter turns gray. Letters can be used more than once"
    );
    handleOpen();
  };
  const handleClickHelp = () => {
    setMsg(`Word Hint: ${meaning}`);
    handleOpen();
  };
  return (
    // console.log("solution", solution),
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backdropFilter: "blur(10px)",
            background:
              "linear-gradient(93.5deg, #7f28a663 4.67%, #fefbff2e 96.2%)",
            padding: 5,
            mt: 1,
            width: "90%",
            height: "90%",
            maxWidth: 500,
            maxHeight: "calc(95vh - 68px)",
            justifyContent: "center",
          }}
        >
          {solution && <Wordle solution={solution} meaning={meaning} />}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          disableRestoreFocus={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {msg}
            </Typography>
          </Box>
        </Modal>
      </div>
      <Box
        style={{ position: "fixed", right: "10px", bottom: "20px" }}
        onClick={handleClickHelp}
      >
        <Fab aria-label="like">
          <HelpIcon />
        </Fab>
      </Box>
      <Box
        style={{ position: "fixed", right: "10px", bottom: "100px" }}
        onClick={handleClickInfo}
      >
        <Fab aria-label="like">
          <InfoIcon />
        </Fab>
      </Box>
    </div>
  );
};
export default WordleGame;
