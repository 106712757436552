import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../../Insights.css";

export default function DropdownAnalytics(props) {
  const [assessmentScore, setAssessmentScore] = useState("Psychometric");
  const [assessmentLevel, setAssessmentLevel] = useState("Level1");
  const [duration, setDuration] = useState("Current");

  const handleChangeAssessmentScore = (event) => {
    // console.log("event ",event);
    // console.log("event.target.value",event.target.value);
    setAssessmentScore(event.target.value);
    // console.log("assessmentScore ",assessmentScore);
    // console.log("props ",props);
    props.assessmentScore(event.target.value);
  };
  const handleChangeAssessmentLevel = (event) => {
    setAssessmentLevel(event.target.value);
    props.assessmentLevel(event.target.value);
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
    props.duration(event.target.value);
  };

  return (
    // console.log("props dropdown ",props),
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "80px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 250,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{
                color: "#ffffff",
              }}
            >
              Assessment Score
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={assessmentScore}
              onChange={handleChangeAssessmentScore}
              autoWidth
              label="Assessment Score"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value={"EQ"}>Emotional Quotient (EQ)</MenuItem>
              <MenuItem value={"IQ"}>Intelligence Quotient (IQ)</MenuItem>
              <MenuItem value={"Psychometric"}>Psychometric</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{
                color: "#ffffff",
              }}
            >
              Assessment Level
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={assessmentLevel}
              onChange={handleChangeAssessmentLevel}
              autoWidth
              label="Assessment Level"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value={"Level1"}>Level 1</MenuItem>
              <MenuItem value={"Level2"}>Level 2</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 160,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              sx={{
                color: "#ffffff",
              }}
              id="demo-simple-select-autowidth-label"
            >
              Duration
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={duration}
              onChange={handleChangeDuration}
              autoWidth
              label="Duration"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value={"Current"}>Current</MenuItem>
              <MenuItem value={"History"}>History</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}