import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as Copyright } from "../../assets/icons/copyright.svg";
import { ReactComponent as Ecllipse1 } from "../../assets/icons/ellipse1.svg";
import { ReactComponent as Ecllipse2 } from "../../assets/icons/ellipse2.svg";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import { resetPassword } from "./index";
import "./ResetPassword.css";

export default function ResetPassword() {
  // const { signinData } = useSelector((state) => state);
  const navigate = useNavigate();
  let { id, token } = useParams();
  // const { user } = isAutheticated();

  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickConfirm = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleCloseSnackbar = (event) => {
    setState({ ...state });
    setSuccess(false);
  };
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: {
        password: "",
        confirm: "",
      },
      validationSchema: Yup.object({
        password: Yup.string()
          .required("No password provided")
          .min(8, "Password is too short - should be 8 chars minimum."),
        // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
        confirm: Yup.string()
          .required("This field is required")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      }),
      onSubmit: (values) => {
        // console.log("values", values);
        const data = resetPassword({
          reset_token: token,
          user_id: id,
          new_password: values.password,
        });
        data.then((val) => {
          console.log("val", val);
          if (val.data.error.length > 0) {
            setState({ vertical: "bottom", horizontal: "left" });
            setSuccess(true);
            setMessage(`${val.data.error}`);
          } else {
            setState({ vertical: "bottom", horizontal: "left" });
            setSuccess(true);
            setMessage("Password reset is successfull");
            setTimeout(() => {
              navigate("/signin");
            }, 2000);
          }
        });
      },
    });
  
  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <div className="ellipse1">
        <Ecllipse1 />
      </div>
      <div className="ellipse2">
        <Ecllipse2 />
      </div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card
          sx={{
            padding: 1,
            margin: 1,
            width: "442px",
            height: "90%",
            maxWidth: "90%",
            maxHeight: "calc(95vh - 68px)",
            justifyContent: "center",
            overflowY: "auto",
            borderRadius: "15px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CardContent>
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "30.5354px",
                lineHeight: "56px",
                fontFamily: "Circular Spotify Text",
                fontWeight: 900,
              }}
              variant="h3"
              component="h3"
            >
              Reset Password
            </Typography>
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: "18px",
                lineHeight: "24px",
                fontFamily: "Circular Spotify Text",
                color: "#81899A",
                fontWeight: "light",
                textAlign: "center",
                maxWidth: "291px",
                margin: "8px auto 0",
              }}
              variant="h3"
              component="h3"
            >
              Hey! Enter new password
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "95%", maxwidth: "40ch" },
              }}
              style={{ marginTop: "30px" }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClick} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              {touched.password && errors.password && (
                <FormHelperText error>{`${errors.password}`}</FormHelperText>
              )}
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickConfirm} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Confirm Password"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                required
                name="confirm"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirm}
              />
              {touched.confirm && errors.confirm && (
                <FormHelperText error>{`${errors.confirm}`}</FormHelperText>
              )}

              <CardActions>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    fontFamily: "Circular Spotify Text",
                    fontWeight: 900,
                    width: "100%",
                    textTransform: "none",
                    height: "50px",
                    marginLeft: "-8px",
                    borderRadius: "8px",
                    background:
                      "radial-gradient(98.44% 1567.43% at 97.46% 50.27%, #7DA9F8 0%, #7C90F0 100%)",
                  }}
                >
                  Submit
                </Button>
              </CardActions>
            </Box>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={success}
          message={message}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontFamily: "Circular Spotify Text",
            fontWeight: 400,
            gap: "10px",
            margin: "30px auto 10px",
          }}
        >
          <Copyright />
          <span style={{ paddingRight: "10px", borderRight: "2px solid #fff" }}>
            Copyright @Enablic
          </span>
          <span>Privacy Policy</span>
        </Box>
      </div>
    </div>
  );
}
