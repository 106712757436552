import * as React from "react";

const Trophy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 11518 12668"
  >
    <defs>
      <radialGradient
        id="id2"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-.6471 -1.13399 1.18635 -.61854 4480 13266)"
        cx={5731.36}
        cy={4180.78}
        r={3080.47}
        fx={5731.36}
        fy={4180.78}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: "#fede43",
          }}
        />
        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: "#fcc204",
          }}
        />
      </radialGradient>
      <style>
        {
          ".str0{stroke:#2b2a29;stroke-width:17.981}.fil4{fill:#fdeb07}.fil3{fill:#fff}"
        }
      </style>
      <mask id="id0">
        <radialGradient
          id="id1"
          gradientUnits="userSpaceOnUse"
          cx={1568.57}
          cy={201.675}
          r={0}
          fx={1568.57}
          fy={201.675}
        >
          <stop
            offset={0}
            style={{
              stopOpacity: 0.94902,
              stopColor: "white",
            }}
          />
          <stop
            offset={0.871}
            style={{
              stopOpacity: -127.976,
              stopColor: "white",
            }}
          />
          <stop
            offset={1}
            style={{
              stopOpacity: 1,
              stopColor: "white",
            }}
          />
        </radialGradient>
        <path
          style={{
            fill: "url(#id1)",
          }}
          d="M1560 193h8344v7976H1560z"
        />
      </mask>
    </defs>
    <g id="Layer_x0020_1">
      <g id="_2267944879552">
        <path
          className="str0"
          d="M3833 10567s1284 19 1368 12c83-7 1796-12 1796-12l820 47 321 441v416l-23 785-203 119-2842 71-1677-59v-988l226-713 214-119z"
          style={{
            fill: "#b5651d",
          }}
        />
        <path
          className="str0"
          style={{
            mask: "url(#id0)",
            fill: "url(#id2)",
          }}
          d="M2544 2927c59 0 1332-203 1332-203s595-380 666-547c71-166 452-1011 452-1011l535-927s488-84 535 0c48 83 381 630 381 630l714 1415s392 417 475 417c84 0 655 35 857 107 202 71 749 142 832 190 84 48 571 119 571 119l-107 702-595 547-689 678-250 630 24 845 154 868-24 773h-677l-1582-845-536-154-440 238-808 416-595 333-523-12-179-547s131-809 167-880c35-72 107-785 107-785l-167-738-487-511-536-511-475-512-107-440 333-226 642-59z"
        />
        <path
          className="str0"
          d="m4654 10270 523-1832 59-916 376-361s786 135 754 195c-32 59-83 844-83 844l274 1035 333 785 107 547-1059-48-737 60-547-309z"
          style={{
            fill: "#f08219",
          }}
        />
        <path
          className="fil3"
          d="M3612 11177c0-235 164-429 404-429h3485c240 0 404 194 404 429v1061H3612v-1061zm1843-3687c316-167 291-167 607 0 0 531 64 1130 166 1576 56 243 110 448 204 655l286 598H4799c17-65 104-208 143-286 388-778 513-1625 513-2543zM1768 3449c0-334 163-297 579-355l1500-218c634-105 633-178 944-799l715-1457c140-291 380-261 497-17l429 859c128 255 454 996 595 1148 182 195 288 214 602 257l1894 277c296 52 315 327 16 575-107 90-154 131-251 229-276 276-546 572-845 822-622 520-510 706-408 1327l191 1122c46 280 238 827-169 827-87 0-1151-589-1356-691-455-227-603-395-1018-395-177 0-596 260-834 379-213 106-1297 707-1389 707-118 0-195-55-237-144-72-150 192-1403 234-1653 195-1175 193-880-736-1809-166-166-313-313-480-479-130-131-473-410-473-512zm-429-50c0 364 441 726 699 941 230 192 697 724 937 933 46 41 71 49 107 95 72 88 74 173 51 303-43 238-86 480-124 711-36 217-231 1255-231 1436 0 314 295 632 606 632 372 0 536-153 893-345 256-138 499-255 749-388 0 786-203 1823-646 2461-53 75-64 58-86 141-338 0-574 20-763 172-160 129-323 330-323 611v1136c-400 0-758-76-758 202 0 180 147 228 328 228h5961c470 0 378-430 126-430h-556v-1136c0-281-164-482-324-611-188-152-424-172-762-172-19-71-84-136-153-251-397-665-579-1542-579-2351 236 126 1328 733 1515 733 248 0 345-1 550-208 305-307 152-684 78-1127-38-232-83-471-123-711-193-1164-319-779 537-1635 128-128 286-308 419-414 109-87 546-497 615-623 202-369 40-770-269-928-389-199-2298-257-2483-472-68-79-261-504-326-634L6356 376c-267-505-926-492-1187-17-132 241-918 1899-982 1973-107 124-598 160-776 184-281 38-1519 198-1690 280-214 102-382 355-382 603z"
        />
        <path
          className="fil3"
          d="M5026 11708h1465c384 0 322-429 50-429H4975c-271 0-333 429 51 429z"
        />
        <path
          className="fil4"
          d="M10380 4080c0 86 27 107 68 160l194 261c186 231 526-35 301-301-131-156-201-322-361-322-122 0-202 80-202 202zM10380 2666c0 135 184 385 462 7 153-208 170-143 170-360 0-107-220-237-359-81-74 82-273 332-273 434zM505 2313c0 217 17 152 170 360 277 378 462 128 462-7 0-107-83-177-166-289-37-48-60-86-96-131-147-182-370-36-370 67zM505 4358c0 276 259 269 359 157l194-261c179-229 21-376-124-376-162 0-429 382-429 480zM10734 3373c0 262 294 202 581 202 271 0 271-404 0-404-287 0-581-59-581 202zM0 3373c0 262 294 202 581 202 271 0 271-404 0-404-287 0-581-59-581 202z"
        />
      </g>
    </g>
  </svg>
);

export default Trophy;
