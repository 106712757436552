import React, { useState, useEffect } from "react";
import { HorizontalChart } from "./HorizontalChart";

import "./PerformanceSection.css";

export const PerformanceSectionMobile = () => {
  const [, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    // console.log("academicSkillsGame ", academicSkillsGame),
    // console.log("cognitiveSkillsGame ", cognitiveSkillsGame),
    // console.log("ageSkillsGame ", ageSkillsGame),
    // console.log("abledSkillsGame ", abledSkillsGame),
    // console.log("gameNameData ", gameNameData),
    // console.log("windowWidth ", windowWidth),
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div className="frosted-glass">
            <div className="game-section-header-bg">
              <div className="game-section-header-content">
                <div className="game-section-header-content-text">
                  <div>
                    <div className="game-section-header-content-text-heading">
                      Performance
                    </div>
                    <div className="game-section-header-content-text-description">
                      We provide Mind train Games Everyday to our users for
                      Free.
                    </div>
                  </div>
                </div>
                <div>
                <img
            src={"/assets/performance-png-9602.png"}
            className="game-section-header-content-text-img"
            alt="graph"
          />
                  {/* <GamePlayBrain className="game-section-header-content-text-img" /> */}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  display: "flex",
                }}
              >
                <div className="game-section-header-bottom-bg">
                  <div className="game-section-header-bottom-text">
                    A daily taste of assorted cognitive tasks.
                  </div>
                </div>
              </div>
            </div>
          <h3 style={{ color: "#ffffff" }}>Brain Attribute Chart</h3>
          <HorizontalChart />
          <h3 style={{ color: "#ffffff" }}>Total Games Played: 43</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
