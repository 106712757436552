import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function AssessmentDropdownAttribute(props) {
  const [assessments, setAssessments] = useState("");

  const handleChangeAssessments = (event) => {
    setAssessments(event.target.value);
    props.assessment(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "80px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <FormControl
            sx={{
              m: 1,
              width: 300,
              borderRadius: "10px",
              background: "#51355E",
              "& .MuiInputLabel-root": {
                color: '#fff',
                '&.Mui-focused': {
                  color: '#fff'
                }
              },
              "& .MuiOutlinedInput-root": {
                // "& > fieldset": { borderColor: "#fff" },
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff',
                }
              }
            }}
            color="secondary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{
                color: "#ffffff",
              }}
            >
              Cognitive Assessment
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={assessments}
              onChange={handleChangeAssessments}
              autoWidth
              label="Academic Skills"
              sx={{
                color: "#ffffff",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"EQ"}>Emotional Quotient (EQ)</MenuItem>
              <MenuItem value={"IQ"}>Intelligence Quotient (IQ)</MenuItem>
              <MenuItem value={"Psychometric"}>Psychometric</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
