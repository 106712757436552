import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import InsightsDropdownAttribute from "./InsightsDropdownAttribute/InsightsDropdownAttribute";
import { InsightsData } from "./InsightsData/InsightsData";
import axios from "axios";
import { API } from "../../../backend";
import { Box, Card, CardActionArea, Grid, CardContent } from "@mui/material";
import { GameContentHeader } from "../../common/Games/GameContentHeader";
import { GenrePieChart } from "./GenrePieChart/GenrePieChart";

ChartJS.register(ArcElement, Tooltip, Legend);

// const randomColorGenerator = () => {
//   return Math.random().toString(16).substr(-6);
// };

export const InsightsContent = ({ sidebarWidth }) => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [assessmentScore, setAssessmentScore] = useState("Psychometric");
  const [assessmentLevel, setAssessmentLevel] = useState("Level1");
  const [duration, setDuration] = useState("Current");
  const [insightList, setInsightList] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [genreLabel, setGenreLabel] = useState([]);
  // const [colorArray, setColorArray] = useState([]);
  const [individualGenreScore, setIndividualScore] = useState([]);
  const pieChartBackgroundColor = [
    "rgba(255, 99, 132)",
    "rgba(255, 159, 64)",
    "rgba(255, 205, 86)",
    "rgba(75, 192, 192)",
    "rgba(54, 162, 235)",
    "rgba(153, 102, 255)",
    "rgba(233 233 233 / 53%)",
  ];

  const data = {
    labels: [...genreLabel, "Missed Score"],
    datasets: [
      {
        data: scoreData,
        backgroundColor: pieChartBackgroundColor,
        borderColor: ["rgb(233 233 233 / 23%)"],
        borderWidth: 3,
      },
    ],
    options: {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            color: "white",
            usePointStyle: true,
            font: {
              size: 12,
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    const lists = Object.values(InsightsData).filter(
      (insightsFilterData, i) => {
        // console.log("insightsFilterData ", insightsFilterData);
        return (
          (!assessmentScore ||
            insightsFilterData.type.includes(assessmentScore)) &&
          (!assessmentLevel ||
            insightsFilterData.type.includes(assessmentLevel)) &&
          (!duration || insightsFilterData.type.includes(duration))
        );
      }
    );
    // console.log("lists ", lists);
    setInsightList(lists);
  }, [assessmentLevel, assessmentScore, duration]);

  const handleAssessmentScore = (val) => {
    if (val.length !== 0) {
      setAssessmentScore(val);
    } else {
      setAssessmentScore();
    }
  };
  const handleAssessmentLevel = (val) => {
    if (val.length !== 0) {
      setAssessmentLevel(val);
    } else {
      setAssessmentLevel();
    }
  };
  const handleDuration = (val) => {
    if (val.length !== 0) {
      setDuration(val);
    } else {
      setDuration();
    }
  };

  useEffect(() => {
    (async () => {
      let lastTestData;
      let lastTestGenreLength;
      try {
        const getUserScoreDetails = await axios.get(
          `${API}/scorecards/getScoreHistoryByUserId/${localSigninData?.data?.user?._id}`,
          {
            headers: {
              token: localSigninData?.data?.token,
            },
          }
        );
        lastTestData = getUserScoreDetails.data.data;
        lastTestGenreLength = lastTestData.length - 1;
        // console.log(
        //   "no of genres ",
        //   Object.entries(lastTestData[lastTestGenreLength].groupedScore).length
        // );
        // console.log(
        //   "getUserScoreDetails.data.data",
        //   lastTestData[lastTestGenreLength].groupedScore
        // );
        // console.log("lastTestData", lastTestData[lastTestGenreLength]);
        let percentage = Object.entries(
          lastTestData[lastTestGenreLength].groupedScore
        ).map((data) => {
          // console.log("data[1].percentageScore", data[1].percentageScore);
          return (data[1]["actualScore"] / data[1]["totalScore"]) * 100;
        });
        setIndividualScore([...percentage]);
        // console.log("percentage ", percentage);
        let genreLabelArr = Object.entries(
          lastTestData[lastTestGenreLength].groupedScore
        ).map((data) => {
          // console.log("data[0]", data[0]);
          return data[0].split(/(?=[A-Z])/).join(" ");
        });

        setGenreLabel(genreLabelArr);
        // setGenreLabel([...genreLabelArr,"Missed Score"]);
        // console.log("genreLabel ", genreLabel);
        let missedScore =
          100 * percentage.length -
          percentage.reduce((total, currentValue) => {
            return total + currentValue;
          }, 0);
        percentage.push(missedScore);

        // converting absolute values to percentages
        let total_scores = percentage.reduce((t, v) => t + v);
        percentage = percentage.map((v) => 100 * (v / total_scores));

        setScoreData(percentage);
        // console.log("percentage ->",percentage);
      } catch { }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is for generating random color based upon no of Genre
  // useEffect(() => {
  //   const arrayOfRandomColor = () => {
  //     const randomColorArray = scoreData.map(() => {
  //       return "#" + randomColorGenerator(); // generate an array of random colors
  //     });
  //     setColorArray(randomColorArray);
  //     console.log("randomColorArray ", randomColorArray);
  //   };
  //   if (scoreData.length > 0) {
  //     arrayOfRandomColor();
  //   }
  // }, [scoreData]);

  return (
    // console.log("assessmentScore ", assessmentScore),
    // console.log("assessmentLevel", assessmentLevel),
    // console.log("duration ", duration),
    // console.log("insightList ", insightList),
    (
      // console.log("scoreData ", scoreData),
      // console.log("scoreData length ", scoreData.length),
      // console.log("genreLabel", genreLabel),
      // console.log("individualGenreScore ", individualGenreScore),
      // console.log("individualGenreScore ", individualGenreScore),
      <div>
        <div
          style={{
            marginLeft: sidebarWidth,
            width: `calc(100% - ${sidebarWidth}px)`,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <GameContentHeader />

          <div className="insightsSection">
            <h1 className="insightsText">Assessment Insights</h1>
            <div
              style={{
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                width: "100%",
                // minWidth: "900px",
              }}
              id="selectInsights"
            >
              <InsightsDropdownAttribute
                assessmentScore={(val) => {
                  handleAssessmentScore(val);
                }}
                assessmentLevel={(val) => {
                  handleAssessmentLevel(val);
                }}
                duration={(val) => {
                  handleDuration(val);
                }}
              />
            </div>
            {assessmentScore === "Psychometric" &&
              assessmentLevel === "Level1" &&
              duration === "Current" ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "rgb(233 233 233 / 23%)",
                      backdropFilter: "blur(2px)",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        flexShrink: 0,
                        display: "block",
                        fontSize: "1.5em",
                        paddingLeft: "1.2rem",
                        paddingTop: "1.2rem",
                        boxSizing: "border-box",
                        fontWeight: "bold",
                        color: "#ffffff",
                      }}
                    >
                      Total Score Distribution
                    </div>
                    <div
                      style={{
                        width: "400px",
                        height: "400px",
                        marginTop: "40px",
                      }}
                    >
                      <Pie data={data} options={data.options}></Pie>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={"/assets/enabliccharecter3.gif"}
                      alt={"brain"}
                      width={400}
                    />
                  </div>
                </div>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {genreLabel.length > 0 ? (
                      genreLabel.map((data, index) => (
                        <Grid item xs={6} md={4} sm={6} lg={3}>
                          <Card
                            sx={{
                              maxWidth: 345,
                              borderRadius: "10px",
                              background: "rgb(233 233 233 / 23%)",
                              backdropFilter: "blur(1px)",
                              boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.31)",
                            }}
                            id="playTheGame"
                          >
                            <CardActionArea>
                              <CardContent style={{ position: "relative" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    marginBottom: "15px",
                                    flexShrink: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "1.3em",
                                    boxSizing: "border-box",
                                    fontWeight: "bold",
                                    color: "#ffffff",
                                  }}
                                >
                                  {data}
                                </div>
                                <GenrePieChart
                                  genreLabel={[`${data}`, "Missed Score"]}
                                  colorArray={[
                                    pieChartBackgroundColor[index],
                                    "rgba(233 233 233 / 53%)",
                                  ]}
                                  individualGenreScore={[
                                    individualGenreScore[index],
                                    100 - individualGenreScore[index],
                                  ]}
                                />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <h1
                        style={{
                          width: "100%",
                          height: "600px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#51355E",
                        }}
                      >
                        More to come on this space
                      </h1>
                    )}
                  </Grid>
                </Box>
              </>
            ) : (
              <h1
                style={{
                  width: "100%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#51355E",
                }}
              >
                Yet to be assessed.
              </h1>
            )}
          </div>
        </div>
      </div>
    )
  );
};
