import axios from "axios";
import { API } from "../../../../backend";

export const SubmitScore = (
  originalScore,
  missedScore,
  gameId,
  enablicJourneyScore,
  currentSchedule,
  groupedScore
) => {
  const userId = JSON.parse(localStorage.getItem("jwt"))?.data.user._id;
  const localSigninData = JSON.parse(localStorage.getItem("jwt"))?.data?.token;

  const objData = {};
  if (enablicJourneyScore) {
    axios
      .get(`${API}/users/getCurrentJourney/${userId}`, {
        headers: {
          token: localSigninData,
        },
      })
      .then((val) => {
        objData["user_id"] = userId;
        objData["game_id"] = gameId;
        objData["original_score"] = originalScore;
        objData["missed_score"] = missedScore;
        objData["journey_detail"] = {
          cycle_no: val.data.data.current_journey_cycle,
          schedule_no: parseInt(currentSchedule),
        };
        if (groupedScore !== null) {
          objData["grouped_score"] = groupedScore;
        }
        // console.log("objData.game_id ", objData.game_id);
        if (objData.game_id?.length > 0) {
          submitGameScore(objData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  } else {
    objData["user_id"] = userId;
    objData["game_id"] = gameId;
    objData["original_score"] = originalScore;
    objData["missed_score"] = missedScore;
    if (groupedScore !== null) {
      objData["grouped_score"] = groupedScore;
    }
    if (objData.game_id?.length > 0) submitGameScore(objData);
  }
  // console.log("objData", objData);
  function submitGameScore(objData) {
    return axios
      .post(`${API}/users/submitScore`, objData, {
        headers: {
          token: localSigninData,
        },
      })
      .then((val) => {
        return val;
      })
      .catch((err) => {
        console.error(err);
      });
  }
};
