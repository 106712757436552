import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../backend";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as CaretCircleLeft } from "../../assets/icons/CaretCircleLeft.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as GameIcon } from "../../assets/icons/playcircle.svg";
import { ReactComponent as PerformanceIcon } from "../../assets/icons/gauge.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/insights.svg";
import { ReactComponent as EnablicIcon } from "../../assets/icons/enablic.svg";
import UserList from "../common/Games/assets/UserList";
import GraduationCap from "../../assets/icons/GraduationCap";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const listItemData = [
  { label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },
  { label: "Games", link: "/games", icon: <GameIcon /> },
  { label: "Performance", link: "/performance", icon: <PerformanceIcon /> },
  {
    label: "Assessments",
    link: "/assessments",
    icon: <GraduationCap width={25} height={25} />,
  },
  { label: "Insights", link: "/insights", icon: <InsightsIcon /> },
  {
    label: "User Analytics",
    link: "/userAnalytics",
    icon: <UserList height={25} width={25} />,
  },
];

const listItemDataUser = [
  { label: "Dashboard", link: "/dashboard", icon: <HomeIcon /> },
  { label: "Games", link: "/games", icon: <GameIcon /> },
  { label: "Performance", link: "/performance", icon: <PerformanceIcon /> },
  {
    label: "Assessments",
    link: "/assessments",
    icon: <GraduationCap width={25} height={25} />,
  },
  { label: "Insights", link: "/insights", icon: <InsightsIcon /> },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: "yellow",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNavBar({ setSidebarToggled }) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [roleManagement, setRoleManagement] = useState();
  const [loading, setLoading] = useState(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    setSidebarToggled(toggle);
  }, [toggle]); //eslint-disable-line


  useEffect(() => {
    (async () => {
      const checkRole = await axios.get(
        `${API}/users/getbyid/${localSigninData?.data?.user?._id}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      // console.log("checkRole ", checkRole.data.data.role);
      setRoleManagement(checkRole.data.data.role);
      setLoading(false);
    })();
  }, [roleManagement, loading]);

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        PaperProps={{
          sx: {
            backdropFilter: "blur(90px)",
            background:
              "linear-gradient(93.5deg, #1a0f1f 4.67%, #1c032a00 96.2%)",
            color: "#ffffff",
            overflowX: "hidden",
            height: "100vh",
          },
        }}
        variant="permanent"
        open={toggle}
      >
        <CaretCircleLeft
          style={{
            transform: toggle ? "scaleX(1)" : "scaleX(-1)",
            position: "absolute",
            right: "0",
          }}
          onClick={handleToggle}
        />
        <DrawerHeader></DrawerHeader>
        <List>
          {roleManagement === 'admin' ? (
            listItemData.map((text, index) => (
              <ListItem
                key={text.label}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(`${text.link}`)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: toggle ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: toggle ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.label}
                    sx={{ opacity: toggle ? 1 : 0 }}
                  />
                </ListItemButton>
                <hr
                  style={{
                    backgroundImage:
                      "linear-gradient(90deg, #0073CD, transparent)",

                    border: "0",
                    height: "1px",
                  }}
                />
              </ListItem>
            ))
          ) : (
            listItemDataUser.map((text, index) => (
              <ListItem
                key={text.label}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(`${text.link}`)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: toggle ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: toggle ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.label}
                    sx={{ opacity: toggle ? 1 : 0 }}
                  />
                </ListItemButton>
                <hr
                  style={{
                    backgroundImage:
                      "linear-gradient(90deg, #0073CD, transparent)",

                    border: "0",
                    height: "1px",
                  }}
                />
              </ListItem>
            ))
          )}
        </List>
        <List>
          {["Enablic Journey"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate("/dashboard/enablicJourney")}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: toggle ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: toggle ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <EnablicIcon />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: toggle ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
