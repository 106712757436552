import React, { useState } from 'react'
import SideNavBar from '../SideNavBar/SideNavBar';
import MobileViewNavBar from '../MobileViewNavBar/MobileViewNavBar';
import ProfileContent from './ProfileContent';
import ProfileContentMobile from './ProfileContentMobile';

const UserProfile = () => {
    const [sidebarToggled, setSidebarToggled] = useState(false);
    return (
        <div>
            <div className="webView">
                <SideNavBar setSidebarToggled={setSidebarToggled} />
                <ProfileContent sidebarWidth={!sidebarToggled ? 65 : 240} />
            </div>
            <div className="mobileView">
                <MobileViewNavBar />
                <ProfileContentMobile />
            </div>
        </div>
    );
}

export default UserProfile