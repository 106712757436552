export const workoutItems = [
    {
      name: "Classic",
      description:
        "Get a set of games based on your training habits and preferences.",
      color: "#E6B026",
    },
    {
      name: "Math",
      description: "Challenge your estimation and calculation skills.",
      color: "#2C33E1",
    },
    {
      name: "Language",
      description: "Dive deep into your vocabulary and reading skills.",
      color: "#F56D70",
    },
    {
      name: "Favorites",
      description: "Treat your brain to the games you play the most.",
      color: "#6882FF",
    },
    {
      name: "Strengthen",
      description: "Play your weakest games and raise your low game scores.",
      color: "#48A39B",
    },
    {
      name: "Quick",
      description: "Race through short games in 8 minutes or less.",
      color: "#48A39B",
    },
  ];