import React from "react";
import Confetti from "react-confetti";

export const ConfettiEffect = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "-147px",
        left: "-150px",
        zIndex:"100"
      }}
    >
      <Confetti
        confettiSource={{
          x: 10,
          y: 10,
          w: 2000,
          h: 0,
        }}
      />
    </div>
  );
};
