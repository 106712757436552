import React from "react";
import "./singlecard.css";

export const SingleCard = ({ card, handleChoice, flipped, disabled }) => {
  
  const handleClick = () => {
    if (!disabled) {
      handleChoice(card);
    }
  };
  return (
    <div className="card">
      <div className={flipped ? "flipped" : ""}>
        <img
          className="front"
          src={card.src}
          alt="card front"
          height="150"
          weight="200"
        />
        <img
          className="back"
          src="/img/cover.jpg"
          onClick={handleClick}
          alt="cover"
          height="150"
          weight="200"
        />
      </div>
    </div>
  );
};
