import { API } from "../../backend.js";
import axios from "axios";

export const update = async (user) => {
  // console.log("user", user);
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  // console.log("token: ", localSigninData?.data?.token)
  try {
    const response = await axios.put(`${API}/users/updatebyid/${localSigninData?.data?.user?._id}`, user, {
      "Content-Type": "application/json",
      // header: "Access-Control-Allow-Origin",
      headers: {
        token: localSigninData?.data?.token,
      }
    });
    // console.log("await response.data", response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
  
};
