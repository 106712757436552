import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import VideoTutorial from "../../GameTutorial/VideoTutorial";
import Puzzle from "./Puzzle";
import MascotIndicate from "../../Mascot/MascotIndicate";

export const StartPuzzle = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"))?.data?.user
    ?.first_name;

  const [startSequenceGame, setStartSequenceGame] = useState();
  const [counter, setCounter] = useState(3);
  const [startGame, setStartGame] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (counter === 0) {
      setStartSequenceGame(true);
    }
    if (startGame) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, startGame]);
  const handleStartGame = () => {
    setStartGame(true);
  };
  return (
    <div>
      {startSequenceGame === true ? <Puzzle /> : ""}
      {startSequenceGame === undefined ? (
        <>
          {startGame ? (
            <Box
              mt={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1>GET Ready...</h1>
              <h1>{counter}</h1>
            </Box>
          ) : (
            <div
              className="puzzle-play-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1>Puzzle</h1>
              <button onClick={handleStartGame}>Start Game</button>
              <button onClick={handleOpen}>How to play?</button>
              <VideoTutorial
              videoLink={"https://d28u08h3rd64o.cloudfront.net/puzzle/puzzle.m3u8"}
                open={open}
                setOpen={setOpen}
                handleClose={() => handleClose()}
              />
            </div>
          )}
        </>
      ) : (
        ""
      )}
      {counter === 3 ? (
        <MascotIndicate
          textBubble={`Hey ${localSigninData}, Help me to solve the Puzzle. Click on the Start Button.`}
        />
      ) : (
        ""
      )}
    </div>
  );
};
