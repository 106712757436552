import React, { useState, useEffect } from "react";
import { useCountdownTimer } from "use-countdown-timer";
import { letters, countries, animals, names } from "./data";
import { Box, Modal } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { gameData } from "../GameData/gameData";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { SubmitScore } from "../SubmitScore/SubmitScore";
import { API } from "../../../../backend";
import { handleChangeStartGame } from "../../../../redux/actions";
import { styled } from "@mui/material/styles";

import "./container.css";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import WinnerMascot from "../../Mascot/WinnerMascot";
import GymMascot from "../../Mascot/GymMascot";

const options = {
  shouldForwardProp: (prop) => prop !== "borderColor",
};
const outlinedSelectors = [
  "& .MuiOutlinedInput-notchedOutline",
  "&:hover .MuiOutlinedInput-notchedOutline",
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline",
];
const TextField = styled(
  MuiTextField,
  options
)(({ borderColor }) => ({
  "& label.Mui-focused": {
    color: borderColor,
  },
  [outlinedSelectors.join(",")]: {
    borderWidth: 3,
    borderColor,
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Container = () => {
  let navigate = useNavigate();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const { countdown, reset, start } = useCountdownTimer({
    timer: 1000 * 50,
    autostart: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [msg, setMsg] = useState("");
  const [, setFinalScore] = useState(0);
  const [bingoLetter, setBingoLetter] = useState(
    letters[Math.floor(Math.random() * letters.length)].toUpperCase()
  );
  const [stages, setStages] = useState(1);
  const [originalScore, setOriginalScore] = useState(0);
  const [missedScore, setMissedScore] = useState(0);
  const [, setGameId] = useState("");
  const enablicJourneyScore = true;
  const dispatch = useDispatch();
  const { currentSchedule } = useSelector((state) => state);

  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [animal, setAnimal] = useState("");

  const countriesUpper = countries.map((element) => {
    return element.toUpperCase();
  });
  const namesUpper = names.map((element) => {
    return element.toUpperCase();
  });
  const animalsUpper = animals.map((element) => {
    return element.toUpperCase();
  });

  const clearFields = () => {
    setCountry("");
    setName("");
    setAnimal("");
  };

  const validateCountry = (country) => {
    if (country.substring(0, 1).toUpperCase() === bingoLetter) {
      if (countriesUpper.includes(country.toUpperCase())) {
        // setScore((score) => score + 10)
        return true;
      }
    }
    return false;
  };
  const validateName = (name) => {
    if (name.substring(0, 1).toUpperCase() === bingoLetter) {
      if (namesUpper.includes(name.toUpperCase())) {
        // setScore((score) => score + 10)
        return true;
      }
    }
    return false;
  };
  const validateAnimal = (animal) => {
    if (animal.substring(0, 1).toUpperCase() === bingoLetter) {
      if (animalsUpper.includes(animal.toUpperCase())) {
        // setScore((score) => score + 10)
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const isTimeUp = () => {
      if (countdown === 0) {
        return true;
      }
      return false;
    };
    if (isTimeUp()) {
      setGameOver(true);
      setFinalScore(score);
      setOriginalScore(score);
      setMissedScore(30 * stages - score);
      // setScore(0);
    }
    if (gameOver) {
      axios
        .get(`${API}/games/getByName/${gameData["Bingo"].name}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          // console.log("val", val.data.data._id);
          setGameId(val.data.data._id);
          if (window.location.pathname === "/gameplay") {
            SubmitScore(originalScore, missedScore, val.data.data._id, false);
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              originalScore,
              missedScore,
              val.data.data._id,
              enablicJourneyScore,
              currentSchedule
            );
            setTimeout(() => {
              dispatch(handleChangeStartGame(false));
            }, 1000);
          }
        })
        .catch((err) => {
          setOpenSnackbar(true);
          setMessage(err.message);
          setSeverity("error");
        });
      // handleOpen();
      // setMsg(`Your Final score is ${score}.`);
    } else {
      setMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, gameOver, score]);

  const generateLetter = () => {
    setStages((prev) => prev + 1);
    setBingoLetter(
      letters[Math.floor(Math.random() * letters.length)].toUpperCase()
    );
  };

  const startGame = () => {
    setGameOver(false);
    handleClose();
    generateLetter();
    setScore(0);
    reset();
    start();
  };
  const handleChange = (e) => {
    e.preventDefault();
  };
  const bingo = () => {
    if (country === "" || name === "" || animal === "") {
      setMsg("Please fill all the fields.");
      handleOpen();
      return;
    } else {
      reset();
      if (
        validateCountry(country) &&
        validateName(name) &&
        validateAnimal(animal)
      ) {
        setScore((score) => score + 30);

        clearFields();
        generateLetter();
        start();
      } else if (
        !validateCountry(country) &&
        validateName(name) &&
        validateAnimal(animal)
      ) {
        setScore((score) => score + 20);

        clearFields();
        generateLetter();
        start();
      } else if (
        validateCountry(country) &&
        !validateName(name) &&
        validateAnimal(animal)
      ) {
        setScore((score) => score + 20);

        clearFields();
        generateLetter();
        start();
      } else if (
        validateCountry(country) &&
        validateName(name) &&
        !validateAnimal(animal)
      ) {
        setScore((score) => score + 20);

        clearFields();
        generateLetter();
        start();
      } else if (
        !validateCountry(country) &&
        !validateName(name) &&
        validateAnimal(animal)
      ) {
        setScore((score) => score + 10);

        clearFields();
        generateLetter();
        start();
      } else if (
        !validateCountry(country) &&
        validateName(name) &&
        !validateAnimal(animal)
      ) {
        setScore((score) => score + 10);

        clearFields();
        generateLetter();
        start();
      } else if (
        validateCountry(country) &&
        !validateName(name) &&
        !validateAnimal(animal)
      ) {
        setScore((score) => score + 10);

        clearFields();
        generateLetter();
        start();
      } else {
        setGameOver(true);
        clearFields();
        setFinalScore(score);
        setOriginalScore(score);
        setMissedScore(30 * stages - score);
      }
    }
  };
  const handleBack = () => {
    navigate("/games");
  };
  return (
    // console.log("stages", stages),
    (
      <>
        {gameOver && originalScore > 40 ? <ConfettiEffect /> : ""}
        {gameOver ? (
          originalScore > 40 ? (
            <>
              <ConfettiEffect />
              <WinnerMascot
                textBubble={`“Wahoo! That was fantastic champ!”`}
              />
            </>
          ) : (
            <>
              <GymMascot
                textBubble={`Don’t worry, you got this champ! Come on let’s play another game for a bit.`}
              />
            </>
          )
        ) : (
          <></>
        )}
        <div className="totalWidth">
          <Box
            style={{
              width: "100%",
            }}
          >
            <Card
              style={{
                padding: 1,
                marginLeft: "5%",
                width: "55vw",
                height: "fit-content",
                // maxHeight: "calc(85vh - 68px)",
                justifyContent: "center",
                borderRadius: "20px",
                background: "rgb(138 100 169 / 40%)",
                backdropFilter: "saturate(180%) blur(10px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  height: "50px",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.19)",
                }}
              >
                <div className="questionText">
                  Timer:{" "}
                  <span
                    className={`${countdown <= 10000 ? "text-red-500 animate-pulse" : ""
                      }`}
                  >
                    {countdown / 1000} sec
                  </span>
                </div>
                <div></div>
                <div className="scoreText">
                  Score: <span> {score}</span>
                </div>
              </div>
              {gameOver ? (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "400px",
                  }}
                >
                  <h1
                    style={{
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "#ffffff",
                    }}
                  >
                    Game Over
                  </h1>
                  <div className="dashboard-play-btn">
                    <button onClick={handleBack}>Back to Game Section</button>
                  </div>
                </div>
              ) : (
                <CardContent>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p className="questionText">Bingo says letter</p>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h1 className="alphabet">{bingoLetter}</h1>
                  </div>
                  <div className="textarea">
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": { color: "#ffffff" }, //styles the label
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "#ffffff" },
                        },
                        input: { color: "#ffffff" },
                        marginButtom: "5px",
                      }}
                      onCut={handleChange}
                      onCopy={handleChange}
                      onPaste={handleChange}
                      borderColor="#ffffff"
                      id="filled-required"
                      label="Country"
                      variant="filled"
                      required
                      name="Country"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        start();
                      }}
                    />
                    <TextField
                      id="filled-required"
                      sx={{
                        "& .MuiInputLabel-root": { color: "#ffffff" }, //styles the label
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "#ffffff" },
                        },
                        input: { color: "#ffffff" },
                        marginButtom: "5px",
                      }}
                      onCut={handleChange}
                      onCopy={handleChange}
                      onPaste={handleChange}
                      borderColor="#ffffff"
                      label="Fruit"
                      variant="filled"
                      required
                      name="Fruit"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        start();
                      }}
                    />
                    <TextField
                      id="filled-required"
                      sx={{
                        "& .MuiInputLabel-root": { color: "#ffffff" }, //styles the label
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": { borderColor: "#ffffff" },
                        },
                        input: { color: "#ffffff" },
                        marginButtom: "5px",
                      }}
                      onCut={handleChange}
                      onCopy={handleChange}
                      onPaste={handleChange}
                      borderColor="#ffffff"
                      label="Animal"
                      variant="filled"
                      required
                      name="Animal"
                      value={animal}
                      onChange={(e) => {
                        setAnimal(e.target.value);
                        start();
                      }}
                    />
                  </div>
                  <div className="bingo-play-btn">
                    {!gameOver ? (
                      <button onClick={bingo} disabled={gameOver}>
                        Bingo!
                      </button>
                    ) : (
                      <button onClick={startGame}>Start</button>
                    )}
                  </div>
                </CardContent>
              )}
            </Card>
          </Box>
          <Modal
            open={open && msg !== ""}
            onClose={handleClose}
            disableRestoreFocus={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backdropFilter: "saturate(180%) blur(6px)" }}
          >
            <Box sx={style}>
              <h1>{msg}</h1>

              <div className="bingo-play-btn">
                {!gameOver ? "" : <button onClick={startGame}>Start</button>}
              </div>
            </Box>
          </Modal>
          <CustomizedSnackbars
            open={openSnackbar}
            message={message}
            severity={severity}
          />
        </div>
      </>
    )
  );
};

export default Container;
