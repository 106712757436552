/* var num = Math.floor(Math.random() * (Math.pow(10,3)+ 1))+1
console.log("num=", num)
var result=num.toString(2).substr(-6);
console.log("result",result);
console.log(result.length); */

import React, { useEffect, useState } from "react";
import "./TileMaster.css";
import { motion } from "framer-motion";
import Trophy from "./assets/Trophy";
import Heart from "./assets/Heart";
import axios from "axios";
import CustomizedSnackbars from "../../Snackbar/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeStartGame } from "../../../../redux/actions";
import { gameData } from "../GameData/gameData";
import { API } from "../../../../backend";
import { NavLink } from "react-router-dom";

import CardComponent from "./Components/CardComponent";
import { SubmitScore } from "../SubmitScore/SubmitScore";
// import Confetti from "react-confetti";
import WinnerMascot from "../../Mascot/WinnerMascot";
import { ConfettiEffect } from "../../ConfettiEffect/ConfettiEffect";
import GymMascot from "../../Mascot/GymMascot";

function TileMaster() {
  const dispatch = useDispatch();
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  const [dynamicBox, setDynamicBox] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [wrongCounter, setWrongCounter] = useState(0);
  const [correctCounter, setCorrectCounter] = useState(0);
  const [startGame, setStartGame] = useState(false);
  const [calcOne, setCalcOne] = useState(0);
  const [levelCount, setLevelCount] = useState(1);
  const [levelWrongCount, setLevelWrongCount] = useState(0);
  const [life, setLife] = useState(3);
  const [previewTimer, setPreviewTimer] = useState(3);
  const [counter, setCounter] = useState(previewTimer);
  const [gameTimer, setGameTimer] = useState(5);
  const [finalScore, setFinalScore] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [, setGameId] = useState("");
  const enablicJourneyScore = true;
  const { currentSchedule } = useSelector((state) => state);

  const generateRandomNum = () => {
    let result;
    if (levelCount === 1) {
      const num = Math.floor(Math.random() * (Math.pow(10, 4) - 10)) + 1; //generate a random number
      result = num.toString(2).substr(-6); //convert number to string and extract the string
      // result = "111111";
    } else if (levelCount >= 12) {
      const num = Math.floor(Math.random() * (Math.pow(10, 15) - 10)) + 1; //generate a random number
      result = num.toString(2).substr(-30); //convert number to string and extract the string
      setPreviewTimer(9);
      setGameTimer((prev) => prev + 30);
    } else {
      const num =
        Math.floor(Math.random() * (Math.pow(10, levelCount + 3) - 10)) + 1; //generate a random number
      result = num.toString(2).substr(-(levelCount * 3));
      setPreviewTimer(levelCount + 2);
    }
    setCounter(previewTimer);
    setGameTimer((prev) => {
      if (startGame) {
        if (levelCount > 5 && levelCount <= 12) {
          return prev + 30;
        } else return prev + 5;
      } else return prev;
    });
    return result;
  };
  const gameLogic = () => {
    let result = generateRandomNum();
    const removedDup = allCharactersSame(result); //check if all the characters of string are same
    setDynamicBox(removedDup);

    return removedDup;
  };
  function allCharactersSame(s) {
    let s1 = new Set();

    s.split("").forEach((data) => {
      s1.add(data);
    });

    // If all characters are same
    // Size of set will always be 1
    if (s1.size === 1) {
      let result = generateRandomNum();
      setDynamicBox(result);

      return allCharactersSame(result);
    } else {
      return s;
    }
  }

  // useEffect to Generate rows at the initial stage
  useEffect(() => {
    const result = gameLogic();
    if (startGame) {
      result.split("").forEach((data) => {
        if (data === "1") {
          setCalcOne((prev) => prev + 1);
        }
        return calcOne;
      });
      setShowCard(true);
      if (levelCount > 3) {
        setTimeout(() => {
          setShowCard(false);
        }, 1000 * previewTimer);
      } else {
        setTimeout(() => {
          setShowCard(false);
        }, 1000 * previewTimer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startGame]);

  // Level Up Logic
  useEffect(() => {
    if (calcOne === correctCounter && calcOne > 0) {
      setLevelCount((prev) => prev + 1);
      const result = gameLogic();

      if (startGame) {
        result.split("").forEach((data) => {
          if (data === "1") {
            setCalcOne((prev) => prev + 1);
          }
          return calcOne;
        });
        setShowCard(true);
        setTimeout(() => {
          setShowCard(false);
        }, 1000 * previewTimer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcOne, correctCounter]);

  // life decrement
  useEffect(() => {
    if (startGame) {
      if (levelWrongCount !== wrongCounter) {
        setLife((prev) => prev - 1); // decrease life during wrong count
        setLevelWrongCount(wrongCounter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelCount, startGame]);

  //Preview countdown Timer
  useEffect(() => {
    if (startGame) {
      counter > 0 && setTimeout(() => setCounter((prev) => prev - 1), 1000);
    }
  }, [counter, startGame]);

  //Game (level) countdown Timer
  useEffect(() => {
    if (startGame) {
      counter === 0 &&
        gameTimer > 0 &&
        setTimeout(() => setGameTimer((prev) => prev - 1), 1000);
    }
  }, [counter, gameTimer, startGame]);

  //Reset Timer on Game completion
  useEffect(() => {
    if (!(life > 0 && gameTimer !== 0)) {
      setCounter(0);
      setPreviewTimer(0);
      setGameTimer(0);
    }
    if (life === 0) {
      setStartGame(false);
    }
  }, [gameTimer, life]);

  //submit score after game completion
  useEffect(() => {
    let groupedScore;
    if (!startGame && life === 0) {
      axios
        .get(`${API}/games/getByName/${gameData["TileMaster"].name}`, {
          headers: {
            token: `${localSigninData && localSigninData.data.token}`,
          },
        })
        .then((val) => {
          setGameId(val.data.data._id);
          groupedScore = Math.round(
            (correctCounter / (wrongCounter + correctCounter)) * 10000
          );
          setFinalScore(groupedScore);
          if (window.location.pathname === "/gameplay") {
            SubmitScore(
              correctCounter,
              wrongCounter,
              val.data.data._id,
              false,
              null,
              groupedScore
            );
          } else if (window.location.pathname !== "/gameplay") {
            SubmitScore(
              correctCounter,
              wrongCounter,
              val.data.data._id,
              enablicJourneyScore,
              currentSchedule,
              groupedScore
            );
            setTimeout(() => {
              dispatch(handleChangeStartGame(false));
            }, 3000);
          }
        })
        .catch((err) => {
          setOpenSnackbar(true);
          setMessage(err.message);
          setSeverity("error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startGame]);

  const handleWrongCount = () => {
    setWrongCounter(
      (prev) => prev + Math.max(1, 2.5 / Math.log10(1 + levelCount))
    );
  };
  const handleCorrectCount = () => {
    setCorrectCounter((prev) => prev + 1);
  };
  const handleRestartGame = () => {
    setDynamicBox("");
    setShowCard(false);
    setWrongCounter(0);
    setCorrectCounter(0);
    setStartGame(0);
    setCalcOne(0);
    setLevelCount(1);
    setLevelWrongCount(0);
    setLife(3);
    setPreviewTimer(3);
    setCounter(previewTimer);
    setGameTimer(5);
    setFinalScore(0);
  };
  return (
    <>
      {life === 0 || gameTimer === 0 ? (
        finalScore > 5000 ? (
          <>
            <ConfettiEffect />
            <WinnerMascot
              textBubble={`Fantastic, ${localSigninData?.data?.user?.first_name}! You are on a roll`}
            />
          </>
        ) : (
          <>
            <GymMascot
              textBubble={`Chin up! Let's have a try again!`}
            />
          </>
        )
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "12px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backdropFilter: "blur(3px)",
            backgroundColor: "rgb(108 108 108 / 40%)",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Trophy width={60} level={levelCount} />
            </div>

            <div className="timer">
              {life > 0
                ? counter === 0
                  ? `Level Timer: ${gameTimer}`
                  : `Preview Timer: ${counter}`
                : "--"}
            </div>
            <div
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Heart height={47} wdith={30} life={life} />
            </div>
          </div>
          <div
            style={{
              height: "400px",
              width: "650px",
              display: "flex",
              flexWrap: "wrap",
              gap: "14px",
            }}
          >
            {life > 0 && gameTimer !== 0 ? (
              dynamicBox.split("").map((data, i) => (
                <motion.div
                  initial={{
                    scale: 0,
                  }}
                  animate={{
                    scale: 1,
                    transition: {
                      delay: 0.09 * i,
                      duration: 0.4,
                    },
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  key={`${levelCount}_${i}`}
                  style={{ display: "flex" }}
                >
                  <CardComponent
                    key={i}
                    data={data}
                    index={i}
                    showCard={showCard}
                    levelCount={levelCount}
                    handleWrongCount={() => handleWrongCount()}
                    handleCorrectCount={() => handleCorrectCount()}
                    startGame={startGame}
                  />
                </motion.div>
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h1
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    GAME OVER
                  </h1>
                  <hr
                    style={{
                      height: "1",
                      width: "400px",
                      border: "0.5px solid #61dafb",
                    }}
                  />
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                    }}
                  >
                    FINAL SCORE: {finalScore}
                  </h3>
                  <br />
                  {window.location.pathname === "/gameplay" ? (
                    <div>
                      <div className="home-play-btn" >
                        <button className="game-section-return-btn" onClick={handleRestartGame}>
                          <span>Play Again</span>
                        </button>
                      </div>
                      <NavLink to="/games">
                        <div className="home-play-btn">
                          <button>
                            <span>Game Section</span>
                          </button>
                        </div>
                      </NavLink>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          {!startGame && life !== 0 ? (
            <div className="start-play-btn">
              <button
                onClick={() => {
                  setStartGame(true);
                }}
              >
                Start
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <CustomizedSnackbars
          open={openSnackbar}
          message={message}
          severity={severity}
        />
      </div>
    </>
  );
}

export default TileMaster;
