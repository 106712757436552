export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const CHANGE_DIFFICULTY = "CHANGE_DIFFICULTY";
export const CHANGE_TYPE = "CHANGE_TYPE";
export const CHANGE_AMOUNT = "CHANGE_AMOUNT";
export const CHANGE_SCORE = "CHANGE_SCORE";
export const CHANGE_SIGNIN = "CHANGE_SIGNIN";
export const CHANGE_TESTOVER = "CHANGE_TESTOVER";
export const CHANGE_TOTALQUESTION = "CHANGE_TOTALQUESTION";
export const CHANGE_WORDLEGAMEOVER = "CHANGE_WORDLEGAMEOVER";
export const CHANGE_STARTGAME = "CHANGE_STARTGAME";
export const CHANGE_SCHEDULE = "CHANGE_SCHEDULE";

export const EXPLORE_THE_PLATFORM="EXPLORE_THE_PLATFORM";
export const PROGRESS_ITEM_TUTORIAL = "PROGRESS_ITEM_TUTORIAL";
export const ENABLIC_JOURNEY_TUTORIAL = "ENABLIC_JOURNEY_TUTORIAL";

export const CORRECT_ANSWER = "CORRECT_ANSWER";
export const WRONG_ANSWER = "WRONG_ANSWER";
export const SET_TIME = "SET_TIME";
export const GAIN_POINTS = "GAIN_POINTS";
export const REMOVE_LIVE = "REMOVE_LIVE";
export const FINISH_GAME = "FINISH_GAME";
export const RESTART_GAME = "RESTART_GAME";
export const REBOOT_GAME = "REBOOT_GAME";
export const START_GAME = "START_GAME";
export const EARN_LIFE = "EARN_LIFE";
