import { API } from "../../backend.js";
import axios from "axios";

export const resetPassword = async (data) => {
  console.log("password ", data);
  try {
    const response = await axios.post(`${API}/users/verifyresetpassword`, data, {
      "Content-Type": "application/json",
      header: "Access-Control-Allow-Origin",
    });
    return response.data;
  } catch (err) {
    return err.response;
  }
};
