import { ReactComponent as CurvedLine } from "./assets/curved-line.svg";
import { ReactComponent as LeftBrain } from "./assets/left-brain.svg";
import { ReactComponent as RightBrain } from "./assets/right-brain.svg";
import { ReactComponent as Football } from "./assets/football.svg";
import { ReactComponent as PlayBtn } from "./assets/play.svg";
import { ReactComponent as RightLine } from "./assets/right-line.svg";
import { ReactComponent as Ecllipse1 } from "../../assets/icons/ellipse1.svg";
import { ReactComponent as Ecllipse2 } from "../../assets/icons/ellipse2.svg";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import MobileViewTopNavBar from "../MobileViewTopNavBar/MobileViewTopNavBar";
import { motion } from "framer-motion";

export default function Home() {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/games");
  };
  return (
    <motion.div
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
        transition: {
          delay: 0,
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
      }}
      style={{
        position: "relative",
        width: "100vw",
        overflow: "hidden",
        height: "fit-content",
      }}
    >
      <div className="ellipse1">
        <Ecllipse1 />
      </div>
      <div className="ellipse2">
        <Ecllipse2 />
      </div>
      <div className="toggleTopNavBar">
        <TopNavBar />
      </div>
      <div className="toggleMobileViewTopNavBar">
        <MobileViewTopNavBar />
      </div>
      <h1 className="home-header">
        Stay <span>Sharp,</span> Build <span>Confidence,</span> and Boost{" "}
        <span>Productivity.</span>
      </h1>
      <div className="home-curved-line">
        <CurvedLine />
      </div>

      <div className="home-sub-header">
        Push your cognitive skills, train harder and use your time better with
        fun, stimulating games and workouts.
      </div>

      <div className="home-play-btn">
        <button onClick={handleClick}>
          <PlayBtn /> <span>PLAY NOW</span>
        </button>
      </div>

      <div className="home-mid-section">
        <div className="home-left-brain">
          <motion.div
            initial={{
              x: "60px",
            }}
            animate={{
              x: "0px",
              transition: {
                delay: 1,
                duration: 0.7,
              },
            }}
          >
            <LeftBrain />
          </motion.div>
          <motion.div
            initial={{
              x: "-60px",
            }}
            animate={{
              x: "0px",
              opacity: "1",
              transition: {
                delay: 1,
                duration: 0.5,
              },
            }}
          >
            <Football
              style={{ position: "absolute", bottom: 0, left: "50%" }}
            />
          </motion.div>
        </div>
        <div className="home-right-brain">
          <RightLine
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translateY(-100%) translateX(35%)",
            }}
          />
          <motion.div
            initial={{
              scale: 0.8,
            }}
            animate={{
              scale: 1,
              transition: {
                delay: 1,
                duration: 0.7,
              },
            }}
          >
            <RightBrain />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
