import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
export default function CardComponent(props) {
  const [show, setShow] = useState();
  const [wrong, setWrong] = useState(false);

  //toggling the card color at initial
  useEffect(() => {
    setShow(props.showCard);
  }, [props.showCard]);

  return (
    <motion.div
      initial={{
        scale: 1,
      }}
      animate={
        wrong
          ? {
              x: [0, 10, -10, 10, -10, 0],
              border: "2.2px solid #f86565",
              transition: {
                duration: 0.5,
              },
            }
          : { x: 0 }
      }
      key={props.index}
      style={{
        width: "200px",
        // background: `${data === "1" ? "blue" : "pink"}`,
        background: `${
          show
            ? props.data === "1"
              ? "linear-gradient(to right, rgb(125 0 188), rgb(250 0 255))"
              : "pink"
            : "linear-gradient(to right, rgb(157 226 0), rgb(0 246 255))"
        }`,
        borderRadius: "5px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      }}
      whileTap={
        props.startGame && !props.showCard && !show ? { scale: 0.95 } : ""
      }
      whileHover={
        props.startGame && !props.showCard && !show
          ? { scale: 1.05,transition:{duration:0.3} }
          : { scale: 1,transition:{duration:0.3} }
      }
      onClick={() => {
        if (props.startGame && !props.showCard && !show) {
          if (props.data === "0") {
            setWrong(true);
            props.handleWrongCount();
          } else {
            setWrong(false);
            props.handleCorrectCount();
          }
          setShow(true);
        }
      }}
    ></motion.div>
  );
}
