import React from "react";
import DeviceTablet from "../../../assets/icons/DeviceTablet";
import { motion } from "framer-motion";

export const Rotate = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "80px 0 80px 0",
        }}
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={{
            x: 0,
            y: 0,
            scale: 1,
            rotate: 90,
          }}
          transition={{
            delay: 1,
            duration: 2,
          }}
        >
          <DeviceTablet width={200} height={200} fill={"#ffffff"} />
        </motion.div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "rgb(255, 255, 255)",
          fontSize: "22px",
          fontWeight: "700",
        }}
      >
        To play the games rotate your device
      </div>
    </div>
  );
};
