import axios from "axios";
import { API } from "../../../backend";

export const journeyProgress = async () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  try {
    const val = await axios.get(
      `${API}/users/getCurrentJourney/${localSigninData && localSigninData.data.user._id}`,
      {
        headers: {
          token: `${localSigninData && localSigninData.data.token}`,
        },
      }
    );
    // console.log("journey progress ", val.data.data.current_journey_progress);
    return val.data.data.current_journey_progress;
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentJourney = async () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));

  try {
    const val = await axios.get(
      `${API}/users/getCurrentJourney/${localSigninData && localSigninData.data.user._id}`,
      {
        headers: {
          token: `${localSigninData && localSigninData.data.token}`,
        },
      }
    );
    return val;
  } catch (err) {
    console.log(err);
  }
};
