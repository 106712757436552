import React from "react";

function HeartIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="none"
      viewBox="0 0 78 69"
    >
      <path
        fill="#FC0000"
        stroke="#FB0303"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.75"
        d="M69.108 9.44a18.562 18.562 0 00-26.257 0l-3.578 3.577-3.577-3.577A18.567 18.567 0 009.438 35.697l3.578 3.578 26.257 26.257 26.258-26.257 3.577-3.578a18.563 18.563 0 000-26.257v0z"
      ></path>
    </svg>
  );
}

export default HeartIcon;
