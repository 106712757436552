export const solutions = [
  { id: 1, word: "about", meaning: "Moving around; astir." },
  { id: 2, word: "above", meaning: "Heaven." },
  {
    id: 3,
    word: "after",
    meaning: "Later; second (of two); next, following, subsequent",
  },
  { id: 4, word: "again", meaning: "Another time; once more." },
  { id: 5, word: "along", meaning: "In company; together." },
  {
    id: 6,
    word: "among",
    meaning:
      "Denotes a mingling or intermixing with distinct or separable objects. (See Usage Note at amidst.)",
  },
  {
    id: 7,
    word: "asked",
    meaning: "To request (information, or an answer to a question).",
  },
  {
    id: 8,
    word: "began",
    meaning: "To start, to initiate or take the first step into something.",
  },
  { id: 9, word: "being", meaning: "A living creature." },
  { id: 10, word: "below", meaning: "In a lower place." },
  {
    id: 11,
    word: "birds",
    meaning:
      "A member of the class of animals Aves in the phylum Chordata, characterized by being warm-blooded, having feathers and wings usually capable of flight, and laying eggs.",
  },
  {
    id: 12,
    word: "black",
    meaning:
      "The colour/color perceived in the absence of light, but also when no light is reflected, but rather absorbed.",
  },
  {
    id: 13,
    word: "bring",
    meaning: "(ditransitive) To transport toward somebody/somewhere.",
  },
  {
    id: 14,
    word: "built",
    meaning: "To form (something) by combining materials or parts.",
  },
  {
    id: 15,
    word: "class",
    meaning:
      "A group, collection, category or set sharing characteristics or attributes.",
  },
  { id: 16, word: "close", meaning: "An end or conclusion." },
  {
    id: 17,
    word: "comes",
    meaning: "To move from further away to nearer to.",
  },
  {
    id: 18,
    word: "could",
    meaning: "(auxiliary verb, defective) To know how to; to be able to.",
  },
  {
    id: 20,
    word: "early",
    meaning:
      "A shift (scheduled work period) that takes place early in the day.",
  },
  {
    id: 21,
    word: "earth",
    meaning:
      "The third planet of the Solar System; the world upon which humans live.",
  },
  {
    id: 22,
    word: "field",
    meaning: "A land area free of woodland, cities, and towns; open country.",
  },
  {
    id: 23,
    word: "first",
    meaning: "The person or thing in the first position.",
  },
  {
    id: 24,
    word: "found",
    meaning: "To encounter or discover by accident; to happen upon.",
  },
  {
    id: 25,
    word: "front",
    meaning:
      "The foremost side of something or the end that faces the direction it normally moves.",
  },
  {
    id: 26,
    word: "girls",
    meaning: "A female child, adolescent, or a young woman.",
  },
  {
    id: 27,
    word: "given",
    meaning:
      "(ditransitive) To move, shift, provide something abstract or concrete to someone or something or somewhere.",
  },
  { id: 28, word: "going", meaning: "To move:" },
  {
    id: 29,
    word: "great",
    meaning: "A person of major significance, accomplishment or acclaim.",
  },
  { id: 30, word: "green", meaning: "Having green as its color." },
  {
    id: 31,
    word: "group",
    meaning:
      "A number of things or persons being in some relation to one another.",
  },
  {
    id: 32,
    word: "hands",
    meaning:
      "The part of the forelimb below the forearm or wrist in a human, and the corresponding part in many other animals.",
  },
  {
    id: 33,
    word: "heard",
    meaning: "(stative) To perceive sounds through the ear.",
  },
  {
    id: 34,
    word: "heart",
    meaning:
      "A muscular organ that pumps blood through the body, traditionally thought to be the seat of emotion.",
  },
  {
    id: 35,
    word: "horse",
    meaning: "Any of several animals related to Equus ferus caballus.",
  },
  {
    id: 36,
    word: "house",
    meaning: "A structure built or serving as an abode of human beings.",
  },
  {
    id: 37,
    word: "kinds",
    meaning:
      "A type, race or category; a group of entities that have common characteristics such that they may be grouped together.",
  },
  {
    id: 38,
    word: "known",
    meaning:
      "To perceive the truth or factuality of; to be certain of or that.",
  },
  {
    id: 39,
    word: "large",
    meaning:
      "An old musical note, equal to two longas, four breves, or eight semibreves.",
  },
  { id: 40, word: "later", meaning: "Near the end of a period of time." },
  { id: 41, word: "learn", meaning: "The act of learning something" },
  {
    id: 42,
    word: "least",
    meaning: "Something of the smallest possible extent; an indivisible unit.",
  },
  { id: 43, word: "leave", meaning: "To have a consequence or remnant." },
  {
    id: 44,
    word: "light",
    meaning:
      "Visible electromagnetic radiation. The human eye can typically detect radiation (light) in the wavelength range of about 400 to 750 nanometers. Nearby shorter and longer wavelength ranges, although not visible, are commonly called ultraviolet and infrared light.",
  },
  {
    id: 45,
    word: "lines",
    meaning:
      "A path through two or more points (compare \u2018segment\u2019); a continuous mark, including as made by a pen; any path, curved or straight.",
  },
  { id: 46, word: "lived", meaning: "To be alive; to have life." },
  { id: 47, word: "makes", meaning: "Brand or kind; model." },
  { id: 48, word: "means", meaning: "To lament." },
  {
    id: 49,
    word: "might",
    meaning: "Power, strength, force or influence held by a person or group.",
  },
  {
    id: 50,
    word: "miles",
    meaning:
      "The international mile: a unit of length precisely equal to 1.609344 kilometers established by treaty among Anglophone nations in 1959, divided into 5,280 feet or 1,760 yards.",
  },
  {
    id: 51,
    word: "money",
    meaning:
      "A legally or socially binding conceptual contract of entitlement to wealth, void of intrinsic value, payable for all debts and taxes, and regulated in supply.",
  },
  {
    id: 52,
    word: "music",
    meaning:
      "A series of sounds organized in time, employing melody, harmony, tempo etc. usually to convey a mood.",
  },
  {
    id: 53,
    word: "never",
    meaning: "At no time; on no occasion; in no circumstance.",
  },
  {
    id: 54,
    word: "night",
    meaning:
      "The period between sunset and sunrise, when a location faces far away from the sun, thus when the sky is dark.",
  },
  { id: 55, word: "often", meaning: "Frequent." },
  {
    id: 56,
    word: "order",
    meaning: "Arrangement, disposition, or sequence.",
  },
  {
    id: 57,
    word: "other",
    meaning: "An other, another (person, etc), more often rendered as another.",
  },
  {
    id: 58,
    word: "paper",
    meaning:
      "A sheet material used for writing on or printing on (or as a non-waterproof container), usually made by draining cellulose fibres from a suspension in water.",
  },
  { id: 59, word: "parts", meaning: "A portion; a component." },
  {
    id: 60,
    word: "piece",
    meaning:
      "A part of a larger whole, usually in such a form that it is able to be separated from other parts.",
  },
  {
    id: 61,
    word: "place",
    meaning: "(physical) An area; somewhere within an area.",
  },
  { id: 62, word: "point", meaning: "A discrete division of something." },
  {
    id: 63,
    word: "power",
    meaning: "Ability to do or undergo something.",
  },
  {
    id: 64,
    word: "quite",
    meaning:
      "(heading) To the greatest extent or degree; completely, entirely.",
  },
  { id: 65, word: "ready", meaning: "Ready money; cash" },
  { id: 66, word: "right", meaning: "Straight, not bent." },
  {
    id: 67,
    word: "river",
    meaning:
      "A large and often winding stream which drains a land mass, carrying water down from higher areas to a lower point, oftentimes ending in another body of water, such as an ocean or in an inland sea.",
  },
  {
    id: 68,
    word: "round",
    meaning:
      "So as to form a circle or trace a circular path, or approximation thereof.",
  },
  {
    id: 69,
    word: "shall",
    meaning:
      "(modal, auxiliary verb, defective) Used before a verb to indicate the simple future tense in the first person singular or plural.",
  },
  { id: 70, word: "short", meaning: "A short circuit." },
  {
    id: 71,
    word: "shown",
    meaning: "To display, to have somebody see (something).",
  },
  {
    id: 72,
    word: "shows",
    meaning: "A play, dance, or other entertainment.",
  },
  {
    id: 73,
    word: "since",
    meaning: "From a specified time in the past.",
  },
  {
    id: 74,
    word: "small",
    meaning:
      "Any part of something that is smaller or slimmer than the rest, now usually with anatomical reference to the back.",
  },
  { id: 75, word: "sound", meaning: "Healthy." },
  { id: 76, word: "space", meaning: "(heading) Of time." },
  { id: 77, word: "stand", meaning: "The act of standing." },
  { id: 78, word: "start", meaning: "The beginning of an activity." },
  {
    id: 79,
    word: "state",
    meaning: "A condition; a set of circumstances applying at any given time.",
  },
  { id: 80, word: "still", meaning: "A period of calm or silence." },
  {
    id: 81,
    word: "stood",
    meaning: "(heading) To position or be positioned physically.",
  },
  {
    id: 82,
    word: "story",
    meaning:
      "A sequence of real or fictional events; or, an account of such a sequence.",
  },
  {
    id: 83,
    word: "study",
    meaning:
      "(usually academic) To review materials already learned in order to make sure one does not forget them, usually in preparation for an examination.",
  },
  {
    id: 84,
    word: "table",
    meaning: "Furniture with a top surface to accommodate a variety of uses.",
  },
  {
    id: 85,
    word: "taken",
    meaning:
      "To get into one's hands, possession or control, with or without force.",
  },
  { id: 86, word: "there", meaning: "That place." },
  {
    id: 87,
    word: "these",
    meaning: "The thing, item, etc. being indicated.",
  },
  {
    id: 88,
    word: "thing",
    meaning:
      "That which is considered to exist as a separate entity, object, quality or concept.",
  },
  {
    id: 89,
    word: "think",
    meaning: "An act of thinking; consideration (of something).",
  },
  {
    id: 90,
    word: "those",
    meaning:
      '(demonstrative) The thing, person, idea, quality, event, action or time indicated or understood from context, especially if more remote geographically, temporally or mentally than one designated as "this", or if expressing distinction.',
  },
  {
    id: 91,
    word: "times",
    meaning:
      "The inevitable progression into the future with the passing of present and past events.",
  },
  { id: 92, word: "today", meaning: "A current day or date." },
  {
    id: 93,
    word: "trees",
    meaning: "Fast growing function based on Kruskal's tree theorem.",
  },
  {
    id: 94,
    word: "tried",
    meaning: "Tested, hence, proven to be firm or reliable.",
  },
  {
    id: 95,
    word: "under",
    meaning: "Being lower; being beneath something.",
  },
  {
    id: 96,
    word: "until",
    meaning: "Up to the time of (something happening).",
  },
  { id: 97, word: "using", meaning: "To utilize or employ." },
  {
    id: 98,
    word: "voice",
    meaning:
      "Sound uttered by the mouth, especially by human beings in speech or song; sound thus uttered considered as possessing some special quality or character",
  },
  {
    id: 99,
    word: "vowel",
    meaning:
      "A sound produced by the vocal cords with relatively little restriction of the oral cavity, forming the prominent sound of a syllable.",
  },
  {
    id: 100,
    word: "water",
    meaning:
      "A substance (of molecular formula H\u2082O) found at room temperature and pressure as a clear liquid; it is present naturally as rain, and found in rivers, lakes and seas; its solid form is ice and its gaseous form is steam.",
  },
  {
    id: 101,
    word: "where",
    meaning: "The place in which something happens.",
  },
  {
    id: 102,
    word: "which",
    meaning:
      "(interrogative) What one or ones (of those mentioned or implied).",
  },
  {
    id: 103,
    word: "while",
    meaning: "An uncertain duration of time, a period of time.",
  },
  {
    id: 104,
    word: "white",
    meaning:
      "The color/colour of snow or milk; the colour of light containing equal amounts of all visible wavelengths.",
  },
  {
    id: 105,
    word: "whole",
    meaning: "Something complete, without any parts missing.",
  },
  {
    id: 106,
    word: "whose",
    meaning: "(interrogative) That or those of whom or belonging to whom.",
  },
  {
    id: 107,
    word: "words",
    meaning:
      "The smallest unit of language that has a particular meaning and can be expressed by itself; the smallest discrete, meaningful unit of language. (contrast morpheme.)",
  },
  {
    id: 108,
    word: "world",
    meaning: '(with "the") Human collective existence; existence in general.',
  },
  {
    id: 109,
    word: "would",
    meaning:
      "Something that would happen, or would be the case, under different circumstances; a potentiality.",
  },
  { id: 110, word: "write", meaning: "The act or style of writing." },
  {
    id: 111,
    word: "years",
    meaning:
      "A solar year, the time it takes the Earth to complete one revolution of the Sun (between 365.24 and 365.26 days depending on the point of reference).",
  },
  {
    id: 112,
    word: "young",
    meaning: "People who are young; young people, collectively; youth.",
  },
];

export const alphabets = [
  { key: "a" },
  { key: "b" },
  { key: "c" },
  { key: "d" },
  { key: "e" },
  { key: "f" },
  { key: "g" },
  { key: "h" },
  { key: "i" },
  { key: "j" },
  { key: "k" },
  { key: "l" },
  { key: "m" },
  { key: "n" },
  { key: "o" },
  { key: "p" },
  { key: "q" },
  { key: "r" },
  { key: "s" },
  { key: "t" },
  { key: "u" },
  { key: "v" },
  { key: "w" },
  { key: "x" },
  { key: "y" },
  { key: "z" },
];
