import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { API } from "../../backend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Marks Vs Test Graph",
    },
  },
  responsive: true,
};

const ScoreCards = () => {
  const localSigninData = JSON.parse(localStorage.getItem("jwt"));
  const [marksVsTest, setMarksVsTest] = useState({});
  const [groupedResults, setGroupedResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getScoreCards = async () => {
    try {
      const res = await axios.get(
        `${API}/scorecards/getByUserId/${localSigninData?.data?.user?._id}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      const labels = [];
      const datasets = [
        {
          label: "Total Marks",
          backgroundColor: "rgb(255, 99, 132)",
          data: [],
        },
        {
          label: "Score",
          backgroundColor: "rgb(75, 192, 192)",
          data: [],
        },
      ];

      res.data?.data.forEach((data) => {
        labels.push(data.test.name);
        datasets[0].data.push(data.test.maxScore);
        datasets[1].data.push(data.totalScore);
      });

      setMarksVsTest({ labels, datasets });
    } catch (err) {
      console.log(err);
    }
  };

  const getGroupedResults = async () => {
    try {
      const res = await axios.get(
        `${API}/scorecards/groupByCategory/${localSigninData?.data?.user?._id}`,
        {
          headers: {
            token: localSigninData?.data?.token,
          },
        }
      );
      const labels = [];
      let dataSetLabels = new Set();
      const datasets = [];

      res.data?.data.forEach((data) => {
        Object.keys(data.groupedData).forEach((key) => {
          dataSetLabels.add(key);
        });
      });

      [...dataSetLabels].forEach((label, index) => {
        datasets.push(
          {
            label: `${label} - Total Marks`,
            backgroundColor: "rgb(255, 99, 132)",
            data: [],
            stack: `Stack ${index}`,
          },
          {
            label: `${label} - Score`,
            backgroundColor: "rgb(75, 192, 192)",
            data: [],
            stack: `Stack ${index}`,
          }
        );
      });

      res.data?.data.forEach((data) => {
        labels.push(data.test.name);
        [...dataSetLabels].forEach((dataLabel, index) => {
          datasets[2 * index].data.push(
            data.groupedData[dataLabel]?.totalScore || 0
          );
          datasets[2 * index + 1].data.push(
            data.groupedData[dataLabel]?.actualScore || 0
          );
        });
      });

      setGroupedResults({ labels, datasets });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    Promise.all([getScoreCards(), getGroupedResults()]).then(() => {
      setIsLoading(false);
    });
  }, []); //eslint-disable-line

  return (
    <Box
      sx={{
        width: "1500px",
        maxWidth: "95%",
        margin: "2rem auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#fff",
          margin: "1rem 0",
        }}
      >
        Marks Vs Tests Graph
      </Typography>
      {!isLoading ? (
        <Bar
          options={options}
          data={marksVsTest}
          style={{ backgroundColor: "rgb(55 44 44)", maxHeight: "400px" }}
        />
      ) : (
        <Box
          style={{
            minHeight: "calc(50vh - 120px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            margin: "2rem 0 1rem",
          }}
        >
          Category Grouping Results
        </Typography>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <div
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: "rgb(255, 99, 132)",
            }}
          ></div>
          <div>Total Marks</div>
          <div
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: "rgb(75, 192, 192)",
            }}
          ></div>
          <div>Score</div>
        </div>
      </div>
      {!isLoading ? (
        <Bar
          options={{
            ...options,
            plugins: { ...options.plugins, legend: { display: false } },
          }}
          data={groupedResults}
          style={{ backgroundColor: "rgb(55 44 44)", maxHeight: "400px" }}
        />
      ) : (
        <Box
          style={{
            minHeight: "calc(50vh - 120px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ScoreCards;
